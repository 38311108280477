import React from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContRedBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { DeleteInvoiceById } from "../../../API_Functions/CaseQuote_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const DeleteInvoiceWindow = ({
  open,
  CloseWindow,
  invoiceid,
  RefreshProjectUI,
}) => {
  const DeleteInvoiceFromProject = async (invoiceid) => {
    if (invoiceid !== 0) {
      var data = await DeleteInvoiceById(invoiceid);
      if (data === null) {
        RefreshProjectUI();
      }
    }
  };

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContRedBorderTopRounded>
        <SmallHeaderLeagueSpartan>
          You're about to delete this invoice
        </SmallHeaderLeagueSpartan>
        <SmallTextPadding>
          Are you sure you want to delete this invoice?
        </SmallTextPadding>
        <FlexRowCont>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={CloseWindow}
          >
            Return
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              CloseWindow();
              DeleteInvoiceFromProject(invoiceid);
            }}
          >
            Delete
          </RegularResponsiveButton>
        </FlexRowCont>
      </FlexColumnContRedBorderTopRounded>
    </FixedOverlay200vh>
  );
};

DeleteInvoiceWindow.defaultProps = {
  CloseWindow: () => {},
  RefreshProjectUI: () => {},
};

export default DeleteInvoiceWindow;
