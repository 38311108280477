/* eslint-disable */

import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/ActionMenu";
import { useParams } from "react-router-dom";
import StoreAdminCard from "../../comps/StoreAdminCard";

import {
  LeftCont,
  FlexCont100vw100vh,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const CatFlexDiv = styled.div`
  width: 80%;
  display: flex;
  flex-flow: row row;
  gap: 5px;
  @media (max-width: 1006px) {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StoreAdminListScreen = ({ MetroID }) => {
  //=============================================================================
  // Admin Store List - Admin can view the stores in a Metro and select the one
  // that they would like to view.
  //=============================================================================

  //=============================================================================
  // Redirects to the StoreAdmin (Only accessable by Admin Role)
  //=============================================================================

  const params = useParams();

  //AXIOS
  const [storeList, setStoreList] = useState([]);

  const GetSessionInfo = async () => {
    idForMetro = JSON.parse(sessionStorage.getItem("userInfo")).Metro_Id;
    idForStore = JSON.parse(sessionStorage.getItem("userInfo")).Store_Id;
    const resp2 = await axios.get(window.$DBURL + "store/metro/" + params.Id);
    if (resp2.status == 200) {
      if (resp2.data !== null) {
        if (resp2.data.stores !== null) {
          setStoreList(resp2.data.stores);
        }
      }
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <RightCont>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <LeagueSpartanExtraLargeBold>
              View Stores
            </LeagueSpartanExtraLargeBold>
          </FlexDiv70x7Percent>
          <CatFlexDiv>
            {storeList.map((o, i) => (
              <StoreAdminCard
                key={i}
                CatHeadText={o.Name}
                CatParaText={o.Address}
                storeId={o.Id}
              ></StoreAdminCard>
            ))}
          </CatFlexDiv>
        </RightViewFullWidth>
      </RightCont>
    </FlexCont100vw100vh>
  );
};

StoreAdminListScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default StoreAdminListScreen;
