import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContGreenBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { GetCaseById } from "../../../API_Functions/CaseQuote_Functions";
import { AddQuoteRevision } from "../../../API_Functions/CaseQuote_Functions";
import { GetCurrentTime } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { CategoryTypes } from "../../../StylesheetComps/CategoryTypes";

const DuplicateProposalWindow = ({ open, onClose, quoteObj }) => {
  const history = useHistory();
  const params = useParams();
  const [savingRevision, setSavingRevision] = useState(false);

  const AddProposal = async (quoteobj) => {
    try {
      await AddQuoteRevision(quoteobj);
    } catch (error) {
      console.error('Error while adding quote revision:', error);
    } finally {
      history.push(
        "/viewcase/" + params.Id + "/" + params.storeId + "/" + quoteobj.Case_Id
      );
    }
  };
  
  const CloneProposal = async (quoteobj) => {
    var data = await GetCaseById(quoteobj.Case_Id);
    if (data != null) {
      var quotes = data.Case.Quotes;
      var proposal = 0;
      quotes.forEach((q) => {
        if (q.Proposal > proposal) {
          proposal = q.Proposal;
        }
      });
      quoteobj.Proposal = proposal + 1;
      quoteobj.Revision = 0;
      quoteobj.AddTime = await GetCurrentTime();

      setSavingRevision(true);
      await AddProposal(quoteobj);
    }
  };
  if (!open) return null;
  if (savingRevision === true) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <FixedOverlay200vh>
        <FlexColumnContGreenBorderTopRounded>
          <SmallHeaderLeagueSpartan>
            You're about to duplicate this quote.
          </SmallHeaderLeagueSpartan>
          <SmallTextPadding>
            Are you sure you want to duplicate this quote?
          </SmallTextPadding>
          <FlexRowCont>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryGrey}
              onClick={onClose}
            >
              Cancel
            </RegularResponsiveButton>
            <RegularResponsiveButton
              onClick={() => {
                CloneProposal(quoteObj);
              }}
            >
              Duplicate Quote
            </RegularResponsiveButton>
          </FlexRowCont>
        </FlexColumnContGreenBorderTopRounded>
      </FixedOverlay200vh>
    );
  }
};

DuplicateProposalWindow.defaultProps = {
  CloseWindow: () => {},
  onClose: () => {},
  newCreatedRevision: () => {},
};

export default DuplicateProposalWindow;
