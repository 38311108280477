import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFlexStart,
  LeftItemWindowFixed
} from "../../../StylesheetComps/Cont";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  RegularText
} from "../../../StylesheetComps/Titles";

const ConfirmWindow = ({
  open,
  onClose,
  confirmtext,
  leftbuttontext,
  rightbuttontext,
  leftbuttoncolor,
  rightbuttoncolor,
  left,
  LeftFunction,
  RightFunction,
  width,
  top,
}) => {
  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexRowContFlexStart>
        <LeftItemWindowFixed left={left} top={top} width={width}>
          <RegularText>{confirmtext}</RegularText>
          <br></br>
          <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              bgcolor={leftbuttoncolor}
              onClick={LeftFunction}
            >
              {leftbuttontext}
            </RegularResponsiveButton>{" "}
            <RegularResponsiveButton
              bgcolor={rightbuttoncolor}
              onClick={RightFunction}
            >
              {rightbuttontext}
            </RegularResponsiveButton>
          </FlexRowCont100PercentSpaceBetween>

          <FlexColumnCont></FlexColumnCont>
        </LeftItemWindowFixed>
      </FlexRowContFlexStart>
    </FixedOverlay200vh>
  );
};

ConfirmWindow.defaultProps = {
  RightFunction: () => {},
  LeftFunction: () => {},
  rightbuttoncolor: ColorPalette.PrimaryButtonBlue,
  leftbuttoncolor: ColorPalette.PrimaryButtonBlue,
  left: "40%",
  top: "5%",
};

export default ConfirmWindow;
