import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddToolKit,
  GetToolKitObject,
} from "../../../API_Functions/Tool_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  ContMargin20px,
  FlexDiv,
  FlexRowCont60PercentSpaceEvenly,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { Icon45x45 } from "../../../StylesheetComps/Imgs/Icons";
import { Image200x200pxContain } from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaRegular,
  TextInput100Percent,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularTextBoldRed,
} from "../../../StylesheetComps/Titles";

const SubToolKitAdd = ({ open, onClose, onNewSubToolkit }) => {
  const params = useParams();

  const [toolkitObj, setToolkitObj] = useState({});

  const [errorText] = useState("");
  const [ToolKitImgDisplay, setToolKitImgDisplay] = useState("");
  const [photoDisplay, setPhotoDisplay] = useState("none");

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  var file = "";

  function handleUpload(event) {
    file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setToolKitImgDisplay(pic);
      toolkitObj.ImageUrl = pic;
      setPhotoDisplay("block");
    }
  };

  const GetSubkitObjectStructure = async () => {
    var data = await GetToolKitObject();
    if (data !== null) {
      setToolkitObj(data);
    }
  };

  const AddNewSubToolkit = async (toolkit) => {
    var data = await AddToolKit(toolkit);
    if (data !== null) {
      onNewSubToolkit();
      onClose();
    }
  };

  useEffect(() => {
    GetSubkitObjectStructure();
  }, []);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <ContMargin20px>
        <LeftItemWindowFixed>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              ToolKit Name
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="ToolKit Name"
              onChange={(e) => {
                toolkitObj.Name = e.target.value;
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Code
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="ToolKit Code"
              onChange={(e) => {
                toolkitObj.Code = e.target.value;
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Position
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="Position"
              onChange={(e) => {
                toolkitObj.Position = e.target.value;
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              ToolKit Description
            </LeagueSpartanExtraLargeWidth200px>
            <TextAreaRegular
              maxlength="100"
              onChange={(e) => {
                toolkitObj.Description = e.target.value;
              }}
            ></TextAreaRegular>
          </FlexDiv>
          <LeagueSpartanExtraLargeWidth200px>
            ToolKit Picture
          </LeagueSpartanExtraLargeWidth200px>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <FlexDiv>
            <Image200x200pxContain
              display={photoDisplay}
              src={window.$IMGURL + ToolKitImgDisplay}
            ></Image200x200pxContain>
            <Icon45x45
              display={photoDisplay}
              src="/Garbage_Icon.svg"
              onClick={() => {
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
                toolkitObj.ImageUrl = "";
              }}
            ></Icon45x45>
          </FlexDiv>
          <button for="ToolKitpic" class="btn" onClick={handleClick}>
            Load Image
          </button>
          <br></br>
          <TextInput100Percent
            display="none"
            type="file"
            id="ToolKitpic"
            ref={hiddenFileInput}
            name="filename"
            onChange={handleUpload}
          ></TextInput100Percent>
          <br></br>
          <FlexRowCont60PercentSpaceEvenly>
            <RegularResponsiveButton
              onClick={() => {
                toolkitObj.Parent_Id = params.kitId;
                toolkitObj.Store_Id = params.Id;
                AddNewSubToolkit(toolkitObj);
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
              }}
            >
              Save
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
              }}
            >
              Cancel
            </RegularResponsiveButton>
          </FlexRowCont60PercentSpaceEvenly>
        </LeftItemWindowFixed>
      </ContMargin20px>
    </FixedOverlay200vh>
  );
};

SubToolKitAdd.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onToolKitAdd: () => {},
  onSetFile: () => {},
  onReleaseFile: () => {},
  ToolKitPosProp: 0,
};

export default SubToolKitAdd;
