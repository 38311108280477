import React from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { FlexDiv, LeftItemWindowFixed } from "../../../StylesheetComps/Cont";
import {
  LeagueSpartanExtraLargeBold,
  RegularText,
} from "../../../StylesheetComps/Titles";
import { AbsoluteOverlayDivFullWidth } from "../../../StylesheetComps/OverlayCont";

const AvailableWindow = ({ open, day, month, year, PushToSlots, onClose }) => {
  if (!open) return null;
  return (
    <>
      {" "}
      <AbsoluteOverlayDivFullWidth>
        <LeftItemWindowFixed left="20%" top="20%">
          <LeagueSpartanExtraLargeBold>
            Next Available Date after this is:
          </LeagueSpartanExtraLargeBold>
          <RegularText>
            {month}/{day}/{year}
          </RegularText>
          <FlexDiv>
            <RegularResponsiveButton onClick={PushToSlots}>
              Select
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={onClose}
            >
              Cancel
            </RegularResponsiveButton>
          </FlexDiv>
        </LeftItemWindowFixed>
      </AbsoluteOverlayDivFullWidth>
    </>
  );
};

AvailableWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  PushToSlots: () => {},
  day: 0,
  month: 0,
  year: 0,
};

export default AvailableWindow;
