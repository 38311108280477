import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddToolKit,
  GetToolKitObject,
} from "../../../API_Functions/Tool_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  ContMargin20px,
  FlexDiv,
  FlexRowCont,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../../StylesheetComps/Div";
import { Image200x200pxContain } from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaLarge,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularTextBoldRed,
  StyledHRPropColour,
} from "../../../StylesheetComps/Titles";

const ToolKitAdd = ({ open, onClose, RefreshToolKitList, ToolKitPosProp }) => {
  const params = useParams();
  const [toolkitObj, setToolkitObj] = useState({});
  const [ToolKitPos] = useState(ToolKitPosProp);
  const [errorText] = useState("");
  const [ToolKitImgDisplay, setToolKitImgDisplay] = useState("");
  const [photoDisplay, setPhotoDisplay] = useState("none");
  const hiddenFileInput = React.useRef(null);

  var file = "";

  function handleUpload(event) {
    file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setToolKitImgDisplay(pic);
      toolkitObj.ImageUrl = pic;
      setPhotoDisplay("block");
    }
  };

  const GetToolKitStructure = async () => {
    var data = await GetToolKitObject();
    setToolkitObj(data);
  };

  const AddNewToolKit = async (toolkitobj) => {
    var data = await AddToolKit(toolkitobj);
    if (data !== null) {
      RefreshToolKitList();
    }
  };

  useEffect(() => {
    GetToolKitStructure();
  }, []);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <ContMargin20px>
        <LeftItemWindowFixed width="100px">
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              ToolKit Name
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="ToolKit Name"
              onChange={(e) => {
                var toolkit = toolkitObj;
                toolkit.Name = e.target.value;
                setToolkitObj(toolkit);
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Code
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="ToolKit Code"
              onChange={(e) => {
                var toolkit = toolkitObj;
                toolkit.Code = e.target.value;
                setToolkitObj(toolkit);
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Position
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputReusable
              placeholder="Position"
              value={ToolKitPos}
              onChange={(e) => {
                var toolkit = toolkitObj;
                toolkit.Position = e.target.value;
                setToolkitObj(toolkit);
              }}
            ></TextInputReusable>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              ToolKit Description
            </LeagueSpartanExtraLargeWidth200px>
            <TextAreaLarge
              maxlength="100"
              onChange={(e) => {
                var toolkit = toolkitObj;
                toolkit.Description = e.target.value;
                setToolkitObj(toolkit);
              }}
            ></TextAreaLarge>
          </FlexDiv>
          <LeagueSpartanExtraLargeWidth200px>
            ToolKit Picture
          </LeagueSpartanExtraLargeWidth200px>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <FlexRowCont>
            <Image200x200pxContain
              display={photoDisplay}
              src={window.$IMGURL + ToolKitImgDisplay}
            ></Image200x200pxContain>
            <Icon30px30pxPointer
              display={photoDisplay}
              src="/Garbage_Icon.svg"
              onClick={() => {
                toolkitObj.ImageUrl = "";
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
              }}
            ></Icon30px30pxPointer>
          </FlexRowCont>
          <TextInputReusable
            type="file"
            id="ToolKitpic"
            ref={hiddenFileInput}
            name="filename"
            onChange={handleUpload}
          ></TextInputReusable>
          <StyledHRPropColour
            border={`0.5px solid white`}
            width="70%"
          ></StyledHRPropColour>
          <FlexDivCenter>
            <RegularResponsiveButton
              onClick={() => {
                toolkitObj.Store_Id = params.Id;
                AddNewToolKit(toolkitObj);
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
                onClose();
              }}
            >
              Save
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setToolKitImgDisplay("");
                setPhotoDisplay("none");
              }}
            >
              Cancel
            </RegularResponsiveButton>
          </FlexDivCenter>
          <StyledHRPropColour
            border={`0.5px solid white`}
            width="70%"
          ></StyledHRPropColour>{" "}
        </LeftItemWindowFixed>
      </ContMargin20px>
    </FixedOverlay200vh>
  );
};

ToolKitAdd.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onToolKitAdd: () => {},
  onSetFile: () => {},
  onReleaseFile: () => {},
  ToolKitPosProp: 0,
};

export default ToolKitAdd;
