/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import ActionMenu from "../../comps/ActionMenu";
import ConfirmCancel from "../../comps/ConfirmCancel";
import EditAppointment from "../../comps/EditAppointment";
import Menu from "../../comps/MenuComps/Menu";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const InstallerPersonalCalendar = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Installers Personal Calendar - Install can view their Scheduled Appointments
  //=============================================================================

  //=============================================================================
  // Page should only be accessable and viewable by Installers
  //=============================================================================

  const [installerId, setInstallerId] = useState(0);
  const [isEditAppointment, setIsEditAppointment] = useState(false);
  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;
  const [appointmentList, setAppointmentList] = useState([]);
  const [calendarMonth, setCalendarMonth] = useState(currentMonth);
  const [nameofUser, setNameOfUser] = useState("");
  const [startTimeSlot, setStartTimeSlot] = useState(0);
  const [endTimeSlot, setEndTimeSlot] = useState(0);
  const [startTimeSlotMinute, setStartTimeSlotMinute] = useState(0);
  const [endTimeSlotMinute, setEndTimeSlotMinute] = useState(0);

  const [appDay, setAppDay] = useState(0);
  const [appMonth, setAppMonth] = useState(0);
  const [appYear, setAppYear] = useState(0);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const [flexible, setFlexible] = useState(false);

  const [slotId, setSlotId] = useState(0);
  const [appId, setAppId] = useState(0);

  const [contactName, setContactName] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");

  const [appointmentIdAsync, setAppointmentIdAsync] = useState(0);
  const [ConfirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const [ConfirmDateChange, setConfirmDateChange] = useState(false);

  var appointmentId = 0;
  var contactNameNew = "";
  var addressNew = "";
  var phoneNew = "";
  var cityNew = "";
  var flexibleNew = "";
  var slotIdNew = 0;
  var appDescNew = "";
  var newDay = "";
  var newMonth = "";
  var newYear = "";

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  //Get Installer Personal Information

  const GetSessionInfo = async () => {
    setNameOfUser(JSON.parse(sessionStorage.getItem("userInfo")).Name);
    setInstallerId(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    GetTimeSlots(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    GetUserAppointmentsAsync(JSON.parse(sessionStorage.getItem("userInfo")).Id);
  };

  //Get Own TimeSlots

  const GetTimeSlots = async (installerId) => {
    try {
      // Make an HTTP GET request to the specified URL
      const resp = await axios.get(
        window.$DBURL + "appointment/slot/installer/" + installerId
      );

      // Check if the request was successful and there are time slots available
      if (
        resp.status === 200 &&
        resp.data &&
        resp.data.timeslots &&
        resp.data.timeslots.length > 0
      ) {
        // Set the start time slot and minute
        setStartTimeSlot(resp.data.timeslots[0].Start.Hour);
        setStartTimeSlotMinute(resp.data.timeslots[0].Start.Minute);
        // Set the end time slot and minute
        setEndTimeSlot(
          resp.data.timeslots[resp.data.timeslots.length - 1].End.Hour
        );
        setEndTimeSlotMinute(
          resp.data.timeslots[resp.data.timeslots.length - 1].End.Minute
        );
      }
    } catch (error) {
      // Handle error
    }
  };

  //Get Appointments booked with Installer

  const GetUserAppointmentsAsync = async (installerId) => {
    try {
      // Make an HTTP POST request to the specified URL
      const resp = await axios.post(window.$DBURL + "appointment/installer", {
        Year: currentYear,
        Month: calendarMonth,
        Day: 0,
        Installer_Id: installerId,
      });

      // Check if the request was successful and there are appointments available
      if (
        resp.status === 200 &&
        resp.data &&
        resp.data.appointment &&
        resp.data.appointment.length > 0
      ) {
        // Filter Set the appointment list after filtering out invalid appointments
        setAppointmentList(
          resp.data.appointment.filter(function (o, i) {
            if (o.Start !== null && o.End !== null && o.Id !== 0) {
              return o;
            }
          })
        );
      }
    } catch (error) {
      // Handle error
    }
  };

  const [yearUpdated, setYearUpdated] = useState(0);
  const [monthUpdated, setMonthUpdated] = useState(0);
  const [dayUpdate, setDayUpdate] = useState(0);

  const HandleAppointmentForm = async ({
    contactName,
    address,
    phone,
    city,
    flexible,
    slotId,
    appDesc,
    EditYear,
    EditMonth,
    EditDay,
  }) => {
    contactNameNew = contactName;
    addressNew = address;
    phoneNew = phone;
    cityNew = city;
    flexibleNew = flexible;
    slotIdNew = slotId;
    appDescNew = appDesc;
    newDay = EditDay;
    newMonth = EditMonth;
    newYear = EditYear;
    setDayUpdate(EditDay);
    setMonthUpdated(EditMonth);
    setYearUpdated(EditYear);
  };

  //Update Appointment with Homeowner

  const HandleAppointmentUpdated = async ({
    editName,
    editAddress,
    editPhone,
    editCity,
    editFlexible,
    editSlotId,
    editAppDesc,
  }) => {
    if (
      (newDay === appDay &&
        newMonth === appMonth &&
        newYear === appYear &&
        slotIdNew === slotId) ||
      slotIdNew === 0
    ) {
      const resp = await axios.put(window.$DBURL + "appointment", {
        Id: appId,
        DailyOpenSlot_Id: slotIdNew || slotId,
        Day: newDay || appDay,
        Month: newMonth || appMonth,
        Year: newYear || appYear,
        Flexible: flexibleNew,
        Contactname: contactNameNew || contactName,
        Phone: phoneNew || phone,
        City: cityNew || city,
        Street: addressNew || address,
        Description: appDescNew || appDesc,
      });
      if (resp.status === 200) {
        GetAppointmentInfoAsync(appointmentIdAsync);
        GetUserAppointmentsAsync(installerId);
      }
    } else {
      const resp = await axios.put(window.$DBURL + "appointment/all", {
        Id: appId,
        DailyOpenSlot_Id: slotIdNew || slotId,
        Day: newDay || appDay,
        Month: newMonth || appMonth,
        Year: newYear || appYear,
        Flexible: flexibleNew,
        Contactname: contactNameNew || contactName,
        Phone: phoneNew || phone,
        City: cityNew || city,
        Street: addressNew || address,
        Description: appDescNew || appDesc,
      });
      if (resp.status === 200) {
        GetAppointmentInfoAsync(appointmentIdAsync);
        GetUserAppointmentsAsync(installerId);
      }
    }
  };

  //Update Appointment with Homeowner and move other appointments forward

  const HandleAppointmentUpdatedInstallerMove = async () => {
    const resp = await axios.put(window.$DBURL + "appointment/all", {
      Id: appId,
      DailyOpenSlot_Id: slotIdNew || slotId,
      Day: dayUpdate || appDay,
      Month: monthUpdated || appMonth,
      Year: yearUpdated || appYear,
      Flexible: flexibleNew,
      Contactname: contactNameNew || contactName,
      Phone: phoneNew || phone,
      City: cityNew || city,
      Street: addressNew || address,
      Description: appDescNew || appDesc,
    });
    if (resp.status === 200) {
      GetAppointmentInfoAsync(appointmentIdAsync);
      GetUserAppointmentsAsync(installerId);
      GetTimeSlots(installerId);
    }
  };

  //Update Appointment with Homeowner and don't move other appointments forward

  const HandleAppointmentUpdatedInstallerStay = async () => {
    const resp = await axios.put(window.$DBURL + "appointment", {
      Id: appId,
      DailyOpenSlot_Id: slotIdNew || slotId,
      Day: dayUpdate || appDay,
      Month: monthUpdated || appMonth,
      Year: yearUpdated || appYear,
      Flexible: flexibleNew,
      Contactname: contactNameNew || contactName,
      Phone: phoneNew || phone,
      City: cityNew || city,
      Street: addressNew || address,
      Description: appDescNew || appDesc,
    });
    if (resp.status === 200) {
      GetAppointmentInfoAsync(appointmentIdAsync);
      GetUserAppointmentsAsync(installerId);
      GetTimeSlots(installerId);
    }
  };

  //Cancel Appointment with Homeowner and move other appointments forward

  const HandleCancel = async (appointmentId, installerId) => {
    try {
      const resp = await axios.delete(
        `${window.$DBURL}appointment/cancel/${appointmentId}`
      );
      if (resp.status === 200) {
        GetUserAppointmentsAsync(installerId);
        GetTimeSlots(installerId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Cancel Appointment with Homeowner and don't move other appointments forward

  const HandleCancelNoMove = async (appointmentId, installerId) => {
    try {
      const resp = await axios.delete(
        `${window.$DBURL}appointment/${appointmentId}`
      );
      if (resp.status === 200) {
        GetUserAppointmentsAsync(installerId);
        GetTimeSlots(installerId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Get Appointment Info

  const GetAppointmentInfoAsync = async (appointmentId) => {
    // The try-catch block is used to handle any potential errors that may occur while sending
    // the GET request.
    try {
      const resp = await axios.get(
        `${window.$DBURL}appointment/${appointmentId}`
      );
      if (resp.status === 200) {
        const { data } = resp;
        if (data && data.appointment) {
          const { appointment } = data;
          setAppDay(appointment.Day);
          setAppMonth(appointment.Month);
          setAppYear(appointment.Year);

          // Update the start time of the appointment if it is not null.
          if (appointment.Start) {
            setStartHour(appointment.Start.Hour);
            setStartMinute(appointment.Start.Minute);
          }

          // Update the end time of the appointment if it is not null.
          if (appointment.End) {
            setEndHour(appointment.End.Hour);
            setEndMinute(appointment.End.Minute);
          }

          setFlexible(appointment.Flexible);
          setSlotId(appointment.DailyOpenSlot_Id);
          setAppId(appointment.Id);

          setContactName(appointment.Contactname);
          setAppDesc(appointment.Description);
          setAddress(appointment.Street);
          setPhone(appointment.Phone);
          setCity(appointment.City);

          setInstallerId(appointment.Installer_Id);
        }
      }
    } catch (error) {
      // If an error occurs, log it to the console for further investigation.
      console.error(error);
    }
  };

  //Get Appointment Info

  const GetAppointmentInfo = async (appointmentId) => {
    try {
      const resp = await axios.get(
        `${window.$DBURL}appointment/${appointmentId}`
      );
      const { data } = resp;
      const { appointment } = data;

      setAppDay(appointment.Day);
      setAppMonth(appointment.Month);
      setAppYear(appointment.Year);

      if (appointment.Start) {
        setStartHour(appointment.Start.Hour);
        setStartMinute(appointment.Start.Minute);
      }

      if (appointment.End) {
        setEndHour(appointment.End.Hour);
        setEndMinute(appointment.End.Minute);
      }

      setFlexible(appointment.Flexible);
      setSlotId(appointment.DailyOpenSlot_Id);
      setAppId(appointment.Id);

      setContactName(appointment.Contactname);
      setAppDesc(appointment.Description);
      setAddress(appointment.Street);
      setPhone(appointment.Phone);
      setCity(appointment.City);

      setInstallerId(appointment.Installer_Id);

      // If the start or end time of the appointment is exactly on the hour (e.g. 12:00),
      // set the minute value to "00" rather than 0.
      if (appointment.Start.Minute === 0 && appointment.End.Minute === 0) {
        setStartMinute("00");
        setEndMinute("00");
      } else if (appointment.End.Minute === 0) {
        setEndMinute("00");
      } else if (appointment.Start.Minute === 0) {
        setStartMinute("00");
      } else {
        setStartMinute(appointment.Start.Minute);
        setEndMinute(appointment.End.Minute);
      }
    } catch (error) {
      // If an error occurs, log it to the console for further investigation.
      console.error(error);
    }
  };

  //Calendar Prop Getter (Change Calendar Day Colour based on availability of the day)

  const closeDayPropGetter = (date) => {
    // Use the Array.prototype.find() method to search the appointmentList array for an
    // appointment that matches the specified date.
    const appointment = appointmentList.find((o) => {
      return (
        o.Day === date.getDate() &&
        o.Month === date.getMonth() + 1 &&
        o.Year === date.getFullYear()
      );
    });

    // If an appointment is found that matches the date, return an object with a style
    // property that sets the background color of the date to green.
    if (appointment) {
      return { style: { backgroundColor: "#169ADB" } };
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetUserAppointmentsAsync(installerId);
  }, [calendarMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <EditAppointment
            currentTimeslotId={slotId}
            appointmentTime={
              startHour + ":" + startMinute + " - " + endHour + ":" + endMinute
            }
            CancelAppointment={() => {
              setConfirmCancelOpen(true);
            }}
            onPreview={HandleAppointmentForm}
            onAppointmentEdit={HandleAppointmentUpdated}
            onUpdateDate={() => {
              setConfirmDateChange(true);
            }}
            installerId={installerId}
            dfName={contactName}
            dfNotes={appDesc}
            dfAddress={address}
            dfCity={city}
            dfCellPhone={phone}
            month={appMonth}
            day={appDay}
            year={appYear}
            dfFlex={flexible}
            open={isEditAppointment}
            onClose={() => {
              setIsEditAppointment(false);
            }}
          ></EditAppointment>
          <ConfirmCancel
            open={ConfirmCancelOpen}
            MoveForward={() => {
              HandleCancel(appointmentIdAsync, installerId);
            }}
            DoNotMove={() => {
              HandleCancelNoMove(appointmentIdAsync, installerId);
            }}
            onClose={() => {
              setConfirmCancelOpen(false);
            }}
          ></ConfirmCancel>
          <ConfirmCancel
            open={ConfirmDateChange}
            MoveForward={() => {
              HandleAppointmentUpdatedInstallerMove();
              GetAppointmentInfoAsync(appointmentIdAsync);
              GetUserAppointmentsAsync(installerId);
            }}
            DoNotMove={() => {
              HandleAppointmentUpdatedInstallerStay();
              GetAppointmentInfoAsync(appointmentIdAsync);
              GetUserAppointmentsAsync(installerId);
            }}
            onClose={() => {
              setConfirmDateChange(false);
            }}
          ></ConfirmCancel>
          <LeagueSpartanExtraLargeBold>
            {nameofUser + "'s Personal Calendar"}
          </LeagueSpartanExtraLargeBold>
          <Calendar
            localizer={localizer}
            events={appointmentList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Description + " with " + o.Contactname,
                start: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.Start.Hour,
                  o.Start.Minute
                ),
                end: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.End.Hour,
                  o.End.Minute
                ),
                allDay: false,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={calendarStyle}
            dayLayoutAlgorithm="no-overlap"
            min={new Date(2021, 11, 0, startTimeSlot, startTimeSlotMinute, 0)}
            max={new Date(2021, 11, 0, endTimeSlot, endTimeSlotMinute, 0)}
            longPressThreshold={10}
            dayPropGetter={closeDayPropGetter}
            onSelectEvent={(event) => {
              setIsEditAppointment(true);
              appointmentId = event.id;
              setAppointmentIdAsync(event.id);
              GetAppointmentInfo(appointmentIdAsync);
            }}
            onNavigate={(date) => {
              setCalendarMonth(date.getMonth() + 1);
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

InstallerPersonalCalendar.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default InstallerPersonalCalendar;
