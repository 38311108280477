import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetCategory,
  GetStoreCategories,
  GetStoreProducts,
} from "../../API_Functions/CatProduct_Functions";
import Input from "../../comps/Input";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  AutoSizeOverflowY,
  Cont100Percent80PercentOverflowY,
  FlexColumnContFullWidthOverflowY,
  FlexColumnFullWidth,
  FlexDiv,
  FlexFixedCont,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont100x8PercentSpaceAround,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
  LeftItemWindowFixed,
} from "../../StylesheetComps/Cont";
import { BlankDiv, FlexDivCenter } from "../../StylesheetComps/Div";
import { TextInput72Percent } from "../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { FixedOverlay1000vh } from "../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanRegular,
  StyledHRPropColour,
  TinyTextItalics,
  TitleFontSize100PercentPointer,
} from "../../StylesheetComps/Titles";
import DeleteConfirmWindow from "../Windows/DeleteProduct";

const ProductRelation = ({
  open,
  GetRelationsAgain,
  onClose,
  onFormComplete,
  onRelationId,
  RelatedProducts,
  onPreview,
  ProductName,
  ProductID,
  onDelRelation,
  UpdateRFactor,
}) => {
  const [storeNewMetro] = useState("");
  const [RhFactor, setRhFactor] = useState(0);
  const [Position, setPosition] = useState(0);
  const [storeProducts, setStoreProducts] = useState([]);
  const [allProductsInStore, setAllProductsInStore] = useState([]);
  // const [storeSubCategories, setStoreSubCategories] = useState([]);
  const [storeCategories, getStoreCategories] = useState([]);

  const params = useParams();
  const [ProductRelationId, setProductRelationId] = useState(0);
  const [InputDisplay, setInputDisplay] = useState("none");
  const [RFactorDisplay, setRFactorDisplay] = useState("none");
  const [addItemDisplay, setAddItemDisplay] = useState("none");
  const [editItemDisplay, setEditItemDisplay] = useState("none");
  const [ItemName, setItemName] = useState("");

  const [rFactorDisplayPropCont, setRFactorDisplayPropCont] = useState("flex");
  const [secondaryProdCateType, setSecondaryProdCateType] = useState(1000);

  const [relatedId, setRelatedId] = useState(0);
  const [RFactorValue, setRFactorValue] = useState(0);
  const [PositionValue, setPositionValue] = useState(0);

  const [rfactorText, setRfactorText] = useState(0);
  const [positionText, setPositionText] = useState(0);
  const [productText, setProductText] = useState("");

  const [confirmDelete, setConfirmDelete] = useState(false);

  const [parentId, setParentId] = useState(0);

  const [rfactorDisplayProp, setRfactorDisplayProp] = useState("none");
  const [positionDisplayProp, setPositionDisplayProp] = useState("none");
  const [deleteItemId, setDeleteItemId] = useState(0);

  const GetAllStoreProducts = async (storeid) => {
    var data = await GetStoreProducts(storeid);
    if (data !== null) {
      setAllProductsInStore(data.products);
    }
  };

  const GetStoreCategoriesFunct = async (storeid) => {
    var data = await GetStoreCategories(storeid);
    if (data != null) {
      getStoreCategories(data.cate);
    }
  };

  const GetCurrentCategories = async (cateid) => {
    var data = await GetCategory(cateid);
    if (data !== null) {
      getStoreCategories(data.SubCategories);
      setParentId(data.Parent_Id);
    }
  };

  const Get_SubCategory_Product = async (catid) => {
    var cate = await GetCategory(catid);
    if (cate != null) {
      // setStoreSubCategories(cate.SubCategories);
      setStoreProducts(cate.Products);
    }
  };

  const HandleAddRelation = async (
    prod_id,
    relatedprod_id,
    rfactor,
    position
  ) => {
    var resp = await axios.post(window.$DBURL + "catalog/relation", {
      Id: prod_id,
      Related_Id: relatedprod_id,
      RFactor: rfactor,
      Position: position,
    });
    if (resp.status === 200) {
      GetRelationsAgain();
    }
  };

  useEffect(() => {
    GetStoreCategoriesFunct(params.Id);
    GetAllStoreProducts(params.Id);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (secondaryProdCateType) {
      case 2:
        setRFactorDisplayPropCont("none");
        break;
      case 3:
        setRFactorDisplayPropCont("none");
        break;
      default:
        setRFactorDisplayPropCont("flex");
    }
  }, [secondaryProdCateType]);

  var relatedProductId = 0;

  const ShowEditRelatedItemUI = async (o) => {
    relatedProductId = o.Id;
    setRFactorValue(o.RFactor);
    setPositionValue(o.Position);
    setRelatedId(o.Id);
    setRfactorText(o.RFactor.toFixed(4));
    setProductText(o.Name);
    setPositionText(o.Position);
    setAddItemDisplay("none");
    setEditItemDisplay("block");
  };

  const CloseAndResetWindow = async (reset) => {
    onFormComplete(reset);
    onClose();
    getStoreCategories([]);
    // setStoreSubCategories([]);
    setStoreProducts([]);
    setInputDisplay("none");
    setStoreProducts([]);
    setRFactorDisplay("none");
    setAddItemDisplay("none");
    // setCategoryName("");
  };

  const ShowLinkNewProductUI = async () => {
    setEditItemDisplay("none");
    setAddItemDisplay("flex");
  };

  const SelectCategory = async (o) => {
    Get_SubCategory_Product(o.Id);
    // setIconSrc("/rightarrow.svg");
    // setCategoryName(o.Name);
  };

  const SelectProduct = async (o) => {
    setProductRelationId(o.Id);
    setSecondaryProdCateType(o.CateType);
    setRFactorDisplay("block");
    setInputDisplay("block");
    setItemName(o.Name);
  };

  const ShowRFactorInfo = async (display) => {
    if (display === "none") {
      setRfactorDisplayProp("block");
    } else if (display === "block") {
      setRfactorDisplayProp("none");
    }
  };

  const ShowPositionInfo = async (display) => {
    if (display === "none") {
      setPositionDisplayProp("block");
    } else if (display === "block") {
      setPositionDisplayProp("none");
    }
  };

  const AddRelatedProduct = async (id, relatedid, rfactor, position) => {
    HandleAddRelation(id, relatedid, rfactor, position);
    alert("Item added");
  };

  const FilterProducts = (productlist, text) => {
    // setCategoryName("Search Results:");
    // setStoreSubCategories([]);
    setStoreProducts(
      productlist.filter((o) => {
        return o.Name.toLowerCase().includes(text.toLowerCase());
      })
    );
  };

  const ResetAddUI = async () => {
    setEditItemDisplay("none");
    setRfactorText("");
    setPositionText("");
  };

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh>
        <FlexFixedCont>
          <LeftItemWindowFixed minwidth="40vw">
            <Cont100Percent80PercentOverflowY overflowx="hidden">
              <FlexDivCenter>
                <LeagueSpartanRegular>{ProductName}</LeagueSpartanRegular>
              </FlexDivCenter>
              <FlexDiv>
                <LeagueSpartanRegular>
                  Current Linked Products:
                </LeagueSpartanRegular>
              </FlexDiv>

              <FlexRowCont100PercentSpaceBetween>
                <SmallHeader>Delete:</SmallHeader>
                <SmallHeader width="180px">Name:</SmallHeader>
                <SmallHeader>RFactor:</SmallHeader>
                <SmallHeader>Position:</SmallHeader>
                <SmallHeader>Edit:</SmallHeader>
              </FlexRowCont100PercentSpaceBetween>
              <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>
              <AutoSizeOverflowY>
                <DeleteConfirmWindow
                  deleteOpen={confirmDelete}
                  onDeleteFunction={() => {
                    relatedProductId = deleteItemId;
                    onRelationId({ relatedProductId });
                    setConfirmDelete(false);
                  }}
                  closeDel={() => {
                    setConfirmDelete(false);
                  }}
                ></DeleteConfirmWindow>
                {RelatedProducts.map((o, i) => (
                  <FlexRowCont100PercentSpaceBetween>
                    <Icon30px30pxPointer
                      src="/Garbage_Icon.svg"
                      onClick={() => {
                        setConfirmDelete(true);
                        setDeleteItemId(o.Id);
                      }}
                    ></Icon30px30pxPointer>
                    <SmallHeader width="150px">{o.Name}</SmallHeader>

                    <p>{o.RFactor.toFixed(4)}</p>
                    <p>{o.Position}</p>

                    <Icon30px30pxPointer
                      src="/Editicon.svg"
                      onClick={() => {
                        ShowEditRelatedItemUI(o);
                      }}
                    ></Icon30px30pxPointer>
                  </FlexRowCont100PercentSpaceBetween>
                ))}
              </AutoSizeOverflowY>
              <FlexRowCont
                onClick={() => {
                  onPreview({ storeNewMetro });
                }}
              >
                <RegularResponsiveButton
                  onClick={() => {
                    ShowLinkNewProductUI();
                  }}
                >
                  Link New Product
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    CloseAndResetWindow(storeNewMetro);
                  }}
                >
                  Close
                </RegularResponsiveButton>
              </FlexRowCont>
            </Cont100Percent80PercentOverflowY>
          </LeftItemWindowFixed>

          <LeftItemWindowFixed
            width="40vw"
            display={addItemDisplay}
            left="47vw"
          >
            <FlexColumnFullWidth>
              <TextInput72Percent
                onChange={(e) => {
                  FilterProducts(allProductsInStore, e.target.value);
                }}
                placeholder="Search for a product"
              ></TextInput72Percent>
              <FlexRowContFullWidth alignitems="baseline">
                <FlexColumnFullWidth>
                  <StyledHRPropColour
                    border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                  ></StyledHRPropColour>
                  <br></br>
                  <FlexRowContFlexStart>
                    <Icon30px30pxPointer
                      onClick={() => {
                        if (parentId === 0) {
                          GetStoreCategoriesFunct(params.Id);
                          Get_SubCategory_Product(0);
                        } else {
                          GetCurrentCategories(parentId);
                          Get_SubCategory_Product(parentId);
                        }
                      }}
                      src="/leftarrow.svg"
                    ></Icon30px30pxPointer>
                    <LeagueSpartanRegular>Subcategories</LeagueSpartanRegular>
                  </FlexRowContFlexStart>
                  {storeCategories.map((o, i) => (
                    <TitleFontSize100PercentPointer
                      onClick={() => {
                        SelectCategory(o);
                        GetCurrentCategories(o?.Id);
                      }}
                    >
                      {o.Name}
                    </TitleFontSize100PercentPointer>
                  ))}
                  <br></br>
                  <StyledHRPropColour
                    border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                  ></StyledHRPropColour>
                  <br></br>

                  <FlexColumnCont></FlexColumnCont>
                </FlexColumnFullWidth>

                <FlexColumnFullWidth>
                  <StyledHRPropColour
                    border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                  ></StyledHRPropColour>
                  <br></br>
                  <FlexRowContFlexStart>
                    <LeagueSpartanRegular>Products</LeagueSpartanRegular>
                  </FlexRowContFlexStart>
                  {storeProducts.map((o, i) => (
                    <TitleFontSize100PercentPointer
                      onClick={() => {
                        SelectProduct(o);
                      }}
                    >
                      {o.Name}
                    </TitleFontSize100PercentPointer>
                  ))}

                  <FlexColumnCont></FlexColumnCont>
                </FlexColumnFullWidth>

                <FlexColumnContFullWidthOverflowY>
                  <StyledHRPropColour
                    width="95%"
                    border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                  ></StyledHRPropColour>
                  <br></br>
                  <FlexColumnFullWidth display={RFactorDisplay}>
                    <FlexColumnFullWidth display={rFactorDisplayPropCont}>
                      <BlankDiv>
                        <LeagueSpartanRegular>RFactor</LeagueSpartanRegular>
                        <Icon30px30pxPointer
                          src="/questionmark.svg"
                          onClick={() => {
                            ShowRFactorInfo(rfactorDisplayProp);
                          }}
                        ></Icon30px30pxPointer>
                      </BlankDiv>
                      <TinyTextItalics display={rfactorDisplayProp}>
                        (RFactor is the ratio of quantity of linked product to
                        primary product. Ex. If primary product quanity is 2,
                        linked product quantity is 1, then RFactor is 1/2 = 0.5.
                        RFactor is not required for labor hours)
                      </TinyTextItalics>
                      <Input
                        placeholder={"RFactor"}
                        inputwidth="80%"
                        inputheight={"5%"}
                        inputdisplay={InputDisplay}
                        onChange={(e) => {
                          setRhFactor(e.target.value);
                        }}
                      ></Input>
                    </FlexColumnFullWidth>
                    <FlexColumnFullWidth>
                      <BlankDiv>
                        <LeagueSpartanRegular>Position</LeagueSpartanRegular>
                        <Icon30px30pxPointer
                          src="/questionmark.svg"
                          onClick={() => {
                            ShowPositionInfo(positionDisplayProp);
                          }}
                        ></Icon30px30pxPointer>
                      </BlankDiv>
                      <TinyTextItalics display={positionDisplayProp}>
                        (Position is the place where the product will be
                        displayed on screen. Position 2 will be displayed after
                        position 1, for example)
                      </TinyTextItalics>
                      <Input
                        placeholder={"Position"}
                        inputwidth="80%"
                        inputheight={"5%"}
                        inputdisplay={InputDisplay}
                        onChange={(e) => {
                          setPosition(e.target.value);
                        }}
                      ></Input>
                    </FlexColumnFullWidth>
                    <RegularResponsiveButton
                      height="50px"
                      onClick={() => {
                        AddRelatedProduct(
                          ProductID,
                          ProductRelationId,
                          RhFactor,
                          Position
                        );
                      }}
                    >
                      Add {ItemName}
                    </RegularResponsiveButton>
                  </FlexColumnFullWidth>
                </FlexColumnContFullWidthOverflowY>
              </FlexRowContFullWidth>
            </FlexColumnFullWidth>
          </LeftItemWindowFixed>

          <LeftItemWindowFixed display={editItemDisplay} left="50%">
            <FlexColumnFullWidth overflowx="hidden">
              <LeagueSpartanRegular>Edit {productText}</LeagueSpartanRegular>
              <FlexColumnFullWidth>
                <BlankDiv>
                  <LeagueSpartanRegular>RFactor:</LeagueSpartanRegular>
                  <TextInput72Percent
                    key={rfactorText}
                    defaultValue={rfactorText}
                    onChange={(e) => {
                      setRFactorValue(e.target.value);
                    }}
                  ></TextInput72Percent>
                </BlankDiv>
                <BlankDiv>
                  <LeagueSpartanRegular>Position:</LeagueSpartanRegular>
                  <TextInput72Percent
                    key={positionText}
                    defaultValue={positionText}
                    onChange={(e) => {
                      setPositionValue(e.target.value);
                    }}
                  ></TextInput72Percent>
                </BlankDiv>
                <br></br>
                <FlexRowCont100x8PercentSpaceAround>
                  <RegularResponsiveButton
                    onClick={() => {
                      UpdateRFactor({ RFactorValue, PositionValue, relatedId });
                    }}
                  >
                    Save
                  </RegularResponsiveButton>
                  <RegularResponsiveButton
                    bgcolor={ColorPalette.PrimaryRed}
                    onClick={() => {
                      ResetAddUI();
                    }}
                  >
                    Close
                  </RegularResponsiveButton>
                </FlexRowCont100x8PercentSpaceAround>
                <br></br>
              </FlexColumnFullWidth>
            </FlexColumnFullWidth>
          </LeftItemWindowFixed>
        </FlexFixedCont>{" "}
      </FixedOverlay1000vh>{" "}
    </>
  );
};

ProductRelation.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  onRelationId: () => {},
  GetRelationsAgain: () => {},
  UpdateRFactor: () => {},
  UpdatePosition: () => {},
  ProductID: 0,
  ProductName: "",
  RelatedProducts: ["name"],
  StoreID: 0,
};

export default ProductRelation;
