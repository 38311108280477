import React from "react";
import styled from "styled-components";
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { StyledHRGreyBreak } from "../../StylesheetComps/Titles";

const CardContainer = styled.div`
  background-color: #ffffff;
  margin: auto; /* Center the card and add spacing between cards */
  border-radius: 12px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 90%; /* Ensure the card fits within the screen with appropriate margins */
  max-width: 600px; /* Limit the width for better readability on large screens */
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 95%; /* Adjust for smaller devices */
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;


const InfoGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.h1`
  font-family: 'Avenir', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const MobileHeader = styled.span`
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  margin-top: 4px;
`;

const StatusButton = styled.button`
  background-color: ${({ bgcolor }) => bgcolor || "#333"};
  color: #fff;
  font-family: 'San Francisco', 'Helvetica Neue', sans-serif;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.3);
  }
`;

const DetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const DetailItem = styled.p`
  font-family: 'Avenir', sans-serif;
  font-size: 14px;
  color: #555;
  margin: 0;

  strong {
    color: #333;
    font-weight: 600;
  }
`;

const getStatusDetails = (status) => {
  switch (status) {
    case CaseStatus.Drafted:
      return { text: "Draft", color: ColorPalette.DarkGrey };
    case CaseStatus.Submitted:
      return { text: "Submitted", color: ColorPalette.PrimaryRed };
    case CaseStatus.Accepted:
      return { text: "Assigned", color: ColorPalette.PrimaryButtonBlue };
    case CaseStatus.Processed:
      return { text: "Estimated", color: ColorPalette.PrimaryButtonBlue };
    case CaseStatus.Closed:
      return { text: "Closed", color: ColorPalette.DarkGrey };
    case CaseStatus.PayPending:
      return { text: "Pending", color: ColorPalette.PrimaryYellow };
    case CaseStatus.Construction:
      return { text: "In-Progress", color: ColorPalette.PrimaryOrange };
    case CaseStatus.CancelRequested:
      return { text: "Cancelling", color: ColorPalette.PrimaryRed };
    case CaseStatus.Canceled:
      return { text: "Cancelled", color: ColorPalette.PrimaryRed };
    default:
      return { text: "Draft", color: ColorPalette.DarkGrey };
  }
};

const StaffCaseCardMobile = ({
  address,
  homeowner,
  phone,
  status,
  onClick,
}) => {
  const { text: StatusText, color: StatusColor } = getStatusDetails(status);

  return (
    <CardContainer onClick={onClick}>
      <InfoGroup>
        <HeaderGroup>
          <CardHeader>{address}</CardHeader>
          <MobileHeader>{homeowner}</MobileHeader>
        </HeaderGroup>
        <StatusButton bgcolor={StatusColor}>{StatusText}</StatusButton>
      </InfoGroup>
      <DetailsGroup>
        <DetailItem>
          <strong>Phone:</strong> {phone}
        </DetailItem>
      </DetailsGroup>
      <StyledHRGreyBreak></StyledHRGreyBreak>
    </CardContainer>
  );
};

StaffCaseCardMobile.defaultProps = {
  address: "No address provided",
  homeowner: "No homeowner provided",
  phone: "No phone provided",
  status: "Draft",
};

export default StaffCaseCardMobile;
