import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GetCategory,
  GetStoreCategories,
} from "../../../API_Functions/CatProduct_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont100Percent80PercentOverflowY,
  FlexColumnCont70x100Percent,
  FlexColumnFullWidth,
  FlexColumnFullWidthMargins,
  FlexDiv,
  FlexFixedCont,
  FlexRowCont70PercentSpaceBetween,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../../StylesheetComps/Div";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  LeagueSpartanRegular,
  RegularGreenText,
  RegularText,
  StyledHRPropColour,
} from "../../../StylesheetComps/Titles";
import ConfirmWindow from "../ConfirmWIndow";

const ToolProductRelation = ({
  getInfo,
  open,
  onClose,
  GetRelationsAgain,
  onChange,
  onRelationId,
  onFormComplete,
  RelatedProductTools,
  onPreview,
  ProductName,
  ToolID,
}) => {
  const [storeNewMetro] = useState("");
  const [storeProducts, getStoreProducts] = useState([]);
  // const [storeSubCategories, getStoreSubCategories] = useState([]);
  const [storeCategories, getStoreCategories] = useState([]);
  const params = useParams();
  const [ProductRelationId, setProductRelationId] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [ItemName, setItemName] = useState("");
  const [RFactorDisplay, setRFactorDisplay] = useState("none");
  const [addItemDisplay, setAddItemDisplay] = useState("none");
  const [categoryName, setCategoryName] = useState("");

  const [deleteProductId, setDeleteProductId] = useState(0);

  const [relatedProducts, setRelatedProducts] = useState([]);

  const [deleteRelatedProduct, setDeleteRelatedProduct] = useState(false);
  const [parentId, setParentId] = useState(0);

  useEffect(() => {
    setRelatedProducts(RelatedProductTools);
  }, [RelatedProductTools]);

  var relatedProductId = 0;

  const GetStoreCategoriesFunct = async (storeid) => {
    var data = await GetStoreCategories(storeid);
    if (data != null) getStoreCategories(data.cate);
  };

  const Get_Subcategory_Products = async (catid) => {
    var cate = await GetCategory(catid);
    if (cate != null) {
      setParentId(cate.Parent_Id);
      getStoreCategories(cate.SubCategories);
      getStoreProducts(cate.Products);
    }
  };

  const HandleAddRelation = async () => {
    const resp = await axios.post(window.$DBURL + "tool/product", {
      Tool_Id: ToolID,
      Product_Id: ProductRelationId,
    });
    if (resp.status === 200) {
      GetRelationsAgain();
    }
    // setSuccessMessage("Tool-Product Relationship Created")
  };

  useEffect(() => {
    GetStoreCategoriesFunct(params.Id);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh>
        <FlexFixedCont>
          <LeftItemWindowFixed minwidth="40vw">
            <Cont100Percent80PercentOverflowY overflowx="hidden">
              <FlexDivCenter>
                <LeagueSpartanRegular>{ProductName}</LeagueSpartanRegular>
              </FlexDivCenter>

              <FlexDiv>
                <LeagueSpartanHeaderSmall>
                  Current Linked Products:
                </LeagueSpartanHeaderSmall>
              </FlexDiv>
              <RegularGreenText>{successMessage}</RegularGreenText>
              <FlexColumnFullWidth>
                <ConfirmWindow
                  open={deleteRelatedProduct}
                  rightbuttontext="Delete"
                  rightbuttoncolor={ColorPalette.PrimaryRed}
                  RightFunction={() => {
                    setDeleteRelatedProduct(false);
                    relatedProductId = deleteProductId;
                    onRelationId({ relatedProductId });
                  }}
                  LeftFunction={() => {
                    setDeleteRelatedProduct(false);
                  }}
                  leftbuttontext="Cancel"
                  leftbuttoncolor={ColorPalette.PrimaryGrey}
                  confirmtext="Are you sure you want to delete this Tool-Product Relationship?"
                ></ConfirmWindow>
                {relatedProducts.map((o, i) => (
                  <FlexDiv key={i}>
                    <RegularText>{o.Name}</RegularText>
                    <Icon30px30pxPointer
                      src="/Garbage_Icon.svg"
                      onClick={() => {
                        setDeleteRelatedProduct(true);
                        setDeleteProductId(o.Id);
                      }}
                    ></Icon30px30pxPointer>
                  </FlexDiv>
                ))}
              </FlexColumnFullWidth>
              <FlexColumnFullWidthMargins>
                <FlexRowCont70PercentSpaceBetween
                  onClick={() => {
                    onPreview({ storeNewMetro });
                  }}
                >
                  <RegularResponsiveButton
                    onClick={() => {
                      setAddItemDisplay("flex");
                    }}
                  >
                    Link New Product
                  </RegularResponsiveButton>
                  <RegularResponsiveButton
                    bgcolor={ColorPalette.PrimaryRed}
                    onClick={() => {
                      onFormComplete(storeNewMetro);
                      onClose();
                      setSuccessMessage("");
                      getStoreProducts([]);
                      setRelatedProducts([]);
                      setRFactorDisplay("none");
                      setAddItemDisplay("none");
                      setCategoryName("");
                    }}
                  >
                    Close
                  </RegularResponsiveButton>
                </FlexRowCont70PercentSpaceBetween>
              </FlexColumnFullWidthMargins>
              <br></br>
            </Cont100Percent80PercentOverflowY>
          </LeftItemWindowFixed>

          <LeftItemWindowFixed
            minwidth="45vw"
            left="48vw"
            display={addItemDisplay}
          >
            <FlexColumnFullWidth>
              {/* <TextInput72Percent
                onChange={(e) => {
                  // FilterTools(allToolsInStore, e.target.value);
                }}
                placeholder="Search for a Product"
              ></TextInput72Percent> */}
              <FlexRowContFullWidth alignitems="baseline">
                <FlexColumnFullWidth height="auto" overflowy="auto">
                  <FlexRowContFlexStart height="auto">
                    <Icon30px30pxPointer
                      onClick={() => {
                        if (parentId === 0) {
                          GetStoreCategoriesFunct(params.Id);
                          setCategoryName("");
                          getStoreProducts([]);
                        } else {
                          Get_Subcategory_Products(parentId);
                        }
                      }}
                      src="/leftarrow.svg"
                    ></Icon30px30pxPointer>
                    <LeagueSpartanHeaderSmall>
                      {categoryName} Subcategories
                    </LeagueSpartanHeaderSmall>
                  </FlexRowContFlexStart>
                  {storeCategories.map((o, i) => (
                    <RegularText
                      key={i}
                      cursor="pointer"
                      fontsize="16px"
                      onClick={() => {
                        Get_Subcategory_Products(o.Id);
                        setCategoryName(o.Name);
                      }}
                    >
                      {o.Name}
                    </RegularText>
                  ))}
                </FlexColumnFullWidth>
                <FlexColumnFullWidth>
                  <FlexColumnFullWidth alignitems="baseline">
                    <FlexRowContFlexStart height="auto">
                      <LeagueSpartanHeaderSmall>
                        {categoryName} Products
                      </LeagueSpartanHeaderSmall>
                    </FlexRowContFlexStart>
                    {storeProducts.map((o, i) => (
                      <div key={i}>
                        <RegularText
                          cursor="pointer"
                          fontsize="16px"
                          onClick={() => {
                            setProductRelationId(o.Id);
                            setRFactorDisplay("block");
                            setItemName(o.Name);
                          }}
                        >
                          {o.Name}
                        </RegularText>
                        <StyledHRPropColour
                          border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
                        ></StyledHRPropColour>
                      </div>
                    ))}
                  </FlexColumnFullWidth>
                </FlexColumnFullWidth>
                <FlexColumnFullWidth>
                  <FlexColumnCont70x100Percent display={RFactorDisplay}>
                    <RegularResponsiveButton
                      width="150px"
                      onClick={() => {
                        HandleAddRelation();
                        getStoreProducts([]);
                        setRFactorDisplay("none");
                        setAddItemDisplay("none");
                        setCategoryName("");
                      }}
                    >
                      Add {ItemName}
                    </RegularResponsiveButton>
                  </FlexColumnCont70x100Percent>
                </FlexColumnFullWidth>
              </FlexRowContFullWidth>
            </FlexColumnFullWidth>
          </LeftItemWindowFixed>
        </FlexFixedCont>{" "}
      </FixedOverlay1000vh>{" "}
    </>
  );
};

ToolProductRelation.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  onRelationId: () => {},
  GetRelationsAgain: () => {},
  ToolID: 0,
  ProductName: "",
  RelatedProductTools: ["name"],
  StoreID: 0,
};

export default ToolProductRelation;
