import React from "react";
import QuoteTableToolItems from "../QuoteTableToolItems";
import QuoteTableTops from "../QuoteTableTops";
import { Cont100PercentMobile100Percent } from "../../StylesheetComps/Cont";
import {
  QuadContainer,
  QuoteQuadHead,
  QuoteQuadHeadMedium,
  QuoteQuadHeaderBorderTopLeft,
  QuoteQuadHeaderBorderTopRight,
} from "../../StylesheetComps/Div";
import { SmallText } from "../../StylesheetComps/Titles";

const QuoteTableTools = ({
  quoteItems,
  quoteTotalsArray,
  subtotal,
  total,
  onItemChange,
}) => {
  return (
    <Cont100PercentMobile100Percent>
      <QuadContainer>
        <QuoteQuadHeaderBorderTopLeft>
          <SmallText>Name</SmallText>
        </QuoteQuadHeaderBorderTopLeft>

        <QuoteQuadHead>
          <SmallText>Quantity</SmallText>
        </QuoteQuadHead>

        <QuoteQuadHeadMedium>
          <SmallText>Rent Hours</SmallText>
        </QuoteQuadHeadMedium>

        <QuoteQuadHeadMedium>
          <SmallText>Price</SmallText>
        </QuoteQuadHeadMedium>

        <QuoteQuadHeaderBorderTopRight>
          <SmallText>Remove Item</SmallText>
        </QuoteQuadHeaderBorderTopRight>
      </QuadContainer>
      {quoteItems.map((o, i) => (
        <QuoteTableToolItems
          noChange={o.NoChange}
          key={i}
          toolobj={o}
          onItemChange={onItemChange}
          itemId={o.Id}
        ></QuoteTableToolItems>
      ))}

      <QuoteTableTops
        subtotal={subtotal}
        quoteTax={quoteTotalsArray}
        total={total}
      ></QuoteTableTops>
    </Cont100PercentMobile100Percent>
  );
};

QuoteTableTools.defaultProps = {
  quoteItems: ["name"],
  quoteTotalsArray: ["name"],
  subtotal: "",
  total: "",
  onItemChange: () => {},
};

export default QuoteTableTools;
