import axios from "axios";
import { useEffect, useState } from "react";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import { useHistory } from "react-router-dom";
import { ColorPalette } from "../../../StylesheetComps/Colors";

import { TopDivWhite } from "../../../MobileComponents/Divs";
import {
  MobileLogo30x30px,
  MobileLogo50x50px,
} from "../../../MobileComponents/Images";
import { MenuHeader } from "../../../MobileComponents/Text";
import { Header } from "../../../StylesheetComps/AuthSheet";
import {
  Cont82vw21vh,
  RelativeFlexContAutoWidth13vh,
} from "../../../StylesheetComps/Cont";
import { MenuMainCont, MobileMainCont } from "../../../StylesheetComps/Div";
import { Image60x50Percent } from "../../../StylesheetComps/Imgs/Images";
import {
  FlexColumnCont,
  SmallHeader,
  TopMenuCont,
} from "../../../StylesheetComps/Menu";
import MobileSlideMenu from "../../MobileSlideMenu";

const ActionMenu = ({
  pageProp,
  hackmyyardbottom,
  dashboardbottom,
  projectsbottom,
  calendarbottom,
  billingbottom,
  mobilePageProp,
}) => {
  const history = useHistory();
  const [moreToggle, setMoreToggle] = useState(false);

  const [metroId, setMetroId] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const [menuSRC, setMenuSRC] = useState("/hamburger.svg");

  const CheckStoreEstimates = async (metroId, storeId) => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + metroId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          if (storeId === 0) {
            if (resp.data.stores.length !== 1) {
              history.push("/storelistestimate/" + metroId);
            } else {
              history.push(
                "/estimate/" + metroId + "/" + resp.data.stores[0].Id
              );
            }
          } else {
            history.push("/estimate/" + metroId + "/" + storeId);
          }
        }
      }
    }
  };

  const ChangeIcon = async () => {
    if (menuSRC === "/hamburger.svg") {
      setMenuSRC("/CloseMobile.svg");
    } else {
      setMenuSRC("/hamburger.svg");
    }
  };

  useEffect(() => {
    var user = JSON.parse(sessionStorage.getItem("userInfo"));
    setMetroId(user?.Metro_Id);
    setStoreId(user?.Store_Id);
  }, []);

  if (window.$ISMOBILE === true) {
    return (
      <div>
        <TopDivWhite>
          <MobileLogo50x50px
            onClick={() => {
              history.push("/home");
            }}
            src="/NRCLogoRegularBlack.png"
          ></MobileLogo50x50px>
          <MenuHeader>{mobilePageProp}</MenuHeader>
          <MobileLogo30x30px
            src={menuSRC}
            onClick={() => {
              setMoreToggle(!moreToggle);
              ChangeIcon(menuSRC);
            }}
          ></MobileLogo30x30px>
        </TopDivWhite>
        <MobileSlideMenu
          open={moreToggle}
          onCloseMenu={() => {
            setMoreToggle(false);
          }}
        ></MobileSlideMenu>
      </div>
    );
  } else {
    return (
      <div>
        <Cont82vw21vh>
          <MobileMainCont>
            <RelativeFlexContAutoWidth13vh>
              <Image60x50Percent
                src={"/mobileprofile.svg"}
                onClick={() => {
                  history.push("/profile");
                }}
              ></Image60x50Percent>

              <AddToHomeScreen />
            </RelativeFlexContAutoWidth13vh>
          </MobileMainCont>
        </Cont82vw21vh>

        <MenuMainCont></MenuMainCont>
        <TopMenuCont height='15vh' mobiledisplay="none">
          <FlexColumnCont
            marginbottom="0%"
            margin="0"
            width="100%"
            height="19%"
          ></FlexColumnCont>

          <FlexColumnCont
            margin="0px"
            flexdirection="row"
            justifycontent="flex-start"
            width="100%"
            height="24%"
            alignitems="left"
          >
            <Header
              fontsize="160%"
              marginleft="2vw"
              margintop="3%"
              marginbottom="0%"
            >
              {pageProp}
            </Header>
          </FlexColumnCont>

          {/* <FlexColumnCont
            margin="10px"
            width="100%"
            height="15%"
            margintop="2%"
            flexdirection="row"
            justifycontent="flex-start"
            alignitems="left"
          >
            <FlexColumnCont
              height="100%"
              width="100%"
              margin="0"
              marginleft="1vw"
              justifycontent="flex-start"
              alignitems="left"
              flexdirection="row"
            ></FlexColumnCont>
          </FlexColumnCont> */}

          {/* <FlexColumnCont
            margin="0"
            width="100%"
            height="20%"
            flexdirection="row"
            justifycontent="flex-start"
            alignitems="left"
          >
            <FlexColumnCont
              height="100%"
              margin="0"
              justifycontent="flex-start"
              alignitems="left"
              flexdirection="row"
              marginleft="2%"
            >
              <SmallHeader
                height="15px"
                fontsize="16px"
                paddingbottom="15px"
                borderbottom={hackmyyardbottom}
                cursor="pointer"
                hovercolor={ColorPalette.PrimaryGrey}
                margin="8px"
                marginright="4%"
                onClick={() => {
                  CheckStoreEstimates(metroId, storeId);
                }}
              >
                New Project
              </SmallHeader>
              <SmallHeader
                height="15px"
                cursor="pointer"
                paddingbottom="15px"
                borderbottom={dashboardbottom}
                fontsize="16px"
                hovercolor={ColorPalette.PrimaryGrey}
                margin="8px"
                marginright="4%"
                onClick={() => {
                  history.push("/home");
                }}
              >
                Home
              </SmallHeader>
              <SmallHeader
                height="15px"
                cursor="pointer"
                paddingbottom="15px"
                borderbottom={projectsbottom}
                fontsize="16px"
                hovercolor={ColorPalette.PrimaryGrey}
                margin="8px"
                marginright="4%"
                onClick={() => {
                  history.push("/CasesPersonal/" + metroId + "/" + storeId);
                }}
              >
                Projects
              </SmallHeader>
         
              <SmallHeader
                height="15px"
                cursor="pointer"
                paddingbottom="15px"
                borderbottom={billingbottom}
                fontsize="16px"
                hovercolor={ColorPalette.PrimaryGrey}
                margin="8px"
                marginright="4%"
                onClick={() => {
                  history.push("/billing");
                }}
              >
                Billing
              </SmallHeader>
            </FlexColumnCont>
          </FlexColumnCont> */}
        </TopMenuCont>
      </div>
    );
  }
};

ActionMenu.defaultProps = {
  pageProp: "",
};

export default ActionMenu;
