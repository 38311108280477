import React, { useState } from "react";
import {
  FlexColumnCont250pxGreenBorderTopRounded,
  FlexColumnCont90x95Percent,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";

import {
  SmallHeaderLeagueSpartan,
  GreyNavigationButton,
  GreenNavigationButton,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { LinkText } from "../../../StylesheetComps/AuthSheet";
import { Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const SaveProjectWindow = ({
  saveOpen,
  onSaveCase,
  SaveCase_ChangeStatusToSubmit,
  onClose,
}) => {
  const [submitted, setSubmitted] = useState(false);

  if (!saveOpen) return null;
  if (submitted !== true) {
    return (
      <FixedOverlay200vh>
        <FlexColumnCont250pxGreenBorderTopRounded>
          <FlexRowCont
            height="100%"
            justifycontent="flex-start"
            alignitems="baseline"
          >
            <FlexColumnCont90x95Percent>
              <SmallHeaderLeagueSpartan>
                Ready to Submit Your Project?
              </SmallHeaderLeagueSpartan>
              <SmallTextPadding>
                Make sure to review all of your information and confirm the
                details. Once you press submit, our team will be alerted and get
                started right away. You will receive a confirmation email and
                the project proposal within 1-2 days*
              </SmallTextPadding>

              <FlexRowCont justifycontent="flex-end">
                <LinkText
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </LinkText>
                <GreyNavigationButton
                  width="50%"
                  onClick={() => {
                    setSubmitted(true);
                    onSaveCase();
                  }}
                >
                  Draft
                </GreyNavigationButton>
                <GreenNavigationButton
                  width="50%"
                  onClick={() => {
                    setSubmitted(true);
                    SaveCase_ChangeStatusToSubmit();
                  }}
                >
                  Submit
                </GreenNavigationButton>
              </FlexRowCont>
            </FlexColumnCont90x95Percent>
            <Icon20x20
              onClick={() => {
                onClose();
              }}
              src="/Close.svg"
            ></Icon20x20>
          </FlexRowCont>
        </FlexColumnCont250pxGreenBorderTopRounded>
      </FixedOverlay200vh>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

SaveProjectWindow.defaultProps = {
  SaveCase: () => {},
  SaveCase_ChangeStatusToSubmit: () => {},
};

export default SaveProjectWindow;
