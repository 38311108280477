import styled from "styled-components";

const nullProp = null;

export const MobileInput = styled.input`
  display: ${(props) => (props.display ? props.display : "")};
  font-family: Avenir;
  font-size: 12px;
  width: 300px;
  border-radius: 4px;
  background-color: #ededed;
  border-style: none;
  margin: 2px;
  margin-left: 5px;
  padding: 10px;
`;

export default nullProp;
