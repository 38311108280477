import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetToolKit } from "../../../API_Functions/Tool_Functions";
import { FlexDiv, GridCont1000px } from "../../../StylesheetComps/Cont";
import { Icon30x40pxPointer } from "../../../StylesheetComps/Imgs/Icons";
import { StickyOverlayDiv160vwAutoHeight } from "../../../StylesheetComps/OverlayCont";
import { RegularTextBoldRed } from "../../../StylesheetComps/Titles";
import ToolCardQuote from "../../ToolCardQuote";

const ToolSelectionCard = ({ open, onClose, onPreview }) => {
  const params = useParams();

  const [storeToolKits, getStoreToolKits] = useState([]);
  const [storeSubToolKits, setStoreSubToolKits] = useState([]);
  const [storeTools, setStoreTools] = useState([]);
  const [parentId, setParentId] = useState(0);

  const [textDisplay, setTextDisplay] = useState("none");
  const [backDisplay, setBackDisplay] = useState("none");

  var ToolId = 0;

  const GetStoreToolKits = async (storeid) => {
    const resp = await axios.get(window.$DBURL + "tool/kit/store/" + storeid);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.toolkit !== null) {
          getStoreToolKits(resp.data.toolkit);
        }
      }
    }
  };

  const GetStoreToolsFunction = async (toolkitid) => {
    var data = await GetToolKit(toolkitid);
    if (data !== null) {
      setStoreSubToolKits(data.SubKits);
      setStoreTools(data.Tools);
      if (data.Parent_Id !== null) {
        setParentId(data.Parent_Id);
      }
    }
  };

  useEffect(() => {
    if (open === true) {
      GetStoreToolKits(params.storeId);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open === true) {
      if (storeToolKits.length === 0 && storeTools.length === 0) {
        setTextDisplay("block");
      } else {
        setTextDisplay("none");
      }
    }
  }, [storeTools, storeToolKits, open]);

  if (!open) return null;
  return (
    <StickyOverlayDiv160vwAutoHeight>
      <FlexDiv>
        <Icon30x40pxPointer
          displayProps={backDisplay}
          src={"/whiteleftarrow.svg"}
          onClick={() => {
            if (parentId === 0) {
              GetStoreToolKits();
              setBackDisplay("none");
              setStoreSubToolKits([]);
              setStoreTools([]);
            } else {
              GetStoreToolsFunction(parentId);
            }
          }}
        ></Icon30x40pxPointer>
      </FlexDiv>
      <GridCont1000px>
        {storeToolKits.map((o, i) => (
          <ToolCardQuote
            key={i}
            ToolId={o.Id}
            ToolHeadText={o.Name + " Kit"}
            ToolUrl={o.VideoUrl}
            onView={() => {
              getStoreToolKits([]);
              GetStoreToolsFunction(o.Id);
              setBackDisplay("block");
              setParentId(o.Parent_Id);
            }}
          ></ToolCardQuote>
        ))}
        {storeSubToolKits.map((o, i) => (
          <ToolCardQuote
            key={i}
            ToolId={o.Id}
            ToolHeadText={o.Name + " SubKit"}
            ToolUrl={o.VideoUrl}
            onView={() => {
              GetStoreToolsFunction(o.Id);
              setBackDisplay("block");
            }}
          ></ToolCardQuote>
        ))}
        <RegularTextBoldRed display={textDisplay}>
          No Tools in this Category
        </RegularTextBoldRed>
        {storeTools.map((o, i) => (
          <ToolCardQuote
            key={i}
            ToolId={o.Id}
            ToolHeadText={o.Name}
            ToolUrl={o.VideoUrl}
            onView={() => {
              ToolId = o.Id;
              onPreview({ ToolId });
              onClose();
              getStoreToolKits([]);
              setStoreTools([]);
            }}
          ></ToolCardQuote>
        ))}
      </GridCont1000px>
      <FlexDiv
        onClick={() => {
          onClose();
          getStoreToolKits([]);
          setStoreSubToolKits([]);
          setStoreTools([]);
          setTextDisplay("none");
          setBackDisplay("none");
        }}
      >
        <Icon30x40pxPointer
          src={"/Close2.svg"}
          onClick={() => {
            GetStoreToolKits(params.storeId);
            setTextDisplay("none");
          }}
        ></Icon30x40pxPointer>
      </FlexDiv>
    </StickyOverlayDiv160vwAutoHeight>
  );
};

ToolSelectionCard.defaultProps = {
  onPreview: () => {},
};

export default ToolSelectionCard;
