import { useEffect, useState } from "react";
import moment from "moment";
import {
  Cont50Percent100Percent,
  Cont50Percent100PercentFlexEnd,
  FlexColumnContGreenBorders,
  FlexColumnContGreyBorders,
  FlexColumnFullWidth,
  FlexRowCont,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import {
  Image200x200pxContain,
  InboxProfileImage,
} from "../../StylesheetComps/Imgs/Images";
import {
  LeagueSpartanRegular,
  MediumHeaderDarkGrey,
  RegularTextAutoMargin,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
const ProposalCommentCard = ({
  commentObj,
  estimatorId,
  UpdateProjectUI,
  estimatorName,
  estimatorPhoto,
  userinfo,
  homeownerName,
  OpenPicture,
}) => {
  const [commentPosterName, setCommentPosterName] = useState("");
  const [convertedDateString, setConvertedDateString] = useState("");

  // const [markAsReadDisplay, setMarkAsReadDisplay] = useState("");

  // const [readIcon, setReadIcon] = useState("/Grey_Checkmark_Icon.svg");

  const UpdateNameBasedOnComment = async (commentobj, estimatorid) => {
    switch (commentobj.User_Id) {
      case estimatorid:
        setCommentPosterName(estimatorName);
        break;
      default:
        setCommentPosterName("Homeowner");
        break;
    }
  };

  useEffect(() => {
    setConvertedDateString(moment(commentObj?.AddTime).format("LLL"));
  }, [commentObj.AddTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    UpdateNameBasedOnComment(commentObj, estimatorId);
  }, [commentObj]); // eslint-disable-line react-hooks/exhaustive-deps
  if (window.$ISMOBILE === true) {
    if (commentObj !== null) {
      if (commentObj.Comment !== null) {
        if (commentObj.User_Id === estimatorId) {
          return (
            <FlexRowContFullWidth>
              <FlexRowCont>
                <InboxProfileImage
                  src={window.$IMGURL + estimatorPhoto}
                ></InboxProfileImage>
                <FlexColumnFullWidth>
                  <TinyTextItalics marginleft="10px" marginbottom="0px">
                    {" "}
                    {commentPosterName}
                  </TinyTextItalics>
                  <FlexColumnContGreyBorders>
                    <FlexRowCont height="auto">
                      <Cont50Percent100Percent>
                        <FlexRowContFlexStart></FlexRowContFlexStart>
                      </Cont50Percent100Percent>

                      <Cont50Percent100PercentFlexEnd>
                        <FlexRowCont
                          justifycontent="flex-end"
                          height="auto"
                        ></FlexRowCont>
                      </Cont50Percent100PercentFlexEnd>
                    </FlexRowCont>

                    <FlexColumnFullWidth>
                      <RegularTextAutoMargin>
                        {commentObj.Comment}
                      </RegularTextAutoMargin>
                      <Image200x200pxContain
                        cursor={"pointer"}
                        onClick={() => {
                          OpenPicture();
                        }}
                        display={
                          commentObj.File === null || commentObj.File === ""
                            ? "none"
                            : "block"
                        }
                        src={window.$IMGURL + commentObj?.File}
                      ></Image200x200pxContain>
                    </FlexColumnFullWidth>
                  </FlexColumnContGreyBorders>
                </FlexColumnFullWidth>
              </FlexRowCont>
              <TinyTextItalics marginleft="0px" margintop="0px">
                {" "}
                {convertedDateString}
              </TinyTextItalics>
            </FlexRowContFullWidth>
          );
        } else {
          return (
            <FlexRowContFullWidth
              marginleft={userinfo.Image === estimatorPhoto ? "5%" : "4%"}
            >
              <FlexRowCont>
                <FlexColumnFullWidth>
                  <TinyTextItalics marginleft="10px" marginbottom="0px">
                    {" "}
                    {userinfo.Name}
                  </TinyTextItalics>
                  <FlexColumnContGreenBorders>
                    <FlexColumnFullWidth>
                      <RegularTextAutoMargin>
                        {commentObj.Comment}
                      </RegularTextAutoMargin>
                      <Image200x200pxContain
                        cursor={"pointer"}
                        onClick={() => {
                          OpenPicture();
                        }}
                        display={
                          commentObj.File === null || commentObj.File === ""
                            ? "none"
                            : "block"
                        }
                        src={window.$IMGURL + commentObj?.File}
                      ></Image200x200pxContain>
                    </FlexColumnFullWidth>
                  </FlexColumnContGreenBorders>
                  <TinyTextItalics marginleft="25vw" margintop="0px">
                    {" "}
                    {convertedDateString}
                  </TinyTextItalics>
                </FlexColumnFullWidth>
                <InboxProfileImage
                  display={userinfo.Image === estimatorPhoto ? "none" : "block"}
                  src={
                    userinfo.Image === estimatorPhoto
                      ? "/NRCLogoRegularBlack.png"
                      : window.$IMGURL + userinfo.Image
                  }
                ></InboxProfileImage>
              </FlexRowCont>
            </FlexRowContFullWidth>
          );
        }
      }
    }
  } else {
    if (commentObj !== null) {
      if (commentObj.Comment !== null) {
        if (commentObj.User_Id === estimatorId) {
          return (
            <FlexRowContFullWidth width="85%">
              {/* <InboxProfileImage
                src={window.$IMGURL + estimatorPhoto}
              ></InboxProfileImage> */}
              <FlexColumnContGreyBorders>
                <FlexRowCont height="auto">
                  <Cont50Percent100Percent>
                    <FlexRowContFlexStart>
                      <LeagueSpartanRegular>
                        {commentPosterName}
                      </LeagueSpartanRegular>
                      <TinyTextItalics>New Rhodes Construction Guide</TinyTextItalics>
                    </FlexRowContFlexStart>
                  </Cont50Percent100Percent>

                  <Cont50Percent100PercentFlexEnd>
                    <FlexRowCont justifycontent="flex-end" height="auto">
                      <MediumHeaderDarkGrey>
                        {convertedDateString}
                      </MediumHeaderDarkGrey>
                    </FlexRowCont>
                  </Cont50Percent100PercentFlexEnd>
                </FlexRowCont>

                <FlexColumnFullWidth>
                  <RegularTextAutoMargin>
                    {commentObj.Comment}
                  </RegularTextAutoMargin>
                  <Image200x200pxContain
                    cursor={"pointer"}
                    onClick={() => {
                      OpenPicture();
                    }}
                    display={
                      commentObj.File === null || commentObj.File === ""
                        ? "none"
                        : "block"
                    }
                    src={window.$IMGURL + commentObj?.File}
                  ></Image200x200pxContain>
                </FlexColumnFullWidth>
              </FlexColumnContGreyBorders>
            </FlexRowContFullWidth>
          );
        } else {
          return (
            <FlexRowContFullWidth
              width="85%"
              marginleft={userinfo.Image === estimatorPhoto ? "25%" : "15%"}
            >
              <FlexColumnContGreenBorders>
                <FlexRowCont height="auto" width="90%">
                  <Cont50Percent100Percent>
                    <FlexRowCont justifycontent="flex-start" height="auto">
                      <MediumHeaderDarkGrey>
                        {convertedDateString}
                      </MediumHeaderDarkGrey>
                    </FlexRowCont>
                  </Cont50Percent100Percent>

                  <Cont50Percent100PercentFlexEnd>
                    <FlexRowCont justifycontent="flex-end" height="auto">
                      <LeagueSpartanRegular>
                        {homeownerName}
                      </LeagueSpartanRegular>
                      <TinyTextItalics>{commentPosterName}</TinyTextItalics>
                    </FlexRowCont>
                  </Cont50Percent100PercentFlexEnd>
                </FlexRowCont>

                <FlexColumnFullWidth width="90%">
                  <RegularTextAutoMargin>
                    {commentObj.Comment}
                  </RegularTextAutoMargin>
                  <Image200x200pxContain
                    cursor={"pointer"}
                    onClick={() => {
                      OpenPicture();
                    }}
                    display={
                      commentObj.File === null || commentObj.File === ""
                        ? "none"
                        : "block"
                    }
                    src={window.$IMGURL + commentObj?.File}
                  ></Image200x200pxContain>
                </FlexColumnFullWidth>
              </FlexColumnContGreenBorders>
              {/* <InboxProfileImage
                display={userinfo.Image === estimatorPhoto ? "none" : "block"}
                src={
                  userinfo.Image === estimatorPhoto
                    ? "/NRCLogoRegularBlack.png"
                    : window.$IMGURL + userinfo.Image
                }
              ></InboxProfileImage> */}
            </FlexRowContFullWidth>
          );
        }
      }
    }
  }
};

ProposalCommentCard.defaultProps = {
  UpdateProjectUI: () => {},
  OpenPicture: () => {},
};

export default ProposalCommentCard;
