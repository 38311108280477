import styled from "styled-components";

const nullProp = null;

export const RightViewFullWidth = styled.div`
  width: 100vw;
  height: ${(props) => (props.height ? props.height : "100vh")};
  @media (max-width: 1006px) {
    width: 100vw;
    margin: 0%;
    height: 0vh;
    background-color: white;
  }
`;

export const RightView_FlexFullWidth_Column = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media (max-width: 1006px) {
    width: 100vw;
    margin: 0%;
    height: 0vh;
    background-color: white;
  }
`;

export default nullProp;
