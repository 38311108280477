//Images for Mobile View Only
import styled from "styled-components";

const nullProp = null;

export const MobileLogo30x30px = styled.img`
  width: 30px;
  height: 30px;
`;

export const MobileLogo50x50px = styled.img`
  width: 50px;
  height: 50px;
`;

export const TopImage15vh = styled.img`
  width: 100vw;
  height: 15vh;
  object-fit: cover;
  transition: height 0.2s;
  transition-timing-function: linear;
`;

export const TopImage20vh = styled.img`
  width: 100vw;
  height: 20vh;
  object-fit: cover;
  transition: height 0.2s;
  transition-timing-function: linear;
`;

export const TopImage40vh = styled.img`
  width: 100vw;
  height: 40vh;
  object-fit: cover;
  transition: height 0.2s;
  transition-timing-function: linear;
`;

export const LogoRelative = styled.img`
  width: 220px;
  height: 230px;
  position: relative;
  left: 24%;
  bottom: 52%;
  transition: height 0.2s;
  transition-timing-function: linear;
`;

export const LogoRelativeLeft = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  transition: height 0.2s;
  transition-timing-function: linear;
`;

export default nullProp;
