import { React } from "react";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnFullWidth,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanHeaderSmall,
  LeagueSpartanTinyMargins,
  RegularTextAutoMargin,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";

const QuoteTableTops = ({ cateType, subtotal, tax, total, quoteTax }) => {
  var DisplayNotes = "none";

  var Total = total;
  var Labor_total_notes = "Minimum labor may apply"; // "Minimum labor charge is $" + Labor_Min;

  if (cateType === CategoryTypes.Labor) {
    DisplayNotes = "block";
  }

  return (
    <div>
      <FlexRowContFullWidth>
        <FlexColumnFullWidth>
          <LeagueSpartanTinyMargins display={DisplayNotes}>
            {Labor_total_notes}{" "}
          </LeagueSpartanTinyMargins>
        </FlexColumnFullWidth>
        <FlexColumnFullWidth width="40%">
          <FlexRowCont100PercentSpaceBetween>
            <FlexColumnFullWidth textalign="left">
              <LeagueSpartanHeaderSmall>SUBTOTAL</LeagueSpartanHeaderSmall>
              {quoteTax?.map((o, i) => (
                <LeagueSpartanHeaderSmall key={i}>
                  {o.TaxCode + ":"}
                </LeagueSpartanHeaderSmall>
              ))}
              <LeagueSpartanHeaderSmall>TOTAL</LeagueSpartanHeaderSmall>
            </FlexColumnFullWidth>

            <FlexColumnFullWidth textalign="right">
              <RegularTextAutoMargin>
                {"$" +
                  subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </RegularTextAutoMargin>
              {quoteTax?.map((o, i) => (
                <RegularTextAutoMargin key={i}>
                  {"$" + o.Amount?.toFixed(2)}
                </RegularTextAutoMargin>
              ))}
              <RegularTextAutoMargin>
                {"$" + Total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </RegularTextAutoMargin>
            </FlexColumnFullWidth>
          </FlexRowCont100PercentSpaceBetween>
        </FlexColumnFullWidth>
      </FlexRowContFullWidth>
      <StyledHRPropColour
        border={`0.5px solid ${ColorPalette.TertiaryGrey}`}
      ></StyledHRPropColour>
    </div>
  );
};

QuoteTableTops.defaultProps = {
  cateType: 0,
  subtotal: "$0.00",
  quoteTax: ["name"],
  tax: "$0.00",
  total: "$0.00",
};

export default QuoteTableTops;
