import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Input from "../../comps/Input";
import { TextInput300x50 } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  Cont100Percent80PercentOverflowY,
  FlexColumnFullWidth,
  FlexDiv,
  FlexFixedCont,
  FlexRowCont,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
  LeftItemWindowFixed,
} from "../../StylesheetComps/Cont";
import { BlankDiv, FlexDivCenter } from "../../StylesheetComps/Div";
import { Icon20x20 } from "../../StylesheetComps/Imgs/Icons";
import { TextInput72Percent } from "../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { FixedOverlay1000vh } from "../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanRegular,
  RegularText,
  SmallText,
} from "../../StylesheetComps/Titles";

const ProductToolRelation = ({
  UpdateRelatedTool,
  open,
  onClose,
  GetRelationsAgain,
  onRelationId,
  RelatedTools,
  ProductName,
  ProductID,
}) => {
  const [storeToolkits, setStoreToolkits] = useState([]);
  // const [storeSubToolkits, setStoreSubToolkits] = useState([]);
  const [storeTools, setStoreTools] = useState([]);
  const [allToolsInStore, setAllToolsInStore] = useState([]);
  const params = useParams();
  const [ProductRelationId, setProductRelationId] = useState(0);
  const [ItemName, setItemName] = useState("");
  const [subToolKitHeader, setSubToolKitHeader] = useState("SubToolKit:");
  const [RFactorDisplay, setRFactorDisplay] = useState("none");
  const [addItemDisplay, setAddItemDisplay] = useState("none");
  // const [iconSrc, setIconSrc] = useState("/leftarrow.svg");
  const [categoryName, setCategoryName] = useState("");

  //EDIT RFACTOR
  const [editTriggerInputDisplay, setEditTriggerInputDisplay] =
    useState("none");
  const [editTriggerLabelDisplay, setEditTriggerLabelDisplay] =
    useState("flex");
  const [editTriggerId, setEditTriggerId] = useState(0);
  const [triggerValue, setTriggerValue] = useState(0);
  const [isNewToolDeliveryChecked, setIsNewToolDeliveryChecked] =
    useState(false);

  const [triggerDisplayProp, setTriggerDisplayProp] = useState("none");
  const [toolsDisplayProp, setToolsDisplayProp] = useState("none");

  const [parentId, setParentId] = useState(0);

  var relatedProductId = 0;

  const GetStoreToolKitsFunc = async (store_id) => {
    var resp = await axios.get(window.$DBURL + "tool/kit/store/" + store_id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        setStoreToolkits(resp.data.toolkit);
      }
    }
  };

  const GetStoreTools = async (store_id) => {
    var resp = await axios.get(window.$DBURL + "store/" + store_id + "/tools");
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.tools !== null) {
          setAllToolsInStore(resp.data.tools);
        }
      }
    }
  };

  const GetToolsOfKit = async (kitId) => {
    var resp = await axios.get(window.$DBURL + "tool/kit/" + kitId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.toolkit !== null) {
          setCategoryName(resp.data.toolkit.Name);
          setParentId(resp.data.toolkit.Parent_Id);
          if (resp.data.toolkit.Tools !== null) {
            if (resp.data.toolkit.Tools.length > 0) {
              setStoreTools(resp.data.toolkit.Tools);
            }
          }
          if (resp.data.toolkit.SubKits !== null) {
            setSubToolKitHeader(resp.data.toolkit.Name);
            setStoreToolkits(resp.data.toolkit.SubKits);
          }
        }
      }
    }
  };

  const HandleAddRelation = async () => {
    var resp = await axios.post(window.$DBURL + "tool/prodtool", {
      Product_Id: ProductID,
      Tool_Id: ProductRelationId,
      Trigger: triggerValue,
      ListAsDelivery: isNewToolDeliveryChecked,
    });
    if (resp.status === 200) {
      GetRelationsAgain();
      setIsNewToolDeliveryChecked(false);
    }
  };

  const FilterTools = (toollist, text) => {
    if (toolsDisplayProp === "none") {
      setToolsDisplayProp("flex");
    }
    setCategoryName("Search Results:");
    setStoreTools(
      toollist.filter((o) => {
        return o.Name.toLowerCase().includes(text.toLowerCase());
      })
    );
  };

  useEffect(() => {
    GetStoreTools(params.Id);
    GetStoreToolKitsFunc(params.Id);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const UpdateCheckbox = async (
    o,
    i,
    checkboxCheck,
    triggerValue,
    editTriggerId
  ) => {
    if (checkboxCheck === true) {
      checkboxCheck = true;
      UpdateRelatedTool({ triggerValue, editTriggerId, checkboxCheck });
    } else {
      checkboxCheck = false;
      UpdateRelatedTool({ triggerValue, editTriggerId, checkboxCheck });
    }
  };

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh>
        <FlexFixedCont>
          <LeftItemWindowFixed minwidth="40vw">
            <Cont100Percent80PercentOverflowY overflowx="hidden">
              <FlexDivCenter>
                <LeagueSpartanRegular>{ProductName}</LeagueSpartanRegular>
              </FlexDivCenter>

              <FlexDiv>
                <LeagueSpartanRegular>
                  Current Linked Tools:
                </LeagueSpartanRegular>
              </FlexDiv>

              <FlexDiv>
                <FlexColumnCont
                  borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                  flexdirection="row"
                  justifycontent="flex-start"
                >
                  <FlexColumnCont
                    width="36%"
                    flexdirection="row"
                    justifycontent="flex-start"
                  >
                    <SmallHeader>Name:</SmallHeader>
                  </FlexColumnCont>
                  <FlexColumnCont
                    width="40%"
                    flexdirection="row"
                    justifycontent="flex-start"
                  >
                    <SmallHeader>Trigger:</SmallHeader>
                  </FlexColumnCont>
                  <FlexColumnCont
                    width="20%"
                    flexdirection="row"
                    justifycontent="flex-start"
                  >
                    <SmallHeader>List-As-Delivery:</SmallHeader>
                  </FlexColumnCont>
                </FlexColumnCont>
              </FlexDiv>
              <FlexColumnFullWidth overflowy="auto">
                {RelatedTools.map((o, i) => (
                  <FlexColumnCont
                    borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                    justifycontent="flex-start"
                    flexdirection="row"
                  >
                    <FlexColumnCont
                      justifycontent="flex-start"
                      width="36%"
                      height="100%"
                      flexdirection="row"
                    >
                      <RegularText>{o.Name}</RegularText>
                    </FlexColumnCont>

                    <FlexColumnCont
                      width="40%"
                      height="100%"
                      flexdirection="row"
                      justifycontent="flex-start"
                    >
                      <FlexColumnCont
                        display={editTriggerInputDisplay}
                        width="35%"
                        justifycontent="flex-start"
                        height="100%"
                        flexdirection="row"
                      >
                        <TextInput300x50
                          inputwidth="30%"
                          inputheight="20%"
                          inputmargin="0"
                          inputdisplay={editTriggerInputDisplay}
                          defaultValue={o.Trigger}
                          onChange={(e) => {
                            setEditTriggerInputDisplay("block");
                            setEditTriggerId(o.Id);
                            setTriggerValue(e.target.value);
                          }}
                        ></TextInput300x50>
                      </FlexColumnCont>
                      <RegularResponsiveButton
                        display={editTriggerInputDisplay}
                        onClick={() => {
                          let checkboxCheck = o.ListAsDelivery;
                          UpdateRelatedTool({
                            triggerValue,
                            editTriggerId,
                            checkboxCheck,
                          });
                          setEditTriggerInputDisplay("none");
                          setEditTriggerLabelDisplay("flex");
                        }}
                      >
                        Save
                      </RegularResponsiveButton>

                      <FlexColumnCont
                        display={editTriggerLabelDisplay}
                        width="40%"
                        height="100%"
                        flexdirection="row"
                      >
                        <RegularText>{o.Trigger}</RegularText>
                        <Icon30px30pxPointer
                          src="/Editicon.svg"
                          onClick={() => {
                            relatedProductId = o.Id;
                            setEditTriggerInputDisplay(true);
                            setEditTriggerLabelDisplay("none");
                          }}
                        ></Icon30px30pxPointer>
                      </FlexColumnCont>
                    </FlexColumnCont>

                    <FlexColumnCont
                      width="20%"
                      height="100%"
                      justifycontent="space-between"
                      flexdirection="row"
                    >
                      <TextInput300x50
                        defaultChecked={o.ListAsDelivery}
                        onChange={(e) => {
                          setEditTriggerInputDisplay("none");
                          setEditTriggerLabelDisplay("flex");
                          UpdateCheckbox(
                            o,
                            i,
                            e.target.checked,
                            o.Trigger,
                            o.Id
                          );
                        }}
                        inputwidth="50%"
                        inputheight="25px"
                        type="checkbox"
                      ></TextInput300x50>
                      <Icon30px30pxPointer
                        src="/Garbage_Icon.svg"
                        onClick={() => {
                          relatedProductId = o.Id;
                          onRelationId({ relatedProductId });
                        }}
                      ></Icon30px30pxPointer>
                    </FlexColumnCont>
                  </FlexColumnCont>
                ))}
              </FlexColumnFullWidth>

              <FlexRowCont>
                <RegularResponsiveButton
                  onClick={() => {
                    setAddItemDisplay("flex");
                  }}
                >
                  Add
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    onClose();
                    setRFactorDisplay("none");
                    setAddItemDisplay("none");
                    setCategoryName("");
                    setStoreTools([]);
                    setToolsDisplayProp("none");
                  }}
                >
                  Close
                </RegularResponsiveButton>
              </FlexRowCont>
            </Cont100Percent80PercentOverflowY>
          </LeftItemWindowFixed>

          <LeftItemWindowFixed
            width="45vw"
            left="48vw"
            display={addItemDisplay}
          >
            <FlexColumnFullWidth>
              <TextInput72Percent
                onChange={(e) => {
                  FilterTools(allToolsInStore, e.target.value);
                }}
                placeholder="Search for a Tool"
              ></TextInput72Percent>
              <FlexColumnFullWidth>
                <FlexRowContFullWidth alignitems="baseline">
                  <FlexColumnFullWidth display="flex">
                    <FlexColumnFullWidth overflowy="auto">
                      <FlexRowContFlexStart>
                        <Icon30px30pxPointer
                          onClick={() => {
                            if (parentId === 0) {
                              GetStoreToolKitsFunc(params.Id);
                              setSubToolKitHeader("");
                              setCategoryName("");
                              setStoreTools([]);
                            } else {
                              GetToolsOfKit(parentId);
                            }
                          }}
                          src="/leftarrow.svg"
                        ></Icon30px30pxPointer>
                        <LeagueSpartanRegular>
                          {subToolKitHeader} Subcategories
                        </LeagueSpartanRegular>
                      </FlexRowContFlexStart>
                      {storeToolkits.map((o, i) => (
                        <BlankDiv>
                          <FlexRowContFullWidth
                            onClick={() => {
                              setRFactorDisplay("none");
                              setStoreTools([]);
                              GetToolsOfKit(o.Id);
                              setToolsDisplayProp("flex");
                            }}
                          >
                            <FlexRowContFullWidth>
                              <RegularText>{o.Name}</RegularText>
                            </FlexRowContFullWidth>
                          </FlexRowContFullWidth>
                        </BlankDiv>
                      ))}
                    </FlexColumnFullWidth>
                  </FlexColumnFullWidth>
                  <FlexColumnFullWidth display={toolsDisplayProp}>
                    {/* <LeagueSpartanRegular>{categoryName}</LeagueSpartanRegular> */}
                    {/* <LeagueSpartanRegular>
                      {subToolKitHeader}
                    </LeagueSpartanRegular>
                    <FlexColumnFullWidth maxheight="280px" overflowy="auto">
                      {storeSubToolkits.map((o, i) => (
                        <BlankDiv>
                          <FlexRowContFullWidth
                            onClick={() => {
                              setRFactorDisplay("none");
                              setStoreSubToolkits([]);
                              setStoreTools([]);
                              GetToolsOfKit(o.Id);
                              setToolsDisplayProp("flex");
                              setIconSrc("/rightarrow.svg");
                            }}
                          >
                            <FlexRowContFullWidth>
                              <RegularText>{o.Name}</RegularText>
                            </FlexRowContFullWidth>
                            <Icon20x20 src="/Green Button.svg"></Icon20x20>
                            <Icon20x20 src={iconSrc}></Icon20x20>
                          </FlexRowContFullWidth>
                        </BlankDiv>
                      ))}
                    </FlexColumnFullWidth> */}
                    <FlexColumnFullWidth overflowy="auto" maxheight="auto">
                      <FlexRowContFlexStart>
                        <LeagueSpartanRegular>
                          {categoryName} Tools:
                        </LeagueSpartanRegular>
                      </FlexRowContFlexStart>
                      {storeTools.map((o, i) => (
                        <BlankDiv>
                          <FlexRowContFullWidth
                            onClick={() => {
                              setProductRelationId(o.Id);
                              setRFactorDisplay("block");
                              setItemName(o.Name);
                            }}
                          >
                            <FlexRowContFullWidth>
                              <RegularText>{o.Name}</RegularText>
                            </FlexRowContFullWidth>
                          </FlexRowContFullWidth>
                        </BlankDiv>
                      ))}
                    </FlexColumnFullWidth>
                  </FlexColumnFullWidth>
                  <FlexColumnFullWidth overflowy="auto">
                    <FlexColumnFullWidth display={RFactorDisplay}>
                      <FlexDiv>
                        <LeagueSpartanRegular>{"Trigger"}</LeagueSpartanRegular>
                        <Icon20x20
                          src="/questionmark.svg"
                          onClick={() => {
                            if (triggerDisplayProp === "none") {
                              setTriggerDisplayProp("block");
                            } else if (triggerDisplayProp === "block") {
                              setTriggerDisplayProp("none");
                            }
                          }}
                        ></Icon20x20>
                      </FlexDiv>
                      <SmallText display={triggerDisplayProp}>
                        (Trigger is minimum loads that a tool will be
                        automatically added in an estimate. Ex. The skidsteer will be
                        used if the material to be transported is more than 5
                        loads of the skidsteer. Skidsteer will be not used if
                        material is less than 5 loads. Then the Trigger for
                        skidsteer is 5.)
                      </SmallText>
                      <Input
                        placeholder={"Trigger"}
                        inputwidth="80%"
                        inputheight={"5%"}
                        inputdisplay={RFactorDisplay}
                        onChange={(e) => {
                          setTriggerValue(e.target.value);
                        }}
                      ></Input>
                      <FlexRowCont>
                        <LeagueSpartanRegular>
                          {"List-As-Delivery"}
                        </LeagueSpartanRegular>
                        <TextInput300x50
                          checked={isNewToolDeliveryChecked}
                          onChange={(e) => {
                            setIsNewToolDeliveryChecked(e.target.checked);
                          }}
                          inputwidth="50%"
                          inputheight="25px"
                          type="checkbox"
                        ></TextInput300x50>
                      </FlexRowCont>

                      <RegularResponsiveButton
                        onClick={() => {
                          HandleAddRelation();
                          // setRFactorDisplay("none");
                          // setAddItemDisplay("none");
                          setStoreTools([]);
                          setToolsDisplayProp("none");
                          setCategoryName("");

                          GetStoreToolKitsFunc(params.Id);
                          setSubToolKitHeader("");
                        }}
                      >
                        Add {ItemName}
                      </RegularResponsiveButton>
                    </FlexColumnFullWidth>
                  </FlexColumnFullWidth>
                </FlexRowContFullWidth>
              </FlexColumnFullWidth>
            </FlexColumnFullWidth>
          </LeftItemWindowFixed>
        </FlexFixedCont>{" "}
      </FixedOverlay1000vh>{" "}
    </>
  );
};

ProductToolRelation.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  onRelationId: () => {},
  GetRelationsAgain: () => {},
  UpdateRelatedTool: () => {},
  ProductID: 0,
  ProductName: "",
  RelatedTools: ["name"],
  StoreID: 0,
};

export default ProductToolRelation;
