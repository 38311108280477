import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddQuoteItem,
  AddQuoteTool,
  UpdateQuoteItem
} from "../../../API_Functions/CaseQuote_Functions";
import { CategoryTypes } from "../../../StylesheetComps/CategoryTypes";
import {
  FlexDiv
} from "../../../StylesheetComps/Cont";

import styled from 'styled-components';
import { GetProduct, GetStoreProducts, GetStoreTools } from "../../../API_Functions/CatProduct_Functions";
import { Icon15x15, Icon30x40pxPointer } from "../../../StylesheetComps/Imgs/Icons";
import QuoteAddOnSelection from "../QuoteAddOnSelection";
import { InputField } from "../../../StylesheetComps/Inputs";
import { Backdrop } from "@material-ui/core";
import CalculatorWindow from "../Calculators";
import { MobileFlexColumnCont } from "../../../MobileComponents/Divs";

// Styled Components
const LineItemContainer = styled.div`
  display: flex;
  flex-direction: row; // Align items in a row
  align-items: center; // Center-align vertically for equal space above and below
  justify-content: space-evenly; // Align items to the start of the container
  width: 100%;
  border-bottom: 1px solid #ccc;
  @media (max-width: 1006px) {
    flex-direction: column;
  }
`;

const InputGroup = styled.div`
  margin: 10px 20px; // Adding 10px top and bottom margin for spacing
  display: flex; // Make it a flex container
  flex-direction: column; // Stack label and input vertically
  align-items: flex-start; // Align to the start to keep labels left-aligned
  max-width: 60%; // Increase this percentage to allow more space
  width: 100%;
  @media (max-width: 1006px) {
    flex-direction: column;
    max-width: 100%;
    margin: 0px;
  }
`;

const InputLabel = styled.label`
display: block;
margin-bottom: 10px; // Increasing a bit to provide more space
font-size: 14px; // Slightly smaller than the input text for a hierarchy
color: #333; // Matches the text color in input and textarea
font-weight: 500; // Slightly bold to differentiate it from the input text
letter-spacing: 0.5px; // Optional, adds a touch of refinement

`;

const ActionIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
`;

const TextAreaField = styled.textarea`
margin-bottom: 20px;
padding: 8px 8px;
font-family: Avenir;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 8px;
outline: none;
color: #333;
background-color: #f9f9f9;
transition: border-color 0.3s ease;
width: 100%;
box-sizing: border-box;
resize: vertical; // Allows vertical resizing, but you can also set this to 'none' if you don't want resizing

&:focus {
  border-color: #007aff; // You can adjust this to match your theme color
}

::placeholder {
  color: #999;
}

`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;


const ProductList = styled.div`
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2; // To make it overlay other elements
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for a sophisticated look
  width: 100%;
`;

const ProductItem = styled.div`
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #eee;
  color: #333;

  &:hover {
    background-color: #f6f6f6; // Highlighting on hover
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1006px) {
    flex-direction: row;
  }
`;

const InverseInputRow = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1006px) {
    flex-direction: column;
    width: 100%;
  }
`;


// Component
const QuoteLineItem = ({
   open,
  onClose,
  onCloseNoAdd,
  onPreview,
  itemId,
  quoteid,
  quote,
  RefreshItemList,
  RefreshUpdatedItem,
  siteMapper,
  squareFeetSM,
  perimeterSM,
  Tag_Id,
  addOnSelectedItem
}) => {
    const [squareFeet, setSquareFeet] = useState(0);
    const [depth, setDepth] = useState(0);
    const [productobj, setProductObj] = useState({
      Name:"",
      Unit:'',
      CateType:CategoryTypes.Material,
      Price:0, 
    });
    const [isProductListVisible, setProductListVisible] = useState(false);
    const [nameInputValue, setNameInputValue] = useState(productobj?.Name || "");
    const [cubicFeetTotal, setCubicFeetTotal] = useState(0);
    const [taglist, setTaglist] = useState([]);
    const [primaryEstimateItemId, setPrimaryEstimateItemId] = useState(0);
    const [calculatorsVisible, setCalculatorsVisible] = useState(false);
  
    //Removals
    const [removalArea, setRemovalArea] = useState(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
    const [removalDepth, setRemovalDepth] = useState(0);
  
    const [managerNotes, setManagerNotes] = useState("");
    const [itemObj, setItemObj] = useState({});
    const [addTagWindowVisible, setAddTagWindowVisible] = useState(false);
    const [tagObj, setTagObj] = useState({});
  
    const [inputValue, setInputValue] = useState(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
    const [isUserInput, setIsUserInput] = useState(false);
    const [isAddOnsWindowVisible, setIsAddOnsWindowVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
      setRemovalArea(siteMapper === true ? squareFeetSM.toFixed(2) : 0);
    }, [siteMapper, squareFeetSM]);
  
    const params = useParams();
  
    useEffect(() => {
      setCubicFeetTotal(squareFeet * (depth / 12));
    }, [depth, squareFeet]);
    
    useEffect(() => {
      setTaglist(quote?.Tags);
    }, [quote]);
  
    const ResetCalculator = async () => {
      setCubicFeetTotal(0);
      setSquareFeet(0);
      setDepth(0);
    };

    const GetProductInfo = async (product_id) => {
      const data = await GetProduct(product_id)
      console.log(data.Notes)
      setManagerNotes(data.Notes)
    }
  
    const AddNewItemToQuote = async (itemobj) => {
      try {
        const data = await AddQuoteItem(itemobj);
        if (data && data.item) {
          setPrimaryEstimateItemId(data.item.Id)
          CheckIfProductHasAddons(itemobj.Product_Id);
          setIsLoading(false)
          if (siteMapper === true) {
            ResetCalculator();
            onClose();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    
  const CheckIfProductHasAddons = async (productid) => {
    const data = await GetProduct(productid);
    if (data !== null) {
      setProductObj(data)
      if (data.Addons.length > 0) {
        setIsAddOnsWindowVisible(true);
      } else {
        RefreshItemList(data.item);
      }
    }
    // setItemId(0);
    // setQuoteItemWindowToggle(false);
  };

  const CheckIfProductHasAddonsEstimateScreen = async (productid) => {
    const data = await GetProduct(productid);
    if (data !== null) {
      setProductObj(data)
      if (data.Addons.length > 0) {
        setIsAddOnsWindowVisible(true);
      } else {
        window.alert('This Product Has No Add-Ons Available')
      }
    }
    // setItemId(0);
    // setQuoteItemWindowToggle(false);
  };
    
    
    const UpdateItemOnQuote = async (itemobj) => {
      try {
        const data = await UpdateQuoteItem(itemobj);
        if (data && data.item) {
          RefreshUpdatedItem(data.item);
          ResetCalculator();
          onClose(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      if(addOnSelectedItem !== null) {
        setPrimaryEstimateItemId(addOnSelectedItem.Id)
        CheckIfProductHasAddonsEstimateScreen(addOnSelectedItem.Product_Id)
      }
    }, [addOnSelectedItem]);
    
  
    // const SaveItem = async (itemobj) => {
    //   if (itemobj) {
    //     if (itemobj.CateType === CategoryTypes.Removal) {
    //       itemObj.Quant = removalArea * (removalDepth / 12);
    //     }
    //     if (itemobj.Id === 0) {
    //       if(siteMapper === true && itemobj.CateType === CategoryTypes.Material) {
    //         setInputValue(squareFeetSM.toFixed(2));
    //         var value = (squareFeetSM.toFixed(2))
    //         itemObj.Quant = value;
    //       }
    //       AddNewItemToQuote(itemObj);
    //     } else {
    //       UpdateItemOnQuote(itemObj);
    //     }
    //   }
    // };
  
    const SaveItem = async (productobj) => {
      setIsLoading(true);
      if(productobj.RUnit !== undefined){
        productobj.Quote_Id = params.quoteId
        productobj.Case_Id = params.caseId
        let toolobj = {}
        toolobj.Quote_Id = Number(params.quoteId)
        toolobj.Quant = Number(productobj.Quant)
        toolobj.Tool_Id = productobj.Id
        toolobj.RentalLength = Number(productobj.RentalLength)
       const data = await AddQuoteTool(toolobj)
       if(data !== null) {
        RefreshItemList(data.Tool)
        setIsLoading(false);
       }
      } else {
      productobj.Quote_Id = params.quoteId
      productobj.Case_Id = params.caseId
      productobj.Tag_Id = Tag_Id
      productobj.Product_Id = productobj.Id
      productobj.Id = 0
      productobj.Name = nameInputValue
      setIsLoading(true); // Start loading
      if (productobj) {
        if (productobj.CateType === CategoryTypes.Removal) {
          productobj.Quant = removalArea * (removalDepth / 12);
          productobj.Notes = `Area:${removalArea} SqFt., Depth: ${removalDepth} Inches`
        }
        if (productobj.Id === 0) {
          if(siteMapper === true && productobj.CateType === CategoryTypes.Material && !isUserInput) {
            setInputValue(squareFeetSM.toFixed(2));
            var value = (squareFeetSM.toFixed(2))
            productobj.Quant = value;
          }
          AddNewItemToQuote(productobj);
        } 
      }
    }
      setInputValue(0);
      // setIsLoading(true); // Stop loading once done
      setIsUserInput(false); // Reset the isUserInput state for the next input
    };
  
    // useEffect(() => {
    //   if (itemId === 0) {
    //     itemObj.Name = productobj?.Name;
    //     itemObj.CateType = productobj?.CateType;
    //     itemObj.Product_Id = productobj?.Id;
    //     itemObj.UnitPrice = productobj?.UnitPrice;
    //     itemObj.Unit = productobj?.Unit;
    //     itemObj.Quote_Id = params.quoteId;
    //   }
    // }, [productobj]); // eslint-disable-line react-hooks/exhaustive-deps
  
    // useEffect(() => {
    //   if (productobj !== undefined) {
    //     if (params.quoteId !== undefined) {
    //       GetQuoteTaglist(params.quoteId);
    //     } else {
    //       GetQuoteTaglist(quoteid);
    //     }
    //     setManagerNotes(productobj?.Notes);
    //     GetItem(itemId);
    //   }
    // }, [productobj]); // eslint-disable-line react-hooks/exhaustive-deps

    const [unfilteredProducts, setUnfilteredProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [first30Products, setFirst30Products] = useState();


    const GetAllProducts = async (storeId) => {
      try {
        const productsData = await GetStoreProducts(storeId)
        const toolsData = await GetStoreTools(storeId)

        const combinedData = [...(productsData?.products || []), ...(toolsData?.tools || [])]; // Merging products and tools. This assumes the tools are in a 'tools' property of the toolsData.

          setUnfilteredProducts(combinedData)
        
      } catch (error) {
        console.error("Error fetching products or tools:", error);
      }
    }
  
    // const FilterProducts = (productlist, text) => {
    //   function find(productlist, text) {
    //     text = text.toLowerCase().split(" ");
    //     return productlist.filter(function (word) {
    //       return text.every(function (char) {
    //         return word.Name.toLowerCase().includes(char);
    //       });
    //     });
    //   }
  
    //   const output = find(productlist, text);
    //   setProducts(output);
    // };

    // const FilterProducts = (productlist, text) => {
    //   function find(productlist, text) {
    //     const lowerCaseText = text.toLowerCase();
    
    //     // Check if any part of the text contains a substring of 'access'
    //     const isAccessCategory = lowerCaseText.includes('acc') ||
    //                               lowerCaseText.includes('cce') ||
    //                               lowerCaseText.includes('ces') ||
    //                               lowerCaseText.includes('ess');
    
    //     return productlist.filter(function (product) {
    //       // If any part of 'access' is in the text, only return items of that CategoryType
    //       if (isAccessCategory && product.CateType === CategoryTypes.Access) {
    //         return true;
    //       }
    
    //       // Otherwise, go ahead with the original text-based filter
    //       const textArray = lowerCaseText.split(" ");
    //       return textArray.every(function (char) {
    //         return product.Name.toLowerCase().includes(char);
    //       });
    //     });
    //   }
    
    //   // Get filtered products and update state
    //   const output = find(productlist, text);
    //   setProducts(output);
    // };

    const FilterProducts = (productlist, text) => {
      function find(productlist, text) {
        const lowerCaseText = text.toLowerCase();
    
        // Check if any part of the text contains a substring of 'access'
        const isAccessCategory = lowerCaseText.includes('acc') ||
                                  lowerCaseText.includes('cce') ||
                                  lowerCaseText.includes('ces') ||
                                  lowerCaseText.includes('ess');
    
        return productlist.filter(function (product) {
          // Exclude products with 'DNU' in uppercase in their name
          if (product.Name.includes('DNU')) {
            return false;
          }
    
          // If any part of 'access' is in the text, only return items of that CategoryType
          if (isAccessCategory && product.CateType === CategoryTypes.Access) {
            return true;
          }
    
          // Otherwise, go ahead with the original text-based filter
          const textArray = lowerCaseText.split(" ");
          return textArray.every(function (char) {
            return product.Name.toLowerCase().includes(char);
          });
        });
      }
    
      // Get filtered products and update state
      const output = find(productlist, text);
      setProducts(output);
    };
    
    
    
    
  
    useEffect(() => {
      GetAllProducts(params.storeId)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      setFirst30Products(products?.slice(0, 30))
    }, [products]); // eslint-disable-line react-hooks/exhaustive-deps
  

  return (
  //   isLoading === true ? (
  //     <Backdrop style={{ zIndex: 1000 }} open>
  //     <CircularProgress color="inherit" />
  //   </Backdrop>
  // ) : 
  <div>
    <LineItemContainer>
<InputGroup>
  <FlexDiv>
  <InputLabel>Name:</InputLabel>
  <Icon15x15 onClick={()=>{setCalculatorsVisible(true)}} src='/Calculator_Icon.svg'></Icon15x15>
  <CalculatorWindow isVisible={calculatorsVisible} onClose={()=>{setCalculatorsVisible(false)}} ></CalculatorWindow>
  </FlexDiv>
  <InputWrapper>
    <InputField
     value={nameInputValue}
     onChange={(e) => {
       const value = e.target.value;
       setNameInputValue(value);
       itemObj.Name = value;
       FilterProducts(unfilteredProducts, value);
       setProductListVisible(true); // Show the product list
     }}   
    />
    {isProductListVisible && first30Products && first30Products.length > 0 ? (
      <ProductList>
        {first30Products.map((product, i) => (
          <ProductItem key={i}
          onClick={() => {
            const { Notes, ...restOfProduct } = product; // Exclude Notes from product
        setProductObj(restOfProduct); // Set productObj to remaining properties
        setNameInputValue(product.Name);
        setProductListVisible(false); // Hide the product list
        GetProductInfo(product.Id)
          }}        
          >
           {
    product.RUnit !== undefined ? 
    `${product.Name}` 
    :
    `${product.Name} - $${parseFloat(product.UnitPrice).toFixed(2)} ${product.Unit}` 
    
}

          </ProductItem>
        ))}
      </ProductList>
    ) : null}
  </InputWrapper>
</InputGroup>
<p>{managerNotes}</p>

 {productobj.CateType === CategoryTypes.Material ||
              productobj.CateType === CategoryTypes.Open ||
              productobj.CateType === CategoryTypes.Addon ||productobj.CateType === CategoryTypes.Access || productobj.ToolKit_Id !== undefined ?
(
  <InputGroup>
  <InputLabel>Quantity ({productobj?.Unit}) of Item:</InputLabel>
  <InputField
    value={inputValue}
    key={siteMapper === true ? squareFeetSM : ''}
    onChange={(e) => {
      const value = e.target.value;
      setInputValue(value);
      // Create a new object that has all the same properties as productObj, but with the updated Quant value
      setProductObj((prevProductObj) => ({
        ...prevProductObj,
        Quant: value
      }));
      setIsUserInput(true); // User has entered input
    }}  
  />
</InputGroup> ) : null}

{productobj.ToolKit_Id !== undefined ?
(
  <InputGroup>
  <InputLabel>Rental Length:</InputLabel>
  <InputField
    onChange={(e) => {
      const value = e.target.value;
      // setInputValue(value);
      // Create a new object that has all the same properties as productObj, but with the updated Quant value
      setProductObj((prevProductObj) => ({
        ...prevProductObj,
        RentalLength: value
      }));
      setIsUserInput(true); // User has entered input
    }}  
  />
</InputGroup> ) : null}

    {productobj?.CateType === CategoryTypes.Removal ? (
      <FlexDiv>
        <InputGroup>
          <InputLabel>Area (SqFt)*:</InputLabel>
          <InputField
            value={removalArea}
            key={siteMapper === true ? squareFeetSM : ''}
            onChange={(e) => {
              setRemovalArea(e.target.value);
            }}
          />
        </InputGroup>
        <InputGroup>
          <InputLabel>Depth (Inches)*:</InputLabel>
          <InputField
            onChange={(e) => {
              setRemovalDepth(e.target.value);
            }}
          />
        </InputGroup>
      </FlexDiv>
    ) : null}

    {productobj?.CateType === CategoryTypes.Labor ? (
      <InputGroup>
        <InputLabel>Hours:</InputLabel>
        <InputField
          onChange={(e) => 
            {
              const value = e.target.value;
            setProductObj((prevProductObj) => ({
              ...prevProductObj,
              Quant: value
            }));
          }}
        />
      </InputGroup>
    ) : null}

    {productobj?.CateType === CategoryTypes.Open ? (
      <InverseInputRow>
        <InputGroup>
          <InputLabel>Unit:</InputLabel>
          <InputField
            defaultValue={productobj?.Unit}
            onChange={(e) => {
              const value = e.target.value;
              setProductObj((prevProductObj) => ({
                ...prevProductObj,
                Unit: value
              }));
            }}
          />
        </InputGroup>
        <InputGroup>
          <InputLabel>Product Price:</InputLabel>
          <InputField
            defaultValue={productobj?.UnitPrice}
            onChange={(e) => {
              const value = e.target.value;
              setProductObj((prevProductObj) => ({
                ...prevProductObj,
                UnitPrice: value
              }));
            }}
          />
        </InputGroup>
      </InverseInputRow>
    ) : null}

    {productobj.RUnit === undefined ?

    <InputGroup>
      <InputLabel>Notes:</InputLabel>
      <TextAreaField
        onChange={(e) => {
          const value = e.target.value;
          setProductObj((prevProductObj) => ({
            ...prevProductObj,
            Notes: value
          }));
        }}
      />
    </InputGroup>
    : null }
      {/* Repeat for other inputs */}
      <InputRow>
      <ActionIcon onClick={() => {SaveItem(productobj); setNameInputValue('');}}>
        <Icon30x40pxPointer src='/Green Button.svg' />
      </ActionIcon>
      <ActionIcon
        onClick={() => {
          onClose()
          ResetCalculator();
        }}
      >
        <Icon30x40pxPointer src='/Close.svg' />
      </ActionIcon>
      </InputRow>
    </LineItemContainer>
    <QuoteAddOnSelection
    primaryitemid={primaryEstimateItemId}
    open={isAddOnsWindowVisible}
    RefreshQuoteItems={(item, itemid) => {
      RefreshItemList();
    }}
    productObj={productobj}
    onClose={() => {
      setIsAddOnsWindowVisible(false);
      onClose(true);
      RefreshItemList();
    }}
  ></QuoteAddOnSelection>
  </div>
  );
};

QuoteLineItem.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  RefreshItemList: () => {},
  RefreshUpdatedItem: () => {},
  defaultName: "",
  unitForProduct: "",
  GetQuoteInfoById:() => {},
  onCloseNoAdd:() => {},
  onClose:() => {},
  Tag_Id:0,
  addOnSelectedItem:null
};

export default QuoteLineItem;