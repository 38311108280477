import React from "react";
import { BodyPara, Header } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { FlexColumnContCenterAlign } from "../../../StylesheetComps/Cont";
import {
  EstimatorDiv,
  ProgressBar,
  ProgressBarWidth,
  Section1,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import {
  FlexColumnCont,
  Icon,
  SmallHeader,
} from "../../../StylesheetComps/Menu";

const ProjectPath = ({
  open,
  onNext,
  onSelectSurveyPath,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
}) => {
  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="25%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Tell Us About Yourself
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="2%"
          fontsize="16px"
        >
          Choose the level of guidance you need for your project to us determine
          how we can help you better through education, design options, and
          further consultation.{" "}
        </BodyPara>

        <FlexColumnCont
          flexdirection="row"
          width="70%"
          height="80%"
          marginbottom="0%"
          alignitems="left"
        >
          <FlexColumnContCenterAlign>
            <Icon width="50%" borderradius="0" src="/Simpleperson.svg"></Icon>
            <SmallHeaderLeagueSpartan mobilefontsize="16px" fontsize="18px">
              "I know what I need and want"
            </SmallHeaderLeagueSpartan>
            <SmallHeader
              mobiledisplay="none"
              fontsize="15px"
              color={ColorPalette.PrimaryGrey}
            >
              Homeowner / Avid Gardener / Landscaper
            </SmallHeader>
            <BodyPara width="90%" fontsize="14px">
              Less consultation and survey options available.
            </BodyPara>
            <RegularResponsiveButton
              onClick={() => {
                onSelectSurveyPath("simple");
                onNext();
              }}
            >
              Start
            </RegularResponsiveButton>
          </FlexColumnContCenterAlign>

          <FlexColumnContCenterAlign>
            <Icon width="50%" borderradius="0" src="/Detailedperson.svg"></Icon>
            <SmallHeaderLeagueSpartan mobilefontsize="16px" fontsize="18px">
              "I need guidance and consultation"
            </SmallHeaderLeagueSpartan>
            <SmallHeader
              mobiledisplay="none"
              fontsize="15px"
              color={ColorPalette.PrimaryGrey}
            >
              Homeowner / Beginner Gardener / New to New Rhodes Construction
            </SmallHeader>
            <BodyPara width="90%" fontsize="14px">
              Full consultation and in-depth survey options available.
            </BodyPara>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryTurquoise}
              onClick={() => {
                onSelectSurveyPath("detailed");
                onNext();
              }}
            >
              Start
            </RegularResponsiveButton>
          </FlexColumnContCenterAlign>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectPath.defaultProps = {
  onChange: () => {},
  onSelectSurveyPath: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectPath;
