/* eslint-disable */

//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import axios from "axios";
import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  GetQuoteByIdForUserDisplay,
  QuoteStatus,
  ReverseQuant_QuoteItem,
  SetQuoteTo_Buy,
  SetQuoteTo_Buy_Install,
} from "../../API_Functions/CaseQuote_Functions";
import {
  decimalHoursToTimeString,
  GetCurrentTime,
  MakeStripePay,
  SetHeaderAuthorization,
} from "../../API_Functions/Utility_Functions";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import TopMentProposalView from "../../comps/MenuComps/TopMenuProposalView";
import QuoteItem from "../../comps/QuoteItem";
import ImageViewWindow from "../../comps/Windows/ImageEnlargementWindow";
import RevisionInstructionsWindow from "../../comps/Windows/RevisionInstructions";
import DuplicateProposalWindow from "../../comps/Windows/SaveRevisionGuide";
import MobileQuoteItem from "../../MobileComponents/MobileQuoteItem";
import { Stored_Values } from "../../Stored_Values";
import { CaseStatus } from "../../StylesheetComps/CaseStatus";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FixedIconToTop,
  RegularResponsiveButton,
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import { GetTaxesForMetro } from "../../API_Functions/Store_Functions";
import {
  AutoSizeOverflowY,
  FlexColumn81vwLightGrey,
  FlexColumnFullWidth,
  FlexDiv,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContAutox100PercentMobileColumn,
  FlexRowContFlexStart,
  FlexRowContFlexStartMobileColumn,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Cont, ContentContQuote } from "../../StylesheetComps/Projects";
import {
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  StyledHRGreenBreakThick,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";

const ViewProposalConstructionView = () => {
  const history = useHistory();
  const params = useParams();
  var MaterialSum = 0;
  var InstallSum = 0;
  var TotalSum = 0;
  var materialSub = 0;
  var installSub = 0;

  const [Quote, setProposal] = useState({});

  const [supplyTotal, setSupplyTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });

  const [installTotal, setInstallTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });

  const GreenBorderConst = "4px solid " + ColorPalette.PrimaryButtonBlue;

  // Material / Install Header Display Const

  const [laborHeader, setLaborHeader] = useState("none");
  const [toolsmachinesHeader, setToolsMachinesHeader] = useState("none");
  const [removalHeader, setRemovalHeader] = useState("none");
  const [accessDisplay, setAccessDisplay] = useState("none");
  const [generalMaterialsHeader, setGeneralMaterialsHeader] = useState("none");
  const [deliveryHeader, setDeliveryHeader] = useState("none");

  //Revision Instruction Window

  const [revisionInstructionDisplay, setRevisionInstructionDisplay] =
    useState(false);

  const [curTime, setCurTime] = useState("");
  // Image Gallery Preview

  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageGalleryDisplay, setImageGalleryDisplay] = useState(false);

  // Accept / Decline Button Display

  const [revisionAcceptDisplay, setRevisionAcceptDisplay] = useState("none");
  const [revisionDeclineDisplay, setRevisionDeclineDisplay] = useState("none");

  // Accept / Decline Revision Window Display Boolean

  const [declineRevisionWindowDisplay, setDeclineRevisionWindowDisplay] =
    useState(false);
  const [acceptRevisionWindowDisplay, setAcceptRevisionWindowDisplay] =
    useState(false);

  const [deleteState, setDeleteState] = useState(false);

  // Supply / Supply + Install Box Border

  const [supplyOnly, setSupplyOnly] = useState("");
  const [supplyInstall, setSupplyInstall] = useState(
    "4px solid " + ColorPalette.PrimaryButtonBlue
  );

  // Project Subtotal Const
  const [projectSummarySubtotal, setProjectSummarySubtotal] = useState(0);
  const [projectSubtotal, setProjectSubtotal] = useState(0);

  // Project Deposit Const

  const [deposit, setDeposit] = useState(0);
  const [showDepositButton, setShowDepositButton] = useState("block");
  // Project Grand Total Const

  const [projectGrandTotal, setProjectGrandTotal] = useState(0);

  // Project Case Number

  const [projectCaseNumber, setProjectCaseNumber] = useState(0);

  // Proposal Comments Array

  const [proposalComments, setProposalComments] = useState([]);

  // Section Open / Close Toggle Booleans

  const [overviewToggle, setOverviewToggle] = useState(true);
  const [supplyEstimateToggle, setSupplyEstimateToggle] = useState(false);
  const [installEstimateToggle, setInstallEstimateToggle] = useState(false);
  const [commentsToggle, setCommentsToggle] = useState(false);

  //Section Display + Arrow SRC

  const [proposalOverviewDisplay, setProposalOverviewDisplay] =
    useState("block");
  const [projectOverviewArrow, setProjectOverviewArrow] =
    useState("/downarrow.svg");

  const [installEstimateDisplay, setInstallEstimateDisplay] = useState("block");
  const [installEstimateArrow, setInstallEstimateArrow] =
    useState("/uparrow.svg");

  const [laborDetailView, setLaborDetailView] = useState("none");
  const [showGenerateInovice, setShowGenerateInvoice] = useState("none");
  const [laborDetailToggleView, setLaborDetailToggleView] = useState("none");
  const [laborDetailToggle, setLaborDetailToggle] = useState(false);
  const [laborDetailArrow, setLaborDetailArrow] = useState("/downarrow.svg");

  const [proposalCommentsDisplay, setProposalCommentsDisplay] =
    useState("none");
  const [proposalCommentsArrow, setProposalCommentsArrow] =
    useState("/uparrow.svg");
  const [discardButtonDisplay, setDiscardButtonDisplay] = useState("none");
  const [makeRevisionsButtonDisplay, setMakeRevisionsButtonDisplay] =
    useState("block");
  const [saveRevisionsButtonDisplay, setSaveRevisionsButtonDisplay] =
    useState("none");

  const [cloneProposalButtonDisplay, setCloneProposalButtonDisplay] =
    useState("none");
  const [cloneProposalWindowDisplay, setCloneProposalWindowDisplay] =
    useState(false);

  const [installSubtotalDisplay, setInstallSubtotalDisplay] = useState("flex");

  const [hideProjectConfirmationDisplay, setHideProjectConfirmationDisplay] =
    useState("block");

  // Save Revision Window Display

  const [saveRevisionWindowDisplay, setSaveRevisionWindowDisplay] =
    useState(false);

  const [headerInputShown, setHeaderInputShown] = useState(0);

  const [supplyTotalOpacity, setSupplyTotalOpacity] = useState("100%");
  const [installTotalOpacity, setInstallTotalOpacity] = useState("100%");

  const [revisionText, setRevisionText] = useState("");

  // Boolean if Homeowner Agrees to Terms

  const [userAgreementStatus, setUserAgreementStatus] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [invoicefileMaterial, setInvoicefileMaterial] = useState(
    "invoice_material.pdf"
  );
  const [invoicefileInstall, setInvoicefileInstall] = useState(
    "invoice_install.pdf"
  );
  // Proceed to Deposit Button Background Color

  const [DepositBGColor, setDepositBGColor] = useState(
    ColorPalette.PrimaryGrey
  );

  // 'Please Agree to our Terms' error text

  const [errorAgreementText, setErrorAgreementText] = useState("");

  const [supplyOnlySelected, setSupplyOnlySelected] = useState(false);

  const [commentText, setCommentText] = useState("");

  const [labourBreakdownDisplay, setShowLabourBreakdown] = useState("none");

  const [showRelated, setShowRelated] = useState(false);

  const [storeTaxes, setStoreTaxes] = useState(false);

  const scrollToOverview = document.getElementById("overview");
  const scrollToSupply = document.getElementById("supply");
  const scrollToInstall = document.getElementById("install");
  const scrollToDesigns = document.getElementById("designs");
  const scrollToComments = document.getElementById("comments");
  const scrollToConfirm = document.getElementById("confirmation");
  const [caseStatus, setCaseStatus] = useState(0);

  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [accessTotal, setAccessTotal] = useState(0);

  const [homeownerName, setHomeownerName] = useState("");

  const [sendButtonDisplay, setSendButtonDisplay] = useState("none");

  const [submittingLoadingIcon, setSubmittingLoadingIcon] = useState(false);

  const materialOnlyRadioReference = useRef(null);
  const materialAndInstallRadioReference = useRef(null);
  const buttonRef = React.useRef(null);

  const [totalHoursByProduct, setTotalHoursByProduct] = useState({});
  const [totalRemovalsByItem, setTotalRemovalsByItem] = useState({});
  let noTagTimeSubtotal = 0;
  let noTagInstallSubtotal = 0;
  let removalTimeSubtotal = 0;
  let removalPriceSubtotal = 0;
  let accessAreaPriceSubtotal = 0;
  let toolsPriceSubtotal = 0;
  let deliveryPriceSubtotal = 0;

  const DisplayImageInGallery = async (url) => {
    if (url !== "" && url !== undefined && url !== null) {
      setImagePreviewUrl(url);
      setImageGalleryDisplay(true);
      document.body.style.overflow = "hidden";
    }
  };

  const DisplayProposalOverview = async (toggle) => {
    if (toggle === true) {
      setProjectOverviewArrow("/uparrow.svg");
      setProposalOverviewDisplay("none");
      setOverviewToggle(false);
    } else {
      setProjectOverviewArrow("/downarrow.svg");
      setProposalOverviewDisplay("flex");
      setOverviewToggle(true);
    }
  };

  const DisplayLaborBreakdown = async (toggle) => {
    if (toggle === true) {
      setLaborDetailArrow("/downarrow.svg");
      setLaborDetailToggleView("none");
      setLaborDetailToggle(false);
    } else {
      setLaborDetailToggle(true);
      setLaborDetailArrow("/uparrow.svg");
      setLaborDetailToggleView("flex");
    }
  };

  const [projectLength, setProjectLength] = useState(0);

  let total = 0;

  useEffect(() => {
    setProjectLength(total);
  }, [total, Quote?.Labor?.Items]);

  // const ScrollToBottom = () => {
  //   window.scrollTo(0, document.body.scrollHeight);
  // };

  const GetProjectInformation = async (caseid) => {
    const data = await GetCaseById(caseid);
    const caseData = data?.Case;

    if (caseData) {
      setHomeownerName(caseData.Name ?? "");
      setProjectName(caseData.Tittle ?? "");
      setProjectCaseNumber(caseData.CaseNumber ?? 0);

      if (caseData.Street && caseData.City) {
        const ext = `${caseData.Street}-${caseData.City}`;
        setInvoicefileInstall(`invoice_install_${ext}.pdf`);
        setInvoicefileMaterial(`invoice_material_${ext}.pdf`);
      }

      const status = caseData.CaseStatus;
      const hideStatuses = [
        CaseStatus.Closed,
        CaseStatus.Canceled,
        CaseStatus.CancelRequested,
        CaseStatus.Drafted,
      ];
      if (hideStatuses.includes(status)) {
        setMakeRevisionsButtonDisplay("none");
      }

      setCaseStatus(status);
    }
  };

  const DisplayDepositButton = async () => {
    if (caseStatus === CaseStatus.Construction) {
      setShowDepositButton("none");
    } else setShowDepositButton("block");
  };

  const [estimateGST, setEstimateGST] = useState(0);
  const [estimatePST, setEstimatePST] = useState(0);
  const [laborNamesByProduct, setLaborNamesByProduct] = useState({});

  const GetProposalInformation = async (quoteid) => {
    var data = await GetQuoteByIdForUserDisplay(quoteid);
    if (data !== null) {
      if (data.quote !== null) {
        setProposal(data.quote);
        setDeliveryTotal(
          data?.quote?.Delivery?.Tools?.reduce(
            (accumulator, tool) => accumulator + tool.Price,
            0
          )
        );
        setAccessTotal(
          data?.quote?.AccessArea?.Items?.reduce(
            (accumulator, items) => accumulator + items.Price,
            0
          )
        );
        UpdateUIBasedOnStatus(data.quote);
        supplyTotal.Subtotal = (
          data.quote?.Material?.Total?.Subtotal +
          data.quote?.Delivery?.Total?.Subtotal
        ).toFixed(2);
        installTotal.Subtotal =
          data.quote?.Labor?.Total?.Subtotal > Stored_Values.MIN_LABOR
            ? (
                data.quote?.Labor?.Total?.Subtotal +
                data.quote?.Tool?.Total?.Subtotal +
                data.quote?.Removal?.Total?.Subtotal +
                data.quote?.AccessArea?.Total?.Subtotal
              ).toFixed(2)
            : (
                Stored_Values?.MIN_LABOR +
                data.quote?.Tool?.Total?.Subtotal +
                data.quote?.Removal?.Total?.Subtotal +
                data.quote?.AccessArea?.Total?.Subtotal
              ).toFixed(2);
        var subtotal =
          Number(supplyTotal.Subtotal) + Number(installTotal.Subtotal);
        setProjectSubtotal(
          subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        if (data.quote.Tittle !== null && data.quote.Tittle !== undefined) {
          setEstimateName(data?.quote?.Tittle);
        }
        var storeTaxArray = await GetTaxesForMetro(params.Id);
        setStoreTaxes(storeTaxArray);
        let gstTax = 0;
        // let pstTax = 0
        const taxitems = [
          {
            type: "Material",
            subtotal: parseFloat(data.quote?.Material?.Total?.Subtotal),
          },
          {
            type: "Delivery",
            subtotal: parseFloat(data.quote?.Delivery?.Total?.Subtotal),
          },
          {
            type: "Labor",
            subtotal: parseFloat(
              data.quote?.Labor?.Total?.Subtotal < Stored_Values.MIN_LABOR
                ? Stored_Values.MIN_LABOR
                : data.quote?.Labor?.Total?.Subtotal
            ),
          },
          {
            type: "Removal",
            subtotal: parseFloat(data.quote?.Removal?.Total?.Subtotal),
          },
          {
            type: "AccessArea",
            subtotal: parseFloat(data.quote?.AccessArea?.Total?.Subtotal),
          },
          {
            type: "Tool",
            subtotal: parseFloat(data.quote?.Tool?.Total?.Subtotal),
          },
        ];

        const gst = storeTaxArray.find((tax) => tax.TaxCode === "Gst");
        // const pst = storeTaxArray.find(tax => tax.TaxCode === 'PST');

        if (gst) {
          taxitems.forEach((item) => {
            const { type, subtotal } = item;

            if (type === "Material") {
              gstTax += (gst.Rate / 100) * subtotal;
              // pstTax += (pst.Rate / 100) * subtotal;
            } else {
              gstTax += (gst.Rate / 100) * subtotal;
            }
          });
        }
        // setEstimatePST(pstTax.toFixed(2))
        setEstimateGST(gstTax.toFixed(2));
        setProjectGrandTotal(
          Number(gstTax.toFixed(2)) + Number(subtotal.toFixed(2))
        );
        setDeposit(
          (Number(gstTax.toFixed(2)) + Number(subtotal.toFixed(2))) * 0.1
        );

        computeTotalHours(data.quote.Material.Items, data.quote.Labor.Items);
        computeRemovalsTotal(data.quote.Removal.Items, data.quote.Labor.Items);
      }
    }
  };

  function computeTotalHours(productsArray, labourArray) {
    const totalHoursByProduct = {};
    const laborNamesByProduct = {};

    productsArray.forEach((product, productIndex) => {
      if (product.Parent_Id === 0) {
        let totalHours = 0;

        labourArray.forEach((labour) => {
          if (
            (labour.Notes === `Install ${product.Name}` ||
              labour.Notes === product.Name) &&
            labour.Parent_Id === product.Id
          ) {
            totalHours += labour.Quant;
            if (!laborNamesByProduct[`${product.Name}_${productIndex}`]) {
              laborNamesByProduct[`${product.Name}_${productIndex}`] =
                new Set();
            }
            laborNamesByProduct[`${product.Name}_${productIndex}`].add(
              labour.Name
            );
          }
        });

        productsArray.forEach((relatedProduct) => {
          if (relatedProduct.Parent_Id === product.Id) {
            labourArray.forEach((labour) => {
              if (
                (labour.Notes === `Install ${relatedProduct.Name}` ||
                  labour.Notes === relatedProduct.Name) &&
                labour.Parent_Id === relatedProduct.Id
              ) {
                totalHours += labour.Quant;
              }
            });
          }
        });

        totalHoursByProduct[`${product.Name}_${productIndex}`] = totalHours;
      }
    });

    setTotalHoursByProduct(totalHoursByProduct);
    setLaborNamesByProduct(laborNamesByProduct);
  }

  function computeRemovalsTotal(removalsArray, labourArray) {
    const totalRemovalsByItem = {};
    const assignedLabours = new Set();

    removalsArray.forEach((removal, removalIndex) => {
      let totalRemovals = 0;

      labourArray.forEach((labour) => {
        if (assignedLabours.has(labour.Id)) return; // Skip already assigned labours

        const removalMatch = removalsArray.reduce((closest, r) => {
          if (
            labour.Notes.includes("Removals") &&
            labour.Notes.split("-").some((part) => r.Name.includes(part.trim()))
          ) {
            const currentDiff = Math.abs(
              r.Price + r.LaborSubtotal - labour.Price
            );
            const closestDiff = closest
              ? Math.abs(closest.Price + closest.LaborSubtotal - labour.Price)
              : Infinity;

            return currentDiff < closestDiff ? r : closest;
          }
          return closest;
        }, null);

        if (
          removalMatch &&
          (removalMatch.Id === removal.Id ||
            removalMatch.Parent_Id === removal.Id)
        ) {
          totalRemovals += labour.Quant;
          assignedLabours.add(labour.Id); // Mark the labour as assigned
        }
      });

      totalRemovalsByItem[`${removal.Name}_${removalIndex}`] = totalRemovals;
    });

    setTotalRemovalsByItem(totalRemovalsByItem);
  }

  const UpdateUIBasedOnStatus = async (quoteobj) => {
    let userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      quoteobj.QuoteStatus === QuoteStatus.RevisionRequested &&
      userinfo.Role === UserRole.Manager
    ) {
      setRevisionDeclineDisplay("block");
      setRevisionAcceptDisplay("block");
    }
    if (
      userinfo.Role === UserRole.Manager ||
      userinfo.Role === UserRole.Estimator
    ) {
      setCloneProposalButtonDisplay("block");
    }
  };

  const ChangeUIToMakeRevisions = async () => {
    setHeaderInputShown(3);
    setMakeRevisionsButtonDisplay("none");
    setDiscardButtonDisplay("block");
    setSaveRevisionsButtonDisplay("block");
  };

  const UpdateQuoteItemQuantity = async (itemid, itemquantity) => {
    var reverseQuantity = await ReverseQuant_QuoteItem(itemid, itemquantity);
    Quote.Material.Items.find(function (o, i) {
      if (o.Id === itemid) {
        o.Quant = itemquantity;
        o.Price = reverseQuantity * o.UnitPrice;
        setSupplyTotalOpacity("40%");
        setInstallTotalOpacity("40%");
      }
      return null;
    });
    //Change Delete State to force a rerender in React
    setDeleteState(!deleteState);
  };

  const ValidateAgreement = async (supplyonly, caseid, quoteid) => {
    if (supplyonly === true) {
      var databuy = await SetQuoteTo_Buy(quoteid);
      if (databuy !== null) {
        PushToPayment(deposit.toFixed(2), caseid, quoteid);
      } else setErrorAgreementText("Please try again!");
    } else {
      var databuyinstall = await SetQuoteTo_Buy_Install(quoteid);
      if (databuyinstall !== null) {
        var depositSelect =
          deposit < Stored_Values.MIN_DEPOSIT
            ? Stored_Values.MIN_DEPOSIT
            : deposit;
        PushToPayment(depositSelect.toFixed(2), caseid, quoteid);
      } else setErrorAgreementText("Please try again!");
    }
    //PushToPayment(deposit.toFixed(2), caseid, quoteid)
  };

  const PushToPayment = async (amount, caseid, quoteid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      if (data.Case !== null) {
        var token = axios.defaults.headers.common["Authorization"];
        if (token) {
          var tokenRef = token.substring(6);
        } else {
          tokenRef = "am9zaHRlc3QxMDU6QWExMjM0NTY3OCQ=";
        }
        var name =
          data.Case.Name +
          ", " +
          data.Case.Street +
          "," +
          data.Case.City +
          " on Project # " +
          data.Case.CaseNumber;
        var transactionid = data.Case.CaseNumber;
        var paymenturl = await MakeStripePay(
          amount,
          "CAD",
          name,
          transactionid,
          quoteid,
          data.Case.Store_Id,
          tokenRef,
          0
        );
        if (paymenturl !== null) {
          window.open(paymenturl, "_blank");
        }
      }
    }
  };

  const SetCurrentTime = async () => {
    var curtime = await GetCurrentTime();
    setCurTime(curtime);
  };

  const ref = React.createRef();
  const ref2 = React.createRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [12, 40],
  };

  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const SetQuoteDisplay = async (quote) => {
    if (quote != null) {
      if (
        userinfo.Role === UserRole.Installer ||
        userinfo.Role === UserRole.Manager ||
        userinfo.Role === UserRole.Estimator
      ) {
        setLaborDetailView("block");
      }
      if (
        userinfo.Role === UserRole.Manager ||
        userinfo.Role === UserRole.Estimator ||
        userinfo.Role === UserRole.Accountant
      ) {
        setShowGenerateInvoice("block");
        setShowLabourBreakdown("flex");
      }

      if (
        quote.Material?.Items.filter(
          (item) => item.Parent_Id === 0 && item.Tag_Id === 0
        ).length > 0
      )
        setGeneralMaterialsHeader("flex");

      if (quote.Delivery?.Tools?.length > 0) setDeliveryHeader("flex");

      if (quote.Tool?.Tools?.length > 0) setToolsMachinesHeader("flex");

      if (quote.Removal?.Items?.length > 0) setRemovalHeader("flex");

      if (quote.AccessArea?.Items?.length > 0) setAccessDisplay("flex");
    }
  };

  const RouteToPrevious = async (role) => {
    if (role == UserRole.User || role == UserRole.VIP) {
      history.push(
        "/viewcaseuser/" +
          params.Id +
          "/" +
          params.storeId +
          "/" +
          params.caseId
      );
    } else if (params.lastrevision == 0) {
      history.push(
        "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      );
    } else {
      history.push(
        "/viewcaseuser/" +
          params.Id +
          "/" +
          params.storeId +
          "/" +
          params.caseId
      );
    }
  };

  //Project Comment Files
  const [previewImage, setPreviewImage] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    SetHeaderAuthorization();
    GetProposalInformation(params.quoteId);
    GetProjectInformation(params.caseId);
    DisplayDepositButton();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (params.numberofquotes === 0) {
      setMakeRevisionsButtonDisplay("none");
      setHideProjectConfirmationDisplay("none");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    SetCurrentTime();
    SetQuoteDisplay(Quote);
  }, [Quote]); // eslint-disable-line react-hooks/exhaustive-deps

  var observe;
  if (window.attachEvent) {
    observe = function (element, event, handler) {
      element?.attachEvent("on" + event, handler);
    };
  } else {
    observe = function (element, event, handler) {
      element?.addEventListener(event, handler, false);
    };
  }
  function init() {
    var text = document.getElementById("text");
    if (text !== null) {
      function resize() {
        text.style.height = "auto";
        text.style.height = text.scrollHeight + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }

  const formattedDateTime = async () => {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString("default", { month: "short" });
    const year = now.getFullYear();
    let hour = now.getHours();
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minute = now.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
  };

  const downloadPDF = async () => {
    const timestamp = await formattedDateTime();
    const input = document.getElementById("estimate");

    // const includeElement = document.getElementById('include-on-pdf');
    // const originalDisplayStyle = includeElement.style.display;
    // includeElement.style.display = 'block';

    html2canvas(input, {
      useCss: true,
      scale: 1, // Reduce the size of the canvas
      ignoreElements: (element) => {
        return element.id === "exclude-from-pdf";
      },
    }).then((canvas) => {
      // includeElement.style.display = originalDisplayStyle;

      const imgData = canvas.toDataURL("image/jpeg", 1); // Use JPEG with 50% quality
      const pdf = new jsPDF("p", "mm", "a4"); // set the PDF to A4 size
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pageWidth;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      let pdfPages = Math.ceil(pdfHeight / pageHeight);

      for (let i = 0; i < pdfPages; i++) {
        if (i !== 0) {
          pdf.addPage();
        }
        pdf.addImage(imgData, "PNG", 0, -(i * pageHeight), pdfWidth, pdfHeight);
      }

      pdf.save(`${timestamp} ${estimateName} Construction View.pdf`);
    });
  };

  useEffect(() => {
    init();
  }, [Quote.Notes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (commentText.length > 0) {
      setSendButtonDisplay("block");
    } else {
      setSendButtonDisplay("none");
    }
  }, [commentText]);

  useEffect(() => {
    if (previewImage !== "") {
      setSendButtonDisplay("block");
    } else {
      setSendButtonDisplay("none");
    }
  }, [previewImage]);

  if (Quote !== null && Quote !== undefined) {
    if (Quote.Material !== null && Quote.Material !== undefined) {
      if (window.$ISMOBILE === true) {
        return (
          <Cont>
            <LeftCont>
              <Menu> </Menu>
            </LeftCont>
            <FlexColumn81vwLightGrey>
              <TopMentProposalView
                currentPage={"constructionView"}
                mobilePageProp={estimateName}
                pageProp={projectName}
                estimateName={estimateName}
                homeownerName={homeownerName}
                projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
                scrollinfo={() => {
                  scrollToOverview.scrollIntoView();
                }}
                scrollsupply={() => {
                  scrollToSupply.scrollIntoView();
                }}
                scrollinstall={() => {
                  scrollToInstall.scrollIntoView();
                }}
                scrolldesigns={() => {
                  scrollToDesigns.scrollIntoView();
                }}
                scrollcomments={() => {
                  scrollToComments.scrollIntoView();
                }}
                scrollconfirmation={() => {
                  scrollToConfirm.scrollIntoView();
                }}
              ></TopMentProposalView>

              <FlexRowContFlexStartMobileColumn
                height="auto"
                display={
                  Quote.QuoteStatus === QuoteStatus.RevisionRequested
                    ? "none"
                    : "flex"
                }
              ></FlexRowContFlexStartMobileColumn>

              {/* Proposal Overview --------------------------------------------------------------- */}
              <RevisionInstructionsWindow
                open={revisionInstructionDisplay}
                CloseWindow={() => {
                  setRevisionInstructionDisplay(false);
                }}
                StartRevising={() => {
                  ChangeUIToMakeRevisions();
                  setRevisionInstructionDisplay(false);
                }}
              ></RevisionInstructionsWindow>

              <DuplicateProposalWindow
                quoteObj={Quote}
                onClose={() => {
                  setCloneProposalWindowDisplay(false);
                }}
                open={cloneProposalWindowDisplay}
              ></DuplicateProposalWindow>
              <ImageViewWindow
                open={imageGalleryDisplay}
                imgurl={imagePreviewUrl}
                onClose={() => {
                  setImageGalleryDisplay(false);
                  document.body.style.overflow = "unset";
                }}
              ></ImageViewWindow>

              <FlexRowContAutox100PercentMobileColumn
                alignitems="baseline"
                margin="0"
              >
                <ContentContQuote id="install">
                  <FlexColumnFullWidth>
                    <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                      <FlexColumnFullWidth>
                        <FlexRowContFlexStart>
                          <LeagueSpartanLarge>
                            {projectName} - {estimateName} - Construction View
                          </LeagueSpartanLarge>
                        </FlexRowContFlexStart>
                      </FlexColumnFullWidth>
                    </FlexRowCont100PercentSpaceBetween>
                  </FlexColumnFullWidth>

                  <FlexColumnFullWidth
                    display={installEstimateDisplay}
                    height="auto"
                    ref={ref2}
                  >
                    <AutoSizeOverflowY>
                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {Quote.Tags.map(
                        (t, j) => (
                          (MaterialSum = 0),
                          (InstallSum = 0),
                          (TotalSum = 0),
                          (
                            <div>
                              <div>
                                <br></br>

                                <FlexRowCont100PercentSpaceBetween>
                                  <LeagueSpartanRegular>
                                    {t.TagName}
                                  </LeagueSpartanRegular>
                                </FlexRowCont100PercentSpaceBetween>

                                <StyledHRGreenBreakThick></StyledHRGreenBreakThick>

                                {Quote.Material.Items.filter(
                                  (item) =>
                                    item.Tag_Id == t.Id && item.Parent_Id === 0
                                ).map((o, i) => (
                                  <MobileQuoteItem
                                    noChange={o.NoChange}
                                    key={i}
                                    inputheaderposition={headerInputShown}
                                    OutputValue={(value) => {
                                      UpdateQuoteItemQuantity(o.Id, value);
                                    }}
                                    quoteItemObj={o}
                                    parentid={o.Parent_Id}
                                    header1="Delete"
                                    header2={o.Name}
                                    subheader2notes={
                                      o.Notes == null ? "" : o.Notes + ""
                                    }
                                    subheader2={
                                      o.Description === null
                                        ? ""
                                        : o.Description
                                    }
                                    header3={
                                      o.Quant.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      ) +
                                      " " +
                                      o.DisplayUnit
                                    }
                                    header4={
                                      "$" +
                                      o.UnitPrice.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header5={
                                      "$" +
                                      o.Price.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header6={
                                      "$" +
                                      (o.LaborSubtotal / o.Quant).toFixed(4)
                                    }
                                    header7={
                                      "$" +
                                      o.LaborSubtotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header8={
                                      "$" +
                                      (
                                        parseFloat(o.Price.toFixed(2)) +
                                        parseFloat(o.LaborSubtotal.toFixed(2))
                                      )
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    header3title="Quantity"
                                    header4title="Unit Rate"
                                    header5title="Material Subtotal"
                                    header6title="Install Rate"
                                    header7title="Install Subtotal"
                                  ></MobileQuoteItem>
                                ))}
                              </div>
                            </div>
                          )
                        )
                      )}

                      <div>
                        <br></br>

                        <FlexRowCont100PercentSpaceBetween>
                          <LeagueSpartanRegular
                            display={generalMaterialsHeader}
                          >
                            General Materials
                          </LeagueSpartanRegular>
                        </FlexRowCont100PercentSpaceBetween>

                        <StyledHRGreenBreakThick
                          display={generalMaterialsHeader}
                        ></StyledHRGreenBreakThick>

                        {Quote.Material.Items.map((o, i) => {
                          return o.Parent_Id === 0 && o.Tag_Id === 0 ? (
                            <MobileQuoteItem
                              key={i}
                              quoteItemObj={o}
                              inputheaderposition={headerInputShown}
                              OutputValue={(value) => {
                                UpdateQuoteItemQuantity(o.Id, value);
                              }}
                              noChange={o.NoChange}
                              parentid={o.Parent_Id}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={
                                o.Description === null ? "" : o.Description
                              }
                              subheader2notes={
                                o.Notes == null ? "" : o.Notes + ""
                              }
                              header3={o.Quant.toFixed(2) + " " + o.DisplayUnit}
                              header4={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header5={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header6={
                                "$" + (o.LaborSubtotal / o.Quant).toFixed(4)
                              }
                              header7={
                                "$" +
                                o.LaborSubtotal.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                (
                                  parseFloat(o.Price.toFixed(2)) +
                                  parseFloat(o.LaborSubtotal.toFixed(2))
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              header3title="Quantity"
                              header4title="Unit Rate"
                              header5title="Material Subtotal"
                              header6title="Install Rate"
                              header7title="Install Subtotal"
                            ></MobileQuoteItem>
                          ) : null;
                        })}
                      </div>

                      <LeagueSpartanRegular display={deliveryHeader}>
                        Delivery
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        display={deliveryHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Delivery.Tools.map((o, i) => (
                        <MobileQuoteItem
                          key={i}
                          header1=" "
                          subheader2={o.Notes === null ? "" : o.Notes}
                          header3={o.Quant.toFixed(2).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                          header4={
                            "$" +
                            o.BasePrice.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            "/ Load"
                          }
                          header8={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          header5={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          header3title="Quantity"
                          header4title="Delivery Rate"
                          header5title="Delivery Subtotal"
                        ></MobileQuoteItem>
                      ))}

                      <LeagueSpartanRegular display={removalHeader}>
                        Removals
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={removalHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Removal.Items.map((o, i) => (
                        <MobileQuoteItem
                          key={i}
                          header1="Delete"
                          header2={o.Name}
                          subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                          header3={
                            o.Quant.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            " " +
                            o.DisplayUnit
                          }
                          header4={
                            "$" +
                            ((o.Price + o.LaborSubtotal) / o.Quant)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header5={
                            "$" +
                            (o.Price + o.LaborSubtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header8={
                            "$" +
                            (o.Price + o.LaborSubtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header3title="Quantity"
                          header4title="Removal Rate"
                          header5title="Removal Subtotal"
                        ></MobileQuoteItem>
                      ))}

                      <LeagueSpartanRegular display={accessDisplay}>
                        Access Area
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        display={accessDisplay}
                      ></StyledHRGreenBreakThick>

                      {Quote.AccessArea.Items.map((o, i) => (
                        <MobileQuoteItem
                          key={i}
                          header1="Delete"
                          subheader2={o.Notes}
                          header3={
                            o.Quant.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            " " +
                            o.DisplayUnit
                          }
                          header4={
                            "$" +
                            o.UnitPrice.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            "" +
                            o.DisplayUnit
                          }
                          header5={
                            "$" +
                            o.Price.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )
                          }
                          header3title="Quantity"
                          header4title="Access Rate"
                          header5title="Access Subtotal"
                        ></MobileQuoteItem>
                      ))}

                      <LeagueSpartanRegular display={toolsmachinesHeader}>
                        Tools/Machines
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={toolsmachinesHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Tool.Tools.map((o, i) => {
                        return o?.ToolName.includes("Wheelbarrow") ? null : (
                          <MobileQuoteItem
                            key={i}
                            header1="Delete"
                            header2={o.ToolName}
                            subheader2={o.Notes !== null ? o.Notes : ""}
                            itemLink={o.VideoUrl}
                            header3={
                              o.RentalLength > 0
                                ? o.RentalLength.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ) + " hr"
                                : o.Quant.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ) + " day"
                            }
                            header4={
                              o.RentalRate > 0
                                ? "$" +
                                  (o.RentalRate * o.RentalLength)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                            }
                            header5={
                              o.RentalRate > 0 && o.BasePrice > 0
                                ? "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : ""
                            }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header3title="Quantity"
                            header4title="Rental Rate"
                            header5title={
                              o.RentalRate > 0 && o.BasePrice > 0
                                ? "Move Cost Per Machine"
                                : ""
                            }
                          ></MobileQuoteItem>
                        );
                      })}

                      <LeagueSpartanRegular display={laborDetailToggleView}>
                        Labour Breakdown (Staff Only)
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={laborDetailToggleView}
                      ></StyledHRGreenBreakThick>

                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {laborDetailToggleView === "flex"
                        ? Quote.Labor.Items.map((o, i) => (
                            <MobileQuoteItem
                              key={i}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={o.Notes}
                              header6={
                                o.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                ) + " hr"
                              }
                              header7={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                            ></MobileQuoteItem>
                          ))
                        : null}

                      <br></br>
                    </AutoSizeOverflowY>
                  </FlexColumnFullWidth>
                </ContentContQuote>
              </FlexRowContAutox100PercentMobileColumn>
            </FlexColumn81vwLightGrey>

            <FixedIconToTop
              src="/BackToTopArrowAlt.svg"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            ></FixedIconToTop>
          </Cont>
        );
      }
      /////////////////////////// MOBILE ->>>>>>>>>>>>>

      /////////////////////////// DESKTOP ->>>>>>>>>>>>>

      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey id="estimate">
            <TopMentProposalView
              currentPage={"constructionView"}
              mobilePageProp={estimateName}
              pageProp={projectName}
              estimateName={estimateName}
              homeownerName={homeownerName}
              projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
              onBack={() => {
                RouteToPrevious(userinfo.Role);
              }}
              scrollinfo={() => {
                scrollToOverview.scrollIntoView();
              }}
              scrollsupply={() => {
                scrollToSupply.scrollIntoView();
              }}
              scrollinstall={() => {
                scrollToInstall.scrollIntoView();
              }}
              scrolldesigns={() => {
                scrollToDesigns.scrollIntoView();
              }}
              scrollcomments={() => {
                scrollToComments.scrollIntoView();
              }}
              scrollconfirmation={() => {
                scrollToConfirm.scrollIntoView();
              }}
            ></TopMentProposalView>
            {/* Approval --------------------------------------------------------------- */}

            <FlexRowContFlexStartMobileColumn
              height="auto"
              display={
                Quote.QuoteStatus === QuoteStatus.RevisionRequested
                  ? "none"
                  : "flex"
              }
            >
              <FlexRowCont alignitems="stretch" height="auto"></FlexRowCont>
            </FlexRowContFlexStartMobileColumn>

            {/* Proposal Overview --------------------------------------------------------------- */}
            <RevisionInstructionsWindow
              open={revisionInstructionDisplay}
              CloseWindow={() => {
                setRevisionInstructionDisplay(false);
              }}
              StartRevising={() => {
                ChangeUIToMakeRevisions();
                setRevisionInstructionDisplay(false);
              }}
            ></RevisionInstructionsWindow>
            <ImageViewWindow
              open={imageGalleryDisplay}
              imgurl={imagePreviewUrl}
              onClose={() => {
                setImageGalleryDisplay(false);
                document.body.style.overflow = "unset";
              }}
            ></ImageViewWindow>
            <DuplicateProposalWindow
              quoteObj={Quote}
              onClose={() => {
                setCloneProposalWindowDisplay(false);
              }}
              open={cloneProposalWindowDisplay}
            ></DuplicateProposalWindow>

            <FlexRowContAutox100PercentMobileColumn
              alignitems="baseline"
              margin="0"
            >
              <ContentContQuote id="install">
                <FlexColumnFullWidth>
                  <FlexDiv>
                    <LeagueSpartanLarge>
                      {estimateName} - Construction View -{" "}
                      {decimalHoursToTimeString(projectLength)}
                    </LeagueSpartanLarge>
                    <RegularResponsiveButton
                      id="exclude-from-pdf"
                      display={labourBreakdownDisplay}
                      onClick={downloadPDF}
                    >
                      Download PDF
                    </RegularResponsiveButton>
                  </FlexDiv>
                </FlexColumnFullWidth>

                <FlexColumnFullWidth
                  display={installEstimateDisplay}
                  height="auto"
                  ref={ref2}
                >
                  <AutoSizeOverflowY>
                    {Quote.Tags.map((t, j) => {
                      let installSubSumByTag = {};
                      let timeSumByTag = {};
                      const quoteItems = Quote.Material.Items.filter(
                        (item) => item.Tag_Id == t.Id && item.Parent_Id === 0
                      ).map((o, i) => {
                        const installSub =
                          showRelated === true
                            ? o.LaborSubtotal
                            : o.LaborSubtotalWithRelatedItems;

                        if (!installSubSumByTag.hasOwnProperty(o.Tag_Id)) {
                          installSubSumByTag[o.Tag_Id] = 0;
                          timeSumByTag[o.Tag_Id] = 0;
                        }

                        installSubSumByTag[o.Tag_Id] += installSub;

                        // Get the index of the item in the original array
                        const originalIndex = Quote.Material.Items.findIndex(
                          (item) => item.Id === o.Id
                        );

                        // Get the total labor hours for this item's name
                        const totalHours =
                          totalHoursByProduct[`${o.Name}_${originalIndex}`] ||
                          0;

                        timeSumByTag[o.Tag_Id] +=
                          totalHoursByProduct[`${o.Name}_${originalIndex}`];
                        const laborNames = laborNamesByProduct[
                          `${o.Name}_${originalIndex}`
                        ]
                          ? Array.from(
                              laborNamesByProduct[`${o.Name}_${originalIndex}`]
                            ).join(", ")
                          : "";

                        const laborName = laborNames
                          ? laborNames.split(", ")[0]
                          : ""; // Adjust as needed if multiple names

                        // Find the corresponding labor item in Quote.Labor.Items
                        const laborItem = Quote.Labor.Items.find(
                          (item) => item.Name === laborName
                        );

                        // Get the price from the labor item, or default to 175 if not found
                        const hourlyRate = laborItem
                          ? laborItem.UnitPrice
                          : 154;
                        return (
                          <QuoteItem
                            noChange={o.NoChange}
                            constructionView={true}
                            key={i}
                            showRelatedItems={showRelated}
                            onClick={() => {
                              setShowRelated(!showRelated);
                            }}
                            inputheaderposition={headerInputShown}
                            OutputValue={(value) => {
                              UpdateQuoteItemQuantity(o.Id, value);
                            }}
                            quoteItemObj={o}
                            parentid={o.Parent_Id}
                            header1="Delete"
                            header2={o.Name}
                            subheader2notes={
                              o.Notes == null ? "" : o.Notes + ""
                            }
                            subheader2={
                              o.Description === null ? "" : o.Description
                            }
                            header3={
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              " " +
                              o.DisplayUnit
                            }
                            //   header4={
                            //     "$" + (materialSub / o.Quant).toFixed(4)
                            //   }
                            //   header5={
                            //     "$" +
                            //     materialSub
                            //       .toFixed(2)
                            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            //   }
                            //   header6={
                            //     "$" + (installSub / o.Quant).toFixed(4)
                            //   }
                            header6={laborNames ? ` ${laborNames}` : ""}
                            header7={decimalHoursToTimeString(
                              installSub / hourlyRate
                            )}
                            header8={
                              "$" +
                              installSub
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                          ></QuoteItem>
                        );
                      });

                      Object.keys(timeSumByTag).forEach((tagId) => {
                        timeSumByTag[tagId] = decimalHoursToTimeString(
                          timeSumByTag[tagId]
                        );
                      });

                      return (
                        <div>
                          <div>
                            {quoteItems.length > 0 ? (
                              <QuoteItem
                                isHeader={true}
                                header2={t.TagName}
                                header3="Quantity"
                                header6="Crew"
                                header7="Time Allowed"
                                header8="Install Subtotal"
                                bgColor={ColorPalette.White}
                              ></QuoteItem>
                            ) : null}

                            {quoteItems.length > 0 ? (
                              <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                            ) : null}

                            {quoteItems}

                            {quoteItems.length > 0 && (
                              <QuoteItem
                                isHeader={true}
                                bgColor={ColorPalette.White}
                                header2={`Subtotal`}
                                // header7={timeSumByTag[t.Id]}
                                header8={
                                  "$" +
                                  installSubSumByTag[t.Id]
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              ></QuoteItem>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div>
                      {generalMaterialsHeader === "flex" ? <br></br> : null}

                      {generalMaterialsHeader === "flex" ? (
                        <QuoteItem
                          isHeader={true}
                          header2="General Materials"
                          header3="Quantity"
                          header4=""
                          header5=""
                          header6="Crew"
                          header7="Time Allowed"
                          header8="Install Subtotal"
                          bgColor={ColorPalette.White}
                        ></QuoteItem>
                      ) : null}

                      <StyledHRGreenBreakThick
                        display={generalMaterialsHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Material.Items.map((o, i) => {
                        if (o.Parent_Id === 0 && o.Tag_Id === 0) {
                          const materialSub =
                            showRelated === true
                              ? o.Price
                              : o.SubtotalWithRelatedItems;

                          const installSub =
                            showRelated === true
                              ? o.LaborSubtotal
                              : o.LaborSubtotalWithRelatedItems;

                          MaterialSum += o.Price;
                          InstallSum += o.LaborSubtotal;
                          TotalSum += o.Price + o.LaborSubtotal;

                          // Get the index of the item in the original array
                          const originalIndex = Quote.Material.Items.findIndex(
                            (item) => item.Id === o.Id
                          );

                          // Get the total labor hours for this item's name
                          const totalHours =
                            totalHoursByProduct[`${o.Name}_${originalIndex}`] ||
                            0;

                          const laborNames = laborNamesByProduct[
                            `${o.Name}_${originalIndex}`
                          ]
                            ? Array.from(
                                laborNamesByProduct[
                                  `${o.Name}_${originalIndex}`
                                ]
                              ).join(", ")
                            : "";

                          const laborName = laborNames
                            ? laborNames.split(", ")[0]
                            : ""; // Adjust as needed if multiple names

                          // Find the corresponding labor item in Quote.Labor.Items
                          const laborItem = Quote.Labor.Items.find(
                            (item) => item.Name === laborName
                          );

                          // Get the price from the labor item, or default to 175 if not found
                          const hourlyRate = laborItem
                            ? laborItem.UnitPrice
                            : 154;

                          noTagTimeSubtotal +=
                            totalHoursByProduct[`${o.Name}_${originalIndex}`] ||
                            0;
                          noTagInstallSubtotal += installSub;

                          return (
                            <QuoteItem
                              showRelatedItems={showRelated}
                              constructionView={true}
                              onClick={() => {
                                setShowRelated(!showRelated);
                              }}
                              key={i}
                              quoteItemObj={o}
                              inputheaderposition={headerInputShown}
                              OutputValue={(value) => {
                                UpdateQuoteItemQuantity(o.Id, value);
                              }}
                              noChange={o.NoChange}
                              parentid={o.Parent_Id}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={
                                o.Description === null ? "" : o.Description
                              }
                              subheader2notes={
                                o.Notes == null ? "" : o.Notes + ""
                              }
                              header3={
                                o.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                ) +
                                " " +
                                o.DisplayUnit
                              }
                              header6={laborNames ? ` ${laborNames}` : ""}
                              header7={decimalHoursToTimeString(
                                installSub / hourlyRate
                              )}
                              header8={
                                "$" +
                                installSub
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            ></QuoteItem>
                          );
                        }
                        return null;
                      })}

                      {noTagInstallSubtotal > 0 && (
                        <QuoteItem
                          isHeader={true}
                          bgColor={ColorPalette.White}
                          header2={`Subtotal`}
                          // header7={decimalHoursToTimeString(noTagTimeSubtotal)}
                          header8={
                            "$" +
                            noTagInstallSubtotal
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        ></QuoteItem>
                      )}
                    </div>
                    {generalMaterialsHeader === "flex" ? <br></br> : null}
                    {deliveryHeader === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="Delivery"
                        header3="Quantity"
                        header4=""
                        header5=""
                        header6=""
                        header7=""
                        header8="Delivery Total"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={deliveryHeader}
                    ></StyledHRGreenBreakThick>
                    <>
                      {Object.values(
                        Quote.Delivery.Tools.reduce((acc, curr) => {
                          const price = curr.BasePrice.toFixed(2);
                          if (!acc[price]) {
                            acc[price] = { ...curr, Quant: 0 };
                          }
                          acc[price].Quant += curr.Quant;
                          return acc;
                        }, {})
                      ).map((o, i) => {
                        deliveryPriceSubtotal += o.BasePrice * o.Quant;
                        return (
                          <div key={i}>
                            <QuoteItem
                              showRelatedItems={showRelated}
                              onClick={() => {
                                setShowRelated(!showRelated);
                              }}
                              header1=" "
                              header2={`$${o.BasePrice.toFixed(2)} Deliveries`}
                              subheader2={`Deliveries at $${o.BasePrice.toFixed(
                                2
                              )} per load`}
                              header3={o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                              header5="-"
                              header4="-"
                              header8={
                                "$" +
                                (o.BasePrice * o.Quant)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                            ></QuoteItem>
                          </div>
                        );
                      })}
                    </>

                    {deliveryPriceSubtotal > 0 && (
                      <QuoteItem
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        header8={
                          "$" +
                          deliveryPriceSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItem>
                    )}

                    {deliveryHeader === "flex" ? <br></br> : null}
                    {removalHeader === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="Removals"
                        header3="Quantity"
                        header4=""
                        header5=""
                        header6=""
                        header7=""
                        header8="Removal Subtotal"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={removalHeader}
                    ></StyledHRGreenBreakThick>

                    {Quote.Removal.Items.map((o, i) => {
                      // Find the original index of the removal item in the removalsArray
                      const originalRemovalIndex =
                        Quote.Removal.Items.findIndex(
                          (removal) => removal.Id === o.Id
                        );
                      // Access the total removal time for each removal item using the totalRemovalsByItem object and the original index
                      const removalTime = decimalHoursToTimeString(
                        totalRemovalsByItem[
                          `${o.Name}_${originalRemovalIndex}`
                        ] || 0
                      );

                      removalTimeSubtotal +=
                        totalRemovalsByItem[
                          `${o.Name}_${originalRemovalIndex}`
                        ] || 0;
                      removalPriceSubtotal += o.Price + o.LaborSubtotal;

                      return (
                        <div key={i}>
                          <QuoteItem
                            header1="Delete"
                            header2={o.Name}
                            subheader2notes={
                              o.Notes == null ? "" : o.Notes + ""
                            }
                            showRelatedItems={showRelated}
                            onClick={() => {
                              setShowRelated(!showRelated);
                            }}
                            header3={
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              " " +
                              o.DisplayUnit
                            }
                            header4="-"
                            header5="-"
                            header6="-"
                            header7={removalTime}
                            header8={
                              "$" +
                              (o.Price + o.LaborSubtotal)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            header9="-"
                          ></QuoteItem>
                        </div>
                      );
                    })}

                    {removalPriceSubtotal > 0 && (
                      <QuoteItem
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        header7={decimalHoursToTimeString(removalTimeSubtotal)}
                        header8={
                          "$" +
                          removalPriceSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItem>
                    )}

                    {removalHeader === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}
                    {accessDisplay === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="Access Area"
                        header3=""
                        header4=""
                        header5=""
                        header6=""
                        header7=""
                        header8="Access Total"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={accessDisplay}
                    ></StyledHRGreenBreakThick>

                    {Quote.AccessArea.Items.map((o, i) => {
                      accessAreaPriceSubtotal += o.Price;

                      return (
                        <div key={i}>
                          <QuoteItem
                            showRelatedItems={showRelated}
                            onClick={() => {
                              setShowRelated(!showRelated);
                            }}
                            header1="Delete"
                            header2={o.Name}
                            subheader2={o.Notes}
                            header3={
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              " " +
                              o.DisplayUnit
                            }
                            header4="-"
                            header5="-"
                            header6={
                              "$" +
                              o.UnitPrice.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              "" +
                              o.DisplayUnit
                            }
                            header7={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                          ></QuoteItem>
                        </div>
                      );
                    })}

                    {accessAreaPriceSubtotal > 0 && (
                      <QuoteItem
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        header8={
                          "$" +
                          accessAreaPriceSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItem>
                    )}

                    {accessDisplay === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}
                    {toolsmachinesHeader === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="Tools/Machines"
                        header3="Quantity"
                        header4="Rental Length"
                        header5=""
                        header6="Rental Rate"
                        header7="Move Cost Per Machine"
                        header8="Machine Subtotal"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={toolsmachinesHeader}
                    ></StyledHRGreenBreakThick>

                    {Quote.Tool.Tools.map((o, i) => {
                      if (!o?.ToolName.includes("Wheelbarrow")) {
                        toolsPriceSubtotal += o.Price;

                        return (
                          <QuoteItem
                            key={i}
                            header1="Delete"
                            header2={o.ToolName}
                            subheader2={o.Notes !== null ? o.Notes : ""}
                            header6={
                              o.RentalRate > 0
                                ? "$" +
                                  o.RentalRate.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                            }
                            itemLink={o.VideoUrl}
                            header3={
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) + " day"
                            }
                            header4={
                              o.RentalLength.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) + " hr"
                            }
                            header7={
                              o.RentalRate > 0 && o.BasePrice > 0
                                ? "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : ""
                            }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                          ></QuoteItem>
                        );
                      }
                      return null;
                    })}

                    {toolsPriceSubtotal > 0 && (
                      <QuoteItem
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        header8={
                          "$" +
                          toolsPriceSubtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItem>
                    )}
                    {toolsmachinesHeader === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}
                    <FlexRowContFullWidth
                      onClick={() => {
                        DisplayLaborBreakdown(laborDetailToggle);
                      }}
                    >
                      <QuoteItem
                        isHeader={true}
                        header2="Labour Breakdown"
                        header3=""
                        header4=""
                        header5=""
                        header6="Quantity"
                        header7="Labour Rate"
                        header8="Line Subtotal"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    </FlexRowContFullWidth>
                    <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                    <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                    {(() => {
                      return Quote.Labor.Items.map((o, i) => {
                        total += o.Quant;
                        return (
                          <QuoteItem
                            key={i}
                            header1="Delete"
                            header2={o.Name}
                            subheader2={o.Notes}
                            header6={decimalHoursToTimeString(o.Quant)}
                            header7={
                              "$" +
                              o.UnitPrice.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                          ></QuoteItem>
                        );
                      }).concat(
                        <QuoteItem
                          key="total"
                          isHeader={true}
                          header2="Estimated Project Length:"
                          header8={`${total
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hr`}
                        />
                      );
                    })()}
                    <div>
                      <br></br>
                      <br></br>
                    </div>
                  </AutoSizeOverflowY>
                </FlexColumnFullWidth>
              </ContentContQuote>
            </FlexRowContAutox100PercentMobileColumn>
          </FlexColumn81vwLightGrey>
          <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop>
        </Cont>
      );
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

ViewProposalConstructionView.defaultProps = {
  ProfileName: "Default Name",
};

export default ViewProposalConstructionView;
