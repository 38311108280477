import React from "react";
import { Link } from "react-router-dom";
import { FlexCont300x150px } from "../../StylesheetComps/Cont";
import {
  Div30Percent,
  Div70Percent,
  FlexEndDivAutox15Percent,
} from "../../StylesheetComps/Div";
import { Icon80x80Percent } from "../../StylesheetComps/Imgs/Icons";
import {
  LeagueSpartanExtraLargeBold,
  TitleFontSize100Percent,
} from "../../StylesheetComps/Titles";

const StoreManagerCard = ({ CatHeadText, CatParaText, storeId }) => {
  return (
    <FlexCont300x150px>
      <Div30Percent>
        <Icon80x80Percent src="/storemanagement.svg"></Icon80x80Percent>
      </Div30Percent>
      <Div70Percent>
        <Link
          to={"/Store/" + storeId}
          style={{ textDecoration: "none", color: "black" }}
        >
          <LeagueSpartanExtraLargeBold>
            {CatHeadText}
          </LeagueSpartanExtraLargeBold>
        </Link>
        <TitleFontSize100Percent>{CatParaText}</TitleFontSize100Percent>
      </Div70Percent>

      <FlexEndDivAutox15Percent></FlexEndDivAutox15Percent>
    </FlexCont300x150px>
  );
};

StoreManagerCard.defaultProps = {
  CatHeadText: "Fencing",
  CatParaText:
    "This is a category for the products that are contained within, sample text",
  CatButtonText: "View Products",
  CatUrl: "",
  storeId: 0,
};

export default StoreManagerCard;
