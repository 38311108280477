import { useEffect, useState } from "react";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import { useHistory } from "react-router-dom";
import { ColorPalette } from "../../../StylesheetComps/Colors";

import { useParams } from "react-router-dom";
import { GetCaseById } from "../../../API_Functions/CaseQuote_Functions";
import { TopDivWhite } from "../../../MobileComponents/Divs";
import {
  MobileLogo30x30px,
  MobileLogo50x50px,
} from "../../../MobileComponents/Images";
import { MenuHeader } from "../../../MobileComponents/Text";
import { Header, SmallTextInput } from "../../../StylesheetComps/AuthSheet";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont82vw21vh,
  FlexDiv,
  RelativeFlexContAutoWidth13vh,
} from "../../../StylesheetComps/Cont";
import { FlexDivCenter, FlexDivSpaceBetween, MenuMainCont, MobileMainCont } from "../../../StylesheetComps/Div";
import { Image60x50Percent } from "../../../StylesheetComps/Imgs/Images";
import {
  FlexColumnCont,
  Icon,
  StickyTopMenuCont,
  TopMenuContAutoHeightSticky
} from "../../../StylesheetComps/Menu";
import Dropdown from "../../DropdownMenu";
import MobileSlideMenu from "../../MobileSlideMenu";
import { UserRole } from "../../../StylesheetComps/UserRoles";
import DuplicateProposalWindow from "../../Windows/SaveRevisionGuide";
import LoadingIndicator from "../../InteractiveLoadingBar";
import { Icon30px30pxPointer } from "../../../StylesheetComps/Quote";

const TopMentProposalView = ({
  pageProp,
  estimateName,
  onBack,
  homeownerName,
  scrollinfo,
  scrollsupply,
  scrollinstall,
  scrolldesigns,
  scrollcomments,
  mobilePageProp,
  currentPage,
  quoteObj,
  scrollconfirmation,
  saveAndSend,
  saveEstimate,
  handleLockInEstimate,
  handleDeleteLockedEstimate,
  downloadPDF,
  CreateSharableLink,
  OpenEstimateEmail,
  duplicateEstimate
}) => {
  const history = useHistory();
  const params = useParams();
  const [moreToggle, setMoreToggle] = useState(false);

  const [cityName, setCityName] = useState("");
  <Icon
    width="2%"
    height="100%"
    margintop="4px"
    src="/LocationIcon.svg"
  ></Icon>;

  const [sharableLink, setSharableLink] = useState("");
  const [sharableLinkInputDisplay, setSharableLinkInputDisplay] = useState("none");
  const iconmargin = '10px'
  const userinfo = JSON.parse(sessionStorage.getItem("userInfo"));


  const [cloneProposalButtonDisplay, setCloneProposalButtonDisplay] =
  useState("none");
const [cloneProposalWindowDisplay, setCloneProposalWindowDisplay] =
  useState(false);

  const [menuSRC, setMenuSRC] = useState("/hamburger.svg");

  const ChangeIcon = async () => {
    if (menuSRC === "/hamburger.svg") {
      setMenuSRC("/CloseMobile.svg");
    } else {
      setMenuSRC("/hamburger.svg");
    }
  };

  const SetHeaderUI = async (case_id) => {
    if (case_id !== undefined && case_id !== 0) {
      var data = await GetCaseById(case_id);
      if (data !== null) {
        setCityName(data.Case.City);
      }
    }

    let userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (
      userinfo?.Role === UserRole.Manager ||
      userinfo?.Role === UserRole.Estimator
    ) {
      setCloneProposalButtonDisplay("block");
    }
  };

  useEffect(() => {
    SetHeaderUI(params.caseId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (window.$ISMOBILE === true && (userinfo?.Role === UserRole.Manager || userinfo?.Role === UserRole.Estimator)) {
    return (
      <div>
        <TopDivWhite>
          <MobileLogo50x50px
            onClick={() => {
              history.push("/home");
            }}
            src="/NRCLogoRegularBlack.png"
          ></MobileLogo50x50px>
          <MenuHeader>{mobilePageProp}</MenuHeader>
          <MobileLogo30x30px
            onClick={() => {
              setMoreToggle(!moreToggle);
              ChangeIcon(menuSRC);
            }}
            src={menuSRC}
          ></MobileLogo30x30px>
        </TopDivWhite>
        <MobileSlideMenu
          open={moreToggle}
          onCloseMenu={() => {
            setMoreToggle(false);
          }}
        ></MobileSlideMenu>
        {currentPage==='estimatorView' ?
                    <FlexDivSpaceBetween>
                                         <Icon30px30pxPointer title="Duplicate Estimate" margin={iconmargin} src='/duplicateestimate_icon.svg' onClick={()=>{duplicateEstimate()}}></Icon30px30pxPointer>
            <Icon30px30pxPointer title="Lock Estimate" margin={iconmargin} src='/lock_icon.svg' onClick={handleLockInEstimate}></Icon30px30pxPointer>

<Icon30px30pxPointer title="Unlock Estimate" margin={iconmargin} src='/unlock_icon.svg'  onClick={handleDeleteLockedEstimate}></Icon30px30pxPointer>

            <Icon30px30pxPointer title="Send Estimate via Email" margin={iconmargin} src='/grey_email.svg' onClick={ async()=>{
OpenEstimateEmail()
}} ></Icon30px30pxPointer>
  
<SmallTextInput width='0px'  value={sharableLink} type="text" id='linkText' inputdisplay={'none'}></SmallTextInput>
           
<Icon30px30pxPointer title="Download PDF" margin={iconmargin} src='/pdf_icon.svg'   onClick={downloadPDF}></Icon30px30pxPointer>
<Icon30px30pxPointer title="Generate Sharable Link" src='/share_icon.svg' onMouseOver={()=>{setSharableLinkInputDisplay('block'); }} onClick={ async()=>{
CreateSharableLink()
}} ></Icon30px30pxPointer>

            <Dropdown currentPage={currentPage} saveAndSend={()=>{saveAndSend()}} saveEstimate={()=>{saveEstimate()}} ></Dropdown>
            </FlexDivSpaceBetween>
            : null}
      </div>
    );
  } else 
  if (window.$ISMOBILE === true) {
    return (
      <div>
        <TopDivWhite>
          <MobileLogo50x50px
            onClick={() => {
              history.push("/home");
            }}
            src="/NRCLogoRegularBlack.png"
          ></MobileLogo50x50px>
          <MenuHeader>{mobilePageProp}</MenuHeader>
          <MobileLogo30x30px
            onClick={() => {
              setMoreToggle(!moreToggle);
              ChangeIcon(menuSRC);
            }}
            src={menuSRC}
          ></MobileLogo30x30px>
        </TopDivWhite>
        <MobileSlideMenu
          open={moreToggle}
          onCloseMenu={() => {
            setMoreToggle(false);
          }}
        ></MobileSlideMenu>
      </div>
    );
  } else if (params.viewonly) {
    return (
      <StickyTopMenuCont id='exclude-from-pdf'>
      <Cont82vw21vh width="100.5vw">
        <MobileMainCont>
          <RelativeFlexContAutoWidth13vh>
            <Image60x50Percent
              src={"/mobileprofile.svg"}
              onClick={() => {
                history.push("/profile");
              }}
            ></Image60x50Percent>

            <AddToHomeScreen />
          </RelativeFlexContAutoWidth13vh>
        </MobileMainCont>
      </Cont82vw21vh>

      <MenuMainCont></MenuMainCont>
      <TopMenuContAutoHeightSticky mobiledisplay="none" width='100vw'>
        <FlexColumnCont
          marginbottom="0%"
          margin="0"
          width="95%"
          height="0vh"
          mobileheight="8vh"
        ></FlexColumnCont>
        <FlexColumnCont
        width="94%"
        height="50px"
        padding="10px"
        alignitems="baseline"
        >
        <FlexColumnCont
          margin="0px"
          flexdirection="row"
          justifycontent="space-between"
          width="100%"
          height="auto"
          alignitems="left"
        >
          <FlexDivCenter>
          <Header
            fontsize="160%"
            margintop="0%"
            marginbottom="0%"
          >
           {estimateName}
          </Header>
          </FlexDivCenter>
          <FlexDiv>
          </FlexDiv>
        </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          margin="0"
          width="100%"
          height="20%"
          flexdirection="row"
          justifycontent="flex-start"
          alignitems="left"
          borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
        >
          <FlexColumnCont
            height="100%"
            margin="0"
            justifycontent="flex-start"
            alignitems="left"
            flexdirection="row"
            marginleft="2%"
          >
          </FlexColumnCont>
        </FlexColumnCont>
      </TopMenuContAutoHeightSticky>
    </StickyTopMenuCont>
    )
  } else {
    return (
      <StickyTopMenuCont id='exclude-from-pdf'>
        <Cont82vw21vh>
          <MobileMainCont>
            <RelativeFlexContAutoWidth13vh>
              <Image60x50Percent
                src={"/mobileprofile.svg"}
                onClick={() => {
                  history.push("/profile");
                }}
              ></Image60x50Percent>

              <AddToHomeScreen />
            </RelativeFlexContAutoWidth13vh>
          </MobileMainCont>
        </Cont82vw21vh>

        <MenuMainCont></MenuMainCont>
        <TopMenuContAutoHeightSticky mobiledisplay="none">
          <FlexColumnCont
            marginbottom="0%"
            margin="0"
            width="95%"
            height="0vh"
            mobileheight="8vh"
          ></FlexColumnCont>
          <FlexColumnCont
          width="94%"
          height="50px"
          padding="10px"
          alignitems="baseline"
          >
          <FlexColumnCont
            margin="0px"
            flexdirection="row"
            justifycontent="space-between"
            width="100%"
            height="auto"
            alignitems="left"
          >
            <FlexDivCenter>
            <RegularResponsiveButton onClick={() => {
                    onBack();
                  }} width="5px" minwidth="60px" marginright="10px" height="18px" lineheight="22px" bgcolor={ColorPalette.DarkGrey}>
                   {`←`} {` `} Back</RegularResponsiveButton> 
            <Header
              fontsize="160%"
              margintop="0%"
              marginbottom="0%"
            >
             {estimateName}
            </Header>
            </FlexDivCenter>
            <FlexDiv>
  
                   {/* <RegularResponsiveButton
                   marginright="15px"
                      display={cloneProposalButtonDisplay}
                      onClick={() => {
                        setCloneProposalWindowDisplay(true);
                      }}
                    >
                      Duplicate
                    </RegularResponsiveButton>
                    <DuplicateProposalWindow
              quoteObj={quoteObj}
              onClose={() => {
                setCloneProposalWindowDisplay(false);
              }}
              open={cloneProposalWindowDisplay}
            ></DuplicateProposalWindow> */}
            {currentPage==='estimatorView' ?
            <FlexDivSpaceBetween>
                   <Icon30px30pxPointer title="Duplicate Estimate" margin={iconmargin} src='/duplicateestimate_icon.svg' onClick={()=>{duplicateEstimate()}}></Icon30px30pxPointer>
            <Icon30px30pxPointer title="Lock Estimate" margin={iconmargin} src='/lock_icon.svg' onClick={handleLockInEstimate}></Icon30px30pxPointer>

<Icon30px30pxPointer title="Unlock Estimate" margin={iconmargin} src='/unlock_icon.svg'  onClick={handleDeleteLockedEstimate}></Icon30px30pxPointer>

            <Icon30px30pxPointer title="Send Estimate via Email" margin={iconmargin} src='/grey_email.svg' onClick={ async()=>{
OpenEstimateEmail()
}} ></Icon30px30pxPointer>
  
<SmallTextInput width='0px'  value={sharableLink} type="text" id='linkText' inputdisplay={'none'}></SmallTextInput>
           
<Icon30px30pxPointer title="Download PDF" margin={iconmargin} src='/pdf_icon.svg'   onClick={downloadPDF}></Icon30px30pxPointer>
<Icon30px30pxPointer title="Generate Sharable Link" src='/share_icon.svg' onMouseOver={()=>{setSharableLinkInputDisplay('block'); }} onClick={ async()=>{
CreateSharableLink()
}} ></Icon30px30pxPointer>

            <Dropdown currentPage={currentPage} saveAndSend={()=>{saveAndSend()}} saveEstimate={()=>{saveEstimate()}} ></Dropdown>
            </FlexDivSpaceBetween>
            : null}
            </FlexDiv>
          </FlexColumnCont>
          </FlexColumnCont>

          <FlexColumnCont
            margin="0"
            width="100%"
            height="20%"
            flexdirection="row"
            justifycontent="flex-start"
            alignitems="left"
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <FlexColumnCont
              height="100%"
              margin="0"
              justifycontent="flex-start"
              alignitems="left"
              flexdirection="row"
              marginleft="2%"
            >
            </FlexColumnCont>
          </FlexColumnCont>
        </TopMenuContAutoHeightSticky>
      </StickyTopMenuCont>
    );
  }
};

TopMentProposalView.defaultProps = {
  pageProp: "",
  estimateName: "",
  homeownerName:"",
  currentPage:"homeownerView",
  scrollinfo: () => {},
  scrollsupply: () => {},
  scrollinstall: () => {},
  scrolldesigns: () => {},
  scrollcomments: () => {},
  scrollconfirmation: () => {},
  saveAndSend: () => {},
  saveEstimate: () => {},
  onBack: ()=>{},
  handleLockInEstimate:()=>{},
  handleDeleteLockedEstimate:()=>{},
  downloadPDF:()=>{},
  CreateSharableLink:()=>{},
  OpenEstimateEmail:()=>{},
  duplicateEstimate:()=>{}
};

export default TopMentProposalView;
