/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { AuthCont } from "../../../StylesheetComps/AuthSheet";

//Left Container
import { LeftAuthCont } from "../../../StylesheetComps/AuthSheet";
import { LeftItemsCont } from "../../../StylesheetComps/AuthSheet";
import { Logo } from "../../../StylesheetComps/AuthSheet";
import { Header } from "../../../StylesheetComps/AuthSheet";
import { BodyPara } from "../../../StylesheetComps/AuthSheet";

//Right Container
import {
  RightAuthCont,
  MediumGreenButton,
  MediumWhiteButton,
} from "../../../StylesheetComps/AuthSheet";
import { RightItemsCont } from "../../../StylesheetComps/AuthSheet";
import { RightBarDiv } from "../../../StylesheetComps/AuthSheet";
import { LinkText } from "../../../StylesheetComps/AuthSheet";
import { PrivacyPolicy } from "../../../StylesheetComps/AuthSheet";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import { InputDiv } from "../../../StylesheetComps/AuthSheet";
import { SmallHeader } from "../../../StylesheetComps/Menu";
import { passwordStrength } from "check-password-strength";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import {
  Div100vw20vh,
  SignupItemContWithEffects,
  AuthPageCont,
} from "../../../MobileComponents/Divs";
import {
  LogoRelative,
  TopImage20vh,
  TopImage40vh,
} from "../../../MobileComponents/Images";
import { MobileWideGreenButton } from "../../../MobileComponents/Buttons";
import {
  ErrorMessage12px,
  UnderlinedBoldTitle16px,
} from "../../../MobileComponents/Text";

const InstallerRegisterPage = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false);

  //New User Credentials
  const [un, setUn] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [signUpForm, setSignUpForm] = useState(true);
  const [activateAccount, setActivateAccount] = useState(false);

  const [displayPasswordInfo, setDisplayPasswordInfo] = useState("none");

  const [passwordColor, setPasswordColor] = useState("white");

  const InstallerRegister = async (username, phone, email, password) => {
    if (pass !== confirmPass) {
      setErrorMessage("Passwords don't match!");
    } else if (un.includes(" ")) {
      setErrorMessage("Username cannot contain spaces");
    } else if (un === "" || phone === "" || email === "" || pass === "") {
      setErrorMessage("Please fill out all form inputs");
    } else if (passwordColor !== "green") {
      setErrorMessage("Password not strong enough!");
    } else {
      const resp = await axios
        .post(window.$DBURL + "users/installer", {
          Name: null,
          Username: username,
          Password: password,
          Platform: navigator.userAgent.substring(0, 50),
          Metro_Id: 0,
          City_Id: 0,
          Phone: phone,
          Email: email,
        })
        .catch(handleErrors);
      if (resp !== undefined) {
        setActivateAccount(true);
        setSignUpForm(false);
      }
    }
  };

  const CheckPasswordStrength = async (password) => {
    if (displayPasswordInfo === "none") {
      setDisplayPasswordInfo("flex");
    }
    switch (
      passwordStrength(password, [
        {
          id: 0,
          value: "Too weak",
          minDiversity: 0,
          minLength: 0,
        },
        {
          id: 1,
          value: "Weak",
          minDiversity: 2,
          minLength: 4,
        },
        {
          id: 2,
          value: "Medium",
          minDiversity: 3,
          minLength: 6,
        },
        {
          id: 3,
          value: "Strong",
          minDiversity: 4,
          minLength: 8,
        },
      ]).value
    ) {
      case "Too weak":
        setPasswordColor("red");
        break;

      case "Weak":
        setPasswordColor("orange");
        break;

      case "Medium":
        setPasswordColor("yellow");
        break;

      case "Strong":
        setPasswordColor("green");
        break;
      default:
    }
  };

  function handleErrors(err) {
    if (err.request) {
      setErrorMessage(err.response.data.Message);
    } else if (err.response) {
      setErrorMessage(err.response.data.Message);
    }
  }

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (isMobile !== false) {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        if (
          document.getElementById("headerimage") !== null &&
          document.getElementById("itemscontainer") !== null &&
          document.getElementById("logoimage") !== null
        ) {
          document.getElementById("headerimage").style.height = "15vh";
          document.getElementById("itemscontainer").style.top = "-10%";
          document.getElementById("logoimage").style.height = "70px";
          document.getElementById("logoimage").style.bottom = "25%";
        }
      } else {
        if (
          document.getElementById("headerimage") !== null &&
          document.getElementById("itemscontainer") !== null &&
          document.getElementById("logoimage") !== null
        ) {
          document.getElementById("headerimage").style.height = "20vh";
          document.getElementById("itemscontainer").style.top = "1.5%";
          document.getElementById("logoimage").style.height = "120px";
          document.getElementById("logoimage").style.bottom = "45%";
        }
      }
    }
  }

  useEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      setIsMobile(true);
    }
  }, []);

  if (isMobile == true) {
    if (signUpForm === true) {
      return (
        <AuthPageCont>
          <LeftAuthCont backgroundimg="/Signup Page Image.png">
            <LeftItemsCont>
              <Logo src="NRCLogoRegularBlack.png"></Logo>
              <Header headercolor="white">Welcome Back!</Header>
              <BodyPara bodycolor="white">
                Log into your New Rhodes Construction account here.
              </BodyPara>
              <MediumWhiteButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Log In
              </MediumWhiteButton>
            </LeftItemsCont>
          </LeftAuthCont>

          <RightAuthCont>
            <Div100vw20vh>
              <TopImage20vh
                id="headerimage"
                src="/Signup Page Image.png"
              ></TopImage20vh>
              <LogoRelative
                id="logoimage"
                src="/NRCLogoRegularBlack.png"
              ></LogoRelative>
            </Div100vw20vh>

            <br></br>
            <br></br>
            <br></br>

            <SignupItemContWithEffects id="itemscontainer">
              <Header marginbottom="0.5%">Installer Registration</Header>
              <BodyPara bodycolor="black" width="50%" marginbottom="3%">
              To find projects posted by homeowners, you need register as an installer. 
               You can use your email as username.
               <SmallHeader
                mobilefontsize="18px"
                cursor="pointer"
                display="block"
                mobiledisplay="block"
                margintop="5%"
                textdecoration="underline"
                color="blue"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                If you are a home owner to submit your project, click here to sign up
              </SmallHeader>
              </BodyPara>
              <InputDiv mobileflexdirection="column">
                <TextInput300x50
                  autoComplete="new-password"
                  placeholder="Email or Username"
                  mobilewidth="70vw"
                  inputwidth="7"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setUn(e.target.value);
                  }}
                ></TextInput300x50>
                <TextInput300x50
                  inputmarginleft="5%"
                  inputmarginleftmobile="0"
                  autoComplete="new-password"
                  placeholder="Cell Phone"
                  mobilewidth="70vw"
                  inputwidth="40%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                ></TextInput300x50>
                <TextInput300x50
                  autoComplete="new-password"
                  placeholder="Email Address"
                  mobilewidth="70vw"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></TextInput300x50>
                <TextInput300x50
                  autoComplete="new-password"
                  type="password"
                  placeholder="Password"
                  mobilewidth="70vw"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setPass(e.target.value);
                    CheckPasswordStrength(e.target.value);
                  }}
                ></TextInput300x50>
                <TextInput300x50
                  autoComplete="new-password"
                  type="password"
                  mobilewidth="70vw"
                  placeholder="Confirm Password"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                ></TextInput300x50>
              </InputDiv>
              <br></br>
              <ErrorMessage12px>{errorMessage}</ErrorMessage12px>
              <FlexColumnCont
                display={displayPasswordInfo}
                height="20%"
                padding="5px"
                margintop="5px"
                width="42%"
                margin="0"
              >
                <FlexColumnCont
                  mobilewidth="70vw"
                  borderradius="10px"
                  margin="0"
                  height="0.5%"
                  padding="5px"
                  margintop="-15px"
                  width="100%"
                  bgcolor={passwordColor}
                  border="1px solid #d3d3d3"
                ></FlexColumnCont>
                <BodyPara
                  bodycolor="grey"
                  fontsize="14px"
                  width="100%"
                  margintop="2.5%"
                >
                    Password must contain at least one lower- and upper-case letter, one number, 
                  one special character, and be longer than eight characters.
                </BodyPara>
              </FlexColumnCont>
              <MobileWideGreenButton
                onClick={() => {
                  InstallerRegister(un, phone, email, pass);
                }}
              >
                Register
              </MobileWideGreenButton>
              <UnderlinedBoldTitle16px
                onClick={() => {
                  history.push("/");
                }}
              >
                Have an account? Login here
              </UnderlinedBoldTitle16px>
            </SignupItemContWithEffects>

            <FlexColumnCont
              width="95%"
              height="5%"
              margintop="3.75vw"
              flexdirection="row"
              justifycontent="flex-end"
              alignitems="right"
            >
              <PrivacyPolicy>
                All rights reserved 2020 New Rhodes Construction Privacy Policy
              </PrivacyPolicy>
            </FlexColumnCont>
          </RightAuthCont>
        </AuthPageCont>
      );
    } else if (activateAccount === true) {
      return (
        <AuthCont>
          <LeftAuthCont backgroundimg="/Signup Page Image.png">
            <LeftItemsCont>
              <Logo src="NRCLogoRegularBlack.png"></Logo>
              <Header headercolor="white">Welcome Back!</Header>
              <BodyPara bodycolor="white">
                Log into your New Rhodes Construction account here.
              </BodyPara>
              <MediumWhiteButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Log In
              </MediumWhiteButton>
            </LeftItemsCont>
          </LeftAuthCont>

          <RightAuthCont>
            <Div100vw20vh>
              <TopImage40vh
                id="headerimage"
                src="/Signup Page Image.png"
              ></TopImage40vh>
              <LogoRelative
                id="logoimage"
                src="/NRCLogoRegularBlack.png"
              ></LogoRelative>
            </Div100vw20vh>

            <br></br>
            <br></br>
            <br></br>

            <RightItemsCont top="20%">
              <Header>Verify Your Account</Header>
              <BodyPara bodycolor="black" width="55%">
              Verify your account by clicking the verification link sent to your email or phone number.
              </BodyPara>
              <br></br>
              <MobileWideGreenButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Continue
              </MobileWideGreenButton>
            </RightItemsCont>

            <FlexColumnCont
              width="95%"
              height="5%"
              margintop="3.75vw"
              flexdirection="row"
              justifycontent="flex-end"
              alignitems="right"
            >
              <PrivacyPolicy>
                All rights reserved 2020 New Rhodes Construction Privacy Policy
              </PrivacyPolicy>
            </FlexColumnCont>
          </RightAuthCont>
        </AuthCont>
      );
    }
  } else {
    if (signUpForm === true) {
      return (
        <AuthCont>
          <LeftAuthCont backgroundimg="/Signup Page Image.png">
            <LeftItemsCont>
              <Logo src="NRCLogoRegularBlack.png"></Logo>
              <Header headercolor="white">Welcome Back!</Header>
              <BodyPara bodycolor="white">
                Log into your New Rhodes Construction account here.
              </BodyPara>
              <MediumWhiteButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Log In
              </MediumWhiteButton>
            </LeftItemsCont>
          </LeftAuthCont>

          <RightAuthCont>
            <RightBarDiv>
              <LinkText
                onClick={() => {
                  window.open("https://www.newrhodesconstruction.com/", "_blank");
                }}
              >
                Home
              </LinkText>
              <LinkText
                onClick={() => {
                  window.open("https://www.newrhodesconstruction.com/about/", "_blank");
                }}
              >
                About
              </LinkText>
              <LinkText
                onClick={() => {
                  window.open(
                    "https://www.newrhodesconstruction.com/contact-us/",
                    "_blank"
                  );
                }}
              >
                Contact
              </LinkText>
            </RightBarDiv>

            <RightItemsCont top="1.5%">
              <Header marginbottom="0.5%">Installer Registration</Header>
              <BodyPara bodycolor="black" width="50%" marginbottom="3%">
                To find projects posted by homeowners, you need register as an installer. 
                You can use your email as username.
                <SmallHeader
                mobilefontsize="18px"
                cursor="pointer"
                display="block"
                mobiledisplay="block"
                margintop="5%"
                textdecoration="underline"
                color="blue"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                If you are a home owner to submit your project, click here to sign up
              </SmallHeader>
              </BodyPara>
              <InputDiv mobileflexdirection="column">
                <TextInput300x50
                  autoComplete="new-password"
                  placeholder="Email or Username"
                  mobilewidth="62vw"
                  inputwidth="40%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setUn(e.target.value);
                  }}
                ></TextInput300x50>
                <TextInput300x50
                  inputmarginleft="5%"
                  inputmarginleftmobile="0"
                  autoComplete="new-password"
                  placeholder="Cell Phone"
                  mobilewidth="62vw"
                  inputwidth="40%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                ></TextInput300x50>
              </InputDiv>
              <InputDiv>
                <TextInput300x50
                  autoComplete="new-password"
                  placeholder="Email Address"
                  mobilewidth="62vw"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                ></TextInput300x50>
              </InputDiv>
              <InputDiv>
                <TextInput300x50
                  autoComplete="new-password"
                  type="password"
                  placeholder="Password"
                  mobilewidth="62vw"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setPass(e.target.value);
                    CheckPasswordStrength(e.target.value);
                  }}
                ></TextInput300x50>
              </InputDiv>
              <InputDiv>
                <TextInput300x50
                  autoComplete="new-password"
                  type="password"
                  mobilewidth="62vw"
                  placeholder="Confirm Password"
                  inputwidth="100%"
                  inputheight="2.5vh"
                  onChange={(e) => {
                    setConfirmPass(e.target.value);
                  }}
                ></TextInput300x50>
              </InputDiv>
              <SmallHeader margintop="2%" color="red">
                {errorMessage}
              </SmallHeader>
              <FlexColumnCont
                display={displayPasswordInfo}
                height="20%"
                padding="5px"
                margintop="5px"
                width="42%"
                margin="0"
              >
                <FlexColumnCont
                  mobilewidth="70vw"
                  borderradius="10px"
                  margin="0"
                  height="0.5%"
                  padding="5px"
                  margintop="-15px"
                  width="100%"
                  bgcolor={passwordColor}
                  border="1px solid #d3d3d3"
                ></FlexColumnCont>
                <BodyPara
                  bodycolor="grey"
                  fontsize="14px"
                  width="100%"
                  margintop="2.5%"
                >
                  Password must contain at least one lower- and upper-case letter, one number, 
                  one special character, and be longer than eight characters.
                </BodyPara>
              </FlexColumnCont>
              <MediumGreenButton
                onClick={() => {
                  InstallerRegister(un, phone, email, pass);
                }}
              >
                Register
              </MediumGreenButton>
              <SmallHeader
                mobilefontsize="18px"
                cursor="pointer"
                display="none"
                mobiledisplay="block"
                margintop="5%"
                textdecoration="underline"
                color="blue"
                onClick={() => {
                  history.push("/");
                }}
              >
                Have an account? Login here
              </SmallHeader>
            </RightItemsCont>

            <FlexColumnCont
              width="95%"
              height="5%"
              margintop="3.75vw"
              flexdirection="row"
              justifycontent="flex-end"
              alignitems="right"
            >
              <PrivacyPolicy>
                All rights reserved 2020 New Rhodes Construction Privacy Policy
              </PrivacyPolicy>
            </FlexColumnCont>
          </RightAuthCont>
        </AuthCont>
      );
    } else if (activateAccount === true) {
      return (
        <AuthCont>
          <LeftAuthCont backgroundimg="/Signup Page Image.png">
            <LeftItemsCont>
              <Logo src="NRCLogoRegularBlack.png"></Logo>
              <Header headercolor="white">Welcome Back!</Header>
              <BodyPara bodycolor="white">
                Log into your New Rhodes Construction account here.
              </BodyPara>
              <MediumWhiteButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Log In
              </MediumWhiteButton>
            </LeftItemsCont>
          </LeftAuthCont>

          <RightAuthCont>
            <RightBarDiv>
              <LinkText
                onClick={() => {
                  window.open("https://www.newrhodesconstruction.com/", "_blank");
                }}
              >
                Home
              </LinkText>
              <LinkText
                onClick={() => {
                  window.open("https://www.newrhodesconstruction.com/about/", "_blank");
                }}
              >
                About
              </LinkText>
              <LinkText
                onClick={() => {
                  window.open(
                    "https://www.newrhodesconstruction.com/contact-us/",
                    "_blank"
                  );
                }}
              >
                Contact
              </LinkText>
            </RightBarDiv>

            <RightItemsCont top="22%">
              <Header>Thank you!</Header>
              <Header margin="0" margintop="-2.5%">
                Welcome to New Rhodes Construction.
              </Header>
              <BodyPara bodycolor="black" width="55%">
                Please Log In and Select Your Region
              </BodyPara>
              <MediumGreenButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Continue
              </MediumGreenButton>
            </RightItemsCont>

            <FlexColumnCont
              width="95%"
              height="5%"
              margintop="3.75vw"
              flexdirection="row"
              justifycontent="flex-end"
              alignitems="right"
            >
              <PrivacyPolicy>
                All rights reserved 2020 New Rhodes Construction Privacy Policy
              </PrivacyPolicy>
            </FlexColumnCont>
          </RightAuthCont>
        </AuthCont>
      );
    }
  }
};

InstallerRegisterPage.defaultProps = {
  h1: "This is my default text",
};

export default InstallerRegisterPage;