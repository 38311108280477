import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/ActionMenu";
import { useParams } from "react-router-dom";
import {
  FlexRowCont,
  FlexColumnCont100xAutoPercent,
} from "../../StylesheetComps/Cont";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserRole } from "../../StylesheetComps/UserRoles";
import {
  FlexColumnFullWidth,
  LeftCont,
  FlexColumn81vwLightGrey,
} from "../../StylesheetComps/Cont";
import { Cont } from "../../StylesheetComps/Projects";
import { ContentCont77vw } from "../../StylesheetComps/Projects";
import { SmallText } from "../../StylesheetComps/Titles";
import { FlexColumnContBoxShadow } from "../../StylesheetComps/Menu";
import {
  Image300x200,
  SmallHeaderLeagueSpartan,
} from "../../StylesheetComps/LandscapeForm";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const PaymentFailScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Payment Success Screen - User redirected here on Successful Stripe Payment
  //=============================================================================

  //=============================================================================
  // User will redirect to QuoteAppointment Page to Book Appointment with Inst.
  //=============================================================================

  const [roleNum, setRoleNum] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const history = useHistory();
  const params = useParams();

  const GetProfileInfo = async () => {
    sessionStorage.setItem("token", params.token);
    axios.defaults.baseURL = window.$DBURL;
    axios.defaults.headers.common["Authorization"] = "Basic " + params.token;
    const resp = await axios.get(window.$DBURL + "users/profile");
    sessionStorage.setItem("userInfo", JSON.stringify(resp.data.user));
    setUserInfo(JSON.parse(sessionStorage.getItem("userInfo")));
    setRoleNum(resp.data.user.Role);
  };

  useEffect(() => {
    // SetHeaderAuthorization();
    GetProfileInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (roleNum !== UserRole.Loading) {
    return (
      <Cont>
        <LeftCont>
          {/* <Menu> </Menu> */}
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu></ActionMenu>

          <ContentCont77vw>
            <FlexColumnFullWidth display={"block"} height="auto" margin="0">
              <FlexColumnCont100xAutoPercent>
                <Image300x200 src={"/Payment_Error_Icon.svg"}></Image300x200>
                <SmallHeaderLeagueSpartan fontsize="24px">
                  Payment Not Completed
                </SmallHeaderLeagueSpartan>

                <br></br>
                <br></br>

                <FlexRowCont width="70%" mobileflexdirection="column">
                  <FlexColumnContBoxShadow>
                    <SmallHeaderLeagueSpartan>
                      Try a Different Payment Method
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
There's been an issue with your payment, please use a different payment method, or try again later. We're sorry for the inconvenience!
                    </SmallText>
                  </FlexColumnContBoxShadow>

                  <FlexColumnContBoxShadow>
                    <SmallHeaderLeagueSpartan>
                      Speak With Us Directly
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      Having difficulty with payment? Contact us to find a
                      solution.
                    </SmallText>
                    <a
                      rel="noreferrer"
                      href="https://www.newrhodesconstruction.com/contact-us/"
                      target={"_blank"}
                      style={{ "text-decoration": "none" }}
                    >
                      <RegularResponsiveButton>
                        Contact Us
                      </RegularResponsiveButton>
                    </a>
                  </FlexColumnContBoxShadow>
                </FlexRowCont>

                <br></br>
                <br></br>
              </FlexColumnCont100xAutoPercent>
            </FlexColumnFullWidth>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

PaymentFailScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default PaymentFailScreen;
