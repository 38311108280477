export const CaseStatus = {
    Drafted: 0,
    Submitted: 1,
    Accepted: 2,
    Processed: 3,
    Closed: 4,
    CancelRequested: 5,
    PayPending: 6,
    Construction: 7,
    Canceled: -1,
  }