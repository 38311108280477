import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DeleteQuoteItemByEstimator,
  GetQuoteById,
} from "../../API_Functions/CaseQuote_Functions";
import {
  GetCategory,
  GetProduct,
  GetStoreCategories,
  GetStoreProducts,
} from "../../API_Functions/CatProduct_Functions";
import { TextInput300x50 } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnFullWidth,
  FlexContCenterAlign,
  FlexContSpaceEvenly,
  FlexRowCont100PercentSpaceBetween
} from "../../StylesheetComps/Cont";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import {
  AvenirCategoryLabelWide,
  CategoryCenterInnerDiv,
  CategoryCont,
  Cont100PercentWidth,
  GridCont100PercentDisplayProps,
  HundredPercentFlexDiv,
  Icon30px30pxPointer,
  ItemSelectionContainer,
  TwentyPercentWidthCont
} from "../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  MediumHeader,
  RegularText,
  RegularTextBoldRed,
  StyledHRGreyBreak,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import QuoteAddOnSelection from "../Windows/QuoteAddOnSelection";
import QuoteItemWindow from "../Windows/QuoteItemWindow";

const MaterialSelection = ({
  open,
  quote,
  quoteId,
  onBackToQuote,
  returnCateType,
}) => {
  const [productObject, setProductObject] = useState({});

  const params = useParams();

  const [storeCategories, setStoreCategories] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [categorySubCategories, setCategorySubcategories] = useState([]);
  const [unfilteredProducts, setUnfilteredProducts] = useState([]);

  const [emptyProductMsg, setEmptyProductMsg] = useState("");

  const [loadingPrompt, setLoadingPrompt] = useState(false);

  const [quoteItemWindowToggle, setQuoteItemWindowToggle] = useState(false);

  const [arrowSrc, setArrowSrc] = useState("/uparrow.svg");
  const [itemsDisplayProp, setItemsDisplayProp] = useState("none");
  const [displayCategories, setDisplayCategories] = useState("none");

  const [currentQuoteItemsArray, setCurrentQuoteItemsArray] = useState([]);
  const [currentQuoteLaborArray, setCurrentQuoteLaborArray] = useState([]);
  const [currentQuoteRemovalArray, setCurrentQuoteRemovalArray] = useState([]);

  const [inputValue, setInputValue] = useState("");

  const [isAddOnsWindowVisible, setIsAddOnsWindowVisible] = useState(false);
  const [productId, setProductId] = useState(0);

  const [itemId, setItemId] = useState(0);
  const [primaryitemid, setPrimaryItemId] = useState(0);

  const GetCurrentQuoteItems = async (quote_id) => {
    const data = await GetQuoteById(quote_id);
    
    if (data) {
      const { quote } = data;
  
      quote.Material && setCurrentQuoteItemsArray(quote.Material.Items);
      quote.Labor && setCurrentQuoteLaborArray(quote.Labor.Items);
      quote.Removal && setCurrentQuoteRemovalArray(quote.Removal.Items);
    }
  };

  const setStoreProducts = async (storeid) => {
    var data = await GetStoreProducts(storeid);
    if (data != null) {
      setUnfilteredProducts(data.products);
    }
  };

  const filterCategory = (o, categorytype) => {
    if (categorytype === CategoryTypes.Material) {
      return o.CateType === CategoryTypes.Material || o.CateType === CategoryTypes.Open;
    } else {
      return o.CateType === categorytype;
    }
  };
  
  const GetStoreCategoriesFunc = async (categorytype) => {
    var data = await GetStoreCategories(params.storeId);
  
    if (data != null) {
      if (quoteId !== 0) {
        setStoreCategories(
          data.cate.filter((o) => filterCategory(o, categorytype))
        );
        setLoadingPrompt(false);
      }
    }
  };
  
  const bgcolorvalue = (o, i) => {
    if (o.CateType === CategoryTypes.Material) {
      return "#169ADB";
    } else if (o.CateType === CategoryTypes.Addon) {
      return "yellow";
    } else if (o.CateType === CategoryTypes.Removal) {
      return "grey";
    } else if (o.CateType === CategoryTypes.Labor) {
      return "#964B00";
    } else {
      return "white";
    }
  };

  const GetProduct_Subcat_ofCategory = async (cat_id) => {
    var cate = await GetCategory(cat_id);
    if (cate !== null) {
      setCategorySubcategories(cate.SubCategories);
      setCategoryProducts(cate.Products);
    }
  };

  const GetProductInfo = async (productid) => {
    var data = await GetProduct(productid);
    if (data !== null) {
      setProductObject(data);
      setQuoteItemWindowToggle(true);
    }
  };

  const OpenUpdateQuoteItemWindow = async (o, i) => {
    setProductObject(o);
    setItemId(o.Id);
    setQuoteItemWindowToggle(true);
  };

  const DeleteItem = async (item, itemlist) => {
    const data = await DeleteQuoteItemByEstimator(item.Id);
    if (data) {
      GetCurrentQuoteItems(quoteId);
    }
    if (itemlist?.length > 0) {
      var index = itemlist.indexOf(item);
      itemlist.splice(index, 1);
      setItems([...itemlist]);
    }
  };
  
  const [items, setItems] = useState([]);

  const FilterProducts = (productlist, text) => {
    setDisplayCategories("none");

    function find(productlist, text) {
      text = text.toLowerCase().split(" ");
      return productlist.filter(function (word) {
        return text.every(function (char) {
          return word.Name.toLowerCase().includes(char);
        });
      });
    }

    const output = find(productlist, text);
    setCategoryProducts(output);
  };

  const ResetInputFilter = async () => {
    setInputValue("");
    FilterProducts(unfilteredProducts, " ");
    setDisplayCategories("grid");
  };

  const UpdateArrowUI = async () => {
    if (arrowSrc === "/downarrow.svg") {
      setArrowSrc("/uparrow.svg");
      setItemsDisplayProp("none");
    } else {
      setArrowSrc("/downarrow.svg");
      setItemsDisplayProp("flex");
    }
  };

  const ReturnToQuote = async () => {
    onBackToQuote();
    setItems([]);
    setStoreCategories([]);
    setCategoryProducts([]);
    setUnfilteredProducts([]);
    setCategorySubcategories([]);
    setCurrentQuoteItemsArray([]);
    setCurrentQuoteLaborArray([]);
    setCurrentQuoteRemovalArray([]);
  };

  const CheckIfProductHasAddons = async (productid) => {
    const data = await GetProduct(productid);
    if (data !== null) {
      setProductObject(data)
      if (data.Addons.length > 0) {
        setIsAddOnsWindowVisible(true);
      }
    }
    setItemId(0);
    setQuoteItemWindowToggle(false);
  };

  useEffect(() => {
    if (items.length !== 0) {
      GetCurrentQuoteItems(quoteId);
    }
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (quoteId !== 0) {
      FilterProducts(unfilteredProducts, "");
    }
  }, [quoteId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      setStoreProducts(params.storeId);
      GetCurrentQuoteItems(quoteId);
      setLoadingPrompt(true);
  
      switch (returnCateType) {
        case CategoryTypes.Material:
        case CategoryTypes.Labor:
        case CategoryTypes.Removal:
          GetStoreCategoriesFunc(returnCateType);
          break;
        default:
          GetStoreCategoriesFunc();
      }
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  if (loadingPrompt === true) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (quoteId !== 0) {
    return (
      <HundredPercentFlexDiv>
        <ItemSelectionContainer>
          <QuoteAddOnSelection
            primaryitemid={primaryitemid}
            open={isAddOnsWindowVisible}
            RefreshQuoteItems={(item, itemid) => {
              GetCurrentQuoteItems(params.quoteId);
              setItems([...items, item]);
              //AddItemToLocalArray(item, itemid);
            }}
            productObj={productObject}
            onClose={() => {
              setIsAddOnsWindowVisible(false);
            }}
          ></QuoteAddOnSelection>

          <Cont100PercentWidth>
            <FlexRowCont100PercentSpaceBetween>
              <TextInput300x50
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  FilterProducts(unfilteredProducts, e.target.value);
                }}
                inputwidth="80%"
                inputheight="25%"
                inputborderadius="10px"
                placeholder="Type in product name to search "
              ></TextInput300x50>
              <RegularResponsiveButton
                onClick={() => {
                  ResetInputFilter();
                }}
              >
                Products
              </RegularResponsiveButton>
            </FlexRowCont100PercentSpaceBetween>

            <StyledHRGreyBreak></StyledHRGreyBreak>
            <GridCont100PercentDisplayProps display={displayCategories}>
              {storeCategories?.map((o, i) => (
                <CategoryCenterInnerDiv key={i}>
                  <CategoryCont
                    bgcolorprops={bgcolorvalue(o, i)}
                    onClick={() => {
                      setEmptyProductMsg(" ");
                      GetProduct_Subcat_ofCategory(o.Id);
                    }}
                  >
                    <RegularText>{o.Name}</RegularText>
                  </CategoryCont>
                </CategoryCenterInnerDiv>
              ))}
            </GridCont100PercentDisplayProps>

            <StyledHRGreyBreak display={displayCategories}></StyledHRGreyBreak>

            <FlexColumnCont margin="0" overflowy="auto" height="auto">
              <GridCont100PercentDisplayProps maxheight="90vh">
                {categorySubCategories?.map((o, i) => (
                  <CategoryCenterInnerDiv key={i}>
                    <CategoryCont
                      bgcolorprops={bgcolorvalue(o, i)}
                      onClick={() => {
                        GetProduct_Subcat_ofCategory(o.Id);
                      }}
                    >
                      <RegularText>
                        {o.Name} - {"Subcategory"} {o.Unit}
                      </RegularText>
                    </CategoryCont>
                  </CategoryCenterInnerDiv>
                ))}

                {categoryProducts?.map((o, i) => (
                  <CategoryCenterInnerDiv key={i}>
                    <CategoryCont
                      onClick={() => {
                        GetProductInfo(o.Id);
                        setProductId(o.Id);
                      }}
                    >
                      <MediumHeader>{o.Name}</MediumHeader>
                      <TinyTextItalics>
                        <b>
                          {"$" + parseFloat(o.UnitPrice).toFixed(2)} {o.Unit}
                        </b>
                      </TinyTextItalics>
                    </CategoryCont>
                  </CategoryCenterInnerDiv>
                ))}
              </GridCont100PercentDisplayProps>

              <RegularTextBoldRed>{emptyProductMsg}</RegularTextBoldRed>
            </FlexColumnCont>
          </Cont100PercentWidth>
        </ItemSelectionContainer>

        <TwentyPercentWidthCont>
          <FlexColumnCont
            flexdirection="row"
            height="5%"
            justifycontent="space-evenly"
            margin="0%"
          >
            <LeagueSpartanHeaderSmall>
              Estimate #{params.quoteId}
            </LeagueSpartanHeaderSmall>
            <RegularResponsiveButton
              onClick={() => {
                ReturnToQuote();
              }}
            >
              Return to Estimate
            </RegularResponsiveButton>
          </FlexColumnCont>
          <StyledHRGreyBreak></StyledHRGreyBreak>
          <HundredPercentFlexDiv>
            <br></br>
            <FlexContCenterAlign>
              <LeagueSpartanHeaderSmall>
                Current Items on Estimate
              </LeagueSpartanHeaderSmall>
              <Icon30px30pxPointer
                src={arrowSrc}
                onClick={() => {
                  UpdateArrowUI();
                }}
              ></Icon30px30pxPointer>
            </FlexContCenterAlign>
          </HundredPercentFlexDiv>

          <FlexColumnFullWidth
            maxheight="30vh"
            overflowy="auto"
            display={itemsDisplayProp}
          >
            {currentQuoteItemsArray.map((o, i) => (
              <FlexContSpaceEvenly height="auto" key={i}>
                <AvenirCategoryLabelWide>{o.Name}</AvenirCategoryLabelWide>
                <AvenirCategoryLabelWide>
                  {" "}
                  <b>
                    {" ($"}
                    {parseFloat(o.UnitPrice).toFixed(2)}
                    {")"}
                  </b>
                  {" x "}
                  <b>{parseFloat(o.Quant).toFixed(2)}</b>
                </AvenirCategoryLabelWide>
                <Icon30px30pxPointer
                  src={"/EditIcon.svg"}
                  onClick={() => {
                    OpenUpdateQuoteItemWindow(o, i);
                  }}
                ></Icon30px30pxPointer>
                <Icon30px30pxPointer
                  src={"/AddOn_Icon.svg"}
                  onClick={() => {
                    setPrimaryItemId(o.Id)
                    CheckIfProductHasAddons(o.Product_Id);
                  }}
                ></Icon30px30pxPointer>
                  <Icon30px30pxPointer
                  src={"/Garbage_Icon.svg"}
                  onClick={() => {
                    //DeleteProductFromQuoteByEstimator(o, i, o.Id);
                    DeleteItem(o, items);
                    GetCurrentQuoteItems(quoteId);
                  }}
                ></Icon30px30pxPointer>
              </FlexContSpaceEvenly>
            ))}
            {currentQuoteLaborArray.map((o, i) => (
              <FlexContSpaceEvenly height="auto" key={i}>
                <AvenirCategoryLabelWide>{o.Name}</AvenirCategoryLabelWide>
                <AvenirCategoryLabelWide>
                  {" "}
                  <b>
                    {" ($"}
                    {parseFloat(o.UnitPrice).toFixed(2)}
                    {")"}
                  </b>
                  {" x "}
                  <b>{parseFloat(o.Quant).toFixed(2)}</b>
                </AvenirCategoryLabelWide>
                <Icon30px30pxPointer
                  src={"/EditIcon.svg"}
                  onClick={() => {
                    OpenUpdateQuoteItemWindow(o, i);
                  }}
                ></Icon30px30pxPointer>
                <Icon30px30pxPointer
                  src={"/Garbage_Icon.svg"}
                  onClick={() => {
                    //DeleteProductFromQuoteByEstimator(o, i, o.Id);
                    DeleteItem(o, items);
                    GetCurrentQuoteItems(quoteId);
                  }}
                ></Icon30px30pxPointer>
              </FlexContSpaceEvenly>
            ))}
            {currentQuoteRemovalArray.map((o, i) => (
              <FlexContSpaceEvenly height="auto" key={i}>
                <AvenirCategoryLabelWide>{o.Name}</AvenirCategoryLabelWide>
                <AvenirCategoryLabelWide>
                  {" "}
                  <b>
                    {" ($"}
                    {parseFloat(o.UnitPrice).toFixed(2)}
                    {")"}
                  </b>
                  {" x "}
                  <b>{parseFloat(o.Quant).toFixed(2)}</b>
                </AvenirCategoryLabelWide>
                <Icon30px30pxPointer
                  src={"/EditIcon.svg"}
                  onClick={() => {
                    OpenUpdateQuoteItemWindow(o, i);
                  }}
                ></Icon30px30pxPointer>
                <Icon30px30pxPointer
                  src={"/Garbage_Icon.svg"}
                  onClick={() => {
                    //DeleteProductFromQuoteByEstimator(o, i, o.Id);
                    DeleteItem(o, items);
                    GetCurrentQuoteItems(quoteId);
                  }}
                ></Icon30px30pxPointer>
              </FlexContSpaceEvenly>
            ))}
          </FlexColumnFullWidth>

          <QuoteItemWindow
            itemId={itemId}
            quoteid={quoteId}
            quote={quote}
            open={quoteItemWindowToggle}
            RefreshItemList={(item) => {
              GetCurrentQuoteItems(quoteId);
              setItems([...items, item]);
              setPrimaryItemId(item.Id);
            }}
            RefreshUpdatedItem={(item) => {
              GetCurrentQuoteItems(quoteId); 
              var index = items.findIndex((o) => o.Id === item.Id);
              items[index] = item;
            }}
            onClose={(added) => {
              if (added === true) {
                CheckIfProductHasAddons(productId);
              } else {
                setQuoteItemWindowToggle(false);
                setItemId(0);
              }
            }}
            productobj={productObject}
          ></QuoteItemWindow>
          <br></br>
          <HundredPercentFlexDiv>
            <br></br>
            <LeagueSpartanHeaderSmall>Items Added</LeagueSpartanHeaderSmall>
          </HundredPercentFlexDiv>
          <StyledHRGreyBreak></StyledHRGreyBreak>

          {items.map((o, i) => (
            <FlexContSpaceEvenly height="auto" key={o?.Name}>
              <AvenirCategoryLabelWide>{o.Name}</AvenirCategoryLabelWide>
              <AvenirCategoryLabelWide>
                {" "}
                <b>
                  {" ($"}
                  {parseFloat(o.UnitPrice).toFixed(2)}
                  {")"}
                </b>
                {" x "}
                <b>{parseFloat(o.Quant).toFixed(2)}</b>
              </AvenirCategoryLabelWide>
              <Icon30px30pxPointer
                src={"/EditIcon.svg"}
                onClick={() => {
                  OpenUpdateQuoteItemWindow(o, i);
                }}
              ></Icon30px30pxPointer>
              <Icon30px30pxPointer
                src={"/Garbage_Icon.svg"}
                onClick={() => {
                  //DeleteProductFromQuoteByEstimator(o, i, o.returnedId);
                  DeleteItem(o, items);
                  GetCurrentQuoteItems(quoteId);
                }}
              ></Icon30px30pxPointer>
            </FlexContSpaceEvenly>
          ))}
          <br></br>
          <RegularResponsiveButton
            onClick={() => {
              ReturnToQuote();
            }}
          >
            Return to Estimate
          </RegularResponsiveButton>
        </TwentyPercentWidthCont>
      </HundredPercentFlexDiv>
    );
  }
};

MaterialSelection.defaultProps = {
  onPreview: () => {},
  onFormComplete: () => {},
  quoteId: 0,
  returnCateType: 1000,
};

export default MaterialSelection;
