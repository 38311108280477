import React, { useEffect, useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { DivWithDisplay, FlexColumnContGreenBorderTopRounded } from "../../../StylesheetComps/Cont";
import { TextInputReusable } from "../../../StylesheetComps/LandscapeForm";

import { ExtraLargeTextMobileRegular } from "../../../StylesheetComps/Titles";

const ChangeBidAmountWindow = ({ Open, onClose, inValue, OutValue}) => {

    const [hours, setHours]  = useState(0);
    const[rate, setRate]  = useState(0);
    const [subtotal, setSubtotal]  = useState(inValue);
    const Initialize = async() =>{
        setHours(0);
        setRate(0);
        setSubtotal(inValue);
    }

  useEffect(()=>{
      setSubtotal(hours* rate); 
  }, [rate, hours])

  useEffect(()=>{
    Initialize(); 
  }, [ inValue])

  if (!Open) return null;
  return (
    <FlexColumnContGreenBorderTopRounded>
      <DivWithDisplay>
        <ExtraLargeTextMobileRegular>
          Enter your time and rate to recalculate your labor subtotal, or change subtotal directly:
        </ExtraLargeTextMobileRegular>
        <DivWithDisplay flexdirection="row">
            <DivWithDisplay width="200px">Enter Hours: </DivWithDisplay>
            <DivWithDisplay width="200px">
                <TextInputReusable width="200px"  defaultValue={0}
                     onChange={(e) => {
                        if (e.target.value !== "") {
                          setHours(e.target.value); 
                          setSubtotal(hours* rate);                                        
                        }
                      }}></TextInputReusable>
            </DivWithDisplay>                      
        </DivWithDisplay>
        <DivWithDisplay flexdirection="row">
            <DivWithDisplay width="200px">Hourly Rate($/hr): </DivWithDisplay>
            <DivWithDisplay width="200px">
                <TextInputReusable width="200px" defaultValue={0}
                    onChange={(e) => {
                        if (e.target.value !== "") {
                          setRate(e.target.value);   
                          setSubtotal(hours* rate);                  
                        }
                      }}></TextInputReusable>
            </DivWithDisplay>        
        </DivWithDisplay>
        <DivWithDisplay flexdirection="row">
            <DivWithDisplay width="200px">Subtotal($): </DivWithDisplay>
            <DivWithDisplay width="200px">
            <TextInputReusable width="200px" value={subtotal} 
                    onChange={(e) => {
                        if (e.target.value !== "") {
                            setSubtotal(e.target.value);                                             
                        }
                      }}></TextInputReusable>
            </DivWithDisplay>        
        </DivWithDisplay>
        <DivWithDisplay flexdirection="row">         
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryButtonBlue}
            onClick={() => {
                OutValue(subtotal);
                Initialize();    
                onClose();            
            }}
          >
            Confirm
          </RegularResponsiveButton>
          <DivWithDisplay width="100px"></DivWithDisplay>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={() => {  
                Initialize();      
                onClose();               
            }}
          >
            Cancel
          </RegularResponsiveButton>
        </DivWithDisplay>
      </DivWithDisplay>
    </FlexColumnContGreenBorderTopRounded>
  );
};

ChangeBidAmountWindow.defaultProps = {
    Open: false,
    inValue: 0,
    OutValue: () => {}, 
};

export default ChangeBidAmountWindow;
