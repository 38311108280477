import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import InstallerCard from "../../comps/InstallerCard";
import Menu from "../../comps/MenuComps/Menu";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContCenterAlign,
  FlexContMobileColumn,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

const StoreInstallersScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Store Installers Page - View Installers of a store and click to view
  // that Installer's Calendar
  //=============================================================================

  // Use the useParams hook to extract the storeId from the URL
  const params = useParams();

  // Use the useHistory hook to access the history object
  const history = useHistory();

  // Initialize the state variables for the installer list and the noInstallerText
  const [installerList, setInstallerList] = useState([]);
  const [noInstallerText, setNoInstallerText] = useState("");

  // Define the GetInstallers function to fetch the installers for the given store
  const GetInstallers = async (storeid) => {
    try {
      // Fetch the installers for the given store
      const { data } = await axios.get(
        `${window.$DBURL}store/${storeid}/installers`
      );

      // Update the installerList state variable with the list of installers
      setInstallerList(data.installers);

      // If there are no installers, update the noInstallerText state variable
      if (data.installers.length === 0) {
        setNoInstallerText(
          "There are 0 Installers in your area. If this is an error, contact us directly."
        );
      }
    } catch (err) {
      // Handle the error here
    }
  };

  // Use the useEffect hook to fetch the installers when the component mounts
  useEffect(() => {
    GetInstallers(params.storeId);
  }, []);

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>
      <FlexColumnCont80vwAutoHeight>
        <ActionMenu mobilePageProp="View Store Installers"></ActionMenu>
        <FlexColumnFullWidth>
          <FlexContCenterAlign>
            <Icon30x40pxPointer
              src="/backarrow.svg"
              onClick={() => {
                history.push("/storelistinstallers/" + params.Id);
              }}
            ></Icon30x40pxPointer>
            <LeagueSpartanExtraLargeBold>
              Store Installers
            </LeagueSpartanExtraLargeBold>
          </FlexContCenterAlign>
          <FlexColumnFullWidth>
            {installerList.map((o, i) => (
              <InstallerCard
                key={i}
                index={i}
                InstallerName={o.Name}
                Phone={o.Phone}
                Email={o.Email}
                installerId={o.Id}
                installerUserId={o.User_Id}
              ></InstallerCard>
            ))}
            <RegularTextBoldRed>{noInstallerText}</RegularTextBoldRed>
          </FlexColumnFullWidth>
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

StoreInstallersScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
  storeId: 0,
};

export default StoreInstallersScreen;
