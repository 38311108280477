import { useEffect, useState } from "react";
import {
  DeleteQuoteTool,
  UpdateQuoteTool,
} from "../../API_Functions/CaseQuote_Functions";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import {
  Cont100Percent4Percent,
  FlexColumnFullWidth,
  FlexContFullWidthJustifyCenter,
  FlexDiv,
} from "../../StylesheetComps/Cont";
import {
  FlexColumnDivCentered20Percent,
  FlexDivCentered45Percent,
  FullWidthDivFlexStart,
  QuadDeleteTitle,
  QuoteQuadMedium,
} from "../../StylesheetComps/Div";
import {
  BlueUnderlinePointerPara,
  Futura25PercentWidth,
  RegularText,
  SmallText,
  SmallTitleBold,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";

const QuoteTableToolItems = ({ onItemChange, itemId, toolobj, noChange }) => {
  const [youtubeLinkProp, setYoutubeLinkProp] = useState("");
  const [txtLineState] = useState("none");
  const [toolQuantity, setToolQuantity] = useState(0);
  const [toolRentHours, setToolRentHours] = useState(0);
  const [showSaveQuantity, setShowSaveQuantity] = useState("none");
  const [showSaveRentHours, setShowSaveRentHours] = useState("none");
  const [showUnitQuantity, setShowUnitQuantity] = useState("block");
  const [displayView, setDisplayView] = useState("block");
  const [showQuantInput, setShowQuantInput] = useState("none");
  const [showQuantText, setShowQuantText] = useState("block");

  const DeleteItem = async (itemid) => {
    var data = await DeleteQuoteTool(itemid);
    if (data == null) {
      onItemChange();
    }
  };

  const UpdateToolQuantity = async (quoteitem) => {
    quoteitem.Quant = toolQuantity;
    var data = await UpdateQuoteTool(quoteitem);
    if (data !== null) {
      onItemChange();
    }
  };

  const UpdateToolRentHours = async (quoteitem) => {
    quoteitem.RentalLength = toolRentHours;
    var data = await UpdateQuoteTool(quoteitem);
    if (data !== null) {
      onItemChange();
    }
  };

  const ExecuteUpdateQuantity = async () => {
    if (toolQuantity !== 0 && !noChange) {
      UpdateToolQuantity(toolobj);
    }
    setShowSaveQuantity("none");
    if (!noChange) setShowUnitQuantity("block");
  };

  const onFocusQuantity = () => {
    if (!noChange) setShowSaveQuantity("block");

    setShowUnitQuantity("none");
  };

  const ExecuteUpdateRentHour = async () => {
    if (toolRentHours !== 0 && !noChange) {
      UpdateToolRentHours(toolobj);
    }
    setShowSaveRentHours("none");
  };

  const onFocusRentHour = () => {
    if (!noChange) setShowSaveRentHours("block");
  };

  const SetQuantDisplay = async (nochange) => {
    if (nochange) {
      setShowQuantInput("none");
      setShowQuantText("flex");
    } else {
      setShowQuantInput("flex");
      setShowQuantText("none");
    }
  };

  useEffect(() => {
    SetQuantDisplay(noChange);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (toolobj?.VideoUrl !== null) {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (toolobj?.VideoUrl?.match(p)) {
        setYoutubeLinkProp(toolobj?.VideoUrl);
        setDisplayView("block");
      } else {
        setDisplayView("none");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Cont100Percent4Percent>
      <FullWidthDivFlexStart>
        <FlexDivCentered45Percent>
          <FlexColumnFullWidth>
            <SmallTitleBold>{toolobj.ToolName}</SmallTitleBold>
            <TinyTextItalics>{toolobj.Notes}</TinyTextItalics>
          </FlexColumnFullWidth>
          <RegularText
            display={displayView}
            color="blue"
            onClick={() => {
              if (toolobj.VideoUrl !== null) {
                window.open(youtubeLinkProp, "_blank");
              }
            }}
          >
            View
          </RegularText>
        </FlexDivCentered45Percent>

        <FlexColumnDivCentered20Percent displayProp={showQuantText}>
          <FlexDiv>
            <FlexColumnFullWidth>
              <RegularText>{toolobj.Quant}</RegularText>
            </FlexColumnFullWidth>
            <Futura25PercentWidth
              displayProp={showUnitQuantity}
              txtLine={txtLineState}
            >
              {toolobj.Unit}
            </Futura25PercentWidth>
          </FlexDiv>
        </FlexColumnDivCentered20Percent>

        <FlexColumnDivCentered20Percent displayProp={showQuantInput}>
          <FlexContFullWidthJustifyCenter>
            <TextInputProp
              inputwidth="50%"
              onFocus={onFocusQuantity}
              type="number"
              defaultValue={toolobj.Quant}
              onChange={(e) => {
                if (!noChange) setToolQuantity(e.target.value);
              }}
            ></TextInputProp>
            <Futura25PercentWidth
              displayProp={showUnitQuantity}
              txtLine={txtLineState}
            >
              {toolobj.Unit}
            </Futura25PercentWidth>
            <BlueUnderlinePointerPara
              display={showSaveQuantity}
              onClick={ExecuteUpdateQuantity}
            >
              Save
            </BlueUnderlinePointerPara>
          </FlexContFullWidthJustifyCenter>
        </FlexColumnDivCentered20Percent>

        <QuoteQuadMedium alignitems="center">
          <FlexContFullWidthJustifyCenter>
            <TextInputProp
              inputwidth="50%"
              onFocus={onFocusRentHour}
              type="number"
              defaultValue={toolobj.RentalLength}
              onChange={(e) => {
                if (!noChange) setToolRentHours(e.target.value);
              }}
            ></TextInputProp>
            <BlueUnderlinePointerPara
              display={showSaveRentHours}
              onClick={ExecuteUpdateRentHour}
            >
              Save
            </BlueUnderlinePointerPara>
          </FlexContFullWidthJustifyCenter>
        </QuoteQuadMedium>

        <QuoteQuadMedium>
          <SmallText>${toolobj.Price.toFixed(2)}</SmallText>
        </QuoteQuadMedium>

        <QuoteQuadMedium>
          <QuadDeleteTitle
            onClick={() => {
              DeleteItem(itemId);
            }}
          >
            Delete
          </QuadDeleteTitle>
        </QuoteQuadMedium>
      </FullWidthDivFlexStart>
    </Cont100Percent4Percent>
  );
};

QuoteTableToolItems.defaultProps = {
  itemId: 0,
  onItemChange: () => {},
};

export default QuoteTableToolItems;
