import React from "react";
import { UpdateStore } from "../../../API_Functions/Store_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont400x575pxNoMargin,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { LeagueSpartanExtraLargeWidth200px } from "../../../StylesheetComps/Titles";

const EditStoreWindow = ({ open, onClose, storeobj }) => {
  const UpdateStoreInformation = async (store) => {
    var data = await UpdateStore(store);
    if (data !== null) {
      onClose();
    }
  };

  if (!open) return null;
  return (
    <FixedOverlay1000vh>
      {" "}
      <Cont400x575pxNoMargin>
        <LeftItemWindowFixed>
          <LeagueSpartanExtraLargeWidth200px>
            Store Name
          </LeagueSpartanExtraLargeWidth200px>

          <TextInput72Percent
            placeholder="Store Name"
            onChange={(e) => {
              storeobj.Name = e.target.value;
            }}
            defaultValue={storeobj.Name}
          ></TextInput72Percent>

          <LeagueSpartanExtraLargeWidth200px>
            Address
          </LeagueSpartanExtraLargeWidth200px>

          <TextInput72Percent
            placeholder="Address"
            onChange={(e) => {
              storeobj.Address = e.target.value;
            }}
            defaultValue={storeobj.Address}
          ></TextInput72Percent>

          <LeagueSpartanExtraLargeWidth200px>
            Phone Number
          </LeagueSpartanExtraLargeWidth200px>

          <TextInput72Percent
            placeholder="Phone"
            onChange={(e) => {
              storeobj.Phone = e.target.value;
            }}
            defaultValue={storeobj.Phone}
          ></TextInput72Percent>

          <LeagueSpartanExtraLargeWidth200px>
            Min. Deposit
          </LeagueSpartanExtraLargeWidth200px>

          <TextInput72Percent
            placeholder="Min. Deposit"
            onChange={(e) => {
              storeobj.Minimum_Deposit = e.target.value;
            }}
            defaultValue={storeobj.Minimum_Deposit}
          ></TextInput72Percent>

          <LeagueSpartanExtraLargeWidth200px>
            Min. Labor Charge
          </LeagueSpartanExtraLargeWidth200px>

          <TextInput72Percent
            placeholder="Min. Labor Charge"
            onChange={(e) => {
              storeobj.Minimum_Labor = e.target.value;
            }}
            defaultValue={storeobj.Minimum_Labor}
          ></TextInput72Percent>

          <RegularResponsiveButton
            onClick={() => {
              UpdateStoreInformation(storeobj);
            }}
          >
            Update
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </LeftItemWindowFixed>
      </Cont400x575pxNoMargin>{" "}
    </FixedOverlay1000vh>
  );
};

EditStoreWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  StoreID: 0,
  Name: "",
  Address: "",
  Phone: "",
};

export default EditStoreWindow;
