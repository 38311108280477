import React, { useState } from "react";
import { Cont100PercentWidth } from "../../../StylesheetComps/Quote";
import { StickyCont400x400px } from "../../../StylesheetComps/Cont";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import {
  TextArea72Percent,
  TextInput72Percent,
} from "../../../StylesheetComps/LandscapeForm";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { Div300pxMargin30px } from "../../../StylesheetComps/Div";
import { LeagueSpartanRegular } from "../../../StylesheetComps/Titles";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const NewQuoteTool = ({
  open,
  onClose,
  onChange,
  onFormComplete,
  onPreview,
  defaultName,
}) => {
  const [toolQuantity, setToolQuantity] = useState(1);
  const [rentalLength, setRentalLength] = useState(0);
  const [storeName] = useState(defaultName);
  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh>
        <StickyCont400x400px>
          <Div300pxMargin30px>
            <LeagueSpartanRegular>{defaultName}</LeagueSpartanRegular>
            <LeagueSpartanRegular>Quantity</LeagueSpartanRegular>
            <TextInput72Percent
              defaultValue={toolQuantity}
              onChange={(e) => {
                setToolQuantity(e.target.value);
              }}
            ></TextInput72Percent>
            <LeagueSpartanRegular>Rent Hours</LeagueSpartanRegular>
            <TextArea72Percent
              onChange={(e) => {
                setRentalLength(e.target.value);
              }}
            ></TextArea72Percent>
            <Cont100PercentWidth
              onClick={() => {
                onPreview({ storeName, toolQuantity, rentalLength });
              }}
            >
              <RegularResponsiveButton
                onClick={() => {
                  onFormComplete(storeName, toolQuantity, rentalLength);
                  onClose();
                }}
              >
                Add
              </RegularResponsiveButton>
            </Cont100PercentWidth>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={onClose}
            >
              Cancel
            </RegularResponsiveButton>
          </Div300pxMargin30px>
        </StickyCont400x400px>{" "}
      </FixedOverlay1000vh>{" "}
    </>
  );
};

NewQuoteTool.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  defaultName: "",
};

export default NewQuoteTool;
