import React from "react";
import styled from "styled-components";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { FlexColumnContRedBorderTopRounded } from "../../../StylesheetComps/Cont";
import { Div400x75px, Div450x175px } from "../../../StylesheetComps/Div";
import { ExtraLargeTextMobileRegular } from "../../../StylesheetComps/Titles";

// Mobile specific styled components
const MobileFlexColumnCont = styled.div`
  position: fixed;
  left: 5%; // give some spacing from the edge
  top: 10%; // start a bit closer to the top
  transform: translateX(-5%); // Adjust for the changed left
  width: 90%; // almost full screen for mobile
  background-color: white;
  border-radius: 15px; // reduced border-radius for mobile
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px; // slightly reduced padding
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
`;

const MobileDivContainer = styled(Div450x175px)`
  width: 90%; // you can set your preferred width
  height: auto; // adjust the height
  margin: 0 auto; // center the div
`;

const MobileButton = styled(RegularResponsiveButton)`
  width: 100%;
  margin: 5px 0; // give some spacing between buttons
`;

const DeleteConfirmWindow = ({ deleteOpen, closeDel, onDeleteFunction }) => {
  if (!deleteOpen) return null;

  if (window.$ISMOBILE === true) {
    return (
      <MobileFlexColumnCont>
        <MobileDivContainer>
          <ExtraLargeTextMobileRegular>
            Are you sure you want to delete this?
          </ExtraLargeTextMobileRegular>
          <Div400x75px> {/* you can replace this with another mobile div if needed */}
            <MobileButton
              bgcolor={ColorPalette.PrimaryGrey}
              onClick={closeDel}
            >
              Cancel
            </MobileButton>
            <MobileButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                closeDel();
                onDeleteFunction();
              }}
            >
              Delete
            </MobileButton>
          </Div400x75px>
        </MobileDivContainer>
      </MobileFlexColumnCont>
    );
  }

  // Default return for non-mobile
  return (
    <FlexColumnContRedBorderTopRounded>
      <Div450x175px>
        <ExtraLargeTextMobileRegular>
          Are you sure you want to delete this?
        </ExtraLargeTextMobileRegular>
        <Div400x75px>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={closeDel}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              closeDel();
              onDeleteFunction();
            }}
          >
            Delete
          </RegularResponsiveButton>
        </Div400x75px>
      </Div450x175px>
    </FlexColumnContRedBorderTopRounded>
  );
};

DeleteConfirmWindow.defaultProps = {
  onDeleteFunction: () => {},
};

export default DeleteConfirmWindow;
