import axios from "axios";
import React, { useEffect, useState } from "react";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import StoreManagerCard from "../../comps/StoreManagerCard";
import {
  Div70PerWidth7PerHeight,
  FlexCont100vw100vh,
  LeftCont,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { GridDiv80xAutoPercent } from "../../StylesheetComps/Div";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const ViewListOfStoresScreen = () => {
  //=============================================================================
  // Store List Default Screen - Template
  //=============================================================================

  //=============================================================================
  // Store is used as the default for Store Lists in the Application
  //=============================================================================

  const [storeList, setStoreList] = useState([]);

  const GetStoreList = async () => {
    try {
      var metroid = JSON.parse(sessionStorage.getItem("userInfo")).Metro_Id;
      // Fetch the installers for the given store
      const resp = await axios.get(`${window.$DBURL}store/metro/${metroid}`);
      if (resp.status === 200 && resp.data && resp.data.stores) {
        setStoreList(resp.data.stores);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    GetStoreList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <RightCont>
        <ActionMenu />
        <RightViewFullWidth>
          <Div70PerWidth7PerHeight>
            <LeagueSpartanExtraLargeBold>
              View Stores
            </LeagueSpartanExtraLargeBold>
          </Div70PerWidth7PerHeight>
          <GridDiv80xAutoPercent>
            {storeList.map((o, i) => (
              <StoreManagerCard
                key={i}
                CatHeadText={o.Name}
                CatParaText={o.Address}
                storeId={o.Id}
              ></StoreManagerCard>
            ))}
          </GridDiv80xAutoPercent>
        </RightViewFullWidth>
      </RightCont>
    </FlexCont100vw100vh>
  );
};

ViewListOfStoresScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default ViewListOfStoresScreen;
