// React import
import React from "react";

// Import of Navigate function from react-big-calendar
import { Navigate } from "react-big-calendar";

// Import of react-big-calendar CSS
import "react-big-calendar/lib/css/react-big-calendar.css";

// Import of react-datepicker CSS
import "react-datepicker/dist/react-datepicker.css";

// Import of PropTypes
import PropTypes from "prop-types";

// Import of date-arithmetic module
import * as dates from "date-arithmetic";

// Import of TimeGrid from react-big-calendar
import TimeGrid from "react-big-calendar/lib/TimeGrid";

// FixedOverlay200vh imported from the "OverlayCont" stylesheet
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";

// FlexColumnContGreenBorderTopRounded, FlexColumnFullWidth, and FlexRowCont100PercentSpaceBetween imported from the "Cont" stylesheet
import {
  FlexColumnContGreenBorderTopRounded,
  FlexColumnFullWidth,
  FlexRowCont100PercentSpaceBetween,
} from "../../StylesheetComps/Cont";

// RegularText imported from the "Titles" stylesheet
import { RegularText } from "../../StylesheetComps/Titles";

// RegularResponsiveButton imported from the "Components" stylesheet
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

// ColorPalette imported from the "Colors" stylesheet
import { ColorPalette } from "../../StylesheetComps/Colors";

const ConfirmCancel = ({ open, onClose, MoveForward, DoNotMove }) => {
  class MyWeek extends React.Component {
    render() {
      let {
        date,
        localizer,
        min = localizer.startOf(new Date(), "day"),
        max = localizer.endOf(new Date(), "day"),
        scrollToTime = localizer.startOf(new Date(), "day"),
      } = this.props;
      let range = MyWeek.range(date, { localizer });

      return (
        <TimeGrid
          {...this.props}
          range={range}
          eventOffset={15}
          localizer={localizer}
          min={min}
          max={max}
          scrollToTime={scrollToTime}
        />
      );
    }
  }

  MyWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.any,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  MyWeek.range = (date, { localizer }) => {
    let start = date;
    let end = dates.add(start, 0, "day");

    let current = start;
    let range = [];

    while (localizer.lte(current, end, "day")) {
      range.push(current);
      current = localizer.add(current, 1, "day");
    }

    return range;
  };

  MyWeek.navigate = (date, action, { localizer }) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return localizer.add(date, -1, "day");

      case Navigate.NEXT:
        return localizer.add(date, 1, "day");

      default:
        return date;
    }
  };

  MyWeek.title = (date) => {
    return `Installer Appointment Slots: ${date.toLocaleDateString()}`;
  };

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded>
        <FlexColumnFullWidth>
          <RegularText>Move forward all flexible appointments?</RegularText>
          <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              onClick={() => {
                onClose();
                MoveForward();
              }}
            >
              Yes
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                DoNotMove();
              }}
            >
              No
            </RegularResponsiveButton>
          </FlexRowCont100PercentSpaceBetween>
        </FlexColumnFullWidth>
      </FlexColumnContGreenBorderTopRounded>
    </FixedOverlay200vh>
  );
};

ConfirmCancel.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onAppointmentEdit: () => {},
  CancelAppointment: () => {},
  MoveForward: () => {},
  DoNotMove: () => {},
  month: 0,
  year: 0,
  day: 0,
  dfName: "",
  dfAddress: "",
  dfCity: "",
  dfCellPhone: "",
  dfFlex: false,
  dfNotes: "",
  appointmentTime: "",
};

export default ConfirmCancel;
