//Buttons for Mobile View Only
import styled from "styled-components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const nullProp = null;

export const MobileSmallGreenButton = styled.div`
  width: 50vw;
  height: 4vh;
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: white;
  background-color: ${ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: 12px;
  line-height: 4vh;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s;
  margin-left: 20px;
  display: ${(props) => (props.display ? props.display : "block")};
`;

export const MobileGreenButton = styled.div`
  width: 40vw;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  color: white;
  margin-left: 20px;
  vertical-align: middle;
  background-color: ${ColorPalette.PrimaryButtonBlue};
  font-family: LeagueSpartan-Bold;
  font-size: 18px;
`;

export const MobileWideGreenButton = styled.div`
  width: 60vw;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  color: white;
  vertical-align: middle;
  background-color: ${ColorPalette.PrimaryButtonBlue};
  font-family: LeagueSpartan-Bold;
  font-size: 18px;
`;

export const MobileWideGreenButtonRelative = styled.div`
  width: 80vw;
  height: 50px;
  position: relative;
  left: 10%;
  bottom: 5px;
  line-height: 50px;
  text-align: center;
  border-radius: 10px;
  color: white;
  vertical-align: middle;
  background-color: ${ColorPalette.PrimaryButtonBlue};
  font-family: LeagueSpartan-Bold;
  font-size: 18px;
`;

export default nullProp;
