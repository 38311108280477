/* eslint-disable */

import { useEffect, useState } from "react";
import { decimalHoursToTimeString } from "../../API_Functions/Utility_Functions";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnCont100xAutoPercent,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexDiv,
  QuoteItemCont,
} from "../../StylesheetComps/Cont";
import {
  FlexibleEstimateDiv15Percent,
  FlexibleEstimateDiv35Percent,
  FlexibleEstimateDiv5Percent,
} from "../../StylesheetComps/Div";
import {
  Icon20x20,
  Icon30x40pxPointerNoMargin,
} from "../../StylesheetComps/Imgs/Icons";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import {
  GreyTinyTextItalics,
  SmallGreenLeagueText,
  TinyHeaderDarkGrey,
} from "../../StylesheetComps/Titles";
import Dropdown from "../DropdownMenu";
import SpeechBubbleComponent from "../Windows/UpdateBubble";
const QuoteItemEstimator = ({
  onClick,
  header1,
  header2,
  subheader2notes,
  subheader2,
  header3,
  header4,
  header5,
  header6,
  header7,
  header8,
  header9,
  header10,
  quoteItemObj,
  inputheaderposition,
  noChange,
  OutputValue,
  RemoveItemFromLocalQuoteObject,
  parentid,
  itemLink,
  bgColor,
  isHeader,
  showRelatedItems,
  constructionView,
  hideBidQuoteHeaders,
  onToggleDisplay,
  hourlyRate,
  CategoryType,
  ShowItemSelection,
  EditTag,
  DeleteTag,
  DeliveryItems,
  RemovalItems,
  showAddOnsSelection,
  deleteItemFromEstimate,
  RefreshComponentState,
  isAccountant,
  weightedRate,
}) => {
  const [header1Display, setHeader1Display] = useState("none");
  const [header2Display, setHeader2Display] = useState("flex");
  const [subheader2Display] = useState("flex");
  const [header3Display, setHeader3Display] = useState("flex");
  const [header4Display, setHeader4Display] = useState("flex");
  const [header5Display, setHeader5Display] = useState("flex");
  const [header6Display, setHeader6Display] = useState("flex");

  const [header1InputDisplay, setHeader1InputDisplay] = useState("none");
  const [header2InputDisplay, setHeader2InputDisplay] = useState("none");
  const [header3InputDisplay, setHeader3InputDisplay] = useState("none");
  // const [header4InputDisplay, setHeader4InputDisplay] = useState("none")
  const [header5InputDisplay, setHeader5InputDisplay] = useState("none");
  const [header6InputDisplay, setHeader6InputDisplay] = useState("none");

  const [header1DefaultValue, setHeader1DefaultValue] = useState(header1);
  const [header2DefaultValue, setHeader2DefaultValue] = useState(header2);
  const [header3DefaultValue, setHeader3DefaultValue] = useState(header3);
  const [header4DefaultValue, setHeader4DefaultValue] = useState(header4);
  const [header5DefaultValue, setHeader5DefaultValue] = useState(header5);
  const [header6DefaultValue, setHeader6DefaultValue] = useState(header6);

  const [linkHeaderDisplay, setLinkHeaderDisplay] = useState("none");
  const [pointerEvent, setPointerEvent] = useState("none");
  const [youtubeLinkProp, setYoutubeLinkProp] = useState("");

  const [descriptionOverflow, setDescriptionOverflow] = useState("hidden");
  const [descriptionWhiteSpace, setDescriptionWhiteSpace] = useState("nowrap");

  const [deleteIconSrc, setDeleteIconSrc] = useState("");

  const [relatedItemToggleSRC, setRelatedItemToggleSRC] =
    useState("/downarrow.svg");

  const [FontSize, setFontSize] = useState("14px");
  const [FontWeight, setFontWeight] = useState("regular");
  const [relatedItemsDisplay, setRelatedItemsDisplay] = useState("none");

  const DetermineInputHeaderLogic = async () => {
    if (parentid === 0) {
      if (noChange === false) {
        setDeleteIconSrc("/close.svg");
        InitializeSetup();
        switch (inputheaderposition) {
          case 1:
            setHeader1Display("none");
            setHeader1DefaultValue(header1);
            setHeader1InputDisplay("flex");
            break;
          case 2:
            setHeader2Display("none");
            setHeader2DefaultValue(header2);
            setHeader2InputDisplay("flex");
            break;
          case 3:
            setHeader3Display("none");
            setHeader3DefaultValue(quoteItemObj?.Quant?.toFixed(2));
            setHeader3InputDisplay("flex");
            break;
          case 4:
            setHeader4Display("none");
            setHeader4DefaultValue(header4);
            // setHeader4InputDisplay("flex")

            break;
          case 5:
            setHeader5Display("none");
            setHeader5DefaultValue(header5);
            setHeader5InputDisplay("flex");
            break;
          case 6:
            setHeader6Display("none");
            setHeader6DefaultValue(header6);
            setHeader6InputDisplay("flex");
            break;
          default:
            break;
        }
      }
    }
  };

  const InitializeSetup = async () => {
    setHeader1Display("none");
    setHeader2Display("flex");
    setHeader3Display("flex");
    setHeader4Display("flex");
    setHeader5Display("flex");
    setHeader6Display("flex");
    setDeleteIconSrc("");

    setHeader1DefaultValue(header1);
    setHeader2DefaultValue(header2);
    setHeader3DefaultValue(header3);
    setHeader4DefaultValue(header4);
    setHeader5DefaultValue(header5);
    setHeader6DefaultValue(header6);

    setHeader1InputDisplay("none");
    setHeader2InputDisplay("none");
    setHeader3InputDisplay("none");
    // setHeader4InputDisplay("none")
    setHeader5InputDisplay("none");
    setHeader6InputDisplay("none");

    if (isHeader == true) {
      setFontSize("16px");
      setFontWeight("bold");
    }
  };

  const shouldDisplayPencil =
    isHeader === true &&
    header2 !== "Subtotal" &&
    header2 !== "General Materials" &&
    header2 !== "Delivery" &&
    header2 !== "Removals" &&
    header2 !== "Access Area" &&
    header2 !== "Tools/Machines" &&
    header2 !== "Labour Breakdown";

  const shouldDisplayGreenButton =
    header2 === "General Materials" ||
    header2 === "Delivery" ||
    header2 === "Removals" ||
    header2 === "Access Area" ||
    header2 === "Tools/Machines" ||
    header2 === "Labour Breakdown";

  let iconSrc = "";

  if (shouldDisplayPencil) {
    iconSrc = "/Pencil.svg";
  } else if (shouldDisplayGreenButton) {
    iconSrc = "/Green Button.svg";
  }

  const itemIconSRC = isHeader
    ? null // Don't display anything when isHeader is true
    : CategoryType === CategoryTypes.Material
    ? "/Material_EstimateIcon.svg"
    : CategoryType === "Delivery"
    ? "/Delivery_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Removal
    ? "/Removal_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Access
    ? "/AccessArea_EstimateIcon.svg"
    : CategoryType === "Tool"
    ? "/Tools_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Labor
    ? "/Labour_EstimateIcon.svg"
    : ""; // Set default to an empty string

  useEffect(() => {
    DetermineInputHeaderLogic(inputheaderposition);
  }, [inputheaderposition]); // eslint-disable-line react-hooks/exhaustive-deps

  const CheckIfValidLink = async (link) => {
    if (link !== null && link !== undefined && link !== "") {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (link.match(p)) {
        setYoutubeLinkProp(link);
        setLinkHeaderDisplay("underline");
        setPointerEvent("auto");
      } else {
        setLinkHeaderDisplay("none");
      }
    }
  };

  useEffect(() => {
    if (showRelatedItems === true) {
      setRelatedItemsDisplay("flex");
      setRelatedItemToggleSRC("/uparrow.svg");
      CheckIfValidLink(itemLink);
    } else {
      setRelatedItemsDisplay("none");
      setRelatedItemToggleSRC("/downarrow.svg");
    }
  }, [showRelatedItems]);

  const determineIconDisplayValue = () => {
    if (hideBidQuoteHeaders === true) return "none";

    const relatedItemsExist =
      quoteItemObj?.RelatedItems?.filter(
        (o) => o.CateType !== CategoryTypes.Labor
      ).length > 0;

    if (relatedItemsExist && quoteItemObj.Parent_Id === 0) return "block";

    if (DeliveryItems.length > 0 || RemovalItems.length > 0) return "block";

    return "none";
  };

  const determineDivDisplayValue = () => {
    if (hideBidQuoteHeaders === true) return "none";

    if (DeliveryItems.length > 0 || RemovalItems.length > 0) return "none";

    const nonLaborRelatedItemsExist =
      quoteItemObj?.RelatedItems?.filter(
        (o) => o.CateType !== CategoryTypes.Labor
      ).length > 0;

    if (quoteItemObj?.Parent_Id === 0 && nonLaborRelatedItemsExist) {
      return "block";
    }

    if (quoteItemObj?.Parent_Id === 0) {
      return "block";
    }

    if (quoteItemObj?.Parent_Id !== 0 && isHeader === false) {
      return "block";
    }

    if (CategoryType === CategoryTypes.Removal) {
      return "block";
    }

    if (
      quoteItemObj?.Parent_Id !== 0 &&
      CategoryType === CategoryTypes.Material
    ) {
      return "block";
    }

    return "none";
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item, field) => {
    setSelectedItem({ item, field });
  };

  const determineSpeechBubbleDisplayValue = () => {
    if (selectedItem) return "block";
    return "none";
  };

  return (
    <FlexColumnCont100xAutoPercent margin="0" onClick={onClick}>
      <SpeechBubbleComponent
        onCloseBubble={() => {
          setSelectedItem(null);
        }}
        itemObj={selectedItem?.item}
        display={determineSpeechBubbleDisplayValue()}
        RefreshComponentState={() => {
          RefreshComponentState();
        }}
        cancelItemChange={() => {
          setSelectedItem(null);
        }}
        fieldType={selectedItem?.field}
      />
      <QuoteItemCont
        height="100%"
        alignitems="stretch"
        borderbottom={
          relatedItemsDisplay === "block"
            ? `1px dashed ${ColorPalette.PrimaryButtonBlue}`
            : `1px solid ${ColorPalette.PrimaryGrey}`
        }
      >
        {/* Column 1 */}

        <FlexibleEstimateDiv5Percent
          // display={header1Display}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <Icon20x20
            onClick={() => {
              // RemoveItemFromLocalQuoteObject();
            }}
            src={itemIconSRC}
          ></Icon20x20>
        </FlexibleEstimateDiv5Percent>

        <FlexibleEstimateDiv5Percent
          display={header1InputDisplay}
          mobiledisplay="none"
        >
          <TextInputProp
            type="number"
            mobiledisplay="none"
            defaultValue={header1DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv5Percent>

        {/* Column 2 */}

        <FlexibleEstimateDiv35Percent
          width={isHeader === true && header2 !== "Subtotal" ? "220%" : "35%"}
          height={descriptionOverflow === "hidden" ? "95px" : "auto"}
          display={header2Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={isHeader === true ? "bold" : ""}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexDiv
            width={isHeader === true && header2 !== "Subtotal" ? "220%" : ""}
          >
            <FlexColumnCont
              onClick={() => {
                handleItemClick(quoteItemObj, "Notes");
              }}
              // onClick={() => {handleItemClick(quoteItemObj, 'Name'); }}
              //August 23rd, 2023 - API doesn't currently support updating name of estimate item after item is added to the estimate.
              mobilewidth="100%"
              width="auto"
              height="auto"
              margin="0%"
              justifycontent="center"
              alignitems="left"
            >
              <SmallGreenLeagueText
                display={bgColor === ColorPalette.LightGreen ? "block" : "none"}
              ></SmallGreenLeagueText>
              {youtubeLinkProp !== "" ? (
                <a
                  rel="noreferrer"
                  href={youtubeLinkProp}
                  target="_blank"
                  style={{
                    textDecoration: linkHeaderDisplay,
                    pointerEvents: pointerEvent,
                  }}
                >
                  {header2}
                </a>
              ) : (
                header2
              )}

              <TinyHeaderDarkGrey
                width="15vw"
                margin="4px"
                display={isHeader !== true ? subheader2Display : "none"}
                mobiledisplay="block"
                overflow={showRelatedItems === true ? "visible" : "hidden"}
                whitespace={showRelatedItems === true ? "normal" : "nowrap"}
              >
                {subheader2notes}
              </TinyHeaderDarkGrey>
              {/* 
            <TinyHeaderDarkGrey
              width="15vw"
              display={isHeader !== true ? "" : "none"}
              margin="4px"
              overflow={showRelatedItems === true ? "visible" : "hidden"}
              whitespace={showRelatedItems === true ? "normal" : "nowrap"}
            >
              {subheader2}
            </TinyHeaderDarkGrey> */}
            </FlexColumnCont>
            {shouldDisplayPencil ? (
              <>
                <Icon30px20pxPointer
                  display="block"
                  src="/Green Button.svg"
                  onClick={() => {
                    ShowItemSelection();
                  }}
                ></Icon30px20pxPointer>

                <Icon30px20pxPointer
                  display="block"
                  src="/Pencil.svg"
                  onClick={() => {
                    EditTag();
                  }}
                ></Icon30px20pxPointer>
              </>
            ) : (
              shouldDisplayGreenButton && (
                <Icon30px20pxPointer
                  display="block"
                  src="/Green Button.svg"
                  onClick={() => {
                    ShowItemSelection();
                  }}
                ></Icon30px20pxPointer>
              )
            )}
            {/* <Icon30px20pxPointer   display={shouldDisplayPencil || shouldDisplayGreenButton ? 'block' : 'none'}
  src={iconSrc}
  onClick={()=>{shouldDisplayPencil === true ? EditTag() : ShowItemSelection();}}
></Icon30px20pxPointer> */}
          </FlexDiv>

          <Icon30px20pxPointer
            display={
              isHeader === true &&
              header2 !== "General Materials" &&
              header2 !== "Delivery" &&
              header2 !== "Removals" &&
              header2 !== "Access Area" &&
              header2 !== "Tools/Machines" &&
              header2 !== "Labour Breakdown" &&
              header2 !== "Subtotal"
                ? "block"
                : "none"
            }
            src="/Garbage_Icon.svg"
            onClick={() => {
              DeleteTag();
            }}
          ></Icon30px20pxPointer>
        </FlexibleEstimateDiv35Percent>

        <FlexibleEstimateDiv35Percent
          display={isHeader !== true ? header2InputDisplay : "none"}
          mobiledisplay="none"
        >
          <TextInputProp
            type="text"
            mobiledisplay="none"
            defaultValue={header2DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv35Percent>

        {/* Column 3 */}

        <FlexibleEstimateDiv15Percent
          display={
            isHeader === true && header2 !== "Subtotal" ? "none" : "flex"
          }
          mobiledisplay={header3Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign
            onClick={() => {
              handleItemClick(quoteItemObj, "Quantity");
            }}
          >
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header3 === "" ||
                header3 === null ||
                header3 === undefined
                  ? "none"
                  : "block"
              }
            >
              Quantity
            </GreyTinyTextItalics>
            <u>{header3}</u>
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          mobiledisplay={header3InputDisplay}
          display={header3InputDisplay}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            inputwidth="60px"
            type="number"
            id="quantvalue"
            key={header3DefaultValue}
            onBlur={(e) => {
              OutputValue(e.target.valueAsNumber);
            }}
            defaultValue={header3DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 4 */}

        <FlexibleEstimateDiv15Percent
          display={
            isHeader === true && header2 !== "Subtotal" ? "none" : "flex"
          }
          mobiledisplay={header4Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header4 === "" ||
                header4 === null ||
                header4 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === "Tool" ? "Rental Rate" : "Material Rate"}
            </GreyTinyTextItalics>
            {header4}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display="none"
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            width="50px"
            type="number"
            defaultValue={header4DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 5 */}
        {isAccountant === true
          ? // <FlexibleEstimateDiv15Percent
            //   width="0%"
            //   minwidth='0%'
            //   display={hideBidQuoteHeaders === true ? 'none' : header5Display}
            //   mobiledisplay="none"
            //   bgcolor={bgColor}
            //   fontsize={FontSize}
            //   fontweight={FontWeight}
            //   fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
            // >
            //   <FlexColumnContCenterAlign>
            //   <GreyTinyTextItalics display={isHeader === true && header5 !== null ? "none " : "block"}>Material Cost</GreyTinyTextItalics>
            //   {header5}
            //   </FlexColumnContCenterAlign>
            // </FlexibleEstimateDiv15Percent>
            null
          : null}

        <FlexibleEstimateDiv15Percent
          display={header5InputDisplay}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            type="text"
            mobiledisplay="none"
            defaultValue={header5DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 6 */}

        <FlexibleEstimateDiv15Percent
          display={
            isHeader === true && header2 !== "Subtotal" ? "none" : "flex"
          }
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header6 === "" ||
                header6 === null ||
                header6 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === CategoryTypes.Removal
                ? "Removal Rate"
                : CategoryType === "Tool"
                ? "Move Cost Per Machine"
                : CategoryType === "Delivery"
                ? "Delivery Rate"
                : CategoryType === CategoryTypes.Access
                ? "Access Cost"
                : "Install Rate"}
            </GreyTinyTextItalics>

            {header6}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display={header6InputDisplay}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            type="number"
            mobiledisplay="none"
            defaultValue={header6DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 7 */}
        {isAccountant === true
          ? //   <FlexibleEstimateDiv15Percent
            //   display={header6Display}
            //   bgcolor={bgColor}
            //   fontsize={FontSize}
            //   fontweight={FontWeight}
            //   fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
            // >
            //   {" "}
            //   <FlexColumnContCenterAlign>
            //   <GreyTinyTextItalics display={isHeader === true && header7 !== null ? "none " : "block"}>Install Total</GreyTinyTextItalics>
            //   {header7}
            //   </FlexColumnContCenterAlign>
            // </FlexibleEstimateDiv15Percent>
            null
          : null}

        {/* WEIGHTED RATE */}
        {isAccountant === true ? (
          <FlexibleEstimateDiv15Percent
            display={header6Display}
            bgcolor={bgColor}
            fontsize={FontSize}
            fontweight={FontWeight}
            fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
          >
            {" "}
            <FlexColumnContCenterAlign>
              <GreyTinyTextItalics
                display={
                  isHeader === true && header7 !== null ? "none " : "block"
                }
              >
                Weighted Rate
              </GreyTinyTextItalics>
              ${weightedRate}
            </FlexColumnContCenterAlign>
          </FlexibleEstimateDiv15Percent>
        ) : null}

        {/* Column 8 */}
        <FlexibleEstimateDiv15Percent
          borderright={
            isHeader === false ? `1px solid ${ColorPalette.TertiaryGrey}` : ""
          }
          display={
            isHeader === true && header2 !== "Subtotal" ? "none" : "flex"
          }
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {" "}
          <FlexColumnContCenterAlign
            onClick={() => {
              if (!quoteItemObj?.ToolName) {
                handleItemClick(quoteItemObj, "Install");
              } else {
                handleItemClick(quoteItemObj, "Rental Hours");
              }
            }}
          >
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header9 === "" ||
                header9 === null ||
                header9 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === CategoryTypes.Removal
                ? "Removal Time"
                : CategoryType === "Tool"
                ? "Rental Hours"
                : "Install Time"}
            </GreyTinyTextItalics>
            {isHeader === true ? header9 : <u>{header9}</u>}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>
        {/* Column 10 */}
        <FlexibleEstimateDiv15Percent
          display={
            isHeader === true && header2 !== "Subtotal" ? "none" : "flex"
          }
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <b>{header10}</b>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv5Percent
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <Icon30x40pxPointerNoMargin
            display={determineIconDisplayValue()}
            src={relatedItemToggleSRC}
            onClick={onToggleDisplay}
          ></Icon30x40pxPointerNoMargin>
        </FlexibleEstimateDiv5Percent>
        <FlexibleEstimateDiv5Percent
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <div style={{ display: determineDivDisplayValue() }}>
            <Dropdown
              currentPage={"itemCard"}
              addOns={() => {
                showAddOnsSelection();
              }}
              deleteItem={() => {
                deleteItemFromEstimate();
              }}
              changeTag={() => {
                handleItemClick(quoteItemObj, "Tag");
              }}
            ></Dropdown>
          </div>
        </FlexibleEstimateDiv5Percent>
      </QuoteItemCont>

      {DeliveryItems.map((o, i) => {
        return (
          <FlexColumnFullWidth
            margin="0"
            key={i}
            display={showRelatedItems ? "block" : "none"}
          >
            <QuoteItemEstimator
              header1="Delete"
              key={i}
              RefreshComponentState={() => {
                RefreshComponentState();
              }}
              deleteItemFromEstimate={() => {
                deleteItemFromEstimate(o.Id);
              }}
              quoteItemObj={o}
              CategoryType={"Delivery"}
              header2={`${o?.Notes}`}
              header3={o?.Quant + " Load(s)"}
              header4={`$${o?.BasePrice?.toFixed(2).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`}
              header10={
                "$" + o?.Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            ></QuoteItemEstimator>
          </FlexColumnFullWidth>
        );
      })}

      {RemovalItems.map((o, i) => {
        return (
          <FlexColumnFullWidth
            margin="0"
            key={i}
            display={showRelatedItems ? "block" : "none"}
          >
            <QuoteItemEstimator
              header1="Delete"
              subheader2notes={o.Notes}
              quoteItemObj={o}
              RefreshComponentState={() => {
                RefreshComponentState();
              }}
              CategoryType={CategoryTypes.Removal}
              deleteItemFromEstimate={() => {
                deleteItemFromEstimate(o.Id);
              }}
              header2={o.Name}
              header3={
                typeof o?.Quant === "number" && !isNaN(o.Quant)
                  ? o.Quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " " +
                    o.DisplayUnit
                  : "0.00 " + o.DisplayUnit
              }
              header5=""
              header4=""
              header6={
                "$" + ((o.Price + o.LaborSubtotal) / o?.Quant).toFixed(4)
              }
              header7={
                "$" +
                (o.Price + o.LaborSubtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              // header9={o.removalTime}
              header9={
                (() => {
                  return o?.RelatedItems.reduce((acc, item) => {
                    if (item.CateType === CategoryTypes.Labor) {
                      if (item.Notes.startsWith("Install")) {
                        acc += item.Quant;
                      } else if (item.Notes.startsWith("Needed to operate")) {
                        acc += item.Quant;
                      }
                    }
                    return decimalHoursToTimeString(acc);
                  }, 0);
                })() // IIFE - Immediately Invoked Function Expression
              }
              header10={
                "$" +
                (o.Price + o.LaborSubtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            ></QuoteItemEstimator>
          </FlexColumnFullWidth>
        );
      })}

      {quoteItemObj?.RelatedItems?.map((o, i) => {
        if (o.CateType !== CategoryTypes.Labor && constructionView === false) {
          return (
            <FlexColumnFullWidth
              margin="0"
              key={i}
              display={showRelatedItems ? "block" : "none"}
            >
              <QuoteItemEstimator
                hideBidQuoteHeaders={hideBidQuoteHeaders}
                showRelatedItems={showRelatedItems}
                RefreshComponentState={() => {
                  RefreshComponentState();
                }}
                deleteItemFromEstimate={() => {
                  deleteItemFromEstimate(o.Id);
                }}
                quoteItemObj={o}
                key={i}
                hourlyRate={154}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={`${o.Name}`}
                isAccountant={isAccountant}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={
                  typeof o?.Quant === "number" && !isNaN(o.Quant)
                    ? o.Quant.toFixed(2) + " " + o.DisplayUnit
                    : ""
                }
                header4={
                  "$" +
                  o.UnitPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header5={
                  "$" + o.Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header6={
                  o.Quant === 0
                    ? "$0.00"
                    : "$" +
                      (o.LaborSubtotal / o.Quant)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                weightedRate={
                  o?.Quant !== 0
                    ? (o.UnitPrice + o.LaborSubtotal / o.Quant)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : "N/A"
                }
                header7={
                  "$" +
                  o.LaborSubtotal.toFixed(2).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                }
                header9={
                  o.Quant === 0
                    ? "Included"
                    : hourlyRate === 0
                    ? "Included"
                    : decimalHoursToTimeString(o.LaborSubtotal / hourlyRate)
                }
                header10={
                  "$" +
                  (
                    parseFloat(o.Price.toFixed(2)) +
                    parseFloat(o.LaborSubtotal.toFixed(2))
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                bgColor={ColorPalette.LightGreen}
              ></QuoteItemEstimator>
            </FlexColumnFullWidth>
          );
        } else if (
          o.CateType !== CategoryTypes.Labor &&
          constructionView === true
        ) {
          return (
            <FlexColumnFullWidth
              margin="0"
              key={i}
              display={showRelatedItems ? "block" : "none"}
            >
              <QuoteItemEstimator
                hideBidQuoteHeaders={hideBidQuoteHeaders}
                showRelatedItems={showRelatedItems}
                quoteItemObj={o}
                key={i}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={o.Name}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={
                  typeof o?.Quant === "number" && !isNaN(o.Quant)
                    ? o.Quant.toFixed(2) + " " + o.DisplayUnit
                    : ""
                }
                header7={""}
                header10={"$" + o.LaborSubtotal.toFixed(2)}
                bgColor={ColorPalette.LightGreen}
              ></QuoteItemEstimator>
            </FlexColumnFullWidth>
          );
        } else {
          return null;
        }
      })}
    </FlexColumnCont100xAutoPercent>
  );
};

QuoteItemEstimator.defaultProps = {
  header1: "",
  header2: "",
  subheader2: "",
  header3: "",
  header4: "",
  header4input: "",
  header5: "",
  header6: "",
  header7: "",
  header8: "",
  inputheaderposition: 0,
  OutputValue: () => {},
  RemoveItemFromLocalQuoteObject: () => {},
  onToggleDisplay: () => {},
  ShowItemSelection: () => {},
  EditTag: () => {},
  DeleteTag: () => {},
  showAddOnsSelection: () => {},
  deleteItemFromEstimate: () => {},
  RefreshComponentState: () => {},
  bgColor: "",
  isHeader: false,
  showRelatedItems: false,
  hideBidQuoteHeaders: false,
  constructionView: false,
  hourlyRate: 0,
  weightedRate: "N/A",
  CategoryType: CategoryTypes.Material,
  DeliveryItems: [],
  RemovalItems: [],
  isAccountant: false,
};

export default QuoteItemEstimator;
