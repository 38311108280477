import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { StyledHRGreyBreak, TinyTextItalics } from "../../StylesheetComps/Titles";

const ProgressBarContainer = styled.div`
  display: flex;
  width: 50vw;
  height: 30px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
`;

const ProgressBar = styled(animated.div)`
  width: ${(props) => props.width || "0%"};
  height: 100%;
  background-color: ${ColorPalette.PrimaryButtonBlue};
  transition: width 2s;
  box-shadow: ${(props) =>
    props.isActive ? `0px 0px 10px ${ColorPalette.PrimaryButtonBlue}` : "none"};
`;

const ProgressText = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: ${ColorPalette.PrimaryButtonBlue};
  text-align: center;
  font-family: LeagueSpartan-Bold;
`;

const ProgressWindow = styled.div`
  position: fixed;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001; /* Higher than the overlay */
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const DuplicateProgress = ({ currentStage, stageText, open}) => {
  const stages = [
    "Starting",
    "Retrieving Case",
    "Finding Proposal",
    "Retrieving Quote",
    "Identifying Add-ons",
    "Duplicating Estimate",
    "Adding Add-ons",
    "Creating Relationships",
    "Completed",
  ];

  const progressPercentage = ((currentStage + 1) / stages.length) * 100;

  if (!open) return null;
  return (
    <>
      <Overlay />
      <ProgressWindow>
        <ProgressBarContainer>
          <ProgressBar width={`${progressPercentage}%`} isActive={true} />
        </ProgressBarContainer>
        <ProgressText>{stages[currentStage]}</ProgressText>
        <StyledHRGreyBreak></StyledHRGreyBreak>
        <TinyTextItalics>{stageText}</TinyTextItalics>
      </ProgressWindow>
    </>
  );
};

export default DuplicateProgress;
