import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TinyTextItalics } from '../../../StylesheetComps/Titles';

const ConversionWindow = styled.div`
  display: ${({ isVisible }) => isVisible ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 9999;
  transition: all 0.3s ease;
`;

const ConversionContent = styled.div`
  background-color: #fff;
  max-width: 400px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 16px rgba(0,0,0,0.1);
  font-family: 'SF Pro Display', sans-serif; // Apple's System Font
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  appearance: none;
  background-color: #f4f4f4;
  font-family: 'SF Pro Display', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f4f4f4;
  font-family: 'SF Pro Display', sans-serif;
`;

const Button = styled.button`
  background-color: #007AFF; // Apple's blue color
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-family: 'SF Pro Display', sans-serif;
  &:hover {
    background-color: #005EC3;
  }
`;

const ResultText = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
  font-family: 'SF Pro Display', sans-serif;
`;

const DescriptionText = styled.p`
  color: #777;
  margin-bottom: 10px;
  font-family: 'SF Pro Display', sans-serif;
`;


const CalculatorWindow = ({ isVisible, setIsVisible, squareFeetProp, setSquareFeetProp, onClose }) => {
    const [squareFeet, setSquareFeet] = useState(0);
    const [selectedConversion, setSelectedConversion] = useState('cubicFeet');
    const [depth, setDepth] = useState(1);
    const [conversionResult, setConversionResult] = useState(0);
  
    const conversions = {
        cubicFeet: {
            name: 'Cubic Feet',
          description: 'Cubic Feet: This measures volume in feet. It requires the depth to be specified in inches.',
          conversionFunction: (val, depth) => (val * (depth / 12)),
        },
        cubicYards: {
          name: 'Cubic Yards',
          description: 'Cubic Yards: This measures volume in yards. It requires the depth to be specified in inches.',
          conversionFunction: (val, depth) => (val * (depth / 12)) / 27,
        },
        squareYards: {
            name: 'Square Yards',
          description: 'Square Yards: This measures area in yards. Depth is not required for this conversion.',
          conversionFunction: (val) => val / 9,
        },
      };
    
  
    const shouldShowDepthInput = selectedConversion !== 'squareYards';
  
    const handleConversion = () => {
      const result = conversions[selectedConversion].conversionFunction(squareFeet, depth);
      setConversionResult(result.toFixed(2));
    };
  
    useEffect(() => {
      handleConversion();
    }, [squareFeet, depth, selectedConversion]);

    useEffect(() => {
        setSquareFeet(squareFeetProp)
      }, [squareFeetProp]);

  return (
    <ConversionWindow isVisible={isVisible}>
      <ConversionContent>
        <TinyTextItalics>Square Feet</TinyTextItalics>
        <Input
          type="number"
          min={0}
          value={squareFeet}
          onChange={(e) => setSquareFeet(e.target.value)}
          placeholder="Square Feet"
        />
        <TinyTextItalics>Depth (Inches)</TinyTextItalics>
        {shouldShowDepthInput && (
          <Input
            type="number"
            min={0}
            value={depth}
            onChange={(e) => setDepth(e.target.value)}
            placeholder="Depth (for cubic conversions)"
          />
        )}
        <TinyTextItalics>Calculation Selection:</TinyTextItalics>
        <Select value={selectedConversion} onChange={(e) => setSelectedConversion(e.target.value)}>
          <option value="cubicFeet">Cubic Feet</option>
          <option value="cubicYards">Cubic Yards</option>
          <option value="squareYards">Square Yards</option>
        </Select>
        <DescriptionText>{conversions[selectedConversion].description}</DescriptionText>
        <ResultText>Result: {conversionResult} {conversions[selectedConversion].name}</ResultText>
        <Button onClick={() => onClose()}>Close</Button>
      </ConversionContent>
    </ConversionWindow>
  );
};

CalculatorWindow.defaultProps = {
    onClose:()=>{},
}

export default CalculatorWindow;
