export const UserRole = {
    User: 0,
    VIP :1,
    Worker :2, //Crew members
    OwnerOperator :3,
    Estimator : 4,
    Installer :5,
    Manager :6,
    Admin :7,    
    Accountant : 9 ,
    Supplier :10,
    Loading : 1000
  }