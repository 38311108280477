import styled from "styled-components";

const nullProp = null;

export const OverrideEmbedImage = styled.div`
  width: 350px;
  height: 205px;
  position: absolute;
  margin-bottom: 0;
  bottom: 0px;
  left: ${(props) => (props.left ? props.left : "")};
  right: ${(props) => (props.right ? props.right : "")};
`;

export const OverrideEmbedDiv = styled.div`
  background-color: red;
  position: relative;
  margin-bottom: 0;
`;

export const InboxProfileImage = styled.img`
  width: 80px;
  height: 80px;
  display: ${(props) => (props.display ? props.display : "block")};
  border-radius: 50px;
  margin: 10px;
  object-fit: cover;
  @media (max-width: 1006px) {
    width: 60px;
    height: 60px;
    border-radius: 50px;
  }
`;

export const RoundedImageFullWidth = styled.img`
  width: ${(props) => (props.width ? props.width : "40vw")};
  height: ${(props) => (props.height ? props.height : "auto")};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  object-fit: ${(props) => (props.objfit ? props.objfit : "contain")};
`;

export const FullWidthCoverPointerImageWithMargins = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const Image200x200pxContain = styled.img`
  width:  ${(props) => (props.width ? props.width : "200px")};
  height: 200px;
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  border-radius: 4px;
  object-fit: contain;
`;

export const CenteredGridImage = styled.img`
  width: 200px;
  height: 200px;
  display: ${(props) => (props.display ? props.display : "flex")};
  cursor: ${(props) => (props.cursor ? props.cursor : "")};
  justify-content: ${(props) =>
    props.justifycontent ? props.justifycontent : "center"};
  align-items: ${(props) => (props.alignitems ? props.alignitems : "center")};
  border-radius: 4px;
  object-fit: contain;
  @media (max-width: 1006px) {
    width: 90%;
    height: 70%;
    margin-left: 5%;
    margin-top: 5%;
  }
`;

export const Image40x40px = styled.img`
  width: 40px;
  height: 40px;
  margin: 3%;
  margin-left: 20px;
  object-fit: contain;
  position: relative;
  bottom: 0%;
  right: 35px;
  cursor: pointer;
  @media (max-width: 1006px) {
    left: 72vw;
    bottom: 250px;
  }
`;

export const Image80x100px = styled.img`
  width: 80px;
  display: ${(props) => (props.display ? props.display : "block")};
  height: 100px;
  margin: 20px;
`;

export const Image60x50Percent = styled.img`
  width: 60%;
  height: 50%;
  margin: 15px;
  border-radius: 50px;
  // z-Index:2;
  @media (max-width: 1006px) {
    width: 90%;
    height: 70%;
    margin: 0;
  }
`;

export default nullProp;
