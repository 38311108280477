import { useHistory } from "react-router-dom";
import Balloons from "../../../Animation/balloonlottie.json";
import LottieAnimation from "../../../Lottie";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnCont70x100Percent,
  FlexColumnFullWidth,
  FlexDiv,
} from "../../../StylesheetComps/Cont";
import { BlankDiv } from "../../../StylesheetComps/Div";
import { Icon30x40pxPointer } from "../../../StylesheetComps/Imgs/Icons";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { RegularText } from "../../../StylesheetComps/Titles";

const Submitted = ({ open, metroId, storeId, caseNumber }) => {
  const history = useHistory();

  if (!open) return null;
  return (
    <FlexColumnFullWidth bgcolor="white">
      <FlexColumnFullWidth>
        <FlexColumnCont70x100Percent>
          <SmallHeaderLeagueSpartan fontsize="24px">
            Thank you for submitting!
          </SmallHeaderLeagueSpartan>
          <RegularText>
            Hang tight! We're so excited to start your project. You'll receive
            an email from us shortly.
          </RegularText>

          <BlankDiv>
            <LottieAnimation lotti={Balloons} height={300} width={300}>
              {" "}
            </LottieAnimation>
          </BlankDiv>

          <FlexColumnCont
            width="60%"
            margin="0"
            flexdirection="row"
            mobileflexdirection="column"
            justifycontent="space-between"
            mobilewidth="100%"
          >
            <FlexDiv>
              <Icon30x40pxPointer src="/Check Mark.svg"></Icon30x40pxPointer>
              <RegularText>Creation of Estimate</RegularText>
            </FlexDiv>

            <FlexDiv>
              <Icon30x40pxPointer src="/Check Mark.svg"></Icon30x40pxPointer>
              <RegularText>Material Selection</RegularText>
            </FlexDiv>

            <FlexDiv>
              <Icon30x40pxPointer src="/Check Mark.svg"></Icon30x40pxPointer>
              <RegularText>3D Rendering</RegularText>
            </FlexDiv>

            <FlexDiv>
              <Icon30x40pxPointer src="/Check Mark.svg"></Icon30x40pxPointer>
              <RegularText>New Rhodes Construction</RegularText>
            </FlexDiv>
          </FlexColumnCont>
          <br></br>
          <RegularResponsiveButton
            width="200px"
            onClick={() => {
              history.push("/CasesPersonal/" + metroId + "/" + storeId);
            }}
          >
            View Project
          </RegularResponsiveButton>
        </FlexColumnCont70x100Percent>
      </FlexColumnFullWidth>
    </FlexColumnFullWidth>
  );
};

Submitted.defaultProps = {
  metroId: 0,
  storeId: 0,
  caseNumber: 0,
};

export default Submitted;
