import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import StoreEstimateListCard from "../../comps/StoreEstimateListCard";
import {
  Div70PerWidth7PerHeight,
  FlexCont100vw100vh,
  FlexCont80PercentMobileOpt,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const StoreListEstimateScreen = () => {
  //=============================================================================
  // MyStore List Screen - User selects their own store
  //=============================================================================

  const params = useParams();

  const [storeList, getStoreList] = useState([]);
  const [storeMesssage, setStoreMessage] = useState("Select your Store");

  const GetStoreList = async () => {
    if (params.Id === 0) {
      setStoreMessage(
        "Please select a Metro & City in your Profile to gain access to the Form"
      );
    } else {
      const resp = await axios.get(window.$DBURL + "store/metro/" + params.Id);
      if (resp.status === 200) {
        if (resp.data !== null) {
          if (resp.data.stores !== null) {
            getStoreList(resp.data.stores);
          }
        }
      }
    }
  };

  useEffect(() => {
    GetStoreList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <Div70PerWidth7PerHeight>
            <LeagueSpartanExtraLargeBold>
              {storeMesssage}
            </LeagueSpartanExtraLargeBold>
          </Div70PerWidth7PerHeight>
          <FlexCont80PercentMobileOpt>
            {storeList.map((o, i) => (
              <StoreEstimateListCard
                key={i}
                CatHeadText={o.Name}
                storeId={o.Id}
                CatParaText={o.Address}
                catId={o.Id}
              ></StoreEstimateListCard>
            ))}
          </FlexCont80PercentMobileOpt>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

StoreListEstimateScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default StoreListEstimateScreen;
