/* eslint-disable */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "../../Input";
import axios from "axios";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { Icon30x40pxPointer } from "../../../StylesheetComps/Imgs/Icons";
import { Cont85vwAutoHeight } from "../../../StylesheetComps/Cont";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { GetTool, UpdateTool } from "../../../API_Functions/Tool_Functions";
import {
  GetStoreInfo,
  GetTaxesForStore,
} from "../../../API_Functions/Store_Functions";
import { FlexColumnDiv } from "../../../StylesheetComps/Div";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { SmallTitleBold } from "../../../StylesheetComps/Titles";

const InnerCont = styled.div`
  display: flex;
  width: 85vw;
`;

const LeftInfoCont = styled.div`
  width: 23vw;
  background-color: white;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const TaxesP = styled.p`
  // border-color: ${(props) =>
    props.taxbordercolor ? props.taxbordercolor : "black"};
  font-family: Futura;
  font-size: 18px;
  position: relative;
  cursor: pointer;
  padding: 15px;
  // border-style: ${(props) =>
    props.taxborderstyle ? props.taxborderstyle : "solid"};
  // border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 1};
`;

const ButtonDiv = styled.div`
  display: flex;
  margin-top: 8%;
`;

const SectionCont = styled.div`
  display: flex;
  border-bottom: 1px dotted #d3d3d3;
  margin-top: 5%;
  margin-bottom: 2%;
`;

const ItemCont = styled.div`
  display: flex;
  width: 250px;
`;

const HeaderCont = styled.div`
  display: flex;
  width: 100px;
`;

const TaxInner = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TaxHeaderCont = styled.div`
  display: flex;
  align-items: center;
  // justify-content:center;
`;

const AddTaxesCont = styled.div`
  display: ${(props) => (props.addtaxdisplay ? props.addtaxdisplay : "none")};
  flex-direction: column;
`;

const ExplainerPara = styled.p`
  font-size: 12px;
  color: black;
  font-family: Avenir;
  margin-left: 10px;
  display: ${(props) => (props.displayProp ? props.displayProp : "none")};
`;

const QuestionMarkImg = styled.img`
  width: 15px;
  height: 25px;
  margin-bottom: auto;
  cursor: pointer;
  padding: 5px;
`;

const DividingHR = styled.hr`
  border: 0.5px dotted #d3d3d3;
`;

const EditToolWindow = ({ open, onClose, StoreId, toolId, onUpdatedTool }) => {
  const [Tool, setTool] = useState(null);
  const [taxMap, setTaxMap] = useState();
  const [editTaxDisplay, setEditTaxDisplay] = useState("none");
  const [rentalRateInstructionDisplay, setRentalRateInstructionDisplay] =
    useState("none");

  const DeleteTax = async (tooltax_id, tool_id) => {
    const resp = await axios.delete(
      window.$DBURL + "tax/tooltax/" + tooltax_id
    );
    if (resp.status === 200) {
      GetUpdatedToolTaxes(tool_id);
    }
  };

  const AddToolTax = async (tool_id, tax_id) => {
    const resp = await axios.post(window.$DBURL + "tax/tooltax", {
      Tool_Id: tool_id,
      Tax_Id: tax_id,
    });
    if (resp.status === 200) {
      GetUpdatedToolTaxes(tool_id);
      SetTaxOptions(StoreId);
    }
  };

  const GetUpdatedToolTaxes = async (tool_id) => {
    var data = await GetTool(tool_id);
    if (data !== null) {
      if (data.Taxes.length > 0) {
        var tool = { ...Tool };
        tool.Taxes = data.Taxes;
        setTool(tool);
      } else {
        var tool = { ...Tool };
        tool.Taxes = [];
        setTool(tool);
      }
    }
  };

  const SetTaxOptions = async (storeid) => {
    const taxlist = await GetTaxesForStore(storeid);

    setTaxMap(
      taxlist.filter(function (o) {
        if (Tool != null) {
          return !Tool?.Taxes.includes(o.TaxCode);
        } else {
          return o;
        }
      })
    );
  };

  const SetTaxes_ForStore = async (storeid) => {
    var store = await GetStoreInfo(storeid);
    if (store !== null) {
    }
  };

  useEffect(() => {
    SetTaxOptions(StoreId);
  }, [Tool]); // eslint-disable-line react-hooks/exhaustive-deps

  const GetToolInfo = async (tool_id) => {
    var tool = await GetTool(tool_id);
    setTool(tool);
  };

  const SaveToolInfo = async (tool) => {
    var data = await UpdateTool(tool);
    if (data !== null) {
      onUpdatedTool();
      onClose();
    }
  };

  useEffect(() => {
    GetToolInfo(toolId);
  }, [toolId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    SetTaxes_ForStore(StoreId);
    GetToolInfo(toolId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetToolInfo(toolId);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <Cont85vwAutoHeight>
        <InnerCont>
          <LeftInfoCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Tool Name</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Tool Name"
                  key={Tool?.Name}
                  defaultValue={Tool?.Name}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Name = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
            <hr />
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Rental Rate ($ per hour)</SmallTitleBold>
                <QuestionMarkImg
                  src="/questionmark.svg"
                  onClick={() => {
                    if (rentalRateInstructionDisplay === "none") {
                      setRentalRateInstructionDisplay("block");
                    } else if (rentalRateInstructionDisplay === "block") {
                      setRentalRateInstructionDisplay("none");
                    }
                  }}
                ></QuestionMarkImg>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Rental Rate"
                  key={Tool?.RentalRate}
                  defaultValue={Tool?.RentalRate}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.RentalRate = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
            <ExplainerPara displayProp={rentalRateInstructionDisplay}>
              Leave RentalRate blank or set to 0 for Trash bins(containers) and
              for items whose charge isn't based on daily/hourly rate. Set it a
              number greater than 0.00001 if you want to count rental length but
              not cost.
            </ExplainerPara>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>{"Min. Rental (Hours) "}</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder={"Minimum Rental "}
                  key={Tool?.MinRental}
                  defaultValue={Tool?.MinRental}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.MinRental = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Move Cost Per Machine ($)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Move Cost Per Machine"
                  key={Tool?.MoveCost}
                  defaultValue={Tool?.MoveCost}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.MoveCost = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Speed (KM/HR)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Speed (KM/HR)"
                  key={Tool?.Speed}
                  defaultValue={Tool?.Speed}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Speed = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <ButtonDiv>
              <RegularResponsiveButton
                onClick={() => {
                  onClose();
                  SaveToolInfo(Tool);
                }}
              >
                Save
              </RegularResponsiveButton>

              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose();
                  GetToolInfo(toolId);
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </ButtonDiv>
          </LeftInfoCont>
          <DividingHR></DividingHR>
          <LeftInfoCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Width (Feet)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Width (Feet)"
                  key={Tool?.Width}
                  defaultValue={Tool?.Width}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Width = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Length (Feet)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Length (Feet)"
                  key={Tool?.Length}
                  defaultValue={Tool?.Length}
                  inputwidth="60%"
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Length = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Height (Feet)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Height (Feet)"
                  key={Tool?.Height}
                  inputheight="10px"
                  inputwidth="60%"
                  defaultValue={Tool?.Height}
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Height = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Weight (LBS)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Weight (LBS)"
                  key={Tool?.Weight}
                  inputheight="10px"
                  inputwidth="60%"
                  defaultValue={Tool?.Weight}
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Weight = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Capacity (Cubic Feet)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Capacity (Cubic Feet)"
                  key={Tool?.Capacity}
                  inputheight="10px"
                  inputwidth="60%"
                  defaultValue={Tool?.Capacity}
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Capacity = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Load Time (Hours)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Load Time (Hours)"
                  key={Tool?.LoadTime}
                  inputheight="10px"
                  inputwidth="60%"
                  defaultValue={Tool?.LoadTime}
                  onChange={(e) => {
                    var tool = Tool;
                    tool.LoadTime = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Unload Time (Hours)</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="Unload Time (Hours)"
                  key={Tool?.UnloadTime}
                  inputheight="10px"
                  inputwidth="60%"
                  defaultValue={Tool?.UnloadTime}
                  onChange={(e) => {
                    var tool = Tool;
                    tool.UnloadTime = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>
          </LeftInfoCont>
          <DividingHR></DividingHR>
          <LeftInfoCont>
            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Stock</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="# of Tool in Stock"
                  key={Tool?.Stock}
                  defaultValue={Tool?.Stock}
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.Stock = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Video</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  placeholder="YouTube URL"
                  key={Tool?.VideoUrl}
                  defaultValue={Tool?.VideoUrl}
                  inputheight="10px"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.VideoUrl = e.target.value;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <SectionCont>
              <HeaderCont>
                <SmallTitleBold>Use with Obstruction</SmallTitleBold>
              </HeaderCont>
              <ItemCont>
                <Input
                  type="checkbox"
                  key={Tool?.UseWithObstruct}
                  defaultChecked={Tool?.UseWithObstruct}
                  inputheight="20px"
                  inputwidth="20%"
                  onChange={(e) => {
                    var tool = Tool;
                    tool.UseWithObstruct = e.target.checked;
                    setTool(tool);
                  }}
                ></Input>
              </ItemCont>
            </SectionCont>

            <FlexColumnDiv>
              <TaxHeaderCont>
                <SmallTitleBold>Current Taxes</SmallTitleBold>
                <RegularResponsiveButton
                  onClick={() => {
                    setEditTaxDisplay("flex");
                  }}
                >
                  Add Tax
                </RegularResponsiveButton>
              </TaxHeaderCont>
              {Tool?.Taxes.map((o, i) => (
                <TaxInner key={i}>
                  <TaxesP>
                    {o.TaxCode} {o.Rate}%
                  </TaxesP>
                  <Icon30x40pxPointer
                    onClick={() => {
                      DeleteTax(o.Id, toolId);
                      SetTaxOptions(StoreId);
                    }}
                    src="/Garbage_Icon.svg"
                  ></Icon30x40pxPointer>
                </TaxInner>
              ))}
            </FlexColumnDiv>
            <AddTaxesCont addtaxdisplay={editTaxDisplay}>
              <SmallTitleBold>Select Tax to Add to Product</SmallTitleBold>
              {taxMap.map((o, i) => (
                <TaxInner
                  key={i}
                  onClick={() => {
                    AddToolTax(toolId, o.Id);
                    setEditTaxDisplay("none");
                  }}
                >
                  <TaxesP>
                    {o.TaxCode} {o.Rate}%
                  </TaxesP>
                </TaxInner>
              ))}
            </AddTaxesCont>
          </LeftInfoCont>
        </InnerCont>
      </Cont85vwAutoHeight>
    </FixedOverlay200vh>
  );
};

EditToolWindow.defaultProps = {
  onChange: () => {},
};

export default EditToolWindow;
