//  React Imports ---------------------------------------
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetStoreBiddingQuotes } from "../../API_Functions/CaseQuote_Functions";
import moment from "moment";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import {
  FlexColumn81vwLightGrey,
  FlexColumnFullWidthMargins,
  FlexColumnOverflowCont800px,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  LeftCont,
  FlexListOverflowPropDiv,
  DivWithDisplay,
} from "../../StylesheetComps/Cont";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import {
  LeagueSpartanHeaderSmall,
  SmallHeaderOpenSans,
  SmallHeaderOpenSansBold,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
// Import Calendar Components -----------------------------
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";

const ProjectBidListScreen = () => {
  const { metroId, storeId } = useParams();
  const params = useParams();
  const history = useHistory()
  const [storeBiddingQuotes, setStoreBiddingQuotes] = useState([]);

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };

  const GetListOfBiddingQuotes = async (storeid) => {
   
    const data = await GetStoreBiddingQuotes(storeid);
    if (data) {       
      setStoreBiddingQuotes(data);
    }
   
  };

  useEffect(() => {
    SetHeaderAuthorization();
    GetListOfBiddingQuotes(storeId);
  }, []);

  if (window.$ISMOBILE === true) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ContentCont77vw>
            <SmallHeaderOpenSansBold>
              Here are the instructions to installers: 1. Click project to view
              and bid. 2. Give a construction date, etc......
            </SmallHeaderOpenSansBold>
          </ContentCont77vw>
          <ContentCont77vw>
            <FlexColumnFullWidthMargins></FlexColumnFullWidthMargins>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ContentCont77vw>
            <SmallHeaderOpenSansBold>
            To bid on a project:
            </SmallHeaderOpenSansBold>
            <SmallHeaderOpenSans>
             <b> 1. </b> Click the 'Bid' button next to the project you would like to bid on.

            </SmallHeaderOpenSans>
            <SmallHeaderOpenSans><b> 2. </b> Adjust the estimated cost and construction date in the provided fields to submit your desired bid.
            </SmallHeaderOpenSans>
            <SmallHeaderOpenSans><b> 3. </b> Click the 'Post Bid' button to submit your bid to the project.
</SmallHeaderOpenSans>
<SmallHeaderOpenSans><b> 4. </b> If your bid is accepted by the homeowner, you will be notified via email or other communication method.
</SmallHeaderOpenSans>
          </ContentCont77vw>
          <ContentCont77vw>
            <FlexDiv>
              <FlexColumnFullWidthMargins width="100%">
                <FlexRowCont100PercentSpaceBetween>
                  <DivWithDisplay width = "175px">
                    <SmallHeaderOpenSansBold>Projects</SmallHeaderOpenSansBold>
                  </DivWithDisplay>
                  <DivWithDisplay width = "175px">
                    <SmallHeaderOpenSansBold>City</SmallHeaderOpenSansBold>
                  </DivWithDisplay>
                  <DivWithDisplay width = "225px">
                    <SmallHeaderOpenSansBold>
                      Desired Start Date
                    </SmallHeaderOpenSansBold>
                  </DivWithDisplay >
                  <DivWithDisplay width = "175px">
                    <SmallHeaderOpenSansBold></SmallHeaderOpenSansBold>
                  </DivWithDisplay >
                </FlexRowCont100PercentSpaceBetween>
                <FlexListOverflowPropDiv maxheight="600px">
                  {storeBiddingQuotes.map((o, i) => (
                    <div key={i}>
                      <br></br>
                      <FlexRowCont100PercentSpaceBetween>
                        <DivWithDisplay width = "175px">
                          <LeagueSpartanHeaderSmall>
                            {o.Tittle} <br></br>
                          </LeagueSpartanHeaderSmall>
                          {"$ " + o.BidAmount?.toFixed(2).replace(
                                                /\B(?=(\d{3})+(?!\d))/g, "," )}
                        </DivWithDisplay >
                        <DivWithDisplay width = "175px">
                          <SmallHeaderOpenSans>{o.Address}</SmallHeaderOpenSans>
                        </DivWithDisplay >
                        <DivWithDisplay width = "225px">
                          <SmallHeaderOpenSans>
                            {moment(o.DesiredInstallDate)
                              .startOf("day")
                              .format("LL")}                        
                          </SmallHeaderOpenSans>
                        </DivWithDisplay>
                        <DivWithDisplay width = "175px">
                          <RegularResponsiveButton onClick={()=>{    history.push(
          "/bidquote/" +
            params.metroId +
            "/" +
            params.storeId +
            "/" +
            o.Case_Id +
            "/" +
            o.Id +
            "/" +
            0 +
            "/" +
            0
        );}}>Bid</RegularResponsiveButton>
                        </DivWithDisplay >
                      </FlexRowCont100PercentSpaceBetween>
                      <StyledHRPropColour
                        width="100%"
                        border={`0.5px dotted ${ColorPalette.SecondaryGrey}`}
                      ></StyledHRPropColour>
                      <br></br>
                    </div>
                  ))}
                </FlexListOverflowPropDiv>
              </FlexColumnFullWidthMargins>
            </FlexDiv>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  }
};

ProjectBidListScreen.defaultProps = {};

export default ProjectBidListScreen;
