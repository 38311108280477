/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import CreateAppointment from "../../comps/CreateAppointment";
import Menu from "../../comps/MenuComps/Menu";
import AvailableWindow from "../../comps/Windows/AvailableDates";

import * as dates from "date-arithmetic";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import PropTypes from "prop-types";
import {
  Calendar,
  dateFnsLocalizer,
  Navigate,
  Views,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import "react-datepicker/dist/react-datepicker.css";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FlexDiv,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const InstallerCalendarScreen = () => {
  //=============================================================================
  // Installer Calendar Screen - Users can make appointments with Installer
  //=============================================================================

  //=============================================================================
  // Displays Installer Available Days in Calendar View
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [appointmentList] = useState([]);
  const [installerColors, setInstallerColors] = useState([]);
  const [closeDays, setCloseDays] = useState([]);
  const [vacationDays, setVacationDays] = useState([]);
  const [createAppointmentOpen, setCreateAppointmentOpen] = useState(false);
  const [defaultSlotId, setDefaultSlotId] = useState(0);

  const [name, setDefaultName] = useState(0);
  const [defaultAddress, setDefaultAddress] = useState(0);
  const [defaultCity, setDefaultCity] = useState(0);
  const [cellphone, setDefaultCellphone] = useState(0);

  const [checkLogicBox, setCheckLogicBox] = useState(false);

  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;

  const [calendarMonth, setCalendarMonth] = useState(currentMonth);

  const [nextAvailMonth, setNextAvailMonth] = useState(0);
  const [nextAvailDay, setNextAvailDay] = useState(0);
  const [nextAvailYear, setNextAvailYear] = useState(0);

  const CheckAvailableDates = async () => {
    try {
      // Make an HTTP request to the specified URL with the quote, installer, and current time
      const resp = await axios.post(`${window.$DBURL}appointment/date/quote`, {
        Quote_Id: params.quoteId,
        Installer_Id: params.instUserId,
        CurTime: `${storeYear}-${storeMonth}-${storeDay}T10:00:00`,
      });

      // If the request was successful and the dates are not null, set the next available date
      if (resp.status === 200 && resp.data && resp.data.dates) {
        const date = resp.data.dates[0];
        setNextAvailMonth(date.substring(5, 7));
        setNextAvailYear(date.substring(0, 4));
        setNextAvailDay(date.substring(8, 10));
        setCheckLogicBox(true);
      }
    } catch (err) {
      // Handle error
    }
  };

  const HandleDateSelection = async () => {
    const path = `/installersavailable/${params.Id}/${params.storeId}/${nextAvailMonth}/${nextAvailDay}/${nextAvailYear}/${params.instUserId}/${params.quoteId}`;
    history.push(path);
  };

  const GetSessionInfo = async () => {
    try {
      // Get the user information from session storage
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

      // Set the default name, address, city, and cellphone using the user information
      setDefaultName(userInfo.Name);
      setDefaultAddress(userInfo.Street);
      setDefaultCity(userInfo.City);
      setDefaultCellphone(userInfo.Phone);

      // Get the installer's close days, colors, and vacation days
      GetInstallerCloseDays();
      GetInstallerColors();
      GetInstallerVacationDays();

      // If the route ID is 1, handle the start info time slots
      if (params.routeId == 1) {
        HandleStartInfoTimeSlots();
      }
    } catch (err) {
      // Handle the error here
    }
  };

  const GetInstallerColors = async () => {
    try {
      const resp = await axios.post(
        `${window.$DBURL}appointment/installer/calendar`,
        {
          Year: currentYear,
          Month: currentMonth,
          Installer_Id: params.instUserId,
        }
      );

      if (resp.status === 200 && resp.data && resp.data.status) {
        setInstallerColors(resp.data.status);
      }
    } catch (err) {
      // handle error
    }
  };

  const GetInstallerColorsAsync = async () => {
    try {
      const resp = await axios.post(
        `${window.$DBURL}appointment/installer/calendar`,
        {
          Year: currentYear,
          Month: calendarMonth,
          Installer_Id: params.instUserId,
        }
      );

      if (resp.status === 200 && resp.data && resp.data.status) {
        setInstallerColors(resp.data.status);
      }
    } catch (err) {
      // handle error
    }
  };

  const GetInstallerCloseDays = async () => {
    try {
      const resp = await axios.get(
        `${window.$DBURL}appointment/close/installer/${params.instUserId}`
      );

      if (resp.status === 200 && resp.data && resp.data.closedays) {
        setCloseDays(resp.data.closedays);
      }
    } catch (err) {
      // handle error
    }
  };

  const GetInstallerVacationDays = async () => {
    try {
      const resp = await axios.get(
        `${window.$DBURL}appointment/vacation/user/${params.instUserId}`
      );

      if (resp.status === 200 && resp.data && resp.data.vacationdays) {
        setVacationDays(resp.data.vacationdays);
      }
    } catch (err) {
      // handle error
    }
  };

  var fullDate = "";
  var storeMonth = 0;
  var storeYear = 0;
  var storeDay = 0;
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);

  var bookd = new Date();
  var bookfullyear = bookd.getFullYear();
  var bookmonth = ("0" + (bookd.getMonth() + 1)).slice(-2);
  var bookday = ("0" + bookd.getDate()).slice(-2);
  var bookhour = ("0" + bookd.getHours()).slice(-2);
  var booktime = ("0" + bookd.getMinutes()).slice(-2);
  var bookseconds = ("0" + bookd.getSeconds()).slice(-2);

  const HandleAppointment = async (
    contactName,
    address,
    phone,
    city,
    flexible,
    slotId,
    appDesc
  ) => {
    try {
      const bookTime = `${bookfullyear}-${bookmonth}-${bookday}T${bookhour}:${booktime}:${bookseconds}`;
      const payload = {
        DailyOpenSlot_Id: slotId,
        Installer_Id: params.instUserId,
        Day: day,
        Month: month,
        Year: year,
        Flexible: flexible,
        InstallerName: params.instName,
        BookTime: bookTime,
        Contactname: contactName || name,
        Phone: phone || cellphone,
        Street: address || defaultAddress,
        City: city || defaultCity,
        Description: appDesc,
        Store_Id: params.storeId,
      };

      let resp;
      if (params.quoteId === 0) {
        resp = await axios.post(`${window.$DBURL}appointment`, payload);
      } else {
        resp = await axios.post(
          `${window.$DBURL}appointment/quote/${params.quoteId}`,
          payload
        );
      }

      if (resp.status === 200) {
        GetInstallerColorsAsync();
      }
    } catch (err) {
      // Handle the error here
    }
  };

  // const HandleAppointment = async (
  //   contactName,
  //   address,
  //   phone,
  //   city,
  //   flexible,
  //   slotId,
  //   appDesc
  // ) => {
  //   if (params.quoteId === 0) {
  //     const resp = await axios.post(window.$DBURL + "appointment", {
  //       DailyOpenSlot_Id: slotId,
  //       Installer_Id: params.instUserId,
  //       Day: day,
  //       Month: month,
  //       Year: year,
  //       Flexible: flexible,
  //       InstallerName: params.instName,
  //       BookTime:
  //         bookfullyear +
  //         "-" +
  //         bookmonth +
  //         "-" +
  //         bookday +
  //         "T" +
  //         bookhour +
  //         ":" +
  //         booktime +
  //         ":" +
  //         bookseconds,
  //       Contactname: contactName || name,
  //       Phone: phone || cellphone,
  //       Street: address || defaultAddress,
  //       City: city || defaultCity,
  //       Description: appDesc,
  //       Store_Id: params.storeId,
  //     });
  //     if (resp.status === 200) {
  //       GetInstallerColorsAsync();
  //     }
  //   } else {
  //     const resp = await axios.post(
  //       window.$DBURL + "appointment/quote/" + params.quoteId,
  //       {
  //         DailyOpenSlot_Id: slotId,
  //         Installer_Id: params.instUserId,
  //         Day: day,
  //         Month: month,
  //         Year: year,
  //         Flexible: flexible,
  //         InstallerName: params.instName,
  //         BookTime:
  //           bookfullyear +
  //           "-" +
  //           bookmonth +
  //           "-" +
  //           bookday +
  //           "T" +
  //           bookhour +
  //           ":" +
  //           booktime +
  //           ":" +
  //           bookseconds,
  //         Contactname: contactName || name,
  //         Phone: phone || cellphone,
  //         Street: address || defaultAddress,
  //         City: city || defaultCity,
  //         Description: appDesc,
  //         Store_Id: params.storeId,
  //       }
  //     );
  //     if (resp.status === 200) {
  //       GetInstallerColorsAsync();
  //     }
  //   }
  // };

  const installerSlotPropGetter = (date) => {
    return { style: { backgroundColor: "white" } };
  };

  const closeDayPropGetter = (date) => {
    var obj = installerColors.find((o, i) => {
      if (
        (o.Date === date.getDate() &&
          o.Month === date.getMonth() + 1 &&
          o.Year === date.getFullYear() &&
          o.Status === 1) ||
        (o.Date === date.getDate() &&
          o.Month === date.getMonth() + 1 &&
          o.Year === date.getFullYear() &&
          o.Status === 2) ||
        (o.Date === date.getDate() &&
          o.Month === date.getMonth() + 1 &&
          o.Year === date.getFullYear() &&
          o.Status === -1)
      )
        return installerColors[i];
    });
    if (
      obj !== undefined &&
      obj.Date === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear() &&
      obj.Status === 1
    )
      return { style: { backgroundColor: "#169ADB", cursor: "pointer" } };
    else if (
      obj !== undefined &&
      obj.Date === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear() &&
      obj.Status === 2
    )
      return { style: { backgroundColor: "red", cursor: "not-allowed" } };
    else if (
      obj !== undefined &&
      obj.Date === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear() &&
      obj.Status === -1
    )
      return { style: { backgroundColor: "grey", cursor: "not-allowed" } };

    var holidayObj = vacationDays.find((o, i) => {
      if (
        o.Day === date.getDate() &&
        o.Month === date.getMonth() + 1 &&
        o.Year === date.getFullYear()
      )
        return vacationDays[i];
    });
    if (
      holidayObj !== undefined &&
      holidayObj.Day === date.getDate() &&
      holidayObj.Month === date.getMonth() + 1 &&
      holidayObj.Year === date.getFullYear()
    )
      return { style: { backgroundColor: "grey", cursor: "not-allowed" } };

    switch (date.getDay()) {
      case 0:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 0) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 0) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 1:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 1) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 1) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 2:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 2) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 2) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 3:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 3) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 3) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 4:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 4) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 4) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 5:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 5) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 5) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      case 6:
        return {
          className: "close-days",
          style: {
            backgroundColor: closeDays.find(function (o, i) {
              if (o.WeekDay === 6) {
                return true;
              }
            })
              ? "grey"
              : "",
            cursor: closeDays.find(function (o, i) {
              if (o.WeekDay === 6) {
                return true;
              }
            })
              ? "not-allowed"
              : "pointer",
          },
        };
        break;
      default:
    }
  };

  class MyWeek extends React.Component {
    render() {
      let {
        date,
        localizer,
        min = localizer.startOf(new Date(), "day"),
        max = localizer.endOf(new Date(), "day"),
        scrollToTime = localizer.startOf(new Date(), "day"),
      } = this.props;
      let range = MyWeek.range(date, { localizer });

      return (
        <TimeGrid
          {...this.props}
          range={range}
          eventOffset={15}
          localizer={localizer}
          min={min}
          max={max}
          scrollToTime={scrollToTime}
        />
      );
    }
  }

  MyWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.any,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  MyWeek.range = (date, { localizer }) => {
    let start = date;
    let end = dates.add(start, 0, "day");

    let current = start;
    let range = [];

    while (localizer.lte(current, end, "day")) {
      range.push(current);
      current = localizer.add(current, 1, "day");
    }

    return range;
  };

  MyWeek.navigate = (date, action, { localizer }) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return localizer.add(date, -1, "day");

      case Navigate.NEXT:
        return localizer.add(date, 1, "day");

      default:
        return date;
    }
  };

  MyWeek.title = (date) => {
    return `Installer Appointment Slots: ${date.toLocaleDateString()}`;
  };

  const HandleStartInfo = async () => {
    setYear(fullDate.getFullYear());
    setMonth(fullDate.getMonth() + 1);
    setDay(fullDate.getDate());
  };

  const HandleStartInfoTimeSlots = async () => {
    setYear(params.year);
    setMonth(params.month);
    setDay(params.day);
    setDefaultSlotId(params.instId);
    setCreateAppointmentOpen(true);
  };

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  useEffect(() => {
    GetInstallerColorsAsync();
  }, [calendarMonth]);

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <CreateAppointment
            name={name}
            defaultSlotId={defaultSlotId}
            defaultaddress={defaultAddress}
            defaultcity={defaultCity}
            cellphone={cellphone}
            year={year}
            month={month}
            day={day}
            open={createAppointmentOpen}
            onClose={() => {
              setCreateAppointmentOpen(false);
            }}
            onAppointmentCreate={HandleAppointment}
          ></CreateAppointment>
          <FlexDiv>
            <Icon30px20pxPointer
              src="/backarrow.svg"
              onClick={() => {
                history.push(
                  "/storeinstallers/" + params.Id + "/" + params.storeId
                );
              }}
            ></Icon30px20pxPointer>
            <LeagueSpartanExtraLargeBold>
              {params.instName + "'s Installer Calendar"}
            </LeagueSpartanExtraLargeBold>
          </FlexDiv>
          <AvailableWindow
            PushToSlots={HandleDateSelection}
            onClose={() => {
              setCheckLogicBox(false);
            }}
            open={checkLogicBox}
            day={nextAvailDay}
            month={nextAvailMonth}
            year={nextAvailYear}
          ></AvailableWindow>
          <Calendar
            localizer={localizer}
            events={appointmentList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Description + " with " + o.InstallerName,
                start: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.Start.Hour,
                  o.Start.Minute
                ),
                end: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.End.Hour,
                  o.End.Minute
                ),
                allDay: false,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={calendarStyle}
            dayPropGetter={closeDayPropGetter}
            views={{ month: true, week: true, day: true }}
            selectable={true}
            min={new Date(2021, 11, 0, 8, 0, 0)}
            max={new Date(2021, 11, 0, 18, 0, 0)}
            longPressThreshold={10}
            defaultView={Views.MONTH}
            slotPropGetter={installerSlotPropGetter}
            onSelectSlot={(slotInfo) => {
              fullDate = slotInfo.slots[0];
              storeMonth = fullDate.getMonth() + 1;
              storeYear = fullDate.getFullYear();
              storeDay = fullDate.getDate();
              if (
                closeDays.find(function (o, i) {
                  return o.WeekDay === fullDate.getDay();
                })
              ) {
              } else if (
                vacationDays.find(function (o, i) {
                  return (
                    o.Day === fullDate.getDate() &&
                    o.Month === fullDate.getMonth() + 1 &&
                    o.Year === fullDate.getFullYear()
                  );
                })
              ) {
              } else if (
                installerColors.find(function (o, i) {
                  return (
                    o.Date === fullDate.getDate() &&
                    o.Month === fullDate.getMonth() + 1 &&
                    o.Year === fullDate.getFullYear() &&
                    o.Status === 2
                  );
                })
              ) {
              } else if (D > fullDate) {
              } else if (
                installerColors.find(function (o, i) {
                  return (
                    o.Date === fullDate.getDate() &&
                    o.Month === fullDate.getMonth() + 1 &&
                    o.Year === fullDate.getFullYear() &&
                    o.Status === -1
                  );
                })
              ) {
              } else if (params.quoteId !== "0") {
                CheckAvailableDates();
              } else {
                HandleStartInfo();
                history.push(
                  "/installersavailable/" +
                    params.Id +
                    "/" +
                    params.storeId +
                    "/" +
                    storeMonth +
                    "/" +
                    storeDay +
                    "/" +
                    storeYear +
                    "/" +
                    params.instUserId +
                    "/0"
                );
              }
            }}
            onNavigate={(date) => {
              setCalendarMonth(date.getMonth() + 1);
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

InstallerCalendarScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default InstallerCalendarScreen;
