import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FlexCont300x150px } from "../../StylesheetComps/Cont";
import { RegularText, LeagueSpartanLarge } from "../../StylesheetComps/Titles";
import {
  Div30Percent,
  Div70Percent,
  FlexEndDivAutox15Percent,
} from "../../StylesheetComps/Div";
import { Icon80x80Percent } from "../../StylesheetComps/Imgs/Icons";

const StoreEstimateListCard = ({ CatHeadText, CatParaText, storeId }) => {
  const params = useParams();

  return (
    <FlexCont300x150px>
      <Div30Percent>
        <Icon80x80Percent src="/storemanagement.svg"></Icon80x80Percent>
      </Div30Percent>
      <Div70Percent>
        <Link
          to={"/estimate/" + params.Id + "/" + storeId}
          style={{ textDecoration: "none", color: "black" }}
        >
          <LeagueSpartanLarge>{CatHeadText}</LeagueSpartanLarge>
        </Link>
        <RegularText>{CatParaText}</RegularText>
      </Div70Percent>
      <FlexEndDivAutox15Percent></FlexEndDivAutox15Percent>
    </FlexCont300x150px>
  );
};
StoreEstimateListCard.defaultProps = {
  CatHeadText: "Fencing",
  CatParaText:
    "This is a category for the products that are contained within, sample text",
  storeId: 0,
};

export default StoreEstimateListCard;
