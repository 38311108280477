import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont70PercentFlexStart,
  FlexRowContBoxShadow,
  FlexRowContFlexStart,
} from "../../StylesheetComps/Cont";
import { FlexDivSpaceAround } from "../../StylesheetComps/Div";
import { LeagueSpartanRegular } from "../../StylesheetComps/Titles";

const InstallerQuoteAppCard = ({
  InstallerName,
  installerUserId,
  month,
  day,
  year,
  metroId,
  storeId,
}) => {
  const history = useHistory();
  const params = useParams();

  return (
    <FlexRowCont70PercentFlexStart>
      <FlexRowContBoxShadow>
        <FlexRowCont100PercentSpaceBetween>
          <FlexRowContFlexStart>
            <LeagueSpartanRegular>{InstallerName}</LeagueSpartanRegular>
          </FlexRowContFlexStart>
          <FlexDivSpaceAround>
            <RegularResponsiveButton
              width={"250px"}
              onClick={() => {
                history.push(
                  "/installersavailable/" +
                    metroId +
                    "/" +
                    storeId +
                    "/" +
                    month +
                    "/" +
                    day +
                    "/" +
                    year +
                    "/" +
                    installerUserId +
                    "/" +
                    params.quoteId
                );
              }}
            >
              Select Earliest Date: ({month}/{day}/{year})
            </RegularResponsiveButton>

            <RegularResponsiveButton
              onClick={() => {
                history.push(
                  "/installercalendar/" +
                    metroId +
                    "/" +
                    storeId +
                    "/" +
                    0 +
                    "/" +
                    installerUserId +
                    "/" +
                    InstallerName +
                    "/" +
                    0 +
                    "/" +
                    0 +
                    "/" +
                    0 +
                    "/" +
                    0 +
                    "/" +
                    params.quoteId
                );
              }}
              width={"230px"}
              bgcolor={ColorPalette.PrimaryGrey}
            >
              Select Date (With Interruptions)
            </RegularResponsiveButton>
          </FlexDivSpaceAround>
        </FlexRowCont100PercentSpaceBetween>
      </FlexRowContBoxShadow>
    </FlexRowCont70PercentFlexStart>
  );
};

InstallerQuoteAppCard.defaultProps = {
  InstallerName: "Default Name",
  installerUserId: 0,
  month: 0,
  day: 0,
  year: 0,
  metroId: 0,
  storeId: 0,
};

export default InstallerQuoteAppCard;
