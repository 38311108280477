import React from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContRedBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { useHistory } from "react-router-dom";
import { DeleteCaseById } from "../../../API_Functions/CaseQuote_Functions";
import { useParams } from "react-router-dom";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const DeleteProjectWindow = ({ deleteOpen, CloseWindow, projectId }) => {
  const history = useHistory();
  const params = useParams();

  const DeleteProject = async (project_id, metro_id, store_id) => {
    var data = await DeleteCaseById(project_id);
    if (data === null) {
    }
    history.push("/CasesPersonal/" + metro_id + "/" + store_id);
  };

  if (!deleteOpen) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContRedBorderTopRounded>
        <SmallHeaderLeagueSpartan>
          You're about to delete your project
        </SmallHeaderLeagueSpartan>
        <SmallTextPadding>
          Are you sure you want to delete your project? All of your project’s
          information, photos, and progress will be permanently removed and you
          won’t be able to see them again.
        </SmallTextPadding>
        <FlexRowCont>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={CloseWindow}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              CloseWindow();
              DeleteProject(projectId, params.Id, params.storeId);
            }}
          >
            Delete Project
          </RegularResponsiveButton>
        </FlexRowCont>
      </FlexColumnContRedBorderTopRounded>
    </FixedOverlay200vh>
  );
};

DeleteProjectWindow.defaultProps = {
  CloseWindow: () => {},
};

export default DeleteProjectWindow;
