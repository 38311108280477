import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Cont450pxAutoHeight,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanExtraLargeWidth200px,
} from "../../../StylesheetComps/Titles";

const CityList = styled.div`
  width: 80%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px;
  margin-left: 20px;
  line-height: 40px;
  cursor: pointer;
`;

const ZoneCityWindow = ({ zoneOpen, onClose, onFormComplete, onPreview }) => {
  const params = useParams();
  const [metroCities, getMetroCities] = useState([]);

  const GetCities = async () => {
    const respcurrentcities = await axios.get(
      window.$DBURL + "store/zone/" + params.zoneId + "/cities"
    );
    let openDaysSync = respcurrentcities.data.cities.map(({ Name }) => Name);
    const resp = await axios.get(
      window.$DBURL + "utilities/city/metro/" + params.Id
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.cities !== null) {
          getMetroCities(
            resp.data.cities.filter(function (o) {
              return !openDaysSync.includes(o.Name);
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    GetCities();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!zoneOpen) return null;

  return (
    <>
      {" "}
      <Cont450pxAutoHeight>
        <LeagueSpartanExtraLargeBold>
          Add City to Zone
        </LeagueSpartanExtraLargeBold>
        <LeftItemWindowFixed>
          <LeagueSpartanExtraLargeWidth200px>
            Pick City to Add to Zone
          </LeagueSpartanExtraLargeWidth200px>
          {metroCities.map((o, i) => (
            <CityList
              key={i}
              onClick={() => {
                onPreview(o.Id);
                onFormComplete(o.Id);
                onClose();
              }}
            >
              {o.Name}
            </CityList>
          ))}
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </LeftItemWindowFixed>
      </Cont450pxAutoHeight>{" "}
    </>
  );
};

ZoneCityWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
};

export default ZoneCityWindow;
