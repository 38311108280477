import { useHistory, useParams } from "react-router-dom";
import { FlexCont5vhMobile88x5, FlexDiv } from "../../StylesheetComps/Cont";
import { Icon35px100Perc } from "../../StylesheetComps/Imgs/Icons";
import { RegularText } from "../../StylesheetComps/Titles";

const MetroStoreCard = ({
  ManagerName,
  StoreLocationName,
  MetroName,
  onClick,
  onDeleteStore,
  StoreID,
  EditStore,
  onSetEdit,
  StoreName,
}) => {
  const params = useParams();
  const history = useHistory();

  return (
    <FlexCont5vhMobile88x5>
      <RegularText
        onClick={() => {
          history.push("/storeadmin/" + params.Id + "/" + StoreID);
        }}
      >
        {StoreName}
      </RegularText>

      <RegularText
        onClick={() => {
          history.push("/storeadmin/" + params.Id + "/" + StoreID);
        }}
      >
        {StoreLocationName}
      </RegularText>
      <RegularText>{ManagerName}</RegularText>
      <FlexDiv>
        <Icon35px100Perc
          src={"/EditIcon.svg"}
          onClick={() => {
            EditStore();
            onSetEdit = { StoreID };
          }}
        ></Icon35px100Perc>
        <Icon35px100Perc
          src="/Garbage_Icon.svg"
          onClick={onDeleteStore}
        ></Icon35px100Perc>
      </FlexDiv>
    </FlexCont5vhMobile88x5>
  );
};

MetroStoreCard.defaultProps = {
  ManagerName: "No Manager",
  StoreName: "Default Store Name",
  StoreLocationName: "Default Store Address",
  MetroName: "Toronto",
  StoreID: 0,
  onDeleteStore: () => {},
};

export default MetroStoreCard;
