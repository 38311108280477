import React, { useState, useEffect } from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import styled from "styled-components";
import {
  FlexColumnCont90x95Percent,
  FlexColumnCont95x30Percent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
  FlexRowContFlexStart,
} from "../../../StylesheetComps/Cont";
import {
  LandscapePara,
  MediumStyledImage,
  SmallHeaderLeagueSpartan,
  TextInputRegular,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { AddCasePhoto } from "../../../API_Functions/CaseQuote_Functions";
import { Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { LinkText } from "../../../StylesheetComps/AuthSheet";
import { GetCategory, GetStoreCategories, UpdateCategory, UpdateProduct } from "../../../API_Functions/CatProduct_Functions";
import { useParams } from "react-router-dom";
import { LeagueHeader14px } from "../../../MobileComponents/Text";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const ListContainer = styled.div`
  width: 100%; // Or you can set a specific width
  height: 100%; // Set a fixed height or adjust as necessary
  overflow-y: auto; // Enables vertical scrolling
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); // subtle shadow for depth
`;

const SleekRowContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 12px 20px;
  background-color: ${props => props.isSelected ? '#f0f0f0' : '#ffffff'}; // Slightly grey when selected
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
    transform: translateY(-2px);
  }
`;

// Styling the title for each item
const ItemTitle = styled.h2`
  font-size: 16px;
  color: #2c2c2e; // Dark charcoal, almost black
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500; // Medium weight for readability
  margin: 0;
  transition: color 0.3s ease;

  ${SleekRowContainer}:hover & {
    color: #1a1a1a; // Slightly darker on hover
  }
`;


const MoveToFolder = ({
  open,
  onClose,
  movedItemName,
  movedItemObject,
  CloseWindow,
  CancelUpload,
  previewsrc,
  imgtype,
  caseObj,
  AssignIdToImage,
}) => {

const params = useParams();
const [categories, setCategories] = useState([])
const [selectedItemIndex, setSelectedItemIndex] = useState(null);
const [selectedCategory, setSelectedCategory] = useState({});
const [currentParentId, setCurrentParentId] = useState(0);  // Assume 0 means top-level

const GetAllStoreFolders = async (storeid) => {
    const resp = await GetStoreCategories(storeid);
    if(resp){
        setCategories(resp.cate)
    }
}

const handleFilterFolders = async (text) => {
console.log(selectedItemIndex)
}

const handleDoubleClick = async (categoryId) => {
    try {
        const subcategories = await GetCategory(categoryId);
        if (subcategories && Array.isArray(subcategories.SubCategories)) {
            if (subcategories.SubCategories.length > 0) {
                setCategories(subcategories.SubCategories);
                setCurrentParentId(subcategories.Parent_Id); // Update the current parent ID
            } else {
                window.alert('No Subcategories for selection');
            }
        } else {
            console.error('Invalid subcategories data:', subcategories);
        }
    } catch (error) {
        console.error('Error fetching subcategories:', error);
        window.alert('Failed to load subcategories.');
    }
};

const MoveToNewCategory = async (categoryobj, newcategoryobj) => {
    console.log(categoryobj, newcategoryobj);

    // Update the Category_Id of categoryobj with the Id of newcategoryobj
    categoryobj.Category_Id = newcategoryobj.Id;

    // Log the updated object for verification
    console.log('Updated Category Object:', categoryobj);

    const resp = await UpdateProduct(categoryobj)
    if(resp){
        console.log(resp)
    }
}


const handleBackClick = async () => {
    // Check if the currentParentId is valid and not the top level
    if (currentParentId !== 0) {
        try {
            const parentCategory = await GetCategory(currentParentId);
            if (parentCategory && Array.isArray(parentCategory.SubCategories)) {
                setCategories(parentCategory.SubCategories);
                // Set to the parent of the current category, or to top level if none exists
                setCurrentParentId(parentCategory.Parent_Id || 0);
            } else {
                // If no valid parent is found, navigate back to the top level
                GetAllStoreFolders(params.Id);
                setCurrentParentId(0); // Reset to indicate top-level
            }
        } catch (error) {
            console.error('Error fetching parent category:', error);
            // On error, also navigate back to the top level
            GetAllStoreFolders(params.Id);
            setCurrentParentId(0); // Reset to indicate top-level on error
        }
    } else {
        // If already at the top level with Parent_Id 0, fetch top-level categories again
        GetAllStoreFolders(params.Id);
    }
};

useEffect(() => {
GetAllStoreFolders(params.Id)
}, [])


  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <FlexRowCont
          height="100%"
          justifycontent="flex-start"
          alignitems="baseline"
        >
            <button onClick={handleBackClick}>
    Back
</button>
          <FlexColumnCont90x95Percent>
            <SmallHeaderLeagueSpartan>Move {movedItemName}</SmallHeaderLeagueSpartan>
            <br></br>
            <ListContainer>
  {Array.isArray(categories) && categories.map((o, i) => (
    <SleekRowContainer
      key={i}
      isSelected={selectedItemIndex === i}
      onClick={() => {setSelectedItemIndex(o.Id); setSelectedCategory(o)}}
      onDoubleClick={() => handleDoubleClick(o.Id)}
    >
      <ItemTitle>{o.Name}</ItemTitle>
    </SleekRowContainer>
  ))}
</ListContainer>
            <FlexColumnCont95x30Percent>
            
            </FlexColumnCont95x30Percent>
            <FlexRowCont justifycontent="flex-end">
              <LinkText
                
              >
                Cancel
              </LinkText>
              <RegularResponsiveButton
                onClick={()=>{
                    MoveToNewCategory(movedItemObject, selectedCategory)
                }}
              >
                Move To Category
              </RegularResponsiveButton>
            </FlexRowCont>
          </FlexColumnCont90x95Percent>
          <Icon20x20
            onClick={()=>{onClose()}}
            src="/Close.svg"
          ></Icon20x20>
        </FlexRowCont>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

MoveToFolder.defaultProps = {
  onClose:()=>{},
  movedItemName: 'Item Name'
};

export default MoveToFolder;
