import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

const StoreListInstallersAvailableScreen = ({
  onPreview,
  onProfileUpdated,
  storeId,
}) => {
  //=============================================================================
  // Installers Available Screen - User views Timeslots available for Installer
  //=============================================================================

  //=============================================================================
  // This page is redirects to Installer Calendar where user is prompted to
  // create an appointment using the timeslot selected on this page
  //=============================================================================

  const history = useHistory();

  const [noneAvailableText, setNoneAvailableText] = useState("");

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const [installerHeader, setInstallerHeader] = useState("");

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const params = useParams();

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const [installerList, setInstallerList] = useState([]);

  const GetInstallers = async () => {
    try {
      const resp = await axios.post(
        `${window.$DBURL}appointment/store/available`,
        {
          Year: params.storeYear,
          Month: params.storeMonth,
          Day: params.storeDay,
          Store_Id: params.storeId,
        }
      );
      if (resp?.data?.openslots?.length > 0) {      
        setInstallerList(resp.data.openslots);
        setStartHour(resp.data.openslots[0].Start_Hour);
        setStartMinute(resp.data.openslots[0].Start_Minute);
        const maxObj = resp.data.openslots.reduce((max, obj) =>
          max.End_Hour > obj.End_Hour ? max : obj
        );
        const maxMinute = resp.data.openslots.reduce((max, obj) =>
          max.End_Hour > obj.End_Hour ? max : obj
        );
        setEndMinute(maxMinute.End_Minute);
        setEndHour(maxObj.End_Hour);
      }
      else{
        setNoneAvailableText("No time slots set up for appointment");
      }
    } catch (error) {
      // handle error
    }
  };

  const GetSingleInstaller = async () => {
    try {
      const resp = await axios.post(
        window.$DBURL + "appointment/installer/available",
        {
          Year: params.storeYear,
          Month: params.storeMonth,
          Day: params.storeDay,
          Installer_Id: params.instId,
        }
      );
      const data = resp;
      if ( data?.openslots?.length > 0) {
        setInstallerList(data.openslots);
        setStartHour(data.openslots[0].Start_Hour);
        setStartMinute(data.openslots[0].Start_Minute);
        setInstallerHeader(`${data.openslots[0].Name} Timeslots Available on`);
        const maxObj = data.openslots.reduce(
          (max, obj) => (max.End_Hour > obj.End_Hour ? max : obj),
          {}
        );
        const maxMinute = data.openslots.reduce(
          (max, obj) => (max.End_Hour > obj.End_Hour ? max : obj),
          {}
        );
        setEndMinute(maxMinute.End_Minute);
        setEndHour(maxObj.End_Hour);
      } else {
        setNoneAvailableText("No time slot set up for appointment");
      }
    } catch (error) {
      // Handle error
    }
  };

  const getCustomToolbar = (toolbar) => {
    return null;
  };

  useEffect(() => {
    if (params.instId === 0) {
      GetInstallers();
      setInstallerHeader("Installers Available on");
    } else if (params.intId !== 0) {
      GetSingleInstaller();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calendarStyle = {
    height: "75vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <FlexDiv70x7Percent>
            <HundredPercentFlexDiv>
              <Icon30px20pxPointer
                src="/backarrow.svg"
                onClick={() => {
                  // Destructure the properties from the params object
                  const { instId, Id, storeId } = params;

                  // Use a ternary operator to determine which path to push to history
                  history.push(
                    instId === 0
                      ? `/storecalendar/${Id}/${storeId}`
                      : `/storeinstallers/${Id}/${storeId}`
                  );
                }}
              ></Icon30px20pxPointer>
              <LeagueSpartanExtraLargeBold>
                {installerHeader +
                  " " +
                  params.storeMonth +
                  "/" +
                  params.storeDay +
                  "/" +
                  params.storeYear}
              </LeagueSpartanExtraLargeBold>
            </HundredPercentFlexDiv>
          </FlexDiv70x7Percent>
          <RegularTextBoldRed>{noneAvailableText}</RegularTextBoldRed>
          <Calendar
            localizer={localizer}
            defaultDate={
              new Date(params.storeYear, params.storeMonth - 1, params.storeDay)
            }
            events={installerList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Name + " is available",
                start: new Date(
                  params.storeYear,
                  params.storeMonth - 1,
                  params.storeDay,
                  o.Start_Hour,
                  o.Start_Minute
                ),
                end: new Date(
                  params.storeYear,
                  params.storeMonth - 1,
                  params.storeDay,
                  o.End_Hour,
                  o.End_Minute
                ),
                allDay: false,
                installerUserId: o.User_Id,
                InstallerName: o.Name,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={calendarStyle}
            dayLayoutAlgorithm="no-overlap"
            min={new Date(2021, 1, 1, startHour, startMinute, 0)}
            max={new Date(2021, 12, 31, endHour, endMinute, 0)}
            longPressThreshold={10}
            views={{ month: true, week: true, day: true }}
            defaultView={Views.DAY}
            components={{
              toolbar: getCustomToolbar,
            }}
            onNavigate={() => {
              GetInstallers();
            }}
            onSelectEvent={(event) => {
              // Destructure the properties from the params object
              const { Id, storeId, storeYear, storeMonth, storeDay, quoteId } =
                params;

              // Destructure the properties from the event object
              const { id, installerUserId, InstallerName } = event;

              // Use template literals to create the path string
              const path = `/installercalendar/${Id}/${storeId}/${id}/${installerUserId}/${InstallerName}/1/${storeYear}/${storeMonth}/${storeDay}/${quoteId}`;

              // Push the path to history
              history.push(path);
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

StoreListInstallersAvailableScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
  storeId: 0,
};

export default StoreListInstallersAvailableScreen;
