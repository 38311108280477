import React, { useState, useEffect } from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnCont90x95Percent,
  FlexColumnCont95x30Percent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import {
  LandscapePara,
  MediumStyledImage,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { AddQuotePhoto } from "../../../API_Functions/CaseQuote_Functions";
import { Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { LinkText } from "../../../StylesheetComps/AuthSheet";

const SiteMapOverlayUpload = ({
  open,
  CloseWindow,
  CancelUpload,
  previewsrc,
  quoteObj,
  UploadSuccessful,
  UsePhotoAsOverlay
}) => {
  const SpinnerIcon = "/Spinner.svg";

  const [previewImage, setPreviewImage] = useState("");
  const [imageCaption, setImageCaption] = useState("");

  //Upload Boolean prevents Images from getting uploaded twice.

  const [uploadBoolean, setUploadBoolean] = useState(false);

  useEffect(() => {
    if (previewsrc !== undefined && previewsrc !== null) {
      if (previewsrc.includes("pdf") || previewsrc.includes("PDF")) {
        setPreviewImage("/pdficon.png");
      } else {
        setPreviewImage(window.$IMGURL + previewsrc);
      }
    }
  }, [previewsrc]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <FlexRowCont
          height="100%"
          justifycontent="flex-start"
          alignitems="baseline"
        >
          <FlexColumnCont90x95Percent>
            <SmallHeaderLeagueSpartan>Preview Photo</SmallHeaderLeagueSpartan>
            <FlexRowCont width="40%" height="40%" marginbottom="5%">
              <MediumStyledImage
                objfit="contain"
                mobileheight="20vh"
                mobilewidth="40vw"
                height="100%"
                src={previewImage}
              ></MediumStyledImage>
            </FlexRowCont>
            <FlexRowCont justifycontent="flex-end">
              <LinkText
                onClick={() => {
                  CancelUpload();
                }}
              >
                Cancel
              </LinkText>
              <RegularResponsiveButton width='200px'
                onClick={() => {
                  setPreviewImage(SpinnerIcon);
                  UsePhotoAsOverlay(previewsrc)
                }}
              >
                Use Photo As Overlay
              </RegularResponsiveButton>
            </FlexRowCont>
          </FlexColumnCont90x95Percent>
          <Icon20x20
            onClick={() => {
              CancelUpload();
            }}
            src="/Close.svg"
          ></Icon20x20>
        </FlexRowCont>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

SiteMapOverlayUpload.defaultProps = {
  CloseWindow: () => {},
  CancelUpload: () => {},
  UploadSuccessful: () => {},
  UsePhotoAsOverlay: () => {},
};

export default SiteMapOverlayUpload;
