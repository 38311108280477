import { FlexCont5vhMobile88x5, FlexDiv } from "../../StylesheetComps/Cont";
import { Icon35px100Perc } from "../../StylesheetComps/Imgs/Icons";
import { RegularText } from "../../StylesheetComps/Titles";

const MetroCityCard = ({
  CityNameText,
  onClick,
  CityID,
  EditCity,
  onSetEdit,
}) => {
  return (
    <FlexCont5vhMobile88x5>
      <RegularText>{CityNameText}</RegularText>
      <FlexDiv>
        <Icon35px100Perc
          src={"/EditIcon.svg"}
          onClick={() => {
            EditCity();
            onSetEdit = { CityID };
          }}
        ></Icon35px100Perc>
        <Icon35px100Perc
          src="/Garbage_Icon.svg"
          onClick={onClick}
        ></Icon35px100Perc>
      </FlexDiv>
    </FlexCont5vhMobile88x5>
  );
};

MetroCityCard.defaultProps = {
  CityNameText: "EX. Mississauga",
  CityID: 0,
};

export default MetroCityCard;
