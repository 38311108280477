import React from "react";
import {
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
} from "../../StylesheetComps/Cont";
import { Icon30x30 } from "../../StylesheetComps/Imgs/Icons";
import { CaseCardCont } from "../../StylesheetComps/Projects";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import { RegularText } from "../../StylesheetComps/Titles";

const StoreSupplierCard = ({ SupplierNameText, onDel, onEdit }) => {
  return (
    <CaseCardCont width="80%">
      <FlexRowCont100PercentSpaceBetween>
        <FlexDiv width="200px">
          <Icon30x30 src="/managerface.svg"></Icon30x30>
        </FlexDiv>
        <FlexDiv width="200px">
          <RegularText>{SupplierNameText}</RegularText>
        </FlexDiv>
        <Icon30px20pxPointer
          src={"/pencil.svg"}
          onClick={onEdit}
        ></Icon30px20pxPointer>
        <Icon30px20pxPointer
          src="/Garbage_Icon.svg"
          onClick={onDel}
        ></Icon30px20pxPointer>
      </FlexRowCont100PercentSpaceBetween>
    </CaseCardCont>
  );
};

StoreSupplierCard.defaultProps = {
  SupplierNameText: "EX. Home Depot",
};

export default StoreSupplierCard;
