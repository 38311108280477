import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MobileMenuLinkDiv } from "../../MobileComponents/Divs";
import { MobileMenuLinkText } from "../../MobileComponents/Text";
import { RelativeCont100vwPropBottom } from "../../StylesheetComps/Cont";
import { Icon45x45 } from "../../StylesheetComps/Imgs/Icons";
import { UserRole } from "../../StylesheetComps/UserRoles";

const MobileSlideMenu = ({ open }) => {
  const [idForUser, setIdForUser] = useState(0);
  const [roleNum, setRoleNum] = useState(1000);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const [storeId, setStoreId] = useState(0);
  const [metroId, setMetroId] = useState(0);

  const history = useHistory();

  const GetSessionInfo = async () => {
    setIdForUser(JSON.parse(sessionStorage.getItem("userInfo")).Metro_Id);
    setStoreId(JSON.parse(sessionStorage.getItem("userInfo")).Store_Id);
    setMetroId(JSON.parse(sessionStorage.getItem("userInfo")).Metro_Id)
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
    setUserId(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    setUserName(JSON.parse(sessionStorage.getItem("userInfo")).Name);
  };

  const Logout = async () => {
    const resp = await axios.post(
      window.$DBURL + "users/" + userId + "/session/logout"
    );
    if (resp.status === 200) {
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  if (!open) return null;
  if (roleNum === UserRole.Installer) {
    return (
      <RelativeCont100vwPropBottom contbottom="0vw">
        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/myaccount");
          }}
        >
          <Icon45x45 src={"/ProfileIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>My Account</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/installersetup");
          }}
        >
          <Icon45x45 src={"/manageavailability.svg"}></Icon45x45>
          <MobileMenuLinkText>Business Hours</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/CasesPersonal/" + metroId + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Projects.png"}></Icon45x45>
          <MobileMenuLinkText>My Projects</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        {/* <MobileMenuLinkDiv
          onClick={() => {
            history.push("/storecalendar/" + idForUser + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Store_Calendar_Icon.svg"}></Icon45x45>
          <MobileMenuLinkText>Store Calendar</MobileMenuLinkText>
        </MobileMenuLinkDiv> */}

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/storeworkers");
          }}
        >
          <Icon45x45 src={"/Workers_Icon.svg"}></Icon45x45>
          <MobileMenuLinkText>View Workers</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/");
            Logout();
          }}
        >
          <Icon45x45 src={"/LogOutIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>Log Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>
      </RelativeCont100vwPropBottom>
    );
  } else if (roleNum === UserRole.Worker) {
    return (
      <RelativeCont100vwPropBottom contbottom="0vw">
        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/myaccount");
          }}
        >
          <Icon45x45 src={"/ProfileIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>My Account</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        {/* <MobileMenuLinkDiv
          onClick={() => {
            history.push("/storecalendar/" + idForUser + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Store_Calendar_Icon.svg"}></Icon45x45>
          <MobileMenuLinkText>Store Calendar</MobileMenuLinkText>
        </MobileMenuLinkDiv> */}

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/clockinout");
          }}
        >
          <Icon45x45 src={"/clockinout.svg"}></Icon45x45>
          <MobileMenuLinkText>Clock In/Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push(
              "/clockschedule/" +
                storeId +
                "/" +
                userId +
                "/" +
                roleNum +
                "/" +
                userName
            );
          }}
        >
          <Icon45x45 src={"/clockrecords.svg"}></Icon45x45>
          <MobileMenuLinkText>View Clock Records</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push(
              "/workerschedule/" + storeId + "/" + userId + "/" + userName
            );
          }}
        >
          <Icon45x45 src={"/viewmyschedule.svg"}></Icon45x45>
          <MobileMenuLinkText>View My Schedule</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/");
            Logout();
          }}
        >
          <Icon45x45 src={"/LogOutIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>Log Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>
      </RelativeCont100vwPropBottom>
    );
  } else if (roleNum === UserRole.User || roleNum === UserRole.VIP) {
    return (
      <RelativeCont100vwPropBottom contbottom="0vw">
        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/myaccount");
          }}
        >
          <Icon45x45 src={"/ProfileIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>My Account</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/CasesPersonal/" + metroId + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Projects.png"}></Icon45x45>
          <MobileMenuLinkText>My Projects</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/");
            Logout();
          }}
        >
          <Icon45x45 src={"/LogOutIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>Log Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>
      </RelativeCont100vwPropBottom>
    );
  } else if (roleNum === UserRole.Manager || roleNum === UserRole.Estimator) {
    return (
      <RelativeCont100vwPropBottom contbottom="0vw">
        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/myaccount");
          }}
        >
          <Icon45x45 src={"/ProfileIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>My Account</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/cases/" + metroId + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Store_Projects_Icon.svg"}></Icon45x45>
          <MobileMenuLinkText>Store Projects</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/CasesPersonal/" + metroId + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Projects.png"}></Icon45x45>
          <MobileMenuLinkText>My Projects</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/");
            Logout();
          }}
        >
          <Icon45x45 src={"/LogOutIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>Log Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>
      </RelativeCont100vwPropBottom>
    );
  } else {
    return (
      <RelativeCont100vwPropBottom contbottom="0vw">
        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/myaccount");
          }}
        >
          <Icon45x45 src={"/ProfileIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>My Account</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/CasesPersonal/" + metroId + "/" + storeId);
          }}
        >
          <Icon45x45 src={"/Projects.png"}></Icon45x45>
          <MobileMenuLinkText>My Projects</MobileMenuLinkText>
        </MobileMenuLinkDiv>

        <MobileMenuLinkDiv
          onClick={() => {
            history.push("/");
            Logout();
          }}
        >
          <Icon45x45 src={"/LogOutIcon.svg"}></Icon45x45>
          <MobileMenuLinkText>Log Out</MobileMenuLinkText>
        </MobileMenuLinkDiv>
      </RelativeCont100vwPropBottom>
    );
  }
};

MobileSlideMenu.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  onCloseMenu: () => {},
};

export default MobileSlideMenu;
