import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DeleteTool,
  DeleteToolKit,
  GetToolKit,
} from "../../API_Functions/Tool_Functions";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import ToolCard from "../../comps/ToolCard";
import ToolInfoCard from "../../comps/ToolInfoCard";
import ToolkitCard from "../../comps/ToolkitCard";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import EditToolWindow from "../../comps/Windows/EditTool";
import NewToolWindow from "../../comps/Windows/NewTool";
import SubToolKitAdd from "../../comps/Windows/SubToolKitAdd";
import SubToolKitEdit from "../../comps/Windows/SubToolKitEdit";
import ToolProductRelation from "../../comps/Windows/ToolRelProducts";

import { GetTool } from "../../API_Functions/Tool_Functions";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  Div70PerWidth7PerHeight,
  DivWithDisplay,
  FlexColumnCont80vwAutoHeight,
  FlexCont100vw100vh,
  FlexDiv,
  LeftCont,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv80Percent } from "../../StylesheetComps/Div";
import { Icon30px20pxPointer, Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold, LeagueSpartanRegular, LeagueSpartanRegularMinWidth, StyledHRGreenBreak } from "../../StylesheetComps/Titles";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { InputField } from "../../StylesheetComps/Inputs";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";

const ToolListScreen = () => {
  //=============================================================================
  // Tool List Screen - Manager views and edits Tools & SubKits
  //=============================================================================

  const params = useParams();
  const history = useHistory();
  const [kitTools, setKitTools] = useState([]);
  const [subkits, setSubkits] = useState([]);

  const [subtoolkitObj, setSubToolkit] = useState({});

  const [isNewToolOpen, setIsNewToolOpen] = useState(false);
  const [isEditToolOpen, setIsEditToolOpen] = useState(false);
  const [isViewToolOpen, setIsViewToolOpen] = useState(false);
  const [isNewSubkitOpen, setIsNewSubkitOpen] = useState(false);
  const [isEditSubkitOpen, setIsEditSubkitOpen] = useState(false);

  const [isToolProductRelOpen, setIsToolProductRelOpen] = useState(false);

  const [toolObj, setToolObj] = useState(null);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [ToolID, setToolID] = useState(0);
  const [productNameRel, setProductName] = useState("");

  const [isDeleteToolKitOpen, setIsDeleteToolKitOpen] = useState(false);

  const [ToolKitID, setToolKitID] = useState(0);
  const [previousParentId, setPreviousParentId] = useState(0);
  const [toolkitname, setToolkitname] = useState("");

  const GetRelatedProductsOfTool = async (toolid) => {
    if (toolid > 0) {
      var data = await GetTool(toolid);
      if (data !== null) {
        setRelatedProducts(data.RelatedProducts);
      }
    }
  };

  const GetToolInfo = async (tool_id) => {
    var data = await GetTool(tool_id);
    if (data !== null) {
      setToolObj(data);
    }
  };

  const AsyncGetRelatedProductsOfTool = async (toolid) => {
    if (toolid > 0) {
      var data = await GetTool(toolid);
      if (data !== null) {
        setRelatedProducts(data.RelatedProducts);
      }
    }
  };

  const GetKitTools = async (id) => {
    if (id <= 0) return;
    var data = await GetToolKit(id);
    if (data !== null) {
      setKitTools(data.Tools);
      setSubkits(data.SubKits);
      setPreviousParentId(data.Parent_Id);
      setToolkitname(data.Name);
      if (data.SubKits.length === 0) {
        setSubkits([]);
      }
      if (data.Tools.length === 0) {
        setKitTools([]);
      }
    }
  };

  const GetToolKitInfo = async (id) => {
    if (id <= 0) return;
    var data = await GetToolKit(id);
    if (data == null) return;
    setSubToolkit(data);
    setIsEditSubkitOpen(true);
  };

  var NewRelatedProductId = 0;

  const HandleRelatedProductCallback = async ({ relatedProductId }) => {
    NewRelatedProductId = relatedProductId;
    DeleteRelatedProductFromTool();
  };

  const DeleteRelatedProductFromTool = async () => {
    const resp = await axios.delete(window.$DBURL + "tool/product", {
      headers: { Product_Id: NewRelatedProductId, Tool_Id: ToolID },
      data: { Product_Id: NewRelatedProductId, Tool_Id: ToolID },
    });
    if (resp.status === 200) {
      AsyncGetRelatedProductsOfTool(ToolID);
    }
  };

  const DeleteKitById = async (id) => {
    var data = await DeleteToolKit(id);
    if (data === null) {
      GetKitTools(params.kitId);
    }
  };

  const DeleteToolById = async (toolid) => {
    var data = await DeleteTool(toolid);
    if (data === null) {
      GetKitTools(params.kitId);
    }
  };

  useEffect(() => {
    if (params.kitId !== undefined) {
      GetKitTools(params.kitId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (params.kitId !== undefined) {
      GetKitTools(params.kitId);
    }
  }, [params.kitId]);

  return (
    <FlexDiv>
    <LeftCont>
      <Menu> </Menu>
    </LeftCont>

    <RightCont>
      <br></br>
      <RightViewFullWidth >
        
        <DivWithDisplay flexdirection="row">
          <Icon30px30pxPointer
            src="/backarrow.svg"
            margin="10px"
            onClick={() => {
                          setToolObj(null);
                          if (previousParentId === 0) {
                            history.push("/toolkit/" + params.Id);
                          } else {
                            history.push(
                              "/toollist/" +
                                params.Id +
                                "/" +
                                previousParentId +
                                "/" +
                                toolkitname
                            );
                          }
                        }}
          ></Icon30px30pxPointer>
          <SmallHeaderLeagueSpartan width="100px">
            Tools
          </SmallHeaderLeagueSpartan>
            <DivWithDisplay flexdirection="row">
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryButtonBlue}
                onClick={() => {
                              setIsNewToolOpen(true);
                              setIsViewToolOpen(false);
                            }}
              >
                Add Tool
              </RegularResponsiveButton>

              <RegularResponsiveButton
                bgcolor={ColorPalette.SecondaryGreen}
                onClick={() => {
                              setIsNewSubkitOpen(true);
                              setIsViewToolOpen(false);
                            }}
              >
                Add Subkit
              </RegularResponsiveButton>
          </DivWithDisplay>
        </DivWithDisplay>

              <ToolProductRelation
            open={isToolProductRelOpen}
            GetRelationsAgain={() => {
              AsyncGetRelatedProductsOfTool(ToolID);
            }}
            onRelationId={HandleRelatedProductCallback}
            StoreID={params.Id}
            RelatedProductTools={relatedProducts}
            ProductName={productNameRel}
            ToolID={ToolID}
            onClose={() => {
              setIsToolProductRelOpen(false);
            }}
          ></ToolProductRelation>

          <ToolInfoCard
            Tool={toolObj}
            onEdit={() => {
              setIsViewToolOpen(false);
              setIsEditToolOpen(true);
            }}
            open={isViewToolOpen}
            ToolID={ToolID}
            onClose={() => {
              setIsViewToolOpen(false);
              setToolObj(null);
            }}
            onDelete={() => {
              setIsViewToolOpen(false);
              DeleteToolById(ToolID);
            }}
          ></ToolInfoCard>

          <EditToolWindow
            StoreId={params.Id}
            toolId={ToolID}
            open={isEditToolOpen}
            onClose={() => {
              setIsEditToolOpen(false);
              setToolObj(null);
            }}
            onUpdatedTool={() => {
              GetKitTools(params.kitId);
            }}
          >
            {" "}
          </EditToolWindow>

          <NewToolWindow
            open={isNewToolOpen}
            onClose={() => {
              setIsNewToolOpen(false);
            }}
            onNewTool={() => {
              GetKitTools(params.kitId);
            }}
          ></NewToolWindow>

          <SubToolKitAdd
            open={isNewSubkitOpen}
            onClose={() => {
              setIsNewSubkitOpen(false);
            }}
            onNewSubToolkit={() => {
              GetKitTools(params.kitId);
            }}
          ></SubToolKitAdd>

          <SubToolKitEdit
            open={isEditSubkitOpen}
            onClose={() => {
              setIsEditSubkitOpen(false);
            }}
            subtoolkitobj={subtoolkitObj}
            onUpdatedSubtoolkit={() => {
              GetKitTools(params.kitId);
            }}
          ></SubToolKitEdit>
          <DeleteConfirmWindow
            deleteOpen={isDeleteToolKitOpen}
            closeDel={() => {
              setIsDeleteToolKitOpen(false);
            }}
            onDeleteFunction={() => {
              DeleteKitById(ToolKitID);
            }}
          ></DeleteConfirmWindow>
        <br></br>
        <InputField
        width='80%'
              placeholder="Search for a Tool"
              // onChange={(e) => {
              //   FilterProducts(unfilteredProducts, e.target.value);
              // }}
            ></InputField>
         {/* Add/Edit pop up windows */}
        <DivWithDisplay>
   




          {/* <ProductWindow
            categoryId={params.cateId}
            updateProp={productCallSwitch}
            open={isEditProductOpen}
            productID={ProductID}
            onUpdated={() => {
              RefreshProductOnUpdated();
            }}
            onClose={() => {
              setProductID(0);
              setProductObj(null);
              setProductCallSwitch(0);
              setIsEditProductOpen(false);
              setIsProductOpen(false);
            }}
            UpdateTaxData={() => {
              GetProductTaxInfo(ProductID);
            }}
          ></ProductWindow> */}

          {/* <CategoryAdd
            open={isAddSubCatOpen}
            CatPosProp={catePos}
            parentId={params.cateId}
            onClose={() => {
              setisAddSubCatOpen(false);
            }}
            onCategoryAdd={HandleNewCat}
          ></CategoryAdd> */}
          {/* <CategoryEdit
            open={isEditCatOpen}
            category={categoryObj}
            callCategoryAgain={() => {
              GetStoreProductsFunc();
              GetStoreCategoriesFunc();
            }}
            onClose={() => {
              setisEditCatOpen(false);
            }}
          ></CategoryEdit> */}
        </DivWithDisplay>
        <FlexDiv height='75vh'  margin='10px'
        //  onClick={() => {
        //     setProdListDisplayState("none");
        //   }}
          >
        <DivWithDisplay width='30.5%' flexdirection='column' alignitems="left" display="block">
          <LeagueSpartanRegular>
             Subkits:
          </LeagueSpartanRegular>
          <StyledHRGreenBreak></StyledHRGreenBreak>
          <DivWithDisplay overflowx="hidden">
          {subkits.map((o, i) => (
              <ToolkitCard
                onClick={() => {
                  history.push(
                    window.$DBURL + "toolkit/" + params.Id + "/" + params.kitId
                  );
                  setPreviousParentId(o.Parent_Id);
                  setToolkitname(o.Name);
                }}
                kitId={o.Id}
                key={i}
                KitHeadText={o.Name}
                KitUrl={o.ImageUrl === "" ? "" : window.$IMGURL + o.ImageUrl}
                DelKit={() => {
                  setIsDeleteToolKitOpen(true);
                  setToolKitID(o.Id);
                }}
                EditKit={() => {
                  GetToolKitInfo(o.Id);
                  setToolKitID(o.Id);
                }}
              ></ToolkitCard>
            ))}
          </DivWithDisplay>
          </DivWithDisplay>
          <hr style={{margin: '1em'}}></hr>
          <DivWithDisplay width='48%' flexdirection='column' alignitems="left" display="block">
            <LeagueSpartanRegularMinWidth>
              Tools
            </LeagueSpartanRegularMinWidth>
          <StyledHRGreenBreak></StyledHRGreenBreak>

          <DivWithDisplay height='100%' overflowy='scroll' flexdirection="column"  width="80vw" >
          {kitTools.map((o, i) => (
              <ToolCard
                key={i}
                relToolProd={() => {
                  setToolID(o.Id);
                  setProductName(o.Name);
                  GetRelatedProductsOfTool(o.Id);
                  setIsToolProductRelOpen(true);
                }}
                ToolID={o.Id}
                ToolHeadText={o.Name}
                ToolUrl={o.VideoUrl}
                onView={() => {
                  setToolID(o.Id);
                  GetToolInfo(o.Id);
                  setIsViewToolOpen(true);
                }}
              ></ToolCard>
            ))}
          </DivWithDisplay>
        </DivWithDisplay>
        </FlexDiv>
      </RightViewFullWidth>
    </RightCont>
  </FlexDiv>
    // <FlexCont100vw100vh>
    //   <LeftCont>
    //     <Menu
    //       onReset={() => {
    //         setIsToolProductRelOpen(false);
    //         setIsNewToolOpen(false);
    //         setIsViewToolOpen(false);
    //       }}
    //     >
    //       {" "}
    //     </Menu>
    //   </LeftCont>

    //   <FlexColumnCont80vwAutoHeight>
    //     <ActionMenu />
    //     <RightViewFullWidth>
    //       <Div70PerWidth7PerHeight>
    //         <Icon30px20pxPointer
    //           src="/backarrow.svg"
    //           onClick={() => {
    //             setToolObj(null);
    //             if (previousParentId === 0) {
    //               history.push("/toolkit/" + params.Id);
    //             } else {
    //               history.push(
    //                 "/toollist/" +
    //                   params.Id +
    //                   "/" +
    //                   previousParentId +
    //                   "/" +
    //                   toolkitname
    //               );
    //             }
    //           }}
    //         ></Icon30px20pxPointer>
    //         <LeagueSpartanExtraLargeBold>
    //           {toolkitname}
    //         </LeagueSpartanExtraLargeBold>
    //         <RegularResponsiveButton
    //           onClick={() => {
    //             setIsNewToolOpen(true);
    //             setIsViewToolOpen(false);
    //           }}
    //         >
    //           Add Tool
    //         </RegularResponsiveButton>
    //         <RegularResponsiveButton
    //           onClick={() => {
    //             setIsNewSubkitOpen(true);
    //             setIsViewToolOpen(false);
    //           }}
    //         >
    //           Add Subkit
    //         </RegularResponsiveButton>
    //       </Div70PerWidth7PerHeight>

    //       <ToolProductRelation
    //         open={isToolProductRelOpen}
    //         GetRelationsAgain={() => {
    //           AsyncGetRelatedProductsOfTool(ToolID);
    //         }}
    //         onRelationId={HandleRelatedProductCallback}
    //         StoreID={params.Id}
    //         RelatedProductTools={relatedProducts}
    //         ProductName={productNameRel}
    //         ToolID={ToolID}
    //         onClose={() => {
    //           setIsToolProductRelOpen(false);
    //         }}
    //       ></ToolProductRelation>

    //       <ToolInfoCard
    //         Tool={toolObj}
    //         onEdit={() => {
    //           setIsViewToolOpen(false);
    //           setIsEditToolOpen(true);
    //         }}
    //         open={isViewToolOpen}
    //         ToolID={ToolID}
    //         onClose={() => {
    //           setIsViewToolOpen(false);
    //           setToolObj(null);
    //         }}
    //         onDelete={() => {
    //           setIsViewToolOpen(false);
    //           DeleteToolById(ToolID);
    //         }}
    //       ></ToolInfoCard>

    //       <EditToolWindow
    //         StoreId={params.Id}
    //         toolId={ToolID}
    //         open={isEditToolOpen}
    //         onClose={() => {
    //           setIsEditToolOpen(false);
    //           setToolObj(null);
    //         }}
    //         onUpdatedTool={() => {
    //           GetKitTools(params.kitId);
    //         }}
    //       >
    //         {" "}
    //       </EditToolWindow>

    //       <NewToolWindow
    //         open={isNewToolOpen}
    //         onClose={() => {
    //           setIsNewToolOpen(false);
    //         }}
    //         onNewTool={() => {
    //           GetKitTools(params.kitId);
    //         }}
    //       ></NewToolWindow>

    //       <SubToolKitAdd
    //         open={isNewSubkitOpen}
    //         onClose={() => {
    //           setIsNewSubkitOpen(false);
    //         }}
    //         onNewSubToolkit={() => {
    //           GetKitTools(params.kitId);
    //         }}
    //       ></SubToolKitAdd>

    //       <SubToolKitEdit
    //         open={isEditSubkitOpen}
    //         onClose={() => {
    //           setIsEditSubkitOpen(false);
    //         }}
    //         subtoolkitobj={subtoolkitObj}
    //         onUpdatedSubtoolkit={() => {
    //           GetKitTools(params.kitId);
    //         }}
    //       ></SubToolKitEdit>
    //       <DeleteConfirmWindow
    //         deleteOpen={isDeleteToolKitOpen}
    //         closeDel={() => {
    //           setIsDeleteToolKitOpen(false);
    //         }}
    //         onDeleteFunction={() => {
    //           DeleteKitById(ToolKitID);
    //         }}
    //       ></DeleteConfirmWindow>

    //       <FlexDiv80Percent>
    //         {subkits.map((o, i) => (
    //           <ToolkitCard
    //             onClick={() => {
    //               history.push(
    //                 window.$DBURL + "toolkit/" + params.Id + "/" + params.kitId
    //               );
    //               setPreviousParentId(o.Parent_Id);
    //               setToolkitname(o.Name);
    //             }}
    //             kitId={o.Id}
    //             key={i}
    //             KitHeadText={o.Name}
    //             KitUrl={o.ImageUrl === "" ? "" : window.$IMGURL + o.ImageUrl}
    //             DelKit={() => {
    //               setIsDeleteToolKitOpen(true);
    //               setToolKitID(o.Id);
    //             }}
    //             EditKit={() => {
    //               GetToolKitInfo(o.Id);
    //               setToolKitID(o.Id);
    //             }}
    //           ></ToolkitCard>
    //         ))}
    //         {kitTools.map((o, i) => (
    //           <ToolCard
    //             key={i}
    //             relToolProd={() => {
    //               setToolID(o.Id);
    //               setProductName(o.Name);
    //               GetRelatedProductsOfTool(o.Id);
    //               setIsToolProductRelOpen(true);
    //             }}
    //             ToolID={o.Id}
    //             ToolHeadText={o.Name}
    //             ToolUrl={o.VideoUrl}
    //             onView={() => {
    //               setToolID(o.Id);
    //               GetToolInfo(o.Id);
    //               setIsViewToolOpen(true);
    //             }}
    //           ></ToolCard>
    //         ))}
    //       </FlexDiv80Percent>
    //     </RightViewFullWidth>
    //   </FlexColumnCont80vwAutoHeight>
    // </FlexCont100vw100vh>
  );
};

ToolListScreen.defaultProps = {
  ProfileName: "Default Name",
  MetroName: "EXAMPLE Toronto",
};

export default ToolListScreen;
