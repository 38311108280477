/* eslint-disable */

import { useEffect, useState } from "react";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnCont100xAutoPercent,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  QuoteItemCont,
} from "../../StylesheetComps/Cont";
import {
  FlexibleEstimateDiv15Percent,
  FlexibleEstimateDiv35Percent,
  FlexibleEstimateDiv5Percent,
  GreyFlexibleEstimateDiv15Percent,
} from "../../StylesheetComps/Div";
import {
  Icon20x20,
  Icon30x40pxPointerNoMargin,
} from "../../StylesheetComps/Imgs/Icons";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import {
  GreyTinyTextItalics,
  SmallGreenLeagueText,
  TinyHeaderDarkGrey,
} from "../../StylesheetComps/Titles";
import QuoteItemEstimator from "../QuoteItemEstimator";
const QuoteItem = ({
  onClick,
  header1,
  header2,
  subheader2notes,
  subheader2,
  header3,
  header4,
  header5,
  header6,
  header7,
  header8,
  quoteItemObj,
  inputheaderposition,
  noChange,
  OutputValue,
  RemoveItemFromLocalQuoteObject,
  parentid,
  itemLink,
  bgColor,
  isHeader,
  showRelatedItems,
  constructionView,
  hideBidQuoteHeaders,
  onToggleDisplay,
  CategoryType,
  RemovalItems,
}) => {
  const [header1Display, setHeader1Display] = useState("none");
  const [header2Display, setHeader2Display] = useState("flex");
  const [subheader2Display] = useState("flex");
  const [header3Display, setHeader3Display] = useState("flex");
  const [header4Display, setHeader4Display] = useState("flex");
  const [header5Display, setHeader5Display] = useState("flex");
  const [header6Display, setHeader6Display] = useState("flex");

  const [header1InputDisplay, setHeader1InputDisplay] = useState("none");
  const [header2InputDisplay, setHeader2InputDisplay] = useState("none");
  const [header3InputDisplay, setHeader3InputDisplay] = useState("none");
  // const [header4InputDisplay, setHeader4InputDisplay] = useState("none")
  const [header5InputDisplay, setHeader5InputDisplay] = useState("none");
  const [header6InputDisplay, setHeader6InputDisplay] = useState("none");

  const [header1DefaultValue, setHeader1DefaultValue] = useState(header1);
  const [header2DefaultValue, setHeader2DefaultValue] = useState(header2);
  const [header3DefaultValue, setHeader3DefaultValue] = useState(header3);
  const [header4DefaultValue, setHeader4DefaultValue] = useState(header4);
  const [header5DefaultValue, setHeader5DefaultValue] = useState(header5);
  const [header6DefaultValue, setHeader6DefaultValue] = useState(header6);

  const [linkHeaderDisplay, setLinkHeaderDisplay] = useState("none");
  const [pointerEvent, setPointerEvent] = useState("none");
  const [youtubeLinkProp, setYoutubeLinkProp] = useState("");

  const [descriptionOverflow, setDescriptionOverflow] = useState("hidden");
  const [descriptionWhiteSpace, setDescriptionWhiteSpace] = useState("nowrap");

  const [deleteIconSrc, setDeleteIconSrc] = useState("");

  const [relatedItemToggleSRC, setRelatedItemToggleSRC] =
    useState("/downarrow.svg");

  const [FontSize, setFontSize] = useState("14px");
  const [FontWeight, setFontWeight] = useState("regular");
  const [relatedItemsDisplay, setRelatedItemsDisplay] = useState("none");

  const DetermineInputHeaderLogic = async () => {
    if (parentid === 0) {
      if (noChange === false) {
        setDeleteIconSrc("/close.svg");
        InitializeSetup();
        switch (inputheaderposition) {
          case 1:
            setHeader1Display("none");
            setHeader1DefaultValue(header1);
            setHeader1InputDisplay("flex");
            break;
          case 2:
            setHeader2Display("none");
            setHeader2DefaultValue(header2);
            setHeader2InputDisplay("flex");
            break;
          case 3:
            setHeader3Display("none");
            setHeader3DefaultValue(quoteItemObj?.Quant?.toFixed(2));
            setHeader3InputDisplay("flex");
            break;
          case 4:
            setHeader4Display("none");
            setHeader4DefaultValue(header4);
            // setHeader4InputDisplay("flex")

            break;
          case 5:
            setHeader5Display("none");
            setHeader5DefaultValue(header5);
            setHeader5InputDisplay("flex");
            break;
          case 6:
            setHeader6Display("none");
            setHeader6DefaultValue(header6);
            setHeader6InputDisplay("flex");
            break;
          default:
            break;
        }
      }
    }
  };

  const InitializeSetup = async () => {
    setHeader1Display("none");
    setHeader2Display("flex");
    setHeader3Display("flex");
    setHeader4Display("flex");
    setHeader5Display("flex");
    setHeader6Display("flex");
    setDeleteIconSrc("");

    setHeader1DefaultValue(header1);
    setHeader2DefaultValue(header2);
    setHeader3DefaultValue(header3);
    setHeader4DefaultValue(header4);
    setHeader5DefaultValue(header5);
    setHeader6DefaultValue(header6);

    setHeader1InputDisplay("none");
    setHeader2InputDisplay("none");
    setHeader3InputDisplay("none");
    // setHeader4InputDisplay("none")
    setHeader5InputDisplay("none");
    setHeader6InputDisplay("none");

    if (isHeader == true) {
      setFontSize("16px");
      setFontWeight("bold");
    }
  };

  useEffect(() => {
    DetermineInputHeaderLogic(inputheaderposition);
  }, [inputheaderposition]); // eslint-disable-line react-hooks/exhaustive-deps

  const CheckIfValidLink = async (link) => {
    if (link !== null && link !== undefined && link !== "") {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (link.match(p)) {
        setYoutubeLinkProp(link);
        setLinkHeaderDisplay("underline");
        setPointerEvent("auto");
      } else {
        setLinkHeaderDisplay("none");
      }
    }
  };

  useEffect(() => {
    if (showRelatedItems === true) {
      setRelatedItemsDisplay("flex");
      setRelatedItemToggleSRC("/uparrow.svg");
      CheckIfValidLink(itemLink);
    } else {
      setRelatedItemsDisplay("none");
      setRelatedItemToggleSRC("/downarrow.svg");
    }
  }, [showRelatedItems]);

  const itemIconSRC = isHeader
    ? null // Don't display anything when isHeader is true
    : CategoryType === CategoryTypes.Material
    ? "/Material_EstimateIcon.svg"
    : CategoryType === "Delivery"
    ? "/Delivery_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Removal
    ? "/Removal_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Access
    ? "/AccessArea_EstimateIcon.svg"
    : CategoryType === "Tool"
    ? "/Tools_EstimateIcon.svg"
    : CategoryType === CategoryTypes.Labor
    ? "/Labour_EstimateIcon.svg"
    : ""; // Set default to an empty string

  return (
    <FlexColumnCont100xAutoPercent margin="0" onClick={onClick}>
      <QuoteItemCont
        height="100%"
        alignitems="stretch"
        borderbottom={
          relatedItemsDisplay === "block"
            ? `1px dashed ${ColorPalette.PrimaryButtonBlue}`
            : `1px solid ${ColorPalette.PrimaryGrey}`
        }
      >
        {/* Column 1 */}

        <FlexibleEstimateDiv5Percent
          mobiledisplay="none"
          fontsize={isHeader === false ? FontSize : "24px"}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <Icon20x20 src={itemIconSRC}></Icon20x20>
        </FlexibleEstimateDiv5Percent>

        <FlexibleEstimateDiv5Percent
          display={header1InputDisplay}
          mobiledisplay="none"
        >
          <TextInputProp
            type="number"
            mobiledisplay="none"
            defaultValue={header1DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv5Percent>

        {/* Column 2 */}

        <FlexibleEstimateDiv35Percent
          height={descriptionOverflow === "hidden" ? "110px" : "auto"}
          display={header2Display}
          bgcolor={bgColor}
          fontsize={isHeader === false ? FontSize : "18px"}
          fontweight={isHeader === true ? "bold" : ""}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnCont
            mobilewidth="100%"
            width="auto"
            height="auto"
            margin="0%"
            justifycontent="center"
            alignitems="left"
          >
            {/* <SmallGreenLeagueText
              display={bgColor === ColorPalette.LightGreen ? "block" : "none"}
            >
              {"[REQUIRED PRODUCTS:]"}
            </SmallGreenLeagueText> */}
            {youtubeLinkProp !== "" ? (
              <a
                rel="noreferrer"
                href={youtubeLinkProp}
                target="_blank"
                style={{
                  textDecoration: linkHeaderDisplay,
                  pointerEvents: pointerEvent,
                }}
              >
                {header2}
              </a>
            ) : (
              header2
            )}

            <TinyHeaderDarkGrey
              width="15vw"
              margin="4px"
              display={isHeader !== true ? subheader2Display : "none"}
              mobiledisplay="block"
              overflow={"visible"}
              whitespace={"normal"}
              // overflow={showRelatedItems === true ? "visible" : "hidden"}
              // whitespace={showRelatedItems === true ? "normal" : "nowrap"}
            >
              {subheader2notes}
            </TinyHeaderDarkGrey>

            {/* <TinyHeaderDarkGrey
              width="15vw"
              display={isHeader !== true ? "" : "none"}
              margin="4px"
              overflow={showRelatedItems === true ? "visible" : "hidden"}
              whitespace={showRelatedItems === true ? "normal" : "nowrap"}
            >
              {subheader2}
            </TinyHeaderDarkGrey> */}
          </FlexColumnCont>
        </FlexibleEstimateDiv35Percent>

        <FlexibleEstimateDiv35Percent
          display={isHeader !== true ? header2InputDisplay : "none"}
          mobiledisplay="none"
        >
          <TextInputProp
            type="text"
            mobiledisplay="none"
            defaultValue={header2DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv35Percent>

        {/* Column 3 */}

        <FlexibleEstimateDiv15Percent
          display={header3Display}
          mobiledisplay={header3Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header3 === "" ||
                header3 === null ||
                header3 === undefined
                  ? "none"
                  : "block"
              }
            >
              Quantity
            </GreyTinyTextItalics>
            {header3}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          mobiledisplay={header3InputDisplay}
          display={header3InputDisplay}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            inputwidth="60px"
            type="number"
            id="quantvalue"
            key={header3DefaultValue}
            onBlur={(e) => {
              OutputValue(e.target.valueAsNumber);
            }}
            defaultValue={header3DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 4 */}

        <FlexibleEstimateDiv15Percent
          display={hideBidQuoteHeaders === true ? "none" : header4Display}
          mobiledisplay={header4Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header4 === "" ||
                header4 === null ||
                header4 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === "Tool"
                ? "Rental Rate"
                : CategoryType === CategoryTypes.Removal
                ? "Removal Rate"
                : "Material Rate"}
            </GreyTinyTextItalics>
            {header4}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display="none"
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            width="50px"
            type="number"
            defaultValue={header4DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 5 */}

        <FlexibleEstimateDiv15Percent
          display={hideBidQuoteHeaders === true ? "none" : header5Display}
          mobiledisplay="none"
          bgcolor={
            bgColor === ""
              ? ColorPalette.White
              : bgColor !== ColorPalette.LightGreen
              ? bgColor
              : ColorPalette.GreyGreen
          }
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header5 === "" ||
                header5 === null ||
                header5 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === "Tool"
                ? "Rental Length"
                : CategoryType === "Delivery"
                ? "Delivery Rate"
                : CategoryType === CategoryTypes.Access
                ? "Access Cost"
                : "Material Subtotal"}
            </GreyTinyTextItalics>

            {header5}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display={header5InputDisplay}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            type="text"
            mobiledisplay="none"
            defaultValue={header5DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 6 */}

        {/* <FlexibleEstimateDiv15Percent
          display={header6Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {header6}
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display={header6InputDisplay}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            type="number"
            mobiledisplay="none"
            defaultValue={header6DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent> */}

        {/* Column 7 */}
        <FlexibleEstimateDiv15Percent
          borderright={
            isHeader === false ? `1px solid ${ColorPalette.DarkGrey}` : ""
          }
          display={header6Display}
          bgcolor={
            bgColor === ""
              ? ColorPalette.White
              : bgColor !== ColorPalette.LightGreen
              ? bgColor
              : ColorPalette.GreyGreen
          }
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {" "}
          <FlexColumnContCenterAlign>
            <GreyTinyTextItalics
              display={
                isHeader === true ||
                header7 === "" ||
                header7 === null ||
                header7 === undefined
                  ? "none"
                  : "block"
              }
            >
              {CategoryType === CategoryTypes.Removal
                ? "Removal Rate"
                : CategoryType === "Tool"
                ? "Move Cost Per Machine"
                : CategoryType === "Delivery"
                ? "Delivery Rate"
                : CategoryType === CategoryTypes.Access
                ? "Access Cost"
                : "Install Subtotal"}
            </GreyTinyTextItalics>

            {header7}
          </FlexColumnContCenterAlign>
        </FlexibleEstimateDiv15Percent>

        {/* Column 8 */}
        <FlexibleEstimateDiv15Percent
          display={hideBidQuoteHeaders === true ? "none" : header6Display}
          bgcolor={bgColor}
          fontsize={isHeader === false ? FontSize : "18px"}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <b>{header8}</b>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv5Percent
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <Icon30x40pxPointerNoMargin
            display={
              hideBidQuoteHeaders === true
                ? "none"
                : (quoteItemObj?.RelatedItems?.filter(function (o) {
                    return o.CateType !== CategoryTypes.Labor;
                  }).length > 0 &&
                    quoteItemObj.Parent_Id === 0) ||
                  (RemovalItems && RemovalItems.length > 0)
                ? "block"
                : "none"
            }
            src={relatedItemToggleSRC}
            onClick={() => {
              onToggleDisplay();
            }}
          ></Icon30x40pxPointerNoMargin>
        </FlexibleEstimateDiv5Percent>
      </QuoteItemCont>

      {RemovalItems.map((o, i) => {
        return (
          <FlexColumnFullWidth
            margin="0"
            key={i}
            display={showRelatedItems ? "block" : "none"}
          >
            <QuoteItem
              header1="Delete"
              quoteItemObj={o}
              CategoryType={CategoryTypes.Removal}
              header2={o.Name}
              header3={
                typeof o?.Quant === "number" && !isNaN(o.Quant)
                  ? o.Quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    " " +
                    o.DisplayUnit
                  : "0.00 " + o.DisplayUnit
              }
              header5=""
              header4={
                "$" + ((o.Price + o.LaborSubtotal) / o?.Quant).toFixed(4)
              }
              // header4={
              //   "$" +
              //   (o.Price + o.LaborSubtotal)
              //     .toFixed(2)
              //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              // }
              header9={o.removalTime}
              header8={
                "$" +
                (o.Price + o.LaborSubtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            ></QuoteItem>
          </FlexColumnFullWidth>
        );
      })}

      {quoteItemObj?.RelatedItems?.map((o, i) => {
        if (o.CateType !== CategoryTypes.Labor && constructionView === false) {
          return (
            <FlexColumnFullWidth
              margin="0"
              key={i}
              display={showRelatedItems ? "block" : "none"}
            >
              <QuoteItem
                hideBidQuoteHeaders={hideBidQuoteHeaders}
                showRelatedItems={showRelatedItems}
                quoteItemObj={o}
                key={i}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={o.Name}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={o.Quant.toFixed(2) + " " + o.DisplayUnit}
                header4={
                  "$" +
                  o.UnitPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header5={
                  "$" + o.Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header6={
                  "$" +
                  (o.LaborSubtotal / o.Quant)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                header7={
                  "$" +
                  o.LaborSubtotal.toFixed(2).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                }
                header8={
                  "$" +
                  (
                    parseFloat(o.Price.toFixed(2)) +
                    parseFloat(o.LaborSubtotal.toFixed(2))
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                bgColor={ColorPalette.LightGreen}
              ></QuoteItem>
            </FlexColumnFullWidth>
          );
        } else if (
          o.CateType !== CategoryTypes.Labor &&
          constructionView === true
        ) {
          return (
            <FlexColumnFullWidth
              margin="0"
              key={i}
              display={showRelatedItems ? "block" : "none"}
            >
              <QuoteItem
                hideBidQuoteHeaders={hideBidQuoteHeaders}
                showRelatedItems={showRelatedItems}
                quoteItemObj={o}
                key={i}
                parentid={o.Parent_Id}
                noChange={o.NoChange}
                header2={o.Name}
                subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                subheader2={o.Description == null ? "" : o.Description}
                header3={o.Quant.toFixed(2) + " " + o.DisplayUnit}
                header7={""}
                header8={"$" + o.LaborSubtotal.toFixed(2)}
                bgColor={ColorPalette.LightGreen}
              ></QuoteItem>
            </FlexColumnFullWidth>
          );
        } else {
          return null;
        }
      })}
    </FlexColumnCont100xAutoPercent>
  );
};

QuoteItem.defaultProps = {
  header1: "",
  header2: "",
  subheader2: "",
  header3: "",
  header4: "",
  header4input: "",
  header5: "",
  header6: "",
  header7: "",
  header8: "",
  inputheaderposition: 0,
  OutputValue: () => {},
  RemoveItemFromLocalQuoteObject: () => {},
  onToggleDisplay: () => {},
  bgColor: "",
  RemovalItems: [],
  isHeader: false,
  showRelatedItems: false,
  hideBidQuoteHeaders: false,
  constructionView: false,
  CategoryType: CategoryTypes.Material,
};

export default QuoteItem;
