import axios from "axios"; // used for making HTTP requests
import React, { useEffect, useState } from "react"; // imports the React library and hooks for state and lifecycle methods
import "react-big-calendar/lib/css/react-big-calendar.css"; // imports a stylesheet for the react-big-calendar component
import "react-datepicker/dist/react-datepicker.css"; // imports a stylesheet for the react-datepicker component
import { useParams } from "react-router-dom"; // imports the hook for accessing route parameters
import styled from "styled-components"; // imports a library for creating styled components
import Input from "../../comps/Input"; // imports the Input component from the specified location
import { ColorPalette } from "../../StylesheetComps/Colors"; // imports the ColorPalette object from the specified location
import { RegularResponsiveButton } from "../../StylesheetComps/Components"; // imports the RegularResponsiveButton component from the specified location
import { FlexDiv, LeftItemWindowFixed } from "../../StylesheetComps/Cont"; // imports the FlexDiv and LeftItemWindowFixed components from the specified location
import { TextAreaLarge } from "../../StylesheetComps/LandscapeForm"; // imports the TextAreaLarge component from the specified location
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont"; // imports the FixedOverlay200vh component from the specified location
import {
  RegularTextBoldRed,
  TinyTextItalics,
} from "../../StylesheetComps/Titles"; // imports the RegularTextBoldRed and TinyTextItalics components from the specified location

const ItemCont = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 30%;
  width: 500px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  @media (max-width: 1006px) {
    left: 0%;
    width: 98.5vw;
    height: 90vh;
    overflow-y: scroll;
    margin: 0px;
  }
`;
const Header = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: black;
  font-family: Futura;
  margin-left: 12px;
  padding-top: 10px;
  @media (max-width: 1006px) {
    padding-top: 0px;
    font-size: 18px;
  }
`;

const AppointmentSecDiv = styled.div`
  display: flex;
  width: 90%;
`;

const SubHeaderPara = styled.p`
  font-family: Futura;
  font-size: 16px;
  width: 150px;
`;

const ButtonDiv = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
`;

const AppointmentContDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 10%;
  margin-bottom: 5%;
`;

const RadioButtonInnerDiv = styled.div`
  margin-right: 5%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const CreateAppointment = ({
  open,
  onClick,
  onClose,
  onPreview,
  defaultSlotId,
  onAppointmentCreate,
  month,
  day,
  year,
  name,
  defaultaddress,
  defaultcity,
  cellphone,
}) => {
  const params = useParams();
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setEmail] = useState("");
  const [flexible, setFlexible] = useState(false);

  const [slotId, setSlotId] = useState(0);
  const [appDesc, setAppDesc] = useState("");

  const [startHour, setStartHour] = useState("");
  const [endHour, setEndHour] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [endMinute, setEndMinute] = useState("");

  const [errorText, setErrorText] = useState("");
  const [errorTextDisplay, setErrorTextDisplay] = useState("none");

  const GetInstallerTimeSlots = async (Id) => {
    const resp = await axios.get(window.$DBURL + "appointment/slot/" + Id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.slot !== null) {
          if (
            resp.data.slot.Start.Minute === 0 &&
            resp.data.slot.End.Minute === 0
          ) {
            setStartHour(resp.data.slot.Start.Hour);
            setStartMinute(resp.data.slot.Start.Minute + "0");
            setEndHour(resp.data.slot.End.Hour);
            setEndMinute(resp.data.slot.End.Minute + "0");
          }
          if (resp.data.slot.End.Minute === 0) {
            setEndHour(resp.data.slot.End.Hour);
            setEndMinute(resp.data.slot.End.Minute + "0");
          }
          if (resp.data.slot.Start.Minute === 0) {
            setStartHour(resp.data.slot.Start.Hour);
            setStartMinute(resp.data.slot.Start.Minute + "0");
          }
          if (
            resp.data.slot.Start.Minute < 10 &&
            resp.data.slot.Start.Minute !== 0
          ) {
            setStartHour(resp.data.slot.Start.Hour);
            setStartMinute("0" + resp.data.slot.Start.Minute);
          }
          if (
            resp.data.slot.End.Minute < 10 &&
            resp.data.slot.End.Minute !== 0
          ) {
            setEndHour(resp.data.slot.End.Hour);
            setEndMinute("0" + resp.data.slot.End.Minute);
          }
          if (resp.data.slot.End.Minute > 10) {
            setEndHour(resp.data.slot.End.Hour);
            setEndMinute(resp.data.slot.End.Minute);
          }
          if (resp.data.slot.Start.Minute > 10) {
            setStartHour(resp.data.slot.Start.Hour);
            setStartMinute(resp.data.slot.Start.Minute);
          }
          setSlotId(Id);
        }
      }
    }
  };

  useEffect(() => {
    GetInstallerTimeSlots(params.eventId);
  }, [day, month, year, open, params.eventId]);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <ItemCont>
        <LeftItemWindowFixed>
          <div>
            <Header>
              Appointment for {month}/{day}/{year}
            </Header>
            <AppointmentSecDiv>
              <AppointmentContDiv>
                <SubHeaderPara>Appointment Time</SubHeaderPara>
                <SubHeaderPara>
                  {startHour}:{startMinute} - {endHour}:{endMinute}
                </SubHeaderPara>
              </AppointmentContDiv>
              <AppointmentContDiv>
                <SubHeaderPara>Flexible? </SubHeaderPara>
                <FlexDiv>
                  <RadioButtonInnerDiv>
                    <input
                      type="radio"
                      id="yes"
                      name="flexible"
                      onChange={() => {
                        setFlexible(true);
                      }}
                    />
                    <label htmlFor="yes">Yes</label>
                    <br />
                    <input
                      type="radio"
                      id="no"
                      name="flexible"
                      onChange={() => {
                        setFlexible(false);
                      }}
                    />
                    <label htmlFor="no">No</label>
                    <br />
                  </RadioButtonInnerDiv>
                </FlexDiv>
              </AppointmentContDiv>
            </AppointmentSecDiv>
          </div>
          <TinyTextItalics>
            (If flexible is selected, your appointment will be moved forward if
            an earlier date / time become available)
          </TinyTextItalics>
          <FlexDiv>
            <SubHeaderPara>Notes for Meeting</SubHeaderPara>
            <TextAreaLarge
              maxLength="250"
              placeholder="Anything you want to tell us?"
              onChange={(e) => {
                setAppDesc(e.target.value);
              }}
            ></TextAreaLarge>
          </FlexDiv>
          <FlexDiv>
            <SubHeaderPara>Contact Name</SubHeaderPara>
            <Input
              defaultValue={name}
              placeholder="Contact Name"
              inputwidth="60%"
              inputheight="10px"
              onChange={(e) => {
                setContactName(e.target.value);
              }}
            ></Input>
          </FlexDiv>
          <FlexDiv>
            <SubHeaderPara>Address</SubHeaderPara>
            <Input
              defaultValue={defaultaddress}
              placeholder="Address"
              inputwidth="60%"
              inputheight="10px"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            ></Input>
          </FlexDiv>
          <FlexDiv>
            <SubHeaderPara>City</SubHeaderPara>
            <Input
              defaultValue={defaultcity}
              placeholder="City"
              inputwidth="60%"
              inputheight="10px"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></Input>
          </FlexDiv>
          <FlexDiv>
            <SubHeaderPara>Cell Phone</SubHeaderPara>
            <Input
              defaultValue={cellphone}
              placeholder="Cell Phone"
              inputwidth="60%"
              inputheight="10px"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            ></Input>
          </FlexDiv>
          <RegularTextBoldRed display={errorTextDisplay}>
            {errorText}
          </RegularTextBoldRed>

          <ButtonDiv>
            <RegularResponsiveButton
              onClick={() => {
                if (slotId === 0) {
                  setErrorText(
                    "No Appointment Slot Selected, please return to Calendar and select a date and appointment slot"
                  );
                  setErrorTextDisplay("block");
                } else {
                  setErrorText("");
                  setErrorTextDisplay("none");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                  });
                  onAppointmentCreate(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc
                  );
                  onClose();
                }
              }}
            >
              Submit
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </RegularResponsiveButton>
          </ButtonDiv>
        </LeftItemWindowFixed>
      </ItemCont>
    </FixedOverlay200vh>
  );
};

CreateAppointment.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onAppointmentCreate: () => {},
  month: 0,
  year: 0,
  day: 0,
  name: "",
  defaultaddress: "",
  defaultcity: "",
  cellphone: "",
  defaultSlotId: 0,
};

export default CreateAppointment;
