import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";

import { useParams } from "react-router-dom";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  Div70PerWidth7PerHeight,
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const StoreScheduleDayScreen = ({ onPreview, onProfileUpdated, storeId }) => {
  //=============================================================================
  // Store Schedule Day Screen - View all workers schedule on Selected Date
  //=============================================================================

  const history = useHistory();

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const params = useParams();

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [installerList, setInstallerList] = useState([]);

  const GetSchedulesForDay = async () => {
    const resp = await axios.post(window.$DBURL + "schedule/store", {
      Id: params.storeId,
      Year: params.storeYear,
      Month: params.storeMonth,
      Day: params.storeDay,
    });
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.schedules !== null) {
          if (resp.data.schedules.length > 0) {
            setInstallerList(resp.data.schedules);
            setStartHour(resp.data.schedules[0].Start.Hour);
            setStartMinute(resp.data.schedules[0].Start.Minute);
            let maxObj = resp.data.schedules.reduce((max, obj) =>
              max.End.Hour > obj.End.Hour ? max : obj
            );
            let maxMinute = resp.data.schedules.reduce((max, obj) =>
              max.End.Hour > obj.End.Hour ? max : obj
            );
            setEndMinute(maxMinute.End.Minute);
            setEndHour(maxObj.End.Hour);
          }
        }
      }
    }
  };

  const getCustomToolbar = (toolbar) => {
    return null;
  };

  useEffect(() => {
    GetSchedulesForDay();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileColumn>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <Div70PerWidth7PerHeight>
            <HundredPercentFlexDiv>
              <Icon30px20pxPointer
                src="/backarrow.svg"
                onClick={() => {
                  history.push("/storeschedule");
                }}
              ></Icon30px20pxPointer>
              <LeagueSpartanExtraLargeBold>
                {"Workers Scheduled on " +
                  params.storeMonth +
                  "/" +
                  params.storeDay +
                  "/" +
                  params.storeYear}
              </LeagueSpartanExtraLargeBold>
            </HundredPercentFlexDiv>
          </Div70PerWidth7PerHeight>
          <Calendar
            localizer={localizer}
            defaultDate={
              new Date(params.storeYear, params.storeMonth - 1, params.storeDay)
            }
            events={installerList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Crewname,
                start: new Date(
                  params.storeYear,
                  params.storeMonth - 1,
                  params.storeDay,
                  o.Start.Hour,
                  o.Start.Minute
                ),
                end: new Date(
                  params.storeYear,
                  params.storeMonth - 1,
                  params.storeDay,
                  o.End.Hour,
                  o.End.Minute
                ),
                allDay: false,
                installerUserId: o.Crew_Id,
                InstallerName: o.Crewname,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "75%", width: "100%" }}
            dayLayoutAlgorithm="no-overlap"
            min={new Date(2021, 1, 1, startHour, startMinute, 0)}
            max={new Date(2021, 12, 31, endHour, endMinute, 0)}
            views={{ month: true, week: true, day: true }}
            defaultView={Views.DAY}
            components={{
              toolbar: getCustomToolbar,
            }}
            onNavigate={() => {
              GetSchedulesForDay();
            }}
            onSelectEvent={(event) => {
              history.push("/storeschedule");
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

StoreScheduleDayScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
  storeId: 0,
};

export default StoreScheduleDayScreen;
