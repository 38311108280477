import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DeleteCasePhoto,
  GetCaseById,
} from "../../API_Functions/CaseQuote_Functions";
import {
  AddImageToCloud,
  UploadFileToCloud,
} from "../../API_Functions/Utility_Functions";
import CasePhotoUploadPreviewWindow from "../../comps/Windows/PhotoUpload";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnCont75x80Percent,
  FlexColumnFullWidth,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont70PercentFlexStart,
} from "../../StylesheetComps/Cont";
import {
  FlexDivFlexEnd,
  GridDivMobileFlexDiv,
} from "../../StylesheetComps/Div";
import { Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import {
  Image40x40px,
  RoundedImageFullWidth,
} from "../../StylesheetComps/Imgs/Images";
import {
  Image300x200,
  LandscapePara,
  SmallHeaderLeagueSpartan,
  TextInputRegular,
} from "../../StylesheetComps/LandscapeForm";
import {
  LeagueSpartanRegularMinWidth,
  RegularTextBoldRed,
  SmallTextPadding,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";

const UpdateCase = () => {
  //=============================================================================
  // UpdateCase MicroPage - User can update their own case as long as it has not
  // yet been accepted by a guide
  //=============================================================================

  const history = useHistory();

  const [desc, setDesc] = useState("");
  const [defaultName, setDefaultName] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const [defaultPhone, setDefaultPhone] = useState("");
  const [defaultTitle, setDefaultTitle] = useState("");

  const [previewImage, setPreviewImage] = useState("");
  const [previewImageWindowDisplay, setPreviewImageWindowDisplay] =
    useState("");
  const [errorText, setErrorText] = useState("");

  const [imgArray, setImgArray] = useState([]);
  const [caseObj, setCaseObj] = useState({});

  useEffect(() => {
    GetCase(params.caseId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Define a function that takes an ID as an argument
  const GetCase = async (id) => {
    try {
      // Get the case data by calling the GetCaseById function
      const data = await GetCaseById(id);

      // Check if the data and Case properties exist
      if (data && data.Case) {
        // Set the case object using the data from the Case property
        setCaseObj(data.Case);
        setDesc(data.Case.Description);
        setDefaultName(data.Case.Name);
        setDefaultEmail(data.Case.Email);
        setDefaultAddress(data.Case.Street);
        setDefaultPhone(data.Case.Phone);
        setDefaultTitle(data.Case.Tittle);
        setImgArray(data.Case.Photos);
      }
    } catch (error) {
      // Handle the error and log it to the console
      console.error(error);
    }
  };

  const HandleDelete = async (picobj, index) => {
    try {
      // Delete the case photo using the provided Id
      await DeleteCasePhoto(picobj.Id);

      // Delete the image from the server using Axios
      const response = await axios.delete(`${window.$DBURL}utilities/image`, {
        data: { Url: picobj.File },
      });

      // If the image was successfully deleted, remove it from the array
      if (response.status === 200) {
        imgArray.splice(index, 1);
        setImgArray([...imgArray]);
      }
    } catch (error) {
      // Handle any errors that may have occurred
      console.error(error);
    }
  };

  const UpdateProject = async () => {
    const resp = await axios.put(window.$DBURL + "case", {
      Id: params.caseId,
      UpdateTime:
        fullyear +
        "-" +
        month +
        "-" +
        day +
        "T" +
        hour +
        ":" +
        time +
        ":" +
        seconds,
      Description: desc,
      Name: defaultName,
      Phone: defaultPhone,
      Street: defaultAddress,
      Email: defaultEmail,
      Tittle: defaultTitle,
    });
    if (resp !== null) {
    }
    history.push(
      "/viewcaseuser/" + params.Id + "/" + params.storeId + "/" + params.caseId
    );
  };

  const params = useParams();

  var d = new Date();
  var fullyear = d.getFullYear();
  var month = ("0" + (d.getMonth() + 1)).slice(-2);
  var day = ("0" + d.getDate()).slice(-2);
  var hour = ("0" + d.getHours()).slice(-2);
  var time = ("0" + d.getMinutes()).slice(-2);
  var seconds = ("0" + d.getSeconds()).slice(-2);

  const inputFile = useRef(null);

  const onButtonClick6 = () => {
    inputFile.current.click();
  };

  let formdata = new FormData();
  const [photo] = useState("");

  const GetImgUrl_PushToLocal = async () => {
    if (photo !== "") {
      // Add the new case photo to the form data
      formdata.append("New Case Photo", photo);

      // Get the image URL from the cloud
      const imgUrl = await AddImageToCloud(formdata);

      // If the URL was returned successfully, add it to the array and update the array
      if (imgUrl !== null) {
        imgArray.push(imgUrl);
        pullArrayAgain();
      }
    }
  };

  useEffect(() => {
    GetImgUrl_PushToLocal();
  }, [photo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpload = async (event) => {
    var file = event.target.files[0];
    if (
      file.name.includes(".jpg") ||
      file.name.includes(".JPG") ||
      file.name.includes(".jpeg") ||
      file.name.includes(".JPEG") ||
      file.name.includes(".png") ||
      file.name.includes(".PNG") ||
      file.name.includes(".heic") ||
      file.name.includes(".HEIC") ||
      file.name.includes(".heif") ||
      file.name.includes(".HEIF") ||
      file.name.includes(".webp") ||
      file.name.includes(".WEBP") ||
      file.name.includes(".jfif") ||
      file.name.includes(".JFIF") ||
      file.name.includes(".pjpeg") ||
      file.name.includes(".PJPEG") ||
      file.name.includes(".pjp") ||
      file.name.includes(".PJP") ||
      file.name.includes(".pdf") ||
      file.name.includes(".PDF")
    ) {
      var pic = await UploadFileToCloud(file);
      if (pic !== null) {
        setPreviewImage(pic);
        setPreviewImageWindowDisplay(true);
      }
    } else {
      setErrorText(
        "File not supported, we are also not currently accepting videos."
      );
      window.scrollTo(0, 0);
    }
  };

  const ResetImage = async (img) => {
    var resp = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (resp.status === 200) {
    }
  };

  const pullArrayAgain = () => {
    setImgArray([...imgArray]);
  };

  return (
    <div>
      <FlexColumnCont75x80Percent width="80vw" height="auto" bgcolor="white">
        <FlexRowCont100PercentSpaceBetween>
          <FlexColumnFullWidth width="40vw" mobiledisplay="none">
            <RoundedImageFullWidth src="/Log In Image.png"></RoundedImageFullWidth>
          </FlexColumnFullWidth>
          <FlexColumnFullWidth width="60%" mobilewidth="97vw">
            <CasePhotoUploadPreviewWindow
              AssignIdToImage={() => {
                GetCase(params.caseId);
              }}
              caseObj={caseObj}
              open={previewImageWindowDisplay}
              previewsrc={previewImage}
              CancelUpload={() => {
                ResetImage(previewImage);
                setPreviewImageWindowDisplay(false);
              }}
              CloseWindow={() => {
                setPreviewImageWindowDisplay(false);
              }}
            ></CasePhotoUploadPreviewWindow>
            <br></br>
            <FlexDivFlexEnd mobilepaddingbottom="15vh">
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  history.push(
                    "/viewcaseuser/" +
                      params.Id +
                      "/" +
                      params.storeId +
                      "/" +
                      params.caseId
                  );
                }}
              >
                Back
              </RegularResponsiveButton>
              <RegularResponsiveButton
                onClick={() => {
                  UpdateProject();
                }}
              >
                Save Changes
              </RegularResponsiveButton>
            </FlexDivFlexEnd>
            <br></br>
            <FlexRowCont100PercentSpaceBetween>
              <LeagueSpartanRegularMinWidth width="150px">
                Project Title
              </LeagueSpartanRegularMinWidth>
              <TextInputRegular
                font_size="16px"
                mobilefontsize="75%"
                placeholder={"Project Title"}
                inputheight="1%"
                inputborderradius="5px"
                defaultValue={defaultTitle}
                onChange={(e) => {
                  setDefaultTitle(e.target.value);
                }}
              ></TextInputRegular>
            </FlexRowCont100PercentSpaceBetween>

            <FlexRowCont100PercentSpaceBetween>
              <LeagueSpartanRegularMinWidth width="150px">
                Contact Name
              </LeagueSpartanRegularMinWidth>
              <TextInputRegular
                font_size="16px"
                mobilefontsize="75%"
                placeholder={"Contact Name"}
                inputheight="1%"
                inputborderradius="5px"
                defaultValue={defaultName}
                onChange={(e) => {
                  setDefaultName(e.target.value);
                }}
              ></TextInputRegular>
            </FlexRowCont100PercentSpaceBetween>

            <FlexRowCont100PercentSpaceBetween>
              <LeagueSpartanRegularMinWidth width="150px">
                Email
              </LeagueSpartanRegularMinWidth>
              <TextInputRegular
                placeholder={"Email"}
                mobilefontsize="75%"
                inputheight="1%"
                inputborderradius="5px"
                defaultValue={defaultEmail}
                onChange={(e) => {
                  setDefaultEmail(e.target.value);
                }}
              ></TextInputRegular>
            </FlexRowCont100PercentSpaceBetween>

            <FlexRowCont100PercentSpaceBetween>
              <LeagueSpartanRegularMinWidth width="150px">
                Address
              </LeagueSpartanRegularMinWidth>
              <TextInputRegular
                placeholder={"Address"}
                mobilefontsize="75%"
                inputheight="1%"
                inputborderradius="5px"
                defaultValue={defaultAddress}
                onChange={(e) => {
                  setDefaultAddress(e.target.value);
                }}
              ></TextInputRegular>
            </FlexRowCont100PercentSpaceBetween>

            <FlexRowCont100PercentSpaceBetween>
              <LeagueSpartanRegularMinWidth width="150px">
                Phone
              </LeagueSpartanRegularMinWidth>
              <TextInputRegular
                placeholder={"Phone"}
                mobilefontsize="75%"
                inputheight="1%"
                inputborderradius="5px"
                defaultValue={defaultPhone}
                onChange={(e) => {
                  setDefaultPhone(e.target.value);
                }}
              ></TextInputRegular>
            </FlexRowCont100PercentSpaceBetween>

            <br></br>

            <FlexRowCont100PercentSpaceBetween>
              <LandscapePara
                mobileheight="40vh"
                maxLength="1500"
                defaultValue={desc}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />
            </FlexRowCont100PercentSpaceBetween>
          </FlexColumnFullWidth>
        </FlexRowCont100PercentSpaceBetween>

        <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
        <FlexColumnFullWidth>
          <FlexRowCont70PercentFlexStart>
            <SmallHeaderLeagueSpartan>Upload Photos</SmallHeaderLeagueSpartan>
            <Icon30x40pxPointer
              src={"/Green Button.svg"}
              onClick={onButtonClick6}
            ></Icon30x40pxPointer>
          </FlexRowCont70PercentFlexStart>

          <br></br>

          <input
            style={{ display: "none" }}
            ref={inputFile}
            type="file"
            onChange={handleUpload}
          />
          <GridDivMobileFlexDiv>
            {imgArray.map((o, i) => (
              <div>
                <SmallTextPadding>{o.Caption}</SmallTextPadding>
                <GridDivMobileFlexDiv key={i}>
                  <Image300x200
                    src={window.$IMGURL + o.File}
                    alt={"PDF"}
                  ></Image300x200>
                  <Image40x40px
                    src="/Garbage_Icon.svg"
                    onClick={() => {
                      HandleDelete(o, i);
                    }}
                  ></Image40x40px>
                </GridDivMobileFlexDiv>
              </div>
            ))}
          </GridDivMobileFlexDiv>
        </FlexColumnFullWidth>
        <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>
      </FlexColumnCont75x80Percent>
    </div>
  );
};

UpdateCase.defaultProps = {};

export default UpdateCase;
