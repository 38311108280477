//  React Imports ---------------------------------------
import React, {useState} from "react";
import styled from "styled-components";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
// Imported Styled Components ---------------------------
import {
  FlexColumn81vwLightGrey,
  FlexColumnFullWidthMargins,
  FlexDiv,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import SiteMapper from "../../comps/SiteMapper";
import ViewProposalEstimatorView from "../../UserProjectPages/ViewProposalEstimate";
import { StickyTopMenuCont } from "../../StylesheetComps/Menu";

const ResizableFlexDiv = styled(FlexDiv)`
  display: flex;
  height: auto;
  width: 100vw;
`;

// Styled component for the draggable bar
const DraggableBar = styled.div`
  width: 5px;
  position: sticky;
  top:0;
  cursor: col-resize;
  background-color: #ccc;
  height: 100vh; // Adjust height as needed
  user-select: none; // Prevent text selection while dragging
  z-Index: 999;
`;

const SitemapDesktop = ({hideMenu}) => {

  // State to manage the width of the left panel
  const [leftWidth, setLeftWidth] = useState(80); // Start with 80vw
  const [scrollToItem, setScrollToItem] = useState({}); // Start with 80vw
  const [refreshEstimate, setRefreshEstimate] = useState(false); 

  const startResizing = (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    // When the mouse moves, adjust the width of the left panel
    const handleMouseMove = (mouseMoveEvent) => {
      let newWidth = (mouseMoveEvent.clientX / window.innerWidth) * 100;
      // Enforce a minimum width of 30vw and a maximum width of 80vw for leftWidth
      newWidth = Math.max(Math.min(newWidth, 80), 50);
      setLeftWidth(newWidth);
    };

    // Add event listeners for mouse move and mouse up
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', handleMouseMove);
    }, { once: true });
  };

  // Ensure the SiteMapper component has a minimum of 20vw, 
  // and adjust based on the current leftWidth to maintain overall constraints
  const rightWidth = Math.max(100 - leftWidth, 20);

  const ScrollToItemOnEstimate = async (item) => {
    setScrollToItem(item)
  }

  const handleResetScrollToItem = () => {
    setScrollToItem(null);
  };  

  const handleResetRefreshEstimate = () => {
    setRefreshEstimate(false)
  }

  const RefreshEstimate = () => {
    setRefreshEstimate(true)
  }

  if (window.$ISMOBILE === true) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu
            mobilePageProp="Sample Page"
            pageProp={"Sample Page"}
          ></ActionMenu>

          <ContentCont77vw>
            <FlexColumnFullWidthMargins></FlexColumnFullWidthMargins>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <div>
        <ResizableFlexDiv>
          <FlexDiv width={`${leftWidth}vw`}>
            <ViewProposalEstimatorView refreshEstimate={refreshEstimate} refreshEstimateFinished={()=>{handleResetRefreshEstimate()}} scrollToItem={scrollToItem} onResetScrollToItem={handleResetScrollToItem}  hideMenu={true} contWidth={`${leftWidth}vw`} />
          </FlexDiv>
          <DraggableBar onMouseDown={startResizing} />
          <StickyTopMenuCont width={`${rightWidth}vw`} height="100vh">
            <SiteMapper RefreshEstimate={()=>{RefreshEstimate()}} DeselectIcon={()=>{handleResetScrollToItem()}} ScrollToItemOnEstimate={(item, itemIndex)=>{ScrollToItemOnEstimate(item, itemIndex)}} contWidth={`${rightWidth}vw`} />
          </StickyTopMenuCont>
        </ResizableFlexDiv>
      </div>
    );
  }
};

SitemapDesktop.defaultProps = {};

export default SitemapDesktop;
