import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Input from "../../../comps/Input";
import { useParams } from "react-router-dom";
import {
  Cont450pxAutoHeight,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";

import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanExtraLargeWidth200px,
} from "../../../StylesheetComps/Titles";

const CityList = styled.div`
  width: 80%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px;
  margin-left: 20px;
  line-height: 40px;
  cursor: pointer;
`;

const ZoneSupplierWindow = ({
  zoneOpen,
  onClose,
  onFormComplete,
  onPreview,
}) => {
  const params = useParams();
  const [zoneSuppliers, getZoneSuppliers] = useState([]);
  const [posInput, setPosInput] = useState("none");
  const [addName, setAddName] = useState("");
  const [positionSupp, setPositionSupp] = useState(0);
  const [supplierId, setSupplierId] = useState(0);

  const GetSuppliers = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/" + params.Id + "/supplier"
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.suppliers !== null) {
          getZoneSuppliers(resp.data.suppliers);
        }
      }
    }
  };

  useEffect(() => {
    GetSuppliers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!zoneOpen) return null;

  return (
    <>
      {" "}
      <Cont450pxAutoHeight>
        <LeagueSpartanExtraLargeBold>
          Add Supplier to Zone
        </LeagueSpartanExtraLargeBold>
        <LeftItemWindowFixed>
          <LeagueSpartanExtraLargeWidth200px>
            Pick Supplier to Add to Zone
          </LeagueSpartanExtraLargeWidth200px>
          {zoneSuppliers.map((o, i) => (
            <div>
              <CityList
                key={i}
                onClick={() => {
                  setSupplierId(o.Id);
                  setPosInput("block");
                  setAddName(o.Name);
                }}
              >
                {o.Name}
              </CityList>
              <hr></hr>
            </div>
          ))}
          <Input
            inputwidth="71%"
            inputheight={"50%"}
            placeholder={"Enter Position"}
            inputdisplay={posInput}
            onChange={(e) => {
              setPositionSupp(e.target.value);
            }}
          ></Input>
          <RegularResponsiveButton
            onClick={() => {
              onPreview({ supplierId, positionSupp });
              onFormComplete(supplierId, positionSupp);
              onClose();
            }}
          >
            Add {addName}
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </LeftItemWindowFixed>
      </Cont450pxAutoHeight>{" "}
    </>
  );
};

ZoneSupplierWindow.defaultProps = {
  onPreview: () => {},
  onFormComplete: () => {},
};

export default ZoneSupplierWindow;
