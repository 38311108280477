import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import ToolkitCard from "../../comps/ToolkitCard";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import ToolKitAdd from "../../comps/Windows/ToolKitAdd";
import ToolKitEdit from "../../comps/Windows/ToolKitEdit";
//Functions Import

import {
  DeleteToolKit,
  GetStoreToolKits,
  GetToolKit,
} from "../../API_Functions/Tool_Functions";

import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  Div70PerWidth7PerHeight,
  DivWithDisplay,
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv40Percent, FlexDiv80Percent } from "../../StylesheetComps/Div";
import { SmallHeaderLeagueSpartan, TextInput100Percent, TextInput72Percent, TextInputRegular, TextInputReusable } from "../../StylesheetComps/LandscapeForm";
import { Icon30px20pxPointer, Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { InputField } from "../../StylesheetComps/Inputs";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { GetStoreTools } from "../../API_Functions/CatProduct_Functions";
import ToolCard from "../../comps/ToolCard";
import { StyledHRGreyBreak } from "../../StylesheetComps/Titles";

const ToolKitScreen = () => {
  //=============================================================================
  // ToolKit Screen - View ToolKits (Essentially Categories for Tools)
  //=============================================================================

  const params = useParams();
  const history = useHistory();
  const [storeToolKits, getStoreToolKits] = useState([]);
  const [toolkitObj, setToolkitObj] = useState({});
  const [deleteToolkitId, setDeleteToolkitId] = useState(0);

  const [isNewToolKitOpen, setIsNewToolKitOpen] = useState(false);
  const [isEditToolKitOpen, setIsEditToolKitOpen] = useState(false);
  const [isDeleteToolKitOpen, setIsDeleteToolKitOpen] = useState(false);

  //Search Features: 
  const [storeTools, setStoreTools] = useState([]);
  const [unfilteredTools, setUnfilteredTools] = useState([]);

  const HandleDeleteKit = async (id) => {
    var data = await DeleteToolKit(id);
    if (data === null) {
      GetStoreToolKitsMap(params.Id);
    }
  };

  // const FilterTools = (toollist, text) => {
  //   if (text === "") {
  //     setStoreTools([]);
  //   } else {
  //     setStoreTools(
  //       toollist.filter((o) => {
  //         return o.Name.toLowerCase().includes(text.toLowerCase());
  //       })
  //     );
  //   }
  // };

  const FilterTools = (toollist, text) => {
    if (text === "") {
      setStoreTools([]);
    } else {
      // Split the search text into words and convert them to lowercase
      const searchTextWords = text.toLowerCase().split(" ");
  
      // Filter the tool list
      const filteredTools = toollist.filter(tool => {
        // Convert the tool's name to lowercase
        const toolNameLower = tool.Name.toLowerCase();
  
        // Check if every word from the search text is included in the tool's name
        return searchTextWords.every(searchWord => toolNameLower.includes(searchWord));
      });
  
      // Update the store with the filtered tools
      setStoreTools(filteredTools);
    }
  };
  

  const GetStoreToolsFunc = async (storeid) => {
    try {
      const resp = await GetStoreTools(storeid)
      if(resp.data !== null){
        setUnfilteredTools(resp.tools)
      }
    } catch (error) {
      setStoreTools([])
      setUnfilteredTools([])
      window.alert(`Trouble receiving Tool Information: ${error.message}`)
    }
  }

  const GetStoreToolKitsMap = async (id) => {
    var data = await GetStoreToolKits(id);
    if (data == null) return;
    getStoreToolKits(data.toolkit);
  };

  const GetToolKitInfo = async (id) => {
    var data = await GetToolKit(id);
    if (data == null) return;
    setToolkitObj(data);
    setIsEditToolKitOpen(true);
  };

  useEffect(() => {
    GetStoreToolKitsMap(params.Id);
    GetStoreToolsFunc(params.Id)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DivWithDisplay flexdirection='row'>
      <LeftCont>
      <Menu onReset={() => {
            setIsNewToolKitOpen(false);
            setIsEditToolKitOpen(false);
          }}></Menu>
        </LeftCont>

<FlexColumnFullWidth height='100vh'>
<ToolKitAdd
            ToolKitPosProp={storeToolKits.length + 1}
            open={isNewToolKitOpen}
            onClose={() => {
              setIsNewToolKitOpen(false);
            }}
            RefreshToolKitList={() => {
              GetStoreToolKitsMap(params.Id);
            }}
          ></ToolKitAdd>
          <ToolKitEdit
            open={isEditToolKitOpen}
            onClose={() => {
              setIsEditToolKitOpen(false);
            }}
            RefreshToolkitList={() => {
              GetStoreToolKitsMap(params.Id);
            }}
            toolkitobj={toolkitObj}
          ></ToolKitEdit>
    <DivWithDisplay width='100%'>
      <DivWithDisplay>
        <DivWithDisplay alignitems='left' flexdirection='row'>
          <Icon30px30pxPointer
          margin='10px'
          src='/backarrow.svg'
          onClick={() => {
            history.push("/Store/" + params.Id);
          }}
          >
            </Icon30px30pxPointer>
            <SmallHeaderLeagueSpartan width='auto'>
              Tool Kits
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
              margin='8px'
              onClick={() => {
                setIsNewToolKitOpen(true);
              }}
            >
              Add Toolkit
            </RegularResponsiveButton>
            </DivWithDisplay>
            <DivWithDisplay flexdirection='row'>
            <InputField 
            placeholder="Search for a Tool"
            inputwidth="70%"
            inputheight="25%"
            inputborderadius="10px"
            onChange={(e) => {
              FilterTools(unfilteredTools, e.target.value);
            }}
          ></InputField>
  </DivWithDisplay>
  <StyledHRGreyBreak></StyledHRGreyBreak>
  <DeleteConfirmWindow
            deleteOpen={isDeleteToolKitOpen}
            closeDel={() => {
              setIsDeleteToolKitOpen(false);
            }}
            onDeleteFunction={() => {
              HandleDeleteKit(deleteToolkitId);
            }}
          ></DeleteConfirmWindow>
                 {storeToolKits.map((o, i) => (
              <ToolkitCard
                kitId={o.Id}
                key={i}
                KitHeadText={o.Name}
                KitUrl={o.ImageUrl === "" ? "" : window.$IMGURL + o.ImageUrl}
                DelKit={() => {
                  setDeleteToolkitId(o.Id);
                  setIsDeleteToolKitOpen(true);
                }}
                EditKit={() => {
                  GetToolKitInfo(o.Id);
                }}
              ></ToolkitCard>
            ))}
            </DivWithDisplay>
            </DivWithDisplay>

  </FlexColumnFullWidth>

</DivWithDisplay>

    // <FlexCont100vw100vh>
    //   <LeftCont>
    //     <Menu
    //       onReset={() => {
    //         setIsNewToolKitOpen(false);
    //         setIsEditToolKitOpen(false);
    //       }}
    //     >
    //       {" "}
    //     </Menu>
    //   </LeftCont>

    //   <FlexColumnCont80vwAutoHeight>
    //     <ActionMenu />
    //     <RightViewFullWidth>
    //       <ToolKitAdd
    //         ToolKitPosProp={storeToolKits.length + 1}
    //         open={isNewToolKitOpen}
    //         onClose={() => {
    //           setIsNewToolKitOpen(false);
    //         }}
    //         RefreshToolKitList={() => {
    //           GetStoreToolKitsMap(params.Id);
    //         }}
    //       ></ToolKitAdd>
    //       <ToolKitEdit
    //         open={isEditToolKitOpen}
    //         onClose={() => {
    //           setIsEditToolKitOpen(false);
    //         }}
    //         RefreshToolkitList={() => {
    //           GetStoreToolKitsMap(params.Id);
    //         }}
    //         toolkitobj={toolkitObj}
    //       ></ToolKitEdit>
    //       <Div70PerWidth7PerHeight>
    //         <Icon30px20pxPointer
    //           src="/backarrow.svg"
    //           onClick={() => {
    //             history.push("/Store/" + params.Id);
    //           }}
    //         ></Icon30px20pxPointer>
    //         <SmallHeaderLeagueSpartan width="20%">
    //           Toolkits
    //         </SmallHeaderLeagueSpartan>
    //         <RegularResponsiveButton
    //           onClick={() => {
    //             setIsNewToolKitOpen(true);
    //           }}
    //         >
    //           Add Toolkit
    //         </RegularResponsiveButton>
    //       </Div70PerWidth7PerHeight>
    //       <DeleteConfirmWindow
    //         deleteOpen={isDeleteToolKitOpen}
    //         closeDel={() => {
    //           setIsDeleteToolKitOpen(false);
    //         }}
    //         onDeleteFunction={() => {
    //           HandleDeleteKit(deleteToolkitId);
    //         }}
    //       ></DeleteConfirmWindow>
    //       <FlexDiv80Percent>
    //       <InputField placeholder="Search for a Tool"
    //       onChange={(e) => {
    //         FilterTools(unfilteredTools, e.target.value);
    //       }}
    //       ></InputField>
    //      {storeTools.map((o, i) => (
    //           <ToolCard
    //             key={i}
    //             relToolProd={() => {
    //               var toolKitName = storeToolKits.find(toolKit => toolKit.Id === o.ToolKit_Id)?.Name;
    //               history.push(
    //                 "/toollist/" + params.Id + "/" + o.ToolKit_Id + '/' + toolKitName
    //               );
    //             }}
    //             ToolID={o.Id}
    //             ToolHeadText={o.Name}
    //             ToolUrl={o.VideoUrl}
    //             onView={() => {
    //               var toolKitName = storeToolKits.find(toolKit => toolKit.Id === o.ToolKit_Id)?.Name;
    //               history.push(
    //                 "/toollist/" + params.Id + "/" + o.ToolKit_Id + '/' + toolKitName
    //               );
    //             }}
    //           ></ToolCard>
    //         ))}
    //       <br></br>
    //         {storeToolKits.map((o, i) => (
    //           <ToolkitCard
    //             kitId={o.Id}
    //             key={i}
    //             KitHeadText={o.Name}
    //             KitUrl={o.ImageUrl === "" ? "" : window.$IMGURL + o.ImageUrl}
    //             DelKit={() => {
    //               setDeleteToolkitId(o.Id);
    //               setIsDeleteToolKitOpen(true);
    //             }}
    //             EditKit={() => {
    //               GetToolKitInfo(o.Id);
    //             }}
    //           ></ToolkitCard>
    //         ))}
    //       </FlexDiv80Percent>
    //     </RightViewFullWidth>
    //   </FlexColumnCont80vwAutoHeight>
    // </FlexCont100vw100vh>
  );
};

ToolKitScreen.defaultProps = {
  ProfileName: "Default Name",
  MetroName: "EXAMPLE Toronto",
};

export default ToolKitScreen;
