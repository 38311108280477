import moment from "moment";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import { SetQuoteTo_Bid } from "../../../API_Functions/CaseQuote_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
    FlexRowCont100PercentSpaceBetween, FlexRowContFlexStart,
    LeftItemWindowFixed
} from "../../../StylesheetComps/Cont";
import { FlexColumnDivSpaceBetween } from "../../../StylesheetComps/Div";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
    RegularText
} from "../../../StylesheetComps/Titles";

const ConfirmBiddingDateWindow = ({
  open,
  onClose,
  startdate,
  leftbuttontext,
  rightbuttontext,
  leftbuttoncolor,
  rightbuttoncolor,
  left,
  LeftFunction,
  RightFunction,
  width,
  top,
  quote
}) => {

    const [startDate, setStartDate] = useState(new Date());
    const [reactDatePickerToggle, setReactDatePickerToggle] = useState(true);
    const [windowText, setWindowText] = useState("");
    const [postButtonDisplay, setPostButtonDisplay] = useState("none");

    const params = useParams()
    const history = useHistory()

    const PostQuoteForBid = async (quote, startdate) => {
        var selectedStartYear = startdate.getFullYear();
        var selectedStartMonth = startdate.getMonth() + 1;
        var selectedStartDate = startdate.getDate();
    
        var selectedDate =
          selectedStartYear +
          "-" +
          selectedStartMonth +
          "-" +
          selectedStartDate +
          "T" +
          0 +
          0 +
          ":" +
          0 +
          0;
    
        quote.DesiredInstallDate = selectedDate;
        await SetQuoteTo_Bid(quote);
        history.push(`/CasesPersonal/${params.metroId}/${params.storeId}`);
      };

      useEffect(() => {
        if(moment(startDate).format('LL') !== moment(new Date).format('LL')){
            setWindowText(`You've selected ${moment(startDate).format(
                "LL"
              )} as your preferred project start date. Post project for bid?`)
            setPostButtonDisplay(`block`)
        } else {
            setWindowText(`Please select your preferred project install start date.`)
            setPostButtonDisplay(`none`)
        }
      }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexRowContFlexStart>
        <LeftItemWindowFixed left={left} top={top} width={`600px`} actualheight={`300px`}>
          <FlexRowCont100PercentSpaceBetween alignitems={`baseline`} height={`100%`}>
          <ReactDatePicker
                open={reactDatePickerToggle}
                placeholderText="Select Date"
                minDate={new Date()}
                onSelect={(date) => {
                  setStartDate(date);
                }}
              />

            <FlexColumnDivSpaceBetween width="100%">
            <RegularText>{windowText}</RegularText>
            <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              bgcolor={leftbuttoncolor}
              onClick={LeftFunction}
            >
              {leftbuttontext}
            </RegularResponsiveButton>{" "}
            <RegularResponsiveButton
            display={postButtonDisplay}
              bgcolor={rightbuttoncolor}
              onClick={()=>{PostQuoteForBid(quote, startDate)}}
            >
              {`Post Project for Bid`}
            </RegularResponsiveButton>
                </FlexRowCont100PercentSpaceBetween>  
            </FlexColumnDivSpaceBetween>
          </FlexRowCont100PercentSpaceBetween>
        </LeftItemWindowFixed>
      </FlexRowContFlexStart>
    </FixedOverlay200vh>
  );
};

ConfirmBiddingDateWindow.defaultProps = {
  RightFunction: () => {},
  LeftFunction: () => {},
  rightbuttoncolor: ColorPalette.PrimaryButtonBlue,
  leftbuttoncolor: ColorPalette.PrimaryButtonBlue,
  left: "40%",
  top: "5%",
};

export default ConfirmBiddingDateWindow;
