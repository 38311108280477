import { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  ContMargin20px,
  FlexColumnFullWidth,
  FlexDiv,
} from "../../../StylesheetComps/Cont";
import {
  FlexColumnDiv,
  FlexEndDivAutox15Percent,
} from "../../../StylesheetComps/Div";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import {
  FixedOverlay1000vh,
  OverlayCont450pxAutoHeight,
} from "../../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularTextBoldRed,
} from "../../../StylesheetComps/Titles";

const NewRoleWindow = ({
  open,
  onClose,
  onChange,
  onFormComplete,
  onPreview,
  RoleNameProp,
}) => {
  const [error, setError] = useState(null);
  const [roleFullName, setRoleFullName] = useState("");
  const [roleUsername, setRoleUsername] = useState("");
  const [roleEmail, setRoleEmail] = useState("");
  const [rolePhone, setRolePhone] = useState("");
  const [rolePassword, setRolePassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  if (!open) return null;
  return (
    <FixedOverlay1000vh>
      {" "}
      <ContMargin20px>
        <OverlayCont450pxAutoHeight>
          <FlexDiv>
            <FlexColumnFullWidth>
              <FlexDiv>
                <FlexColumnDiv>
                  <LeagueSpartanExtraLargeWidth200px>
                    {RoleNameProp} Name
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setRoleFullName(e.target.value);
                    }}
                  ></TextInput72Percent>

                  <LeagueSpartanExtraLargeWidth200px>
                    Email
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setRoleEmail(e.target.value);
                    }}
                  ></TextInput72Percent>
                  <LeagueSpartanExtraLargeWidth200px>
                    Phone
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setRolePhone(e.target.value);
                    }}
                  ></TextInput72Percent>
                </FlexColumnDiv>

                <FlexColumnDiv>
                  <LeagueSpartanExtraLargeWidth200px>
                    Username
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setRoleUsername(e.target.value);
                    }}
                  ></TextInput72Percent>
                  <LeagueSpartanExtraLargeWidth200px>
                    {" "}
                    Password
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setRolePassword(e.target.value);
                    }}
                    type="password"
                  ></TextInput72Percent>
                  <LeagueSpartanExtraLargeWidth200px>
                    {" "}
                    Confirm Password
                  </LeagueSpartanExtraLargeWidth200px>
                  <TextInput72Percent
                    autoComplete="new-password"
                    onChange={(e) => {
                      setConfirmPass(e.target.value);
                    }}
                    type="password"
                  ></TextInput72Percent>
                  <RegularTextBoldRed>{error}</RegularTextBoldRed>
                </FlexColumnDiv>
              </FlexDiv>
              <FlexEndDivAutox15Percent>
                <RegularResponsiveButton
                  onClick={() => {
                    if (confirmPass.length < 8) {
                      setError("Password Not Long Enough! (8 Characters)");
                    } else if (rolePassword !== confirmPass) {
                      setError("Passwords don't match!");
                    } else {
                      onPreview({
                        roleFullName,
                        roleUsername,
                        roleEmail,
                        rolePhone,
                        rolePassword,
                      });
                      onFormComplete(
                        roleFullName,
                        roleUsername,
                        roleEmail,
                        rolePhone,
                        rolePassword
                      );
                    }
                  }}
                >
                  Add
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={onClose}
                >
                  Cancel
                </RegularResponsiveButton>
              </FlexEndDivAutox15Percent>
            </FlexColumnFullWidth>
          </FlexDiv>
        </OverlayCont450pxAutoHeight>
      </ContMargin20px>{" "}
    </FixedOverlay1000vh>
  );
};

NewRoleWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
};

export default NewRoleWindow;
