//Can't test admin so eslinting for now
/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import MetroCityCard from "../../comps/MetroCityCard";
import MetroStoreCard from "../../comps/MetroStoreCard";
import MetroTaxCard from "../../comps/MetroTaxCard";
import NewStoreWindow from "../../comps/Windows/AddStore";
import DeleteWindow from "../../comps/Windows/Delete This";
import DeleteCityWindow from "../../comps/Windows/DeleteCity";
import DeleteTaxWindow from "../../comps/Windows/DeleteTax";
import EditCityWindow from "../../comps/Windows/EditCity";
import EditStoreWindow from "../../comps/Windows/EditStore";
import EditTaxWindow from "../../comps/Windows/EditTax";
import NewCityWindow2 from "../../comps/Windows/New City";
import NewTaxWindow from "../../comps/Windows/NewTax";

import { GetStoreInfo } from "../../API_Functions/Store_Functions";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexCont100vw100vh,
  FlexDiv,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightView_FlexFullWidth_Column } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const ViewSplitCont = styled.div`
  width: 26.5%;
  display: flex;
  flex-direction: column;
`;

const MetroIDScreen = () => {
  //=============================================================================
  // Metro Screen - View a Single Metro and its Stores, Taxes, & Cities
  //=============================================================================

  //=============================================================================
  // Accessable on by Admin
  //=============================================================================

  //AXIOS
  const [storeObj, setStoreObj] = useState();

  const [metroName, getMetroName] = useState([]);
  const [metroStores, getMetroStoresList] = useState([]);
  const [metroTax, getMetroTax] = useState([]);
  const [metrocities, getMetroCities] = useState([]);
  const [managerName1, setManagerName] = useState([]);

  //OPEN STATES
  const [isOpen, setIsOpen] = useState(false);
  const [isTaxOpen, setIsTaxOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isEditTaxOpen, setIsEditTaxOpen] = useState(false);
  const [isEditCityOpen, setIsEditCityOpen] = useState(false);

  const [delOpen, setDelOpen] = useState(false);
  const [delTaxOpen, setDelTaxOpen] = useState(false);
  const [delCityOpen, setDelCityOpen] = useState(false);

  const [newCityName, setStoreNewCity] = useState("");
  const [newTaxName, setNewTaxName] = useState("");
  const [newTaxRate, setNewTaxRate] = useState(0);
  const [newStoreName, setStoreName] = useState("");
  const [newStoreAddress, setStoreAddress] = useState("");
  const [newStorePhone, setStorePhone] = useState("");

  const [editTaxName, setEditTaxName] = useState("");
  const [editTaxRate, setEditTaxRate] = useState(0);

  const [editCityName, setEditCityName] = useState("");

  const [storeDelId, setStoreId] = useState(0);
  const [taxDelId, setTaxId] = useState(0);
  const [cityDelId, setCityId] = useState(0);
  var [EditStoreId, setEditStoreID] = useState(0);

  const history = useHistory();
  const params = useParams();

  const GetMetro = async () => {
    const resp = await axios.get(
      window.$DBURL + "utilities/metros/" + params.Id
    );
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.metro !== null) {
          setMetroInfo(resp.data.metro);
          getMetroName(resp.data.metro.Name);
        }
      }
    }
  };

  const GetMetroStores = async () => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + params.Id);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          getMetroStoresList(resp.data.stores);
        }
      }
    }
  };

  const GetMetroTaxes = async () => {
    const resp = await axios.get(window.$DBURL + "tax/metro/" + params.Id);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.tax !== null) {
          getMetroTax(resp.data.tax);
        }
      }
    }
  };

  const GetMetroCities = async () => {
    const resp = await axios.get(
      window.$DBURL + "utilities/city/metro/" + params.Id
    );
    if (resp.status === 200) {
      if (resp.data != null) {
        if (resp.data.cities != null) {
          if (resp.data.cities.length > 0) {
            getMetroCities(resp.data.cities);
          }
        }
      }
    }
  };

  const GetStaff = async () => {
    const resp = await axios.get(
      window.$DBURL + "admin/manager/metro/" + params.Id
    );
    // setManagerName(resp.data)
  };

  const DeleteStore = async () => {
    const resp = await axios.delete(window.$DBURL + "store/" + storeDelId);
    if (resp.status == 200) {
      GetMetroStores();
    }
  };

  const DeleteTax = async () => {
    const resp = await axios.delete(window.$DBURL + "tax/" + taxDelId);
    if (resp.status == 200) {
      GetMetroTaxes();
    }
  };

  const DeleteCity = async () => {
    const resp = await axios.delete(
      window.$DBURL + "utilities/city/" + cityDelId
    );
    if (resp.status == 200) {
      GetMetroCities();
    }
  };

  const HandleFormComplete = async ({
    storeNewCity,
    taxName,
    taxPercent,
    storeName,
    storeAddress,
    storePhone,
    StoreID,
  }) => {
    setStoreNewCity(storeNewCity);
    setNewTaxName(taxName);
    setNewTaxRate(taxPercent);
    setStoreName(storeName);
    setStoreAddress(storeAddress);
    setStorePhone(storePhone);
    setEditStoreID(StoreID);
  };

  const HandleNewCity = async (newCity) => {
    var resp = await axios.post(window.$DBURL + "utilities/city", {
      Name: newCity,
      Metro_Id: params.Id,
    });
    if (resp.status == 200) {
      GetMetroCities();
    }
  };

  const HandleEditCity = async (newCity) => {
    var resp = await axios.put(window.$DBURL + "utilities/city", {
      Id: cityDelId,
      Name: newCity || editCityName,
      Metro_Id: params.Id,
    });
    if (resp.status == 200) {
      GetMetroCities();
    }
  };

  const HandleNewTax = async (newTaxName, newTaxRate) => {
    var resp = await axios.post(window.$DBURL + "tax", {
      TaxCode: newTaxName,
      Rate: newTaxRate,
      Metro_Id: params.Id,
    });
    if (resp.status == 200) {
      GetMetroTaxes();
    }
  };

  const HandleEditTax = async (newTaxName, newTaxRate) => {
    var resp = await axios.put(window.$DBURL + "tax", {
      Id: taxDelId,
      TaxCode: newTaxName || editTaxName,
      Rate: newTaxRate || editTaxRate,
    });
    if (resp.status == 200) {
      GetMetroTaxes();
    }
  };

  const GetStoreInformation = async (store_id) => {
    var data = await GetStoreInfo(store_id);
    if (data !== null) {
      setStoreObj(data);
      setIsEditOpen(true);
    }
  };

  useEffect(() => {
    GetMetro();
    GetMetroStores();
    GetMetroTaxes();
    GetMetroCities();
    GetStaff();
  }, []);

  const [metroInfo, setMetroInfo] = useState([]);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightView_FlexFullWidth_Column>
          <HundredPercentFlexDiv>
            <Icon30px20pxPointer
              src="/backarrow.svg"
              onClick={() => {
                history.push("/metrolist");
              }}
            ></Icon30px20pxPointer>
            <LeagueSpartanExtraLargeBold>
              {metroName}
            </LeagueSpartanExtraLargeBold>
          </HundredPercentFlexDiv>
          <FlexDiv>
            <ViewSplitCont>
              <FlexDiv70x7Percent>
                <LeagueSpartanExtraLargeBold>
                  Stores
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton onClick={() => setIsOpen(true)}>
                  Add Store
                </RegularResponsiveButton>
              </FlexDiv70x7Percent>
              <EditStoreWindow
                storeobj={storeObj}
                StoreID={storeDelId}
                open={isEditOpen}
                onClose={() => {
                  setIsEditOpen(false);
                }}
                StoreId={storeDelId}
              ></EditStoreWindow>
              <NewStoreWindow
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onChange={() => {
                  GetMetroStores();
                }}
              ></NewStoreWindow>
              {metroStores.map((o, i) => (
                <MetroStoreCard
                  key={i}
                  StoreID={o.Id}
                  ManagerName={managerName1}
                  StoreLocationName={o.Address}
                  StoreName={o.Name}
                  onDeleteStore={() => {
                    setDelOpen(true);
                    setStoreId(o.Id);
                  }}
                  EditStore={() => {
                    GetStoreInformation(o.Id);
                  }}
                ></MetroStoreCard>
              ))}
            </ViewSplitCont>
            <ViewSplitCont>
              <DeleteWindow
                deleteOpen={delOpen}
                closeDel={() => setDelOpen(false)}
                onDeleteFunction={DeleteStore}
              ></DeleteWindow>
              <DeleteTaxWindow
                deleteOpen={delTaxOpen}
                closeDel={() => setDelTaxOpen(false)}
                onDeleteFunction={DeleteTax}
              ></DeleteTaxWindow>
              <DeleteCityWindow
                deleteOpen={delCityOpen}
                closeDel={() => setDelCityOpen(false)}
                onDeleteFunction={DeleteCity}
              ></DeleteCityWindow>

              <FlexDiv70x7Percent>
                <LeagueSpartanExtraLargeBold>Taxes</LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton onClick={() => setIsTaxOpen(true)}>
                  Add Taxes
                </RegularResponsiveButton>
              </FlexDiv70x7Percent>
              <NewTaxWindow
                open={isTaxOpen}
                onClose={() => setIsTaxOpen(false)}
                onFormComplete={HandleNewTax}
                onPreview={HandleFormComplete}
              ></NewTaxWindow>
              <EditTaxWindow
                Name={editTaxName}
                Rate={editTaxRate}
                open={isEditTaxOpen}
                onClose={() => setIsEditTaxOpen(false)}
                onFormComplete={HandleEditTax}
                onPreview={HandleFormComplete}
              ></EditTaxWindow>
              {metroTax.map((o, i) => (
                <MetroTaxCard
                  key={i}
                  TaxID={o.Id}
                  TaxNameText={o.TaxCode}
                  TaxRateText={o.Rate + "%"}
                  onClick={() => {
                    setDelTaxOpen(true);
                    setTaxId(o.Id);
                  }}
                  EditTax={() => {
                    setEditTaxName(o.TaxCode);
                    setEditTaxRate(o.Rate);
                    setIsEditTaxOpen(true);
                    setTaxId(o.Id);
                  }}
                ></MetroTaxCard>
              ))}
            </ViewSplitCont>
            <ViewSplitCont>
              <DeleteTaxWindow></DeleteTaxWindow>
              <FlexDiv70x7Percent>
                <LeagueSpartanExtraLargeBold>
                  Cities
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton onClick={() => setIsCityOpen(true)}>
                  Add City
                </RegularResponsiveButton>
              </FlexDiv70x7Percent>
              <NewCityWindow2
                open={isCityOpen}
                onClose={() => setIsCityOpen(false)}
                onFormComplete={HandleNewCity}
                onPreview={HandleFormComplete}
              ></NewCityWindow2>
              <EditCityWindow
                Name={editCityName}
                open={isEditCityOpen}
                onClose={() => setIsEditCityOpen(false)}
                onFormComplete={HandleEditCity}
                onPreview={HandleFormComplete}
              ></EditCityWindow>
              {metrocities.map((o, i) => (
                <MetroCityCard
                  key={i}
                  CityNameText={o.Name}
                  CityID={o.Id}
                  onClick={() => {
                    setDelCityOpen(true);
                    setCityId(o.Id);
                  }}
                  EditCity={() => {
                    setEditCityName(o.Name);
                    setIsEditCityOpen(true);
                    setCityId(o.Id);
                  }}
                ></MetroCityCard>
              ))}
            </ViewSplitCont>
          </FlexDiv>
        </RightView_FlexFullWidth_Column>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

MetroIDScreen.defaultProps = {};

export default MetroIDScreen;
