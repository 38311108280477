// Importing React hooks
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Importing mobile components
import { TopDivWhite } from "../../MobileComponents/Divs";
import {
  MobileLogo30x30px,
  MobileLogo50x50px,
} from "../../MobileComponents/Images";
import { MenuHeader } from "../../MobileComponents/Text";

// Importing stylesheet components
import { Cont82vw15vh } from "../../StylesheetComps/Cont";

// Importing a custom component
import MobileSlideMenu from "../MobileSlideMenu";

const ActionMenu = ({ mobilePageProp }) => {
  const history = useHistory();
  const [moreToggle, setMoreToggle] = useState(false);
  const [iconToggle, setIconToggle] = useState(false);
  const [menuSRC, setMenuSRC] = useState("/hamburger.svg");

  window.addEventListener("beforeinstallprompt", function (e) {
    window.$deferredPrompt = e;
  });

  const ChangeIcon = async (toggle) => {
    if (toggle === false) {
      setMenuSRC("/CloseMobile.svg");
      setIconToggle(true);
    } else {
      setMenuSRC("/hamburger.svg");
      setIconToggle(false);
    }
  };

  if (window.$ISMOBILE === true) {
    return (
      <div>
        <TopDivWhite>
          <MobileLogo50x50px
            onClick={() => {
              history.push("/home");
            }}
            src="/NRCLogoRegularBlack.png"
          ></MobileLogo50x50px>
          <MenuHeader>{mobilePageProp}</MenuHeader>
          <MobileLogo30x30px
            onClick={() => {
              setMoreToggle(!moreToggle);
              ChangeIcon(iconToggle);
            }}
            src={menuSRC}
          ></MobileLogo30x30px>
        </TopDivWhite>
        <MobileSlideMenu
          open={moreToggle}
          onCloseMenu={() => {
            setMoreToggle(false);
          }}
        ></MobileSlideMenu>
      </div>
    );
  } else {
    return <Cont82vw15vh></Cont82vw15vh>;
  }
};

ActionMenu.defaultProps = {
  profileurl: "",
  AddtoHomeScreenIOS: () => {},
  AddtoHomeScreenAndroid: () => {},
};

export default ActionMenu;
