import React from "react";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import ScrollToTop from "../../comps/ScrollToTop";
import {
  FlexColumnCont80vwAutoHeight,
  FlexContMobileColumn,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import UpdateCase from "../UpdateCase";

const UpdateCasePage = () => {
  return (
    <FlexContMobileColumn>
      <ScrollToTop />

      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu
          mobilePageProp="Edit your Project"
          pageProp={"Edit Your Project"}
        ></ActionMenu>

        <RightViewFullWidth>
          <UpdateCase />
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

UpdateCasePage.defaultProps = {};

export default UpdateCasePage;
