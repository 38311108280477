import React from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnCont100xAutoPercent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  RegularTextWithMargins,
  SmallHeaderLeagueSpartanWidth100Percent,
} from "../../../StylesheetComps/Titles";
import { Icon45x45 } from "../../../StylesheetComps/Imgs/Icons";
import { FlexDivFlexEnd } from "../../../StylesheetComps/Div";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const RevisionInstructionsWindow = ({ open, CloseWindow, StartRevising }) => {
  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <FlexColumnCont100xAutoPercent>
          <SmallHeaderLeagueSpartan>
            How to Make Project Revisions
          </SmallHeaderLeagueSpartan>
          <RegularTextWithMargins>
            All revisions will be sent to your New Rhodes Construction Guide for approval
            before the work is confirmed. Once the revisions are confirmed, a
            new revision of the project will be created for your approval.
          </RegularTextWithMargins>
        </FlexColumnCont100xAutoPercent>

        <FlexRowCont>
          <Icon45x45 src="/number1.svg"></Icon45x45>
          <SmallHeaderLeagueSpartanWidth100Percent>
            Click the "Start Revisions" button.
          </SmallHeaderLeagueSpartanWidth100Percent>
        </FlexRowCont>

        <FlexRowCont>
          <Icon45x45 src="/number2.svg"></Icon45x45>
          <SmallHeaderLeagueSpartanWidth100Percent>
            Adjust the quantity of the materials.
          </SmallHeaderLeagueSpartanWidth100Percent>
        </FlexRowCont>

        <FlexRowCont>
          <Icon45x45 src="/number3.svg"></Icon45x45>
          <SmallHeaderLeagueSpartanWidth100Percent>
            Click "Save" and your changes will be sent.
          </SmallHeaderLeagueSpartanWidth100Percent>
        </FlexRowCont>

        <FlexDivFlexEnd>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={CloseWindow}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton onClick={StartRevising}>
            Start Revisions
          </RegularResponsiveButton>
        </FlexDivFlexEnd>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

RevisionInstructionsWindow.defaultProps = {
  CloseWindow: () => {},
  ContinueRevising: () => {},
  StartRevising: () => {},
  RefreshProjectUI: () => {},
};

export default RevisionInstructionsWindow;
