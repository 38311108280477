import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  GetInvoice,
} from "../../API_Functions/CaseQuote_Functions";
import {
  MakeStripePay,
  SendPayNotification,
  SetHeaderAuthorization,
} from "../../API_Functions/Utility_Functions";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumn81vwLightGrey,
  FlexColumnCont90PercentLeftAlign,
  FlexColumnContAutoHeightLeftAlign,
  FlexColumnFullWidth,
  FlexRowCont,
  FlexRowCont60PercentSpaceEvenly,
  FlexRowContBoxShadow,
  FlexRowContFlexStart,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Div125px, Div175px } from "../../StylesheetComps/Div";
import { Icon120x120, Icon80x80 } from "../../StylesheetComps/Imgs/Icons";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { Cont, ContentContPropWidth } from "../../StylesheetComps/Projects";
import {
  LeagueSpartanRegular,
  SmallText,
  StyledHRGreyBreak,
} from "../../StylesheetComps/Titles";

const PaymentOptionsScreen = () => {
  const [etransferInfoDisplay, setEtransferInfoDisplay] = useState("none");
  const [chequeInfoDisplay, setChequeInfoDisplay] = useState("none");

  const history = useHistory();
  const params = useParams();

  function generateRandomToken(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const ProceedToStripePayment = async (amount, caseid, quoteid, invoiceid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      if (data.Case !== null) {
        var token = axios.defaults.headers.common["Authorization"];
        if (token) {
          var tokenRef = token.substring(6);
        } else {
          tokenRef = "am9zaHRlc3QxMDU6QWExMjM0NTY3OCQ=";
        }
        var name =
          data.Case.Name +
          ", " +
          data.Case.Street +
          "," +
          data.Case.City +
          " on Project # " +
          data.Case.CaseNumber;
        var transactionid = data.Case.CaseNumber;
        var paymenturl = await MakeStripePay(
          amount,
          "CAD",
          name,
          transactionid,
          quoteid,
          data.Case.Store_Id,
          tokenRef,
          invoiceid
        );
        if (paymenturl !== null) {
          window.open(paymenturl, "_blank");
        }
      }
    }
  };

  const UpdateEtransferInfoUI = async (display) => {
    switch (display) {
      case "none":
        setEtransferInfoDisplay("flex");
        break;
      case "flex":
        setEtransferInfoDisplay("none");
        break;
      default:
        setEtransferInfoDisplay("none");
    }
  };

  const UpdateChequeInfoUI = async (display) => {
    switch (display) {
      case "none":
        setChequeInfoDisplay("flex");
        break;

      case "flex":
        setChequeInfoDisplay("none");

        break;

      default:
        setChequeInfoDisplay("none");
    }
  };

  const ConfirmaPaymentMethod = async (
    method,
    amount,
    caseid,
    quoteid,
    invoiceid
  ) => {
    if (invoiceid === 0 || invoiceid === "0")
      await SendPayNotification(caseid, amount, method, "For deposit");
    else {
      var invoice = await GetInvoice(invoiceid);
      var notes = "For invoice number " + invoice.Number;
      await SendPayNotification(caseid, amount, method, notes);
    }

    history.push("/paymentpending/" + amount + "/" + caseid + "/" + quoteid);
  };

  useEffect(() => {
    SetHeaderAuthorization();
  }, []);

  return (
    <Cont>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <FlexColumn81vwLightGrey>
        <ActionMenu
          pageProp={"Proposal Payment Options"}
          projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
        ></ActionMenu>

        <ContentContPropWidth width="96%" height="auto">
          <FlexColumnCont
            justifycontent="space-evenly"
            width="92%"
            alignitems="baseline"
            height="95%"
          >
            <SmallHeaderLeagueSpartan>
              Select a Payment Method
            </SmallHeaderLeagueSpartan>

            <FlexRowContBoxShadow
              onClick={() => {
                ProceedToStripePayment(
                  params.amount,
                  params.caseId,
                  params.quoteId,
                  params.invoiceid
                );
              }}
            >
              <Icon120x120
                mobiledisplay="none"
                src="/Credit_Card_Icon.svg"
              ></Icon120x120>
              <FlexColumnContAutoHeightLeftAlign>
                <SmallHeaderLeagueSpartan>
                  Pay by Credit Card
                </SmallHeaderLeagueSpartan>
                <SmallHeader>
                  Pay through Stripe Payment using VISA, Mastercard, or American
                  Express.
                </SmallHeader>
              </FlexColumnContAutoHeightLeftAlign>
              <RegularResponsiveButton>Select</RegularResponsiveButton>
            </FlexRowContBoxShadow>

            {/* <FlexRowContBoxShadow
              onClick={() => {
                UpdateEtransferInfoUI(etransferInfoDisplay);
              }}
            >
              <Icon120x120
                mobiledisplay="none"
                src="/Etransfer_Icon.svg"
              ></Icon120x120>
              <FlexColumnContAutoHeightLeftAlign>
                <SmallHeaderLeagueSpartan>
                  Pay by E-Transfer
                </SmallHeaderLeagueSpartan>
                <SmallHeader>
                  Pay by e-transfer through your chosen financial institution.
                </SmallHeader>
              </FlexColumnContAutoHeightLeftAlign>
              <RegularResponsiveButton>Select</RegularResponsiveButton>
            </FlexRowContBoxShadow> */}

            {/* <FlexColumnContAutoHeightLeftAlign
              width="52%"
              display={etransferInfoDisplay}
            >
              <br></br>
              <FlexColumnCont90PercentLeftAlign justifycontent="flex-start">
                <br></br>
                <FlexRowContFlexStart>
                  <Icon80x80 src="/1_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign
                    margin="0"
                    alignitems="left"
                  >
                    <LeagueSpartanRegular>
                      Sign into your online bank account
                    </LeagueSpartanRegular>
                    <SmallText>
                      In a new window, log into your chosen financial
                      institution.
                    </SmallText>
                  </FlexColumnContAutoHeightLeftAlign>
                </FlexRowContFlexStart>
              </FlexColumnCont90PercentLeftAlign>

              <FlexColumnCont90PercentLeftAlign justifycontent="flex-start">
                <br></br>
                <FlexRowContFlexStart>
                  <Icon80x80 src="/2_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign
                    margin="0"
                    alignitems="left"
                  >
                    <LeagueSpartanRegular>
                      Select E-transfer and enter payment information
                    </LeagueSpartanRegular>
                    <SmallText>
                      It is <b> VERY IMPORTANT </b> to include either your
                      project name, phone number, or contact name in the message
                      box so we can identify which project your payment belongs
                      to.
                    </SmallText>
                  </FlexColumnContAutoHeightLeftAlign>
                </FlexRowContFlexStart>
                <br></br>
                <FlexColumnFullWidth>
                  <FlexRowCont60PercentSpaceEvenly>
                    <Div125px>
                      <SmallText>Name: </SmallText>
                    </Div125px>
                    <Div175px>
                      <SmallText>New Rhodes Construction </SmallText>
                    </Div175px>
                  </FlexRowCont60PercentSpaceEvenly>
                  <FlexRowCont60PercentSpaceEvenly>
                    <Div125px>
                      <SmallText>Email Address: </SmallText>
                    </Div125px>
                    <Div175px>
                      <SmallText>ar@newrhodesconstruction.com </SmallText>
                    </Div175px>
                  </FlexRowCont60PercentSpaceEvenly>

                  <FlexRowCont60PercentSpaceEvenly>
                    <Div125px>
                      <SmallText>Security Question: </SmallText>
                    </Div125px>
                    <Div175px>
                      <SmallText>What's our company name?</SmallText>
                    </Div175px>
                  </FlexRowCont60PercentSpaceEvenly>

                  <FlexRowCont60PercentSpaceEvenly>
                    <Div125px>
                      <SmallText>Security Answer: </SmallText>
                    </Div125px>
                    <Div175px>
                      <SmallText>New Rhodes Construction</SmallText>
                    </Div175px>
                  </FlexRowCont60PercentSpaceEvenly>
                </FlexColumnFullWidth>
              </FlexColumnCont90PercentLeftAlign>

              <br></br>
              <FlexColumnCont90PercentLeftAlign justifycontent="flex-start">
                <br></br>
                <FlexRowContFlexStart>
                  <Icon80x80 src="/3_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign alignitems="left">
                    <LeagueSpartanRegular>
                      Submit E-transfer and wait for confirmation.
                    </LeagueSpartanRegular>
                    <SmallText>
                      For most financial institutions, we offer AUTO-DEPOSIT.
                      Once we receive the funds, we will email you with payment
                      confirmation within 3 business days.
                    </SmallText>
                  </FlexColumnContAutoHeightLeftAlign>
                </FlexRowContFlexStart>
              </FlexColumnCont90PercentLeftAlign>
              <br></br>
              <FlexRowCont>
                <RegularResponsiveButton
                  onClick={() => {
                    ConfirmaPaymentMethod(
                      "E-transfer",
                      params.amount,
                      params.caseId,
                      params.quoteId,
                      params.invoiceid
                    );
                  }}
                >
                  Confirm Payment
                </RegularResponsiveButton>
              </FlexRowCont>
              <br></br>

              <StyledHRGreyBreak></StyledHRGreyBreak>
              <br></br>
            </FlexColumnContAutoHeightLeftAlign> */}

            {/* <FlexRowContBoxShadow
              onClick={() => {
                UpdateChequeInfoUI(chequeInfoDisplay);
              }}
            >
              <Icon120x120
                mobiledisplay="none"
                src="/Cheque_Icon.svg"
              ></Icon120x120>
              <FlexColumnContAutoHeightLeftAlign>
                <SmallHeaderLeagueSpartan>
                  Pay by Cheque
                </SmallHeaderLeagueSpartan>
                <SmallText>
                  Pay by issuing a cheque by mail, or dropping it off at our
                  head office.
                </SmallText>
              </FlexColumnContAutoHeightLeftAlign>
              <RegularResponsiveButton>Select</RegularResponsiveButton>
            </FlexRowContBoxShadow> */}
            <br></br>
            <br></br>
            {/* <FlexColumnContAutoHeightLeftAlign
              width="52%"
              display={chequeInfoDisplay}
            >
              <br></br>
              <FlexColumnCont90PercentLeftAlign
                alignitems="center"
                justifycontent="flex-start"
              >
                <br></br>
                <FlexRowContFlexStart>
                  <Icon80x80 src="/1_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign
                    margin="0"
                    alignitems="left"
                  >
                    <LeagueSpartanRegular>
                      Write Payment information on the cheque
                    </LeagueSpartanRegular>
                    <FlexRowCont60PercentSpaceEvenly>
                      <Div125px>
                        <SmallText>Date: </SmallText>
                      </Div125px>
                      <Div175px>
                        <SmallText>Current Date </SmallText>
                      </Div175px>
                    </FlexRowCont60PercentSpaceEvenly>

                    <FlexRowCont60PercentSpaceEvenly>
                      <Div125px>
                        <SmallText>Payee: </SmallText>
                      </Div125px>
                      <Div175px>
                        <SmallText>New Rhodes Construction </SmallText>
                      </Div175px>
                    </FlexRowCont60PercentSpaceEvenly>

                    <FlexRowCont60PercentSpaceEvenly>
                      <Div125px>
                        <SmallText>Amount: </SmallText>
                      </Div125px>
                      <Div175px>
                        <SmallText>Total Contracted Deposit Amount</SmallText>
                      </Div175px>
                    </FlexRowCont60PercentSpaceEvenly>

                    <FlexRowCont60PercentSpaceEvenly>
                      <Div125px>
                        <SmallText>Signature: </SmallText>
                      </Div125px>
                      <Div175px>
                        <SmallText>Your signature is required</SmallText>
                      </Div175px>
                    </FlexRowCont60PercentSpaceEvenly>

                    <FlexRowCont60PercentSpaceEvenly>
                      <Div125px>
                        <SmallText>Memo: </SmallText>
                      </Div125px>
                      <Div175px>
                        <SmallText>Address or Project Name</SmallText>
                      </Div175px>
                    </FlexRowCont60PercentSpaceEvenly>
                  </FlexColumnContAutoHeightLeftAlign>
                </FlexRowContFlexStart>
              </FlexColumnCont90PercentLeftAlign>

              <FlexColumnCont90PercentLeftAlign justifycontent="flex-start">
                <br></br>
                <FlexRowContFlexStart>
                  <Icon80x80 src="/2_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign alignitems="left">
                    <LeagueSpartanRegular>
                      Mail or drop off your cheque at our head office.
                    </LeagueSpartanRegular>
                    <SmallText>
                      If dropping off, please give us a call beforehand at +1
                      (778)-800-3598 to coordinate with our team.
                    </SmallText>
                    <br></br>
                    <FlexColumnContAutoHeightLeftAlign
                      alignitems="left"
                      justifycontent="flex-start"
                    >
                      <FlexRowCont60PercentSpaceEvenly>
                        <Div125px>
                          <SmallText>Head Office: </SmallText>
                        </Div125px>
                        <Div175px>
                          <SmallText>
                            12306 McTavish Road, Pitt Meadows B.C. V3Y 1Z1
                            Canada
                          </SmallText>
                        </Div175px>
                      </FlexRowCont60PercentSpaceEvenly>
                    </FlexColumnContAutoHeightLeftAlign>
                  </FlexColumnContAutoHeightLeftAlign>
                </FlexRowContFlexStart>
              </FlexColumnCont90PercentLeftAlign>

              <FlexColumnCont90PercentLeftAlign justifycontent="flex-start">
                <FlexRowContFlexStart>
                  <Icon80x80 src="/3_Icon.svg"></Icon80x80>
                  <FlexColumnContAutoHeightLeftAlign
                    margin="0"
                    alignitems="left"
                  >
                    <LeagueSpartanRegular>
                      Payment Confirmation
                    </LeagueSpartanRegular>
                    <SmallText>
                      Once we deposit the cheque, we will email you with payment
                      confirmation within 1-3 business days.
                    </SmallText>
                  </FlexColumnContAutoHeightLeftAlign>
                  <br></br>
                </FlexRowContFlexStart>
              </FlexColumnCont90PercentLeftAlign>
              <FlexRowCont>
                <RegularResponsiveButton
                  onClick={() => {
                    ConfirmaPaymentMethod(
                      "Cheque",
                      params.amount,
                      params.caseId,
                      params.quoteId,
                      params.invoiceid
                    );
                  }}
                >
                  Confirm Payment
                </RegularResponsiveButton>
              </FlexRowCont>
            </FlexColumnContAutoHeightLeftAlign> */}
          </FlexColumnCont>
        </ContentContPropWidth>
      </FlexColumn81vwLightGrey>
    </Cont>
  );
};

PaymentOptionsScreen.defaultProps = {};

export default PaymentOptionsScreen;
