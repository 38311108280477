import React from "react";
import QuoteTableTops from "../QuoteTableTops";
import { Cont100PercentMobile100Percent } from "../../StylesheetComps/Cont";
import {
  QuadContainer,
  QuoteQuadHead,
  QuoteQuadHeaderBorderTopLeft,
  QuoteQuadHeadMedium,
  QuoteQuadHeaderBorderTopRight,
} from "../../StylesheetComps/Div";
import QuoteTableDeliveryItems from "../QuoteTableDeliveryItems";
import { RegularText, SmallText } from "../../StylesheetComps/Titles";

const QuoteTableDelivery = ({
  quoteItems,
  quoteTotalsArray,
  subtotal,
  total,
  onItemChange,
}) => {
  return (
    <Cont100PercentMobile100Percent>
      <QuadContainer>
        <QuoteQuadHeaderBorderTopLeft>
          <SmallText>Name</SmallText>
        </QuoteQuadHeaderBorderTopLeft>

        <QuoteQuadHead>
          <SmallText>Load(s)</SmallText>
        </QuoteQuadHead>

        <QuoteQuadHeadMedium>
          <SmallText>Rate</SmallText>
        </QuoteQuadHeadMedium>

        <QuoteQuadHeadMedium>
          <SmallText>Price</SmallText>
        </QuoteQuadHeadMedium>

        <QuoteQuadHeaderBorderTopRight>
          <SmallText>Remove Item</SmallText>
        </QuoteQuadHeaderBorderTopRight>
      </QuadContainer>
      {quoteItems.map((o, i) => (
        <QuoteTableDeliveryItems
          key={i}
          toolobj={o}
          noChange={false}
          onItemChange={onItemChange}
          itemId={o.Id}
        ></QuoteTableDeliveryItems>
      ))}

      <QuoteTableTops
        subtotal={subtotal}
        quoteTax={quoteTotalsArray}
        total={total}
      ></QuoteTableTops>
    </Cont100PercentMobile100Percent>
  );
};

QuoteTableDelivery.defaultProps = {
  quoteItems: ["name"],
  quoteTotalsArray: ["name"],
  subtotal: "",
  total: "",
  onItemChange: () => {},
};

export default QuoteTableDelivery;
