import axios from 'axios';
 
 // get categories of a store with storeid.
 export const GetStoreCategories = async(storeid)=>{
    var resp = await axios.get(window.$DBURL+"catalog/store/"+ storeid);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
  }

  // get category info, subcategories and products of a category with id 
 export const GetCategory = async(catid)=>{
    var resp = await axios.get(window.$DBURL+"catalog/"+ catid);
    if(resp.status===200)
    {
        return resp.data.cate;
    }
    else
    {
        //display error message

        //and return null. 
        return null;
    }
  }

  //delete a category with category id
export const DeleteCategory = async(id)=>{
  var resp = await axios.delete(window.$DBURL+"catalog/"+ id);    
    if(resp.status === 200){
        return null
    }
}

//get product info by product id.
export const GetProduct = async(id)=>{
    var resp = await axios.get(window.$DBURL+"catalog/prod/"+ id);
    if(resp.status===200)
    {
        return resp.data.product;
    }
    else
    {
        //display error message

        //and return null. 
        return null;
    }
}

export const DeleteProduct = async(id)=>{
    await axios.delete(window.$DBURL+"catalog/prod/"+id);  
}  


// get categories of a store with storeid.
export const GetStoreProducts = async(storeid)=>{
    var resp = await axios.get(window.$DBURL+"catalog/prod/store/"+ storeid);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        //display error message 
        
        //and return null. 
        return null;
    }
  }

  // get tools of a store with storeid.
export const GetStoreTools = async(storeid)=>{
    var resp = await axios.get(window.$DBURL+"store/"+ storeid+'/tools');
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        //display error message 
        
        //and return null. 
        return null;
    }
  }

export const GetProductObject = async()=>{
    var product = await GetProduct(0);
    return product;
}

export const GetCategoryObject = async()=>{
    var cate = await GetCategory(0);
    return cate;
}

//add a new product
export const AddProduct = async(product)=>{
    var resp = await axios.post(window.$DBURL+"catalog/prod/add",{Product: product});
    if(resp.status===200)
    {
        return resp.data.product;
    }
    else
    {
        return null;
    }
}

export const UpdateProduct = async(product)=>{
    var resp = await axios.put(window.$DBURL+"catalog/prod/update", {Product: product});
    if(resp.status===200)
    {
        return resp.data.product;
    }
    else
    {
        return null;
    }
}

export const AddCategory = async(category)=>{
    var resp = await axios.post(window.$DBURL+"catalog/add",{Category: category});
    if(resp.status===200)
    {
        return resp.data.newcate;
    }
    else
    {
        return null;
    }
}

export const UpdateCategory = async(category)=>{
    var resp = await axios.put(window.$DBURL+"catalog/update",{Category: category});
    if(resp.status===200)
    {
        return resp.data.newcate;
    }
    else
    {
        return null;
    }
}

//add a new product
export const AddProductAddon = async(productid, cateid, position)=>{
    var resp = await axios.post(window.$DBURL+"catalog/product/" + productid + 
        "/addon/" + cateid + "/position/"+position);
    if(resp.status===200)
    {
        return resp.data.addons;
    }
    else
    {
        return null;
    }
}

export const UpdateProductAddon = async(productid, cateid, position)=>{
    var resp = await axios.put(window.$DBURL+"catalog/product/" + productid + 
        "/addon/" + cateid + "/position/"+position);
    if(resp.status===200)
    {
        return resp.data.addons;
    }
    else
    {
        return null;
    }
}

export const DeleteProductAddon = async(productid, cateid)=>{
    var resp = await axios.delete(window.$DBURL+"catalog/product/" + productid + 
        "/addon/" + cateid );
    if(resp.status===200)
    {
        return resp.data.addons;
    }
    else
    {
        return null;
    }
}
