import React from "react";
import { useHistory } from "react-router-dom";
import { FlexDiv, FlexRowContFullWidth } from "../../StylesheetComps/Cont";
import { Icon30x30 } from "../../StylesheetComps/Imgs/Icons";
import {
  BlueUnderlinePointerPara,
  RegularText,
} from "../../StylesheetComps/Titles";

const WorkerCard = ({ ManagerName, roleID, workerId, storeId }) => {
  const history = useHistory();

  return (
    <FlexRowContFullWidth>
      <FlexDiv>
        <RegularText>{ManagerName}</RegularText>
      </FlexDiv>

      <FlexDiv>
        <FlexDiv
          onClick={() => {
            history.push(
              "workerschedule/" + storeId + "/" + workerId + "/" + ManagerName
            );
          }}
        >
          <Icon30x30 src="/workercalendar.svg"></Icon30x30>
          <BlueUnderlinePointerPara>View Calendar</BlueUnderlinePointerPara>
        </FlexDiv>

        <FlexDiv
          onClick={() => {
            history.push(
              "clockschedule/" +
                storeId +
                "/" +
                workerId +
                "/" +
                roleID +
                "/" +
                ManagerName
            );
          }}
        >
          <Icon30x30 src="/workerclockrecords.svg"></Icon30x30>
          <BlueUnderlinePointerPara>
            View Clock Records
          </BlueUnderlinePointerPara>
        </FlexDiv>
      </FlexDiv>
    </FlexRowContFullWidth>
  );
};

WorkerCard.defaultProps = {
  ManagerName: "Default Name",
  roleID: 0,
  workerId: 0,
  storeId: 0,
};

export default WorkerCard;
