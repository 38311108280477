import RelatedProduct from "../../RelatedProduct.svg";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexCont10vw15vh, FlexDiv, FlexRowContFullWidth } from "../../StylesheetComps/Cont";
import { FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { Icon20x20, Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { RegularText, TitleFontSize100PercentPointer } from "../../StylesheetComps/Titles";

const ToolCard = ({ ToolHeadText, onView, relToolProd }) => {
  return (
    // <FlexCont10vw15vh>
    //   <TitleFontSize100PercentPointer onClick={onView}>
    //     {ToolHeadText}
    //   </TitleFontSize100PercentPointer>
    //   <Icon30x40pxPointer
    //     src={RelatedProduct}
    //     onClick={relToolProd}
    //   ></Icon30x40pxPointer>
    // </FlexCont10vw15vh>

<FlexRowContFullWidth hovermargin='5px' hoverbgcolor={ColorPalette.LightGrey}>
<FlexDivSpaceBetween width='90%'>
<FlexDiv width='30vw'>
  <Icon20x20 src='/Tools.svg' />
  <RegularText onClick={onView}>
           {ToolHeadText}
  </RegularText>
  </FlexDiv>
  <FlexDiv>
  <Icon30px30pxPointer src={RelatedProduct} onClick={relToolProd}></Icon30px30pxPointer>
  </FlexDiv>
  </FlexDivSpaceBetween>
  <hr></hr>
</FlexRowContFullWidth>
  );
};

ToolCard.defaultProps = {
  ToolHeadText: "Excavator",
  ToolParaText: "",
  ToolId: 0,
  ToolUrl:
    "https://cdn.app.compendium.com/uploads/user/a862d463-3ea6-443a-9187-e1040e91eb0e/cb33b26c-b911-43cf-9a4a-0406a7cd3abe/File/7d892f09940824516f16df249bd8bc25/excavator_blog_640x428.jpg",
};

export default ToolCard;
