import React, { useEffect } from 'react';

const CalendlyWidget = () => {
    useEffect(() => {
        // Create script element
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;

        // Append script to the body
        document.body.appendChild(script);

        // Cleanup function to remove script from the body on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div 
            className="calendly-inline-widget" 
            data-url="https://calendly.com/d/2kb-8wf-np4?hide_gdpr_banner=1&primary_color=169adb" 
            style={{ minWidth: "45%", height: "90vh", marginLeft:'20px', marginBottom:'20px', marginRight:'20px' }}
        ></div>
    );
};

export default CalendlyWidget;
