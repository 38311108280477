import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetTaxesForStore } from "../../../API_Functions/Store_Functions";
import { AddTool, GetToolObject } from "../../../API_Functions/Tool_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont85vwAutoHeight,
  FlexColumnFullWidth,
  FlexContCenterAlign,
  FlexDiv,
} from "../../../StylesheetComps/Cont";
import {
  Div175px,
  FlexColumnDiv,
  FlexDivMargins,
  GridContainer3Wide,
} from "../../../StylesheetComps/Div";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeBold,
  RegularText,
  SmallTitleBold,
  TinyTextItalics,
} from "../../../StylesheetComps/Titles";
import Input from "../../Input";

const NewToolWindow = ({ open, onClose, onNewTool }) => {
  const params = useParams();
  const [taxMap, setTaxMap] = useState([]);

  const [checkboxCheck] = useState(false);
  const [rentalRateInstructionDisplay, setRentalRateInstructionDisplay] =
    useState("none");

  const [toolObj, setToolObj] = useState({});

  const GetToolObjectStructure = async () => {
    var data = await GetToolObject();
    if (data !== null) {
      setToolObj(data);
    }
  };

  const SetTaxForStore = async (store_id) => {
    var tax = await GetTaxesForStore(store_id);

    if (tax != null) {
      setTaxMap(tax);
    }
  };

  const HandleTaxes = (taxid, taxcode, taxrate) => {
    const index = toolObj.Taxes.findIndex((o) => {
      if (o.TaxCode === taxcode) {
        return true;
      }

      return false;
    });

    if (index === -1) {
      toolObj.Taxes = [
        ...toolObj.Taxes,
        {
          Id: toolObj.Taxes.length,
          Tax_Id: taxid,
          TaxCode: taxcode,
          Rate: taxrate,
        },
      ];
    } else {
      toolObj.Taxes.splice(index, 1);
    }
  };

  const AddNewTool = async (toolobj) => {
    var data = await AddTool(toolobj);
    if (data !== null) {
      onNewTool();
      onClose();
    }
  };

  useEffect(() => {
    SetTaxForStore(params.Id);
    GetToolObjectStructure();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <Cont85vwAutoHeight>
        <FlexDiv>
          <FlexColumnFullWidth>
            <LeagueSpartanExtraLargeBold>{`New ${params.kitName} Tool`}</LeagueSpartanExtraLargeBold>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Tool Name</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Tool Name"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Name = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
            <FlexDivMargins>
              <FlexDiv>
                <SmallTitleBold>Rental Rate ($ per hour)</SmallTitleBold>
                <Icon30px30pxPointer
                  src="/questionmark.svg"
                  onClick={() => {
                    if (rentalRateInstructionDisplay === "none") {
                      setRentalRateInstructionDisplay("block");
                    } else if (rentalRateInstructionDisplay === "block") {
                      setRentalRateInstructionDisplay("none");
                    }
                  }}
                ></Icon30px30pxPointer>
              </FlexDiv>
              <FlexDiv>
                <Input
                  placeholder="Rental Rate"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.RentalRate = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
            <TinyTextItalics display={rentalRateInstructionDisplay}>
              Leave RentalRate blank or set to 0 for Trash bins(containers) or
              items whose charge isn't based on daily/hourly rate. Set it a
              number greater than 0.00001 if you want to count rental length but
              not cost.
            </TinyTextItalics>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>{"Min. Rental (Hours) "}</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder={"Minimum Rental "}
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.MinRental = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Move Cost Per Machine ($)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Move Cost Per Machine"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.MoveCost = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Speed (KM/HR)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Speed (KM/HR)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Speed = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDiv>
              <RegularResponsiveButton
                onClick={() => {
                  toolObj.CapUnit = "Cubic Feet";
                  toolObj.RUnit = "hour";
                  toolObj.SpeedUnit = "km/hr";
                  toolObj.ToolKit_Id = params.kitId;
                  toolObj.Store_Id = params.Id;
                  AddNewTool(toolObj);
                }}
              >
                Save
              </RegularResponsiveButton>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </FlexDiv>
          </FlexColumnFullWidth>
          <FlexColumnFullWidth>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Width (Feet)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Width (Feet)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Width = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Length (Feet)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Length (Feet)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Length = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Height (Feet)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Height (Feet)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Height = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Weight (LBS)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Weight (LBS)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Weight = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Capacity (Cubic Feet)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Capacity (Cubic Feet)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Capacity = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Load Time (Hours)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Load Time (Hours)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.LoadTime = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Unload Time (Hours)</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="Unload Time (Hours)"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.UnloadTime = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>
          </FlexColumnFullWidth>
          <FlexColumnFullWidth>
            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Number of Item in Stock</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="# of Tool in Stock"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.Stock = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Use with Obstruction</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  type="checkbox"
                  inputheight="20px"
                  inputwidth="20%"
                  onChange={(e) => {
                    toolObj.UseWithObstruct = e.target.checked;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <FlexDivMargins>
              <Div175px>
                <SmallTitleBold>Tool Video YouTube URL</SmallTitleBold>
              </Div175px>
              <FlexDiv>
                <Input
                  placeholder="YouTube URL"
                  inputheight="10px"
                  inputwidth="60%"
                  onChange={(e) => {
                    toolObj.VideoUrl = e.target.value;
                  }}
                ></Input>
              </FlexDiv>
            </FlexDivMargins>

            <GridContainer3Wide>
              <FlexColumnDiv>
                {taxMap.map((o, i) => (
                  <FlexContCenterAlign key={i}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        HandleTaxes(o.Id, o.TaxCode, o.Rate);
                      }}
                      checked={checkboxCheck[i]}
                    ></input>
                    <RegularText>
                      {o.TaxCode} {o.Rate}%
                    </RegularText>
                  </FlexContCenterAlign>
                ))}
              </FlexColumnDiv>
            </GridContainer3Wide>
          </FlexColumnFullWidth>
        </FlexDiv>
      </Cont85vwAutoHeight>
    </FixedOverlay200vh>
  );
};

NewToolWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onNewTool: () => {},
};

export default NewToolWindow;
