import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddQuote,
  GetCaseById,
  GetQuoteById,
  GetQuoteObject,
} from "../../API_Functions/CaseQuote_Functions";
import { GetCurrentTime } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import AccessAreaQuote from "../../comps/Quote Form/AccessArea";
import ScrollToTop from "../../comps/ScrollToTop";
import {
  FlexColumnCont80vwAutoHeight,
  FlexCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";

const QuoteScreen = () => {
  //=============================================================================
  // QuoteScreen - This page contains all steps to create a quote by Managers/Est.
  //=============================================================================

  //=============================================================================
  // Composed of AccessArea, Material Selection
  //=============================================================================
  const history = useHistory();
  const [quoteObj, setQuoteObj] = useState({});

  const params = useParams();

  const [accessAreaOpen, setAccessAreaOpen] = useState(false);

  const [quoteId, setQuoteId] = useState(0);

  const [grandTotal] = useState(0);

  const HandleAccessForm = async (accessObj) => {
    quoteObj.AccessArea.Obstruct = accessObj.Obstruct;
    quoteObj.AccessArea.Width = accessObj.Width;
    quoteObj.AccessArea.Length = accessObj.Length;
    quoteObj.AccessArea.Elevation = accessObj.Elevation;
    quoteObj.AccessArea.Items = accessObj.Items;
    AddNewQuote(quoteObj, params.caseId);
  };

  const AddNewQuote = async (quoteobj, caseid) => {
    var addtime = await GetCurrentTime();
    var proposal = await GetNewQuoteProposalNumber(caseid);
    quoteobj.Case_Id = caseid;
    quoteobj.AddTime = addtime;
    quoteobj.Proposal = proposal;
    quoteobj.AccessArea.Case_Id = caseid;
    var data = await AddQuote(quoteobj);
    if (data !== null) {
      setQuoteId(data.quote.Id);
      setQuoteObj(data.quote);
      window.scrollTo(0, 0);
      history.push(
        `/viewquote/${params.Id}/${params.storeId}/${params.caseId}/${data.quote.Id}/0`
      );
    }
  };

  const GetNewQuoteProposalNumber = async (caseid) => {
    var data = await GetCaseById(caseid);
    if (data != null) {
      if (data.Case?.Quotes?.length > 0) {
        var proposal = 0;
        data.Case.Quotes.forEach((element) => {
          if (element.Proposal > proposal) proposal = element.Proposal;
        });
        proposal++;
        return proposal;
      } else return 0;
    } else return 0;
  };

  const GetQuoteInfo = async (quoteid) => {
    var data = await GetQuoteById(quoteid);
    if (data !== null) {
      setQuoteObj(data.quote);
    }
  };

  const GetBlankQuoteObj = async () => {
    var data = await GetQuoteObject();
    if (data !== null) {
      setQuoteObj(data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetBlankQuoteObj();
  }, []);

  return (
    <FlexCont100vw100vh>
      <ScrollToTop />
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <AccessAreaQuote
            quoteid={quoteId}
            ReturnToQuote={() => {
              GetQuoteInfo(quoteId);
              setAccessAreaOpen(true);
              window.scrollTo(0, 0);
            }}
            onPreview={HandleAccessForm}
            open={accessAreaOpen}
          ></AccessAreaQuote>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

QuoteScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default QuoteScreen;
