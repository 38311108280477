import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Navigate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import * as dates from "date-arithmetic";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import { subDays } from "date-fns";
import DeleteProduct from "../Windows/DeleteProduct";
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";
import { BlueUnderlinePointerPara } from "../../StylesheetComps/Titles";
import { FlexContCenterAlign } from "../../StylesheetComps/Cont";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const ItemCont = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 30%;
  width: 500px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  align-items: center;
  justify-content: center;
`;

const AppointmentCreationDiv = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
`;

const SubHeaderPara = styled.p`
  font-family: Futura;
  font-size: 16px;
  width: 170px;
  display: ${(props) => (props.textDisplay ? props.textDisplay : "block")};
`;

const ButtonDiv = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
`;

const InputEdit = styled.input`
  width: 80%;
  height: 40%;
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
  margin-right: 2%;
`;

const TextAreaEdit = styled.textarea`
  width: 60%;
  height: 20vh;
  background-color: #ededed;
  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  font-family: Futura;
  font-size: 16px;
  border-radius: 4px;
  padding: 10px;
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: auto;
`;

const DatePickDiv = styled.div`
  display: ${(props) => (props.datedisplay ? props.datedisplay : "none")};
`;

const TimeSlotDiv = styled.div`
  display: ${(props) =>
    props.timeslotDisplay ? props.timeslotDisplay : "none"};
`;

const InputDivsTime = styled.div`
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
  justify-content: flex-start;
  width: 100%;
`;

const InputClosedDays = styled.input`
  margin: 10px;
  // width:10%;
  height: 30px;
`;

const InputDataList = styled.datalist``;

const InputOption = styled.option``;

const CloseDayInputText = styled.p`
  font-family: Futura;
  font-size: 18px;
  width: 50px;
  font-weight: bold;
`;

const CancelAppointmentText = styled.p`
  font-size: 14px;
  color: red;
  position: relative;
  cursor: pointer;
`;

const EditSchedule = ({
  open,
  onClose,
  onPreview,
  onScheduleEdit,
  DeleteSchedule,
  month,
  day,
  year,
  dfName,
  dfNotes,
  dfAddress,
  dfCellPhone,
  dfCity,
  dfFlex,
  startHour,
  endHour,
  startMinute,
  endMinute,
}) => {
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");

  const [timeSlotDisplayProp] = useState("flex");
  const [openHourSelected, setOpenHourSelected] = useState(0);
  const [openMinuteSelected, setOpenMinuteSelected] = useState(0);
  const [closeHourSelected, setCloseHourSelected] = useState(0);
  const [closeMinuteSelected, setCloseMinuteSelected] = useState(0);

  const [appDesc, setAppDesc] = useState("");

  const [notesDisplay, setNotesDisplay] = useState("none");
  const [nameDisplay, setNameDisplay] = useState("none");
  const [addressDisplay, setAddressDisplay] = useState("none");
  const [cityDisplay, setCityDisplay] = useState("none");
  const [phoneDisplay, setPhoneDisplay] = useState("none");

  const [timeslotDisplay, setTimeslotDisplay] = useState("none");

  const [notesDisplayText, setNotesDisplayText] = useState("block");
  const [nameDisplayText, setNameDisplayText] = useState("block");
  const [addressDisplayText, setAddressDisplayText] = useState("block");
  const [cityDisplayText, setCityDisplayText] = useState("block");
  const [phoneDisplayText, setPhoneDisplayText] = useState("block");

  const [timeTextDisplay, setTimeTextDisplay] = useState("block");

  const [edit1, setEdit1] = useState("Edit");
  const [edit2, setEdit2] = useState("Edit");
  const [edit3, setEdit3] = useState("Edit");
  const [edit4, setEdit4] = useState("Edit");
  const [edit5, setEdit5] = useState("Edit");
  const [editTime, setEditTime] = useState("Edit");

  const [defaultNotes, setDefaultNotes] = useState(dfNotes);
  const [defaultContact, setDefaultContact] = useState(dfName);
  const [defaultAddress, setDefaultAddress] = useState(dfAddress);
  const [defaultCity, setDefaultCity] = useState(dfCity);
  const [defaultPhone, setDefaultPhone] = useState(dfCellPhone);

  const [defaultStartHour, setDefaultStartHour] = useState(startHour);
  const [defaultEndHour, setDefaultEndHour] = useState(endHour);
  const [defaultStartMinute, setDefaultStartMinute] = useState(startMinute);
  const [defaultEndMinute, setDefaultEndMinute] = useState(endMinute);

  const [defaultMonth, setDefaultMonth] = useState(month);
  const [defaultDay, setDefaultDay] = useState(day);
  const [defaultYear, setDefaultYear] = useState(year);

  class MyWeek extends React.Component {
    render() {
      let {
        date,
        localizer,
        min = localizer.startOf(new Date(), "day"),
        max = localizer.endOf(new Date(), "day"),
        scrollToTime = localizer.startOf(new Date(), "day"),
      } = this.props;
      let range = MyWeek.range(date, { localizer });

      return (
        <TimeGrid
          {...this.props}
          range={range}
          eventOffset={15}
          localizer={localizer}
          min={min}
          max={max}
          scrollToTime={scrollToTime}
        />
      );
    }
  }

  MyWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.any,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  MyWeek.range = (date, { localizer }) => {
    let start = date;
    let end = dates.add(start, 0, "day");

    let current = start;
    let range = [];

    while (localizer.lte(current, end, "day")) {
      range.push(current);
      current = localizer.add(current, 1, "day");
    }

    return range;
  };

  MyWeek.navigate = (date, action, { localizer }) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return localizer.add(date, -1, "day");

      case Navigate.NEXT:
        return localizer.add(date, 1, "day");

      default:
        return date;
    }
  };

  MyWeek.title = (date) => {
    return `Installer Appointment Slots: ${date.toLocaleDateString()}`;
  };

  const [EditYear, setEditYear] = useState(0);
  const [EditMonth, setEditMonth] = useState(0);
  const [EditDay, setEditDay] = useState(0);
  const [startDate, setStartDate] = useState(null);
  var startDateSync = null;

  const HandleStartDate = () => {
    setEditDay(startDateSync.getDate());
    setEditMonth(startDateSync.getMonth() + 1);
    setEditYear(startDateSync.getFullYear());
  };

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  const [startMinuteParam, setStartMinuteParam] = useState("");
  const [endMinuteParam, setEndMinuteParam] = useState("");

  const CheckTimes = async () => {
    if (startMinute === 0 && endMinute !== 0) {
      setStartMinuteParam(startMinute + "0");
    } else if (startMinute !== 0 && endMinute === 0) {
      setEndMinuteParam(endMinute + "0");
    } else if (startMinute === 0 && endMinute === 0) {
      setStartMinuteParam(startMinute + "0");
      setEndMinuteParam(endMinute + "0");
    } else {
      setStartMinuteParam(startMinute);
      setEndMinuteParam(endMinute);
    }
  };

  useEffect(() => {
    CheckTimes();
  }, [startMinute, endMinute]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultNotes(dfNotes);
    setDefaultContact(dfName);
    setDefaultAddress(dfAddress);
    setDefaultCity(dfCity);
    setDefaultPhone(dfCellPhone);
  }, [dfNotes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultContact(dfName);
  }, [dfName]);

  useEffect(() => {
    setDefaultAddress(dfAddress);
  }, [dfAddress]);

  useEffect(() => {
    setDefaultCity(dfCity);
  }, [dfCity]);

  useEffect(() => {
    setDefaultPhone(dfCellPhone);
  }, [dfCellPhone]);

  useEffect(() => {
    setDefaultStartHour(startHour);
  }, [startHour]);

  useEffect(() => {
    setDefaultStartMinute(startMinute);
  }, [startMinute]);

  useEffect(() => {
    setDefaultEndHour(endHour);
  }, [endHour]);

  useEffect(() => {
    setDefaultEndMinute(endMinute);
  }, [endMinute]);

  useEffect(() => {
    setDefaultMonth(month);
  }, [month]);

  useEffect(() => {
    setDefaultDay(day);
  }, [day]);

  useEffect(() => {
    setDefaultYear(year);
  }, [year]);

  const [cancelSchedule, setCancelSchedule] = useState(false);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <ItemCont>
        <AppointmentCreationDiv>
          <DeleteProduct
            deleteOpen={cancelSchedule}
            closeDel={() => {
              setCancelSchedule(false);
            }}
            onDeleteFunction={() => {
              setCancelSchedule(false);
              onClose();
              DeleteSchedule();
            }}
          ></DeleteProduct>
          <CancelAppointmentText
            onClick={() => {
              setCancelSchedule(true);
            }}
          >
            Delete Schedule
          </CancelAppointmentText>
          <FlexContCenterAlign>
            <SubHeaderPara>Schedule Date/Time</SubHeaderPara>
            <HeaderDiv>
              <SubHeaderPara textDisplay={timeTextDisplay}>
                {month}/{day}/{year}
              </SubHeaderPara>
              <SubHeaderPara textDisplay={timeTextDisplay}>
                {startHour +
                  ":" +
                  startMinuteParam +
                  " - " +
                  endHour +
                  ":" +
                  endMinuteParam}
              </SubHeaderPara>
              <BlueUnderlinePointerPara
                onClick={() => {
                  if (editTime === "Edit") {
                    setTimeslotDisplay("block");
                    setTimeTextDisplay("block");
                    setEditTime("Save");
                  } else {
                    setTimeslotDisplay("none");
                    setTimeTextDisplay("block");
                    setEditTime("Edit");
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                      openHourSelected,
                      openMinuteSelected,
                      closeHourSelected,
                      closeMinuteSelected,
                    });
                    onScheduleEdit(
                      contactName,
                      address,
                      phone,
                      city,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                      openHourSelected,
                      openMinuteSelected,
                      closeHourSelected,
                      closeMinuteSelected
                    );
                  }
                }}
              >
                {editTime}
              </BlueUnderlinePointerPara>
            </HeaderDiv>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <TimeSlotDiv timeslotDisplay={timeslotDisplay}>
              <SubHeaderPara>Change Time to:</SubHeaderPara>

              <InputDivsTime
                inputdisplay={timeSlotDisplayProp}
                key={defaultStartHour}
              >
                <CloseDayInputText>From:</CloseDayInputText>
                <InputClosedDays
                  defaultValue={defaultStartHour}
                  type="text"
                  maxLength="2"
                  list="openhours"
                  onChange={(e) => {
                    setOpenHourSelected(e.target.value);
                  }}
                />
                <InputDataList id="openhours">
                  <InputOption value="1"></InputOption>
                  <InputOption value="2"></InputOption>
                  <InputOption value="3"></InputOption>
                  <InputOption value="4"></InputOption>
                  <InputOption value="5"></InputOption>
                  <InputOption value="6"></InputOption>
                  <InputOption value="7"></InputOption>
                  <InputOption value="8"></InputOption>
                  <InputOption value="9"></InputOption>
                  <InputOption value="10"></InputOption>
                  <InputOption value="11"></InputOption>
                  <InputOption value="12"></InputOption>
                  <InputOption value="13"></InputOption>
                  <InputOption value="14"></InputOption>
                  <InputOption value="15"></InputOption>
                  <InputOption value="16"></InputOption>
                  <InputOption value="17"></InputOption>
                  <InputOption value="18"></InputOption>
                  <InputOption value="19"></InputOption>
                  <InputOption value="20"></InputOption>
                  <InputOption value="21"></InputOption>
                  <InputOption value="22"></InputOption>
                  <InputOption value="23"></InputOption>
                  <InputOption value="24"></InputOption>
                </InputDataList>
                <div key={defaultStartMinute}>
                  <InputClosedDays
                    defaultValue={defaultStartMinute}
                    type="text"
                    maxLength="2"
                    list="openminutes"
                    onChange={(e) => {
                      setOpenMinuteSelected(e.target.value);
                    }}
                  />
                </div>
                <InputDataList id="openminutes"></InputDataList>
              </InputDivsTime>

              <InputDivsTime
                inputdisplay={timeSlotDisplayProp}
                key={defaultEndHour}
              >
                <CloseDayInputText>To:</CloseDayInputText>
                <InputClosedDays
                  defaultValue={defaultEndHour}
                  type="text"
                  maxLength="2"
                  list="closehours"
                  onChange={(e) => {
                    setCloseHourSelected(e.target.value);
                  }}
                />
                <InputDataList id="closehours">
                  <InputOption value="1"></InputOption>
                  <InputOption value="2"></InputOption>
                  <InputOption value="3"></InputOption>
                  <InputOption value="4"></InputOption>
                  <InputOption value="5"></InputOption>
                  <InputOption value="6"></InputOption>
                  <InputOption value="7"></InputOption>
                  <InputOption value="8"></InputOption>
                  <InputOption value="9"></InputOption>
                  <InputOption value="10"></InputOption>
                  <InputOption value="11"></InputOption>
                  <InputOption value="12"></InputOption>
                  <InputOption value="13"></InputOption>
                  <InputOption value="14"></InputOption>
                  <InputOption value="15"></InputOption>
                  <InputOption value="16"></InputOption>
                  <InputOption value="17"></InputOption>
                  <InputOption value="18"></InputOption>
                  <InputOption value="19"></InputOption>
                  <InputOption value="20"></InputOption>
                  <InputOption value="21"></InputOption>
                  <InputOption value="22"></InputOption>
                  <InputOption value="23"></InputOption>
                  <InputOption value="24"></InputOption>
                </InputDataList>

                <div key={defaultEndMinute}>
                  <InputClosedDays
                    defaultValue={defaultEndMinute}
                    type="text"
                    maxLength="2"
                    list="closeminutes"
                    onChange={(e) => {
                      setCloseMinuteSelected(e.target.value);
                    }}
                  />
                </div>
                <InputDataList id="closeminutes"></InputDataList>
              </InputDivsTime>
            </TimeSlotDiv>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <TimeSlotDiv timeslotDisplay={timeslotDisplay}>
              <SubHeaderPara>Change Date to:</SubHeaderPara>
              <DatePickDiv datedisplay={timeslotDisplay} key={defaultDay}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    startDateSync = date;
                    HandleStartDate();
                  }}
                  dateFormat="MM/dd/yyyy"
                  minDate={subDays(new Date(), 0)}
                  timeClassName={handleColor}
                  placeholderText={
                    defaultMonth + "/" + defaultDay + "/" + defaultYear
                  }
                  showMonthDropdown
                ></DatePicker>
              </DatePickDiv>
            </TimeSlotDiv>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Schedule Description</SubHeaderPara>
            <SubHeaderPara textDisplay={notesDisplayText}>
              {dfNotes}
            </SubHeaderPara>
            <div key={defaultNotes}>
              <TextAreaEdit
                defaultValue={defaultNotes}
                inputdisplay={notesDisplay}
                onChange={(e) => {
                  setAppDesc(e.target.value);
                }}
              ></TextAreaEdit>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit1 === "Edit") {
                  setNotesDisplay("block");
                  setNotesDisplayText("none");
                  setEdit1("Save");
                } else {
                  setNotesDisplay("none");
                  setNotesDisplayText("block");
                  setEdit1("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected,
                  });
                  onScheduleEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected
                  );
                }
              }}
            >
              {edit1}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Contact Name</SubHeaderPara>
            <SubHeaderPara textDisplay={nameDisplayText}>
              {dfName}
            </SubHeaderPara>
            <div key={defaultContact}>
              <InputEdit
                defaultValue={defaultContact}
                inputdisplay={nameDisplay}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              ></InputEdit>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit2 === "Edit") {
                  setNameDisplay("block");
                  setNameDisplayText("none");
                  setEdit2("Save");
                } else {
                  setNameDisplay("none");
                  setNameDisplayText("block");
                  setEdit2("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected,
                  });
                  onScheduleEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected
                  );
                }
              }}
            >
              {edit2}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Address</SubHeaderPara>
            <SubHeaderPara textDisplay={addressDisplayText}>
              {dfAddress}
            </SubHeaderPara>
            <div key={defaultAddress}>
              <InputEdit
                defaultValue={defaultAddress}
                inputdisplay={addressDisplay}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              ></InputEdit>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit3 === "Edit") {
                  setAddressDisplay("block");
                  setAddressDisplayText("none");
                  setEdit3("Save");
                } else {
                  setAddressDisplay("none");
                  setAddressDisplayText("block");
                  setEdit3("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected,
                  });
                  onScheduleEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected
                  );
                }
              }}
            >
              {edit3}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>City</SubHeaderPara>
            <SubHeaderPara textDisplay={cityDisplayText}>
              {dfCity}
            </SubHeaderPara>
            <div key={defaultCity}>
              <InputEdit
                defaultValue={defaultCity}
                inputdisplay={cityDisplay}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              ></InputEdit>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit4 === "Edit") {
                  setCityDisplay("block");
                  setCityDisplayText("none");
                  setEdit4("Save");
                } else {
                  setCityDisplay("none");
                  setCityDisplayText("block");
                  setEdit4("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected,
                  });
                  onScheduleEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected
                  );
                }
              }}
            >
              {edit4}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Cell Phone</SubHeaderPara>
            <SubHeaderPara textDisplay={phoneDisplayText}>
              {dfCellPhone}
            </SubHeaderPara>
            <div key={defaultPhone}>
              <InputEdit
                defaultValue={defaultPhone}
                inputdisplay={phoneDisplay}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></InputEdit>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit5 === "Edit") {
                  setPhoneDisplay("block");
                  setPhoneDisplayText("none");
                  setEdit5("Save");
                } else {
                  setPhoneDisplay("none");
                  setPhoneDisplayText("block");
                  setEdit5("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected,
                  });
                  onScheduleEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                    openHourSelected,
                    openMinuteSelected,
                    closeHourSelected,
                    closeMinuteSelected
                  );
                }
              }}
            >
              {edit5}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <ButtonDiv>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setNotesDisplay("none");
                setNameDisplay("none");
                setAddressDisplay("none");
                setCityDisplay("none");
                setPhoneDisplay("none");
                setNotesDisplayText("block");
                setNameDisplayText("block");
                setAddressDisplayText("block");
                setCityDisplayText("block");
                setPhoneDisplayText("block");
                setEdit1("Edit");
                setEdit2("Edit");
                setEdit3("Edit");
                setEdit4("Edit");
                setEdit5("Edit");
                setTimeslotDisplay("none");
                setTimeTextDisplay("block");
                setEditTime("Edit");
                setPhone("");
                setCity("");
                setAddress("");
                setContactName("");
                setAppDesc("");
                setOpenHourSelected(0);
                setOpenMinuteSelected(0);
                setCloseHourSelected(0);
                setCloseMinuteSelected(0);
                setEditMonth(0);
                setEditYear(0);
                setEditDay(0);
              }}
            >
              Close
            </RegularResponsiveButton>
          </ButtonDiv>
        </AppointmentCreationDiv>
      </ItemCont>
    </FixedOverlay200vh>
  );
};

EditSchedule.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onScheduleEdit: () => {},
  CancelAppointment: () => {},
  month: 0,
  year: 0,
  day: 0,
  dfName: "",
  dfAddress: "",
  dfCity: "",
  dfCellPhone: "",
  dfFlex: false,
  dfNotes: "",
  installerId: 0,
  appointmentTime: "",
  startHour: 0,
  endHour: 0,
  startMinute: 0,
  endMinute: 0,
};

export default EditSchedule;
