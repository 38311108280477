import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LargeResponsiveButton,
  TextInput300x50,
} from "../../StylesheetComps/AuthSheet";
import { FlexColumnFullWidth } from "../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../StylesheetComps/Div";
import { Image200x200pxContain } from "../../StylesheetComps/Imgs/Images";
import { SmallHeader } from "../../StylesheetComps/Menu";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

const AcceptOrderScreen = () => {
  const params = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [headerText, setHeaderText] = useState(
    "Please enter your credentials to accept this order:"
  );
  const [inputDivDisplay, setInputDivDisplay] = useState("flex");

  const GetSessionInfo = async () => {
    const resp = await axios.get(window.$DBURL + "users/profile");
    sessionStorage.setItem("userInfo", JSON.stringify(resp.data.User));
  };

  const HandleAccept = async (quoteid, itemid, supplierId) => {
    const resp = await axios.post(
      window.$DBURL +
        "order/quote/" +
        quoteid +
        "/item/" +
        itemid +
        "/supplier/" +
        supplierId +
        "/accept"
    );
    if (resp.status === 200) {
      setHeaderText("Thank you for accepting this quote!");
      setInputDivDisplay("none");
    } else {
      setError("There was an error accepting this quote");
    }
  };

  //Log supplier in, if authenticated, accept the order

  const GetAuthToken = async () => {
    var resp = await axios
      .post(window.$DBURL + "users/session/login", {
        Username: username,
        Password: password,
      })
      .catch(handleErrors);
    if (resp == null) {
      resp = null;
    } else if (resp.status === 200) {
      const token = resp.data.Session.AuthToken;
      axios.defaults.baseURL = window.$DBURL;
      sessionStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "Basic " + token;
      HandleAccept(params.quoteid, params.itemid, resp.data.User.Id);
    } else {
      setError("The info you entered is incorrect");
    }
    function handleErrors(err) {
      if (err.request) {
        setError("Incorrect Username or Password");
      } else if (err.response) {
        setError("Incorrect Username or Password");
      }
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  return (
    <FlexDivCenter>
      <FlexColumnFullWidth>
        <FlexColumnFullWidth>
          <Image200x200pxContain src="/newrhodeslogo.svg"></Image200x200pxContain>
          <LeagueSpartanExtraLargeBold>
            {headerText}
          </LeagueSpartanExtraLargeBold>
          <FlexColumnFullWidth display={inputDivDisplay}>
            <RegularTextBoldRed>{error}</RegularTextBoldRed>
            <SmallHeader>Username</SmallHeader>
            <TextInput300x50
              placeholder="Username"
              inputheight="2%"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            ></TextInput300x50>
            <SmallHeader>Password</SmallHeader>
            <TextInput300x50
              type="password"
              placeholder="Password"
              inputheight="2%"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></TextInput300x50>
            <LargeResponsiveButton
              onClick={() => {
                GetAuthToken();
              }}
            >
              Accept Order
            </LargeResponsiveButton>
          </FlexColumnFullWidth>
        </FlexColumnFullWidth>
      </FlexColumnFullWidth>
    </FlexDivCenter>
  );
};

AcceptOrderScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default AcceptOrderScreen;
