import React from "react";
import {
  FlexDiv,
  FlexRowCont70PercentSpaceBetween,
} from "../../StylesheetComps/Cont";
import { Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import { CaseCardCont } from "../../StylesheetComps/Projects";
import { RegularText } from "../../StylesheetComps/Titles";
import CaseCard from "../CaseCard";

const ManagerCard = ({ ManagerName, onDel, Email, Phone }) => {
  return (
    <CaseCardCont width="79%">
      <FlexDiv width="500px">
        <RegularText>{ManagerName}</RegularText>
      </FlexDiv>
      <FlexDiv width="500px">
        <RegularText>{Email}</RegularText>
      </FlexDiv>
      <FlexDiv width="500px">
        <RegularText>{Phone}</RegularText>
      </FlexDiv>
      <FlexDiv width="50px">
        <Icon30x40pxPointer
          src="/Garbage_Icon.svg"
          onClick={onDel}
        ></Icon30x40pxPointer>
      </FlexDiv>
    </CaseCardCont>
  );
};

ManagerCard.defaultProps = {
  ManagerName: "Default Name",
  StoreLocationName: "123 Yonge Street",
  roleID: 0,
  MetroName: "Toronto",
  Username: "",
  Email: "",
  Phone: "",
};

export default ManagerCard;
