import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Cont185pxBoxShadowCenter,
  FlexColumnCont98xAutoFlexEnd,
  FlexContCenterAlign,
  FlexDiv,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import { FlexColumnDivAutoMargin, FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { Icon20x20, Icon30x30 } from "../../StylesheetComps/Imgs/Icons";
import { Cont100PercentWidth } from "../../StylesheetComps/Quote";
import { RegularText, SmallTitleBold } from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { ColorPalette } from "../../StylesheetComps/Colors";

const ToolkitCard = ({ KitHeadText, KitUrl, EditKit, DelKit, kitId }) => {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    GetSessionInfo();
  }, []);

  const [roleNum, setRoleNum] = useState(1000);
  const [storeId, setStoreId] = useState(0);
  const GetSessionInfo = async () => {
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
    setStoreId(JSON.parse(sessionStorage.getItem("userInfo")).Store_Id);
  };

  if (roleNum === UserRole.Admin || roleNum === UserRole.Manager) {
    return (
      // <Cont185pxBoxShadowCenter>
      //   <FlexContCenterAlign>
      //     <SmallTitleBold
      //       onClick={() => {
      //         history.push(
      //           "/toollist/" + storeId + "/" + kitId + "/" + KitHeadText
      //         );
      //       }}
      //     >
      //       {" "}
      //       {KitHeadText}
      //     </SmallTitleBold>
      //     <FlexColumnCont98xAutoFlexEnd>
      //       <FlexColumnDivAutoMargin>
      //         <Icon30x30 src={"/EditIcon.svg"} onClick={EditKit}></Icon30x30>
      //         <Icon30x30 src={"/Garbage_Icon.svg"} onClick={DelKit}></Icon30x30>
      //       </FlexColumnDivAutoMargin>
      //     </FlexColumnCont98xAutoFlexEnd>
      //   </FlexContCenterAlign>
      //   <Cont100PercentWidth></Cont100PercentWidth>
      // </Cont185pxBoxShadowCenter>
      <FlexRowContFullWidth hovermargin='5px' hoverbgcolor={ColorPalette.LightGrey}>
      <FlexDivSpaceBetween width='90%'>
      <FlexDiv width='30vw'>
        <Icon20x20 src='/Tools.svg' />
        <RegularText        onClick={() => {
              history.push(
                "/toollist/" + storeId + "/" + kitId + "/" + KitHeadText
              );
            }}>
            {KitHeadText}
        </RegularText>
        </FlexDiv>
        <FlexDiv>
        <Icon30x30 src={"/EditIcon.svg"} onClick={EditKit}></Icon30x30>
               <Icon30x30 src={"/Garbage_Icon.svg"} onClick={DelKit}></Icon30x30>
        </FlexDiv>
        </FlexDivSpaceBetween>
        <hr></hr>
      </FlexRowContFullWidth>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ToolkitCard.defaultProps = {
  KitHeadText: "Tool Kit Default Name",
  KitParaText: "Default Text for a Tool Kit",
  KitButtonText: "View Tools",
  KitUrl: "",
  kitId: 0,
};

export default ToolkitCard;
