/* eslint-disable */

import { useEffect, useState } from "react";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnCont100xAutoPercent,
  QuoteItemCont,
} from "../../StylesheetComps/Cont";
import {
  FlexibleEstimateDiv15Percent,
  FlexibleEstimateDiv35Percent,
  FlexibleEstimateDiv5Percent,
  GreyFlexibleEstimateDiv15Percent,
} from "../../StylesheetComps/Div";
import { Icon20x20 } from "../../StylesheetComps/Imgs/Icons";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { SmallGreenLeagueText } from "../../StylesheetComps/Titles";
const InvoiceToolItem = ({
  onClick,
  header1,
  header2,
  subheader2notes,
  subheader2,
  header3,
  header4,
  header5,
  header6,
  header7,
  header8,
  quoteItemObj,
  invoiceTypeName,
  noChange,
  OutputValue,
  RemoveItemFromLocalQuoteObject,
  parentid,
  itemLink,
  bgColor,
  isHeader,
  showRelatedItems,
  createInvoice,
}) => {
  // const [containerPadding, setContainerPadding] = useState("");

  const [header1Display, setHeader1Display] = useState("none");
  const [header2Display, setHeader2Display] = useState("flex");
  const [subheader2Display] = useState("flex");
  const [header3Display, setHeader3Display] = useState("flex");
  const [header4Display, setHeader4Display] = useState("flex");
  const [header5Display, setHeader5Display] = useState("flex");
  const [header6Display, setHeader6Display] = useState("flex");

  const [header1InputDisplay, setHeader1InputDisplay] = useState("none");
  const [header2InputDisplay, setHeader2InputDisplay] = useState("none");
  const [header3InputDisplay, setHeader3InputDisplay] = useState("none");
  const [header5InputDisplay, setHeader5InputDisplay] = useState("none");
  const [header6InputDisplay, setHeader6InputDisplay] = useState("none");

  const [header1DefaultValue, setHeader1DefaultValue] = useState(header1);
  const [header2DefaultValue, setHeader2DefaultValue] = useState(header2);
  const [header3DefaultValue, setHeader3DefaultValue] = useState(header3);
  const [header4DefaultValue, setHeader4DefaultValue] = useState(header4);
  const [header5DefaultValue, setHeader5DefaultValue] = useState(0);
  const [header6DefaultValue, setHeader6DefaultValue] = useState(
    quoteItemObj?.RentalRate > 0.1
      ? quoteItemObj?.RentalRate
      : quoteItemObj?.BasePrice
  );

  const [Header8, setHeader8] = useState(header8);

  const [linkHeaderDisplay, setLinkHeaderDisplay] = useState("none");
  const [pointerEvent, setPointerEvent] = useState("none");
  const [youtubeLinkProp, setYoutubeLinkProp] = useState("");

  const [descriptionOverflow, setDescriptionOverflow] = useState("hidden");
  const [descriptionWhiteSpace, setDescriptionWhiteSpace] = useState("nowrap");

  const [deleteIconSrc, setDeleteIconSrc] = useState("");

  const [relatedItemToggleSRC, setRelatedItemToggleSRC] =
    useState("/downarrow.svg");
  const [relatedItemsDisplay, setRelatedItemsDisplay] = useState("none");

  const [FontSize, setFontSize] = useState("14px");
  const [FontWeight, setFontWeight] = useState("regular");
  const [Quantity, setItemQuantity] = useState(quoteItemObj?.Quant);

  const DetermineInputHeaderLogic = async () => {
    InitializeSetup();
    if (createInvoice === true) {
      quoteItemObj.Quant = 0;
      setHeader5Display("none");
      setHeader5InputDisplay("flex");
      if (quoteItemObj.RentalRate > 0.1)
        setHeader6DefaultValue(quoteItemObj.RentalRate);
      else setHeader6DefaultValue(quoteItemObj.BasePrice);

      RecalculateSubtotal(quoteItemObj, 0);
    }
  };

  const InitializeSetup = async () => {
    setItemQuantity(quoteItemObj.Quant);

    setHeader1Display("none");
    setHeader2Display("flex");
    setHeader3Display("flex");
    setHeader4Display("flex");
    setHeader5Display("flex");
    setHeader6Display("flex");
    setDeleteIconSrc("");

    setHeader1InputDisplay("none");
    setHeader2InputDisplay("none");
    setHeader3InputDisplay("none");
    // setHeader4InputDisplay("none")
    setHeader5InputDisplay("none");
    setHeader6InputDisplay("none");

    if (isHeader == true) {
      setFontSize("16px");
      setFontWeight("bold");
    }
  };

  const RecalculateSubtotal = async (item, quantity) => {
    if (quantity > 0) {
      item.Quant = quantity;
      if (item.RentalRate > 0.1)
        item.Price = item.RentalRate * item.Quant + item.BasePrice;
      else item.Price = item.Quant * item.BasePrice;
    } else {
      item.Quant = 0;
      item.Price = 0;
    }
    setHeader8("$" + item.Price.toFixed(2));
  };

  useEffect(() => {
    if (quoteItemObj !== null && quoteItemObj != undefined)
      DetermineInputHeaderLogic(invoiceTypeName);
  }, [invoiceTypeName, createInvoice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexColumnCont100xAutoPercent margin="0" onClick={onClick}>
      <QuoteItemCont
        borderbottom={
          relatedItemsDisplay === "block"
            ? `1px dashed ${ColorPalette.PrimaryButtonBlue}`
            : `1px solid ${ColorPalette.PrimaryGrey}`
        }
      >
        {/* Column 1 */}

        <FlexibleEstimateDiv5Percent
          display={header1Display}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <Icon20x20
            onClick={() => {
              RemoveItemFromLocalQuoteObject();
            }}
            src={deleteIconSrc}
          ></Icon20x20>
        </FlexibleEstimateDiv5Percent>

        <FlexibleEstimateDiv5Percent
          display={header1InputDisplay}
          mobiledisplay="none"
        >
          <TextInputProp
            type="number"
            mobiledisplay="none"
            defaultValue={header1DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv5Percent>

        {/* Column 2 */}

        <FlexibleEstimateDiv35Percent
          height={descriptionOverflow === "hidden" ? "110px" : "auto"}
          width="500px"
          display={header2Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={isHeader === true ? "bold" : ""}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <FlexColumnCont
            mobilewidth="100%"
            width="auto"
            height="auto"
            margin="0%"
            justifycontent="center"
            alignitems="left"
          >
            <SmallGreenLeagueText
              display={bgColor === ColorPalette.LightGreen ? "block" : "none"}
            >
              {"[REQUIRED PRODUCTS:]"}
            </SmallGreenLeagueText>
            {youtubeLinkProp !== "" ? (
              <a
                rel="noreferrer"
                href={youtubeLinkProp}
                target="_blank"
                style={{
                  textDecoration: linkHeaderDisplay,
                  pointerEvents: pointerEvent,
                }}
              >
                {header2}
              </a>
            ) : (
              header2
            )}

            {/* <TinyHeaderDarkGrey
              width="15vw"
              margin="4px"
              display={isHeader !== true ? subheader2Display : "none"}
              mobiledisplay="block"
              overflow={showRelatedItems === true ? 'visible' : 'hidden'}
              whitespace={showRelatedItems === true ? 'normal' : 'nowrap'}
            >
              {subheader2notes}
            </TinyHeaderDarkGrey>

            <TinyHeaderDarkGrey
              width="15vw"
              display={isHeader !== true ? "" : "none"}
              margin="4px"
              overflow={showRelatedItems === true ? 'visible' : 'hidden'}
              whitespace={showRelatedItems === true ? 'normal' : 'nowrap'}
            >
              {subheader2}
            </TinyHeaderDarkGrey> */}
          </FlexColumnCont>
        </FlexibleEstimateDiv35Percent>

        <FlexibleEstimateDiv15Percent
          display={isHeader !== true ? header2InputDisplay : "none"}
          mobiledisplay="none"
        >
          <TextInputProp
            type="text"
            mobiledisplay="none"
            defaultValue={header2DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 3 */}

        <FlexibleEstimateDiv35Percent
          justifycontent="center"
          textalign="center"
          display={header3Display}
          mobiledisplay={header3Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {header3}
        </FlexibleEstimateDiv35Percent>

        <FlexibleEstimateDiv15Percent
          mobiledisplay={header3InputDisplay}
          display={header3InputDisplay}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            inputwidth="60px"
            type="number"
            id="quantvalue"
            key={header3DefaultValue}
            onBlur={(e) => {
              OutputValue(e.target.valueAsNumber);
            }}
            defaultValue={header3DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 4 */}

        {/* <FlexibleEstimateDiv15Percent
          
          display={header4Display}
          mobiledisplay={header4Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {header4}
        </FlexibleEstimateDiv15Percent> */}

        <FlexibleEstimateDiv15Percent
          display="none"
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            width="50px"
            type="number"
            defaultValue={header4DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 5 */}

        <GreyFlexibleEstimateDiv15Percent
          display={header5Display}
          mobiledisplay={header5Display}
          bgcolor={
            bgColor === ""
              ? ColorPalette.TertiaryGrey
              : bgColor !== ColorPalette.LightGreen
              ? bgColor
              : ColorPalette.GreyGreen
          }
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {header5}
        </GreyFlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          display={header5InputDisplay}
          mobiledisplay={header5InputDisplay}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <TextInputProp
            inputwidth="100px"
            type="number"
            mobiledisplay="none"
            onChange={(e) => {
              if (e.target.value !== "") {
                RecalculateSubtotal(quoteItemObj, e.target.valueAsNumber);
                OutputValue(quoteItemObj);
              }
            }}
            defaultValue={header5DefaultValue}
          ></TextInputProp>
        </FlexibleEstimateDiv15Percent>

        {/* Column 6 */}

        <FlexibleEstimateDiv15Percent
          borderright={`1px solid ${ColorPalette.DarkGrey}`}
          display={header6Display}
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {header6}
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv15Percent
          borderright={`1px solid ${ColorPalette.DarkGrey}`}
          display={header6InputDisplay}
          mobiledisplay="none"
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        ></FlexibleEstimateDiv15Percent>

        {/* Column 7 */}
        {/* <GreyFlexibleEstimateDiv15Percent
          borderright={`1px solid ${ColorPalette.DarkGrey}`}
          
          display={header6Display}
          bgcolor={
            bgColor === ""
              ? ColorPalette.TertiaryGrey
              : bgColor !== ColorPalette.LightGreen
              ? bgColor
              : ColorPalette.GreyGreen
          }
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          {" "}
          {header7}
        </GreyFlexibleEstimateDiv15Percent> */}

        {/* Column 8 */}
        <FlexibleEstimateDiv15Percent
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        >
          <b>{Header8}</b>
        </FlexibleEstimateDiv15Percent>

        <FlexibleEstimateDiv5Percent
          bgcolor={bgColor}
          fontsize={FontSize}
          fontweight={FontWeight}
          fontfamily={isHeader === true ? "LeagueSpartan-Bold " : ""}
        ></FlexibleEstimateDiv5Percent>
      </QuoteItemCont>
    </FlexColumnCont100xAutoPercent>
  );
};

InvoiceToolItem.defaultProps = {
  header1: "",
  header2: "",
  subheader2: "",
  header3: "",
  header4: "",
  header4input: "",
  header5: "",
  header6: "",
  header7: "",
  header8: "",
  invoiceTypeName: "",
  OutputValue: () => {},
  RemoveItemFromLocalQuoteObject: () => {},
  bgColor: "",
  isHeader: false,
  showRelatedItems: false,
  createInvoice: false,
};

export default InvoiceToolItem;
