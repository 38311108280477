import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetCurrentTime } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import ClockIn from "../../comps/ClockIn";
import Menu from "../../comps/MenuComps/Menu";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextWithMargins,
} from "../../StylesheetComps/Titles";

const ClockInOutScreen = () => {
  //=============================================================================
  // Clock In / Clock Out. Worker is redirected here from link in Menu
  //=============================================================================

  //=============================================================================
  // Worker can clock in and out of a schedule that has been created
  //=============================================================================

  const [clockInOpen, setClockInOpen] = useState(false);
  const [clockId, setClockId] = useState(0);
  const [clockCheck, setClockCheck] = useState("");
  const [scheduleMap, setScheduleMap] = useState([]);
  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = ("0" + (D.getMonth() + 1)).slice(-2);
  const currentDay = ("0" + D.getDate()).slice(-2);

  const GetTodaySchedule = async (userId) => {
    const resp = await axios.post(window.$DBURL + "schedule/crew", {
      Id: userId,
      Year: currentYear,
      Month: currentMonth,
      Day: currentDay,
    });
    setScheduleMap(resp.data.schedules);
  };

  const HandleClockIn = async (userId) => {
    var clockin = await GetCurrentTime();
    const resp = await axios.post(window.$DBURL + "schedule/clock", {
      Crew_Id: userId,
      Schedule_Id: clockId,
      Clockin: clockin,
    });
    if (resp.status === 200) {
      setClockCheck("");
      setClockId(0);
    }
  };

  const HandleClockOut = async (userId) => {
    var clockout = await GetCurrentTime();
    const resp = await axios.put(window.$DBURL + "schedule/worker/clock", {
      Schedule_Id: clockId,
      Clockout: clockout,
    });
    if (resp.status === 200) {
      setClockCheck("");
      setClockId(0);
    }
  };

  const ClockStatusCheck = async () => {
    const resp = await axios.get(
      window.$DBURL + "schedule/clock/sch/" + clockId
    );
    if (resp.data.clock == null) {
      setClockCheck("clockInNeeded");
    } else if (resp.data.clock.Clockout == null) {
      setClockCheck("clockOutNeeded");
    } else if (resp.data.clock.Clockout !== null) {
      setClockCheck("workerClockedForDay");
    }
  };

  useEffect(() => {
    GetTodaySchedule(JSON.parse(sessionStorage.getItem("userInfo")).Id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ClockStatusCheck();
  }, [clockId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileColumn>
      <LeftCont>
        <Menu></Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <ClockIn
            open={clockInOpen}
            clockCheck={clockCheck}
            clockIn={() => {
              HandleClockIn(JSON.parse(sessionStorage.getItem("userInfo")).Id);
            }}
            clockOut={() => {
              HandleClockOut(JSON.parse(sessionStorage.getItem("userInfo")).Id);
            }}
            onClose={() => {
              setClockInOpen(false);
            }}
          >
            {" "}
          </ClockIn>
          <LeagueSpartanExtraLargeBold>
            Select a Schedule to Clock In / Clock Out (24 Hour Time)
          </LeagueSpartanExtraLargeBold>
          {scheduleMap.map((o, i) => (
            <div
              onClick={() => {
                setClockId(o.Id);
                setClockInOpen(true);
              }}
            >
              <FlexRowContFullWidth>
                <RegularTextWithMargins>
                  {o.Month}/{o.Day}/{o.Year}
                </RegularTextWithMargins>
                <RegularTextWithMargins>
                  Start Time -{o.Start.Hour}:{o.Start.Minute}
                </RegularTextWithMargins>
                <RegularTextWithMargins>
                  End Time -{o.End.Hour}:{o.End.Minute}
                </RegularTextWithMargins>
              </FlexRowContFullWidth>
              <hr></hr>
            </div>
          ))}
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

ClockInOutScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default ClockInOutScreen;
