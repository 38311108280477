import React from "react";
import styled from "styled-components";

const InputStyle = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "300px")};
  height: ${(props) => (props.inputheight ? props.inputheight : "50px")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "4px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#EDEDED"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "block")};
  margin: 10px;
  border: 0;
  padding: 18px;
  outline: none;
  font-size: 90%;
  font-family: Futura;
  @media (max-width: 1006px) {
    // font-size:3vh;
  }
`;

const Input = ({
  placeholder,
  checked,
  defaultChecked,
  maxLength,
  inputdisplay,
  autoComplete,
  inputwidth,
  inputheight,
  inputborderradius,
  inputbgcolor,
  inputborderstyle,
  inputborderwidth,
  inputbordercolor,
  defaultValue,
  onChange,
  type,
  value,
  Filter,
}) => {
  return (
    <InputStyle
      type={type}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      checked={checked}
      defaultChecked={defaultChecked}
      inputheight={inputheight}
      inputwidth={inputwidth}
      inputborderradius={inputborderradius}
      maxLength={maxLength}
      autoComplete={autoComplete}
      inputdisplay={inputdisplay}
      inputbordercolor={inputbordercolor}
      inputbgcolor={inputbgcolor}
      inputborderstyle={inputborderstyle}
      inputborderwidth={inputborderwidth}
      onChange={onChange}
      Filter={Filter}
    ></InputStyle>
  );
};

Input.defaultProps = {
  inputwidth: "300px",
  inputheight: "50px",
  inputborderradius: "4px",
  inputbgcolor: "#EDEDED",
  inputborderstyle: "solid",
  inputborderwidth: 0,
  placeholder: "placeholder",
  autoComplete: "new-password",
  onChange: () => {},
  setUn: () => {},
  setPass: () => {},
};

export default Input;
