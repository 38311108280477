import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useEffect, useState } from "react";
import { UpdateToolKit } from "../../../API_Functions/Tool_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont500x850px,
  FlexDiv,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../../StylesheetComps/Div";
import {
  Image200x200pxContain,
  Image40x40px,
} from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaRegular,
  TextInput100Percent,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularTextBoldRed,
  StyledHRPropColour,
} from "../../../StylesheetComps/Titles";

const SubToolKitEdit = ({
  open,
  onClose,
  onUpdatedSubtoolkit,
  onImgDelete,
  subtoolkitobj,
}) => {
  const [photoDisplay, setPhotoDisplay] = useState("block");

  const [ToolKitDefaultUrl, setToolKitDefaultUrl] = useState("block");

  const [errorText] = useState("");

  var file = "";

  function handleUpload(event) {
    file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setToolKitDefaultUrl(pic);
      subtoolkitobj.ImageUrl = pic;
      setPhotoDisplay("block");
    }
  };

  const UpdateSubtoolKit = async (subtoolkit) => {
    var data = await UpdateToolKit(subtoolkit);
    if (data !== null) {
      onUpdatedSubtoolkit();
    }
  };

  const DeleteImg = async (img) => {
    var resp = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (resp.status === 200) {
      subtoolkitobj.ImageUrl = "";
      setToolKitDefaultUrl("");
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    setToolKitDefaultUrl(subtoolkitobj.ImageUrl);
  }, [subtoolkitobj.ImageUrl]);

  if (!open) return null;
  if (subtoolkitobj !== null && subtoolkitobj !== undefined) {
    return (
      <FixedOverlay200vh>
        {" "}
        <Cont500x850px>
          <LeftItemWindowFixed bgcolor="White">
            <FlexDiv>
              <LeagueSpartanExtraLargeWidth200px>
                ToolKit Name
              </LeagueSpartanExtraLargeWidth200px>
              <TextInputReusable
                key={subtoolkitobj.Name}
                placeholder="ToolKit Name"
                defaultValue={subtoolkitobj.Name}
                onChange={(e) => {
                  subtoolkitobj.Name = e.target.value;
                }}
              ></TextInputReusable>
            </FlexDiv>
            <hr></hr>
            <FlexDiv>
              <LeagueSpartanExtraLargeWidth200px>
                Code
              </LeagueSpartanExtraLargeWidth200px>
              <TextInputReusable
                key={subtoolkitobj.Code}
                placeholder="ToolKit Code"
                defaultValue={subtoolkitobj.Code}
                onChange={(e) => {
                  subtoolkitobj.Code = e.target.value;
                }}
              ></TextInputReusable>
            </FlexDiv>
            <hr></hr>
            <FlexDiv>
              <LeagueSpartanExtraLargeWidth200px>
                Position
              </LeagueSpartanExtraLargeWidth200px>
              <TextInputReusable
                key={subtoolkitobj.Position}
                placeholder="Position"
                defaultValue={subtoolkitobj.Position}
                onChange={(e) => {
                  subtoolkitobj.Position = e.target.value;
                }}
              ></TextInputReusable>
            </FlexDiv>

            <hr></hr>
            <FlexDiv>
              <LeagueSpartanExtraLargeWidth200px>
                ToolKit Description
              </LeagueSpartanExtraLargeWidth200px>
              <TextAreaRegular
                maxlength="100"
                onChange={(e) => {
                  subtoolkitobj.Description = e.target.value;
                }}
                defaultValue={subtoolkitobj.Description}
              ></TextAreaRegular>
            </FlexDiv>
            <hr></hr>
            <FlexDiv>
              <LeagueSpartanExtraLargeWidth200px>
                ToolKit Picture
              </LeagueSpartanExtraLargeWidth200px>
              <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
              <FlexDiv>
                <Image200x200pxContain
                  photoprops1={photoDisplay}
                  src={window.$IMGURL + ToolKitDefaultUrl}
                ></Image200x200pxContain>
                <Image40x40px
                  deletephotoprops={photoDisplay}
                  src="/Garbage_Icon.svg"
                  onClick={() => {
                    DeleteImg(ToolKitDefaultUrl);
                    setToolKitDefaultUrl("");
                    subtoolkitobj.ImageUrl = "";
                    setPhotoDisplay("none");
                    onImgDelete();
                  }}
                ></Image40x40px>
              </FlexDiv>
            </FlexDiv>
            <TextInput100Percent
              type="file"
              id="ToolKitpic"
              name="filename"
              onChange={handleUpload}
            ></TextInput100Percent>
            <StyledHRPropColour
              width="90%"
              border="1px solid white"
            ></StyledHRPropColour>
            <FlexDivCenter>
              <br></br>

              <RegularResponsiveButton
                onClick={() => {
                  UpdateSubtoolKit(subtoolkitobj);
                  onClose();
                  setPhotoDisplay("block");
                }}
              >
                Save
              </RegularResponsiveButton>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose();
                  setPhotoDisplay("block");
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </FlexDivCenter>
          </LeftItemWindowFixed>
        </Cont500x850px>
      </FixedOverlay200vh>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

SubToolKitEdit.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onUpdatedSubtoolkit: () => {},
  onImgDelete: () => {},
};

export default SubToolKitEdit;
