import axios from "axios";
import React, { useEffect, useState } from "react";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import WorkerCard from "../../comps/WorkerCard";
import {
  Div70PerWidth7PerHeight,
  FlexColumnFullWidth,
  FlexContMobileTopVH,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";

const StoreWorkersScreen = ({ RoleName }) => {
  //=============================================================================
  // Store Worker Screen - Manager views all of their workers
  //=============================================================================

  //=============================================================================
  // Ability to view the worker's schedule calendar and ClockIn/ClockOuts
  //=============================================================================

  const [workerList, setWorkerList] = useState([]);
  const [storeId, setStoreId] = useState(0);
  var storeIdSync = 0;

  const GetSessionInfo = async () => {
    setStoreId(JSON.parse(sessionStorage.getItem("userInfo")).Store_Id);
    storeIdSync = JSON.parse(sessionStorage.getItem("userInfo")).Store_Id;
    GetWorkerList();
  };

  const GetWorkerList = async () => {
    if (storeIdSync === 0) {
      const resp = await axios.get(
        window.$DBURL + "store/" + storeId + "/crews"
      );
      if (resp.status === 200) {
        if (resp.data !== null) {
          if (resp.data.crews !== null) {
            setWorkerList(resp.data.crews);
          }
        }
      }
    } else {
      const resp = await axios.get(
        window.$DBURL + "store/" + storeIdSync + "/crews"
      );
      if (resp.status === 200) {
        if (resp.data !== null) {
          if (resp.data.crews !== null) {
            setWorkerList(resp.data.crews);
          }
        }
      }
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileTopVH width="100vw" height="100vh">
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <Div70PerWidth7PerHeight></Div70PerWidth7PerHeight>
          <FlexColumnFullWidth>
            {workerList.map((o, i) => (
              <WorkerCard
                key={o.User_Id}
                Email={o.Email}
                Phone={o.Phone}
                workerId={o.User_Id}
                storeId={o.Store_Id}
                ManagerName={o.Name}
                StoreLocationName={o.StoreName}
                roleID={o.Role}
                MetroName={""}
              ></WorkerCard>
            ))}
          </FlexColumnFullWidth>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileTopVH>
  );
};

StoreWorkersScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default StoreWorkersScreen;
