import React, { useState } from "react";
import {
  BodyPara,
  Header,
  TextInput300x50,
} from "../../../StylesheetComps/AuthSheet";
import { FlexRowCont } from "../../../StylesheetComps/Cont";
import {
  EstimatorDiv,
  FlexDivSpaceAround,
  GreenNavigationButton,
  GreyNavigationButton,
  MediumStyledImage,
  ProgressBar,
  ProgressBarWidth,
  Section1,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../../StylesheetComps/Menu";

const ProjectBudget = ({
  open,
  onNext,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
  onSelectProjectBudget,
}) => {
  const [maxbudget, setMaxBudget] = useState("");

  //When user is ready to proceed, combine min and max budget into string
  //that is passed to a higher function for later, and onNext(); to the
  //Photo Upload Page

  const DefineProjectBudget = async (maxbudget) => {
    let projectbudgetstring = "Max Budget:" + maxbudget;
    onSelectProjectBudget(projectbudgetstring);
    onNext();
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="87.5%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" margintop="4%" marginbottom="1%">
          Project Budget
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="2%"
          fontsize="16px"
        >
          Select one of the budget ranges below or set your preferred. Setting a
          budget is an important step in creating a project that fits your needs
          and lifestyle. Our team will put together a package of planting and
          materials suited for your budget and offer different options to
          achieve your project goals.
        </BodyPara>

        <FlexColumnCont
          flexdirection="row"
          mobileflexdirection="column"
          mobilewidth="90vw"
          width="85%"
          height="45%"
          justifycontent="space-around"
        >
          <FlexDivSpaceAround>
            <MediumStyledImage
              height="80%"
              width="15vw"
              mobilewidth="90vw"
              src="/Budget_BeforeClose.jpg"
            ></MediumStyledImage>
            <FlexRowCont>
              <SmallHeaderLeagueSpartan fontsize="16px">
                Before Project
              </SmallHeaderLeagueSpartan>
            </FlexRowCont>
          </FlexDivSpaceAround>

          <FlexDivSpaceAround
            onClick={() => {
              setMaxBudget("Under $10,000");
            }}
          >
            <MediumStyledImage
              height="80%"
              width="15vw"
              mobilewidth="90vw"
              src="/Budget_CloseUp.jpg"
            ></MediumStyledImage>
            <FlexRowCont>
              <SmallHeaderLeagueSpartan fontsize="16px">
                Under $10,000
              </SmallHeaderLeagueSpartan>
            </FlexRowCont>
          </FlexDivSpaceAround>

          <FlexDivSpaceAround
            onClick={() => {
              setMaxBudget("$10,000-$20,000");
            }}
          >
            <MediumStyledImage
              height="80%"
              width="15vw"
              mobilewidth="90vw"
              src="/mediumbudget.jpg"
            ></MediumStyledImage>
            <FlexRowCont>
              <SmallHeaderLeagueSpartan fontsize="16px">
                $10,000 - $20,000
              </SmallHeaderLeagueSpartan>
            </FlexRowCont>
          </FlexDivSpaceAround>

          <FlexDivSpaceAround
            onClick={() => {
              setMaxBudget("$50,000+");
            }}
          >
            <MediumStyledImage
              height="80%"
              width="15vw"
              mobilewidth="90vw"
              src="/highbudget.jpg"
            ></MediumStyledImage>
            <FlexRowCont>
              <SmallHeaderLeagueSpartan fontsize="16px">
                Over $50,000
              </SmallHeaderLeagueSpartan>
            </FlexRowCont>
          </FlexDivSpaceAround>
        </FlexColumnCont>

        <FlexColumnCont
          width="50%"
          height="15%"
          marginbottom="0%"
          margintop="0"
          alignitems="left"
          flexdirection="row"
          mobileflexdirection="column"
        >
          <FlexColumnCont
            width="50%"
            height="100%"
            flexdirection="row"
            alignitems="center"
            justifycontent="center"
          >
            <SmallHeader width="10%" height="auto">
              Max
            </SmallHeader>
            <TextInput300x50
              value={maxbudget}
              inputwidth="60%"
              inputheight="25%"
              placeholder="Maximum Budget"
              onChange={(e) => {
                setMaxBudget(e.target.value);
              }}
            ></TextInput300x50>
          </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          height="8%"
          margin="0"
          margintop="5vh"
          flexdirection="row"
          justifycontent="space-around"
          width="100%"
        >
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              DefineProjectBudget(maxbudget);
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectBudget.defaultProps = {
  onChange: () => {},
  onSelectProjectBudget: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectBudget;
