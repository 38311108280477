// Importing React hooks
import { useEffect, useState } from "react";

// Importing API functions
import {
  AddProductAddon,
  GetCategory,
} from "../../API_Functions/CatProduct_Functions";

// Importing stylesheet components
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumnFullWidth,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
  LeftItemWindowFixed,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanRegular,
  StyledHRPropColour,
  TitleFontSize100PercentPointer,
} from "../../StylesheetComps/Titles";

// Importing a custom component
import ConfirmWindow from "../Windows/ConfirmWIndow";

const CategoryContentList = ({
  open,
  categoryid,
  productObj,
  RefreshCategoryList,
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});
  const [confirmWindowOpen, setIsConfirmWindowOpen] = useState(false);
  const [parentId, setParentId] = useState(0);

  const GetCurrentCategories = async (cateid) => {
    try {
      const data = await GetCategory(cateid);
      if (data !== null) {
        setSubCategories(data.SubCategories);
        setParentId(data.Parent_Id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddNewProductAddOnCategory = async (productId, cateId) => {
    try {
      const position = productObj?.Addons.length + 1;
      const data = await AddProductAddon(productId, cateId, position);
      if (data !== null) {
        RefreshCategoryList();
      }
    } catch (error) {
      // handle error
    }
  };

  useEffect(() => {
    GetCurrentCategories(categoryid);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <LeftItemWindowFixed left="47vw">
      <FlexRowContFullWidth>
        <FlexColumnFullWidth>
          <StyledHRPropColour
            border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
          ></StyledHRPropColour>
          <br></br>
          <FlexRowContFlexStart>
            <Icon30px30pxPointer
              display={parentId !== 0 ? "block" : "none"}
              onClick={() => {
                GetCurrentCategories(parentId);
              }}
              src="/leftarrow.svg"
            ></Icon30px30pxPointer>
            <LeagueSpartanRegular>Subcategories</LeagueSpartanRegular>
          </FlexRowContFlexStart>
          {subCategories.map((o, i) => (
            <TitleFontSize100PercentPointer
              onClick={() => {
                setIsConfirmWindowOpen(true);
                setCategoryObj(o);
              }}
            >
              {o.Name}
            </TitleFontSize100PercentPointer>
          ))}
          <br></br>
          <StyledHRPropColour
            border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
          ></StyledHRPropColour>
          <br></br>

          <FlexColumnCont></FlexColumnCont>
        </FlexColumnFullWidth>
        <ConfirmWindow
          leftbuttontext={"Create Addon"}
          rightbuttontext={"Open Category"}
          confirmtext={"Select an Option"}
          open={confirmWindowOpen}
          LeftFunction={() => {
            AddNewProductAddOnCategory(productObj?.Id, categoryObj?.Id);
            setIsConfirmWindowOpen(false);
          }}
          RightFunction={() => {
            GetCurrentCategories(categoryObj?.Id);
            setParentId(categoryObj?.Parent_Id);
            setIsConfirmWindowOpen(false);
          }}
          onClose={() => {
            setIsConfirmWindowOpen(false);
          }}
        ></ConfirmWindow>
      </FlexRowContFullWidth>
    </LeftItemWindowFixed>
  );
};

CategoryContentList.defaultProps = {
  RefreshCategoryList: () => {},
};

export default CategoryContentList;
