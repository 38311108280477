import React, { useState, useEffect } from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnCont90x95Percent,
  FlexColumnCont95x30Percent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import {
  LandscapePara,
  MediumStyledImage,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { AddCasePhoto } from "../../../API_Functions/CaseQuote_Functions";
import { Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { LinkText } from "../../../StylesheetComps/AuthSheet";
const CasePhotoUploadPreviewWindow = ({
  open,
  CloseWindow,
  CancelUpload,
  previewsrc,
  imgtype,
  caseObj,
  AssignIdToImage,
}) => {
  const SpinnerIcon = "/Spinner.svg";
  const [previewImage, setPreviewImage] = useState("");
  const [imageCaption, setImageCaption] = useState("");
  const [buttonDisplay] = useState("block");

  //Upload Boolean prevents Images from getting uploaded twice.

  const [uploadBoolean, setUploadBoolean] = useState(false);

  const AddPhotoToCase = async (caseid, imgurl, caption, imgtype) => {
    var photo = {};
    switch (imgtype) {
      case "streetview":
        photo = {
          Caption: "Street View - " + caption,
          File: imgurl,
          Case_Id: caseid,
        };
        break;
      case "accessarea":
        photo = {
          Caption: "Access Area - " + caption,
          File: imgurl,
          Case_Id: caseid,
        };
        break;
      case "panorama":
        photo = {
          Caption: "Panorama - " + caption,
          File: imgurl,
          Case_Id: caseid,
        };
        break;
      case "areaofwork":
        photo = {
          Caption: "Area of Work - " + caption,
          File: imgurl,
          Case_Id: caseid,
        };
        break;

      default:
        photo = {
          Caption: caption,
          File: imgurl,
          Case_Id: caseid,
        };
        break;
    }
    if (caseid !== 0 && imgurl !== "" && caption !== null) {
      var newphoto = await AddCasePhoto(photo);
      if (newphoto !== null) {
        AssignIdToImage(newphoto.Id);
        setImageCaption("");
        setUploadBoolean(false);
        CloseWindow();
      }
    }
  };

  const ChangeUIToPreventDoubles = async (uploadboolean) => {
    if (uploadboolean === false) {
      setUploadBoolean(true);
      AddPhotoToCase(caseObj.Id, previewsrc, imageCaption, imgtype);
    } else {
    }
  };

  useEffect(() => {
    if (previewsrc !== undefined && previewsrc !== null) {
      if (previewsrc.includes("pdf") || previewsrc.includes("PDF")) {
        setPreviewImage("/pdficon.png");
      } else {
        setPreviewImage(window.$IMGURL + previewsrc);
      }
    }
  }, [previewsrc]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <FlexRowCont
          height="100%"
          justifycontent="flex-start"
          alignitems="baseline"
        >
          <FlexColumnCont90x95Percent>
            <SmallHeaderLeagueSpartan>Preview Photo</SmallHeaderLeagueSpartan>
            <FlexRowCont width="40%" height="40%" marginbottom="5%">
              <MediumStyledImage
                objfit="contain"
                mobileheight="20vh"
                mobilewidth="40vw"
                height="100%"
                src={previewImage}
              ></MediumStyledImage>
            </FlexRowCont>
            <FlexColumnCont95x30Percent>
              <SmallHeaderLeagueSpartan>
                Photo Description
              </SmallHeaderLeagueSpartan>
              <LandscapePara
                mobilewidth="68vw"
                mobileheight="25vh"
                maxLength="250"
                onChange={(e) => {
                  setImageCaption(e.target.value);
                }}
              ></LandscapePara>
            </FlexColumnCont95x30Percent>
            <FlexRowCont justifycontent="flex-end">
              <LinkText
                onClick={() => {
                  CancelUpload();
                }}
              >
                Cancel
              </LinkText>
              <RegularResponsiveButton
                display={buttonDisplay}
                onClick={() => {
                  setPreviewImage(SpinnerIcon);
                  ChangeUIToPreventDoubles(uploadBoolean);
                }}
              >
                Upload
              </RegularResponsiveButton>
            </FlexRowCont>
          </FlexColumnCont90x95Percent>
          <Icon20x20
            onClick={() => {
              CancelUpload();
            }}
            src="/Close.svg"
          ></Icon20x20>
        </FlexRowCont>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

CasePhotoUploadPreviewWindow.defaultProps = {
  CloseWindow: () => {},
  CancelUpload: () => {},
  AssignIdToImage: () => {},
};

export default CasePhotoUploadPreviewWindow;
