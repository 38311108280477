import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import ManagerCard from "../../comps/ManagerCard";
import Menu from "../../comps/MenuComps/Menu";
import StoreSupplierCard from "../../comps/StoreSupplierCard";
import EditSupplier from "../../comps/Windows/EditSupplier";
import NewRoleWindow from "../../comps/Windows/NewRole";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnCont80vwAutoHeight,
  FlexCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDivCenterItems,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

const RoleListScreen = ({ RoleName }) => {
  //=============================================================================
  // RoleList Screen - Manager can view all accounts for specific Role
  //=============================================================================

  //=============================================================================
  // Manager can create or delete accounts from this page
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [roles, setRoles] = useState([]);
  const [suppId, setSuppId] = useState(0);

  const [NameProp, setNameProp] = useState("");
  const [EmailProp, setEmailProp] = useState("");
  const [PhoneProp, setPhoneProp] = useState("");

  const [roleOpen, setRoleOpen] = useState(false);
  const [editSuppOpen, setEditSuppOpen] = useState(false);
  const [idForUser, setIdForUser] = useState(0);
  const [errorText, setErrorText] = useState("");

  var IDFORROLE = 0;
  var setroleFullName = "";
  var setroleUsername = "";
  var setroleEmail = "";
  var setrolePhone = "";
  var setrolePassword = "";

  const GetRoleInfo = async () => {
    if (params.role === "Supplier") {
      const resp = await axios.get(
        window.$DBURL + "store/" + params.Id + "/supplier"
      );
      setRoles(Object.values(resp.data)[0]);
    } else if (params.role === "OwnerOperator") {
      const resp = await axios.get(
        window.$DBURL + "store/" + params.Id + "/operators"
      );
      setRoles(Object.values(resp.data)[0]);
    } else if (params.role === "Worker") {
      const resp = await axios.get(
        window.$DBURL + "store/" + params.Id + "/crews"
      );
      setRoles(Object.values(resp.data)[0]);
    } else {
      const resp = await axios.get(
        window.$DBURL + "store/" + params.Id + "/" + params.role + "s"
      );
      setRoles(Object.values(resp.data)[0]);
    }
  };

  const GetSessionInfo = async () => {};

  const GetStoreInfo = async () => {
    const resp = await axios.get(window.$DBURL + "store/" + params.Id);
    setIdForUser(resp.data.store.Metro_Id);
  };

  const HandleRoleDel = async (key) => {
    if (params.role === "OwnerOperator") {
      const resp = await axios.delete(
        window.$DBURL + "admin/operator/" + IDFORROLE
      );
      if (resp.status === 200) {
        GetRoleInfo();
      }
    } else if (params.role === "Worker") {
      const resp = await axios.delete(
        window.$DBURL + "admin/crew/" + IDFORROLE
      );
      if (resp.status === 200) {
        GetRoleInfo();
      }
    } else {
      const resp = await axios.delete(
        window.$DBURL + "admin/" + params.role + "/" + IDFORROLE
      );
      if (resp.status === 200) {
        GetRoleInfo();
      }
    }
  };

  const HandleSupplierDel = async (key) => {
    const resp = await axios.delete(
      window.$DBURL + "store/" + params.Id + "/supplier/" + IDFORROLE
    );
    if (resp.status === 200) {
      GetRoleInfo();
    }
  };

  const HandleFormComplete = async ({
    roleFullName,
    roleUsername,
    roleEmail,
    rolePhone,
    rolePassword,
  }) => {
    setroleFullName = roleFullName;
    setroleUsername = roleUsername;
    setroleEmail = roleEmail;
    setrolePhone = rolePhone;
    setrolePassword = rolePassword;
  };

  const HandleFormUpdate = async ({ roleFullName, roleEmail, rolePhone }) => {
    setroleFullName = roleFullName;
    setroleEmail = roleEmail;
    setrolePhone = rolePhone;
  };

  const HandleNewRole = async () => {
    if (params.role === "Supplier") {
      const resp = await axios
        .post(window.$DBURL + "store/" + params.Id + "/supplier", {
          Name: setroleFullName,
          Username: setroleUsername,
          Password: setrolePassword,
          Phone: setrolePhone,
          Email: setroleEmail,
          Metro_Id: idForUser,
        })
        .catch(handleErrors);
      if (resp.status === 200) {
        GetRoleInfo();
        setRoleOpen(false);
      }
    } else if (params.role === "OwnerOperator") {
      const resp = await axios
        .post(window.$DBURL + "admin/operator", {
          Name: setroleFullName,
          Username: setroleUsername,
          Password: setrolePassword,
          Phone: setrolePhone,
          Email: setroleEmail,
          Metro_Id: idForUser,
        })
        .catch(handleErrors);
      if (resp.status === 200) {
        GetRoleInfo();
        setRoleOpen(false);
      }
    } else if (params.role === "Worker") {
      const resp = await axios
        .post(window.$DBURL + "admin/crew", {
          Name: setroleFullName,
          Username: setroleUsername,
          Password: setrolePassword,
          Phone: setrolePhone,
          Email: setroleEmail,
          Metro_Id: idForUser,
        })
        .catch(handleErrors);
      if (resp.status === 200) {
        GetRoleInfo();
        setRoleOpen(false);
      }
    } else {
      const resp = await axios
        .post(window.$DBURL + "admin/" + params.role, {
          Name: setroleFullName,
          Username: setroleUsername,
          Password: setrolePassword,
          Phone: setrolePhone,
          Email: setroleEmail,
          Metro_Id: idForUser,
        })
        .catch(handleErrors);
      if (resp.status === 200) {
        GetRoleInfo();
        setRoleOpen(false);
      }
    }
  };

  function handleErrors(err) {
    if (err.Message === "Invalid username! Username already exists.") {
      setErrorText("Invalid username! Username already exists.");
    } else if (err.request) {
      setErrorText("Manager Creation Failed, Username may already be in use.");
    } else if (err.response) {
      setErrorText("Manager Creation Failed, Username may already be in use.");
    }
  }

  const HandleUpdateSupp = async () => {
    const resp = await axios.put(
      window.$DBURL + "store/" + params.Id + "/supplier",
      {
        Id: suppId,
        Name: setroleFullName || NameProp,
        Email: setroleEmail || EmailProp,
        Phone: setrolePhone || PhoneProp,
      }
    );
    if (resp.status === 200) {
      GetRoleInfo();
    }
  };

  useEffect(() => {
    GetRoleInfo();
    GetStoreInfo();
    GetSessionInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (params.role === "Supplier") {
    return (
      <FlexCont100vw100vh>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu />
          <RightViewFullWidth>
            <FlexDiv70x7Percent>
              <HundredPercentFlexDivCenterItems>
                <Icon30px20pxPointer
                  src="/backarrow.svg"
                  onClick={() => {
                    history.push("/rolecat/" + params.Id);
                  }}
                ></Icon30px20pxPointer>
                <LeagueSpartanExtraLargeBold>
                  {params.role + "s"}
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton
                  onClick={() => {
                    setRoleOpen(true);
                  }}
                >
                  Create {params.role}
                </RegularResponsiveButton>
              </HundredPercentFlexDivCenterItems>
            </FlexDiv70x7Percent>
            <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
            <EditSupplier
              NameProp={NameProp}
              EmailProp={EmailProp}
              PhoneProp={PhoneProp}
              supplierID={suppId}
              open={editSuppOpen}
              onClose={() => {
                setEditSuppOpen(false);
              }}
              onPreview={HandleFormUpdate}
              onFormComplete={HandleUpdateSupp}
            ></EditSupplier>
            <NewRoleWindow
              open={roleOpen}
              onClose={() => {
                setRoleOpen(false);
              }}
              RoleNameProp={params.role}
              onPreview={HandleFormComplete}
              onFormComplete={HandleNewRole}
            ></NewRoleWindow>
            {roles.map((o, i) => (
              <StoreSupplierCard
                key={o.Id}
                roleID={o.Id}
                SupplierNameText={o.Name}
                StoreLocationName={o.StoreName}
                MetroName={""}
                onDel={() => {
                  IDFORROLE = o.Id;
                  HandleSupplierDel();
                }}
                onEdit={() => {
                  setSuppId(o.Id);
                  setNameProp(o.Name);
                  setEmailProp(o.Email);
                  setPhoneProp(o.Phone);
                  setEditSuppOpen(true);
                }}
              ></StoreSupplierCard>
            ))}
          </RightViewFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexCont100vw100vh>
    );
  } else {
    return (
      <FlexCont100vw100vh>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu />
          <RightViewFullWidth>
            <FlexDiv70x7Percent>
              <HundredPercentFlexDivCenterItems>
                <Icon30px20pxPointer
                  src="/backarrow.svg"
                  onClick={() => {
                    history.push("/rolecat/" + params.Id);
                  }}
                ></Icon30px20pxPointer>
                <LeagueSpartanExtraLargeBold>
                  {params.role + "s"}
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton
                  onClick={() => {
                    setRoleOpen(true);
                  }}
                >
                  Create {params.role}
                </RegularResponsiveButton>
              </HundredPercentFlexDivCenterItems>
            </FlexDiv70x7Percent>
            <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
            <NewRoleWindow
              open={roleOpen}
              onClose={() => {
                setRoleOpen(false);
              }}
              RoleNameProp={params.role}
              onPreview={HandleFormComplete}
              onFormComplete={HandleNewRole}
            ></NewRoleWindow>
            {roles.map((o, i) => (
              <ManagerCard
                key={o.User_Id}
                Email={o.Email}
                Phone={o.Phone}
                roleID={o.User_Id}
                ManagerName={o.Name}
                StoreLocationName={o.StoreName}
                MetroName={""}
                onDel={() => {
                  IDFORROLE = o.User_Id;
                  HandleRoleDel();
                }}
              ></ManagerCard>
            ))}
          </RightViewFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexCont100vw100vh>
    );
  }
};

RoleListScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default RoleListScreen;
