import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import {
  DeleteQuoteItemByEstimator,
  ReverseQuant_QuoteItem,
  UpdateQuoteItem,
} from "../../API_Functions/CaseQuote_Functions";
import { GetProduct } from "../../API_Functions/CatProduct_Functions";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  Cont100Percent4PercentDisplayProp,
  FlexColumnFullWidth,
  FlexContFullWidthJustifyCenter,
  FlexDiv,
  GreyCont100Percent4PercentDisplayProp,
} from "../../StylesheetComps/Cont";
import {
  FlexColumnDiv,
  FlexColumnDivCentered20Percent,
  FlexDivCentered45Percent,
  FullWidthDivFlexStart,
  QuoteQuadMedium
} from "../../StylesheetComps/Div";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  BlueUnderlinePointerPara,
  RegularText,
  SmallHeaderOpenSans,
  SmallText,
  TinyHeaderDarkGrey,
  TinyTextItalics
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";

const QuoteTableItems = ({ quoteItemObj, onItemChange, noChange, bgcolor }) => {
  const [expand, setExpand] = useState(false);

  const [roleType, setRoleType] = useState(0);
  const [txtLineState, setTxtLineState] = useState("none");
  const [description, setDescription] = useState("");
  const [descriptionDisplay, setDescriptionDisplay] = useState("none");
  const [arrowSRC, setArrowSRC] = useState("/downarrow.svg");
  const [itemNotePara, setItemNote] = useState("");

  const [deleteItem, setDeleteItem] = useState(false);
  const [deleteRelatedItemWindow, setDeleteRelatedItemWindow] = useState(false);
  const [relatedDeleteItemId] = useState(0);

  const [itemQuantity, setItemQuantity] = useState(0);
  const [showSave, setShowSave] = useState("none");
  const [showUnit, setShowUnit] = useState("block");
  const [relatedDisplayProp, setRelatedDisplayProp] = useState("none");
  const [showQuantInput, setShowQuantInput] = useState("none");
  const [showQuantText, setShowQuantText] = useState("block");

  const DeleteItem = async (itemid) => {
    var data = await DeleteQuoteItemByEstimator(itemid);
    if (data === null) {
      onItemChange(itemid);
    }
  };

  const UpdateItem = async (quoteitem) => {
    var quant = await ReverseQuant_QuoteItem(quoteItemObj.Id, itemQuantity);
    quoteitem.Quant = quant;
    var data = await UpdateQuoteItem(quoteitem);
    if (data !== null) {
      onItemChange();
    }
  };

  const SetProductDescriptionNotes = async (id) => {
    if (expand !== true) {
      var product = await GetProduct(id);
      if (product != null) {
        if (product.Description != null) {
          setDescriptionDisplay("block");
          setDescription(product.Description);
        } else {
          setDescriptionDisplay("none");
          setDescription("No Description");
        }
      } else setDescriptionDisplay("none");
    }
  };

  const SetItemNotes = async (notes) => {
    if (notes != null && notes !== undefined) {
      setItemNote(notes);
    }
  };

  const Toggle_Display = async (expand) => {
    if (expand === false) {
      setRelatedDisplayProp("block");
      setExpand(true);
      setArrowSRC("/uparrow.svg");
    } else {
      setDescriptionDisplay("none");
      setRelatedDisplayProp("none");
      setExpand(false);
      setArrowSRC("/downarrow.svg");
    }
  };

  const GetRoleInfo = async () => {
    setRoleType(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  const AssessQuant = async () => {
    if (quoteItemObj.Quant === 0) {
      setTxtLineState("line-through");
    }
  };

  const SetQuantDisplay = async (nochange) => {
    if (nochange) {
      setShowQuantInput("none");
      setShowQuantText("flex");
    } else {
      setShowQuantInput("flex");
      setShowQuantText("none");
    }
  };
  const ExecuteUpdate = async () => {
    if (itemQuantity !== 0 && !noChange) {
      UpdateItem(quoteItemObj);
    }
    setShowSave("none");
    if (!noChange) setShowUnit("block");
  };

  const onFocus = () => {
    if (!noChange) setShowSave("block");

    setShowUnit("none");
  };

  useEffect(() => {
    if (quoteItemObj.Notes !== null && quoteItemObj.Notes !== undefined) {
      setItemNote(quoteItemObj.Notes);
    } else {
      setItemNote("");
    }
  }, [quoteItemObj.Notes]);

   useEffect(() => {
    const fetchRoleAndQuant = async () => {
      await GetRoleInfo();
      AssessQuant();
      SetQuantDisplay(noChange);
    }
    fetchRoleAndQuant();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    quoteItemObj !== null &&
    quoteItemObj !== undefined &&
    quoteItemObj.Id > 0
  ) {
    if (roleType === UserRole.Manager || roleType === UserRole.Estimator) {
      return (
        <div>
          <Cont100Percent4PercentDisplayProp>
            <FullWidthDivFlexStart>
              <FlexDivCentered45Percent bgcolor={bgcolor}>
                <FlexColumnFullWidth>
                  <FlexDiv>
                  <SmallHeaderOpenSans>{quoteItemObj.Name}</SmallHeaderOpenSans>
                  {/* <Icon30px20pxPointer src='/Pencil.svg'></Icon30px20pxPointer> */}
                  </FlexDiv>
                  <TinyTextItalics>{itemNotePara}</TinyTextItalics>
                  <TinyHeaderDarkGrey
                    width="300px"
                    onClick={() => {
                      Toggle_Display(expand);
                    }}
                    overflow={expand === true ? "visible" : "hidden"}
                    whitespace={expand === true ? "normal" : "nowrap"}
                  >
                    {quoteItemObj.Description}
                  </TinyHeaderDarkGrey>
                </FlexColumnFullWidth>
                <Icon30px30pxPointer
                  display={
                    quoteItemObj?.RelatedItems?.filter(function (o) {
                      return o.CateType !== CategoryTypes.Labor;
                    }).length > 0
                      ? "block"
                      : "none"
                  }
                  src={arrowSRC}
                  onClick={() => {
                    SetProductDescriptionNotes(quoteItemObj.Product_Id);
                    SetItemNotes(quoteItemObj.Notes);
                    Toggle_Display(expand);
                  }}
                ></Icon30px30pxPointer>
              </FlexDivCentered45Percent>
              <FlexColumnDivCentered20Percent
                bgcolor={bgcolor}
                displayProp={showQuantText}
              >
                <FlexColumnDiv>
                  <RegularText txtLine={txtLineState}>
                    {quoteItemObj?.Quant?.toFixed(2)}
                  </RegularText>
                </FlexColumnDiv>
              </FlexColumnDivCentered20Percent>

              <FlexColumnDivCentered20Percent displayProp={showQuantInput}>
                <FlexContFullWidthJustifyCenter>
                  <TextInputProp
                    inputwidth="50%"
                    key={quoteItemObj.Quant?.toFixed(2)}
                    onFocus={onFocus}
                    type="number"
                    defaultValue={quoteItemObj.Quant?.toFixed(2)}
                    onChange={(e) => {
                      if (!noChange) setItemQuantity(e.target.value);
                    }}
                  ></TextInputProp>
                  <SmallText display={showUnit} txtLine={txtLineState}>
                    {quoteItemObj.DisplayUnit}
                  </SmallText>
                  <BlueUnderlinePointerPara
                    display={showSave}
                    onClick={() => {
                      ExecuteUpdate();
                    }}
                  >
                    Save
                  </BlueUnderlinePointerPara>
                </FlexContFullWidthJustifyCenter>
              </FlexColumnDivCentered20Percent>

              <QuoteQuadMedium>
                <SmallText>${quoteItemObj.UnitPrice?.toFixed(2)}</SmallText>
              </QuoteQuadMedium>

              <QuoteQuadMedium>
                <SmallText>
                  {"$" +
                    quoteItemObj.Price?.toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </SmallText>
              </QuoteQuadMedium>

              <QuoteQuadMedium>
                <RegularResponsiveButton
                  margintop="auto"
                  marginbottom="auto"
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setDeleteItem(true);
                  }}
                >
                  Delete
                </RegularResponsiveButton>
              </QuoteQuadMedium>

              <DeleteConfirmWindow
                deleteOpen={deleteItem}
                onDeleteFunction={() => {
                  DeleteItem(quoteItemObj.Id);
                }}
                closeDel={() => {
                  setDeleteItem(false);
                }}
              ></DeleteConfirmWindow>
            </FullWidthDivFlexStart>
          </Cont100Percent4PercentDisplayProp>

          <DeleteConfirmWindow
            deleteOpen={deleteRelatedItemWindow}
            onDeleteFunction={() => {
              DeleteItem(relatedDeleteItemId);
            }}
            closeDel={() => {
              setDeleteRelatedItemWindow(false);
            }}
          ></DeleteConfirmWindow>

          {quoteItemObj.RelatedItems?.map((o, i) => {
            if (o.CateType !== CategoryTypes.Labor) {
              return (
                <GreyCont100Percent4PercentDisplayProp
                  key={i}
                  displayProp={relatedDisplayProp}
                >
                  <FullWidthDivFlexStart>
                    <QuoteTableItems
                      key={i}
                      quoteItemObj={o}
                      onItemChange={onItemChange}
                      noChange={o.NoChange}
                      bgcolor={ColorPalette.LightGreen}
                    ></QuoteTableItems>
                  </FullWidthDivFlexStart>
                </GreyCont100Percent4PercentDisplayProp>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

QuoteTableItems.defaultProps = {
  RelatedItems: ["name"],
  onItemChange: () => {},
  bgcolor: "white",
};

export default QuoteTableItems;
