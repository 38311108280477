import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "85%")};
  height: 30px;
  position: absolute;
  background-color: #f0f0f5;
  border-radius: 15px;
  margin: 20px 20px 40px 85px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  user-select: none;
  z-index: 999;
  top: ${(props) => (props.top ? props.top : "0px")};
  left: ${(props) => (props.left ? props.left : "")};
  @media (max-width: 1006px) {
    width: ${(props) => (props.width ? props.width : "80vw")};
    margin: ${(props) => (props.margin ? props.margin : "")};
    top: ${(props) => (props.top ? props.top : "0px")};
  }
`;

const Thumb = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #d1d1d6;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transform: translateX(-50%);
  z-index: 999;
`;


const Slider = ({ onValueChange, width, top, left, margin }) => {
  const [value, setValue] = useState(0.5); // Initial position in the middle
  const sliderRef = useRef(null);

  // Convert the mouse or touch position to a value within the slider's range
  const convertClientXToValue = (clientX) => {
    const rect = sliderRef.current.getBoundingClientRect();
    let position = (clientX - rect.left) / rect.width; // Normalize position (0 to 1)
    position = Math.max(0, Math.min(1, position)); // Ensure position stays within 0 to 1
    const rangeValue = position * (0.000099 - 0.000001) + 0.000001; // Map to range 0.000001 to 0.0001
    return rangeValue;
  };

  const handleInteractionStart = (event) => {
    document.addEventListener('mousemove', handleInteractionMove);
    document.addEventListener('mouseup', handleInteractionEnd);
    document.addEventListener('touchmove', handleInteractionMove, { passive: false });
    document.addEventListener('touchend', handleInteractionEnd);
    handleInteractionMove(event); // Handle the initial interaction
  };

  const handleInteractionMove = (event) => {
    event.preventDefault(); // Prevent default to avoid any unwanted page scrolling
    const clientX = event.type.includes('mouse') ? event.clientX : event.touches[0].clientX;
    const newValue = convertClientXToValue(clientX);
    setValue((newValue - 0.000001) / (0.000099 - 0.000001)); // Update slider position based on value
    if (onValueChange) onValueChange(newValue); // Notify parent component of the new value
  };

  const handleInteractionEnd = () => {
    document.removeEventListener('mousemove', handleInteractionMove);
    document.removeEventListener('mouseup', handleInteractionEnd);
    document.removeEventListener('touchmove', handleInteractionMove);
    document.removeEventListener('touchend', handleInteractionEnd);
  };

  return (
    <SliderContainer margin={margin} width={width} top={top} left={left} ref={sliderRef} onMouseDown={handleInteractionStart} onTouchStart={handleInteractionStart}>
      <Thumb style={{ left: `${value * 100}%` }} />
    </SliderContainer>
  );
};

export default Slider;
