import React, { useState } from "react";

import { Header } from "../../../StylesheetComps/AuthSheet";
import { BodyPara } from "../../../StylesheetComps/AuthSheet";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import {
  GreenNavigationButton,
  GreyNavigationButton,
  SmallHeaderLeagueSpartan,
  ProgressBar,
  ProgressBarWidth,
  FlexDivMarginTop,
} from "../../../StylesheetComps/LandscapeForm";
import {
  Section1,
  EstimatorDiv,
  MediumStyledImage80Opacity,
  SmallIconDisplayNone,
} from "../../../StylesheetComps/LandscapeForm";
import {
  FlexRowCont,
  FlexRowCont100x8PercentSpaceAround,
} from "../../../StylesheetComps/Cont";

const ProjectStyle = ({
  open,
  onNext,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
  onSelectProjectStyle,
}) => {
  const [modern, setModern] = useState("");
  const [english, setEnglish] = useState("");
  const [japanese, setJapanese] = useState("");
  const [contemporary, setContemporary] = useState("");

  const [modernDisplay, setModernDisplay] = useState("none");
  const [englishDisplay, setEnglishDisplay] = useState("none");
  const [japaneseDisplay, setJapaneseDisplay] = useState("none");
  const [contemporaryDisplay, setContemporaryDisplay] = useState("none");

  const [modernOpacity, setModernOpacity] = useState("80%");
  const [englishOpacity, setEnglishOpacity] = useState("80%");
  const [japaneseOpacity, setJapaneseOpacity] = useState("80%");
  const [contemporaryOpacity, setContemporaryOpacity] = useState("80%");

  //Display/Hide the checkmarks and set if the values are selected
  //for the associated style

  const ShowModernDisplayCheckmark = async (modernDisplay) => {
    if (modernDisplay === "none") {
      setModernDisplay("block");
      setModernOpacity("100%");
      setModern("Modern");
    } else {
      setModernDisplay("none");
      setModernOpacity("80%");
      setModern("");
    }
  };

  const ShowEnglishDisplayCheckmark = async (englishDisplay) => {
    if (englishDisplay === "none") {
      setEnglishDisplay("block");
      setEnglishOpacity("100%");
      setEnglish("Traditional/English");
    } else {
      setEnglishDisplay("none");
      setEnglishOpacity("80%");
      setEnglish("");
    }
  };

  const ShowJapaneseDisplayCheckmark = async (japaneseDisplay) => {
    if (japaneseDisplay === "none") {
      setJapaneseDisplay("block");
      setJapaneseOpacity("100%");
      setJapanese("Japanese");
    } else {
      setJapaneseDisplay("none");
      setJapaneseOpacity("80%");
      setJapanese("");
    }
  };

  const ShowContemporaryDisplayCheckmark = async (contemporaryDisplay) => {
    if (contemporaryDisplay === "none") {
      setContemporaryDisplay("block");
      setContemporaryOpacity("100%");
      setContemporary("Contemporary");
    } else {
      setContemporaryDisplay("none");
      setContemporaryOpacity("80%");
      setContemporary("");
    }
  };

  //When user intends to proceed, combine styles into string and pass to
  //higher function, and onNext(); to Project Budget

  const DefineProjectStyle = async (
    modern,
    english,
    japanese,
    contemporary
  ) => {
    let projectstylestring =
      modern + " " + english + " " + japanese + " " + contemporary;
    onSelectProjectStyle(projectstylestring);
    onNext();
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1 height="65vh">
        <ProgressBar>
          <ProgressBarWidth width="81.25%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Style
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="0%"
          fontsize="16px"
        >
          We want to ensure you love the outdoor space you're in, and that comes
          down to style. Let us know which are your favourite styles out of the
          photos below. (Check all that apply)
        </BodyPara>

        <FlexColumnCont
          width="90%"
          height="70%"
          marginbottom="0%"
          margintop="0"
          alignitems="left"
        >
          <FlexColumnCont
            flexdirection="row"
            width="98%"
            height="90%"
            justifycontent="space-around"
            mobileflexdirection="column"
          >
            <FlexDivMarginTop>
              <SmallHeaderLeagueSpartan>Modern</SmallHeaderLeagueSpartan>
              <br></br>
              <MediumStyledImage80Opacity
                src="/Modern.png"
                opacity={modernOpacity}
                onClick={() => {
                  ShowModernDisplayCheckmark(modernDisplay);
                }}
              ></MediumStyledImage80Opacity>
              <SmallIconDisplayNone
                display={modernDisplay}
                onClick={() => {
                  ShowModernDisplayCheckmark(modernDisplay);
                }}
                src="/greencheckmark.svg"
              ></SmallIconDisplayNone>
              <FlexRowCont></FlexRowCont>
            </FlexDivMarginTop>

            <FlexDivMarginTop>
              <SmallHeaderLeagueSpartan>
                Traditional/English
              </SmallHeaderLeagueSpartan>
              <br></br>
              <MediumStyledImage80Opacity
                src="/English.png"
                opacity={englishOpacity}
                onClick={() => {
                  ShowEnglishDisplayCheckmark(englishDisplay);
                }}
              ></MediumStyledImage80Opacity>
              <SmallIconDisplayNone
                display={englishDisplay}
                onClick={() => {
                  ShowEnglishDisplayCheckmark(englishDisplay);
                }}
                src="/greencheckmark.svg"
              ></SmallIconDisplayNone>
              <FlexRowCont></FlexRowCont>
            </FlexDivMarginTop>

            <FlexDivMarginTop>
              <SmallHeaderLeagueSpartan>Japanese</SmallHeaderLeagueSpartan>
              <br></br>
              <MediumStyledImage80Opacity
                src="/Japanese.png"
                opacity={japaneseOpacity}
                onClick={() => {
                  ShowJapaneseDisplayCheckmark(japaneseDisplay);
                }}
              ></MediumStyledImage80Opacity>
              <SmallIconDisplayNone
                display={japaneseDisplay}
                onClick={() => {
                  ShowJapaneseDisplayCheckmark(japaneseDisplay);
                }}
                src="/greencheckmark.svg"
              ></SmallIconDisplayNone>
              <FlexRowCont></FlexRowCont>
            </FlexDivMarginTop>

            <FlexDivMarginTop>
              <SmallHeaderLeagueSpartan>Contemporary</SmallHeaderLeagueSpartan>
              <br></br>
              <MediumStyledImage80Opacity
                src="/Contemporary.jpg"
                opacity={contemporaryOpacity}
                onClick={() => {
                  ShowContemporaryDisplayCheckmark(contemporaryDisplay);
                }}
              ></MediumStyledImage80Opacity>
              <SmallIconDisplayNone
                display={contemporaryDisplay}
                onClick={() => {
                  ShowContemporaryDisplayCheckmark(contemporaryDisplay);
                }}
                src="/greencheckmark.svg"
              ></SmallIconDisplayNone>
              <FlexRowCont></FlexRowCont>{" "}
            </FlexDivMarginTop>
          </FlexColumnCont>
        </FlexColumnCont>
        <FlexRowCont100x8PercentSpaceAround>
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              DefineProjectStyle(modern, english, japanese, contemporary);
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexRowCont100x8PercentSpaceAround>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectStyle.defaultProps = {
  onChange: () => {},
  onSelectProjectStyle: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectStyle;
