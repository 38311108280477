import { FlexCont5vhMobile88x5, FlexDiv } from "../../StylesheetComps/Cont";
import { Icon35px100Perc } from "../../StylesheetComps/Imgs/Icons";
import { RegularText } from "../../StylesheetComps/Titles";

const MetroTaxCard = ({
  ManagerName,
  TaxNameText,
  TaxRateText,
  onClick,
  TaxID,
  EditTax,
  onSetEdit,
}) => {
  return (
    <FlexCont5vhMobile88x5>
      <RegularText>{TaxNameText}</RegularText>
      <RegularText>{TaxRateText}</RegularText>
      <FlexDiv>
        <Icon35px100Perc
          src={"/EditIcon.svg"}
          onClick={() => {
            EditTax();
            onSetEdit = { TaxID };
          }}
        ></Icon35px100Perc>
        <Icon35px100Perc
          src="/Garbage_Icon.svg"
          onClick={onClick}
        ></Icon35px100Perc>
      </FlexDiv>
    </FlexCont5vhMobile88x5>
  );
};

MetroTaxCard.defaultProps = {
  TaxRateText: "EX. 13%",
  TaxNameText: "EX. HST",
  MetroName: "Toronto",
  TaxID: 0,
};

export default MetroTaxCard;
