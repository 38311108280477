import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { AuthCont, LeftAuthCont } from "../../StylesheetComps/AuthSheet";
import { LeftItemsCont } from "../../StylesheetComps/AuthSheet";
import { Logo } from "../../StylesheetComps/AuthSheet";
import { Header } from "../../StylesheetComps/AuthSheet";
import { BodyPara } from "../../StylesheetComps/AuthSheet";

import { RightAuthCont } from "../../StylesheetComps/AuthSheet";
import { RightItemsCont } from "../../StylesheetComps/AuthSheet";
import { RightBarDiv } from "../../StylesheetComps/AuthSheet";
import { PrivacyPolicy } from "../../StylesheetComps/AuthSheet";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const ActivateScreen = () => {
  //=============================================================================
  // Activate Account Screen. User is redirected here after clicking the activate
  // link that was emailed to them.
  //=============================================================================

  //=============================================================================
  // Redirects to the Login Page
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  //User is redirected from email to activate account, useEffect activates right away to Activate Account

  useEffect(() => {
    ActivateAccount(params.activateCode);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ActivateAccount = async (activate) => {
    const resp = await axios.post(window.$DBURL + "users/activate/" + activate);
    if (resp.status === 200) {
    }
  };

  return (
    <AuthCont>
      <LeftAuthCont backgroundimg="/Log In Image.png">
        <LeftItemsCont></LeftItemsCont>
      </LeftAuthCont>

      <RightAuthCont>
        <RightBarDiv></RightBarDiv>

        <RightItemsCont>
          <Logo src="/NRCLogoRegularBlack.png"></Logo>
          <Header headercolor="black">You’re All Set!</Header>
          <BodyPara fontsize="18px" bodycolor="black">
          Your account has been activated. Please log in to start your project.
          </BodyPara>
          <br></br>
          <br></br>
          <br></br>
          <RegularResponsiveButton
            text={""}
            onClick={() => {
              history.push("/");
            }}
            buttonwidth="66%"
          >
            Continue
          </RegularResponsiveButton>
        </RightItemsCont>

        <FlexColumnCont
          width="95%"
          height="5%"
          margintop="3.75vw"
          flexdirection="row"
          justifycontent="flex-end"
          alignitems="right"
        >
          <PrivacyPolicy>
            All rights reserved 2020 New Rhodes Construction Privacy Policy
          </PrivacyPolicy>
        </FlexColumnCont>
      </RightAuthCont>
    </AuthCont>
  );
};

export default ActivateScreen;
