import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import RoleCatCard from "../../comps/RoleCatCard";
import {
  FlexColumnCont80vwAutoHeight,
  FlexCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import {
  FlexDiv70x7Percent,
  GridDiv80xAutoPercent,
} from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDivCenterItems,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const RoleCatScreen = () => {
  //=============================================================================
  // RoleCat Screen - Managers can view all Role Types and click to go to Rolelist
  //=============================================================================

  const [rolesMerge, setRolesMerge] = useState([]);

  const params = useParams();
  const history = useHistory();

  const GetRoles = async (o) => {
    var resp = await axios.get(window.$DBURL + "users/roles");
    if (resp.status === 200) {
      setRolesMerge(
        resp.data.roles.slice(2, 6).concat(resp.data.roles.slice(8, 10))
      );
    }
  };

  useEffect(() => {
    GetRoles();
  }, []);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <HundredPercentFlexDivCenterItems>
              <Icon30px20pxPointer
                src="/backarrow.svg"
                onClick={() => {
                  history.push("/Store/" + params.Id);
                }}
              ></Icon30px20pxPointer>
              <LeagueSpartanExtraLargeBold>
                View Roles
              </LeagueSpartanExtraLargeBold>
            </HundredPercentFlexDivCenterItems>
          </FlexDiv70x7Percent>
          <GridDiv80xAutoPercent>
            {rolesMerge.map((o, i) => (
              <RoleCatCard
                key={i}
                RoleName={o.Role}
                RoleID={o.RoleType}
              ></RoleCatCard>
            ))}
          </GridDiv80xAutoPercent>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

RoleCatScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default RoleCatScreen;
