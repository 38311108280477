import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";

import { GetUserCases } from "../../API_Functions/CaseQuote_Functions";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import CaseStatusButton from "../../comps/CaseStatusButton";
import { MobileWideGreenButton } from "../../MobileComponents/Buttons";
import {
  Div100vw20vh,
  GreenBorderColumnDiv,
  MobileSpaceAroundDiv,
} from "../../MobileComponents/Divs";
import { TopImage40vh } from "../../MobileComponents/Images";
import {
  GreenLeagueText,
  MenuHeader,
  MobileBodyText,
  RegularGreenLeagueText,
} from "../../MobileComponents/Text";
import { GreenButtonWidth12VWHeight40 } from "../../StylesheetComps/AuthSheet";
import {
  DivWithMarginPadding,
  FlexColumnCont80vwAutoHeight,
  FlexColumnCont80x100Percent,
  FlexColumnContAutoHeightLeftAlign,
  FlexColumnFullWidth,
  FlexColumnFullWidthMargins,
  FlexContDashboard,
  FlexContDashboardHeightFixed,
  FlexContzIndex1,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import {
  Image200x200pxContain,
  RoundedImageFullWidth,
} from "../../StylesheetComps/Imgs/Images";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";
import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanExtraLargeWidth200px,
  RegularText,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import CalendlyWidget from "../../comps/CalendlyWidget";
import { FlexDivMargins } from "../../StylesheetComps/Div";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const DashboardScreen = () => {
  const [roleNum, setRoleNum] = useState(1000);
  const [recentUserCases, setRecentUserCases] = useState([]);
  const [mostRecentCase, setMostRecentCase] = useState([]);
  const history = useHistory();
  const [metroId, setMetroId] = useState(0);
  const [storeId, setStoreId] = useState(0);

  window.addEventListener("beforeinstallprompt", (ev) => {
    ev.preventDefault();

    ActionMenu.onclick = () => ev.prompt();
  });

  const GetCurrentProjects = async (userId) => {
    try {
      // Get the user cases
      const cases = await GetUserCases(userId);
  
      // If there are any cases, set the recent user cases and the most recent case
      if (cases.Cases && cases.Cases.length) {
        setRecentUserCases(cases.Cases.slice(0, 3));
        setMostRecentCase(cases.Cases.slice(0, 1));
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  const CheckStoreEstimates = async (metroId, storeId) => {
    try {
      // Make an HTTP request to the specified URL
      const resp = await axios.get(`${window.$DBURL}store/metro/${metroId}`);
  
      // Check the response status and ensure the data and stores properties are not null
      if (resp.status === 200 && resp.data && resp.data.stores) {
        if (storeId === 0) {
          // If there is more than one store, navigate to the store list page
          if (resp.data.stores.length !== 1) {
            history.push(`/storelistestimate/${metroId}`);
          } else {
            // If there is only one store, navigate to the estimate page for that store
            history.push(`/estimate/${metroId}/${resp.data.stores[0].Id}`);
          }
        } else {
          // If a store ID was specified, navigate to the estimate page for that store
          history.push(`/estimate/${metroId}/${storeId}`);
        }
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Test Supabase Data Loading
  

  useEffect(() => {
    // Set the header authorization
    SetHeaderAuthorization();
  
    // Get the user info from session storage
    const userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
  
    // Set the role number, current projects, metro ID, and store ID
    setRoleNum(userinfo.Role);
    GetCurrentProjects(userinfo.Id);
    setMetroId(userinfo.Metro_Id);
    setStoreId(userinfo.Store_Id);
  }, []);

  if (window.$ISMOBILE === true) {
    if (roleNum !== UserRole.Loading || roleNum !== UserRole.Manager || roleNum !== UserRole.Estimator) {
      return (
        <FlexContzIndex1>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>

          <FlexColumnCont80vwAutoHeight>
            <ActionMenu mobilePageProp="Home"></ActionMenu>

            <Div100vw20vh>
              <TopImage40vh src="/homescreenimg.png"></TopImage40vh>
            </Div100vw20vh>

            <FlexColumnCont
              flexdirection="row"
              height="98%"
              width="81%"
              margin="0.25%"
              marginbottom="0"
              mobileflexdirection="column"
              mobilepaddingbottom="15vh"
            >
              <FlexColumnCont height="90%" mobileflexdirection="column">
                <GreenBorderColumnDiv>
                  <GreenLeagueText>
                    Ready to Start Your Project?
                  </GreenLeagueText>
                  <MobileBodyText>
                    Start your project today and receive a free estimate.
                  </MobileBodyText>
                  <MobileWideGreenButton
                    onClick={() => {
                      CheckStoreEstimates(metroId, storeId);
                    }}
                  >
                    New Project
                  </MobileWideGreenButton>
                </GreenBorderColumnDiv>
              </FlexColumnCont>

              <MobileSpaceAroundDiv>
                <MenuHeader>Current Projects</MenuHeader>
                <RegularGreenLeagueText
                  fontsize="10px"
                  onClick={() => {
                    history.push("/CasesPersonal/" + metroId + "/" + storeId);
                  }}
                >
                  View All
                </RegularGreenLeagueText>
              </MobileSpaceAroundDiv>

              <br></br>

              {recentUserCases.map((o, i) => (
                <FlexColumnCont
                  key={i}
                  borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                  mobilejustifycontent="space-between"
                  mobilewidth="90%"
                  cursor="pointer"
                  onClick={() => {
                    history.push(
                      "/viewcaseuser/" +
                        o.Metro_Id +
                        "/" +
                        o.Store_Id +
                        "/" +
                        o.Id
                    );
                  }}
                  flexdirection="row"
                  justifycontent="flex-start"
                >
                  <FlexColumnCont width="32%" alignitems="left">
                    <SmallHeader
                      width="100%"
                      mobilefontsize="14px"
                      fontsize="16px"
                    >
                      {o.Tittle}
                    </SmallHeader>
                    <SmallHeader fontsize="16px">{o.Street}</SmallHeader>
                  </FlexColumnCont>
                  <FlexColumnCont
                    mobiledisplay="none"
                    wordwrap="break-word"
                    width="20%"
                    alignitems="left"
                  >
                    {o.CreateTime.substring(0, 10)}
                  </FlexColumnCont>
                  <FlexColumnCont width="18%" alignitems="left">
                    <CaseStatusButton Status={o.CaseStatus}></CaseStatusButton>
                  </FlexColumnCont>
                </FlexColumnCont>
              ))}
            </FlexColumnCont>
          </FlexColumnCont80vwAutoHeight>
        </FlexContzIndex1>
      );
    } else if (roleNum == UserRole.Manager || roleNum == UserRole.Estimator) {
      return (
        <FlexContzIndex1>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu mobilePageProp="Home"></ActionMenu>

          <Div100vw20vh>
            <TopImage40vh src="/homescreenimg.png"></TopImage40vh>
          </Div100vw20vh>

          <FlexColumnCont
            flexdirection="row"
            height="98%"
            width="81%"
            margin="0.25%"
            marginbottom="0"
            mobileflexdirection="column"
            mobilepaddingbottom="15vh"
          >
            <FlexColumnCont height="90%" mobileflexdirection="column">
              <GreenBorderColumnDiv>
                <GreenLeagueText>
                  Ready to Start Your Project?
                </GreenLeagueText>
                <MobileBodyText>
                  Start your project today and receive a free estimate.
                </MobileBodyText>
                <MobileWideGreenButton
                  onClick={() => {
                    CheckStoreEstimates(metroId, storeId);
                  }}
                >
                  New Project
                </MobileWideGreenButton>
              </GreenBorderColumnDiv>
            </FlexColumnCont>

            <MobileSpaceAroundDiv>
              <MenuHeader>Current Projects</MenuHeader>
              <RegularGreenLeagueText
                fontsize="10px"
                onClick={() => {
                  history.push("/CasesPersonal/" + metroId + "/" + storeId);
                }}
              >
                View All
              </RegularGreenLeagueText>
            </MobileSpaceAroundDiv>

            <br></br>

            {recentUserCases.map((o, i) => (
              <FlexColumnCont
                key={i}
                borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                mobilejustifycontent="space-between"
                mobilewidth="90%"
                cursor="pointer"
                onClick={() => {
                  history.push(
                    "/viewcaseuser/" +
                      o.Metro_Id +
                      "/" +
                      o.Store_Id +
                      "/" +
                      o.Id
                  );
                }}
                flexdirection="row"
                justifycontent="flex-start"
              >
                <FlexColumnCont width="32%" alignitems="left">
                  <SmallHeader
                    width="100%"
                    mobilefontsize="14px"
                    fontsize="16px"
                  >
                    {o.Tittle}
                  </SmallHeader>
                  <SmallHeader fontsize="16px">{o.Street}</SmallHeader>
                </FlexColumnCont>
                <FlexColumnCont
                  mobiledisplay="none"
                  wordwrap="break-word"
                  width="20%"
                  alignitems="left"
                >
                  {o.CreateTime.substring(0, 10)}
                </FlexColumnCont>
                <FlexColumnCont width="18%" alignitems="left">
                  <CaseStatusButton Status={o.CaseStatus}></CaseStatusButton>
                </FlexColumnCont>
              </FlexColumnCont>
            ))}
          </FlexColumnCont>
        </FlexColumnCont80vwAutoHeight>
      </FlexContzIndex1>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  } else {
    //Desktop
    if (roleNum !== UserRole.Loading) {
      return (
        <FlexContzIndex1>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>

          <FlexColumnFullWidth>
            <FlexColumnCont80x100Percent width="80vw">
              <FlexContDashboard>
                {/* <FlexRowContFullWidth>
                  <RoundedImageFullWidth src="/Dashboard_Golf.png"></RoundedImageFullWidth>

                  <FlexColumnCont margin="3%" height="auto">
                    <FlexColumnContAutoHeightLeftAlign>
                      <FlexColumnContAutoHeightLeftAlign>
                        <LeagueSpartanExtraLargeBold>
                          Start a New Project
                        </LeagueSpartanExtraLargeBold>
                        <RegularText>
                          New Project and receive a free cost estimate and construction plan. Once
                          approved, we'll schedule the work and start building!
                        </RegularText>
                      </FlexColumnContAutoHeightLeftAlign>
                      <br></br>
                      <GreenButtonWidth12VWHeight40
                        bgcolor={ColorPalette.PrimaryButtonBlue}
                        fontsize="1vw"
                        lineheight="290%"
                        border="4px solid white"
                        hoveropacity="75%"
                        onClick={() => {
                          CheckStoreEstimates(metroId, storeId);
                        }}
                      >
                        New Project
                      </GreenButtonWidth12VWHeight40>
                    </FlexColumnContAutoHeightLeftAlign>
                  </FlexColumnCont>
                </FlexRowContFullWidth> */}
                <FlexDiv>
                <RoundedImageFullWidth marginleft='1vw' height='90vh' width="40vw" objfit="cover" src="/Log In Image.png"></RoundedImageFullWidth>
                <CalendlyWidget></CalendlyWidget>

                </FlexDiv>

              </FlexContDashboard>

              {/* <FlexRowCont100PercentSpaceBetween>
                <FlexContDashboardHeightFixed
                  paddingtop="5vh"
                  paddingbottom="10vh"
                >
                  <FlexRowCont100PercentSpaceBetween>
                    <LeagueSpartanExtraLargeBold>
                      Recent Project
                    </LeagueSpartanExtraLargeBold>
                    <RegularGreenLeagueText
                      onClick={() => {
                        history.push(
                          "/CasesPersonal/" + metroId + "/" + storeId
                        );
                      }}
                    >
                      View All
                    </RegularGreenLeagueText>
                  </FlexRowCont100PercentSpaceBetween>

                  <br></br>

                  {mostRecentCase.map((o, i) => (
                    <FlexColumnCont
                      key={i}
                      cursor="pointer"
                      onClick={() => {
                        history.push(
                          "/viewcaseuser/" +
                            o.Metro_Id +
                            "/" +
                            o.Store_Id +
                            "/" +
                            o.Id
                        );
                      }}
                      flexdirection="row"
                      justifycontent="flex-start"
                    >
                      <FlexColumnCont
                        width="auto"
                        alignitems="left"
                        margin="0%"
                      >
                        <SmallHeaderLeagueSpartan
                          marginbottom="0px"
                          margintop="0px"
                        >
                          {o.Tittle}
                        </SmallHeaderLeagueSpartan>
                        <SmallHeader fontsize="16px" marginleft="0px">
                          {o.Street}
                        </SmallHeader>
                      </FlexColumnCont>
                    </FlexColumnCont>
                  ))}
                </FlexContDashboardHeightFixed>

                <FlexContDashboardHeightFixed paddingtop="0" paddingbottom="10vh">
                  <FlexRowContFullWidth>
                    <FlexColumnFullWidth>
                      <LeagueSpartanExtraLargeBold>
                        Support
                      </LeagueSpartanExtraLargeBold>

                      <br></br>
                      <FlexColumnCont
                        flexdirection="row"
                        justifycontent="flex-start"
                      >
                        <FlexColumnCont
                          width="auto"
                          alignitems="left"
                          margin="0%"
                        >
                          <SmallHeaderLeagueSpartan
                            marginbottom="0px"
                            margintop="0px"
                          >
                            Contact our team for help.
                          </SmallHeaderLeagueSpartan>

                          <SmallHeader fontsize="16px" marginleft="0px">
                            +1 (778) 800-3598
                          </SmallHeader>
                        </FlexColumnCont>
                      </FlexColumnCont>
                    </FlexColumnFullWidth>
                    <Image200x200pxContain src="/CustomerServiceIcon.svg"></Image200x200pxContain>
                  </FlexRowContFullWidth>
                </FlexContDashboardHeightFixed>
              </FlexRowCont100PercentSpaceBetween> */}
            </FlexColumnCont80x100Percent>
          </FlexColumnFullWidth>
        </FlexContzIndex1>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  }
};

DashboardScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default DashboardScreen;
