import React, { useState, useEffect } from "react";
import { AbsoluteCont, ImageContGallery } from "../../../StylesheetComps/Cont";
import { Icon60x60 } from "../../../StylesheetComps/Imgs/Icons";
import { ImageFullWidth } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { MediumHeader100PercentWidthWhiteText } from "../../../StylesheetComps/Titles";

const ImageViewWindow = ({ open, imgurl, onClose }) => {
  const [imageSource, setImageSource] = useState("");

  useEffect(() => {
    if (imgurl !== null && imgurl !== undefined && imgurl !== "") {
      setImageSource(imgurl);
    }
  }, [imgurl]);

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh onClick={onClose}>
        <AbsoluteCont>
          <ImageContGallery>
            <ImageFullWidth src={window.$IMGURL + imageSource}></ImageFullWidth>
            <MediumHeader100PercentWidthWhiteText
              display="none"
              mobiledisplay="block"
            >
              Click anywhere to close
            </MediumHeader100PercentWidthWhiteText>
            <Icon60x60
              zindex="1000"
              mobiledisplay="none"
              src="/Close2.svg"
            ></Icon60x60>
          </ImageContGallery>
        </AbsoluteCont>
      </FixedOverlay1000vh>{" "}
    </>
  );
};

ImageViewWindow.defaultProps = {
  onClose: () => {},
};

export default ImageViewWindow;
