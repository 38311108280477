import { Checkbox } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddQuoteItem,
  AddQuote_AccessArea,
  GetCaseById,
  GetQuoteById,
  GetQuoteObject,
  UpdateQuote_AccessArea,
} from "../../API_Functions/CaseQuote_Functions";
import {
  GetProduct,
  GetStoreCategories,
} from "../../API_Functions/CatProduct_Functions";
import {
  LargeResponsiveButton,
  RadioButtonInput100x30,
  TextInput300x50,
} from "../../StylesheetComps/AuthSheet";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexDiv,
  FlexRowCont,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import { GridContainer2Wide } from "../../StylesheetComps/Div";
import {
  FlexDivFlexStartCenter,
  Image300x200,
  SmallHeaderLeagueSpartan,
} from "../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../StylesheetComps/Menu";
import { Cont100PercentWidth } from "../../StylesheetComps/Quote";
import {
  RegularText,
  StyledHRGreyBreak,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";

const AccessAreaQuote = ({
  open,
  onNext,
  onPreview,
  onFormComplete,
  ReturnToPage,
  quoteid,
  GetQuoteInfo,
  desiredpage,
}) => {
  const params = useParams();

  const [imgMap, setImgMap] = useState([]);
  const [accessCat, setAccessCat] = useState([]);
  const [checkboxCheck, setCheckboxCheck] = useState(false);

  const [accessObj, setAccessObj] = useState({});

  const GetQuote = async (quoteid) => {
    const data = await GetQuoteById(quoteid);
    if (data && data.quote && data.quote.AccessArea) {
      setAccessObj(data.quote.AccessArea);
    }
  };
  

  const GetQuoteStructure = async () => {
    var data = await GetQuoteObject();
    if (data) {
      if (data.AccessArea) {
        setAccessObj(data.AccessArea);
      }
    }
  };

  const GetCaseImgs = async (caseid) => {
    var data = await GetCaseById(caseid);
    if (data) {
      setImgMap(data.Case.Photos);
    }
  };

  const GetAccessCat = async (storeid) => {
    var data = await GetStoreCategories(storeid);
    setAccessCat(
      data.cate.filter(
        (o) => o.CateType === CategoryTypes.Access && o.Products !== null
      )
    );
  };

  const PassValuesAsProps = async (
    productname,
    productcatetype,
    productid,
    productunitprice
  ) => {
    AddAccessItemToLocalArray(
      productname,
      productcatetype,
      productid,
      productunitprice
    );
    if (quoteid !== 0) {
      AddAccessAreaItemToQuote(
        quoteid,
        productname,
        productcatetype,
        productid,
        productunitprice
      );
    }
  };

  const AddAccessItemToLocalArray = (
    productname,
    productcatetype,
    productid,
    productunitprice
  ) => {
    accessObj.Items = [
      ...accessObj.Items,
      {
        Id: accessObj.Items.length,
        Name: productname,
        Product_Id: productid,
        CateType: productcatetype,
        UnitPrice: productunitprice,
        Notes: "",
        Quant: 1,
      },
    ];
  };

  const AddAccessAreaItemToQuote = async (
    quoteid,
    productname,
    productcatetype,
    productid,
    productunitprice
  ) => {
    var item = {
      Quote_Id: quoteid,
      Name: productname,
      Product_Id: productid,
      UnitPrice: productunitprice,
      Notes: "",
      CateType: productcatetype,
      Quant: 1,
      Unit: "",
    };
    var data = await AddQuoteItem(item);
    if (data) {
    }
  };

  const CreateOrUpdateQuoteAccessArea = async (accessobj, desiredpage) => {
    var data;
    if (accessobj.Quote_Id !== 0) {
      if (accessobj.Id === 0) {
        data = await AddQuote_AccessArea(accessobj);
        if (data) {
          ReturnToPage(desiredpage);
        }
      } else {
        var item = {
          Id: accessobj.Id,
          Width: accessobj.Width,
          Length: accessobj.Length,
          Elevation: accessobj.Elevation,
          Obstruct: accessobj.Obstruct,
        };
        data = await UpdateQuote_AccessArea(item);
        if (data) {
          GetQuoteInfo();
          ReturnToPage(desiredpage);
        }
      }
    }
  };

  useEffect(() => {
    if (open === false) {
      if (params.caseId !== undefined) {
        GetCaseImgs(params.caseId);
      }

      if (params.storeId !== undefined) {
        GetAccessCat(params.storeId);
      }

      if (params.quoteId !== undefined) {
        GetQuote(params.quoteId);
      } else {
        GetQuoteStructure();
      }
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      CreateOrUpdateQuoteAccessArea(accessObj, desiredpage);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const GetAccessItemInfo = async (productid) => {
    var data = await GetProduct(productid);
    if (data) {
      PassValuesAsProps(data.Name, data.CateType, data.Id, data.UnitPrice);
    }
  };

  if (open) return null;
  if (accessObj.Obstruct === undefined && quoteid !== 0) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  if (quoteid === 0) {
    return (
      <FlexColumnFullWidth>
        <FlexColumnContCenterAlign>
          <SmallHeaderLeagueSpartan>Access Area</SmallHeaderLeagueSpartan>

          <StyledHRGreyBreak></StyledHRGreyBreak>

          <FlexRowContFullWidth>
            <FlexColumnFullWidth>
              <Cont100PercentWidth>
                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Length (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Length = e.target.value;
                    }}
                    inputmargintop="0%"
                    inputheight="1%"
                    placeholder={accessObj.Length}
                  ></TextInput300x50>
                </FlexRowCont>
                <br></br>
                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Width (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Width = e.target.value;
                    }}
                    inputmargintop="0%"
                    inputheight="1%"
                    placeholder={accessObj.Width}
                  ></TextInput300x50>
                </FlexRowCont>

                <br></br>

                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Elevation (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Elevation = e.target.value;
                    }}
                    inputheight="1%"
                    inputmargintop="0%"
                    placeholder={accessObj.Elevation}
                  ></TextInput300x50>
                </FlexRowCont>
              </Cont100PercentWidth>
              <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>

              <FlexRowCont justifycontent="flex-start">
                <SmallHeaderLeagueSpartan>Obstruction</SmallHeaderLeagueSpartan>
                <FlexDivFlexStartCenter>
                  <RadioButtonInput100x30
                    type="radio"
                    onClick={() => {
                      accessObj.Obstruct = true;
                    }}
                    id="yes"
                    name="obstruction"
                  ></RadioButtonInput100x30>
                  <RegularText>Yes</RegularText>
                </FlexDivFlexStartCenter>

                <FlexDivFlexStartCenter>
                  <RadioButtonInput100x30
                    type="radio"
                    onClick={() => {
                      accessObj.Obstruct = false;
                    }}
                    id="no"
                    name="obstruction"
                  ></RadioButtonInput100x30>
                  <RegularText>No</RegularText>
                </FlexDivFlexStartCenter>
              </FlexRowCont>

              <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>

              <FlexColumnFullWidth>
                {accessCat.map((o, i) => (
                  <FlexDiv key={i}>
                    <SmallHeaderLeagueSpartan
                      onClick={() => {
                        setCheckboxCheck(false);
                      }}
                    >
                      {o.Name}
                    </SmallHeaderLeagueSpartan>
                    {o.Products.map((o, i) => (
                      <FlexDiv key={i}>
                        <Checkbox
                          color={ColorPalette.PrimaryButtonBlue}
                          type={"checkbox"}
                          checked={checkboxCheck[i]}
                          onClick={() => {
                            GetAccessItemInfo(o.Id);
                          }}
                        ></Checkbox>
                        <RegularText>{o.Name}</RegularText>
                      </FlexDiv>
                    ))}
                  </FlexDiv>
                ))}
              </FlexColumnFullWidth>
            </FlexColumnFullWidth>
          </FlexRowContFullWidth>
          <br></br>
          <RegularResponsiveButton
            onClick={() => {
              onPreview(accessObj);
              onFormComplete(accessObj);
            }}
          >
            Next
          </RegularResponsiveButton>

          <StyledHRGreyBreak></StyledHRGreyBreak>
          <br></br>
          <GridContainer2Wide>
            {imgMap.map((o, i) => (
              <GridContainer2Wide key={i}>
                <Image300x200 src={window.$IMGURL + o.File}></Image300x200>
                <SmallHeader></SmallHeader>
                <SmallHeader>{o.Caption}</SmallHeader>
              </GridContainer2Wide>
            ))}
          </GridContainer2Wide>
        </FlexColumnContCenterAlign>
      </FlexColumnFullWidth>
    );
  } else if (quoteid !== 0) {
    return (
      <FlexColumnFullWidth>
        <FlexColumnContCenterAlign>
          <SmallHeaderLeagueSpartan>Access Area</SmallHeaderLeagueSpartan>

          <br></br>
          <StyledHRGreyBreak></StyledHRGreyBreak>
          <br></br>

          <FlexRowContFullWidth>
            <FlexColumnFullWidth>
              <Cont100PercentWidth>
                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Length (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Length = e.target.value;
                    }}
                    inputheight="1%"
                    inputmargintop="0%"
                    placeholder={accessObj.Length}
                  ></TextInput300x50>
                </FlexRowCont>

                <br></br>

                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Width (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Width = e.target.value;
                    }}
                    inputheight="1%"
                    inputmargintop="0%"
                    placeholder={accessObj.Width}
                  ></TextInput300x50>
                </FlexRowCont>

                <br></br>

                <FlexRowCont justifycontent="flex-start">
                  <SmallHeaderLeagueSpartan>
                    Elevation (Feet)
                  </SmallHeaderLeagueSpartan>
                  <TextInput300x50
                    onChange={(e) => {
                      accessObj.Elevation = e.target.value;
                    }}
                    inputheight="1%"
                    inputmargintop="0%"
                    placeholder={accessObj.Elevation}
                  ></TextInput300x50>
                </FlexRowCont>
              </Cont100PercentWidth>
              <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>

              <FlexRowCont justifycontent="flex-start">
                <SmallHeaderLeagueSpartan>Obstruction</SmallHeaderLeagueSpartan>
                <FlexDivFlexStartCenter>
                  <RadioButtonInput100x30
                    type="radio"
                    onClick={() => {
                      accessObj.Obstruct = true;
                    }}
                    id="yes"
                    name="obstruction"
                    defaultChecked={accessObj.Obstruct === true ? true : false}
                  ></RadioButtonInput100x30>
                  <RegularText>Yes</RegularText>
                </FlexDivFlexStartCenter>

                <FlexDivFlexStartCenter>
                  <RadioButtonInput100x30
                    type="radio"
                    onClick={() => {
                      accessObj.Obstruct = false;
                    }}
                    id="no"
                    name="obstruction"
                    defaultChecked={accessObj.Obstruct === false ? true : false}
                  ></RadioButtonInput100x30>
                  <RegularText>No</RegularText>
                </FlexDivFlexStartCenter>
              </FlexRowCont>
              <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>
              <FlexColumnFullWidth>
                {accessCat.map((o, i) => (
                  <FlexDiv key={i}>
                    <SmallHeaderLeagueSpartan>
                      {o.Name}
                    </SmallHeaderLeagueSpartan>
                    {o.Products.map((o, i) => (
                      <FlexDiv key={i}>
                        <Checkbox
                          color="green"
                          type={"checkbox"}
                          checked={checkboxCheck[i]}
                          onClick={() => {
                            GetAccessItemInfo(o.Id);
                          }}
                        ></Checkbox>
                        <RegularText
                          onClick={() => {
                            GetAccessItemInfo(o.Id);
                          }}
                        >
                          {o.Name}
                        </RegularText>
                      </FlexDiv>
                    ))}
                  </FlexDiv>
                ))}
              </FlexColumnFullWidth>
            </FlexColumnFullWidth>
          </FlexRowContFullWidth>

          <LargeResponsiveButton
            onClick={() => {
              desiredpage = "quote";
              open = false;
              CreateOrUpdateQuoteAccessArea(accessObj, desiredpage);
            }}
          >
            Save Changes
          </LargeResponsiveButton>
          <br></br>
          <StyledHRGreyBreak></StyledHRGreyBreak>
          <br></br>
          <GridContainer2Wide>
            {imgMap.map((o, i) => (
              <GridContainer2Wide key={i}>
                <Image300x200 src={window.$IMGURL + o.File}></Image300x200>
                <SmallHeader></SmallHeader>
                <SmallHeader>{o.Caption}</SmallHeader>
              </GridContainer2Wide>
            ))}
          </GridContainer2Wide>
        </FlexColumnContCenterAlign>
      </FlexColumnFullWidth>
    );
  }
};

AccessAreaQuote.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  GetQuoteInfo: () => {},
  quoteId: 0,
};

export default AccessAreaQuote;
