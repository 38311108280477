// React import
import React from "react";

// Import of react-big-calendar CSS
import "react-big-calendar/lib/css/react-big-calendar.css";

// Import of react-datepicker CSS
import "react-datepicker/dist/react-datepicker.css";

// RegularResponsiveButton imported from the "Components" stylesheet
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

// FlexColumnContGreenBorderTopRounded and FlexColumnFullWidth imported from the "Cont" stylesheet
import {
  FlexColumnContGreenBorderTopRounded,
  FlexColumnFullWidth,
} from "../../StylesheetComps/Cont";

// FlexDivSpaceBetween imported from the "Div" stylesheet
import { FlexDivSpaceBetween } from "../../StylesheetComps/Div";

// FixedOverlay200vh imported from the "OverlayCont" stylesheet
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";

// Icon30px20pxPointer imported from the "Quote" stylesheet
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";

// RegularTextBoldRed imported from the "Titles" stylesheet
import { RegularTextBoldRed } from "../../StylesheetComps/Titles";

const ClockIn = ({ open, onClose, clockIn, clockOut, clockCheck }) => {
  if (!open) return null;
  if (clockCheck === "clockInNeeded") {
    return (
      <FixedOverlay200vh>
        <Icon30px20pxPointer
          src="/Close2.svg"
          onClick={onClose}
        ></Icon30px20pxPointer>
        <FlexColumnContGreenBorderTopRounded>
          <FlexColumnFullWidth>
            <FlexDivSpaceBetween>
              <RegularResponsiveButton
                onClick={() => {
                  clockIn();
                  onClose();
                }}
              >
                Clock In
              </RegularResponsiveButton>
            </FlexDivSpaceBetween>
          </FlexColumnFullWidth>
        </FlexColumnContGreenBorderTopRounded>
      </FixedOverlay200vh>
    );
  } else if (clockCheck === "clockOutNeeded") {
    return (
      <FixedOverlay200vh>
        <Icon30px20pxPointer
          src="/Close2.svg"
          onClick={onClose}
        ></Icon30px20pxPointer>
        <FlexColumnContGreenBorderTopRounded>
          <FlexColumnFullWidth>
            <FlexDivSpaceBetween>
              <RegularResponsiveButton
                onClick={() => {
                  clockOut();
                  onClose();
                }}
              >
                Clock Out
              </RegularResponsiveButton>
            </FlexDivSpaceBetween>
          </FlexColumnFullWidth>
        </FlexColumnContGreenBorderTopRounded>
      </FixedOverlay200vh>
    );
  } else if (clockCheck === "workerClockedForDay") {
    return (
      <FixedOverlay200vh>
        <Icon30px20pxPointer
          src="/Close2.svg"
          onClick={onClose}
        ></Icon30px20pxPointer>
        <FlexColumnContGreenBorderTopRounded>
          <FlexColumnFullWidth>
            <FlexDivSpaceBetween>
              <RegularTextBoldRed>
                You've already clocked in/out for this schedule, please contact
                your manager if an error was made.
              </RegularTextBoldRed>
            </FlexDivSpaceBetween>
          </FlexColumnFullWidth>
        </FlexColumnContGreenBorderTopRounded>
      </FixedOverlay200vh>
    );
  }
};

ClockIn.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onAppointmentEdit: () => {},
  CancelAppointment: () => {},
  MoveForward: () => {},
  DoNotMove: () => {},
  month: 0,
  year: 0,
  day: 0,
  dfName: "",
  dfAddress: "",
  dfCity: "",
  dfCellPhone: "",
  dfFlex: false,
  dfNotes: "",
  installerId: 0,
  appointmentTime: "",
  clockCheck: "",
};

export default ClockIn;
