import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import { useHistory } from "react-router-dom";
import { Header } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont82vw21vh,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  RelativeFlexContAutoWidth13vh,
} from "../../../StylesheetComps/Cont";
import {
  FlexColumnDivSpaceBetween,
  MenuMainCont,
  MobileMainCont,
} from "../../../StylesheetComps/Div";
import { Image60x50Percent } from "../../../StylesheetComps/Imgs/Images";
import {
  FlexColumnCont,
  SmallHeader,
  StickyTopMenuCont,
  TopMenuCont_AutoHeight,
} from "../../../StylesheetComps/Menu";
import Dropdown from "../../DropdownMenu";

const ActionMenuQuoteView = ({
  pageProp,
  firstlink,
  secondlink,
  thirdlink,
  fourthlink,
  fifthlink,
  ReturnToProject,
  SwitchToUserView,
}) => {
  const history = useHistory();

  return (
    <StickyTopMenuCont>
      <Cont82vw21vh>
        <MobileMainCont>
          <RelativeFlexContAutoWidth13vh>
            <Image60x50Percent
              src={"/mobileprofile.svg"}
              onClick={() => {
                history.push("/profile");
              }}
            ></Image60x50Percent>

            <AddToHomeScreen />
          </RelativeFlexContAutoWidth13vh>
        </MobileMainCont>
      </Cont82vw21vh>

      <MenuMainCont></MenuMainCont>
      <TopMenuCont_AutoHeight mobiledisplay="none">
        <FlexColumnCont
          marginbottom="0%"
          margin="0"
          width="100%"
          height="2vh"
        ></FlexColumnCont>

        <FlexColumnCont
          margin="0px"
          flexdirection="row"
          justifycontent="flex-start"
          width="98%"
          height="24%"
          alignitems="left"
        >
          <FlexRowCont100PercentSpaceBetween>
            <Header
              fontsize="160%"
              marginleft="2vw"
              margintop="0%"
              marginbottom="0%"
            >
              {pageProp}
            </Header>

            {/* <FlexDiv>
              <RegularResponsiveButton
                bgcolor={ColorPalette.SecondaryGreen}
                width="150px"
                onClick={() => {
                  ReturnToProject();
                }}
              >
                Return to Project
              </RegularResponsiveButton>
              <RegularResponsiveButton
                width="150px"
                onClick={() => {
                  SwitchToUserView();
                }}
              >
                User View
              </RegularResponsiveButton>
            </FlexDiv> */}
          </FlexRowCont100PercentSpaceBetween>
          <Dropdown currentPage={'estimatorView'} executeCallback={()=>{SwitchToUserView()}}></Dropdown>
        </FlexColumnCont>

        <FlexColumnCont
          margin="10px"
          width="100%"
          height="15%"
          margintop="0%"
          flexdirection="row"
          justifycontent="flex-start"
          alignitems="left"
        >
          <FlexColumnCont
            height="100%"
            width="100%"
            margin="0"
            marginleft="1vw"
            justifycontent="flex-start"
            alignitems="left"
            flexdirection="row"
          ></FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          margin="0"
          width="100%"
          height="20%"
          flexdirection="row"
          justifycontent="flex-start"
          alignitems="left"
          borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
        >
          <FlexColumnCont
            height="100%"
            margin="0"
            justifycontent="flex-start"
            alignitems="left"
            flexdirection="row"
            marginleft="2%"
          >
            <SmallHeader
              fontsize="16px"
              paddingbottom="8px"
              cursor="pointer"
              hovercolor={ColorPalette.PrimaryGrey}
              margin="8px"
              marginright="4%"
              onClick={() => {
                firstlink();
              }}
            >
              Estimate Information
            </SmallHeader>
            <SmallHeader
              cursor="pointer"
              paddingbottom="8px"
              fontsize="16px"
              hovercolor={ColorPalette.PrimaryGrey}
              margin="8px"
              marginright="4%"
              onClick={() => {
                secondlink();
              }}
            >
              Access Area
            </SmallHeader>
            <SmallHeader
              cursor="pointer"
              paddingbottom="8px"
              fontsize="16px"
              hovercolor={ColorPalette.PrimaryGrey}
              margin="8px"
              marginright="4%"
              onClick={() => {
                thirdlink();
              }}
            >
              Item Selection
            </SmallHeader>
            {/* <SmallHeader
              cursor="pointer"
              paddingbottom="8px"
              fontsize="16px"
              hovercolor={ColorPalette.PrimaryGrey}
              margin="8px"
              marginright="4%"
              onClick={() => {
                fourthlink()
              }}
            >
              Quote Info
            </SmallHeader> */}
            <SmallHeader
              cursor="pointer"
              paddingbottom="8px"
              fontsize="16px"
              hovercolor={ColorPalette.PrimaryGrey}
              margin="8px"
              marginright="4%"
              onClick={() => {
                fourthlink();
              }}
            >
              Grand Total
            </SmallHeader>
          </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          margin="0"
          width="100%"
          height="20%"
          flexdirection="row"
          justifycontent="flex-start"
          alignitems="left"
        >
          <FlexColumnCont
            height="100%"
            margin="0"
            justifycontent="flex-start"
            alignitems="left"
            flexdirection="row"
            marginleft="2%"
          ></FlexColumnCont>
        </FlexColumnCont>
      </TopMenuCont_AutoHeight>
    </StickyTopMenuCont>
  );
};

ActionMenuQuoteView.defaultProps = {
  pageProp: "",
  firstlink: () => {},
  secondlink: () => {},
  thirdlink: () => {},
  fourthlink: () => {},
  fifthlink: () => {},
  ReturnToProject: () => {},
  SwitchToUserView: () => {},
};

export default ActionMenuQuoteView;
