import React from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { Cont500x200pxFixed } from "../../../StylesheetComps/Cont";
import { Div400x75px, Div450x175px } from "../../../StylesheetComps/Div";
import { ExtraLargeTextMobileRegular } from "../../../StylesheetComps/Titles";

const CancelAppointmentWindow = ({
  deleteOpen,
  onClick,
  onClose,
  closeDel,
  onDeleteFunction,
}) => {
  if (!deleteOpen) return null;
  return (
    <Cont500x200pxFixed>
      <Div450x175px>
        <ExtraLargeTextMobileRegular>
          Are you sure you want to cancel this appointment?
        </ExtraLargeTextMobileRegular>
        <Div400x75px>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={closeDel}
          >
            No
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              closeDel();
              onDeleteFunction();
            }}
          >
            Yes
          </RegularResponsiveButton>
        </Div400x75px>
      </Div450x175px>
    </Cont500x200pxFixed>
  );
};

CancelAppointmentWindow.defaultProps = {
  onDeleteFunction: () => {},
};

export default CancelAppointmentWindow;
