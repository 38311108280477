import axios from "axios";
import React, { useEffect, useState } from "react";
//DATEPICKER IMPORTS
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import ClockList from "../../comps/ClockList";
import Menu from "../../comps/MenuComps/Menu";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFullWidth,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanExtraLargeBold,
  RegularText,
} from "../../StylesheetComps/Titles";

const ClockSchedule = () => {
  //================================================================================
  // Clock Schedule Screen. View/Adjust Schedules Clock In/Outs of a worker schedule
  //================================================================================

  //================================================================================
  // Managers / Accountants should be only ones with access
  //================================================================================

  const params = useParams();
  const [roleNum, setRoleNum] = useState(1000);
  const [workerName, setWorkerName] = useState("");
  const [clockMap, setClockMap] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [scheduleId, setScheduleId] = useState(0);

  const [clockId, setClockId] = useState(0);
  const [clockin, setClockIn] = useState(0);
  const [clockout, setClockOut] = useState(0);

  var newClockInSync = "";
  var newClockOutSync = "";

  var newClockInSyncUpdated = "default";
  var newClockOutSyncUpdated = "default";

  var selectedStartYear = startDate.getFullYear();
  var selectedStartMonth = startDate.getMonth() + 1;
  var selectedStartDate = startDate.getDate();

  var selectedEndYear = endDate.getFullYear();
  var selectedEndMonth = endDate.getMonth() + 1;
  var selectedEndDate = endDate.getDate();

  const GetWorkerClockRecords = async () => {
    const resp = await axios.post(window.$DBURL + "schedule/clock/user", {
      StartDate:
        selectedStartYear +
        "-" +
        selectedStartMonth +
        "-" +
        selectedStartDate +
        "T" +
        0 +
        0 +
        ":" +
        0 +
        0,
      EndDate:
        selectedEndYear +
        "-" +
        selectedEndMonth +
        "-" +
        selectedEndDate +
        "T" +
        23 +
        ":" +
        59,
      Id: params.userId,
    });
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.clock !== null) {
          setClockMap(resp.data.clock.Clocks);
          setWorkerName(resp.data.clock.Name);
        }
      }
    }
  };

  const HandleFormClock = async ({ adjClockInValue, adjClockOutValue }) => {
    newClockInSync = adjClockInValue;
    newClockOutSync = adjClockOutValue;
    if (adjClockInValue !== undefined && adjClockOutValue !== undefined) {
      newClockInSyncUpdated =
        clockin.substring(0, 10) + adjClockInValue + clockin.substring(16, 20);
      newClockOutSyncUpdated =
        clockout.substring(0, 10) +
        adjClockOutValue +
        clockout.substring(16, 20);
    } else if (adjClockOutValue !== undefined) {
      newClockInSyncUpdated = clockin;
      newClockOutSyncUpdated =
        clockout.substring(0, 11) +
        adjClockOutValue +
        clockout.substring(16, 20);
    } else if (adjClockInValue !== undefined) {
      newClockInSyncUpdated =
        clockin.substring(0, 11) + adjClockInValue + clockin.substring(16, 20);
      newClockOutSyncUpdated = clockout;
    }
  };

  const HandleAdjustment = async () => {
    if (newClockOutSync === undefined) {
      const resp = await axios.put(window.$DBURL + "schedule/clock", {
        Id: clockId,
        Schedule_Id: scheduleId,
        Crew_Id: params.userId,
        Crewname: params.workerName,
        Clockin: clockin,
        Clockout: clockout,
        AdjClockin: newClockInSyncUpdated || clockin,
      });
      if (resp.status === 200) {
      }
      newClockInSyncUpdated = "";
    } else if (newClockInSync === undefined) {
      const resp = await axios.put(window.$DBURL + "schedule/clock", {
        Id: clockId,
        Schedule_Id: scheduleId,
        Crew_Id: params.userId,
        Crewname: params.workerName,
        Clockin: clockin,
        Clockout: clockout,
        AdjClockout: newClockOutSyncUpdated || clockout,
      });
      if (resp.status === 200) {
      }
      newClockOutSyncUpdated = "";
    } else {
    }
    GetWorkerClockRecords();
  };

  useEffect(() => {
    GetWorkerClockRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetWorkerClockRecords();
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetWorkerClockRecords();
  }, [endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <FlexRowContFullWidth>
            <LeagueSpartanExtraLargeBold>
              {workerName}
            </LeagueSpartanExtraLargeBold>
            <FlexDiv>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </FlexDiv>
          </FlexRowContFullWidth>

          <FlexRowCont100PercentSpaceBetween>
            <RegularText>Index</RegularText>
            <RegularText>Date</RegularText>
            <RegularText>Clock-In</RegularText>
            <RegularText>Clock-Out</RegularText>
            <RegularText>Adj. Clock-In</RegularText>
            <RegularText>Adj. Clock-Out</RegularText>
          </FlexRowCont100PercentSpaceBetween>

          {clockMap.map((o, i) => (
            <ClockList
              key={i}
              indexNum={i + 1}
              year={o.Clockin.substring(0, 4)}
              month={o.Clockin.substring(5, 7)}
              day={o.Clockin.substring(8, 10)}
              clockin={o.Clockin}
              clockout={o.Clockout}
              adjclockin={o.AdjClockin}
              adjclockout={o.AdjClockout}
              roleID={roleNum}
              onPreview={HandleFormClock}
              onUpdateAdjClock={HandleAdjustment}
              onSetId={() => {
                setScheduleId(o.Schedule_Id);
                setClockId(o.Id);
                setClockIn(o.Clockin);
                setClockOut(o.Clockout);
              }}
            ></ClockList>
          ))}
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

ClockSchedule.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default ClockSchedule;
