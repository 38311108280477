/* eslint-disable */

//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  GetQuoteByIdForUserDisplay
} from "../../API_Functions/CaseQuote_Functions";
import {
  SetHeaderAuthorization
} from "../../API_Functions/Utility_Functions";
import BidItem from "../../comps/BidItem";

//  Imported Components ---------------------------------
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  RegularResponsiveButton
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import {
  DivWithDisplay,
  FlexColumnLeftAlign,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFullWidth,
  Grid_300Div
} from "../../StylesheetComps/Cont";
import { FlexContainerPhotos } from "../../StylesheetComps/Div";
import {
  Image300x200,
  Image375px,
  TextAreaAutoResize
} from "../../StylesheetComps/LandscapeForm";
import { Cont, ContentContQuote } from "../../StylesheetComps/Projects";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LargeText,
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  SmallText,
  StyledHRGreenBreakThick,
  StyledHRPropColour,
  TinyTextItalics
} from "../../StylesheetComps/Titles";

const BidQuotePage = () => {
  const history = useHistory();
  const params = useParams();
  const [Quote, setProposal] = useState({});
  const [Project, setProject] = useState({});
  const [toolsmachinesHeader, setToolsMachinesHeader] = useState("none");
  const [removalHeader, setRemovalHeader] = useState("none");
  const [accessDisplay, setAccessDisplay] = useState("none");
  const [generalMaterialsHeader, setGeneralMaterialsHeader] = useState("none");
  const [deliveryHeader, setDeliveryHeader] = useState("none");

  const [createBid, setCreateBid] = useState(false);

  const [showModifyInvoiceButton, setShowModifyInvoiceButton] = useState(true);
  const [showGenerateInvoiceButton, setShowGenerateInvoiceButton] =
    useState(true);
  const [showSaveInvoiceButton, setShowSaveInvoiceButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);

  const [showAttachToProjectButton, setShowAttachtoProjectButton] =
    useState(true);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [enterPaid, setEnterPaid] = useState(false);
  const [toTal, setTotal] = useState();
  const [Paid, setPaid] = useState(0.0);
  const [invoiceDate, setInvoiceDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState("0000");
  const [invoicefileMaterial, setInvoicefileMaterial] = useState(
    "invoice_material.pdf"
  );
  const [invoicefileInstall, setInvoicefileInstall] = useState(
    "invoice_install.pdf"
  );
  const ref = React.createRef();

  const [pdfOptions, setPdfOptions] = useState({
    orientation: "portrait",
    unit: "in",
    format: [8.5, 11],
    pagesplit: true,
  });

  var installSub = 0;
  var bidItem;

  const GetProjectInformation = async (caseid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      await setProject(data.Case);
      return data.Case;
    } else return null;
  };

  const GetProposalInformation = async (quoteid) => {
    var data = await GetQuoteByIdForUserDisplay(quoteid);
    if (data !== null) {
      if (data.quote !== null) {
        return data.quote;
      }
    } else return null;
  };

  const SetUpBidData = async ( quoteid) => {
    var quote = await GetProposalInformation(quoteid);
    if (quote !== null) {
      setProposal(quote);
      if (toTal === null || toTal === undefined)
        InitializeTotal({ ...quote.Material.Total });
      else InitializeTotal(toTal);
      
      var invoicedate = new Date();
      var duedate = new Date();
      duedate.setDate(duedate.getDate() + 7);
      setInvoiceDate(invoicedate.toLocaleDateString());
      
      SetQuoteDisplay(quote);
    }
  };

  const InitializeTotal = (total) => {
    if (total !== null && total !== undefined) {
      total.Subtotal = 0;
      total.Total = 0;
      if (total.Tax?.length > 0) {
        for (let i = 0; i < total.Tax.length; i++) {
          total.Tax[i].Amount = 0;
        }
      }
      if (toTal === null || toTal === undefined) setTotal(total);
    }
  };
  const SetQuoteDisplay = async (quote) => {
    if (quote != null) {
      if (quote.Material?.Items?.length > 0) setGeneralMaterialsHeader("flex");

      if (quote.Delivery?.Tools?.length > 0) setDeliveryHeader("flex");

      if (quote.Tool?.Tools?.length > 0) setToolsMachinesHeader("flex");

      if (quote.Removal?.Items?.length > 0) setRemovalHeader("flex");

      if (quote.AccessArea?.Items?.length > 0) setAccessDisplay("flex");
    }
  };

  const modifyInvoice = async () => {
    setCreateBid(true);
    setShowModifyInvoiceButton(false);
    setShowGenerateInvoiceButton(false);
    setShowCancelButton(true);
    setShowSaveInvoiceButton(true);
    ItemCounts = 0;
  };

  var ItemCounts = 0;
  const AddItemToInvoiceTotal = (total, tax, subtotal) => {
    if (subtotal > 0) ++ItemCounts;

    if (total !== null && total !== undefined) {
      total.Subtotal += subtotal;
      for (let i = 0; i < tax.length; i++) {
        var amt = (tax[i].Rate * subtotal) / 100;
        for (let j = 0; j < total.Tax?.length; j++) {
          if (total.Tax[j].Id === tax[i].Id) {
            total.Tax[j].Amount += amt;
            total.Total += amt;
          }
        }
      }
      total.Total += subtotal;
    }
  };

  const Save = async () => {
    InitializeTotal(toTal);
    setCreateBid(false);
    setShowCancelButton(false);
    setShowSaveInvoiceButton(false);
    setShowModifyInvoiceButton(true); //Modify button should be used only once to avoid errors.
    setShowGenerateInvoiceButton(true);
    setShowDownloadButton(false);
  };

  const Cancel = async () => {
    InitializeTotal(toTal);
    setCreateBid(false);
    setShowCancelButton(false);
    setShowSaveInvoiceButton(false);
    //setShowModifyInvoiceButton(true);
    setShowGenerateInvoiceButton(true);
  };

  const InitialSetup = async (casid) => {
    var project = await GetProjectInformation(casid);
    await SetUpBidData(project.Id);
  };

  const GenerateBidInfo = async (filename, project, tittle, quoteid) => {
    
  };
  const UploadBidInfo = async (invoice) => {
    //await AddInvoice(invoice);
  };

  const CreateBidRecord = async (filename, project, tittle, quoteid) => {
    var invoice = await GenerateBidInfo(filename, project, tittle, quoteid);
    await UploadBidInfo(invoice);
  };

  const AttachBidToProject = async (event) => {
    const file = event.target.files[0];
    var tittle = file.name;
    //var filename = await UploadFileToCloud(file);
    //await CreateInvoiceRecord(filename, Project, tittle, params.quoteId);
  };
  const hiddenFileInput = React.useRef(null);

  const AttachToProject = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    SetUpBidData( params.quoteId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(async () => {
    SetHeaderAuthorization();
    await InitialSetup(params.caseId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (Quote !== null && Quote !== undefined) {
    if (Quote.Material !== null && Quote.Material !== undefined) {
      InitializeTotal(toTal);
      return (
        <DivWithDisplay>
          <DivWithDisplay>
            <DivWithDisplay alignitems="baseline" margin="0">
              <ContentContQuote id="install">
                <DivWithDisplay height="auto">
                  {/* Top section: back arrow, title, buttons */}
                  <DivWithDisplay  >
                    <DivWithDisplay flexdirection="row">
                      <DivWithDisplay alignitems="left" >
                        <Icon30px30pxPointer
                          src="/leftarrow.svg"
                          onClick={() => {
                            history.push(
                              `/projectbidlist/${params.metroId}/${params.storeId}`
                            );
                          }}
                        ></Icon30px30pxPointer>
                      </DivWithDisplay>
                      <DivWithDisplay>
                        <LeagueSpartanRegular>
                          Bid Generator
                        </LeagueSpartanRegular>
                      </DivWithDisplay>
                      <DivWithDisplay></DivWithDisplay>
                    </DivWithDisplay>
                    <DivWithDisplay flexdirection="row" justifycontent="center">                 
                                
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={AttachBidToProject}
                        style={{ display: "none" }}
                      />
                    </DivWithDisplay>
                    <br></br>
                    <StyledHRPropColour
                      border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
                    ></StyledHRPropColour>
                    <br></br>
                  </DivWithDisplay>

                  <DivWithDisplay height="auto" ref={ref} flexdirection="row">
                    <DivWithDisplay width="25px"> </DivWithDisplay>
                    <DivWithDisplay>
                      <br></br> <br></br>
                      <DivWithDisplay >
                        {/* Start of the bid Section. Bidding instructions and logos*/}
                        <DivWithDisplay flexdirection="row">
                          {<DivWithDisplay alignitems="left">
                            <LeagueSpartanHeaderSmall>
                              Bidding Instructions:
                            </LeagueSpartanHeaderSmall>
                            <SmallText>1. Click Modify Bid if you want to change the subtotals of each item.</SmallText>
                            <SmallText>2. Click Edit button under the subtotal amount to change.</SmallText>
                            <SmallText>3. Click Save Bid after you change. Clieck Submit Bid to complete your bidding.</SmallText>
                            <SmallText>If you have questions, please contact us:</SmallText>
                            <SmallText>+1 778-700-3598</SmallText>
                            <SmallText>ar@newrhodesconstruction.com</SmallText>
                            <SmallText>www.newrhodesconstruction.com</SmallText>
                            
                          </DivWithDisplay> }
                          <Image300x200
                            height="auto"
                            src="/YHLogoTinyPNG.png"
                          ></Image300x200>
                        </DivWithDisplay>
                        <br></br>
                       
                        <br></br>
                        <br></br>
                        {/* Date */}
                        <FlexRowCont100PercentSpaceBetween>
                          
                          <FlexColumnLeftAlign>
                            <FlexDiv>
                              <FlexColumnLeftAlign>
                                

                                <LeagueSpartanHeaderSmall>
                                  DATE
                                </LeagueSpartanHeaderSmall>
                                <br></br>
                               
                              </FlexColumnLeftAlign>
                              <DivWithDisplay
                                width="20px"
                                heigt="20px"
                              ></DivWithDisplay>
                              <FlexColumnLeftAlign>
                                
                                <SmallText>{invoiceDate}</SmallText>
                                <br></br>
                                
                              </FlexColumnLeftAlign>
                            </FlexDiv>
                          </FlexColumnLeftAlign>
                        </FlexRowCont100PercentSpaceBetween>
                        <StyledHRPropColour
                          border={`0.5px solid ${ColorPalette.LightGreen}`}
                        ></StyledHRPropColour>

                        {/* Guides and photos */}
                        <DivWithDisplay>
                          {/*Guide notes*/}
                          <DivWithDisplay
                            id="overview"
                            display={Quote.Notes !== "" ? "block" : "none"}
                          >
                            <DivWithDisplay>
                              <DivWithDisplay flexdirection="row">
                                <LeagueSpartanLarge>Guide Notes</LeagueSpartanLarge>
                              </DivWithDisplay>
                              <DivWithDisplay flexdirection="row"
                                onClick={() => {
                                  DisplayProposalOverview(overviewToggle);
                                }}
                              >                 
                                {/* <Icon30x30 src={projectOverviewArrow}></Icon30x30>   */}
                              </DivWithDisplay>

                              <TextAreaAutoResize
                                id="text"
                                value={Quote.Notes}
                              ></TextAreaAutoResize>
                            </DivWithDisplay>
                          </DivWithDisplay>
                          <DivWithDisplay height="40px"></DivWithDisplay>
                          <DivWithDisplay
                            display={Quote.Photos.length > 0 ? "block" : "none"}
                          >
                            <DivWithDisplay>
                              <DivWithDisplay alignitems="left">
                                <LeagueSpartanLarge>
                                  Construction Plans
                                </LeagueSpartanLarge>
                              </DivWithDisplay>
                               <DivWithDisplay height="40px"></DivWithDisplay>
                               {/*Photo display */}
                              <DivWithDisplay>
                                <Grid_300Div>
                                  {Quote.Photos.map((o, i) => {
                                    return o.File?.includes(".pdf") ||
                                      o.File?.includes(".PDF") ? (
                                      <FlexContainerPhotos key={i}>
                                        <a
                                          id={i}
                                          rel="noreferrer"
                                          href={o.File !== "" ? window.$IMGURL + o.File : ""}
                                          target={"_blank"}
                                          style={{ textDecoration: "none", color: "black" }}
                                        >
                                          <embed
                                            target={"_blank"}
                                            src={"/pdficon.png"}
                                            width={"150px"}
                                            style={{ backgroundColor: "white" }}
                                            height={"200px"}
                                          ></embed>
                                          <DivWithDisplay>
                                            <LargeText>{o.Caption}</LargeText>
                                          </DivWithDisplay>
                                        </a>
                                      </FlexContainerPhotos>
                                    ) : (
                                      <FlexContainerPhotos key={i}>
                                        <Image375px
                                          onClick={() => {
                                            DisplayImageInGallery(o.File);
                                          }}
                                          src={o.File !== "" ? window.$IMGURL + o.File : ""}
                                        ></Image375px>
                                        <DivWithDisplay>
                                          <LargeText>{o.Caption}</LargeText>
                                        </DivWithDisplay>
                                      </FlexContainerPhotos>
                                    );
                                  })}
                                </Grid_300Div>
                              </DivWithDisplay>
                            </DivWithDisplay>
                          </DivWithDisplay>
                        </DivWithDisplay>

                        <DivWithDisplay height="80px"></DivWithDisplay>
                         
                        {/* Modify and submit bid buttons */}
                        <DivWithDisplay flexdirection="row">
                          <RegularResponsiveButton
                            display={
                              showSaveInvoiceButton === true ? "block" : "none"
                            }
                            width="125px"
                            onClick={() => {
                              Save();
                            }}
                          >
                            Save Bid
                          </RegularResponsiveButton>
                          <RegularResponsiveButton
                          display={
                            showModifyInvoiceButton === true ? "block" : "none"
                          }
                          width="125px"
                          bgcolor={ColorPalette.DarkGrey}
                          onClick={() => {
                            InitializeTotal(toTal);
                            modifyInvoice();
                          }}
                        >
                          Modify Bid
                          </RegularResponsiveButton>
                        

                          <RegularResponsiveButton
                            display={
                              showAttachToProjectButton === true ? "block" : "none"
                            }
                            width="125px"
                            bgcolor={ColorPalette.SecondaryGreen}
                            onClick={(e) => {
                              AttachToProject(e);
                            }}
                          >
                            Submit Bid 
                          </RegularResponsiveButton>
                        </DivWithDisplay>
                        {/* Start of the Invoice Items. Items below are conditionally rendered */}
                        
                        {
                          <DivWithDisplay>
                            <BidItem
                              isHeader={true}
                              header2="Item"
                              header3="Description"
                              header4="Hours"
                              header5="Quantity"
                              header6="Install Rate"
                              header7=""
                              header8="Subtotal"
                              bgColor={ColorPalette.White}
                            ></BidItem>
                            <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                            {Quote.Tags.map((t, j) => (
                              <DivWithDisplay>
                                <DivWithDisplay>
                                  {Quote.Material.Items.filter(
                                    (item) =>
                                      item.Tag_Id == t.Id &&
                                      item.Parent_Id === 0
                                  ).map(
                                    (o, i) => (
                                      (bidItem = o),
                                      (installSub =
                                        bidItem.LaborSubtotal),
                                      AddItemToInvoiceTotal(
                                        toTal,
                                        bidItem.Tax,
                                        installSub
                                      ),
                                      (
                                        <DivWithDisplay
                                          display={
                                            bidItem.Quant > 0
                                              ? "flex"
                                              : "none"
                                          }
                                        >
                                          <BidItem
                                            showRelatedItems={true}
                                            noChange={bidItem.NoChange}
                                            key={i}
                                            createBid={createBid}
                                            quoteItemObj={bidItem}
                                            bgColor={ColorPalette.White}
                                            parentid={bidItem.Parent_Id}
                                            OutputValue={(value) => {
                                              bidItem = value;
                                            }}
                                            header1="Delete"
                                            header2={bidItem.Name}
                                            header3={bidItem.Notes}
                                            header4={(bidItem.Quant*bidItem.InstallSpeed).toFixed(2)}
                                            header5={
                                              bidItem.Quant.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) +
                                              " " +
                                              bidItem.DisplayUnit
                                            }
                                            header6={
                                              "$ " +
                                              (
                                                installSub / bidItem.Quant
                                              ).toFixed(4)
                                            }
                                            header7={""}
                                            header8={
                                              
                                              installSub
                                                .toFixed(2)
                                                
                                            }
                                          ></BidItem>
                                        </DivWithDisplay>
                                      )
                                    )
                                  )}
                                </DivWithDisplay>
                              </DivWithDisplay>
                            ))}

                            <DivWithDisplay>
                              {/* display={generalMaterialsHeader} */}

                              {Quote.Material.Items.map((o, i) => {
                                return o.Parent_Id === 0 && o.Tag_Id === 0
                                  ? ((bidItem = o),
                                    (installSub =
                                      o.LaborSubtotal),
                                    AddItemToInvoiceTotal(
                                      toTal,
                                      bidItem.Tax,
                                      installSub
                                    ),
                                    (
                                      <DivWithDisplay
                                        display={
                                          bidItem.Quant > 0
                                            ? "flex"
                                            : "none"
                                        }
                                      >
                                        <BidItem
                                          showRelatedItems={true}
                                          key={i}
                                          quoteItemObj={bidItem}
                                          createBid={createBid}                                      
                                          OutputValue={(value) => {
                                            bidItem = value;
                                          }}
                                          noChange={bidItem.NoChange}
                                          parentid={bidItem.Parent_Id}
                                          bgColor={ColorPalette.White}
                                          header1="Delete"
                                          header2={bidItem.Name}
                                          header3={bidItem.Notes}
                                          header4={(bidItem.Quant*bidItem.InstallSpeed).toFixed(2)}
                                          header5={
                                            bidItem.Quant.toFixed(
                                              2
                                            ).replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            ) +
                                            " " +
                                            bidItem.DisplayUnit
                                          }
                                          header6={
                                            "$ " +
                                            (
                                              installSub / bidItem.Quant
                                            ).toFixed(4)
                                          }
                                          header7={""}
                                          header8={
                                           
                                            installSub
                                              .toFixed(2)
                                              
                                          }
                                        ></BidItem>
                                      </DivWithDisplay>
                                    ))
                                  : null;
                              })}
                            </DivWithDisplay>

                            {Quote.Delivery.Tools.map(
                              (o, i) => (
                                (bidItem = o),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  bidItem.Tax,
                                  bidItem.Price
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      bidItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <BidItem
                                      key={i}
                                      createBid={createBid}
                                      quoteItemObj={bidItem}
                                      OutputValue={(value) => {
                                        bidItem = value;
                                      }}
                                      header1=" "
                                      header2={bidItem.ToolName}
                                      header3={bidItem.Notes}
                                      header5={bidItem.Quant.toFixed(
                                        2
                                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      header6={
                                        "$" +
                                        bidItem.BasePrice.toFixed(
                                          2
                                        ).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        "/ Load"
                                      }
                                      header8={
                                        
                                        bidItem.Price.toFixed(2)
                                      }
                                      header7={""}
                                      bgColor={ColorPalette.White}
                                    ></BidItem>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.Removal.Items.map(
                              (o, i) => (
                                (bidItem = o),
                                (installSub =
                                  bidItem.Price +
                                  bidItem.LaborSubtotal),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  bidItem.Tax,
                                  installSub
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      bidItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <BidItem
                                      showRelatedItems={true}
                                      key={i}
                                      quoteItemObj={bidItem}
                                      createBid={createBid}
                                      OutputValue={(value) => {
                                        bidItem = value;
                                      }}
                                      noChange={bidItem.NoChange}
                                      parentid={bidItem.Parent_Id}
                                      bgColor={ColorPalette.White}
                                      header1="Delete"
                                      header2={bidItem.Name}
                                      header3={bidItem.Notes}
                                      header4={""}
                                      header5={
                                        bidItem.Quant.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        " " +
                                        bidItem.DisplayUnit
                                      }
                                      header6={
                                        "$ " +
                                        (
                                          installSub / bidItem.Quant
                                        ).toFixed(4)
                                      }
                                      header7={""}
                                      header8={
                                       
                                        installSub
                                          .toFixed(2)
                                          
                                      }
                                    ></BidItem>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.AccessArea.Items.map(
                              (o, i) => (
                                (bidItem = o),
                                (installSub =
                                  bidItem.SubtotalWithRelatedItems +
                                  bidItem.LaborSubtotal),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  bidItem.Tax,
                                  installSub
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      bidItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <BidItem
                                      showRelatedItems={true}
                                      key={i}
                                      quoteItemObj={bidItem}
                                      createBid={createBid}                                   
                                      OutputValue={(value) => {
                                        bidItem = value;
                                      }}
                                      noChange={bidItem.NoChange}
                                      parentid={bidItem.Parent_Id}
                                      bgColor={ColorPalette.White}
                                      header1="Delete"
                                      header2={bidItem.Name}
                                      header3={bidItem.Notes}
                                      header4={""}
                                      header5={
                                        bidItem.Quant.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        " " +
                                        bidItem.DisplayUnit
                                      }
                                      header6={
                                        "$ " +
                                        (
                                          installSub / bidItem.Quant
                                        ).toFixed(4)
                                      }
                                      header7={""}
                                      header8={
                                        
                                        installSub
                                          .toFixed(2)
                                         
                                      }
                                    ></BidItem>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.Tool.Tools.map((o, i) =>
                              o.BasePrice > 0.1 || o.RentalRate > 0.1
                                ? ((bidItem = o),
                                  AddItemToInvoiceTotal(
                                    toTal,
                                    bidItem.Tax,
                                    bidItem.Price
                                  ),
                                  (
                                    <DivWithDisplay
                                      display={
                                        bidItem.Quant > 0 ? "flex" : "none"
                                      }
                                    >
                                      <BidItem
                                        key={i}
                                        createBid={createBid}
                                        quoteItemObj={bidItem}
                                        OutputValue={(value) => {
                                          bidItem = value;
                                        }}
                                        header1=" "
                                        header2={bidItem.ToolName}
                                        subheader2={
                                          bidItem.Notes !== null
                                            ? bidItem.Notes
                                            : ""
                                        }
                                        header3={
                                          "$ " +
                                          bidItem.BasePrice.toFixed(
                                            2
                                          ).replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        }
                                        header5={
                                          bidItem.RentalRate > 0.1
                                            ? bidItem.RentalLength.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) + " hr"
                                            : bidItem.Quant.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) + " pc"
                                        }
                                        header6={
                                          "$" +
                                          bidItem.RentalRate.toFixed(
                                            2
                                          ).replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        }
                                        header8={
                                          
                                          bidItem.Price.toFixed(2)
                                        }
                                        header7={""}
                                        bgColor={ColorPalette.White}
                                      ></BidItem>
                                    </DivWithDisplay>
                                  ))
                                : null
                            )}

                            <br></br>
                            <StyledHRPropColour
                              border={`0.5px dotted ${ColorPalette.PrimaryGrey}`}
                            ></StyledHRPropColour>
                            <br></br>
                            <br></br>
                            <FlexRowContFullWidth margintop="100px">
                              <DivWithDisplay>
                                <TinyTextItalics>
                                  We appreciate your business and hope you
                                  consider us for any future projects!
                                </TinyTextItalics>
                              </DivWithDisplay>
                              <DivWithDisplay width="50%" flexdirection="row">
                                <FlexRowCont100PercentSpaceBetween>
                                  <DivWithDisplay textalign="left">
                                    <SmallText>SUBTOTAL</SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        {o.TaxCode} @ {o.Rate}%
                                      </SmallText>
                                    ))}
                                    <SmallText>TOTAL</SmallText>
                                     
                                  </DivWithDisplay>

                                  <DivWithDisplay textalign="right">
                                    <SmallText>
                                      $
                                      {toTal?.Subtotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        $
                                        {o.Amount.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </SmallText>
                                    ))}

                                    <SmallText>
                                      $
                                      {toTal?.Total.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                     
                                    
                                     
                                  </DivWithDisplay>
                                </FlexRowCont100PercentSpaceBetween>
                              </DivWithDisplay>
                            </FlexRowContFullWidth>
                          </DivWithDisplay>
                        }
                      </DivWithDisplay>
                    </DivWithDisplay>
                  </DivWithDisplay>
                </DivWithDisplay>
              </ContentContQuote>
            </DivWithDisplay>
          </DivWithDisplay>

          {/* <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop> */}
        </DivWithDisplay>
      );
    } else {
      return (
        <Cont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    return (
      <Cont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

BidQuotePage.defaultProps = {};

export default BidQuotePage;
