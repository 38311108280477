import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { ColorPalette } from '../../StylesheetComps/Colors';

const LoadingBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${props => props.width}%;
  height: 4px;
  background-color: ${ColorPalette.PrimaryRed};
  transition: width 0.5s ease;
//   transform: translateY(${props => props.show ? '0' : '-100%'});
  z-index: 9999;
`;

const LoadingIndicator = () => {
  const [activeRequests, setActiveRequests] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [show, setShow] = useState(true);
  let lastScrollY = window.pageYOffset;

  useEffect(() => {
    const updateVisibilityOnScroll = () => {
      const currentScrollY = window.pageYOffset;
      setShow(currentScrollY <= lastScrollY);
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', updateVisibilityOnScroll);

    return () => {
      window.removeEventListener('scroll', updateVisibilityOnScroll);
    };
  }, []);

  useEffect(() => {
    const handleRequestStart = () => {
      setActiveRequests(prev => prev + 1);
      setTotalRequests(prev => prev + 1);
    };
    const handleRequestFinish = () => {
      setActiveRequests(prev => prev - 1);
    };

    const requestInterceptor = axios.interceptors.request.use(config => {
      handleRequestStart();
      return config;
    }, error => {
      handleRequestFinish();
      return Promise.reject(error);
    });

    const responseInterceptor = axios.interceptors.response.use(response => {
      handleRequestFinish();
      return response;
    }, error => {
      handleRequestFinish();
      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const progress = totalRequests > 0 ? ((totalRequests - activeRequests) / totalRequests) * 100 : 0;
  const width = activeRequests > 0 ? Math.max(progress, 5) : 0; // Ensuring a minimum width for better visibility

  return <LoadingBar width={width} show={show}/>;
};

export default LoadingIndicator;
