import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AddInvoice } from "../../../API_Functions/CaseQuote_Functions";
import {
  GetCurrentTime,
  UploadFileToCloud,
} from "../../../API_Functions/Utility_Functions";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
  FlexRowCont100x50Percent,
} from "../../../StylesheetComps/Cont";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  RegularTextBoldRed,
  SmallHeaderLeagueSpartanWidth100Percent,
} from "../../../StylesheetComps/Titles";

const AddInvoiceWindow = ({
  open,
  CloseWindow,
  RefreshProjectUI,
  invoiceobj,
}) => {
  const params = useParams();

  const [uploadText, setUploadText] = useState("");
  const [errorText, setErrorText] = useState("");

  const UploadInvoice = async (e) => {
    if (e.target.files[0] !== null) {
      var file = e.target.files[0];
      var pic = await UploadFileToCloud(file);
      if (pic !== null) {
        setUploadText("File uploaded successfully");
        invoiceobj.File = pic;
        setErrorText("");
      }
    }
  };

  const AddInvoiceToProject = async (caseid, invoiceobj) => {
    if (caseid !== 0) {
      invoiceobj.Case_Id = caseid;
      invoiceobj.Upload_Time = await GetCurrentTime();
      var data = await AddInvoice(invoiceobj);
      if (data !== null) {
        ResetWindow();
        RefreshProjectUI();
      } else {
        setErrorText("Error Uploading your Invoice, please try later.");
      }
    }
  };

  const ResetWindow = async () => {
    CloseWindow();
    setErrorText("");
    setUploadText("");
  };

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <Cont100PercentWidth>
          <br></br>

          <FlexRowCont justifycontent="flex-start">
            <SmallHeaderLeagueSpartanWidth100Percent>
              Add an invoice to this project:
            </SmallHeaderLeagueSpartanWidth100Percent>
            <TextInput300x50
              type="file"
              onChange={(e) => {
                UploadInvoice(e);
              }}
            ></TextInput300x50>
          </FlexRowCont>

          <br></br>

          <FlexRowCont justifycontent="flex-start">
            <SmallHeaderLeagueSpartanWidth100Percent>
              Invoice Number
            </SmallHeaderLeagueSpartanWidth100Percent>
            <TextInput300x50
              placeholder="Invoice #"
              onChange={(e) => {
                invoiceobj.Number = e.target.value;
              }}
              inputheight="1%"
              inputmargintop="0%"
            ></TextInput300x50>
          </FlexRowCont>

          <br></br>

          <FlexRowCont justifycontent="flex-start">
            <SmallHeaderLeagueSpartanWidth100Percent>
              Address
            </SmallHeaderLeagueSpartanWidth100Percent>
            <TextInput300x50
              placeholder="Address"
              onChange={(e) => {
                invoiceobj.Address = e.target.value;
              }}
              inputheight="1%"
              inputmargintop="0%"
            ></TextInput300x50>
          </FlexRowCont>
        </Cont100PercentWidth>

        <LeagueSpartanHeaderSmall>{uploadText}</LeagueSpartanHeaderSmall>
        <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
        <FlexRowCont100x50Percent>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              ResetWindow();
            }}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            onClick={() => {
              AddInvoiceToProject(params.caseId, invoiceobj);
            }}
          >
            Save Invoice
          </RegularResponsiveButton>
        </FlexRowCont100x50Percent>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

AddInvoiceWindow.defaultProps = {
  CloseWindow: () => {},
  ContinueRevising: () => {},
  newCreatedRevision: () => {},
  RefreshProjectUI: () => {},
};

export default AddInvoiceWindow;
