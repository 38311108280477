import axios from "axios";
import React, { useEffect, useState } from "react";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import StoreListInstallerCard from "../../comps/StoreListInstallerCard";
import { useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Div70PerWidth7PerHeight,
  FlexCont80PercentMobileOpt,
  FlexRowContFullWidth,
  LeftCont,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const StoreListInstallerScreen = () => {
  //=============================================================================
  // Installer Store List Screen - If there is more than 1 store in the user's
  // Metro, display the stores available in that Metro.
  //=============================================================================

  //=============================================================================
  // Redirects to View Installers Page onClick
  //=============================================================================

  const params = useParams();

  //AXIOS
  const [storeList, setStoreList] = useState([]);
  const [roleNum, setRoleNum] = useState(1000);

  const GetStoreList = async (metro_id) => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + metro_id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          setStoreList(resp.data.stores);
        }
      }
    }
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  useEffect(() => {
    GetStoreList(params.Id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (roleNum !== 1000) {
    return (
      <FlexRowContFullWidth>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <RightCont>
          <ActionMenu />
          <RightViewFullWidth>
            <Div70PerWidth7PerHeight>
              <LeagueSpartanExtraLargeBold>
                View Installers by Store
              </LeagueSpartanExtraLargeBold>
            </Div70PerWidth7PerHeight>
            <FlexCont80PercentMobileOpt>
              {storeList.map((o, i) => (
                <StoreListInstallerCard
                  key={i}
                  CatHeadText={o.Name}
                  CatParaText={o.Address}
                  catId={o.Id}
                ></StoreListInstallerCard>
              ))}
            </FlexCont80PercentMobileOpt>
          </RightViewFullWidth>
        </RightCont>
      </FlexRowContFullWidth>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

StoreListInstallerScreen.defaultProps = {};

export default StoreListInstallerScreen;
