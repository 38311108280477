import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { subDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Menu from "../../comps/MenuComps/Menu";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import { MobileInput } from "../../MobileComponents/Inputs";
import {
  SelectableMediumLongButton,
  TextInputProp,
} from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  RegularResponsiveButton,
  SelectionInputRegular,
} from "../../StylesheetComps/Components";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContCenterAlign,
  FlexContMobileColumn,
  FlexDiv,
  FlexRowCont60PercentSpaceEvenly,
  FlexRowCont70PercentSpaceBetween,
  FlexRowContFlexStart,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import {
  BlankDiv,
  FlexColumnDiv,
  FlexColumnStartDiv45Percent,
  FlexDivFlexEnd,
  FlexDivSpaceAround,
  HiddenDivSpaceAroundColumn,
} from "../../StylesheetComps/Div";
import { BlankOption } from "../../StylesheetComps/Inputs";
import { FlexColumnGreenSelectables } from "../../StylesheetComps/LandscapeForm";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import {
  BlueUnderlinePointerPara,
  LeagueSpartanExtraLargeBold,
  LeagueSpartanRegular,
  MediumHeader,
  PropDiv,
  RegularTextBold,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";

const InstallerSetupScreen = () => {
  //=============================================================================
  // Installer Availability Screen - Business Hour Setup for Installers
  //=============================================================================

  //=============================================================================
  // Installer creates Open/Close Days, Vacation Days, TimeSlots for Appointment
  //=============================================================================

  const [roleNum, setRoleNum] = useState(1000);
  const [inputDisplayProp, setInputDisplayProp] = useState("none");
  const [timeSlotDisplayProp, setTimeSlotDisplayProp] = useState("none");
  const [EditTimeSlotDisplayProp, setEditTimeSlotDisplayProp] =
    useState("none");
  const [vacationDisplay, setVacationDisplay] = useState("none");
  const [editVacationDisplay, setEditVacationDisplay] = useState("none");
  const [daySelected, setDaySelected] = useState(0);
  const [editName, setEditName] = useState("");
  const [vacationId, setVacationId] = useState(0);

  const [openHourSelected, setOpenHourSelected] = useState(0);
  const [openMinuteSelected, setOpenMinuteSelected] = useState(0);
  const [closeHourSelected, setCloseHourSelected] = useState(0);
  const [closeMinuteSelected, setCloseMinuteSelected] = useState(0);

  const [editOpenHourSelected, setEditOpenHourSelected] = useState(0);
  const [editOpenMinuteSelected, setEditOpenMinuteSelected] = useState(0);
  const [editCloseHourSelected, setEditCloseHourSelected] = useState(0);
  const [editCloseMinuteSelected, setEditCloseMinuteSelected] = useState(0);

  const [holidayName, setHolidayName] = useState("");
  const [vacationYear, setVacationYear] = useState(0);
  const [vacationMonth, setVacationMonth] = useState(0);
  const [vacationDay, setVacationDay] = useState(0);

  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);

  const [timeSlotId, setTimeSlotId] = useState(0);

  const [deleteVacation, setDeleteVacation] = useState(false);
  const [deleteSlot, setDeleteSlot] = useState(false);
  const [deleteCloseDay, setDeleteCloseDay] = useState(false);

  const [deleteDayId, setDeleteDayId] = useState(0);
  const [openDays, setOpenDay] = useState([]);
  const [closeDays, setCloseDays] = useState([]);
  const [vacationDays, setVacationDays] = useState([]);

  var openDaysSync = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //Set Info about the User to be used in functions

  const GetSessionInfo = async () => {
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
    GetTimeSlots(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    GetVacationDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    GetCloseDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
  };

  //Using the user's Id, get days of the week that the installer is CLOSED

  const GetCloseDays = async (userId) => {
    const resp = await axios.get(
      window.$DBURL + "appointment/close/installer/" + userId
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.closedays !== null) {
          setCloseDays(resp.data.closedays);
          setOpenDay(
            openDaysSync.filter(function (o) {
              return !resp.data.closedays
                .map(({ WeekDay }) => WeekDay)
                .includes(o);
            })
          );
          switch (
            openDaysSync.filter(function (o) {
              return !resp.data.closedays
                .map(({ WeekDay }) => WeekDay)
                .includes(o);
            })[0]
          ) {
            case "Sunday":
              setDaySelected(0);
              break;
            case "Monday":
              setDaySelected(1);
              break;
            case "Tuesday":
              setDaySelected(2);
              break;
            case "Wednesday":
              setDaySelected(3);
              break;
            case "Thursday":
              setDaySelected(4);
              break;
            case "Friday":
              setDaySelected(5);
              break;
            case "Saturday":
              setDaySelected(6);
              break;
            default:
          }
          // document.getElementById("daysofweek").selectedIndex = 0;
        }
      }
    }
  };

  //Installer adds a day of the week that they are not open for appointments

  const AddCloseDay = async () => {
    try {
      // Make an HTTP request to the specified URL with the selected day
      const resp = await axios.post(`${window.$DBURL}appointment/close/`, {
        WeekDay: daySelected,
      });

      // If the request was successful, get the close days
      if (resp.status === 200) {
        GetCloseDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Installer deletes a day of the week that they are not open for appointments

  const DeleteCloseDay = async (deleteDayId) => {
    try {
      // Make an HTTP request to the specified URL with the delete day ID
      const resp = await axios.delete(
        `${window.$DBURL}appointment/close/${deleteDayId}`
      );

      // If the request was successful, get the close days
      if (resp.status === 200) {
        GetCloseDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Get Dates that the Installer is closed for business (Vacation/Holiday/etc.)

  const GetVacationDays = async (userId) => {
    try {
      // Make an HTTP request to the specified URL with the user ID
      const resp = await axios.get(
        `${window.$DBURL}appointment/vacation/user/${userId}`
      );

      // If the request was successful and the data and vacation days are not null, set the vacation days
      if (resp.status === 200 && resp.data && resp.data.vacationdays) {
        setVacationDays(resp.data.vacationdays);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Add a Holiday to the Installer's calendar

  const AddVacation = async () => {
    try {
      // Check if the holiday name is valid
      if (holidayName && holidayName.trim() !== "") {
        // Check if the vacation date is valid
        if (vacationDay && vacationMonth && vacationYear) {
          // Make an HTTP request to the specified URL with the vacation details
          const resp = await axios.post(
            `${window.$DBURL}appointment/vacation`,
            {
              Day: vacationDay,
              Month: vacationMonth,
              Year: vacationYear,
              Note: holidayName,
            }
          );

          // If the request was successful, get the vacation days
          if (resp.status === 200) {
            GetVacationDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
          }
        }
      }

      // Reset the vacation date and holiday name
      setVacationYear(0);
      setVacationMonth(0);
      setVacationDay(0);
      setHolidayName("");
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Edit Holiday Info on Installer's Calendar

  const EditVacation = async () => {
    try {
      // Check if the vacation ID is valid
      if (vacationId) {
        // Make an HTTP request to the specified URL with the updated vacation details
        const resp = await axios.put(`${window.$DBURL}appointment/vacation`, {
          Id: vacationId,
          Day: vacationDay || selectedDay,
          Month: vacationMonth || selectedMonth,
          Year: vacationYear || selectedYear,
          Note: holidayName || editName,
        });

        // If the request was successful, get the vacation days
        if (resp.status === 200) {
          GetVacationDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
        }
      }

      // Reset the vacation date and holiday name
      setVacationYear(0);
      setVacationMonth(0);
      setVacationDay(0);
      setHolidayName("");
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Delete a holiday

  const DeleteVacationDay = async (vacationId) => {
    try {
      // Make an HTTP request to the specified URL with the vacation ID
      const resp = await axios.delete(
        `${window.$DBURL}appointment/vacation/${vacationId}`
      );

      // If the request was successful, get the vacation days
      if (resp.status === 200) {
        GetVacationDays(JSON.parse(sessionStorage.getItem("userInfo")).Id);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Get timeslots that Homeowners can select to book an appointment with the Installer

  const [timeSlots, setTimeSlots] = useState([]);

  const GetTimeSlots = async (userId) => {
    try {
      // Make an HTTP request to the specified URL with the user ID
      const resp = await axios.get(
        `${window.$DBURL}appointment/slot/installer/${userId}`
      );

      // If the request was successful and the data and time slots are not null, set the time slots
      if (resp.status === 200 && resp.data && resp.data.timeslots) {
        setTimeSlots(resp.data.timeslots);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Add a new timeslot that Homeowners can use to book an appointment with the Installer

  const AddTimeSlot = async () => {
    try {
      // Make an HTTP request to the specified URL with the selected time slots
      const resp = await axios.post(`${window.$DBURL}appointment/slot`, {
        Start: { Hour: openHourSelected, Minute: openMinuteSelected },
        End: { Hour: closeHourSelected, Minute: closeMinuteSelected },
      });

      // If the request was successful, get the time slots
      if (resp.status === 200) {
        GetTimeSlots(JSON.parse(sessionStorage.getItem("userInfo")).Id);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Edit Timeslot information

  const UpdateTimeSlot = async () => {
    try {
      // Make an HTTP request to the specified URL with the updated time slots
      const resp = await axios.put(`${window.$DBURL}appointment/slot`, {
        Id: timeSlotId,
        Start: { Hour: editOpenHourSelected, Minute: editOpenMinuteSelected },
        End: { Hour: editCloseHourSelected, Minute: editCloseMinuteSelected },
      });

      // If the request was successful, get the time slots
      if (resp.status === 200) {
        GetTimeSlots(JSON.parse(sessionStorage.getItem("userInfo")).Id);
      }
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  //Delete a timeslot that Homeowners can select to book an appointment with the Installer

  const DeleteTimeSlot = async (timeSlotId) => {
    try {
      // Make an HTTP request to the specified URL with the time slot ID
      const resp = await axios.delete(
        `${window.$DBURL}appointment/slot/${timeSlotId}`
      );

      // If the request was successful, get the time slots
      if (resp.status === 200)
        GetTimeSlots(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    } catch (error) {
      // Log any errors that occurred during the HTTP request
      console.error(error);
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [startDate, setStartDate] = useState(null);
  var startDateSync = new Date();

  const HandleStartDate = async () => {
    setVacationYear(startDateSync.getFullYear());
    setVacationMonth(startDateSync.getMonth() + 1);
    setVacationDay(startDateSync.getDate());
  };

  const locale = "en"; // or whatever you want...
  const hours = [];

  moment.locale(locale); // optional - can remove if you are only dealing with one locale

  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format("HH:mm"));
    hours.push(
      moment({
        hour,
        minute: 30,
      }).format("HH:mm")
    );
  }

  if (roleNum !== UserRole.Loading) {
    return (
      <FlexContMobileColumn>
        <FullWidthMobileCont100vw100vh>
          <Menu> </Menu>
        </FullWidthMobileCont100vw100vh>

        <FlexColumnCont80vwAutoHeight>
          <FlexColumnFullWidth width="96%" mobilepaddingbottom="10vh">
            <FlexColumnFullWidth>
              <FlexColumnFullWidth>
                <FlexColumnFullWidth>
                  <FlexColumnDiv>
                    <LeagueSpartanExtraLargeBold>
                      Operational Hours:
                    </LeagueSpartanExtraLargeBold>
                    <MediumHeader>
                      Choose the hours and days you would like to be available
                      on. You can customize when homeowners can book
                      appointments with you as well as add specific dates you
                      are unavailable on.
                    </MediumHeader>
                  </FlexColumnDiv>
                  <br></br>
                  <FlexColumnDiv>
                    <LeagueSpartanRegular>Eligible Days:</LeagueSpartanRegular>
                    <MediumHeader>
                      Current days of the week you are active:
                    </MediumHeader>
                  </FlexColumnDiv>
                  <br></br>
                  <FlexRowContFlexStart>
                    <FlexColumnGreenSelectables width="auto">
                      {openDays.map((o, i) => (
                        <SelectableMediumLongButton
                          width="110px"
                          mobilewidth="220px"
                          border={`3px solid ${ColorPalette.PrimaryButtonBlue}`}
                          color={ColorPalette.PrimaryButtonBlue}
                        >
                          {o?.substring(0, 3)}
                        </SelectableMediumLongButton>
                      ))}
                    </FlexColumnGreenSelectables>
                  </FlexRowContFlexStart>
                </FlexColumnFullWidth>

                <StyledHRPropColour
                  border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                ></StyledHRPropColour>

                <FlexColumnFullWidth>
                  <FlexColumnDiv>
                    <FlexContCenterAlign mobileflexdirection="column">
                      <LeagueSpartanRegular>
                        Unavailable Days:
                      </LeagueSpartanRegular>

                      <FlexContCenterAlign>
                        <SelectionInputRegular
                          onChange={(e) => {
                            switch (e.target.value) {
                              case "Sunday":
                                setDaySelected(0);
                                break;
                              case "Monday":
                                setDaySelected(1);
                                break;
                              case "Tuesday":
                                setDaySelected(2);
                                break;
                              case "Wednesday":
                                setDaySelected(3);
                                break;
                              case "Thursday":
                                setDaySelected(4);
                                break;
                              case "Friday":
                                setDaySelected(5);
                                break;
                              case "Saturday":
                                setDaySelected(6);
                                break;
                              default:
                            }
                          }}
                          id="daysofweek"
                        >
                          {openDays.map((o, i) => (
                            <BlankOption key={i}>{o}</BlankOption>
                          ))}
                        </SelectionInputRegular>
                        <RegularResponsiveButton
                          onClick={() => {
                            setInputDisplayProp("none");
                            AddCloseDay();
                          }}
                        >
                          Add Unavailable Day
                        </RegularResponsiveButton>
                      </FlexContCenterAlign>
                    </FlexContCenterAlign>
                    <br></br>

                    <MediumHeader>
                      Current days of the week you are unavailable:
                    </MediumHeader>
                  </FlexColumnDiv>
                  <br></br>

                  <DeleteConfirmWindow
                    deleteOpen={deleteCloseDay}
                    onDeleteFunction={() => {
                      setDeleteCloseDay(false);
                      DeleteCloseDay(deleteDayId);
                    }}
                    closeDel={() => {
                      setDeleteCloseDay(false);
                    }}
                  ></DeleteConfirmWindow>
                  <br></br>

                  <FlexRowContFlexStart>
                    <FlexColumnGreenSelectables width="auto">
                      {closeDays.map((o, i) => {
                        switch (o.WeekDay) {
                          case 0:
                            o.WeekDay = "Sunday";
                            break;
                          case 1:
                            o.WeekDay = "Monday";
                            break;
                          case 2:
                            o.WeekDay = "Tuesday";
                            break;
                          case 3:
                            o.WeekDay = "Wednesday";
                            break;
                          case 4:
                            o.WeekDay = "Thursday";
                            break;
                          case 5:
                            o.WeekDay = "Friday";
                            break;
                          case 6:
                            o.WeekDay = "Saturday";
                            break;
                          default:
                        }

                        return (
                          <FlexDiv>
                            <SelectableMediumLongButton
                              width="110px"
                              mobilewidth="220px"
                              border={`3px solid ${ColorPalette.DarkGrey}`}
                              color={ColorPalette.DarkGrey}
                            >
                              {o.WeekDay?.substring(0, 3)}
                            </SelectableMediumLongButton>
                            <Icon30px20pxPointer
                              onClick={() => {
                                setDeleteDayId(o.Id);
                                setDeleteCloseDay(true);
                              }}
                              src="/Garbage_Icon.svg"
                            ></Icon30px20pxPointer>
                          </FlexDiv>
                        );
                      })}
                    </FlexColumnGreenSelectables>
                  </FlexRowContFlexStart>
                </FlexColumnFullWidth>

                <StyledHRPropColour
                  border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
                ></StyledHRPropColour>

                <FlexColumnFullWidth>
                  <FlexContCenterAlign>
                    <LeagueSpartanRegular>
                      My Vacation Days/Holidays:
                    </LeagueSpartanRegular>

                    <RegularResponsiveButton
                      onClick={() => {
                        setStartDate(null);
                        setHolidayName("");
                        setEditVacationDisplay("none");
                        setVacationDisplay("flex");
                      }}
                    >
                      Add Vacation Day
                    </RegularResponsiveButton>
                  </FlexContCenterAlign>
                  <br></br>

                  <FlexColumnFullWidth
                    display={vacationDisplay}
                    mobiledisplay={vacationDisplay}
                  >
                    <RegularTextBold>Select a Date</RegularTextBold>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        startDateSync = date;
                        setStartDate(date);
                        HandleStartDate();
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={subDays(new Date(), 0)}
                      placeholderText="Click to select a date"
                      showMonthDropdown
                    ></DatePicker>

                    <RegularTextBold>Enter Holiday Name</RegularTextBold>
                    <TextInputProp
                      mobiledisplay="none"
                      placeholder="Holiday Name"
                      onfocus="this.value=''"
                      onChange={(e) => {
                        setHolidayName(e.target.value);
                      }}
                    ></TextInputProp>
                    <MobileInput
                      display={window.$ISMOBILE === true ? "block" : "none"}
                      placeholder="Holiday Name"
                      onfocus="this.value=''"
                      onChange={(e) => {
                        setHolidayName(e.target.value);
                      }}
                    ></MobileInput>
                    <br></br>
                    <RegularResponsiveButton
                      width="50px"
                      onClick={() => {
                        setVacationDisplay("none");
                        setSelectedDay(0);
                        setSelectedMonth(0);
                        setSelectedYear(0);
                        AddVacation();
                        setHolidayName("");
                        setVacationDay(0);
                        setVacationMonth(0);
                        setVacationYear(0);
                      }}
                    >
                      Save
                    </RegularResponsiveButton>
                  </FlexColumnFullWidth>
                  <DeleteConfirmWindow
                    deleteOpen={deleteVacation}
                    onDeleteFunction={() => {
                      setDeleteVacation(false);
                      DeleteVacationDay(vacationId);
                    }}
                    closeDel={() => {
                      setDeleteVacation(false);
                    }}
                  ></DeleteConfirmWindow>
                  <FlexColumnFullWidth
                    key={selectedDay}
                    display={editVacationDisplay}
                    mobiledisplay={editVacationDisplay}
                  >
                    <RegularTextBold>Edit Date</RegularTextBold>
                    <DatePicker
                      selected={startDate}
                      //  filterDate={isWeekday}
                      onChange={(date) => {
                        startDateSync = date;
                        setStartDate(date);
                        HandleStartDate();
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={subDays(new Date(), 0)}
                      placeholderText={
                        selectedMonth + "/" + selectedDay + "/" + selectedYear
                      }
                      showMonthDropdown
                    ></DatePicker>

                    <RegularTextBold>Edit Holiday Name</RegularTextBold>
                    <TextInputProp
                      mobiledisplay="none"
                      onChange={(e) => {
                        setHolidayName(e.target.value);
                      }}
                    ></TextInputProp>
                    <MobileInput
                      display={window.$ISMOBILE === true ? "block" : "none"}
                      defaultValue={editName}
                      onChange={(e) => {
                        setHolidayName(e.target.value);
                      }}
                    ></MobileInput>
                    <br></br>
                    <RegularResponsiveButton
                      width="100px"
                      mobilewidth="200px"
                      onClick={() => {
                        setEditVacationDisplay("none");
                        EditVacation();
                        setHolidayName("");
                        setEditName("");
                      }}
                    >
                      Update Vacation/Holiday
                    </RegularResponsiveButton>
                  </FlexColumnFullWidth>
                  {vacationDays.map((o, i) => (
                    <div>
                      <br></br>
                      <FlexRowCont70PercentSpaceBetween width="60%">
                        <RegularTextBold>
                          {o.Month}/{o.Day}/{o.Year} - {o.Note}
                        </RegularTextBold>

                        <FlexContCenterAlign>
                          <BlueUnderlinePointerPara
                            onClick={() => {
                              setEditName(o.Note);
                              setSelectedDay(o.Day);
                              setSelectedMonth(o.Month);
                              setSelectedYear(o.Year);
                              setVacationId(o.Id);
                              setStartDate(null);
                              setVacationDisplay("none");
                              setEditVacationDisplay("block");
                            }}
                          >
                            Edit
                          </BlueUnderlinePointerPara>
                          <Icon30px20pxPointer
                            onClick={() => {
                              setVacationId(o.Id);
                              setDeleteVacation(true);
                            }}
                            src="/Garbage_Icon.svg"
                          ></Icon30px20pxPointer>
                        </FlexContCenterAlign>
                      </FlexRowCont70PercentSpaceBetween>
                    </div>
                  ))}
                </FlexColumnFullWidth>
              </FlexColumnFullWidth>
              <StyledHRPropColour
                border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
              ></StyledHRPropColour>
              <FlexColumnFullWidth>
                <FlexColumnFullWidth>
                  <FlexContCenterAlign>
                    <LeagueSpartanRegular>
                      Time Slots for Appointments:
                    </LeagueSpartanRegular>
                    <RegularResponsiveButton
                      onClick={() => {
                        setTimeSlotDisplayProp("flex");
                      }}
                    >
                      Create New Timeslot
                    </RegularResponsiveButton>
                  </FlexContCenterAlign>
                  <MediumHeader>
                    Homeowners can make appointments with me during these
                    timeslots.
                  </MediumHeader>
                  <br></br>

                  <FlexColumnDiv display={timeSlotDisplayProp}>
                    <FlexRowCont60PercentSpaceEvenly mobileflexdirection="column">
                      <FlexRowCont70PercentSpaceBetween>
                        <FlexContCenterAlign display={timeSlotDisplayProp}>
                          <RegularTextBold>From:</RegularTextBold>
                          <SelectionInputRegular
                            onChange={(e) => {
                              setOpenHourSelected(
                                e.target.value.substring(0, 2)
                              );
                              setOpenMinuteSelected(
                                e.target.value.substring(3, 5)
                              );
                            }}
                            id="openhours"
                          >
                            {hours.map((o, i) => (
                              <BlankOption key={i}>{o}</BlankOption>
                            ))}
                          </SelectionInputRegular>
                        </FlexContCenterAlign>
                      </FlexRowCont70PercentSpaceBetween>
                      <br></br>
                      <br></br>
                      <FlexRowCont70PercentSpaceBetween>
                        <FlexContCenterAlign display={timeSlotDisplayProp}>
                          <RegularTextBold>To:</RegularTextBold>
                          <SelectionInputRegular
                            onChange={(e) => {
                              setCloseHourSelected(
                                e.target.value.substring(0, 2)
                              );
                              setCloseMinuteSelected(
                                e.target.value.substring(3, 5)
                              );
                            }}
                            id="closehours"
                          >
                            {hours.map((o, i) => (
                              <BlankOption key={i}>{o}</BlankOption>
                            ))}
                          </SelectionInputRegular>
                        </FlexContCenterAlign>
                      </FlexRowCont70PercentSpaceBetween>
                    </FlexRowCont60PercentSpaceEvenly>
                    <br></br>

                    <PropDiv divdisplay={timeSlotDisplayProp}>
                      <RegularResponsiveButton
                        onClick={() => {
                          setTimeSlotDisplayProp("none");
                          AddTimeSlot();
                        }}
                      >
                        Save
                      </RegularResponsiveButton>
                      <RegularResponsiveButton
                        bgcolor={ColorPalette.PrimaryRed}
                        onClick={() => {
                          setTimeSlotDisplayProp("none");
                        }}
                      >
                        Cancel
                      </RegularResponsiveButton>
                    </PropDiv>
                    <LeagueSpartanRegular display={EditTimeSlotDisplayProp}>
                      Edit Timeslot
                    </LeagueSpartanRegular>

                    <FlexContCenterAlign display={EditTimeSlotDisplayProp}>
                      <RegularTextBold>From:</RegularTextBold>
                      <SelectionInputRegular
                        onChange={(e) => {
                          setEditOpenHourSelected(
                            e.target.value.substring(0, 2)
                          );
                          setEditOpenMinuteSelected(
                            e.target.value.substring(3, 5)
                          );
                        }}
                        id="openhours"
                      >
                        {hours.map((o, i) => (
                          <BlankOption key={i}>{o}</BlankOption>
                        ))}
                      </SelectionInputRegular>
                    </FlexContCenterAlign>

                    <FlexContCenterAlign display={EditTimeSlotDisplayProp}>
                      <RegularTextBold>To:</RegularTextBold>
                      <SelectionInputRegular
                        onChange={(e) => {
                          setEditCloseHourSelected(
                            e.target.value.substring(0, 2)
                          );
                          setEditCloseMinuteSelected(
                            e.target.value.substring(3, 5)
                          );
                        }}
                        id="closehours"
                      >
                        {hours.map((o, i) => (
                          <BlankOption key={i}>{o}</BlankOption>
                        ))}
                      </SelectionInputRegular>
                    </FlexContCenterAlign>

                    <PropDiv divdisplay={EditTimeSlotDisplayProp}>
                      <RegularResponsiveButton
                        onClick={() => {
                          setEditTimeSlotDisplayProp("none");
                          UpdateTimeSlot();
                        }}
                      >
                        Save
                      </RegularResponsiveButton>
                      <RegularResponsiveButton
                        bgcolor={ColorPalette.PrimaryRed}
                        onClick={() => {
                          setEditTimeSlotDisplayProp("none");
                        }}
                      >
                        Cancel
                      </RegularResponsiveButton>
                    </PropDiv>
                  </FlexColumnDiv>
                  <BlankDiv>
                    {timeSlots.map((o, i) => {
                      switch (o.Start.Minute) {
                        case 0:
                          o.Start.Minute = "00";
                          break;
                        default:
                      }
                      switch (o.End.Minute) {
                        case 0:
                          o.End.Minute = "00";
                          break;
                        default:
                      }
                      return (
                        <div>
                          {" "}
                          <HundredPercentFlexDiv key={i}>
                            <FlexColumnStartDiv45Percent>
                              <DeleteConfirmWindow
                                deleteOpen={deleteSlot}
                                onDeleteFunction={() => {
                                  setDeleteSlot(false);
                                  DeleteTimeSlot(timeSlotId);
                                }}
                                closeDel={() => {
                                  setDeleteSlot(false);
                                }}
                              ></DeleteConfirmWindow>
                              <FlexDivSpaceAround>
                                <RegularTextBold>{i + 1 + " "}</RegularTextBold>
                                <FlexDiv>
                                  <RegularTextBold>
                                    From: {o.Start.Hour}:{o.Start.Minute}
                                  </RegularTextBold>
                                </FlexDiv>
                                <FlexDiv>
                                  <RegularTextBold>
                                    To: {o.End.Hour}:{o.End.Minute}
                                  </RegularTextBold>
                                </FlexDiv>
                              </FlexDivSpaceAround>
                            </FlexColumnStartDiv45Percent>
                            <FlexDivFlexEnd>
                              {/* <BlueUnderlinePointerPara
                                onClick={() => {
                                  setTimeSlotId(o.Id);
                                  setEditTimeSlotDisplayProp("flex");
                                }}
                              >
                                Edit
                              </BlueUnderlinePointerPara> */}
                            </FlexDivFlexEnd>
                            <FlexDivFlexEnd>
                              <Icon30px20pxPointer
                                onClick={() => {
                                  setTimeSlotId(o.Id);
                                  setDeleteSlot(true);
                                }}
                                src="/Garbage_Icon.svg"
                              ></Icon30px20pxPointer>
                            </FlexDivFlexEnd>
                          </HundredPercentFlexDiv>
                          <hr></hr>
                        </div>
                      );
                    })}
                  </BlankDiv>
                </FlexColumnFullWidth>
              </FlexColumnFullWidth>
            </FlexColumnFullWidth>
          </FlexColumnFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexContMobileColumn>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

InstallerSetupScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default InstallerSetupScreen;
