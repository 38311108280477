import React, { useState } from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContRedBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { useHistory } from "react-router-dom";
import { DeleteQuoteById } from "../../../API_Functions/CaseQuote_Functions";
import { useParams } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const DeleteProposalWindow = ({ deleteOpen, CloseWindow, proposalid }) => {
  const [deletingProposal, setDeletingProposal] = useState(false);

  const history = useHistory();
  const params = useParams();

  const DeleteProposal = async (proposalid, metro_id, store_id) => {
    setDeletingProposal(true);
    var data = await DeleteQuoteById(proposalid);
    if (data == null) {
      CloseWindow();
      history.push(`/cases/${metro_id}/${store_id}`);
    }
  };

  if (!deleteOpen) return null;
  if (deletingProposal === true) {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <FixedOverlay200vh>
        <FlexColumnContRedBorderTopRounded>
          <SmallHeaderLeagueSpartan>
            You're about to delete this proposal
          </SmallHeaderLeagueSpartan>
          <SmallTextPadding>
            Are you sure you want to delete this proposal? This proposal will be
            permanently removed and you won’t be able to see it again.
          </SmallTextPadding>
          <FlexRowCont>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryGrey}
              onClick={CloseWindow}
            >
              Cancel
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                DeleteProposal(proposalid, params.Id, params.storeId);
              }}
            >
              Delete Proposal
            </RegularResponsiveButton>
          </FlexRowCont>
        </FlexColumnContRedBorderTopRounded>
      </FixedOverlay200vh>
    );
  }
};

DeleteProposalWindow.defaultProps = {
  CloseWindow: () => {},
};

export default DeleteProposalWindow;
