import React from "react";
import { useParams } from "react-router-dom";
import { AddStore } from "../../../API_Functions/Store_Functions";

import { Cont400x575pxNoMargin } from "../../../StylesheetComps/Cont";
import { Div300x400pxMargin30px } from "../../../StylesheetComps/Div";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { LeagueSpartanExtraLargeWidth200px } from "../../../StylesheetComps/Titles";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
const NewStoreWindow = ({ open, onClose, onChange }) => {
  const params = useParams();

  var storeobj = {
    Name: "",
    Address: "",
    Phone: "",
    Metro_Id: 0,
    Minimum_Deposit: 0,
    Minimum_Labor: 0,
  };

  const AddNewStore = async (storeobj, metroid) => {
    storeobj.Metro_Id = metroid;
    var data = await AddStore(storeobj);
    if (data !== null) {
      onClose();
      onChange();
    }
  };

  if (!open) return null;
  return (
    <Cont400x575pxNoMargin>
      <Div300x400pxMargin30px>
        <LeagueSpartanExtraLargeWidth200px>
          Store Info
        </LeagueSpartanExtraLargeWidth200px>
        <TextInput72Percent
          placeholder="Store Name"
          onChange={(e) => {
            storeobj.Name = e.target.value;
          }}
        ></TextInput72Percent>
        <TextInput72Percent
          placeholder="Address"
          onChange={(e) => {
            storeobj.Address = e.target.value;
          }}
        ></TextInput72Percent>
        <TextInput72Percent
          placeholder="Phone"
          onChange={(e) => {
            storeobj.Phone = e.target.value;
          }}
        ></TextInput72Percent>
        <TextInput72Percent
          placeholder="Min Deposit"
          onChange={(e) => {
            storeobj.Minimum_Deposit = e.target.value;
          }}
        ></TextInput72Percent>
        <TextInput72Percent
          placeholder="Min Labor"
          onChange={(e) => {
            storeobj.Minimum_Labor = e.target.value;
          }}
        ></TextInput72Percent>

        <RegularResponsiveButton
          onClick={() => {
            AddNewStore(storeobj, params.Id);
          }}
        >
          Add
        </RegularResponsiveButton>
        <RegularResponsiveButton
          bgcolor={ColorPalette.PrimaryRed}
          onClick={onClose}
        >
          Cancel
        </RegularResponsiveButton>
      </Div300x400pxMargin30px>
    </Cont400x575pxNoMargin>
  );
};

NewStoreWindow.defaultProps = {
  onChange: () => {},
};

export default NewStoreWindow;
