import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GetCityList,
  GetMetroList,
  UploadFileToCloud,
} from "../../API_Functions/Utility_Functions";
import Input from "../../comps/Input";
import { MobileSmallGreenButton } from "../../MobileComponents/Buttons";
import { MobileInput } from "../../MobileComponents/Inputs";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  Cont100Percent100Percent,
  FlexDiv,
  FlexRowCont60PercentSpaceEvenly,
} from "../../StylesheetComps/Cont";
import {
  DatalistMedium,
  DatalistOption,
} from "../../StylesheetComps/LandscapeForm";
import { RegularTextBoldRed } from "../../StylesheetComps/Titles";

const LeftFormField = styled.div`
  width: 100%;
  height: 100%;
  margin: 2%;

  @media (max-width: 1006px) {
    margin: 0%;
    width: 100%;
    height: 50%;
  }
`;

const FormLabel = styled.label`
  font-size: 18px;
  font-family: Avenir;
  font-weight: bold;
  margin: 10px;
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 1006px) {
    width: 75px;
    font-size: 14px;
  }
`;

const ProfilePara = styled.p`
  font-size: 18px;
  font-family: Avenir;
  margin: 10px;
  width: 80%;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 1006px) {
    width: 160px;
    margin-right: 30px;
    word-break: break-all;
    font-size: 14px;
  }
`;

const UpdateProfileButton = styled.div`
  padding: 10px;
  border-radius: ${(props) =>
    props.buttonborderradius ? props.buttonborderradius : "10px"};
  background-color: ${(props) =>
    props.buttonbgcolor ? props.buttonbgcolor : ColorPalette.PrimaryButtonBlue};
  color: ${(props) => (props.buttoncolor ? props.buttoncolor : "white")};
  font-size: 18px;
  display: flex;
  /* flex-direction: column;*/
  align-items: center;
  justify-content: center;
  font-family: Avenir;
  margin-left: 0.5%;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1006px) {
    width: 70%;
    height: 7%;
    position: relative;
    bottom: 10%;
  }
`;

const UpdatePass = styled.a`
  font-family: Avenir;
  font-size: 100%;
  text-decoration: underline;
  color: blue;
  display: ${(props) => (props.display ? props.display : "block")};
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 1006px) {
    font-size: 12px;
  }
`;

const SecDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const PhotoCont = styled.img`
  height: 150px;
  display: ${(props) => (props.photoprops1 ? props.photoprops1 : "block")};
  object-fit: contain;
  @media (max-width: 1006px) {
    height: 100%;
    margin-top: 0%;
  }
`;

const FormDivider = styled.div`
  display: flex;
  height: 18vh;
  width: 81.5%;
  @media (max-width: 1006px) {
    width: 200px;
  }
`;

const FileUpload = styled.input`
  margin-top: auto;
  margin-bottom: auto;
`;

const ProfileHR = styled.hr`
  width: 100%;
  border: 1px solid #d3d3d3;
`;

const EditDiv = styled.div`
  display: ${(props) => (props.displayprop ? props.displayprop : "flex")};
  flex-direction: column;
  padding-top: 25px;
`;

const UpdateProfile = ({
  onPreview,
  CallProfileAgain,
  onProfileUpdated,
  metro,
  city,
  name,
  address,
  email,
  phone,
  role,
  updated,
  editprofile,
}) => {
  const [emailValue, setEmailValue] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [AddressValue, setAddressValue] = useState("");
  const [PhoneValue, setPhoneValue] = useState("");

  const [editDisplay, setEditDisplay] = useState("none");

  const [emailValue2, setEmailValue2] = useState("");
  const [NameValue2, setNameValue2] = useState("");
  const [AddressValue2, setAddressValue2] = useState("");
  const [PhoneValue2, setPhoneValue2] = useState("");

  const [ChangePropMetro, setChangePropMetro] = useState("Add");
  const [ChangePropCity, setChangePropCity] = useState("Add");
  const [ChangePropName, setChangePropName] = useState("Edit");
  const [ChangePropEmail, setChangePropEmail] = useState("Edit");
  const [ChangePropPhone, setChangePropPhone] = useState("Edit");
  const [ChangePropAddress, setChangePropAddress] = useState("Edit");
  const [ChangePropImage, setChangePropImage] = useState("Edit");

  const [ChangePropNameDisplay, setChangePropNameDisplay] = useState("none");
  const [ChangePropEmailDisplay, setChangePropEmailDisplay] = useState("none");
  const [ChangePropPhoneDisplay, setChangePropPhoneDisplay] = useState("none");
  const [ChangePropAddressDisplay, setChangePropAddressDisplay] =
    useState("none");
  const [ChangePropImageDisplay, setChangePropImageDisplay] = useState("none");
  const [ChangePropCityDisplay, setChangePropCityDisplay] = useState("none");
  const [ChangePropMetroDisplay, setChangePropMetroDisplay] = useState("none");

  const [cityList, setCityList] = useState([]);
  const [metroList, setMetroList] = useState([]);
  const [ImgUrl, setImgUrl] = useState("");

  var [profileDefaultUrl, setProfileDefaultUrl] = useState(ImgUrl);

  const [photoDisplay, setPhotoDisplay] = useState("block");
  const [changeTextDisplay, setChangeTextDisplay] = useState("none");
  const [errorText] = useState("");
  const [locationErrorText, setLocationErrorText] = useState("");

  var file = "";

  const GetProfileInfo = async () => {
    const resp = await axios.get(window.$DBURL + "users/profile");
    setEmailValue(JSON.parse(sessionStorage.getItem("userInfo")).Email);
    setNameValue(JSON.parse(sessionStorage.getItem("userInfo")).Name);
    setAddressValue(JSON.parse(sessionStorage.getItem("userInfo")).Street);
    setPhoneValue(JSON.parse(sessionStorage.getItem("userInfo")).Phone);
    setImgUrl(resp.data.user.Image);
    if (resp.data.user.Metro === null || resp.data.user.City === " ") {
      setChangePropMetro("Add");
    } else {
      setChangePropMetro("Change");
    }
    if (resp.data.user.City === null || resp.data.user.City === " ") {
      setChangePropCity("Add");
    } else {
      setChangePropCity("Change");
    }
  };

  let userobj = JSON.parse(sessionStorage.getItem("userInfo"));
  let ContactInfo = React.useRef(userobj);

  const GetLocationInfo = async () => {
    let metrodata = await GetMetroList();
    let citydata = await GetCityList(userobj.Metro_Id);
    setCityList(citydata);
    setMetroList(metrodata);
  };

  const UpdateMetroUIChanges = async (displayProp, contactinfo) => {
    if (displayProp === "none") {
      setChangePropCity("Change");
      setChangeTextDisplay("none");
      setChangePropMetroDisplay("flex");
      setChangePropCityDisplay("flex");
      setChangePropMetro("Save");
    } else {
      if (
        contactinfo.current.City_Id === 0 ||
        contactinfo.current.Metro_Id === 0
      ) {
        setLocationErrorText("Please select both a Region and City");
      } else {
        setChangeTextDisplay("block");
        UpdateMetroAndCity(contactinfo);
        setLocationErrorText("");
        setChangePropMetroDisplay("none");
        setChangePropCityDisplay("none");
        setChangePropMetro("Change");
      }
    }
  };

  const UpdateCityUIChanges = async (displayProp, contactinfo) => {
    if (displayProp === "Change") {
      setChangePropCityDisplay("flex");
      setChangePropMetroDisplay("none");
      GetLocationInfo();
      setChangePropCity("Save");
      setChangePropMetro("Change");
    } else {
      UpdateCity(contactinfo);
      setChangePropCityDisplay("none");
      setChangePropCity("Change");
    }
  };

  const UpdateMetroAndCity = async (contactinfo) => {
    const resp = await axios.put(window.$DBURL + "users", {
      Metro_Id: contactinfo.current.Metro_Id,
      Metro: contactinfo.current.Metro,
      City_Id: contactinfo.current.City_Id,
      City: contactinfo.current.City,
      Id: contactinfo.current.Id,
      Username: contactinfo.current.Username,
    });
    if (resp.status === 200) {
      CallProfileAgain();
    }
  };

  const UpdateCity = async (contactinfo) => {
    const resp = await axios.put(window.$DBURL + "users", {
      Metro_Id: contactinfo.current.Metro_Id,
      Metro: contactinfo.current.Metro,
      City_Id: contactinfo.current.City_Id,
      City: contactinfo.current.City,
      Id: contactinfo.current.Id,
      Username: contactinfo.current.Username,
    });
    if (resp.status === 200) {
      CallProfileAgain();
    }
  };

  // Handles file upload event and updates state
  function handleUpload(event) {
    if (ImgUrl !== "") {
      const resp = axios.delete(window.$DBURL + "utilities/image", {
        headers: { Url: ImgUrl },
      });
      if (resp.status === 200) {
      }
    }
    file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setProfileDefaultUrl(pic);
      setPhotoDisplay("block");
    }
  };

  const UpdateCityList = async (metroid) => {
    if (metroid !== 0 && metroid !== 3) {
      ContactInfo.current.City = "";
      ContactInfo.current.City_Id = 0;
      let citydata = await GetCityList(metroid);
      setCityList(citydata);
    }
  };

  //Assigns the ID based on the Name Selected
  const SelectCity = async (e) => {
    ContactInfo.current.City_Id = e.target.value;
    cityList.find(function (o, i) {
      if (o.Id == e.target.value) {
        ContactInfo.current.City = o.Name;
      }
      return null;
    });
  };

  const SelectMetro = async (e) => {
    ContactInfo.current.Metro_Id = e.target.value;
    console.log(
      metroList.find(function (o, i) {
        if (o.Id === e.target.value) {
          ContactInfo.current.Metro = o.Name;
        }
        return null;
      })
    );
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    GetLocationInfo();
    GetProfileInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetLocationInfo();
    GetProfileInfo();
  }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProfileDefaultUrl(ImgUrl);
  }, [ImgUrl]);

  useEffect(() => {
    if (editprofile === true) {
      setEditDisplay("block");
      setChangeTextDisplay("block");
    }
  }, [editprofile]);

  if (window.$ISMOBILE === true) {
    if (role === 1000) {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <Cont100Percent100Percent>
          <FlexDiv>
            <LeftFormField>
              <SecDiv>
                <FlexDiv key={NameValue}>
                  <FormLabel>Name</FormLabel>
                  <ProfilePara>{name}</ProfilePara>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      if (ChangePropName === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropName("Cancel");
                        setChangePropNameDisplay("flex");
                      } else if (ChangePropName === "Cancel") {
                        setChangePropName("Edit");
                        setChangePropNameDisplay("none");
                      }
                    }}
                  >
                    {ChangePropName}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropNameDisplay}>
                  <FlexDiv>
                    <FormLabel>New Name</FormLabel>
                    <MobileInput
                      placeholder="Name"
                      defaultValue={NameValue}
                      onChange={(e) => {
                        setNameValue2(e.target.value);
                      }}
                    ></MobileInput>
                  </FlexDiv>
                  <br></br>
                  <FlexDiv>
                    <MobileSmallGreenButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </MobileSmallGreenButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv key={emailValue}>
                  <FormLabel>Email</FormLabel>
                  <ProfilePara>{email}</ProfilePara>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      if (ChangePropEmail === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropEmail("Cancel");
                        setChangePropEmailDisplay("flex");
                      } else if (ChangePropEmail === "Cancel") {
                        setChangePropEmail("Edit");
                        setChangePropEmailDisplay("none");
                      }
                    }}
                  >
                    {ChangePropEmail}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropEmailDisplay}>
                  <FlexDiv>
                    <FormLabel>New Email</FormLabel>
                    <MobileInput
                      placeholder="Email"
                      defaultValue={emailValue}
                      onChange={(e) => {
                        setEmailValue2(e.target.value);
                      }}
                    ></MobileInput>
                  </FlexDiv>
                  <br></br>
                  <FlexDiv>
                    <MobileSmallGreenButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </MobileSmallGreenButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv key={PhoneValue}>
                  <FormLabel>Cell Phone</FormLabel>
                  <ProfilePara>{phone}</ProfilePara>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      if (ChangePropPhone === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropPhone("Cancel");
                        setChangePropPhoneDisplay("flex");
                      } else if (ChangePropPhone === "Cancel") {
                        setChangePropPhone("Edit");
                        setChangePropPhoneDisplay("none");
                      }
                    }}
                  >
                    {ChangePropPhone}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropPhoneDisplay}>
                  <FlexDiv>
                    <FormLabel>New Cell Phone</FormLabel>
                    <MobileInput
                      defaultValue={PhoneValue}
                      onChange={(e) => {
                        setPhoneValue2(e.target.value);
                      }}
                    ></MobileInput>
                  </FlexDiv>
                  <br></br>
                  <FlexDiv>
                    <MobileSmallGreenButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </MobileSmallGreenButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Profile Photo</FormLabel>
                  <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
                  <FormDivider>
                    <PhotoCont
                      photoprops1={photoDisplay}
                      src={window.$IMGURL + profileDefaultUrl}
                      alt="No Profile Picture"
                    ></PhotoCont>
                  </FormDivider>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      if (ChangePropImage === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropImage("Cancel");
                        setChangePropImageDisplay("flex");
                      } else if (ChangePropImage === "Cancel") {
                        setChangePropImage("Edit");
                        setChangePropImageDisplay("none");
                      }
                    }}
                  >
                    {ChangePropImage}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropImageDisplay}>
                  <FlexDiv>
                    <FormLabel>New Profile Photo</FormLabel>
                    <FileUpload
                      type="file"
                      id="catpic"
                      name="filename"
                      accept=".png,.heic,.jpg,.jpeg"
                      onChange={handleUpload}
                    ></FileUpload>
                  </FlexDiv>
                  <br></br>
                  <FlexDiv>
                    <MobileSmallGreenButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </MobileSmallGreenButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Region</FormLabel>
                  <ProfilePara>{metro}</ProfilePara>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      UpdateMetroUIChanges(ChangePropMetroDisplay, ContactInfo);
                    }}
                  >
                    {ChangePropMetro}
                  </UpdatePass>
                </FlexDiv>
                <RegularTextBoldRed>{locationErrorText}</RegularTextBoldRed>
                <EditDiv displayprop={ChangePropMetroDisplay}>
                  <FlexRowCont60PercentSpaceEvenly>
                    <DatalistMedium
                      defaultValue={ContactInfo.current.Metro}
                      inputwidth="100%"
                      inputheight="40%"
                      id="metros"
                      onChange={(e) => {
                        SelectMetro(e);
                        UpdateCityList(e.target.value);
                      }}
                    >
                      <DatalistOption value={0}>Select Region</DatalistOption>
                      {metroList.map((o, i) => (
                        <DatalistOption
                          key={o.Id}
                          value={o.Id}
                          selected={o.Id === ContactInfo.current.Metro_Id}
                        >
                          {o.Name}
                        </DatalistOption>
                      ))}
                    </DatalistMedium>
                  </FlexRowCont60PercentSpaceEvenly>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>City</FormLabel>
                  <ProfilePara>{city}</ProfilePara>
                  <UpdatePass
                    display={changeTextDisplay}
                    onClick={() => {
                      UpdateCityUIChanges(ChangePropCity, ContactInfo);
                    }}
                  >
                    {ChangePropCity}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropCityDisplay}>
                  <FlexRowCont60PercentSpaceEvenly>
                    <DatalistMedium
                      defaultValue={ContactInfo.current.City}
                      inputwidth="100%"
                      inputheight="40%"
                      id="cities"
                      onChange={(e) => {
                        SelectCity(e);
                      }}
                    >
                      <DatalistOption value={0}>Select City</DatalistOption>
                      {cityList.map((o, i) => (
                        <DatalistOption
                          id="city"
                          selected={o.Id === ContactInfo.current.City_Id}
                          key={o.Id}
                          value={o.Id}
                        >
                          {o.Name}
                        </DatalistOption>
                      ))}
                    </DatalistMedium>
                  </FlexRowCont60PercentSpaceEvenly>
                </EditDiv>
              </SecDiv>

              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Address</FormLabel>
                  <ProfilePara>{address}</ProfilePara>
                  <UpdatePass
                    display={editDisplay}
                    onClick={() => {
                      if (ChangePropAddress === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropAddress("Cancel");
                        setChangePropAddressDisplay("flex");
                      } else if (ChangePropAddress === "Cancel") {
                        setChangePropAddress("Edit");
                        setChangePropAddressDisplay("none");
                      }
                    }}
                  >
                    {ChangePropAddress}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropAddressDisplay}>
                  <FlexDiv>
                    <FormLabel>New Address</FormLabel>
                    <MobileInput
                      placeholder="Address"
                      defaultValue={AddressValue}
                      onChange={(e) => {
                        setAddressValue2(e.target.value);
                      }}
                    ></MobileInput>
                  </FlexDiv>
                  <br></br>
                  <FlexDiv>
                    <MobileSmallGreenButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </MobileSmallGreenButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
            </LeftFormField>
          </FlexDiv>
        </Cont100Percent100Percent>
      );
    }
  } else {
    if (role === 1000) {
      return (
        <Backdrop
          style={{ zIndex: 2 }}
          //  className={classes.Backdrop}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <Cont100Percent100Percent>
          <FlexDiv>
            <LeftFormField>
              <SecDiv>
                <FlexDiv key={NameValue}>
                  <FormLabel>Name</FormLabel>
                  <ProfilePara>{name}</ProfilePara>
                  <UpdatePass
                    onClick={() => {
                      if (ChangePropName === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropName("Cancel");
                        setChangePropNameDisplay("flex");
                      } else if (ChangePropName === "Cancel") {
                        setChangePropName("Edit");
                        setChangePropNameDisplay("none");
                      }
                    }}
                  >
                    {ChangePropName}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropNameDisplay}>
                  <FlexDiv>
                    <FormLabel>New Name</FormLabel>
                    <Input
                      inputwidth="40%"
                      inputheight="1px"
                      placeholder="Name"
                      defaultValue={NameValue}
                      onChange={(e) => {
                        setNameValue2(e.target.value);
                      }}
                    ></Input>
                  </FlexDiv>
                  <FlexDiv>
                    <UpdateProfileButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </UpdateProfileButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv key={emailValue}>
                  <FormLabel>Email</FormLabel>
                  <ProfilePara>{email}</ProfilePara>
                  <UpdatePass
                    onClick={() => {
                      if (ChangePropEmail === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropEmail("Cancel");
                        setChangePropEmailDisplay("flex");
                      } else if (ChangePropEmail === "Cancel") {
                        setChangePropEmail("Edit");
                        setChangePropEmailDisplay("none");
                      }
                    }}
                  >
                    {ChangePropEmail}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropEmailDisplay}>
                  <FlexDiv>
                    <FormLabel>New Email</FormLabel>
                    <Input
                      inputwidth="40%"
                      inputheight="1px"
                      placeholder="Email"
                      defaultValue={emailValue}
                      onChange={(e) => {
                        setEmailValue2(e.target.value);
                      }}
                    ></Input>
                  </FlexDiv>
                  <FlexDiv>
                    <UpdateProfileButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </UpdateProfileButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv key={PhoneValue}>
                  <FormLabel>Cell Phone</FormLabel>
                  <ProfilePara>{phone}</ProfilePara>
                  <UpdatePass
                    onClick={() => {
                      if (ChangePropPhone === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropPhone("Cancel");
                        setChangePropPhoneDisplay("flex");
                      } else if (ChangePropPhone === "Cancel") {
                        setChangePropPhone("Edit");
                        setChangePropPhoneDisplay("none");
                      }
                    }}
                  >
                    {ChangePropPhone}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropPhoneDisplay}>
                  <FlexDiv>
                    <FormLabel>New Cell Phone</FormLabel>
                    <Input
                      inputwidth="40%"
                      inputheight="1px"
                      placeholder="(XXX)-XXX-XXXX"
                      defaultValue={PhoneValue}
                      onChange={(e) => {
                        setPhoneValue2(e.target.value);
                      }}
                    ></Input>
                  </FlexDiv>
                  <FlexDiv>
                    <UpdateProfileButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </UpdateProfileButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Profile Photo</FormLabel>
                  <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
                  <FormDivider>
                    <PhotoCont
                      photoprops1={photoDisplay}
                      src={window.$IMGURL + profileDefaultUrl}
                      alt="No Profile Picture"
                    ></PhotoCont>
                  </FormDivider>
                  <UpdatePass
                    onClick={() => {
                      if (ChangePropImage === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropImage("Cancel");
                        setChangePropImageDisplay("flex");
                      } else if (ChangePropImage === "Cancel") {
                        setChangePropImage("Edit");
                        setChangePropImageDisplay("none");
                      }
                    }}
                  >
                    {ChangePropImage}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropImageDisplay}>
                  <FlexDiv>
                    <FormLabel>New Profile Photo</FormLabel>
                    <FileUpload
                      type="file"
                      id="catpic"
                      name="filename"
                      accept=".png,.heic,.jpg,.jpeg"
                      onChange={handleUpload}
                    ></FileUpload>
                  </FlexDiv>
                  <FlexDiv>
                    <UpdateProfileButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </UpdateProfileButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Region</FormLabel>
                  <ProfilePara>{metro}</ProfilePara>
                  <UpdatePass
                    onClick={() => {
                      UpdateMetroUIChanges(ChangePropMetroDisplay, ContactInfo);
                    }}
                  >
                    {ChangePropMetro}
                  </UpdatePass>
                </FlexDiv>
                <RegularTextBoldRed>{locationErrorText}</RegularTextBoldRed>
                <EditDiv displayprop={ChangePropMetroDisplay}>
                  <FlexRowCont60PercentSpaceEvenly>
                    <DatalistMedium
                      defaultValue={ContactInfo.current.Metro}
                      inputwidth="100%"
                      inputheight="40%"
                      id="metros"
                      onChange={(e) => {
                        SelectMetro(e);
                        UpdateCityList(e.target.value);
                      }}
                    >
                      <DatalistOption value={0}>Select Region</DatalistOption>
                      {metroList.map((o, i) => (
                        <DatalistOption
                          key={o.Id}
                          value={o.Id}
                          selected={o.Id === ContactInfo.current.Metro_Id}
                        >
                          {o.Name}
                        </DatalistOption>
                      ))}
                    </DatalistMedium>
                  </FlexRowCont60PercentSpaceEvenly>
                </EditDiv>
              </SecDiv>
              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>City</FormLabel>
                  <ProfilePara>{city}</ProfilePara>
                  <UpdatePass
                    display={changeTextDisplay}
                    onClick={() => {
                      UpdateCityUIChanges(ChangePropCity, ContactInfo);
                    }}
                  >
                    {ChangePropCity}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropCityDisplay}>
                  <FlexRowCont60PercentSpaceEvenly>
                    <DatalistMedium
                      defaultValue={ContactInfo.current.City}
                      inputwidth="100%"
                      inputheight="40%"
                      id="cities"
                      onChange={(e) => {
                        SelectCity(e);
                      }}
                    >
                      <DatalistOption value={0}>Select City</DatalistOption>
                      {cityList.map((o, i) => (
                        <DatalistOption
                          id="city"
                          selected={o.Id === ContactInfo.current.City_Id}
                          key={o.Id}
                          value={o.Id}
                        >
                          {o.Name}
                        </DatalistOption>
                      ))}
                    </DatalistMedium>
                  </FlexRowCont60PercentSpaceEvenly>
                </EditDiv>
              </SecDiv>

              <ProfileHR></ProfileHR>
              <SecDiv>
                <FlexDiv>
                  <FormLabel>Address</FormLabel>
                  <ProfilePara>{address}</ProfilePara>
                  <UpdatePass
                    onClick={() => {
                      if (ChangePropAddress === "Edit") {
                        setChangePropMetro("Change");
                        setChangePropCity("Change");
                        setChangePropNameDisplay("none");
                        setChangePropName("Edit");
                        setChangePropPhoneDisplay("none");
                        setChangePropPhone("Edit");
                        setChangePropEmailDisplay("none");
                        setChangePropEmail("Edit");
                        setChangePropImageDisplay("none");
                        setChangePropImage("Edit");
                        setChangePropAddress("Cancel");
                        setChangePropAddressDisplay("flex");
                      } else if (ChangePropAddress === "Cancel") {
                        setChangePropAddress("Edit");
                        setChangePropAddressDisplay("none");
                      }
                    }}
                  >
                    {ChangePropAddress}
                  </UpdatePass>
                </FlexDiv>
                <EditDiv displayprop={ChangePropAddressDisplay}>
                  <FlexDiv>
                    <FormLabel>New Address</FormLabel>
                    <Input
                      inputwidth="40%"
                      inputheight="1px"
                      placeholder="Address"
                      defaultValue={AddressValue}
                      onChange={(e) => {
                        setAddressValue2(e.target.value);
                      }}
                    ></Input>
                  </FlexDiv>
                  <FlexDiv>
                    <UpdateProfileButton
                      buttonbgcolor={"#169ADB"}
                      text="Hack My Yard"
                      buttonwidth="30%"
                      onClick={() => {
                        setChangePropAddressDisplay("none");
                        setChangePropAddress("Edit");
                        onPreview({
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl,
                        });
                        onProfileUpdated(
                          emailValue2,
                          NameValue2,
                          AddressValue2,
                          PhoneValue2,
                          profileDefaultUrl
                        );
                        GetProfileInfo();
                      }}
                    >
                      Save Changes
                    </UpdateProfileButton>
                  </FlexDiv>
                </EditDiv>
              </SecDiv>
            </LeftFormField>
          </FlexDiv>
        </Cont100Percent100Percent>
      );
    }
  }
};

UpdateProfile.defaultProps = {
  ProfileName: "Default Name",
  onChange: () => {},
  onPreview: () => {},
  onProfileUpdated: () => {},
  metro: "",
  city: "",
  updated: 0,
  name: "",
  email: "",
  phone: "",
  imgurl: "",
  address: "",
  role: 1000,
};

export default UpdateProfile;
