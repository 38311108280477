import axios from "axios";
import React, { useEffect, useState } from "react";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import {
  BlankDiv,
  FlexCenterDiv100PercentSpaceBetween,
} from "../../../StylesheetComps/Div";
import { Icon30x30 } from "../../../StylesheetComps/Imgs/Icons";
import { Image200x200pxContain } from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaRegular,
  TextInput72Percent,
  TextInputRegular,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanRegular,
  RegularText,
  RegularTextBoldRed,
} from "../../../StylesheetComps/Titles";

const CategoryEdit = ({
  open,
  onClose,
  onPreview,
  category,
  callCategoryAgain,
}) => {
  var categoryObj = category;

  var CateTypeReference = {
    Material: 0,
    AddOn: 1,
    Labor: 2,
    Access: 3,
    Removal: 4,
    Open: 5,
  };

  // catDefaultUrl is the URL/IMG that is displayed on the Front End of the Page. It uses the prop 'category.ImageUrl' to set its value. By default, category.ImageUrl is the Current Img URL
  var [catDefaultUrl, setCatDefaultUrl] = useState("");

  const [photoDisplay, setPhotoDisplay] = useState("block");
  const [errorText] = useState("");

  var type1 = false;
  var type2 = false;
  var type3 = false;
  var type4 = false;
  var type5 = false;
  var type6 = false;

  //DOCUMENTATION OF MATERIAL TYPE
  if (category !== undefined && category !== null) {
    switch (category.CateType) {
      case CateTypeReference.Material:
        type1 = true;
        break;
      case CateTypeReference.AddOn:
        type2 = true;
        break;
      case CateTypeReference.Labor:
        type3 = true;
        break;
      case CateTypeReference.Access:
        type4 = true;
        break;
      case CateTypeReference.Removal:
        type5 = true;
        break;
      case CateTypeReference.Open:
        type6 = true;
        break;
      default:
        type1 = true;
        break;
    }
  }

  // Handles file upload event and updates state

  function handleUpload(event) {
    var file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setCatDefaultUrl(pic);
      categoryObj.ImageUrl = pic;
      setPhotoDisplay("block");
      UpdateCategory(categoryObj);
    }
  };

  //When the Component loads, set the URL of the Img Comp as the URL of the Category

  useEffect(() => {
    if (category !== undefined) {
      setCatDefaultUrl(category.ImageUrl);
    }
  }, [category]);

  //When the Delete Button is clicked, pass the img prop to the backend, if the status is 200, clear the URL for both the value and src.

  const DeleteImg = async (img) => {
    var resp = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (resp.status === 200) {
      categoryObj.ImageUrl = "-";
      setCatDefaultUrl("-");
      UpdateCategory(categoryObj);
    }
  };

  const UpdateCategory = async (cate) => {
    var resp = await axios.put(window.$DBURL + "catalog", {
      CateType: cate.CateType,
      Description: cate.Description,
      Name: cate.Name,
      Id: cate.Id,
      ImageUrl: cate.ImageUrl,
      Position: cate.Position,
      Parent_Id: cate.Parent_Id,
      Combine: cate.Combine,
      CombineName: cate.CombineName,
    });
    if (resp.status === 200) {
      callCategoryAgain();
    }
  };

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <LeftItemWindowFixed width="30vw">
        <FlexDiv>
          <LeagueSpartanRegular>Category Name</LeagueSpartanRegular>
          <TextInputRegular
            placeholder="Category Name"
            defaultValue={category.Name}
            onChange={(e) => {
              categoryObj.Name = e.target.value;
            }}
          ></TextInputRegular>
        </FlexDiv>
        <hr></hr>
        <FlexDiv>
          <LeagueSpartanRegular>Position</LeagueSpartanRegular>
          <TextInputRegular
            placeholder="Position"
            defaultValue={category.Position}
            onChange={(e) => {
              categoryObj.Position = e.target.value;
            }}
          ></TextInputRegular>
        </FlexDiv>
        <hr></hr>
        <LeagueSpartanRegular>Category Type</LeagueSpartanRegular>
        <FlexRowCont100PercentSpaceBetween>
          <RegularText>
            <input
              defaultChecked={type1}
              type="radio"
              value={0}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Material
          </RegularText>
          <RegularText>
            <input
              defaultChecked={type2}
              type="radio"
              value={1}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Add-On
          </RegularText>
          <RegularText>
            <input
              defaultChecked={type3}
              type="radio"
              value={2}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Labor
          </RegularText>
        </FlexRowCont100PercentSpaceBetween>
        <FlexRowCont100PercentSpaceBetween>
          <RegularText>
            <input
              defaultChecked={type4}
              type="radio"
              value={3}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Access
          </RegularText>
          <RegularText>
            <input
              defaultChecked={type5}
              type="radio"
              value={4}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Removal
          </RegularText>
          <RegularText>
            <input
              defaultChecked={type6}
              type="radio"
              value={5}
              name="categoryid"
              onChange={(e) => {
                categoryObj.CateType = e.target.value;
              }}
            ></input>
            Open
          </RegularText>
        </FlexRowCont100PercentSpaceBetween>
        <hr></hr>
        <FlexDiv>
          <LeagueSpartanRegular>Category Description</LeagueSpartanRegular>
          <TextAreaRegular
            maxlength="200"
            onChange={(e) => {
              categoryObj.Description = e.target.value;
            }}
            defaultValue={category.Description}
          ></TextAreaRegular>
        </FlexDiv>
        <hr></hr>
        <FlexDiv>
          <LeagueSpartanRegular>Combine</LeagueSpartanRegular>
          <BlankDiv>
            <FlexDiv>
              <RegularText>
                <input
                  defaultChecked={category.Combine}
                  type="radio"
                  value={true}
                  name="combine"
                  onChange={(e) => {
                    categoryObj.Combine = e.target.value;
                  }}
                ></input>
                Yes
              </RegularText>

              <RegularText>
                <input
                  defaultChecked={!category.Combine}
                  type="radio"
                  value={false}
                  name="combine"
                  onChange={(e) => {
                    categoryObj.Combine = e.target.value;
                  }}
                ></input>
                No
              </RegularText>
            </FlexDiv>
          </BlankDiv>
        </FlexDiv>
        <FlexDiv>
          <LeagueSpartanRegular>Combine Name</LeagueSpartanRegular>
          <TextAreaRegular
            maxlength="100"
            onChange={(e) => {
              categoryObj.CombineName = e.target.value;
            }}
            defaultValue={category.CombineName}
          ></TextAreaRegular>
        </FlexDiv>
        <hr></hr>

        <FlexDiv>
          <LeagueSpartanRegular>Category Picture</LeagueSpartanRegular>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <FlexDiv>
            <Image200x200pxContain
              display={photoDisplay}
              src={window.$IMGURL + catDefaultUrl}
            ></Image200x200pxContain>
            <Icon30x30
              display={photoDisplay}
              src="/Garbage_Icon.svg"
              onClick={() => {
                DeleteImg(catDefaultUrl);
                setCatDefaultUrl("");
                setPhotoDisplay("none");
              }}
            ></Icon30x30>
          </FlexDiv>
        </FlexDiv>
        <TextInput72Percent
          type="file"
          id="catpic"
          name="filename"
          onChange={handleUpload}
        ></TextInput72Percent>
        <FlexCenterDiv100PercentSpaceBetween>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              onClose();
              setPhotoDisplay("block");
            }}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            onClick={() => {
              onPreview(categoryObj);
              UpdateCategory(categoryObj);
              onClose();
              setPhotoDisplay("block");
            }}
          >
            Save
          </RegularResponsiveButton>
        </FlexCenterDiv100PercentSpaceBetween>
      </LeftItemWindowFixed>
    </FixedOverlay200vh>
  );
};

CategoryEdit.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  callCategoryAgain: () => {},
  onImgDelete: () => {},
};

export default CategoryEdit;
