/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import EditSchedule from "../../comps/EditSchedule";
import Menu from "../../comps/MenuComps/Menu";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const MyScheduleScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Store Schedule Screen -  View the schedules of all workers in a store
  //=============================================================================

  //=============================================================================
  // Viewable by Manager of that store
  //=============================================================================

  const [isEditSchedule, setIsEditSchedule] = useState(false);
  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;
  const [scheduleList, setScheduleList] = useState([]);
  const [newEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState();
  var scheduleId = 0;
  var storeIdSync = 0;

  const history = useHistory();

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // USER INFO
  const [userId, setUserId] = useState(0);
  const [name, setName] = useState("");
  const [storeIdOfUser, setStoreIdOfUser] = useState(0);
  const [metroIdOfUser, setMetroIdOfUser] = useState(0);

  const GetSessionInfo = async () => {
    setUserId(JSON.parse(sessionStorage.getItem("userInfo")).Id);
    setName(JSON.parse(sessionStorage.getItem("userInfo")).Name);
    setStoreIdOfUser(JSON.parse(sessionStorage.getItem("userInfo")).Store_Id);
    setMetroIdOfUser(JSON.parse(sessionStorage.getItem("userInfo")).Metro_Id);
    storeIdSync = JSON.parse(sessionStorage.getItem("userInfo")).Store_Id;
    GetUserSchedules();
    GetStoreInfo();
  };

  const GetUserSchedules = async () => {
    if (storeIdSync == 0) {
      const resp = await axios.post(window.$DBURL + "schedule/store", {
        Year: currentYear,
        Month: currentMonth,
        Id: storeIdOfUser,
      });
      if (resp.status == 200) {
        if (resp.data !== null) {
          if (resp.data.schedules !== null) {
            setScheduleList(resp.data.schedules);
          }
        }
      }
    } else {
      const resp = await axios.post(window.$DBURL + "schedule/store", {
        Year: currentYear,
        Month: currentMonth,
        Id: storeIdSync,
      });
      if (resp.status == 200) {
        if (resp.data !== null) {
          if (resp.data.schedules !== null) {
            setScheduleList(resp.data.schedules);
          }
        }
      }
    }
  };

  //CURRENT SCHEDULE

  const [appDay, setAppDay] = useState(0);
  const [appMonth, setAppMonth] = useState(0);
  const [appYear, setAppYear] = useState(0);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const [contactName, setContactName] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [scheduleIdAsync, setScheduleIdAsync] = useState(0);

  var fulldate = "";
  var storeMonth = 0;
  var storeYear = 0;
  var storeDay = 0;

  //NEW SCHEDULE

  const [scheduleAddress, setScheduleAddress] = useState("");
  const [schedulePhone, setSchedulePhone] = useState("");
  const [scheduleCity, setScheduleCity] = useState("");
  const [scheduleDescription, setScheduleDescription] = useState("");
  const [scheduleStartHour, setScheduleStartHour] = useState(0);
  const [scheduleStartMinute, setScheduleStartMinute] = useState(0);
  const [scheduleEndHour, setScheduleEndHour] = useState(0);
  const [scheduleEndMinute, setScheduleEndMinute] = useState(0);

  var nameNew = "";
  var addressNew = "";
  var phoneNew = "";
  var cityNew = "";
  var descriptionNew = "";

  var startHourNew = 0;
  var startMinuteNew = 0;
  var endHourNew = 0;
  var endMinuteNew = 0;

  var yearNew = 0;
  var monthNew = 0;
  var dayNew = 0;

  const GetScheduleInfo = async () => {
    const resp = await axios.get(window.$DBURL + "schedule/" + scheduleId);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.schedule !== null) {
          setAppDay(resp.data.schedule.Day);
          setAppMonth(resp.data.schedule.Month);
          setAppYear(resp.data.schedule.Year);

          if (resp.data.schedule.Start !== null) {
            setStartHour(resp.data.schedule.Start.Hour);
            setStartMinute(resp.data.schedule.Start.Minute);
          }

          if (resp.data.schedule.End !== null) {
            setEndHour(resp.data.schedule.End.Hour);
            setEndMinute(resp.data.schedule.End.Minute);
          }

          setAppDesc(resp.data.schedule.Description);
          setContactName(resp.data.schedule.Contactname);
          setAddress(resp.data.schedule.Street);
          setPhone(resp.data.schedule.Phone);
          setCity(resp.data.schedule.City);
        }
      }
    }
  };

  const GetScheduleInfoAsync = async () => {
    const resp = await axios.get(window.$DBURL + "schedule/" + scheduleIdAsync);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.schedule !== null) {
          setAppDay(resp.data.schedule.Day);
          setAppMonth(resp.data.schedule.Month);
          setAppYear(resp.data.schedule.Year);

          if (resp.data.schedule.Start !== null) {
            setStartHour(resp.data.schedule.Start.Hour);
            setStartMinute(resp.data.schedule.Start.Minute);
          }

          if (resp.data.schedule.End !== null) {
            setEndHour(resp.data.schedule.End.Hour);
            setEndMinute(resp.data.schedule.End.Minute);
          }

          setAppDesc(resp.data.schedule.Description);
          setContactName(resp.data.schedule.Contactname);
          setAddress(resp.data.schedule.Street);
          setPhone(resp.data.schedule.Phone);
          setCity(resp.data.schedule.City);
        }
      }
    }
  };

  const HandleScheduleForm = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    setScheduleAddress(address);
    setSchedulePhone(phone);
    setScheduleCity(city);
    setScheduleDescription(appDesc);
    setScheduleStartHour(openHourSelected);
    setScheduleStartMinute(openMinuteSelected);
    setScheduleEndHour(closeHourSelected);
    setScheduleEndMinute(closeMinuteSelected);
  };

  const HandleEditScheduleForm = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    EditYear,
    EditMonth,
    EditDay,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    nameNew = contactName;
    addressNew = address;
    phoneNew = phone;
    cityNew = city;
    descriptionNew = appDesc;
    startHourNew = openHourSelected;
    startMinuteNew = openMinuteSelected;
    endHourNew = closeHourSelected;
    endMinuteNew = closeMinuteSelected;
    yearNew = EditYear;
    monthNew = EditMonth;
    dayNew = EditDay;
  };

  const HandleNewSchedule = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    const resp = await axios.post(window.$DBURL + "schedule", {
      Crewname: name,
      Crew_Id: userId,
      Year: year,
      Month: month,
      Day: day,
      Start: { Hour: scheduleStartHour, Minute: scheduleStartMinute },
      End: { Hour: scheduleEndHour, Minute: scheduleEndMinute },
      Description: scheduleDescription,
      Street: scheduleAddress,
      City: scheduleCity,
      Phone: schedulePhone,
      Contactname: name,
      Store_Id: storeIdOfUser,
    });
    GetUserSchedules();
  };

  const HandleUpdateSchedule = async ({}) => {
    const resp = await axios.put(window.$DBURL + "schedule", {
      Crewname: name,
      Id: scheduleIdAsync,
      Year: yearNew || year,
      Month: monthNew || month,
      Day: dayNew || day,
      Start: {
        Hour: startHourNew || startHour,
        Minute: startMinuteNew || startMinute,
      },
      End: { Hour: endHourNew || endHour, Minute: endMinuteNew || endMinute },
      Description: descriptionNew || appDesc,
      Street: addressNew || address,
      City: cityNew || city,
      Phone: phoneNew || phone,
      Contactname: nameNew || contactName,
      Store_Id: storeIdOfUser,
    });

    GetUserSchedules();
    GetScheduleInfoAsync();
  };

  const HandleDeleteSchedule = async () => {
    const resp = await axios.delete(
      window.$DBURL + "schedule/" + scheduleIdAsync
    );
    GetUserSchedules();
  };

  const [storeName, setStoreName] = useState("");

  const GetStoreInfo = async () => {
    if (storeIdSync == 0) {
      const resp = await axios.get(window.$DBURL + "store/" + storeIdOfUser);
      setStoreName(resp.data.store.Name);
    } else {
      const resp = await axios.get(window.$DBURL + "store/" + storeIdSync);
      setStoreName(resp.data.store.Name);
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  var fullDate = "";
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);

  const HandleStartInfo = async () => {
    setYear(fullDate.getFullYear());
    setMonth(fullDate.getMonth() + 1);
    setDay(fullDate.getDate());
  };

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <EditSchedule
            DeleteSchedule={() => {
              HandleDeleteSchedule();
              setIsEditSchedule(false);
            }}
            onPreview={HandleEditScheduleForm}
            onScheduleEdit={HandleUpdateSchedule}
            open={isEditSchedule}
            onClose={() => {
              setIsEditSchedule(false);
            }}
            month={appMonth}
            day={appDay}
            year={appYear}
            dfName={contactName}
            dfAddress={address}
            dfCity={city}
            dfCellPhone={phone}
            dfNotes={appDesc}
            startHour={startHour}
            startMinute={startMinute}
            endHour={endHour}
            endMinute={endMinute}
          ></EditSchedule>
          <LeagueSpartanExtraLargeBold>
            {storeName + "'s Store Schedule"}
          </LeagueSpartanExtraLargeBold>
          <Calendar
            localizer={localizer}
            events={scheduleList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Crewname,
                start: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.Start.Hour,
                  o.Start.Minute
                ),
                end: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.End.Hour,
                  o.End.Minute
                ),
                allDay: false,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "75%", width: "100%" }}
            popup
            dayLayoutAlgorithm="no-overlap"
            longPressThreshold={10}
            selectable={true}
            views={{ month: true }}
            defaultView={Views.Month}
            onSelectSlot={(slotInfo) => {
              fulldate = slotInfo.slots[0];
              storeMonth = fulldate.getMonth() + 1;
              storeYear = fulldate.getFullYear();
              storeDay = fulldate.getDate();
              history.push(
                "/storescheduleday/" +
                  metroIdOfUser +
                  "/" +
                  storeIdOfUser +
                  "/" +
                  storeMonth +
                  "/" +
                  storeDay +
                  "/" +
                  storeYear
              );
            }}
            onSelectEvent={(event) => {
              scheduleId = event.id;
              setScheduleIdAsync(event.id);
              GetScheduleInfo();
              fullDate = event.start;
              HandleStartInfo();
              setIsEditSchedule(true);
            }}
            onNavigate={(date) => {
              GetUserSchedules();
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

MyScheduleScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default MyScheduleScreen;
