import axios from "axios";
import React, { useEffect, useState } from "react";
import ActionMenu from "../../comps/ActionMenu";
import ManagerCard from "../../comps/ManagerCard";
import Menu from "../../comps/MenuComps/Menu";
import AddManagerWindow from "../../comps/Windows/AddManager";

import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { FlexCont100vw100vh, LeftCont } from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const ManagerListScreen = () => {
  //=============================================================================
  // Manager List Screen - Create Managers / View Managers here
  //=============================================================================

  //=============================================================================
  // Admin oriented page
  //=============================================================================

  //Add Manager
  const [managerArray, setManagerArray] = useState([]);

  const [isNewManagerOpen, setIsNewManagerOpen] = useState(false);

  var setManagerName = "";
  var setManagerUsername = "";
  var setManagerEmail = "";
  var setManagerPhone = "";
  var setManagerPass = "";

  var newStoreId = 0;
  var newMetroId = 0;
  var setUserId = 0;

  const GetManagers = async () => {
    try {
      const { data } = await axios.get(
        `${window.$DBURL}/admin/manager/metro/0`
      );
      setManagerArray(data.managers);
    } catch (error) {
      // Handle the error and log it to the console
      console.error(error);
    }
  };

  const HandleFormComplete = async ({
    managerName,
    managerUsername,
    managerEmail,
    managerPhone,
    managerPass,
    metroId,
    storeId,
  }) => {
    setManagerName = managerName;
    setManagerUsername = managerUsername;
    setManagerEmail = managerEmail;
    setManagerPhone = managerPhone;
    setManagerPass = managerPass;
    newStoreId = storeId;
    newMetroId = metroId;
  };

  const HandleManagerDel = async () => {
    try {
      // Send a DELETE request to the server using axios.
      const resp = await axios.delete(
        `${window.$DBURL}admin/manager/${setUserId}`
      );

      // retrieve the updated list of managers by calling the GetManagers() function.
      if (resp.status === 200) {
        GetManagers();
      }
    } catch (error) {
      // If an error occurs, log it to the console for further investigation.
      console.error(error);
    }
  };

  const HandleNewManager = async () => {
    try {
      // Create an object that contains the manager's information.
      const manager = {
        Name: setManagerName,
        Username: setManagerUsername,
        Password: setManagerPass,
        Phone: setManagerPhone,
        Email: setManagerEmail,
        Metro_Id: newMetroId,
        Store_Id: newStoreId,
      };

      // Send a POST request to the server using axios. The URL of the server is constructed
      // using the DBURL environment variable and the ID of the new store.
      const resp = await axios.post(
        `${window.$DBURL}admin/manager/${newStoreId}`,
        manager
      );

      // If the request is successful (i.e. the server responds with a 200 status code),
      // retrieve the updated list of managers by calling the GetManagers() function.
      if (resp.status === 200) {
        GetManagers();
      }
    } catch (error) {
      // If an error occurs, log it to the console for further investigation.
      console.error(error);
    }
  };

  useEffect(() => {
    GetManagers();
  }, []);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <LeagueSpartanExtraLargeBold>Managers</LeagueSpartanExtraLargeBold>
            <RegularResponsiveButton
              onClick={() => {
                setIsNewManagerOpen(true);
              }}
            >
              Create Manager
            </RegularResponsiveButton>
          </FlexDiv70x7Percent>
          <AddManagerWindow
            open={isNewManagerOpen}
            onClose={() => setIsNewManagerOpen(false)}
            onPreview={HandleFormComplete}
            onFormComplete={HandleNewManager}
          ></AddManagerWindow>
          {managerArray.map((o, i) => (
            <ManagerCard
              ManagerName={o.Name}
              StoreLocationName={o.Store_Id}
              MetroName={o.Metro_Id}
              onDel={() => {
                setUserId = o.Id;
                HandleManagerDel();
              }}
            ></ManagerCard>
          ))}
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

ManagerListScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default ManagerListScreen;
