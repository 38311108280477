import { useState } from "react";
import { BodyPara, Header } from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import {
  FlexColumnCont53x100Percent,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexRowCont100x8PercentSpaceAround,
} from "../../../StylesheetComps/Cont";
import {
  EstimatorDiv,
  GreenNavigationButton,
  GreyNavigationButton,
  LandscapePara,
  ProgressBar,
  ProgressBarWidth,
  Section1,
} from "../../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../../StylesheetComps/Menu";
import { RegularTextBoldRed } from "../../../StylesheetComps/Titles";
const LandscapeDesc = ({ open, onNext, onBack, onPreview, preDesc }) => {
  const [description, setDescription] = useState("");
  const [characterLimitState, setCharacterLimitState] = useState("none");

  //Check Description Text, if greater than or equal to 1500 characters,
  //display character limit

  const CheckLandscapeDescription = async (landscapeText) => {
    if (landscapeText !== null) {
      setDescription(landscapeText);
      if (landscapeText.length >= 1500) {
        setCharacterLimitState("block");
      } else {
        setCharacterLimitState("none");
      }
    }
  };

  if (!open) return null;

  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="75%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Description
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="2%"
          fontsize="16px"
        >
          Tell us what you'd like to do with your outdoor space. Include your
          project's details here.
        </BodyPara>

        <FlexColumnFullWidth width="50%">
          <RegularTextBoldRed display={characterLimitState}>
            (No more than 1500 characters){" "}
          </RegularTextBoldRed>
          <LandscapePara
            mobileheight="40vh"
            defaultValue={description}
            maxLength="1500"
            placeholder="I want to completely redo my garden. For starters I want..."
            onChange={(e) => {
              CheckLandscapeDescription(e.target.value);
            }}
          ></LandscapePara>
          <SmallHeader
            color={ColorPalette.PrimaryGrey}
            fontsize="16px"
            margintop="3%"
          >
            Character Count: {description.length}{" "}
          </SmallHeader>
        </FlexColumnFullWidth>
        <FlexRowCont100x8PercentSpaceAround>
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              onNext();
              onPreview({ description });
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexRowCont100x8PercentSpaceAround>
      </Section1>
    </EstimatorDiv>
  );
};

LandscapeDesc.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onStoreDescriptionLocal: () => {},
};

export default LandscapeDesc;
