import { useHistory, useParams } from "react-router-dom";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnFullWidth,
  FlexContCenterAlign,
  FlexRowCont70PercentSpaceBetween,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanRegularMinWidth,
} from "../../StylesheetComps/Titles";

const InstallerCard = ({
  InstallerName,
  installerId,
  installerUserId,
  index,
}) => {
  const history = useHistory();
  const params = useParams();

  return (
    <FlexColumnFullWidth
      onClick={() => {
        history.push(
          "/installercalendar/" +
            params.Id +
            "/" +
            params.storeId +
            "/" +
            installerId +
            "/" +
            installerUserId +
            "/" +
            InstallerName +
            "/" +
            0 +
            "/" +
            0 +
            "/" +
            0 +
            "/" +
            0 +
            "/" +
            0
        );
      }}
    >
      <FlexColumnFullWidth>
        <FlexRowCont70PercentSpaceBetween>
          <FlexContCenterAlign>
            <LeagueSpartanRegularMinWidth width="25px">
              {index + 1}.
            </LeagueSpartanRegularMinWidth>
            <LeagueSpartanExtraLargeBold>
              {InstallerName}
            </LeagueSpartanExtraLargeBold>
          </FlexContCenterAlign>
          <RegularResponsiveButton>Calendar</RegularResponsiveButton>
        </FlexRowCont70PercentSpaceBetween>
      </FlexColumnFullWidth>
    </FlexColumnFullWidth>
  );
};

InstallerCard.defaultProps = {
  InstallerName: "Default Name",
  StoreAddress: "123 Yonge Street",
  roleID: 0,
  MetroName: "Toronto",
  Username: "",
  Email: "",
  Phone: "",
  installerId: 0,
  installerUserId: 0,
};

export default InstallerCard;
