import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { useHistory, useParams } from "react-router-dom";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { FlexDiv } from "../../StylesheetComps/Cont";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  right:5px;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0 0 10px 10px; 
`;

const DropdownLink = styled.a`
  color: black;
  width: 100%;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor:pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const Dropdown = ({currentPage, executeCallback, notifyProposal, deleteProposal, quoteId, iconSrc, duplicateProposal, addOns, deleteItem, saveAndSend, saveEstimate, changeTag, archiveProposal, unArchiveProposal, acceptProposal, resetProposal}) => {

    const [showMenu, setShowMenu] = useState(false);
    const [staffDisplay, setStaffDisplay] = useState('none');
    const params = useParams();
    const history = useHistory();
    const dropdownRef = useRef(null); // Reference to the dropdown container

    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };


    const links = getLinksForPage(currentPage)

    function getLinksForPage(currentPage) {
      switch (currentPage) {
        case 'homeownerView':
          return [
            { 
              label: 'Estimator View', 
              url: `/viewestimatestaff/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0` 
            },
            { 
              label: 'Construction View', 
              url: `/viewestimateconstruction/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'Accountant View', 
              url: `/viewestimateaccountant/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'SiteMap View', 
              url: window.$ISMOBILE === true ? `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}` : `/sitemapperdesktop/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}`
            },
          ];
        case 'estimatorView':
          return [
            { 
              label: 'View Project', 
              url: `/viewcase/${params.Id}/${params.storeId}/${params.caseId}` 
            },
            { 
              label: 'Homeowner View', 
              url:  `/viewquoteuser/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'Construction View', 
              url: `/viewestimateconstruction/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'Accountant View', 
              url: `/viewestimateaccountant/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'SiteMap View', 
              url: window.$ISMOBILE === true ? `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}` : `/sitemapperdesktop/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}`
            },
            { 
              label: 'Save Estimate', 
              callback: () => {
                saveEstimate(); setShowMenu(false)
                  }, 
            },
            { 
              label: 'Save & Send', 
              callback: () => {
               var userResponse = window.confirm("Are you sure you want to send this Estimate?")
               if (userResponse) {
                // User clicked "OK"
                saveAndSend(); setShowMenu(false)
            } else {
                // User clicked "Cancel"
                setShowMenu(false);
            }
            
                  }, 
            },
          ];
          case 'itemCard':
            return [
              { 
                label: 'Add-Ons', 
                callback: () => { addOns(); setShowMenu(false) }, 
              },
              { 
                label: 'Change Tag', 
                callback: () => { changeTag(); setShowMenu(false) }, 
              },
              { 
                label: 'Delete', 
                callback: () => { deleteItem(); setShowMenu(false) }, 
              },
            ];
            case 'deliveryItemCard':
            return [
              { 
                label: 'Delete', 
                callback: () => { deleteItem(); setShowMenu(false) }, 
              },
            ];
        case 'constructionView':
          return [
            { 
              label: 'Homeowner View', 
              url:  `/viewquoteuser/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'Estimator View', 
              url: `/viewestimatestaff/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0` 
            },
            { 
              label: 'Accountant View', 
              url: `/viewestimateaccountant/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/0/0` 
            },
            { 
              label: 'SiteMap View', 
              url: window.$ISMOBILE === true ? `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}` : `/sitemapperdesktop/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}`
            },
          ];
        case 'proposalCardGuide':
          return [
            { 
              label: 'Homeowner View', 
              url:  `/viewquoteuser/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}/0/0` 
            },
            { 
              label: 'Old Estimator View', 
              url: `/viewquote/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}/0` 
            },
            { 
              label: 'Estimator View', 
              url: `/viewestimatestaff/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}/0` 
            },
            { 
              label: 'Construction View', 
              url: `/viewestimateconstruction/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}/0/0` 
            },
            { 
              label: 'Accountant View', 
              url: `/viewestimateaccountant/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}/0/0` 
            },
            { 
              label: 'SiteMap View', 
              url: window.$ISMOBILE === true ? `/sitemapper/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}` : `/sitemapperdesktop/${params.Id}/${params.storeId}/${params.caseId}/${quoteId}`
            },
            { 
              label: 'Archive Estimate', 
              callback: () => { archiveProposal() } 
            },
            { 
              label: 'Unarchive Estimate', 
              callback: () => { unArchiveProposal() } 
            },
            // { 
            //   label: 'Accept Estimate', 
            //   callback: () => { acceptProposal() } 
            // },
            // { 
            //   label: 'Unselect Estimate', 
            //   callback: () => { resetProposal() } 
            // },
            { 
              label: 'Send', 
              callback: () => { notifyProposal() } 
            },
            { 
              label: 'Duplicate', 
              callback: () => { duplicateProposal() } 
            },
            { 
              label: 'Delete', 
              callback: () => { deleteProposal() } 
            },
          
          ];
        default:
          return [];
      }
    }
    
          
      useEffect(() => { 
        var roleNum = (JSON.parse(sessionStorage.getItem('userInfo')).Role)
        setStaffDisplay(roleNum === 0 ? 'none' : 'block')
        
      }, []) 

      useEffect(() => {
        // Add event listener to handle outside clicks
        document.addEventListener("mousedown", handleClickOutside);
  
        return () => {
          // Remove event listener on cleanup
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []); // Empty dependency array ensures this runs once on mount and unmount
  
  
    return (
      <DropdownContainer ref={dropdownRef}>
        <Icon30px30pxPointer src='/threedotmenu.svg'
                    display={staffDisplay}
                    onClick={toggleMenu}
                    >
                      
                    </Icon30px30pxPointer>
        <DropdownMenu show={showMenu}>
          {links.map((link, index) =>
          <FlexDiv>
          <Icon30px30pxPointer display={iconSrc === null || iconSrc === undefined ? 'none' : iconSrc} ></Icon30px30pxPointer>
        <DropdownLink
        key={index}
        href={link.callback ? undefined : link.url}
        onClick={(event) => {
          if (link.url) {
            event.preventDefault();
            history.push(link.url);
          } else if (link.callback) {
            event.preventDefault();
            link.callback();
          }
        }}
      >
        {link.label}
      </DropdownLink>
      </FlexDiv>
          )}
        </DropdownMenu>
      </DropdownContainer>
    );
  };

  Dropdown.defaultProps = {
    currentPage:'homeownerView',
    executeCallback:()=>{},
    deleteProposal:()=>{},
    notifyProposal:()=>{},
    duplicateProposal:()=>{},
    resetProposal: ()=>{},
    addOns:()=>{},
    deleteItem:()=>{},
    saveAndSend:()=>{},
    saveEstimate:()=>{},
    archiveProposal:()=>{},
    unArchiveProposal:()=>{},
    acceptProposal:()=>{},
    changeTag:()=>{},
    iconSrc:null
  }
  
  export default Dropdown;
  
