import styled from "styled-components";

const nullProp = null;

//Material Selection -------------------------------------------------

export const HundredPercentFlexDiv = styled.div`
  display: flex;
  width: 100%;
`;

export const HundredPercentFlexDivCenterItems = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ItemSelectionContainer = styled.div`
  width: 57%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: white;

  @media (max-width: 1006px) {
    width: 90%;
    margin-top: 10%;
    align-items: center;
  }
`;

export const TwentyPercentWidthCont = styled.div`
  width: 20%;
  margin: 1%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: white;

  @media (max-width: 1006px) {
    width: 90%;
    margin-top: 10%;
    align-items: center;
  }
`;

export const Cont100PercentWidth = styled.div`
  width: 100%;
`;

export const CategoryCont = styled.div`
  align-items: center;
  width: 100%;
  height: auto;
  padding: 5%;
  box-shadow: 0px 4px 8px #b7b7b7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) =>
    props.bgcolorprops ? props.bgcolorprops : "white"};
`;

export const CategoryCenterInnerDiv = styled.div`
  margin: 5%;
  display: flex;
  height: auto;
  justify-content: center;
  justify-items: center;
`;

export const AvenirCategoryLabelWide = styled.p`
  font-size: 14px;
  font-family: Avenir;
  width: 80%;
`;

export const GridCont100PercentDisplayProps = styled.div`
  width: 100%;
  height: auto;
  max-height: ${(props) => (props.maxheight ? props.maxheight : "")};
  overflow-y: auto;
  display: ${(props) => (props.display ? props.display : "grid")};
  grid-template-columns: repeat(4, 1fr);
`;

export const Icon30px20pxPointer = styled.img`
  height: 20px;
  width: 30px;
  display: ${(props) => (props.display ? props.display : "flex")};
  cursor: pointer;
  margin-top: ${(props) => (props.margintop ? props.margintop : "auto")};;
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "auto")};;
  margin-left: 5px;
  filter: ${(props) => (props.color ? `opacity(0.5) drop-shadow(0 0 0 ${props.color})` : 'none')};
`;

export const Icon30px30pxPointer = styled.img`
  height: 30px;
  width: 30px;
  display: ${(props) => (props.display ? props.display : "flex")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
`;

export default nullProp;
