import React from 'react';
import styled from 'styled-components';
import { ColorPalette } from '../../StylesheetComps/Colors';
import { RegularResponsiveButton } from '../../StylesheetComps/Components';

const SecondButtonContainer = styled.div`
position: fixed;
  bottom: 0%;
  padding:10px;
  border-radius: 0px 5px 5px 0px;
  justify-content:flex-end;
  display:flex;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  z-index: 999;
  background-color: ${ColorPalette.GreyGreen};
  width:99vw;
  opacity: ${({ show }) => (show ? 0 : 1)};

`;

const EstimateFooter = ({ buttonRef, depositAmount, depositCallback }) => {
  const [isButtonInView, setIsButtonInView] = React.useState(true);

  const handleScroll = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const isButtonOutOfView = buttonRect.top > windowHeight || buttonRect.bottom < 0;
    setIsButtonInView(!isButtonOutOfView);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [buttonRef]);

  return (
    <SecondButtonContainer show={isButtonInView}>
      <RegularResponsiveButton onClick={()=>{depositCallback()}} width="auto">Pay Deposit ${depositAmount.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}</RegularResponsiveButton>
    </SecondButtonContainer>
  );
};

EstimateFooter.defaultProps = {
depositAmount:0,
depositCallback:()=>{}
}

export default EstimateFooter;
