import axios from "axios";
import React, { useState } from "react";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  ContMargin20px,
  FlexColumnCont100xAutoPercent,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { FlexCenterDiv100PercentSpaceBetween } from "../../../StylesheetComps/Div";
import { Icon30x30 } from "../../../StylesheetComps/Imgs/Icons";
import { Image200x200pxContain } from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaRegular,
  TextInputRegular,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Icon30px20pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularText,
  RegularTextBoldRed,
  TinyTextItalics,
} from "../../../StylesheetComps/Titles";

const CategoryAdd = ({
  open,
  onClose,
  onCategoryAdd,
  CatPosProp,
  parentId,
}) => {
  const [errorText] = useState("");
  const [photoDisplay, setPhotoDisplay] = useState("none");
  const hiddenFileInput = React.useRef(null);
  const [CombineInstructionDisplay, setCombineInstructionDisplay] =
    useState("none");

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Handles file upload event and updates state
  function handleUpload(event) {
    var file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      categoryObj.current.ImageUrl = pic;
      setPhotoDisplay("block");
    }
  };

  const DeleteImg = async (img) => {
    var respimg = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (respimg.status === 200) {
      categoryObj.current.ImageUrl = "-";
      setPhotoDisplay("none");
    }
  };

  var cate = {
    CateType: 0,
    Description: "",
    Name: "",
    ImageUrl: "",
    Position: CatPosProp,
    Parent_Id: parentId,
    Combine: false,
    CombineName: "",
  };

  let categoryObj = React.useRef(cate);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <ContMargin20px>
        <LeftItemWindowFixed>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Category Name
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputRegular
              placeholder="Category Name"
              onChange={(e) => {
                categoryObj.current.Name = e.target.value;
              }}
            ></TextInputRegular>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Position
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputRegular
              placeholder="Position"
              defaultValue={CatPosProp}
              onChange={(e) => {
                categoryObj.current.Position = e.target.value;
              }}
            ></TextInputRegular>
          </FlexDiv>
          <FlexDiv>
            <FlexRowCont100PercentSpaceBetween>
              <RegularText>
                <input
                  type="radio"
                  value={0}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Material
              </RegularText>

              <RegularText>
                <input
                  type="radio"
                  value={1}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Add-On
              </RegularText>

              <RegularText>
                <input
                  type="radio"
                  value={2}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Labor
              </RegularText>
            </FlexRowCont100PercentSpaceBetween>
          </FlexDiv>
          <FlexDiv>
            <FlexRowCont100PercentSpaceBetween>
              <RegularText>
                <input
                  type="radio"
                  value={3}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Access
              </RegularText>

              <RegularText>
                <input
                  type="radio"
                  value={4}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Removal
              </RegularText>

              <RegularText>
                <input
                  type="radio"
                  value={5}
                  name={"categoryid"}
                  onChange={(e) => {
                    categoryObj.current.CateType = e.target.value;
                  }}
                ></input>
                Open
              </RegularText>
            </FlexRowCont100PercentSpaceBetween>
          </FlexDiv>

          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Category Description
            </LeagueSpartanExtraLargeWidth200px>
            <TextAreaRegular
              maxlength="200"
              onChange={(e) => {
                categoryObj.current.Description = e.target.value;
              }}
            ></TextAreaRegular>
          </FlexDiv>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Combine
            </LeagueSpartanExtraLargeWidth200px>
            <Icon30px20pxPointer
              src="/questionmark.svg"
              onClick={() => {
                if (CombineInstructionDisplay === "none") {
                  setCombineInstructionDisplay("block");
                } else {
                  setCombineInstructionDisplay("none");
                }
              }}
            ></Icon30px20pxPointer>

            <FlexDiv>
              <RegularText>
                <input
                  type="radio"
                  defaultChecked={false}
                  value={true}
                  name={"combine"}
                  onChange={(e) => {
                    categoryObj.current.Combine = e.target.value;
                  }}
                ></input>
                Yes
              </RegularText>

              <RegularText>
                <input
                  type="radio"
                  defaultChecked={true}
                  value={false}
                  name={"combine"}
                  onChange={(e) => {
                    categoryObj.current.Combine = e.target.value;
                  }}
                ></input>
                No
              </RegularText>
            </FlexDiv>
          </FlexDiv>
          <TinyTextItalics maxwidth="500px" display={CombineInstructionDisplay}>
            (If Combine is set to yes, then the labor cost related to products
            of this category will be combined under the name of install
            CombineName when displayed in quote. Don't set Minimum and RoundUp
            for products and their related labor items when Combine is set Yes
            for the category.)
          </TinyTextItalics>
          <br></br>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              CombineName
            </LeagueSpartanExtraLargeWidth200px>
            <TextInputRegular
              placeholder="CombineName"
              maxlength="100"
              onChange={(e) => {
                categoryObj.current.CombineName = e.target.value;
              }}
            ></TextInputRegular>
          </FlexDiv>
          <br></br>
          <FlexDiv>
            <LeagueSpartanExtraLargeWidth200px>
              Category Picture
            </LeagueSpartanExtraLargeWidth200px>
            <RegularResponsiveButton
              for="catpic"
              class="btn"
              onClick={handleClick}
            >
              Load Image
            </RegularResponsiveButton>
          </FlexDiv>
          <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
          <FlexDiv>
            <Image200x200pxContain
              display={photoDisplay}
              src={window.$IMGURL + categoryObj.current.ImageUrl}
            ></Image200x200pxContain>
            <Icon30x30
              display={photoDisplay}
              src="/Garbage_Icon.svg"
              onClick={() => {
                DeleteImg(categoryObj.current.ImageUrl);
              }}
            ></Icon30x30>
          </FlexDiv>
          <FlexColumnCont100xAutoPercent></FlexColumnCont100xAutoPercent>
          <TextInputRegular
            inputdisplay="none"
            type="file"
            id="catpic"
            ref={hiddenFileInput}
            name="filename"
            onChange={handleUpload}
          ></TextInputRegular>
          <FlexCenterDiv100PercentSpaceBetween>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setPhotoDisplay("none");
                categoryObj.current = {
                  CateType: 0,
                  Description: "",
                  Name: "",
                  ImageUrl: "",
                  Position: CatPosProp,
                  Parent_Id: parentId,
                  Combine: false,
                  CombineName: "",
                };
              }}
            >
              Cancel
            </RegularResponsiveButton>
            <RegularResponsiveButton
              onClick={() => {
                onCategoryAdd(categoryObj.current);
                setPhotoDisplay("none");
                onClose();
                categoryObj.current = {
                  CateType: 0,
                  Description: "",
                  Name: "",
                  ImageUrl: "",
                  Position: CatPosProp,
                  Parent_Id: parentId,
                  Combine: false,
                  CombineName: "",
                };
              }}
            >
              Save
            </RegularResponsiveButton>
          </FlexCenterDiv100PercentSpaceBetween>
        </LeftItemWindowFixed>
      </ContMargin20px>
    </FixedOverlay200vh>
  );
};

CategoryAdd.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onCategoryAdd: () => {},
  onSetFile: () => {},
  onReleaseFile: () => {},
  CatPosProp: 0,
  parentId: 0,
};

export default CategoryAdd;
