import axios from 'axios';
import {GetMetroTaxes} from '../Utility_Functions'

export const GetStoreInfo = async(store_id)=>{
    var resp = await axios.get(window.$DBURL+"store/"+store_id);
   
    if(resp.status ===200)
    {
        var store = resp.data.store;
        return store
    }
    else
        return null;
}


export const GetTaxesForStore = async(store_id)=>{
    var store = await GetStoreInfo(store_id);
    
    if(store!=null)
    {
      var tx = await GetMetroTaxes(store.Metro_Id); 
      return tx;
    }
    else
        return []
}

export const GetTaxesForMetro = async(metro_id)=>{
    var tx = await axios.get(window.$DBURL+"tax/metro/"+metro_id)
    return tx.data.tax
}

export const AddStore = async(store)=>{
    var resp = await axios.post(window.$DBURL+"store/info",{Store:store});
   
    if(resp.status ===200)
    {      
        return resp.data.store;
    }
    else
        return null;
}

export const UpdateStore = async(store)=>{
    var resp = await axios.put(window.$DBURL+"store/info",{Store:store});
   
    if(resp.status ===200)
    {       
        return resp.data.store;
    }
    else
        return null;
}

export const GetStoreByMetro = async(metroid)=>{
    var resp = await axios.get(window.$DBURL+"store/metro/"+metroid);    
    if(resp.status === 200){
      if(resp.data !== null){
        if(resp.data.stores !== null){    
            return resp.data.stores;
        }
      }
    }  

    return null;
}

export const AddStaffToStore = async(storeid)=>{
    var resp = await axios.post(window.$DBURL+"users/staff/addto/"+storeid);    
}
