/* eslint-disable */

import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { GetUserCases } from "../../../API_Functions/CaseQuote_Functions";
import { AddStaffToStore, GetStoreByMetro } from "../../../API_Functions/Store_Functions";
import {
  GetCityList,
  GetMetroList,
} from "../../../API_Functions/Utility_Functions";
import { MobileWideGreenButton } from "../../../MobileComponents/Buttons";
import { Div100vw20vh } from "../../../MobileComponents/Divs";
import { LogoRelative, TopImage20vh } from "../../../MobileComponents/Images";
import { UnderlinedBoldTitle16px } from "../../../MobileComponents/Text";
import {
  AuthCont,
  BodyPara,
  Header,
  LeftAuthCont,
  LeftItemsCont,
  LinkText,
  Logo,
  MediumGreenButton,
  MediumWhiteButton,
  PrivacyPolicy,
  RedirectDiv,
  RightAuthCont,
  RightBarDiv,
  RightItemsCont,
  TextInput300x50,
} from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { FlexRowCont100PercentFlexEnd } from "../../../StylesheetComps/Cont";
import { FlexColumnCont, SmallHeader } from "../../../StylesheetComps/Menu";
import { StyledHRPropColour } from "../../../StylesheetComps/Titles";
import { UserRole } from "../../../StylesheetComps/UserRoles";

const LoginPage = () => {
  const history = useHistory();

  const [loginForm, setLoginForm] = useState(true);
  const [twofactor, setTwoFactor] = useState(false);
  const [showMetroSelection, setShowMetroSelection] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const [selectedMetroId, setSelectedMetroId] = useState(0);
  const [selectedMetroName, setSelectedMetroName] = useState("");
  const [selectedCityId, setSelectedCityId] = useState(0);
  const [selectedCityName, setSelectedCityName] = useState("");

  const [userId, setUserId] = useState(0);
  const [username, setUsername] = useState(0);

  const [metroList, setMetroList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [flexHeight, setFlexHeight] = useState("60%");

  const [metroSelectionText, setShowMetroSelectionText] = useState(
    "Make sure you are in our service area by selecting one of the areas below."
  );

  const [forgotPasswordText, setForgotPasswordText] = useState(
    "Enter your username and send password. Check your email for a temporary password. Log in using the temporary password. Update your password on your profile page."
  );

  const [forgotPasswordButtonText, setForgotPasswordButtonText] =
    useState("Send Password");

  const [metroSelectionButtonDisplay, setShowMetroSelectionButtonDisplay] =
    useState("none");

  const [un, setUn] = useState("");
  const [pass, setPass] = useState("");
  const [enteredCode, setEnteredCode] = useState("");
  const [actualCode, setActualCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const GetVerifyCodeFunction = async (username, password) => {
    const resp = await axios
      .post(window.$DBURL + "users/verify", {
        Username: username,
        Password: password,
      })
      .catch(handleErrors);
    if (resp !== undefined) {
      if (resp.data !== null) {
        if (resp.data.code !== null) {
          setErrorMessage("");
          setActualCode(resp.data.code);
          setTwoFactor(true);
          setLoginForm(false);
          setShowMetroSelection(false);
          setForgotPassword(false);
        }
      }
    }
  };

  // const LoginFunction = async (enteredCode, actualCode, username, password) => {
  //   if (enteredCode.length !== 6) {
  //     setErrorMessage("Entered Code is not 6 characters");
  //   } else if (enteredCode === actualCode) {
  //     const resp = await axios.post(window.$DBURL + "users/session/login", {
  //       Username: username,
  //       Password: password,
  //     });
  //     if (resp.status === 200) {
  //       const token = resp.data.Session.AuthToken;
  //       axios.defaults.baseURL = window.$DBURL;
  //       sessionStorage.setItem("token", token);
  //       axios.defaults.headers.common["Authorization"] = "Basic " + token;
  //       if (resp.data !== null) {
  //         if (resp.data.User !== null) {
  //           if (resp.data.User.Metro_Id === 0 || resp.data.User.City_Id === 0) {
  //             sessionStorage.setItem(
  //               "userInfo",
  //               JSON.stringify(resp.data.User)
  //             );
  //             var metros = await GetMetroList();
  //             if (metros !== null) {
  //               setMetroList(metros);
  //               setUserId(resp.data.User.Id);
  //               setUsername(resp.data.User.Username);
  //               setShowMetroSelection(true);
  //               setTwoFactor(false);
  //               setLoginForm(false);
  //               setForgotPassword(false);
  //             }
  //           } else {
  //             sessionStorage.setItem(
  //               "userInfo",
  //               JSON.stringify(resp.data.User)
  //             );
  //             setEnteredCode("");
  //             var data = await GetUserCases(
  //               JSON.parse(sessionStorage.getItem("userInfo")).Id
  //             );

  //             if (data !== null) {
  //               if (data.Cases.length > 0 || window.$ISMOBILE === true) {
  //                 history.push("/home");
  //               } else {
  //                 history.push("/home");
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  const LoginFunction = async (enteredCode, actualCode, username, password) => {
    // Validate entered code
    if (!isValidCode(enteredCode, actualCode)) return;

    // Authenticate user
    const resp = await loginUser(username, password);
    if (!resp || resp.status !== 200) return;

    // Set session and global configurations
    const user = resp.data.User;
    configureSession(resp.data.Session.AuthToken, user);

    // Navigate based on user data
    await navigateUser(user);
};

const isValidCode = (enteredCode, actualCode) => {
    if (enteredCode.length !== 6) {
        setErrorMessage("Entered Code is not 6 characters");
        return false;
    }

    if (enteredCode !== actualCode) {
        setErrorMessage("Incorrect code entered");
        return false;
    }

    return true;
};

const loginUser = async (username, password) => {
    try {
        return await axios.post(window.$DBURL + "users/session/login", {
            Username: username,
            Password: password,
        });
    } catch (error) {
        // Handle the error (e.g. set error message)
        console.error('Login failed:', error);
        return null;
    }
};

const configureSession = (token, user) => {
    axios.defaults.baseURL = window.$DBURL;
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = "Basic " + token;

    if (user) {
        sessionStorage.setItem("userInfo", JSON.stringify(user));
    }
};

const navigateUser = async (user) => {
    if (!user) return;

    if (user.Metro_Id === 0 || user.City_Id === 0) {
        const metros = await GetMetroList();
        if (!metros) return;

        setMetroList(metros);
        setUserId(user.Id);
        setUsername(user.Username);
        setShowMetroSelection(true);
        setTwoFactor(false);
        setLoginForm(false);
        setForgotPassword(false);
    } else {
        const data = await GetUserCases(JSON.parse(sessionStorage.getItem("userInfo")).Id);

        // If the user has cases or if on mobile, navigate to home
        if (data !== null) {
            history.push("/home");
        }
    }
};

const Login = async (username, password) => {
  const resp = await attemptLogin(username, password);
  if (!resp || resp.status !== 200) return;

  setupSession(resp.data.Session.AuthToken);
  
  const user = resp.data.User;
  if (!user) return;

  sessionStorage.setItem("userInfo", JSON.stringify(user));

  await processUserData(user);
};

const attemptLogin = async (username, password) => {
  try {
      return await axios.post(`${window.$DBURL}users/session/login`, {
          Username: username,
          Password: password,
      });
  } catch (err) {
      processLoginErrors(err);
      return null;
  }
};

const setupSession = (token) => {
  axios.defaults.baseURL = window.$DBURL;
  sessionStorage.setItem("token", token);
  axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
};

const processUserData = async (user) => {
  if (user.Metro_Id === 0 || user.City_Id === 0) {
      const metros = await GetMetroList();
      if (!metros) return;

      setMetroList(metros);
      setUserId(user.Id);
      setUsername(user.Username);
      updateUIForMetroSelection();
  } else {
      setEnteredCode("");
      const data = await GetUserCases(user.Id);
      navigateAccordingToData(data);
  }
};

const updateUIForMetroSelection = () => {
  setShowMetroSelection(true);
  setTwoFactor(false);
  setLoginForm(false);
  setForgotPassword(false);
};

const navigateAccordingToData = (data) => {
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  if (userInfo && (userInfo.Role === UserRole.Manager || userInfo.Role === UserRole.Estimator)) {
    history.push(`/cases/${userInfo.Metro_Id}/${userInfo.Metro_Id}`);
  } else {
    history.push("/home");
  }
};

const processLoginErrors = (err) => {
  if (err.response || err.request) {
    setErrorMessage("Incorrect Username or Password!");
  }
};




  // const Login = async (username, password) => {
  //   const resp = await axios
  //     .post(window.$DBURL + "users/session/login", {
  //       Username: username,
  //       Password: password,
  //     })
  //     .catch(handleErrors);
  //   if (resp?.status === 200) {
  //     const token = resp.data.Session.AuthToken;
  //     axios.defaults.baseURL = window.$DBURL;
  //     sessionStorage.setItem("token", token);
  //     axios.defaults.headers.common["Authorization"] = "Basic " + token;
  //     if (resp.data !== null) {
  //       if (resp.data.User !== null) {
  //         sessionStorage.setItem("userInfo", JSON.stringify(resp.data.User));
  //         if (resp.data.User.Metro_Id === 0 || resp.data.User.City_Id === 0) {
  //           var metros = await GetMetroList();
  //           if (metros !== null) {
  //             setMetroList(metros);
  //             setUserId(resp.data.User.Id);
  //             setUsername(resp.data.User.Username);
  //             setShowMetroSelection(true);
  //             setLoginForm(false);
  //             setTwoFactor(false);
  //             setForgotPassword(false);
  //           }
  //         } else {
  //           setEnteredCode("");
  //           var data = await GetUserCases(
  //             JSON.parse(sessionStorage.getItem("userInfo")).Id
  //           );

  //           if (data !== null) {
  //             if (data.Cases.length > 0 || window.$ISMOBILE === true) {
  //               history.push("/home");
  //             } else {
  //               history.push("/home");
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   function handleErrors(err) {
  //     if (err.request) {
  //       setErrorMessage("Incorrect Username or Password!");
  //     } else if (err.response) {
  //       setErrorMessage("Incorrect Username or Password!");
  //     }
  //   }
  // };

  const GetCities = async (metroid) => {
    var cities = await GetCityList(metroid);
    if (cities !== null) {
      setMetroList([]);
      setCityList(cities);
    }
  };

  // const HandlePasswordReset = async (forgotPasswordEmail) => {
  //   const resp = await axios
  //     .post(window.$DBURL + "users/reset/" + forgotPasswordEmail)
  //     .catch(handleErrors);
  //   if (resp !== undefined) {
  //     setForgotPasswordText("We've sent a temporary password to your email");
  //     setForgotPasswordButtonText("Continue");
  //   }
  //   if (resp === undefined) {
  //     if (
  //       forgotPasswordText ===
  //       "Error Submitting Email, Please wait a few moments and try again."
  //     ) {
  //       setForgotPasswordText(
  //         "Still not working? Contact us via the Contact Link in the top right of your screen."
  //       );
  //     } else {
  //       setForgotPasswordText(
  //         "Error Submitting Email, Please wait a few moments and try again."
  //       );
  //     }
  //   }
  // };

  const HandlePasswordReset = async (forgotPasswordEmail) => {
    try {
        const resp = await axios.post(`${window.$DBURL}users/reset/${forgotPasswordEmail}`);

        if (resp) {
            setForgotPasswordText("We've sent a temporary password to your email");
            setForgotPasswordButtonText("Continue");
        }
    } catch (error) {
        processResetErrors();
    }
};

const processResetErrors = () => {
    const errorMessage = "Error Submitting Email, Please wait a few moments and try again.";
    
    if (forgotPasswordText === errorMessage) {
        setForgotPasswordText("Still not working? Contact us via the Contact Link in the top right of your screen.");
    } else {
        setForgotPasswordText(errorMessage);
    }
};


  const UpdateUserLocationInfo = async (
    metroId,
    metroName,
    cityId,
    cityName,
    username,
    userId
  ) => {
    const resp = await axios.put(window.$DBURL + "users", {
      Metro_Id: metroId,
      City_Id: cityId,
      Metro: metroName,
      City: cityName,
      Id: userId,
      Username: username,
    });
    if (resp.status === 200) {
      const resp = await axios.get(window.$DBURL + "users/profile", {});
      if (resp.status === 200) {
        if (resp.data !== null) {
          if (resp.data.Metro_Id !== 0) {
            sessionStorage.setItem("userInfo", JSON.stringify(resp.data.user));
            if (window.$ISMOBILE === true) {
              history.push("/home");
            } else {
              history.push("/home");
            }
          }
        }
      }
    }
  };

  const AddInstallerToStore = async(metroid)=>{
    var user = JSON.parse(sessionStorage.getItem("userInfo"));
    if(user.Role === UserRole.Installer)
    {
      var data = await GetStoreByMetro(metroid);
      if(data?.length>0)
      {
        await AddStaffToStore(data[0].Id); //Assume there is only one store per metro now.
      }
    }
  }

  function handleErrors(err) {
    if (err.request) {
      setErrorMessage("Incorrect Username or Password");
    } else if (err.response) {
      setErrorMessage("Incorrect Username or Password");
    }
  }

  if (window.$ISMOBILE === true) {
    return (
      <AuthCont>
        <LeftAuthCont backgroundimg="/Log In Image.png">
          <LeftItemsCont>
            <Logo src="NRCLogoRegularWhite.png"></Logo>
            <Header headercolor="white">Create Account</Header>
            <BodyPara fontsize="18px" bodycolor="white">
            Join the New Rhodes community and get started on your project today.
            </BodyPara>
            <MediumWhiteButton
              onClick={() => {
                history.push("/signup");
              }}
            >
              Sign Up
            </MediumWhiteButton>
          </LeftItemsCont>
        </LeftAuthCont>

        <RightAuthCont>
          <Div100vw20vh>
            <TopImage20vh src="/Log In Image.png"></TopImage20vh>
            <LogoRelative src="/NRCLogoRegularBlack.png"></LogoRelative>
          </Div100vw20vh>

          <br></br>
          {loginForm === true ? (
            <RightItemsCont>
              <Header marginbottom="1.25%">Welcome Back!</Header>
              <BodyPara bodycolor="black" width="50%" marginbottom="2.5%">
              Log in to your account here.
              </BodyPara>
              <TextInput300x50
                name="username"
                placeholder="Username"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setUn(e.target.value);
                }}
              ></TextInput300x50>
              <TextInput300x50
                name="pass"
                type="password"
                placeholder="Password"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              ></TextInput300x50>
              <RedirectDiv>
                <LinkText
                  fontsize="16px"
                  onClick={() => {
                    setForgotPassword(true);
                    setLoginForm(false);
                  }}
                >
                  Forgot password?
                </LinkText>
              </RedirectDiv>
              <SmallHeader margintop="3%" color="red">
                {errorMessage}
              </SmallHeader>
              <br></br>
              <MobileWideGreenButton
                onClick={() => {
                  Login(un, pass);
                }}
              >
                Log In
              </MobileWideGreenButton>
              <UnderlinedBoldTitle16px
                onClick={() => {
                  history.push("/signup");
                }}
              >
                Create Account
              </UnderlinedBoldTitle16px>
            </RightItemsCont>
          ) : twofactor === true ? (
            <RightItemsCont top="10%">
              <Header>One More Step.</Header>
              <BodyPara marginbottom="1%" bodycolor="black" width="50%">
                Enter the security code sent to your cell phone or email to sign
                into your account
              </BodyPara>
              <input type="password" style={{ display: "none" }} />
              <TextInput300x50
                key=""
                placeholder="Enter Code"
                autoComplete="new-password"
                onblur='this.type="password"'
                onfocus='this.type="text"'
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setEnteredCode(e.target.value);
                }}
              ></TextInput300x50>
              <RedirectDiv>
                <LinkText
                  fontsize="16px"
                  onClick={() => {
                    window.open(
                      "https://www.newrhodesconstruction.com/contact-us/",
                      "_blank"
                    );
                  }}
                >
                  Didn't receive a code?
                </LinkText>
              </RedirectDiv>
              <SmallHeader margintop="3%" color="red">
                {errorMessage}
              </SmallHeader>
              <br></br>
              <MobileWideGreenButton
                onClick={() => {
                  LoginFunction(enteredCode, actualCode, un, pass);
                }}
              >
                Enter
              </MobileWideGreenButton>
            </RightItemsCont>
          ) : showMetroSelection === true ? (
            <RightItemsCont>
              <Header>Set Your Location</Header>
              <BodyPara bodycolor="black" width="50%">
                {metroSelectionText}
              </BodyPara>
              {metroList.map((o, i) => (
                <div>
                  <LinkText
                    key={i}
                    onClick={() => {
                      setSelectedMetroId(o.Id);
                      setSelectedMetroName(o.Name);
                      GetCities(o.Id);
                    }}
                  >
                    {o.Name}
                  </LinkText>
                  <StyledHRPropColour
                    border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                  ></StyledHRPropColour>
                </div>
              ))}
              <FlexColumnCont
                height={flexHeight}
                margin="3%"
                justifycontent="flex-start"
                width="60%"
                mobilewidth="80vw"
                borderradius="10px"
                overflowy="auto"
              >
                {cityList.map((o, i) => (
                  <LinkText
                    key={i}
                    onClick={() => {
                      setSelectedCityId(o.Id);
                      setSelectedCityName(o.Name);
                      setCityList([]);
                      setShowMetroSelectionButtonDisplay("block");
                      setFlexHeight("0%");
                      setShowMetroSelectionText(
                        "Great, Now you have completed your profile."
                      );
                    }}
                  >
                    {o.Name}
                  </LinkText>
                ))}
              </FlexColumnCont>
              <MobileWideGreenButton
                display={metroSelectionButtonDisplay}
                onClick={() => {
                  UpdateUserLocationInfo(
                    selectedMetroId,
                    selectedMetroName,
                    selectedCityId,
                    selectedCityName,
                    username,
                    userId
                  );

                  AddInstallerToStore(selectedMetroId);
                }}
              >
                Continue
              </MobileWideGreenButton>
            </RightItemsCont>
          ) : forgotPassword === true ? (
            <RightItemsCont>
              <Header>Forgot your password?</Header>
              <BodyPara bodycolor="black" width="50%">
                {forgotPasswordText}
              </BodyPara>
              <TextInput300x50
                key=""
                autoComplete="new-password"
                placeholder="Username"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setForgotPasswordEmail(e.target.value);
                }}
              ></TextInput300x50>
              <br></br>
              <MobileWideGreenButton
                onClick={() => {
                  if (forgotPasswordButtonText === "Send Password") {
                    HandlePasswordReset(forgotPasswordEmail);
                  } else {
                    setLoginForm(true);
                    setForgotPassword(false);
                    setForgotPasswordText(
                      "No worries! Enter the username you used below to be sent a temporary password!"
                    );
                    setForgotPasswordButtonText("Send Password");
                  }
                }}
              >
                {forgotPasswordButtonText}
              </MobileWideGreenButton>
              <UnderlinedBoldTitle16px
                onClick={() => {
                  setForgotPassword(false);
                  setLoginForm(true);
                }}
              >
               Continue
              </UnderlinedBoldTitle16px>
            </RightItemsCont>
          ) : null}

          <FlexRowCont100PercentFlexEnd height="15%">
            <PrivacyPolicy>
              All rights reserved 2020 New Rhodes Construction Privacy Policy
            </PrivacyPolicy>
          </FlexRowCont100PercentFlexEnd>
        </RightAuthCont>
      </AuthCont>
    );
  } else {
    return (
      <AuthCont>
        <LeftAuthCont backgroundimg="/Log In Image.png">
          <LeftItemsCont>
            <Logo src="NRCLogoRegularWhite.png"></Logo>
            <Header headercolor="white">Create Account</Header>
            <BodyPara fontsize="18px" bodycolor="white">
            Join the New Rhodes community and get started on your project today.
            </BodyPara>
            <MediumWhiteButton
              onClick={() => {
                history.push("/signup");
              }}
            >
              Sign Up
            </MediumWhiteButton>
          </LeftItemsCont>
        </LeftAuthCont>

        <RightAuthCont>
          <RightBarDiv>
            <LinkText
              onClick={() => {
                window.open("https://www.newrhodesconstruction.com/", "_blank");
              }}
            >
              Home
            </LinkText>
            <LinkText
              onClick={() => {
                window.open("https://www.newrhodesconstruction.com/about/", "_blank");
              }}
            >
              About
            </LinkText>
            <LinkText
              onClick={() => {
                window.open(
                  "https://www.newrhodesconstruction.com/contact-us/",
                  "_blank"
                );
              }}
            >
              Contact
            </LinkText>
          </RightBarDiv>
          {/* Login Form */}
          {loginForm === true ? (
            <RightItemsCont>
              <Header marginbottom="1.25%">Welcome Back!</Header>
              <BodyPara bodycolor="black" width="50%" marginbottom="2.5%">
              Log in to your account here.
              </BodyPara>
              <TextInput300x50
                name="username"
                placeholder="Username"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setUn(e.target.value);
                }}
              ></TextInput300x50>
              <TextInput300x50
                name="pass"
                type="password"
                placeholder="Password"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              ></TextInput300x50>
              <RedirectDiv>
                <LinkText
                  fontsize="16px"
                  onClick={() => {
                    setLoginForm(false);
                    setForgotPassword(true);
                  }}
                >
                  Forgot Password?
                </LinkText>
              </RedirectDiv>
              <SmallHeader margintop="3%" color="red">
                {errorMessage}
              </SmallHeader>
              <MediumGreenButton
                onClick={() => {
                  Login(un, pass);
                }}
              >
                Log In
              </MediumGreenButton>
              <SmallHeader
                cursor="pointer"
                display="none"
                margintop="5%"
                textdecoration="underline"
                color="blue"
                onClick={() => {
                  history.push("/signup");
                }}
              >
                Don't have an account? Signup here
              </SmallHeader>
            </RightItemsCont>
          ) : //   Two Factor Display
          twofactor === true ? (
            <RightItemsCont top="10%">
              <Header>One More Step.</Header>
              <BodyPara marginbottom="1%" bodycolor="black" width="50%">
                Enter the security code sent to your cell phone or email to sign
                into your account
              </BodyPara>
              <input type="password" style={{ display: "none" }} />
              <TextInput300x50
                key=""
                placeholder="Enter Code"
                autoComplete="new-password"
                onblur='this.type="password"'
                onfocus='this.type="text"'
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setEnteredCode(e.target.value);
                }}
              ></TextInput300x50>
              <RedirectDiv>
                <LinkText
                  fontsize="16px"
                  onClick={() => {
                    window.open(
                      "https://www.newrhodesconstruction.com/contact-us/",
                      "_blank"
                    );
                  }}
                >
                  Didn't receive a code?
                </LinkText>
              </RedirectDiv>
              <SmallHeader margintop="3%" color="red">
                {errorMessage}
              </SmallHeader>
              <MediumGreenButton
                onClick={() => {
                  LoginFunction(enteredCode, actualCode, un, pass);
                }}
              >
                Enter
              </MediumGreenButton>
            </RightItemsCont>
          ) : showMetroSelection === true ? (
            <RightItemsCont>
              <Header>Set Your Location.</Header>
              <BodyPara bodycolor="black" width="50%">
                {metroSelectionText}
              </BodyPara>
              {metroList.map((o, i) => (
                <div>
                  <LinkText
                    key={i}
                    onClick={() => {
                      setSelectedMetroId(o.Id);
                      setSelectedMetroName(o.Name);
                      GetCities(o.Id);
                    }}
                  >
                    {o.Name}
                  </LinkText>
                  <StyledHRPropColour
                    border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                  ></StyledHRPropColour>
                </div>
              ))}
              <FlexColumnCont
                height={flexHeight}
                margin="3%"
                justifycontent="flex-start"
                width="60%"
                borderradius="10px"
                overflowy="auto"
              >
                {cityList.map((o, i) => (
                  <LinkText
                    key={i}
                    onClick={() => {
                      setSelectedCityId(o.Id);
                      setSelectedCityName(o.Name);
                      setCityList([]);
                      setShowMetroSelectionButtonDisplay("block");
                      setFlexHeight("0%");
                      setShowMetroSelectionText(
                        "Great, Now you have completed your profile."
                      );
                    }}
                  >
                    {o.Name}
                  </LinkText>
                ))}
              </FlexColumnCont>
              <MediumGreenButton
                display={metroSelectionButtonDisplay}
                onClick={() => {
                  UpdateUserLocationInfo(
                    selectedMetroId,
                    selectedMetroName,
                    selectedCityId,
                    selectedCityName,
                    username,
                    userId
                  );

                  AddInstallerToStore(selectedMetroId);
                }}
              >
                Continue
              </MediumGreenButton>
            </RightItemsCont>
          ) : forgotPassword ? (
            <RightItemsCont>
              <Header>Forgot your password?</Header>
              <BodyPara bodycolor="black" width="50%">
                {forgotPasswordText}
              </BodyPara>
              <br></br>
              <TextInput300x50
                key=""
                autoComplete="new-password"
                placeholder="Username"
                inputwidth="40%"
                inputheight="2.5vh"
                onChange={(e) => {
                  setForgotPasswordEmail(e.target.value);
                }}
              ></TextInput300x50>
              <br></br>
              <MediumGreenButton
                mobilewidth="76vw"
                width="40%"
                onClick={() => {
                  if (forgotPasswordButtonText === "Send Password") {
                    HandlePasswordReset(forgotPasswordEmail);
                  } else {
                    setLoginForm(true);
                    setForgotPassword(false);
                    setForgotPasswordText(
                      "No worries! Enter the username you used below to be sent a temporary password!"
                    );
                    setForgotPasswordButtonText("Send Password");
                  }
                }}
              >
                {forgotPasswordButtonText}
              </MediumGreenButton>
            </RightItemsCont>
          ) : null}

          <FlexRowCont100PercentFlexEnd height="15%">
            <PrivacyPolicy>
              All rights reserved 2020 New Rhodes Construction Privacy Policy
            </PrivacyPolicy>
          </FlexRowCont100PercentFlexEnd>
        </RightAuthCont>
      </AuthCont>
    );
  }
};

LoginPage.defaultProps = {
  h1: "This is my default text",
};

export default LoginPage;
