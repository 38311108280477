import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { DeleteCasePhoto } from "../../../API_Functions/CaseQuote_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import {
  BodyPara,
  Header,
  TextInput300x50,
} from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import {
  FlexColumnFullWidth,
  FlexRowCont100x8PercentSpaceAround,
  FlexRowCont55x15Percent,
  FlexRowCont55x15PercentPropBorderBottom,
} from "../../../StylesheetComps/Cont";
import { InlineGridContainer } from "../../../StylesheetComps/Div";
import { Icon45x45 } from "../../../StylesheetComps/Imgs/Icons";
import { CenteredGridImage } from "../../../StylesheetComps/Imgs/Images";
import {
  EstimatorDiv,
  GreenNavigationButton,
  GreyNavigationButton,
  ProgressBar,
  ProgressBarWidth,
  Section1,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, StyledHR } from "../../../StylesheetComps/Menu";
import {
  Icon30px20pxPointer,
  Icon30px30pxPointer,
} from "../../../StylesheetComps/Quote";
import { RegularTextBoldRed } from "../../../StylesheetComps/Titles";
import CasePhotoUploadPreviewWindow from "../../Windows/PhotoUpload";

const PhotoUploadComp = ({
  open,
  onNext,
  onBack,
  onPhotoUpload,
  onPreview,
  caseObj,
}) => {
  const [streetView, setStreetView] = useState("");
  const [accessTo, setAccessTo] = useState("");
  const [panorama, setPanorama] = useState("");
  const [areaOfWork, setAreaOfWork] = useState("");
  const [extraPhoto, setExtraPhoto] = useState("");

  const [streetViewId, setStreetViewId] = useState(0);
  const [accessToId, setAccessToId] = useState(0);
  const [panoramaId, setPanoramaId] = useState(0);
  const [areaOfWorkId, setAreaOfWorkId] = useState(0);

  const [previewImage, setPreviewImage] = useState("");
  const [previewImageWindowDisplay, setPreviewImageWindowDisplay] =
    useState(false);

  const PhotoPreview =
    "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
  const [streetViewPreview, setStreetViewPreview] = useState(PhotoPreview);
  const [accessAreaPreview, setAccessAreaPreview] = useState(PhotoPreview);
  const [panoramaPreview, setPanoramaPreview] = useState(PhotoPreview);
  const [workAreaPreview, setWorkAreaPreview] = useState(PhotoPreview);

  const PhotoAddIcon = "/Green Button.svg";
  const [streetViewButton, setStreetViewButton] = useState(PhotoAddIcon);
  const [accessAreaButton, setAccessAreaButton] = useState(PhotoAddIcon);
  const [panoramaButton, setPanoramaButton] = useState(PhotoAddIcon);
  const [workAreaButton, setWorkAreaButton] = useState(PhotoAddIcon);

  const [errorText, setErrorText] = useState("");
  const [photoType, setPhotoType] = useState("");

  const [imgArray, setImgArray] = useState([]);
  var ImageId = 0;
  const inputFileExtra = useRef(null);

  const [PDFMap] = useState([]);
  const [videoMap] = useState([]);

  const onInputClick = async (event) => {
    event.target.value = "";
  };

  const DeleteImgFromCloud_AndLocal = async (o) => {
    DeleteImg(o);
    imgArray.splice(ImageId, 1);
    setImgArray([...imgArray]);
  };

  const CheckIfPhotosPresent = async () => {
    onPreview({
      streetView,
      accessTo,
      panorama,
      areaOfWork,
      imgArray,
      PDFMap,
    });
    onPhotoUpload(streetView, accessTo, panorama, areaOfWork, imgArray, PDFMap);
    onNext();
  };

  const UploadPhoto = async (file) => {
    if (file !== undefined) {
      if (
        file.name.includes(".jpg") ||
        file.name.includes(".JPG") ||
        file.name.includes(".jpeg") ||
        file.name.includes(".JPEG") ||
        file.name.includes(".png") ||
        file.name.includes(".PNG") ||
        file.name.includes(".heic") ||
        file.name.includes(".HEIC") ||
        file.name.includes(".heif") ||
        file.name.includes(".HEIF") ||
        file.name.includes(".webp") ||
        file.name.includes(".WEBP") ||
        file.name.includes(".jfif") ||
        file.name.includes(".JFIF") ||
        file.name.includes(".pjpeg") ||
        file.name.includes(".PJPEG") ||
        file.name.includes(".pjp") ||
        file.name.includes(".PJP") ||
        file.name.includes(".pdf") ||
        file.name.includes(".PDF")
      ) {
        var pic = await UploadFileToCloud(file);
        return pic;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  //Upload functions for individual inputs

  async function UploadStreetViewImage(event) {
    var file = event.target.files[0];
    setStreetViewPreview("/Spinner.svg");
    var pic = await UploadPhoto(file);
    if (pic !== null) {
      setErrorText("");
      setPreviewImage(pic);
      setPhotoType("streetview");
      setPreviewImageWindowDisplay(true);
      setStreetView(pic);
      setStreetViewPreview(window.$IMGURL + pic);
      setStreetViewButton("/Check Mark.svg");
    } else {
      setStreetViewPreview(PhotoPreview);
      setErrorText(
        "File not supported, we are also not currently accepting videos."
      );
      window.scrollTo(0, 0);
    }
  }

  async function UploadAccessAreaImage(event) {
    var file = event.target.files[0];
    setAccessAreaPreview("/Spinner.svg");
    var pic = await UploadPhoto(file);
    if (pic !== null) {
      setErrorText("");
      setPreviewImage(pic);
      setPhotoType("accessarea");
      setPreviewImageWindowDisplay(true);
      setAccessTo(pic);
      setAccessAreaPreview(window.$IMGURL + pic);
      setAccessAreaButton("/Check Mark.svg");
    } else {
      setAccessAreaPreview(PhotoPreview);
      setErrorText(
        "File not supported, we are also not currently accepting videos."
      );
      window.scrollTo(0, 0);
    }
  }

  async function UploadPanormaImage(event) {
    var file = event.target.files[0];
    setPanoramaPreview("/Spinner.svg");
    var pic = await UploadPhoto(file);
    if (pic !== null) {
      setErrorText("");
      setPreviewImage(pic);
      setPhotoType("panorama");
      setPreviewImageWindowDisplay(true);
      setPanorama(pic);
      setPanoramaPreview(window.$IMGURL + pic);
      setPanoramaButton("/Check Mark.svg");
    } else {
      setPanoramaPreview(PhotoPreview);
      setErrorText(
        "File not supported, we are also not currently accepting videos."
      );
      window.scrollTo(0, 0);
    }
  }

  async function UploadWorkAreaImage(event) {
    var file = event.target.files[0];
    setWorkAreaPreview("/Spinner.svg");
    var pic = await UploadPhoto(file);
    if (pic !== null) {
      setErrorText("");
      setPreviewImage(pic);
      setPhotoType("areaofwork");
      setPreviewImageWindowDisplay(true);
      setAreaOfWork(pic);
      setWorkAreaPreview(window.$IMGURL + pic);
      setWorkAreaButton("/Check Mark.svg");
    } else {
      setWorkAreaPreview(PhotoPreview);
      setErrorText(
        "File not supported, we are also not currently accepting videos."
      );
      window.scrollTo(0, 0);
    }
  }

  async function UploadExtraPhotos(event) {
    let file = event.target.files[0];
    setPhoto(file);
    setPhotoType("extra");
  }

  const inputFile = useRef(null);
  const inputFile2 = useRef(null);
  const inputFile3 = useRef(null);
  const inputFile4 = useRef(null);

  const DeleteImg = async (img) => {
    var resp = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (resp.status === 200) {
    }
  };

  const onStreetViewButtonClick = (event) => {
    if (streetViewButton === PhotoAddIcon) {
      inputFile.current.click();
    } else {
      DeleteImg(streetView);
      DeleteCasePhoto(streetViewId);
      setStreetViewPreview(PhotoPreview);
      setStreetView("");
      setStreetViewButton(PhotoAddIcon);
    }
  };

  const onAccessAreaButtonClick = (event) => {
    if (accessAreaButton === PhotoAddIcon) {
      inputFile2.current.click();
    } else {
      DeleteImg(accessTo);
      DeleteCasePhoto(accessToId);
      setAccessAreaPreview(PhotoPreview);
      setAccessTo("");
      setAccessAreaButton(PhotoAddIcon);
    }
  };

  const onPanoramaButtonClick = (event) => {
    if (panoramaButton === PhotoAddIcon) {
      inputFile3.current.click();
    } else {
      DeleteImg(panorama);
      DeleteCasePhoto(panoramaId);
      setPanoramaPreview(PhotoPreview);
      setPanorama("");
      setPanoramaButton(PhotoAddIcon);
    }
  };

  const onWorkAreaButtonClick = (event) => {
    if (workAreaButton === PhotoAddIcon) {
      inputFile4.current.click();
    } else {
      DeleteImg(areaOfWork);
      DeleteCasePhoto(areaOfWorkId);
      setWorkAreaPreview(PhotoPreview);
      setAreaOfWork("");
      setWorkAreaButton(PhotoAddIcon);
    }
  };

  const [photo, setPhoto] = useState("");

  const onButtonClickExtra = () => {
    inputFileExtra.current.click();
  };

  const GetImgUrl_PushToLocal = async () => {
    if (photo !== "") {
      var pic = await UploadPhoto(photo);
      if (pic !== null) {
        setPreviewImage(pic);
        setPreviewImageWindowDisplay(true);
        setExtraPhoto(pic);
        pullArrayAgain();
      } else {
        setErrorText(
          "File not supported, we are also not currently accepting videos."
        );
        window.scrollTo(0, 0);
      }
    }
  };

  useEffect(() => {
    GetImgUrl_PushToLocal();
  }, [photo]); // eslint-disable-line react-hooks/exhaustive-deps

  const pullArrayAgain = () => {
    setImgArray([...imgArray]);
  };

  const ResetImage = async (photo) => {
    setPreviewImageWindowDisplay(false);
    switch (photo) {
      case "streetview":
        DeleteImg(streetView);
        setStreetViewPreview(PhotoPreview);
        setStreetView("");
        setStreetViewButton(PhotoAddIcon);
        break;

      case "accessarea":
        DeleteImg(accessTo);
        setAccessAreaPreview(PhotoPreview);
        setAccessTo("");
        setAccessAreaButton(PhotoAddIcon);
        break;

      case "panorama":
        DeleteImg(panorama);
        setPanoramaPreview(PhotoPreview);
        setPanorama("");
        setPanoramaButton(PhotoAddIcon);
        break;

      case "areaofwork":
        DeleteImg(areaOfWork);
        setWorkAreaPreview(PhotoPreview);
        setAreaOfWork("");
        setWorkAreaButton(PhotoAddIcon);
        break;

      case "extra":
        DeleteImg(previewImage);
        break;
      default:
        break;
    }
  };

  const AssignIdToImage = async (id, image) => {
    switch (image) {
      case "streetview":
        setStreetViewId(id);
        break;

      case "accessarea":
        setAccessToId(id);
        break;

      case "panorama":
        setPanoramaId(id);
        break;

      case "areaofwork":
        setAreaOfWorkId(id);
        break;

      case "extra":
        if (extraPhoto.includes("pdf") || extraPhoto.includes("PDF")) {
          PDFMap.push({
            File: extraPhoto,
            Id: id,
          });
        } else {
          imgArray.push({
            File: extraPhoto,
            Id: id,
          });
        }
        break;

      default:
        break;
    }
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <CasePhotoUploadPreviewWindow
          imgtype={photoType}
          caseObj={caseObj}
          AssignIdToImage={(id) => {
            AssignIdToImage(id, photoType);
          }}
          open={previewImageWindowDisplay}
          previewsrc={previewImage}
          CancelUpload={() => {
            ResetImage(photoType);
          }}
          CloseWindow={() => {
            setPreviewImageWindowDisplay(false);
          }}
        ></CasePhotoUploadPreviewWindow>

        <ProgressBar>
          <ProgressBarWidth width="93.75%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Upload Photos
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="0%"
          fontsize="16px"
        >
          If you have photos of your outdoor space for us to better understand
          your project's requirements. We work best with photos of the following
          areas.
        </BodyPara>
        <RegularTextBoldRed>{errorText}</RegularTextBoldRed>

        {/* STREET VIEW OF PROPERTY */}

        <FlexColumnCont
          margin="0"
          mobilewidth="90vw"
          width="55%"
          height="15%"
          flexdirection="row"
          justifycontent="flex-start"
        >
          <Icon45x45
            src={streetViewButton}
            onClick={() => {
              onStreetViewButtonClick();
            }}
          ></Icon45x45>
          <FlexRowCont55x15PercentPropBorderBottom
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <SmallHeaderLeagueSpartan mobilewidth="70vw" width="auto">
              Street View of Property*
            </SmallHeaderLeagueSpartan>
            <StyledHR
              mobiledisplay="none"
              height="50%"
              width="0px"
              border={"1px solid " + ColorPalette.PrimaryGrey}
            ></StyledHR>
            <TextInput300x50
              mobiledisplay="none"
              inputwidth="40%"
              inputheight="20%"
              ref={inputFile}
              type="file"
              onClick={onInputClick}
              onChange={UploadStreetViewImage}
            />
            <Icon30px20pxPointer
              mobilewidth="5vw"
              width="4%"
              onClick={() => {
                onStreetViewButtonClick();
              }}
              src="/Close.svg"
            ></Icon30px20pxPointer>
          </FlexRowCont55x15PercentPropBorderBottom>
        </FlexColumnCont>

        {/* ACCESS TO WORK AREA */}

        <FlexRowCont55x15Percent>
          <Icon45x45
            src={accessAreaButton}
            onClick={onAccessAreaButtonClick}
          ></Icon45x45>
          <FlexRowCont55x15PercentPropBorderBottom
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <SmallHeaderLeagueSpartan mobilewidth="70vw" width="auto">
              Access to Work Area*
            </SmallHeaderLeagueSpartan>
            <StyledHR
              mobiledisplay="none"
              height="50%"
              marginleft="10%"
              width="0px"
              border={"1px solid " + ColorPalette.PrimaryGrey}
            ></StyledHR>
            <TextInput300x50
              mobiledisplay="none"
              inputwidth="40%"
              inputheight="20%"
              ref={inputFile2}
              type="file"
              onClick={onInputClick}
              onChange={UploadAccessAreaImage}
            />
            <Icon30px20pxPointer
              mobilewidth="5vw"
              width="4%"
              onClick={onAccessAreaButtonClick}
              src="/Close.svg"
            ></Icon30px20pxPointer>
          </FlexRowCont55x15PercentPropBorderBottom>
        </FlexRowCont55x15Percent>

        {/* PANORAMA OF YARD */}

        <FlexRowCont55x15Percent>
          <Icon45x45
            src={panoramaButton}
            onClick={onPanoramaButtonClick}
          ></Icon45x45>
          <FlexRowCont55x15PercentPropBorderBottom
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <SmallHeaderLeagueSpartan mobilewidth="70vw" width="auto">
              Panorama of Yard*
            </SmallHeaderLeagueSpartan>
            <StyledHR
              mobiledisplay="none"
              height="50%"
              marginleft="13%"
              width="0px"
              border={"1px solid " + ColorPalette.PrimaryGrey}
            ></StyledHR>
            <TextInput300x50
              mobiledisplay="none"
              inputwidth="40%"
              inputheight="20%"
              ref={inputFile3}
              type="file"
              onClick={onInputClick}
              onChange={UploadPanormaImage}
            />
            <Icon30px20pxPointer
              mobilewidth="5vw"
              width="4%"
              onClick={onPanoramaButtonClick}
              src="/Close.svg"
            ></Icon30px20pxPointer>
          </FlexRowCont55x15PercentPropBorderBottom>
        </FlexRowCont55x15Percent>

        {/* AREA OF WORK */}

        <FlexRowCont55x15Percent>
          <Icon45x45
            src={workAreaButton}
            onClick={onWorkAreaButtonClick}
          ></Icon45x45>
          <FlexRowCont55x15PercentPropBorderBottom
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <SmallHeaderLeagueSpartan mobilewidth="70vw" width="auto">
              Area of Work*
            </SmallHeaderLeagueSpartan>
            <StyledHR
              mobiledisplay="none"
              height="50%"
              marginleft="20%"
              width="0px"
              border={"1px solid " + ColorPalette.PrimaryGrey}
            ></StyledHR>
            <TextInput300x50
              mobiledisplay="none"
              inputwidth="40%"
              inputheight="20%"
              ref={inputFile4}
              type="file"
              onClick={onInputClick}
              onChange={UploadWorkAreaImage}
            />
            <Icon30px20pxPointer
              mobilewidth="5vw"
              width="4%"
              onClick={onWorkAreaButtonClick}
              src="/Close.svg"
            ></Icon30px20pxPointer>
          </FlexRowCont55x15PercentPropBorderBottom>
        </FlexRowCont55x15Percent>

        {/* EXTRA PHOTOS */}

        <FlexRowCont55x15Percent>
          <Icon45x45
            src={PhotoAddIcon}
            onClick={onButtonClickExtra}
          ></Icon45x45>
          <FlexRowCont55x15PercentPropBorderBottom
            borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
          >
            <SmallHeaderLeagueSpartan mobilewidth="70vw" width="auto">
              Extra Photos
            </SmallHeaderLeagueSpartan>
            <StyledHR
              mobiledisplay="none"
              height="50%"
              marginleft="22%"
              width="0px"
              border={"1px solid " + ColorPalette.PrimaryGrey}
            ></StyledHR>
            <TextInput300x50
              mobiledisplay="none"
              inputwidth="40%"
              inputheight="20%"
              ref={inputFileExtra}
              type="file"
              onChange={UploadExtraPhotos}
            />
            <Icon30px20pxPointer
              mobilewidth="5vw"
              width="4%"
              src="/Close.svg"
            ></Icon30px20pxPointer>
          </FlexRowCont55x15PercentPropBorderBottom>
        </FlexRowCont55x15Percent>

        <FlexColumnFullWidth width="6vw" alignitems="center">
          <InlineGridContainer>
            <CenteredGridImage
              display={streetViewPreview !== PhotoPreview ? "flex" : "none"}
              src={streetViewPreview}
            ></CenteredGridImage>
            <CenteredGridImage
              display={accessAreaPreview !== PhotoPreview ? "flex" : "none"}
              src={accessAreaPreview}
            ></CenteredGridImage>
            <CenteredGridImage
              display={panoramaPreview !== PhotoPreview ? "flex" : "none"}
              src={panoramaPreview}
            ></CenteredGridImage>
            <CenteredGridImage
              display={workAreaPreview !== PhotoPreview ? "flex" : "none"}
              src={workAreaPreview}
            ></CenteredGridImage>
            {imgArray.map((o, i) => (
              <InlineGridContainer key={i}>
                <CenteredGridImage
                  src={window.$IMGURL + o.File}
                  alt={"Cannot Display Image"}
                ></CenteredGridImage>
                <Icon30px30pxPointer
                  src="/Close.svg"
                  onClick={() => {
                    ImageId = i;
                    DeleteImgFromCloud_AndLocal(o.File);
                    DeleteCasePhoto(o.Id);
                  }}
                ></Icon30px30pxPointer>
              </InlineGridContainer>
            ))}
            {videoMap.map((o, i) => (
              <video width="320" height="240" controls>
                <source src={window.$IMGURL + o} />
                Your browser does not support the video tag.
              </video>
            ))}
            {PDFMap.map((o, i) => (
              <InlineGridContainer>
                <a
                  rel="noreferrer"
                  href={window.$IMGURL + o.File}
                  target="_blank"
                >
                  <CenteredGridImage
                    objfit="contain"
                    src={"/pdficon.png"}
                    alt={"File:" + o.File}
                  ></CenteredGridImage>
                </a>
              </InlineGridContainer>
            ))}
          </InlineGridContainer>
        </FlexColumnFullWidth>
        <FlexRowCont100x8PercentSpaceAround>
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            bgcolor={ColorPalette.PrimaryButtonBlue}
            onClick={() => {
              CheckIfPhotosPresent();
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexRowCont100x8PercentSpaceAround>
      </Section1>
    </EstimatorDiv>
  );
};

PhotoUploadComp.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onPhotoUpload: () => {},
};

export default PhotoUploadComp;
