import React, { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont400x500px,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { LeagueSpartanExtraLargeWidth200px } from "../../../StylesheetComps/Titles";

const EditTaxWindow = ({
  open,
  onClose,
  onChange,
  onFormComplete,
  onPreview,
  Name,
  Rate,
}) => {
  const [taxName, setTaxName] = useState("");
  const [taxPercent, setTaxPercent] = useState(0);

  if (!open) return null;
  return (
    <>
      {" "}
      <Cont400x500px>
        <LeftItemWindowFixed>
          <LeagueSpartanExtraLargeWidth200px>
            Tax Info
          </LeagueSpartanExtraLargeWidth200px>
          <TextInput72Percent
            placeholder="Tax Name"
            defaultValue={Name}
            onChange={(e) => {
              setTaxName(e.target.value);
            }}
          ></TextInput72Percent>

          <TextInput72Percent
            placeholder="Tax Rate"
            defaultValue={Rate}
            onChange={(e) => {
              setTaxPercent(e.target.value);
            }}
          ></TextInput72Percent>

          <RegularResponsiveButton
            onClick={() => {
              onPreview({ taxName, taxPercent });
              onFormComplete(taxName, taxPercent);
              onClose();
            }}
          >
            Update
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </LeftItemWindowFixed>
      </Cont400x500px>{" "}
    </>
  );
};

EditTaxWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  Name: "",
  Rate: 0,
};

export default EditTaxWindow;
