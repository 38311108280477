import React from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont500x200pxFixedNoMobile,
  FlexColumnContRedBorderTopRounded,
  FlexContCenterAlign,
} from "../../../StylesheetComps/Cont";
import { ExtraLargeText } from "../../../StylesheetComps/Titles";

const DeleteCityWindow = ({ deleteOpen, closeDel, onDeleteFunction }) => {
  if (!deleteOpen) return null;
  return (
    <Cont500x200pxFixedNoMobile>
      <FlexColumnContRedBorderTopRounded>
        <ExtraLargeText>Are you sure you want to delete this?</ExtraLargeText>
        <FlexContCenterAlign>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={closeDel}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              closeDel();
              onDeleteFunction();
            }}
          >
            Delete
          </RegularResponsiveButton>
        </FlexContCenterAlign>
      </FlexColumnContRedBorderTopRounded>
    </Cont500x200pxFixedNoMobile>
  );
};

DeleteCityWindow.defaultProps = {
  onDeleteFunction: () => {},
};

export default DeleteCityWindow;
