import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import StoreListCalendarCard from "../../comps/StoreListCalendarCard";
import {
  Div70PerWidth7PerHeight,
  FlexCont100vw100vh,
  FlexCont80PercentMobileOpt,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const StoreListCalendarScreen = () => {
  //=============================================================================
  // Store Calendar List - If there is more than 1 store in a Metro, User must
  // select the store they want before being redirected to that Store Calendar
  //=============================================================================

  const params = useParams();

  const [storeList, getStoreList] = useState([]);

  const GetStoreList = async (metro_id) => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + metro_id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          getStoreList(resp.data.stores);
        }
      }
    }
  };

  useEffect(() => {
    GetStoreList(params.Id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <Div70PerWidth7PerHeight>
            <LeagueSpartanExtraLargeBold>
              View Calendar by Store
            </LeagueSpartanExtraLargeBold>
          </Div70PerWidth7PerHeight>
          <FlexCont80PercentMobileOpt>
            {storeList.map((o, i) => (
              <StoreListCalendarCard
                key={i}
                CatHeadText={o.Name}
                CatParaText={o.Address}
                catId={o.Id}
              ></StoreListCalendarCard>
            ))}
          </FlexCont80PercentMobileOpt>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

StoreListCalendarScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default StoreListCalendarScreen;
