import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AddQuoteComment,
  CaseStatus,
  DeleteQuotePhoto,
  DeleteQuoteTag,
  GetCaseById,
  GetQuoteById,
  GetQuoteTag,
  SetCaseTo_Processed,
  UpdateQuote,
} from "../../API_Functions/CaseQuote_Functions";
import {
  AddImageToCloud,
  GetCurrentTime,
  UploadFileToCloud,
} from "../../API_Functions/Utility_Functions";
import QuoteGrandTotal from "../../comps/QuoteGrandTotal";
import QuoteTable from "../../comps/QuoteTable";
import QuoteTableDelivery from "../../comps/QuoteTableDelivery";
import QuoteTableTools from "../../comps/QuoteTableTools";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import NewQuoteTool from "../../comps/Windows/NewQuoteTool";
import QuotePhotoUploadPreviewWindow from "../../comps/Windows/QuotePhotoUpload";
import TagWindow from "../../comps/Windows/Tag";
import ToolSelectionCard from "../../comps/Windows/ToolSelectionCard";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FixedIconToTop,
  RegularResponsiveButton,
} from "../../StylesheetComps/Components";
import {
  FlexColumnCont80x100Percent,
  FlexColumnFullWidth,
  FlexColumnFullWidthMargins,
  FlexColumnOverflowCont800px,
  FlexContFullWidthJustifyCenter,
  FlexDiv,
  FlexRowCont100PercentFlexEnd,
  FlexRowCont70PercentSpaceBetween,
  FlexRowContAutox100Percent,
  FlexRowContFlexStart,
  FlexRowContFlexStartAutoHeight,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import {
  FlexCenterDiv100PercentCenter,
  FlexCenterDiv100PercentSpaceBetween,
  FlexContainerItem,
  FlexDiv90PercentMargins,
  FlexDivCenter,
  FlexDivLeftAlign,
  FullWidthDivPropDisplay,
  SummaryContainer,
  SummaryContainerItems,
} from "../../StylesheetComps/Div";
import {
  Icon30x30,
  RelativeIconBottom70Percent,
} from "../../StylesheetComps/Imgs/Icons";
import {
  Image80x100px,
  OverrideEmbedDiv,
  OverrideEmbedImage,
} from "../../StylesheetComps/Imgs/Images";
import { TextInputSmall } from "../../StylesheetComps/Inputs";
import {
  FlexDivFlexStartCenter,
  Image300x200,
  LandscapePara,
  SmallHeaderLeagueSpartan,
  TextAreaAutoResize,
  TextInputRegular,
  TextInputReusable,
} from "../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../StylesheetComps/Menu";
import { FlexOverlayDivFullWidth } from "../../StylesheetComps/OverlayCont";
import {
  Cont100PercentWidth,
  HundredPercentFlexDiv,
  Icon30px30pxPointer,
} from "../../StylesheetComps/Quote";
import {
  BlankTextArea,
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  LeagueSpartanTinyMargins,
  MediumHeader,
  MediumHeaderDarkGrey,
  RegularText,
  RegularTextWithMargins,
  StyledHRGreenBreak,
  StyledHRGreyBreak,
  StyledHRPropColour,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import {
  ContentCont44Percent,
  ContentCont77vw,
} from "../../StylesheetComps/Projects";
import { Stored_Values } from "../../Stored_Values";
import { GetTaxesForMetro } from "../../API_Functions/Store_Functions";
import {
  MediumGreenButton,
  TextInput300x50,
} from "../../StylesheetComps/AuthSheet";
import ProposalCommentCard from "../../comps/ProposalComment";
const ViewQuote = ({
  open,
  onToolAdded,
  ReturnToMaterial,
  ReturnToLabor,
  ReturnToRemoval,
  ReturnToAccess,
  saveQuoteState,
}) => {
  //=============================================================================
  // View Quote - Quote Tables are organized here
  //=============================================================================

  let formdata = new FormData();

  var ToolQuant = 0;
  var RentalL = 0;

  const [quoteObj, setQuoteObj] = useState({});

  const downarrow = "/downarrow.svg";
  const uparrow = "/uparrow.svg";

  const [displaySettings, setDisplaySettings] = useState({
    removal: { display: "flex", arrow: uparrow },
    material: { display: "flex", arrow: uparrow },
    delivery: { display: "flex", arrow: uparrow },
    labor: { display: "flex", arrow: uparrow },
    tool: { display: "flex", arrow: uparrow },
    access: { display: "flex", arrow: uparrow },
  });

  const [supplyTotal, setSupplyTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [installTotal, setInstallTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [projectSubtotal, setProjectSubtotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [projectGrandTotal, setProjectGrandTotal] = useState(0);
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [accessTotal, setAccessTotal] = useState(0);
  const [estimateGST, setEstimateGST] = useState(0);
  const [estimatePST, setEstimatePST] = useState(0);
  const [photo, setPhoto] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewImageWindowDisplay, setPreviewImageWindowDisplay] =
    useState(false);
  const inputFile = useRef(null);
  let [removalsHeader8Subtotal, setRemovalsHeader8Subtotal] = useState(0);

  const [toolOpen, setToolOpen] = useState(false);
  const [toolCardMap, setToolCardMap] = useState(false);
  const [storeTaxes, setStoreTaxes] = useState(false);

  const params = useParams();
  const history = useHistory();

  const [toolID, settoolID] = useState(0);

  const [tagObj, setTagObj] = useState(null);
  const [curTime, setCurTime] = useState("");

  const [deleteTagId, setDeleteTagId] = useState(0);
  const [deleteTagConfirmDisplay, setDeleteTagConfirmDisplay] = useState(false);
  const [tagWindowDisplay, setTagWindowDisplay] = useState(false);
  const [projectInstallSubtotal, setProjectInstallSubtotal] = useState(0);

  const [loadingStates, setLoadingStates] = useState({});

  //Internal Comments
  const [submittingLoadingIcon, setSubmittingLoadingIcon] = useState(false);
  const [sendButtonDisplay, setSendButtonDisplay] = useState("block");
  const [commentText, setCommentText] = useState("");

  const ToggleSection = (section) => {
    setDisplaySettings((prevState) => ({
      ...prevState,
      [section]: {
        display: prevState[section].display === "flex" ? "none" : "flex",
        arrow: prevState[section].display === "flex" ? downarrow : uparrow,
      },
    }));
  };

  const GetQuoteInfoById = async (quoteid) => {
    try {
      const quoteData = await GetQuoteById(quoteid);

      if (quoteData) {
        setQuoteObj(quoteData.quote);
        setLaborTotal(await GetQuoteLaborTotals(quoteData.quote));

        //New Info

        const { quote } = quoteData;

        let removalsHeader7Subtotal = 0;
        let removalsHeader8Subtotal = 0;

        quote?.Removal?.Items.forEach((o) => {
          removalsHeader7Subtotal += Number(
            (o.Price + o.LaborSubtotal).toFixed(2)
          );
          removalsHeader8Subtotal += Number(
            (o.Price + o.LaborSubtotal).toFixed(2)
          );
        });

        let projectInstallSubtotal = 0;

        quote?.Tags?.forEach((t) => {
          const primaryItems = quote?.Material?.Items.filter(
            (item) => item.Tag_Id === t.Id && item.Parent_Id === 0
          );

          primaryItems.forEach((o) => {
            const installSub = o.LaborSubtotalWithRelatedItems;
            projectInstallSubtotal += installSub;
          });
        });

        quote?.Material?.Items.forEach((o) => {
          if (o.Parent_Id === 0 && o.Tag_Id === 0) {
            const installSub = o.LaborSubtotalWithRelatedItems;
            projectInstallSubtotal += installSub;
          }
        });

        setProjectInstallSubtotal(projectInstallSubtotal);

        setDeliveryTotal(
          quote?.Delivery?.Tools?.reduce(
            (accumulator, tool) => accumulator + tool.Price,
            0
          ) || 0
        );
        setAccessTotal(
          quote?.AccessArea?.Items?.reduce(
            (accumulator, items) => accumulator + items.Price,
            0
          ) || 0
        );

        const materialSubtotal = quote?.Material?.Total?.Subtotal || 0;
        const deliverySubtotal = quote?.Delivery?.Total?.Subtotal || 0;
        const toolSubtotal = quote?.Tool?.Total?.Subtotal || 0;
        const accessAreaSubtotal = quote?.AccessArea?.Total?.Subtotal || 0;
        const installSubtotal =
          Number(projectInstallSubtotal) > Stored_Values.MIN_LABOR
            ? Number(projectInstallSubtotal)
            : Stored_Values?.MIN_LABOR;

        supplyTotal.Subtotal = (materialSubtotal + deliverySubtotal).toFixed(2);
        installTotal.Subtotal = (
          installSubtotal +
          toolSubtotal +
          removalsHeader7Subtotal +
          accessAreaSubtotal
        ).toFixed(2);

        const subtotal =
          Number(supplyTotal.Subtotal) + Number(installTotal.Subtotal);
        setProjectSubtotal(
          subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );

        const storeTaxArray = await GetTaxesForMetro(params.Id);
        setStoreTaxes(storeTaxArray);

        let gstTax = 0;
        // let pstTax = 0;

        const taxitems = [
          { type: "Material", subtotal: materialSubtotal },
          { type: "Delivery", subtotal: deliverySubtotal },
          { type: "Labor", subtotal: installSubtotal },
          { type: "Removal", subtotal: removalsHeader7Subtotal },
          { type: "AccessArea", subtotal: accessAreaSubtotal },
          { type: "Tool", subtotal: toolSubtotal },
        ];

        const gst = storeTaxArray.find((tax) => tax.TaxCode === "Gst");
        // const pst = storeTaxArray.find(tax => tax.TaxCode === 'PST');

        if (gst) {
          taxitems.forEach((item) => {
            const { type, subtotal } = item;

            if (type === "Material") {
              gstTax += (gst.Rate / 100) * subtotal;
              // pstTax += (pst.Rate / 100) * subtotal;
            } else {
              gstTax += (gst.Rate / 100) * subtotal;
            }
          });
        }

        gstTax = gstTax.toFixed(2);
        // pstTax = pstTax.toFixed(2);

        // setEstimatePST(pstTax);
        setEstimateGST(gstTax);

        const grandTotal = Number(gstTax) + Number(subtotal.toFixed(2));
        setProjectGrandTotal(grandTotal);
        setDeposit(grandTotal * 0.1);

        //End of New Info
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetQuoteLaborTotals = async (quote) => {
    var total = quote.Labor.Total;
    if (total.Subtotal > 0 && total.Subtotal < quote.Minimum_Labor) {
      total.Subtotal = quote.Minimum_Labor;
      total.Total = total.Subtotal;
      total.Tax?.forEach((tx) => {
        tx.Amount = (total.Subtotal * tx.Rate) / 100.0;
        total.Total += tx.Amount;
      });
    }
    return total;
  };

  const [LaborTotal, setLaborTotal] = useState({});

  function handleUpload(event) {
    setPhoto(event.target.files[0]);
  }

  var observe;
  if (window.attachEvent) {
    observe = function (element, event, handler) {
      element?.attachEvent("on" + event, handler);
    };
  } else {
    observe = function (element, event, handler) {
      element?.addEventListener(event, handler, false);
    };
  }
  function init() {
    var text = document.getElementById("text");
    if (text !== null) {
      function resize() {
        text.style.height = "auto";
        text.style.height = text.scrollHeight + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }

  useEffect(() => {
    init();
  }, [quoteObj.Notes]); // eslint-disable-line react-hooks/exhaustive-deps

  const OpenUploadImageWindow = async (photo) => {
    // We want to use the try-catch pattern here to handle any errors that
    // may occur when making the API call to AddImageToCloud
    try {
      // Check if we have a photo
      if (photo !== null) {
        // Add the photo to the form data
        formdata.append("Quote Photo", photo);

        // Make the API call and store the result in a variable
        const uploadedImage = await AddImageToCloud(formdata);

        // If we have a result, set the preview image and open the window
        if (uploadedImage !== null) {
          setPreviewImage(uploadedImage);
          setPreviewImageWindowDisplay(true);
        }
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error(error);
    }
  };

  const ResetImage = async (imgurl) => {
    DeleteImageFromCloud(imgurl);
    setPreviewImageWindowDisplay(false);
  };

  const onButtonClick6 = () => {
    inputFile.current.click();
  };

  const DeleteImageFromCloud = async (imgUrl) => {
    try {
      await axios.delete(`${window.$DBURL}utilities/image`, {
        data: { Url: imgUrl },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteImageFromQuoteAndCloud = async (imgurl, photoid, quoteobj) => {
    DeleteImageFromCloud(imgurl);
    if (photoid === 0) {
      var index1 = quoteobj.Images.indexOf(imgurl);
      quoteobj.Images.splice(index1, 1);
    } else {
      var deleteQuotePhoto = await DeleteQuotePhoto(photoid);
      if (deleteQuotePhoto !== null) {
      }
    }
    var index2 = quoteobj.Photos.indexOf(imgurl);
    quoteobj.Photos.splice(index2, 1);
    var data = await UpdateQuote(quoteobj);
    if (data !== null) {
      setQuoteObj(data.quote);
    }
  };

  const HandleToolAddition = async ({
    storeName,
    toolQuantity,
    rentalLength,
  }) => {
    ToolQuant = toolQuantity;
    RentalL = rentalLength;
    HandleNewTool();
  };

  const HandleToolId = async ({ ToolId }) => {
    settoolID(ToolId);
    setToolOpen(true);
  };

  // Define a function to handle the addition of a new tool
  const HandleNewTool = async () => {
    // Send a POST request to the case/quote/tool endpoint with the required data
    const resp = await axios.post(window.$DBURL + "case/quote/tool", {
      Quote_Id: params.quoteId,
      Tool_Id: toolID,
      RentalLength: RentalL,
      Quant: ToolQuant,
    });

    // If the request is successful, do something
    if (resp !== null) {
    }

    // Call the onToolAdded function
    onToolAdded();

    // Set the tool card map to false
    setToolCardMap(false);
  };

  // Define a function to update the case status to processed
  const UpdateCaseStatusToProcessed = async () => {
    // Get the case by its ID
    const casestatus = await GetCaseById(params.caseId);

    // Check if the case status is either Accepted or Submitted
    if (
      casestatus.Case.CaseStatus === CaseStatus.Accepted ||
      casestatus.Case.CaseStatus === CaseStatus.Submitted
    ) {
      // Set the case to processed
      const process = await SetCaseTo_Processed(params.caseId);

      //   // If the case is successfully set to processed, redirect to the view case page
      //   if (process !== null) {
      //     history.push(
      //       "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      //     );
      //   }
      // } else {
      //   // If the case status is not Accepted or Submitted, redirect to the view case page
      //   history.push(
      //     "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      //   );
      // }
    }
  };

  const SaveQuoteInfo = async (quoteobj, redirectpage) => {
    const { Id, storeId, caseId } = params;
    try {
      const data = await UpdateQuote(quoteobj);
      if (data) {
        switch (redirectpage) {
          case "project":
            history.push(`/viewcase/${Id}/${storeId}/${caseId}`);
            break;
          case "userview":
            history.push(
              `/viewquoteuser/${Id}/${storeId}/${caseId}/${params.quoteId}/0/0`
            );
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateQuoteInfo = async (quoteobj) => {
    try {
      await UpdateQuote(quoteobj);
    } catch (error) {
      console.error(error);
    }
  };

  const onAddFileIconClicked = () => {
    inputFile.current.click();
  };

  const UploadPhoto = async (event) => {
    var file = event.target.files[0];
    if (file !== undefined) {
      if (
        file.name.includes(".jpg") ||
        file.name.includes(".JPG") ||
        file.name.includes(".jpeg") ||
        file.name.includes(".JPEG") ||
        file.name.includes(".png") ||
        file.name.includes(".PNG") ||
        file.name.includes(".heic") ||
        file.name.includes(".HEIC") ||
        file.name.includes(".heif") ||
        file.name.includes(".HEIF") ||
        file.name.includes(".webp") ||
        file.name.includes(".WEBP") ||
        file.name.includes(".jfif") ||
        file.name.includes(".JFIF") ||
        file.name.includes(".pjpeg") ||
        file.name.includes(".PJPEG") ||
        file.name.includes(".pjp") ||
        file.name.includes(".PJP") ||
        file.name.includes(".pdf") ||
        file.name.includes(".PDF")
      ) {
        var pic = await UploadFileToCloud(file);
        if (pic !== null) {
          setPreviewImage(pic);
          // setErrorText("");
        }
      } else {
        // setErrorText(
        //   "File not supported, we are also not currently accepting videos."
        // );
      }
    } else {
      return null;
    }
  };

  const AddCommentToQuote = async (quoteid, comment, file) => {
    var addtime = await GetCurrentTime();
    setCurTime(addtime);
    if (quoteid !== 0 && (comment !== "" || file != "")) {
      var commentObj = {
        Quote_Id: quoteid,
        Comment: comment,
        AddTime: addtime,
        File: file,
      };
      var data = await AddQuoteComment(commentObj);
      if (data !== null) {
        var proposalData = await GetQuoteInfoById(quoteid);
        setSubmittingLoadingIcon(false);
        setPreviewImage("");
        // setErrorText("");
        eraseText();
      }
    }
  };

  function eraseText() {
    document.getElementById("output").value = "";
  }

  const SetCurrentTime = async () => {
    var curtime = await GetCurrentTime();
    setCurTime(curtime);
  };

  const DeleteTagOnQuote = async (tag_id, quote_id) => {
    // Invoke the DeleteQuoteTag function and wait for the promise to resolve
    const data = await DeleteQuoteTag(tag_id);

    // Check if the data variable is null
    if (data === null) {
      // If the data variable is null, invoke the GetQuoteInfoById function
      GetQuoteInfoById(quote_id);
    }
  };

  // Define a function to handle the Save Button clicked event
  const SaveButtonClicked = async (quoteobj, redirectpage) => {
    // Update the case status to processed
    UpdateCaseStatusToProcessed();

    // Get the current time and set it as the edit time for the quote object
    quoteobj.EditTime = await GetCurrentTime();

    // Save the quote information
    SaveQuoteInfo(quoteobj, redirectpage);
  };

  useEffect(() => {
    if (photo !== "") {
      OpenUploadImageWindow(photo);
    }
  }, [photo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open === true) {
      GetQuoteInfoById(params.quoteId);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (saveQuoteState === true) {
      SaveButtonClicked(quoteObj, "userview");
    }
  }, [saveQuoteState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let header7Subtotal = 0;
    let header8Subtotal = 0;

    quoteObj?.Removal?.Items.forEach((o) => {
      header7Subtotal += o.Price + o.LaborSubtotal;
      header8Subtotal += o.Price + o.LaborSubtotal;
    });

    setRemovalsHeader8Subtotal(header8Subtotal);
  }, [quoteObj?.Removal?.Items]);

  if (!open) return null;
  if (quoteObj !== null && quoteObj !== undefined) {
    if (quoteObj.Material !== null && quoteObj.Material !== undefined) {
      return (
        <FullWidthDivPropDisplay marginleft="2%" inputdisplay="flex">
          <FlexColumnCont80x100Percent>
            <QuotePhotoUploadPreviewWindow
              quoteObj={quoteObj}
              open={previewImageWindowDisplay}
              previewsrc={previewImage}
              CancelUpload={() => {
                ResetImage(previewImage);
              }}
              CloseWindow={() => {
                setPreviewImageWindowDisplay(false);
              }}
              UploadSuccessful={() => {
                GetQuoteInfoById(params.quoteId);
              }}
            ></QuotePhotoUploadPreviewWindow>
            <FlexDiv>
              <FlexRowContFlexStartAutoHeight width="64%">
                <div>
                  <br></br>
                  <FlexDivFlexStartCenter margin="0%">
                    <LeagueSpartanLarge>{quoteObj?.Tittle}</LeagueSpartanLarge>
                    {/* <RegularResponsiveButton
                  onClick={async () => {
                    var tag = await GetQuoteTag(0);
                    setTagObj(tag);
                    setTagWindowDisplay(true);
                  }}
                >
                  New Tag
                </RegularResponsiveButton> */}
                  </FlexDivFlexStartCenter>
                  <br></br>

                  <TextInputReusable
                    defaultValue={quoteObj.Tittle}
                    onChange={(e) => {
                      var quote = quoteObj;
                      quote.Tittle = e.target.value;
                      setQuoteObj(quote);
                    }}
                  ></TextInputReusable>
                  <br></br>

                  {/* {quoteObj.Tags.map((o, i) => (
                <FlexRowContFlexStartAutoHeight key={i}>
                  <FlexRowContFlexStartAutoHeight>
                    <RegularText>{i + 1}.</RegularText>
                    <RegularTextWithMargins>{o.TagName}</RegularTextWithMargins>
                  </FlexRowContFlexStartAutoHeight>

                  <FlexRowCont100PercentFlexEnd>
                    <Icon30px30pxPointer
                      onClick={async () => {
                        var tag = await GetQuoteTag(o.Id);
                        setTagObj(tag);
                        setTagWindowDisplay(true);
                      }}
                      src="/pencil.svg"
                    ></Icon30px30pxPointer>
                  </FlexRowCont100PercentFlexEnd>
                  <Icon30px30pxPointer
                    onClick={() => {
                      setDeleteTagId(o.Id);
                      setDeleteTagConfirmDisplay(true);
                    }}
                    src="/Garbage_Icon.svg"
                  ></Icon30px30pxPointer>
                </FlexRowContFlexStartAutoHeight>
              ))} */}
                </div>
              </FlexRowContFlexStartAutoHeight>

              <div>
                <br></br>
              </div>
            </FlexDiv>

            {/* <StyledHRPropColour
                border={`0.5px solid ${ColorPalette.TertiaryGrey}`}
              ></StyledHRPropColour> */}

            <TagWindow
              tagObj={tagObj}
              quoteid={quoteObj.Id}
              open={tagWindowDisplay}
              RefreshTaglist={() => {
                GetQuoteInfoById(params.quoteId);
              }}
              onClose={() => {
                setTagWindowDisplay(false);
              }}
            ></TagWindow>

            <DeleteConfirmWindow
              closeDel={() => {
                setDeleteTagConfirmDisplay(false);
              }}
              onDeleteFunction={() => {
                DeleteTagOnQuote(deleteTagId, params.quoteId);
              }}
              deleteOpen={deleteTagConfirmDisplay}
            ></DeleteConfirmWindow>

            <br></br>

            <FlexDiv height="auto">
              <FlexDivLeftAlign>
                <FlexDiv width="98%">
                  <div style={{ width: "90%" }}>
                    <LeagueSpartanLarge>
                      Project Plan for Homeowner (Guide Text)
                    </LeagueSpartanLarge>

                    <br></br>
                    <TextAreaAutoResize
                      id="text"
                      defaultValue={quoteObj.Notes}
                      onChange={(e) => {
                        var quote = quoteObj;
                        quote.Notes = e.target.value;
                        setQuoteObj(quote);
                      }}
                    ></TextAreaAutoResize>

                    <br></br>

                    <StyledHRPropColour
                      border={`0.5px solid ${ColorPalette.TertiaryGrey}`}
                    ></StyledHRPropColour>

                    <br></br>
                  </div>

                  <ContentCont44Percent
                    position="static"
                    boxshadow="0px"
                    height="auto"
                    justifycontent="flex-start"
                  >
                    <FlexColumnFullWidthMargins height="100%">
                      <FlexRowContFlexStart height="auto">
                        {/* <Icon30px30pxPointer
                  src="/leftarrow.svg"
                  onClick={() => {
                    RouteToPrevious(userinfo.Role);
                  }}
                ></Icon30px30pxPointer> */}
                        <LeagueSpartanLarge>
                          Estimate Summary
                        </LeagueSpartanLarge>
                      </FlexRowContFlexStart>
                      <br></br>
                      <SummaryContainer
                        height="100%"
                        justifycontent="space-evenly"
                      >
                        {/* <SummaryContainerItems
                      display={
                        quoteObj?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                   <LeagueSpartanHeaderSmall>Material Supply</LeagueSpartanHeaderSmall>
                    </SummaryContainerItems> */}
                        <SummaryContainerItems
                          display={
                            quoteObj?.Material?.Total?.Subtotal > 0
                              ? "flex"
                              : "none"
                          }
                        >
                          <MediumHeader>Materials:</MediumHeader>
                          <MediumHeader>
                            {" "}
                            $
                            {quoteObj?.Material?.Total?.Subtotal?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>
                        <SummaryContainerItems
                          display={
                            quoteObj?.Delivery?.Total?.Subtotal > 0
                              ? "flex"
                              : "none"
                          }
                        >
                          <MediumHeader>Delivery:</MediumHeader>
                          <MediumHeader>
                            {" "}
                            $
                            {quoteObj?.Delivery?.Total?.Subtotal?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>

                        <br></br>

                        {/* <SummaryContainerItems
                      display={
                        quoteObj?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                   <LeagueSpartanHeaderSmall>Installation</LeagueSpartanHeaderSmall>
                    </SummaryContainerItems> */}

                        <SummaryContainerItems
                          display={
                            Number(projectInstallSubtotal).toFixed(2) >
                            Stored_Values.MIN_LABOR
                              ? "flex"
                              : "none"
                          }
                        >
                          <MediumHeader>Labour:</MediumHeader>
                          <MediumHeader>
                            $
                            {Number(projectInstallSubtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>
                        <SummaryContainerItems
                          display={
                            Number(projectInstallSubtotal).toFixed(2) >
                            Stored_Values.MIN_LABOR
                              ? "none"
                              : "flex"
                          }
                        >
                          <MediumHeader>Minimum Labour Fee:</MediumHeader>
                          <MediumHeader>
                            ${Stored_Values?.MIN_LABOR?.toFixed(2)}
                          </MediumHeader>
                        </SummaryContainerItems>
                        <SummaryContainerItems
                          display={
                            quoteObj?.Tool?.Total?.Subtotal > 0
                              ? "flex"
                              : "none"
                          }
                        >
                          <MediumHeader>Machine {"&"} Disposals:</MediumHeader>
                          <MediumHeader>
                            ${" "}
                            {quoteObj?.Tool?.Total?.Subtotal?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>
                        <SummaryContainerItems display={"flex"}>
                          <MediumHeader>Removals:</MediumHeader>
                          <MediumHeader>
                            ${" "}
                            {removalsHeader8Subtotal
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>
                        <SummaryContainerItems
                          display={
                            quoteObj?.AccessArea?.Total?.Subtotal > 0
                              ? "flex"
                              : "none"
                          }
                        >
                          <MediumHeader>Site Access:</MediumHeader>
                          <MediumHeader>
                            $
                            {quoteObj?.AccessArea?.Total?.Subtotal?.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>

                        <StyledHRPropColour
                          border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                        ></StyledHRPropColour>
                        <SummaryContainerItems>
                          <LeagueSpartanHeaderSmall>
                            Project Subtotal:
                          </LeagueSpartanHeaderSmall>
                          <MediumHeader>
                            $
                            {
                              projectSubtotal
                              // .toFixed(2)
                              // .toString()
                              // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                          </MediumHeader>
                        </SummaryContainerItems>

                        <SummaryContainerItems>
                          <MediumHeader>
                            {storeTaxes[0]?.TaxCode} ({storeTaxes[0]?.Rate}%)
                          </MediumHeader>
                          <MediumHeader>
                            $
                            {estimateGST
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </MediumHeader>
                        </SummaryContainerItems>

                        {/* <SummaryContainerItems>
                  <MediumHeader>
                      {storeTaxes[1]?.TaxCode} ({storeTaxes[1]?.Rate}%)
                    </MediumHeader>
                    <MediumHeader>
                      ${estimatePST.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </MediumHeader>
                    </SummaryContainerItems> */}

                        <StyledHRGreenBreak></StyledHRGreenBreak>
                        <SummaryContainerItems>
                          <LeagueSpartanRegular>
                            Project Total:
                          </LeagueSpartanRegular>
                          <LeagueSpartanRegular>
                            {" "}
                            $
                            {projectGrandTotal
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </LeagueSpartanRegular>
                        </SummaryContainerItems>
                      </SummaryContainer>
                    </FlexColumnFullWidthMargins>
                  </ContentCont44Percent>
                </FlexDiv>

                {/* <ContentCont77vw id="comments" width="73vw">
              <FlexRowContFlexStart height="auto">
                <LeagueSpartanLarge>Internal Notes</LeagueSpartanLarge>
              </FlexRowContFlexStart>

              <FlexColumnFullWidth height="auto" width="100%">
                <FlexColumnOverflowCont800px>
                  {quoteObj?.QuoteComments?.map((o, i) => (
                    <ProposalCommentCard
                      key={i}
                      // OpenPicture={() => {
                      //   DisplayImageInGallery(o.File);
                      // }}
                      // homeownerName={homeownerName}
                      estimatorName={quoteObj.Estimator}
                      estimatorPhoto={quoteObj.EstimatorPhoto}
                      // userinfo={userinfo}
                      UpdateProjectUI={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      commentObj={o}
                      estimatorId={quoteObj.User_Id}
                      quoteId={quoteObj.Id}
                    ></ProposalCommentCard>
                  ))}
                </FlexColumnOverflowCont800px>

                <StyledHRGreyBreak></StyledHRGreyBreak>
                <br></br>

                {submittingLoadingIcon === false ? (
                  <FlexRowContFullWidth>
                    <Icon30px30pxPointer
                      src="/AttachFile_Icon.svg"
                      onClick={() => {
                        onAddFileIconClicked();
                      }}
                    ></Icon30px30pxPointer>

                    <Image80x100px
                      display={previewImage !== "" ? "block" : "none"}
                      src={
                        previewImage === "" ? "" : window.$IMGURL + previewImage
                      }
                    ></Image80x100px>
                    <TextInput300x50
                      inputdisplay="none"
                      inputwidth="200px"
                      inputheight="85px"
                      mobiledisplay="none"
                      ref={inputFile}
                      type="file"
                      onClick={onAddFileIconClicked}
                      onChange={UploadPhoto}
                    ></TextInput300x50>
                    <BlankTextArea
                      id="output"
                      onChange={(e) => {
                        setCommentText(e.target.value);
                      }}
                      maxLength="2000"
                      placeholder="Type your message..."
                    ></BlankTextArea>

                    <br></br>
                    <MediumHeaderDarkGrey>
                      {commentText.length}/2000
                    </MediumHeaderDarkGrey>
                    <RegularResponsiveButton
                      display={sendButtonDisplay}
                      onClick={() => {
                        setSubmittingLoadingIcon(true);
                        setSendButtonDisplay("none");
                        AddCommentToQuote(quoteObj.Id, commentText, previewImage);
                      }}
                    >
                      Submit
                    </RegularResponsiveButton>
                  </FlexRowContFullWidth>
                ) : (
                  <FlexContFullWidthJustifyCenter>
                    <CircularProgress color={ColorPalette.PrimaryButtonBlue} />
                  </FlexContFullWidthJustifyCenter>
                )}
              </FlexColumnFullWidth>
            </ContentCont77vw> */}

                <br></br>
                <br></br>

                <FlexRowContFlexStartAutoHeight>
                  <LeagueSpartanLarge>Add Photos to Package</LeagueSpartanLarge>
                  <Icon30px30pxPointer
                    src={"/Green Button.svg"}
                    onClick={onButtonClick6}
                  ></Icon30px30pxPointer>
                </FlexRowContFlexStartAutoHeight>
                <input
                  style={{ display: "none" }}
                  ref={inputFile}
                  type="file"
                  onChange={handleUpload}
                />
                <br></br>
                <FlexRowContAutox100Percent
                  width="100%"
                  flexwrap="wrap"
                  alignitems="baseline"
                >
                  {quoteObj.Photos.map((o, i) => {
                    return loadingStates[o.Id] ? (
                      <Backdrop style={{ zIndex: 2 }} open>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : o.File?.includes(".pdf") || o.File?.includes(".PDF") ? (
                      <FlexContainerItem key={i} width="35%">
                        <FlexRowContFlexStart
                          position="relative"
                          width="auto"
                          justifycontent="space-between"
                          alignitems="baseline"
                          onClick={() => {
                            window.open(window.$IMGURL + o.File, "_blank");
                          }}
                        >
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target={"_blank"}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            <embed
                              target={"_blank"}
                              href={window.$IMGURL + o.File}
                              src={
                                window.$IMGURL +
                                o.File +
                                "#toolbar=0&navpanes=0&scrollbar=0"
                              }
                              width={"350px"}
                              style={{ backgroundColor: "white" }}
                              height={"200px"}
                            ></embed>
                            <br></br>
                          </a>
                          <OverrideEmbedDiv>
                            <OverrideEmbedImage right="0px"></OverrideEmbedImage>
                          </OverrideEmbedDiv>
                          <RelativeIconBottom70Percent
                            src="/Garbage_Icon.svg"
                            onClick={async () => {
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [o.Id]: true,
                              }));
                              await DeleteImageFromQuoteAndCloud(
                                o.File,
                                o.Id,
                                quoteObj
                              );
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [o.Id]: false,
                              }));
                            }}
                          ></RelativeIconBottom70Percent>
                        </FlexRowContFlexStart>
                        <br></br>
                        <SmallHeader width="70%">{o.Caption}</SmallHeader>
                      </FlexContainerItem>
                    ) : (
                      <FlexContainerItem key={i} width="35%">
                        <FlexRowContFlexStart
                          position="relative"
                          width="auto"
                          alignitems="baseline"
                          justifycontent="space-between"
                        >
                          <Image300x200
                            zIndex="1"
                            src={window.$IMGURL + o.File}
                          ></Image300x200>
                          <RelativeIconBottom70Percent
                            src="/Garbage_Icon.svg"
                            onClick={async () => {
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [o.Id]: true,
                              }));
                              await DeleteImageFromQuoteAndCloud(
                                o.File,
                                o.Id,
                                quoteObj
                              );
                              setLoadingStates((prevState) => ({
                                ...prevState,
                                [o.Id]: false,
                              }));
                            }}
                          ></RelativeIconBottom70Percent>
                        </FlexRowContFlexStart>
                        <SmallHeader width="70%">{o.Caption}</SmallHeader>
                      </FlexContainerItem>
                    );
                  })}
                </FlexRowContAutox100Percent>

                <StyledHRPropColour
                  border={`0.5px solid ${ColorPalette.TertiaryGrey}`}
                ></StyledHRPropColour>
                <br></br>
              </FlexDivLeftAlign>
            </FlexDiv>

            <FlexOverlayDivFullWidth>
              <FlexDiv90PercentMargins>
                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <FlexRowContFlexStartAutoHeight>
                      <SmallHeaderLeagueSpartan>
                        Materials
                      </SmallHeaderLeagueSpartan>
                      <Icon30px30pxPointer
                        src={"/Green Button.svg"}
                        onClick={() => {
                          UpdateQuoteInfo(quoteObj);
                          ReturnToMaterial();
                        }}
                      ></Icon30px30pxPointer>
                      <hr></hr>
                      <RegularResponsiveButton
                        onClick={async () => {
                          var tag = await GetQuoteTag(0);
                          setTagObj(tag);
                          setTagWindowDisplay(true);
                        }}
                      >
                        New Tag
                      </RegularResponsiveButton>
                    </FlexRowContFlexStartAutoHeight>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("material");
                      }}
                      src={displaySettings.material.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>
                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.material.display}
                  >
                    <QuoteTable
                      Tags={quoteObj.Tags}
                      key={quoteObj.Material}
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      EditTagWindow={(tag) => {
                        setTagObj(tag);
                        setTagWindowDisplay(true);
                      }}
                      DeleteTag={(tag) => {
                        setDeleteTagId(tag.Id);
                        setDeleteTagConfirmDisplay(true);
                      }}
                      quoteItems={quoteObj.Material.Items}
                      quoteTotalsArray={quoteObj.Material.Total.Tax}
                      subtotal={quoteObj.Material.Total.Subtotal.toFixed(2)}
                      total={quoteObj.Material.Total.Total.toFixed(2)}
                    ></QuoteTable>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>

                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <FlexRowContFlexStartAutoHeight>
                      <SmallHeaderLeagueSpartan>
                        Removals
                      </SmallHeaderLeagueSpartan>
                      <Icon30px30pxPointer
                        onClick={() => {
                          UpdateQuoteInfo(quoteObj);
                          ReturnToRemoval();
                        }}
                        src={"/Green Button.svg"}
                      ></Icon30px30pxPointer>
                    </FlexRowContFlexStartAutoHeight>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("removal");
                      }}
                      src={displaySettings.removal.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>
                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.removal.display}
                  >
                    <QuoteTable
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      quoteItems={quoteObj.Removal.Items}
                      quoteTotalsArray={quoteObj.Removal.Total.Tax}
                      subtotal={quoteObj.Removal.Total.Subtotal.toFixed(2)}
                      total={quoteObj.Removal.Total.Total.toFixed(2)}
                    ></QuoteTable>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>

                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <SmallHeaderLeagueSpartan>
                      Delivery
                    </SmallHeaderLeagueSpartan>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("delivery");
                      }}
                      src={displaySettings.delivery.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>
                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.delivery.display}
                  >
                    <QuoteTableDelivery
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      quoteItems={quoteObj.Delivery.Tools}
                      quoteTotalsArray={quoteObj.Delivery.Total.Tax}
                      subtotal={quoteObj.Delivery.Total.Subtotal.toFixed(2)}
                      total={quoteObj.Delivery.Total.Total.toFixed(2)}
                    ></QuoteTableDelivery>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>

                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <FlexRowContFlexStartAutoHeight>
                      <SmallHeaderLeagueSpartan>
                        Labour
                      </SmallHeaderLeagueSpartan>
                      <Icon30px30pxPointer
                        src={"/Green Button.svg"}
                        onClick={() => {
                          UpdateQuoteInfo(quoteObj);
                          ReturnToLabor();
                        }}
                      ></Icon30px30pxPointer>
                    </FlexRowContFlexStartAutoHeight>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("labor");
                      }}
                      src={displaySettings.labor.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>
                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.labor.display}
                  >
                    <QuoteTable
                      key={quoteObj.Labor.Items}
                      cateType={2}
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      quoteItems={quoteObj.Labor.Items}
                      quoteTotalsArray={LaborTotal.Tax}
                      subtotal={LaborTotal.Subtotal?.toFixed(2)}
                      total={LaborTotal.Total?.toFixed(2)}
                    ></QuoteTable>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>
                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <FlexRowContFlexStartAutoHeight>
                      <SmallHeaderLeagueSpartan>Tools</SmallHeaderLeagueSpartan>
                      <Icon30px30pxPointer
                        src={"/Green Button.svg"}
                        onClick={() => {
                          UpdateQuoteInfo(quoteObj);
                          setToolCardMap(true);
                        }}
                      ></Icon30px30pxPointer>
                    </FlexRowContFlexStartAutoHeight>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("tool");
                      }}
                      src={displaySettings.tool.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>
                  <NewQuoteTool
                    open={toolOpen}
                    onClose={() => {
                      GetQuoteInfoById(params.quoteId);
                      setToolOpen(false);
                    }}
                    onPreview={HandleToolAddition}
                  ></NewQuoteTool>
                  <ToolSelectionCard
                    open={toolCardMap}
                    onClose={() => {
                      setToolCardMap(false);
                    }}
                    onPreview={HandleToolId}
                  >
                    {" "}
                  </ToolSelectionCard>
                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.tool.display}
                  >
                    <QuoteTableTools
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      quoteItems={quoteObj.Tool.Tools}
                      quoteTotalsArray={quoteObj.Tool.Total.Tax}
                      subtotal={quoteObj.Tool.Total.Subtotal.toFixed(2)}
                      total={quoteObj.Tool.Total.Total.toFixed(2)}
                    ></QuoteTableTools>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>
                <Cont100PercentWidth>
                  <FlexCenterDiv100PercentSpaceBetween>
                    <FlexRowContFlexStartAutoHeight>
                      <SmallHeaderLeagueSpartan>
                        Access Area
                      </SmallHeaderLeagueSpartan>
                      <Icon30px30pxPointer
                        src={"/Green Button.svg"}
                        onClick={() => {
                          UpdateQuoteInfo(quoteObj);
                          ReturnToAccess();
                        }}
                      ></Icon30px30pxPointer>
                    </FlexRowContFlexStartAutoHeight>
                    <Icon30x30
                      onClick={() => {
                        ToggleSection("access");
                      }}
                      src={displaySettings.access.arrow}
                    ></Icon30x30>
                  </FlexCenterDiv100PercentSpaceBetween>

                  <FlexRowCont70PercentSpaceBetween>
                    <HundredPercentFlexDiv>
                      <LeagueSpartanTinyMargins>
                        Length:{" "}
                      </LeagueSpartanTinyMargins>
                      <SmallHeader>{quoteObj.AccessArea.Length} Ft</SmallHeader>
                    </HundredPercentFlexDiv>

                    <HundredPercentFlexDiv>
                      <LeagueSpartanTinyMargins>
                        Width:{" "}
                      </LeagueSpartanTinyMargins>
                      <SmallHeader>{quoteObj.AccessArea.Width} Ft</SmallHeader>
                    </HundredPercentFlexDiv>

                    <HundredPercentFlexDiv>
                      <LeagueSpartanTinyMargins>
                        Obstruction:{" "}
                      </LeagueSpartanTinyMargins>
                      <SmallHeader>
                        {quoteObj.AccessArea.Obstruct !== false ? "Yes" : "No"}
                      </SmallHeader>
                    </HundredPercentFlexDiv>

                    <HundredPercentFlexDiv>
                      <LeagueSpartanTinyMargins>
                        Elevation:{" "}
                      </LeagueSpartanTinyMargins>
                      <SmallHeader>
                        {quoteObj.AccessArea.Elevation} Ft
                      </SmallHeader>
                    </HundredPercentFlexDiv>
                  </FlexRowCont70PercentSpaceBetween>

                  <FlexColumnFullWidth
                    margin="0"
                    display={displaySettings.access.display}
                  >
                    <QuoteTable
                      onItemChange={() => {
                        GetQuoteInfoById(params.quoteId);
                      }}
                      quoteItems={quoteObj.AccessArea.Items}
                      quoteTotalsArray={quoteObj.AccessArea.Total.Tax}
                      subtotal={quoteObj.AccessArea.Total.Subtotal.toFixed(2)}
                      total={quoteObj.AccessArea.Total.Total.toFixed(2)}
                    ></QuoteTable>
                  </FlexColumnFullWidth>
                </Cont100PercentWidth>

                <FlexDivCenter>
                  <RegularResponsiveButton
                    width="200px"
                    bgcolor={ColorPalette.SecondaryGreen}
                    onClick={() => {
                      history.push(
                        "/viewcase/" +
                          params.Id +
                          "/" +
                          params.storeId +
                          "/" +
                          params.caseId
                      );
                    }}
                  >
                    Return to Project
                  </RegularResponsiveButton>
                  <RegularResponsiveButton
                    width="200px"
                    onClick={() => {
                      SaveButtonClicked(quoteObj, "project");
                    }}
                  >
                    Save
                  </RegularResponsiveButton>
                </FlexDivCenter>
              </FlexDiv90PercentMargins>
            </FlexOverlayDivFullWidth>
          </FlexColumnCont80x100Percent>
          <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop>
        </FullWidthDivPropDisplay>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 1000 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  } else {
    return (
      <Backdrop style={{ zIndex: 1000 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ViewQuote.defaultProps = {
  onPreview: () => {},
  onFormComplete: () => {},
  onItemChange: () => {},
  onToolAdded: () => {},
};

export default ViewQuote;
