import axios from "axios";
import { useParams } from "react-router-dom";

export const GetMetroList = async () => {
  const resp = await axios.get(window.$DBURL + "utilities/metros");
  if (resp.status === 200) {
    if (resp.data !== null) {
      if (resp.data.metros !== null) {
        return resp.data.metros;
      }
    }
  }
  return null;
};

export const GetCityList = async (metroid) => {
  const resp = await axios.get(
    window.$DBURL + "utilities/city/metro/" + metroid
  );
  if (resp.status === 200) {
    if (resp.data !== null) {
      if (resp.data.cities !== null) {
        return resp.data.cities;
      }
    }
  }
  return null;
};

export const SetHeaderAuthorization = async () => {
  const token = sessionStorage.getItem("token");
  if (token === null || token === "") {
      // window.open("/");
  } else {
    axios.defaults.headers.common["Authorization"] = "Basic " + token;
  }
};

export const AddImageToCloud = async (image) => {
  const resp = await axios.post(window.$DBURL + "utilities/image", image);
  if (resp.status === 200) {
    return resp.data.pics[0];
  } else {
    return null;
  }
};

export const UploadFileToCloud = async (file) => {
  let formdata = new FormData();
  formdata.append("Image/PDF", file);
  var resp = await axios.post(window.$DBURL + "utilities/image", formdata);

  if (resp.status === 200) {
    return resp.data.pics[0];
  } else {
    return null;
  }
};

export const GetMetroTaxes = async (metro_id) => {
  var resp = await axios.get(window.$DBURL + "tax/metro/" + metro_id);
  if (resp.status === 200) {
    var tax = resp.data.tax;
    return tax;
  } else return [];
};

export const AddFeedback = async (feedback) => {
  const resp = await axios.post(window.$DBURL + "feedback", {
    User_Id: feedback.User_Id,
    Comment: feedback.Comment,
    Rank: feedback.Rank,
    AddTime: feedback.AddTime,
    Platform: feedback.Platform,
  });
  if (resp.status === 200) {
    return resp.data.feedback;
  } else {
    return null;
  }
};

// pay amt in dollar, in the name of what. name can be any string describing the payment info.
// return Stripe payment portal url
export const MakeStripePay = async (
  amt,
  currency,
  name,
  transactionid,
  quote_id,
  store_id,
  userauthtoken,
  invoiceid,
) => {
  var amount = amt * 100;
  const resp = await axios.post(window.$DBURL + "pay/checkout", {
    Amt: amount,
    CurrencyCode: currency,
    ProductName: name,
    // SuccessUrl:"https://localhost:3000/paymentsuccesstransit/"+quote_id + "/" + store_id+ "/"+userauthtoken+"/"+transactionid+"/"+amount+"/"+currency,
    // CancelUrl:"https://localhost:3000/paymentfailed/"+quote_id + "/" + userauthtoken
    SuccessUrl:
      "https://app.newrhodesconstruction.com/paymentsuccesstransit/" +
      quote_id +
      "/" +
      store_id +
      "/" +
      userauthtoken +
      "/" +
      transactionid +
      "/" +
      amount +
      "/" +
      currency + 
      "/" +
      invoiceid,
    CancelUrl:
      "https://app.newrhodesconstruction.com/paymentfailed/" +
      quote_id +
      "/" +
      userauthtoken,
  });
  if (resp.status === 200) {
    return resp.data.checkout;
  } else return null;
};

export const CreatePayRecord = async (payrequest) => {
  const resp = await axios.post(window.$DBURL + "pay", {
    CardPayRequest: payrequest,
  });
  if (resp.status === 200) {
    return "Sucess";
  } else {
    return "Failed";
  }
};

export const CreateDepositRecord = async (payrequest) => {
  const resp = await axios.post(window.$DBURL + "pay/deposit", {
    CardPayRequest: payrequest,
  });
  if (resp.status === 200) {
    return "Sucess";
  } else {
    return "Failed";
  }
};

export const GetCurrentTime = async () => {
  var d = new Date();
  var fullyear = d.getFullYear();
  var month = ("0" + (d.getMonth() + 1)).slice(-2);
  var day = ("0" + d.getDate()).slice(-2);
  var hour = ("0" + d.getHours()).slice(-2);
  var time = ("0" + d.getMinutes()).slice(-2);
  var seconds = ("0" + d.getSeconds()).slice(-2);
  var curtime =
    fullyear +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    time +
    ":" +
    seconds;
  return curtime;
};

// Send pay notification to accountant and manager, method is the pay method, ex: E-transfer, Cheque, etc.
// notes can be anything you like to add: ex. Notes: Deposit
export const SendPayNotification = async (caseid, amount, method, notes) => {
  const resp = await axios.post(window.$DBURL + "pay/notify", {
    CaseId: caseid,
    Amount: amount,
    Method: method,
    Notes: notes,
  });
  if (resp.status === 200) {
    return "Sucess";
  } else {
    return "Failed";
  }
};

// export const decimalHoursToTimeString = (decimalHours) => {
//   const hours = Math.floor(decimalHours);
//   const minutes = Math.round((decimalHours - hours) * 60);

//   if (hours === 0) {
//     return `${minutes} Mins`;
//   } else if (minutes === 0) {
//     return `${hours} Hours`;
//   } else {
//     return `${hours} Hours ${minutes} Mins`;
//   }
// };

function decimalHoursToTimeString(decimalHours) {
  // Attempt to convert decimalHours to a floating-point number
  if(decimalHours){
    const hours = parseFloat(decimalHours);
  // Check if the conversion was successful and hours is a valid number
  if (!isNaN(hours)) {
    return `${hours.toFixed(2)} Hours`;
  } else {
    return "0 Hours"; // Or any other fallback response for invalid or non-numeric inputs
  }
} else {
  return 0;
}
}

export { decimalHoursToTimeString };
