import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Input from "../../comps/Input";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { FlexColumnFullWidth } from "../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../StylesheetComps/Div";
import { Image200x200pxContain } from "../../StylesheetComps/Imgs/Images";
import { SmallHeader } from "../../StylesheetComps/Menu";
import {
  LeagueSpartanExtraLargeBold,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

const RejectOrderScreen = () => {
  //=============================================================================
  // Reject Order Screen - Supplier rejects an order with New Rhodes Construction
  //=============================================================================

  //=============================================================================
  // Input of credentials required.
  //=============================================================================

  const GetSessionInfo = async () => {
    const resp = await axios.get(window.$DBURL + "users/profile");
    sessionStorage.setItem("userInfo", JSON.stringify(resp.data.User));
  };

  const params = useParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [headerText, setHeaderText] = useState(
    "Please enter your credentials to reject this order:"
  );
  const [inputDivDisplay, setInputDivDisplay] = useState("flex");
  var supplieridSync = 0;
  const [supplierid, setSupplierid] = useState(0);

  const HandleAccept = async () => {
    if (supplieridSync === 0) {
      const resp = await axios
        .post(
          window.$DBURL +
            "order/quote/" +
            params.quoteid +
            "/item/" +
            params.itemid +
            "/supplier/" +
            supplierid +
            "/reject"
        )
        .catch(handleErrors);
      if (resp.status === 200) {
        setHeaderText(
          "Sorry for missing this opportunity. Hope to work together next time!"
        );
        setInputDivDisplay("none");
      }
      function handleErrors(err) {
        if (err.request) {
          setError(err.response.data.Message);
        } else if (err.response) {
          setError(err.response.data.Message);
        } else if (err.status === 200) {
          setHeaderText(
            "Sorry for missing this opportunity. Hope to work together next time!"
          );
          setInputDivDisplay("none");
        }
      }
    } else {
      const resp = await axios
        .post(
          window.$DBURL +
            "order/quote/" +
            params.quoteid +
            "/item/" +
            params.itemid +
            "/supplier/" +
            supplieridSync +
            "/reject"
        )
        .catch(handleErrors);
      if (resp.status === 200) {
        setHeaderText(
          "Sorry for missing this opportunity. Hope to work together next time!"
        );
        setInputDivDisplay("none");
      }
      function handleErrors(err) {
        if (err.request) {
          setError(err.response.data.Message);
        } else if (err.response) {
          setError(err.response.data.Message);
        } else if (err.status === 200) {
          setHeaderText(
            "Sorry for missing this opportunity. Hope to work together next time!"
          );
          setInputDivDisplay("none");
        }
      }
    }
  };

  const GetAuthToken = async () => {
    var resp = await axios
      .post(window.$DBURL + "users/session/login", {
        Username: username,
        Password: password,
      })
      .catch(handleErrors);
    if (resp == null) {
      resp = null;
    } else if (resp.data !== "Password is too short.") {
      const token = resp.data.Session.AuthToken;
      axios.defaults.baseURL = window.$DBURL;
      sessionStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "Basic " + token;
      setSupplierid(resp.data.User.Id);
      supplieridSync = resp.data.User.Id;
      HandleAccept();
    }

    function handleErrors(err) {
      if (err.request) {
        setError("Incorrect Username or Password");
      } else if (err.response) {
        setError("Incorrect Username or Password");
      }
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []);

  return (
    <FlexDivCenter>
      <FlexColumnFullWidth>
        <FlexColumnFullWidth>
          <Image200x200pxContain src="/newrhodeslogo.svg"></Image200x200pxContain>
          <LeagueSpartanExtraLargeBold>
            {headerText}
          </LeagueSpartanExtraLargeBold>
          <FlexColumnFullWidth display={inputDivDisplay}>
            <RegularTextBoldRed>{error}</RegularTextBoldRed>
            <SmallHeader>Username</SmallHeader>
            <Input
              placeholder="Username"
              inputheight="2%"
              inputwidth="50%"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            ></Input>
            <SmallHeader>Password</SmallHeader>
            <Input
              type="password"
              placeholder="Password"
              inputheight="2%"
              inputwidth="50%"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></Input>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                GetAuthToken();
              }}
            >
              Reject Order
            </RegularResponsiveButton>
          </FlexColumnFullWidth>
        </FlexColumnFullWidth>
      </FlexColumnFullWidth>
    </FlexDivCenter>
  );
};

RejectOrderScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default RejectOrderScreen;
