import React, { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont400x575px,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanExtraLargeWidth200px,
} from "../../../StylesheetComps/Titles";

const EditMetroWindow = ({
  open,
  onClose,
  onChange,
  onMetroComplete,
  onPreview,
}) => {
  const [editMetro, setEditMetro] = useState("");

  if (!open) return null;
  return (
    <Cont400x575px>
      <LeagueSpartanExtraLargeBold>Edit Region</LeagueSpartanExtraLargeBold>
      <LeftItemWindowFixed>
        <LeagueSpartanExtraLargeWidth200px>
          Region Name
        </LeagueSpartanExtraLargeWidth200px>

        <TextInput72Percent
          placeholder="Metro Name"
          onChange={(e) => {
            setEditMetro(e.target.value);
          }}
        ></TextInput72Percent>
        <RegularResponsiveButton
          onClick={() => {
            onPreview({ editMetro });
            onMetroComplete(editMetro);
            onClose();
          }}
        >
          Add
        </RegularResponsiveButton>
        <RegularResponsiveButton
          bgcolor={ColorPalette.PrimaryRed}
          onClick={onClose}
        >
          Cancel
        </RegularResponsiveButton>
      </LeftItemWindowFixed>
    </Cont400x575px>
  );
};

EditMetroWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onMetroComplete: () => {},
};

export default EditMetroWindow;
