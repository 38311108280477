import { useHistory } from "react-router-dom";
import Balloons from "../../../Animation/balloonlottie.json";
import LottieAnimation from "../../../Lottie";
import {
  FlexColumnCont70x100Percent,
  FlexColumnFullWidth,
} from "../../../StylesheetComps/Cont";
import { BlankDiv } from "../../../StylesheetComps/Div";
import {
  GreenNavigationButton,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { RegularText } from "../../../StylesheetComps/Titles";

const Submitted = ({ open, metroId, storeId }) => {
  const history = useHistory();

  if (!open) return null;
  return (
    <FlexColumnFullWidth bgcolor="white">
      <FlexColumnFullWidth>
        <FlexColumnCont70x100Percent>
          <SmallHeaderLeagueSpartan fontsize="24px">
            Thank you for drafting this project!
          </SmallHeaderLeagueSpartan>
          <RegularText>
            This project has been drafted, you can submit it to us at anytime by
            visiting this project from your 'Projects' page.
          </RegularText>

          <BlankDiv>
            <LottieAnimation lotti={Balloons} height={300} width={300}>
              {" "}
            </LottieAnimation>
          </BlankDiv>

          <GreenNavigationButton
            onClick={() => {
              history.push("/CasesPersonal/" + metroId + "/" + storeId);
            }}
            width="15vw"
            lineheight="2.4vw"
            height="auto"
          >
            View Project
          </GreenNavigationButton>
        </FlexColumnCont70x100Percent>
      </FlexColumnFullWidth>
    </FlexColumnFullWidth>
  );
};

Submitted.defaultProps = {
  metroId: 0,
  storeId: 0,
};

export default Submitted;
