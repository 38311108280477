import React, { useState, useEffect } from "react";
import axios from "axios";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/ActionMenu";
import MetroCatCard from "../../comps/MetroCatCard";
import NewMetroWindow from "../../comps/Windows/New Metro";
import DeleteMetroWindow from "../../comps/Windows/DeleteMetro";
import EditMetroWindow from "../../comps/Windows/EditMetro";

import {
  LeftCont,
  FlexCont100vw100vh,
  RightCont,
} from "../../StylesheetComps/Cont";
import { GetMetroList } from "../../API_Functions/Utility_Functions";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import {
  FlexDiv70x7Percent,
  GridDiv80xAutoPercent,
} from "../../StylesheetComps/Div";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const MetroCatScreen = () => {
  //=============================================================================
  // View All Regions Screen - Admin can create Regions
  //=============================================================================

  //=============================================================================
  // Page only accessable by Admin
  //=============================================================================

  const [metroList, getMetroList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const [delMetroOpen, setDelMetroOpen] = useState(false);
  const [editMetroOpen, setEditMetroOpen] = useState(false);
  const [storeMetroId, setStoreMetroId] = useState(0);

  const DeleteMetroFunc = async () => {
    const resp = await axios.delete(
      window.$DBURL + "utilities/metros/" + storeMetroId
    );
    if (resp.status === 200) {
      GetMetro();
    }
  };

  const HandleEditMetro = async (newMetroName) => {
    var resp = await axios.put(window.$DBURL + "utilities/metros", {
      Name: newMetroName,
      Id: storeMetroId,
    });
    if (resp.status === 200) {
      GetMetro();
    }
  };

  const GetMetro = async () => {
    var metros = await GetMetroList();
    getMetroList(metros);
  };

  const HandleNewMetro = async (newMetro) => {
    var resp = await axios.post(window.$DBURL + "utilities/metros", {
      Name: newMetro,
    });
    if (resp.status === 200) {
      GetMetro();
    }
  };

  useEffect(() => {
    GetMetro();
  }, []);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <NewMetroWindow
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onFormComplete={HandleNewMetro}
      ></NewMetroWindow>
      <RightCont>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <LeagueSpartanExtraLargeBold>
              Metro Areas
            </LeagueSpartanExtraLargeBold>
            <RegularResponsiveButton onClick={() => setIsOpen(true)}>
              Add Metro
            </RegularResponsiveButton>
          </FlexDiv70x7Percent>
          <GridDiv80xAutoPercent>
            {metroList.map((o, i) => (
              <MetroCatCard
                key={i}
                MetroName={"-" + o.Name + "-"}
                MetroID={o.Id}
                DelMetro={() => {
                  setDelMetroOpen(true);
                  setStoreMetroId(o.Id);
                }}
                EditMetro={() => {
                  setEditMetroOpen(true);
                  setStoreMetroId(o.Id);
                }}
              ></MetroCatCard>
            ))}

            <DeleteMetroWindow
              deleteOpen={delMetroOpen}
              closeDel={() => setDelMetroOpen(false)}
              onDeleteFunction={DeleteMetroFunc}
            ></DeleteMetroWindow>
          </GridDiv80xAutoPercent>
          <EditMetroWindow
            open={editMetroOpen}
            onClose={() => setEditMetroOpen(false)}
            onMetroComplete={HandleEditMetro}
          ></EditMetroWindow>
        </RightViewFullWidth>
      </RightCont>
    </FlexCont100vw100vh>
  );
};

MetroCatScreen.defaultProps = {};

export default MetroCatScreen;
