import {
  FlexRowCont100PercentSpaceBetween,
  RelativeCont100Percent,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanRegularMinWidth,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";

const QuoteGrandTotal = ({ grandTotal }) => {
  var grand = grandTotal === 0 ? 0 : grandTotal.toFixed(2);

  return (
    <RelativeCont100Percent>
      <FlexRowCont100PercentSpaceBetween>
        <LeagueSpartanRegularMinWidth font_size="16px" head_text="Grand Total:">
          Grand Total:
        </LeagueSpartanRegularMinWidth>
        <LeagueSpartanRegularMinWidth>
          {"$" + grand.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </LeagueSpartanRegularMinWidth>
      </FlexRowCont100PercentSpaceBetween>
      <StyledHRPropColour border={`0.5px solid black`}></StyledHRPropColour>
    </RelativeCont100Percent>
  );
};

QuoteGrandTotal.defaultProps = {
  grandTotal: 0,
};

export default QuoteGrandTotal;
