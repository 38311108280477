/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditAppointment from "../../comps/EditAppointment";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import {
  FlexColumnCont80vwAutoHeight,
  FlexContMobileColumn,
  FlexRowContFullWidth,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";

const MyCalendarScreen = ({ onPreview, onProfileUpdated }) => {
  //==============================================================================
  // MyCalendar / Personal Calendar Screen - Users view their Appointments here
  //==============================================================================

  //==============================================================================
  // Users can reschedule, cancel, etc. on this page for all of their Appointments
  //==============================================================================

  const [isEditAppointment, setIsEditAppointment] = useState(false);
  const [roleNum, setRoleNum] = useState(1000);
  var appointmentId = 0;

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;
  const [appointmentList, setAppointmentList] = useState([]);

  const [calendarMonth, setCalendarMonth] = useState(currentMonth);

  const GetUserAppointments = async () => {
    const resp = await axios.post(window.$DBURL + "appointment/user", {
      Year: currentYear,
      Month: currentMonth,
      Day: 0,
    });
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.appointment !== null) {
          if (resp.data.appointment.length > 0) {
            setAppointmentList(resp.data.appointment);
            setStartTimeSlot(resp.data.appointment[0].Start.Hour);
            setStartTimeSlotMinute(resp.data.appointment[0].Start.Minute);
            setEndTimeSlot(
              resp.data.appointment[resp.data.appointment.length - 1].End.Hour
            );
            setEndTimeSlotMinute(
              resp.data.appointment[resp.data.appointment.length - 1].End.Minute
            );
          } else {
            setAppointmentList([]);
          }
        }
      }
    }
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };
  const GetUserAppointmentsAsync = async () => {
    const resp = await axios.post(window.$DBURL + "appointment/user", {
      Year: currentYear,
      Month: calendarMonth,
      Day: 0,
    });
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.appointment !== null) {
          if (resp.data.appointment.length > 0) {
            setStartTimeSlot(resp.data.appointment[0].Start.Hour);
            setStartTimeSlotMinute(resp.data.appointment[0].Start.Minute);
            setEndTimeSlot(
              resp.data.appointment[resp.data.appointment.length - 1].End.Hour
            );
            setEndTimeSlotMinute(
              resp.data.appointment[resp.data.appointment.length - 1].End.Minute
            );

            setAppointmentList(resp.data.appointment);
          } else {
            setAppointmentList([]);
          }
        }
      }
    }
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  const [nameofUser, setNameOfUser] = useState("");

  const GetSessionInfo = async () => {
    if (JSON.parse(sessionStorage.getItem("userInfo")).Name !== null) {
      setNameOfUser(JSON.parse(sessionStorage.getItem("userInfo")).Name);
    } else {
      setNameOfUser("Homeowner");
    }
  };

  const history = useHistory();

  useEffect(() => {
    SetHeaderAuthorization();
  });

  useEffect(() => {
    GetUserAppointmentsAsync();
    GetSessionInfo();
  }, [calendarMonth]); // eslint-disable-line react-hooks/exhaustive-deps

  const [appDay, setAppDay] = useState(0);
  const [appMonth, setAppMonth] = useState(0);
  const [appYear, setAppYear] = useState(0);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const [flexible, setFlexible] = useState(false);

  const [slotId, setSlotId] = useState(0);
  const [appId, setAppId] = useState(0);

  const [contactName, setContactName] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");

  const [installerId, setInstallerId] = useState(0);

  const [appointmentIdAsync, setAppointmentIdAsync] = useState(0);

  var contactNameNew = "";
  var addressNew = "";
  var phoneNew = "";
  var cityNew = "";
  var flexibleNew = "";
  var slotIdNew = 0;
  var appDescNew = "";
  var newDay = "";
  var newMonth = "";
  var newYear = "";

  const HandleAppointmentForm = async ({
    contactName,
    address,
    phone,
    city,
    flexible,
    slotId,
    appDesc,
    EditYear,
    EditMonth,
    EditDay,
  }) => {
    contactNameNew = contactName;
    addressNew = address;
    phoneNew = phone;
    cityNew = city;
    // setStartDateNew(startDate);
    flexibleNew = flexible;
    slotIdNew = slotId;
    appDescNew = appDesc;
    newDay = EditDay;
    newMonth = EditMonth;
    newYear = EditYear;
  };

  const HandleAppointmentUpdated = async ({
    editName,
    editAddress,
    editPhone,
    editCity,
    editFlexible,
    editSlotId,
    editAppDesc,
  }) => {
    if (
      (newDay === appDay &&
        newMonth === appMonth &&
        newYear === appYear &&
        slotIdNew === slotId) ||
      slotIdNew === 0
    ) {
      const resp = await axios.put(window.$DBURL + "appointment", {
        Id: appId,
        DailyOpenSlot_Id: slotIdNew || slotId,
        Day: newDay || appDay,
        Month: newMonth || appMonth,
        Year: newYear || appYear,
        Flexible: flexibleNew,
        Contactname: contactNameNew || contactName,
        Phone: phoneNew || phone,
        City: cityNew || city,
        Street: addressNew || address,
        Description: appDescNew || appDesc,
      });
      if (resp.status === 200) {
        GetAppointmentInfoAsync();
        GetUserAppointmentsAsync();
      }
    } else {
      const resp = await axios.put(window.$DBURL + "appointment/all", {
        Id: appId,
        DailyOpenSlot_Id: slotIdNew || slotId,
        Day: newDay || appDay,
        Month: newMonth || appMonth,
        Year: newYear || appYear,
        Flexible: flexibleNew,
        Contactname: contactNameNew || contactName,
        Phone: phoneNew || phone,
        City: cityNew || city,
        Street: addressNew || address,
        Description: appDescNew || appDesc,
      });
      if (resp.status === 200) {
        GetAppointmentInfoAsync();
        GetUserAppointmentsAsync();
      }
    }
  };

  const HandleCancel = async () => {
    const resp = await axios.delete(
      window.$DBURL + "appointment/cancel/" + appointmentIdAsync
    );
    if (resp.status === 200) {
      GetAppointmentInfoAsync();
      GetUserAppointmentsAsync();
    }
  };

  const GetAppointmentInfoAsync = async () => {
    const resp = await axios.get(
      window.$DBURL + "appointment/" + appointmentIdAsync
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.appointment !== null) {
          setAppDay(resp.data.appointment.Day);
          setAppMonth(resp.data.appointment.Month);
          setAppYear(resp.data.appointment.Year);

          setFlexible(resp.data.appointment.Flexible);
          setSlotId(resp.data.appointment.DailyOpenSlot_Id);
          setAppId(resp.data.appointment.Id);

          setContactName(resp.data.appointment.Contactname);
          setAppDesc(resp.data.appointment.Description);
          setAddress(resp.data.appointment.Street);
          setPhone(resp.data.appointment.Phone);
          setCity(resp.data.appointment.City);

          setInstallerId(resp.data.appointment.Installer_Id);

          if (resp.data.appointment.Start !== null) {
            setStartHour(resp.data.appointment.Start.Hour);
            setStartMinute(resp.data.appointment.Start.Minute);
          }

          if (resp.data.appointment.End !== null) {
            setEndHour(resp.data.appointment.End.Hour);
            setEndMinute(resp.data.appointment.End.Minute);
          }
        }
      }
    }
  };

  const GetAppointmentInfo = async () => {
    const resp = await axios.get(
      window.$DBURL + "appointment/" + appointmentId
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.appointment !== null) {
          setAppDay(resp.data.appointment.Day);
          setAppMonth(resp.data.appointment.Month);
          setAppYear(resp.data.appointment.Year);

          // setStartMinute(resp.data.appointment.Start.Minute)
          // setEndMinute(resp.data.appointment.End.Minute)

          setFlexible(resp.data.appointment.Flexible);
          setSlotId(resp.data.appointment.DailyOpenSlot_Id);
          setAppId(resp.data.appointment.Id);

          setContactName(resp.data.appointment.Contactname);
          setAppDesc(resp.data.appointment.Description);
          setAddress(resp.data.appointment.Street);
          setPhone(resp.data.appointment.Phone);
          setCity(resp.data.appointment.City);

          setInstallerId(resp.data.appointment.Installer_Id);
          if (resp.data.appointment.Start !== null) {
            setStartHour(resp.data.appointment.Start.Hour);
          }

          if (resp.data.appointment.End !== null) {
            setEndHour(resp.data.appointment.End.Hour);
          }

          if (
            resp.data.appointment.Start.Minute === 0 &&
            resp.data.appointment.End.Minute === 0
          ) {
            setStartMinute("00");
            setEndMinute("00");
          } else if (resp.data.appointment.End.Minute === 0) {
            setEndMinute("00");
          } else if (resp.data.appointment.Start.Minute === 0) {
            setStartMinute("00");
          } else {
            setStartMinute(resp.data.appointment.Start.Minute);
            setEndMinute(resp.data.appointment.End.Minute);
          }
        }
      }
    }
  };

  const closeDayPropGetter = (date) => {
    var obj = appointmentList.find((o, i) => {
      if (
        o.Day === date.getDate() &&
        o.Month === date.getMonth() + 1 &&
        o.Year === date.getFullYear()
      )
        return appointmentList[i];
    });
    if (
      obj !== undefined &&
      obj.Day === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear()
    )
      return { style: { backgroundColor: "#169ADB" } };
  };

  useEffect(() => {
    GetUserAppointments();
  }, []);

  const [startTimeSlot, setStartTimeSlot] = useState(0);
  const [endTimeSlot, setEndTimeSlot] = useState(0);

  const [startTimeSlotMinute, setStartTimeSlotMinute] = useState(0);
  const [endTimeSlotMinute, setEndTimeSlotMinute] = useState(0);

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vh",
    },
  };
  if (roleNum !== 1000) {
    return (
      <FlexContMobileColumn>
        <FullWidthMobileCont100vw100vh>
          <Menu> </Menu>
        </FullWidthMobileCont100vw100vh>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu
            mobilePageProp="Calendar"
            pageProp={"My Calendar"}
            calendarbottom={"4px solid #169ADB"}
          ></ActionMenu>
          <FlexRowContFullWidth>
            <EditAppointment
              currentTimeslotId={slotId}
              appointmentTime={
                startHour +
                ":" +
                startMinute +
                " - " +
                endHour +
                ":" +
                endMinute
              }
              CancelAppointment={HandleCancel}
              onPreview={HandleAppointmentForm}
              onAppointmentEdit={HandleAppointmentUpdated}
              onUpdateDate={HandleAppointmentUpdated}
              installerId={installerId}
              dfName={contactName}
              dfNotes={appDesc}
              dfAddress={address}
              dfCity={city}
              dfCellPhone={phone}
              month={appMonth}
              day={appDay}
              year={appYear}
              dfFlex={flexible}
              open={isEditAppointment}
              onClose={() => {
                setIsEditAppointment(false);
                GetUserAppointmentsAsync();
              }}
            ></EditAppointment>

            <Calendar
              key={appointmentList}
              localizer={localizer}
              events={appointmentList.map((o, i) => {
                // if (appointmentList[0].length > 0) {
                return {
                  key: i,
                  id: o.Id,
                  title: o.Description + " with " + o.Contactname,
                  start: new Date(
                    o.Year,
                    o.Month - 1,
                    o.Day,
                    o.Start.Hour,
                    o.Start.Minute
                  ),
                  end: new Date(
                    o.Year,
                    o.Month - 1,
                    o.Day,
                    o.End.Hour,
                    o.End.Minute
                  ),
                  allDay: false,
                  // }
                };
              })}
              startAccessor="start"
              endAccessor="end"
              // style={{height:"75%", width:"100%"}}
              style={calendarStyle}
              min={new Date(2021, 11, 0, startTimeSlot, startTimeSlotMinute, 0)}
              max={new Date(2021, 11, 0, endTimeSlot, endTimeSlotMinute, 0)}
              longPressThreshold={10}
              views={{ month: true }}
              dayPropGetter={closeDayPropGetter}
              dayLayoutAlgorithm="no-overlap"
              onSelectEvent={(event) => {
                setIsEditAppointment(true);
                appointmentId = event.id;
                setAppointmentIdAsync(event.id);
                GetAppointmentInfo();
              }}
              popup
              onNavigate={(date) => {
                setCalendarMonth(date.getMonth() + 1);
              }}
            />
          </FlexRowContFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexContMobileColumn>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

MyCalendarScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default MyCalendarScreen;
