import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import CreateSchedule from "../../comps/CreateSchedule";
import EditSchedule from "../../comps/EditSchedule";
import Menu from "../../comps/MenuComps/Menu";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const MyScheduleScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // MySchedule Screen - Workers can view their personal schedule on this page
  //=============================================================================

  //=============================================================================
  // Workers can click on their Schedule to view more information about it
  //=============================================================================

  const [isCreateSchedule, setIsCreateSchedule] = useState(false);
  const [isEditSchedule, setIsEditSchedule] = useState(false);
  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;
  const [scheduleList, setScheduleList] = useState([]);
  var userIdSync = 0;
  var scheduleId = 0;

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // USER INFO
  const [userId, setUserId] = useState(0);
  const [name, setName] = useState("");
  const [storeIdOfUser, setStoreIdOfUser] = useState(0);
  const params = useParams();

  const GetSessionInfo = async () => {
    setUserId(params.workerId);
    setName(params.workerName);
    setStoreIdOfUser(params.storeId);
    userIdSync = params.workerId;
    GetUserSchedules();
  };

  const GetUserSchedules = async () => {
    const userId = userIdSync === 0 ? userId : userIdSync;
    const resp = await axios.post(window.$DBURL + "schedule/crew", {
      Year: currentYear,
      Month: currentMonth,
      Id: userId,
    });

    if (
      resp.status === 200 &&
      resp.data !== null &&
      resp.data.schedules !== null
    ) {
      setScheduleList(resp.data.schedules);
    }
  };

  //CURRENT SCHEDULE

  const [appDay, setAppDay] = useState(0);
  const [appMonth, setAppMonth] = useState(0);
  const [appYear, setAppYear] = useState(0);

  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);

  const [contactName, setContactName] = useState("");
  const [appDesc, setAppDesc] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [scheduleIdAsync, setScheduleIdAsync] = useState(0);

  //EDIT
  var nameNew = "";
  var addressNew = "";
  var phoneNew = "";
  var cityNew = "";
  var descriptionNew = "";

  var startHourNew = 0;
  var startMinuteNew = 0;
  var endHourNew = 0;
  var endMinuteNew = 0;

  var yearNew = 0;
  var monthNew = 0;
  var dayNew = 0;

  //NEW
  var nameCreate = "";
  var addressCreate = "";
  var phoneCreate = "";
  var cityCreate = "";
  var descCreate = "";

  var startHourCreate = 0;
  var startMinuteCreate = 0;
  var endHourCreate = 0;
  var endMinuteCreate = 0;

  const GetScheduleInfo = async () => {
    const resp = await axios.get(window.$DBURL + "schedule/" + scheduleId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.schedule !== null) {
          setAppDay(resp.data.schedule.Day);
          setAppMonth(resp.data.schedule.Month);
          setAppYear(resp.data.schedule.Year);

          setAppDesc(resp.data.schedule.Description);
          setContactName(resp.data.schedule.Contactname);
          setAddress(resp.data.schedule.Street);
          setPhone(resp.data.schedule.Phone);
          setCity(resp.data.schedule.City);

          if (resp.data.schedule.Start !== null) {
            setStartHour(resp.data.schedule.Start.Hour);
            setStartMinute(resp.data.schedule.Start.Minute);
          }

          if (resp.data.schedule.End !== null) {
            setEndHour(resp.data.schedule.End.Hour);
            setEndMinute(resp.data.schedule.End.Minute);
          }
        }
      }
    }
  };

  const GetScheduleInfoAsync = async () => {
    try {
      const resp = await axios.get(
        window.$DBURL + "schedule/" + scheduleIdAsync
      );

      if (resp.status === 200 && resp.data && resp.data.schedule) {
        const {
          Day,
          Month,
          Year,
          Start,
          End,
          Description,
          Contactname,
          Street,
          Phone,
          City,
        } = resp.data.schedule;
        setAppDay(Day);
        setAppMonth(Month);
        setAppYear(Year);

        if (Start) {
          setStartHour(Start.Hour);
          setStartMinute(Start.Minute);
        }

        if (End) {
          setEndHour(End.Hour);
          setEndMinute(End.Minute);
        }

        setAppDesc(Description);
        setContactName(Contactname);
        setAddress(Street);
        setPhone(Phone);
        setCity(City);
      }
    } catch (error) {
      // Handle error here
    }
  };

  const HandleScheduleForm = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    nameCreate = contactName;
    addressCreate = address;
    phoneCreate = phone;
    cityCreate = city;
    descCreate = appDesc;

    startHourCreate = openHourSelected;
    startMinuteCreate = openMinuteSelected;
    endHourCreate = closeHourSelected;
    endMinuteCreate = closeMinuteSelected;
  };

  const HandleEditScheduleForm = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    EditYear,
    EditMonth,
    EditDay,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    nameNew = contactName;
    addressNew = address;
    phoneNew = phone;
    cityNew = city;
    descriptionNew = appDesc;
    startHourNew = openHourSelected;
    startMinuteNew = openMinuteSelected;
    endHourNew = closeHourSelected;
    endMinuteNew = closeMinuteSelected;
    yearNew = EditYear;
    monthNew = EditMonth;
    dayNew = EditDay;
  };

  const HandleNewSchedule = async ({
    contactName,
    address,
    phone,
    city,
    appDesc,
    openHourSelected,
    openMinuteSelected,
    closeHourSelected,
    closeMinuteSelected,
  }) => {
    const resp = await axios.post(window.$DBURL + "schedule", {
      Crewname: name,
      Crew_Id: userId,
      Year: year,
      Month: month,
      Day: day,
      Start: { Hour: startHourCreate, Minute: startMinuteCreate },
      End: { Hour: endHourCreate, Minute: endMinuteCreate },
      Description: descCreate,
      Street: addressCreate,
      City: cityCreate,
      Phone: phoneCreate,
      Contactname: nameCreate,
      Store_Id: storeIdOfUser,
    });
    if (resp.status === 200) {
      GetUserSchedules();
    }
  };

  const HandleUpdateSchedule = async () => {
    const resp = await axios.put(window.$DBURL + "schedule", {
      Crewname: name,
      Id: scheduleIdAsync,
      Year: yearNew || year,
      Month: monthNew || month,
      Day: dayNew || day,
      Start: {
        Hour: startHourNew || startHour,
        Minute: startMinuteNew || startMinute,
      },
      End: { Hour: endHourNew || endHour, Minute: endMinuteNew || endMinute },
      Description: descriptionNew || appDesc,
      Street: addressNew || address,
      City: cityNew || city,
      Phone: phoneNew || phone,
      Contactname: nameNew || contactName,
      Store_Id: storeIdOfUser,
    });
    if (resp.status === 200) {
      GetUserSchedules();
      GetScheduleInfoAsync();
    }
  };

  const HandleDeleteSchedule = async () => {
    const resp = await axios.delete(
      window.$DBURL + "schedule/" + scheduleIdAsync
    );
    if (resp.status === 200) {
      GetUserSchedules();
    }
  };

  useEffect(() => {
    GetSessionInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };

  var fullDate = "";
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);

  const HandleStartInfo = async () => {
    setYear(fullDate.getFullYear());
    setMonth(fullDate.getMonth() + 1);
    setDay(fullDate.getDate());
  };

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <CreateSchedule
            onPreview={HandleScheduleForm}
            onScheduleCreate={HandleNewSchedule}
            year={year}
            month={month}
            day={day}
            open={isCreateSchedule}
            onClose={() => {
              setIsCreateSchedule(false);
            }}
          ></CreateSchedule>
          <EditSchedule
            DeleteSchedule={() => {
              HandleDeleteSchedule();
              setIsEditSchedule(false);
            }}
            onPreview={HandleEditScheduleForm}
            onScheduleEdit={HandleUpdateSchedule}
            open={isEditSchedule}
            onClose={() => {
              setIsEditSchedule(false);
            }}
            month={appMonth}
            day={appDay}
            year={appYear}
            dfName={contactName}
            dfAddress={address}
            dfCity={city}
            dfCellPhone={phone}
            dfNotes={appDesc}
            startHour={startHour}
            startMinute={startMinute}
            endHour={endHour}
            endMinute={endMinute}
          ></EditSchedule>
          <LeagueSpartanExtraLargeBold>
            {params.workerName + "'s Personal Schedule"}
          </LeagueSpartanExtraLargeBold>
          <Calendar
            localizer={localizer}
            events={scheduleList.map((o, i) => {
              return {
                id: o.Id,
                title: o.Description,
                start: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.Start.Hour,
                  o.Start.Minute
                ),
                end: new Date(
                  o.Year,
                  o.Month - 1,
                  o.Day,
                  o.End.Hour,
                  o.End.Minute
                ),
                allDay: false,
              };
            })}
            startAccessor="start"
            endAccessor="end"
            style={calendarStyle}
            min={new Date(2021, 11, 0, 8, 0, 0)}
            max={new Date(2021, 11, 0, 18, 0, 0)}
            longPressThreshold={10}
            selectable={true}
            onSelectSlot={(slotInfo) => {
              fullDate = slotInfo.slots[0];
              HandleStartInfo();
              setIsCreateSchedule(true);
            }}
            onSelectEvent={(event) => {
              scheduleId = event.id;
              setScheduleIdAsync(event.id);
              GetScheduleInfo();
              fullDate = event.start;
              HandleStartInfo();
              setIsEditSchedule(true);
            }}
            onNavigate={(date) => {
              GetUserSchedules();
            }}
          />
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

MyScheduleScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default MyScheduleScreen;
