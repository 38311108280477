import { ContPadding10pxMargin20px } from "../../StylesheetComps/Cont";
import { RegularText } from "../../StylesheetComps/Titles";

const ToolCardQuote = ({
  ToolHeadText,
  ToolParaText,
  ToolId,
  ToolUrl,
  EditTool,
  DelTool,
  onView,
  relToolProd,
}) => {
  return (
    <ContPadding10pxMargin20px onClick={onView}>
      <RegularText>{ToolHeadText}</RegularText>
    </ContPadding10pxMargin20px>
  );
};

ToolCardQuote.defaultProps = {
  ToolHeadText: "Excavator",
  ToolParaText: "",
  ToolId: 0,
  ToolUrl:
    "https://cdn.app.compendium.com/uploads/user/a862d463-3ea6-443a-9187-e1040e91eb0e/cb33b26c-b911-43cf-9a4a-0406a7cd3abe/File/7d892f09940824516f16df249bd8bc25/excavator_blog_640x428.jpg",
};

export default ToolCardQuote;
