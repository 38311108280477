import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import * as dates from "date-arithmetic";
import { subDays } from "date-fns";
import getDay from "date-fns/getDay";
import PropTypes from "prop-types";
import { Navigate } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { FlexContCenterAlign } from "../../StylesheetComps/Cont";
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";
import { BlueUnderlinePointerPara } from "../../StylesheetComps/Titles";
import CancelAppointmentWindow from "../Windows/DeleteCancelAppointment";

const ItemCont = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 30%;
  width: 500px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1006px) {
    left: 0%;
    width: 98.5vw;
    height: 90vh;
    overflow-y: auto;
    margin: 0px;
  }
`;

const AppointmentCreationDiv = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
`;

const SubHeaderPara = styled.p`
  font-family: Futura;
  font-size: 16px;
  width: 50%;
  display: ${(props) => (props.textDisplay ? props.textDisplay : "block")};
`;

const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1%;
`;

const RadioDiv = styled.div`
  display: flex;
`;

const RadioButtonInnerDiv = styled.div`
  margin-right: 5%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;

const LandscapePara = styled.textarea`
  width: 60%;
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
  transition: display 2s;
  height: 20vh;
  background-color: #ededed;
  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  border-radius: 4px;
  font-family: Futura;
  font-size: 14px;
  padding: 10px;
  padding-right: 40px;
  margin-right: 30px;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin-top: auto;
  margin-bottom: auto;
`;

const DatePickDiv = styled.div`
  display: ${(props) => (props.datedisplay ? props.datedisplay : "none")};
`;

const ExplainerPara = styled.p`
  font-size: 14px;
  color: black;
  font-family: Avenir;
  margin-left: 10px;
  display: ${(props) => (props.displayProp ? props.displayProp : "none")};
`;

const TimeSlotDiv = styled.div`
  display: ${(props) =>
    props.timeslotDisplay ? props.timeslotDisplay : "none"};
  width: 100%;
  align-items: center;
`;

const CancelAppointmentText = styled.p`
  font-size: 14px;
  color: red;
  position: relative;
  cursor: pointer;
`;

const QuestionMarkImg = styled.img`
  width: 15px;
  height: 25px;
  margin-bottom: auto;
  cursor: pointer;
  padding: 5px;
`;

const EditAppointment = ({
  open,
  onClick,
  onClose,
  onPreview,
  onAppointmentEdit,
  currentTimeslotId,
  onUpdateDate,
  CancelAppointment,
  month,
  day,
  year,
  dfName,
  dfNotes,
  dfAddress,
  dfCellPhone,
  dfCity,
  dfFlex,
  installerId,
  appointmentTime,
}) => {
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  var flexible = dfFlex;

  const [closeDays, setCloseDays] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [slotId, setSlotId] = useState(0);
  const [appDesc, setAppDesc] = useState("");

  const [notesDisplay, setNotesDisplay] = useState("none");
  const [nameDisplay, setNameDisplay] = useState("none");
  const [addressDisplay, setAddressDisplay] = useState("none");
  const [cityDisplay, setCityDisplay] = useState("none");
  const [phoneDisplay, setPhoneDisplay] = useState("none");

  const [timeslotDisplay, setTimeslotDisplay] = useState("none");

  const [notesDisplayText, setNotesDisplayText] = useState("block");
  const [nameDisplayText, setNameDisplayText] = useState("block");
  const [addressDisplayText, setAddressDisplayText] = useState("block");
  const [cityDisplayText, setCityDisplayText] = useState("block");
  const [phoneDisplayText, setPhoneDisplayText] = useState("block");

  const [timeTextDisplay, setTimeTextDisplay] = useState("block");

  const [timeSlotInfoDisplay, setTimeSlotInfoDisplay] = useState("none");

  const [edit1, setEdit1] = useState("Edit");
  const [edit2, setEdit2] = useState("Edit");
  const [edit3, setEdit3] = useState("Edit");
  const [edit4, setEdit4] = useState("Edit");
  const [edit5, setEdit5] = useState("Edit");
  const [editTime, setEditTime] = useState("Edit");

  const [defaultNotes, setDefaultNotes] = useState(dfNotes);
  const [defaultContact, setDefaultContact] = useState(dfName);
  const [defaultAddress, setDefaultAddress] = useState(dfAddress);
  const [defaultCity, setDefaultCity] = useState(dfCity);
  const [defaultPhone, setDefaultPhone] = useState(dfCellPhone);

  const [flexibleDisplayProp, setFlexibleDisplayProp] = useState("none");

  const [cancelAppointment, setCancelAppointment] = useState(false);

  var [type1] = useState();
  var [type2] = useState();

  const GetInstallerCloseDays = async () => {
    const resp = await axios.get(
      window.$DBURL + "appointment/close/installer/" + installerId
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.closedays !== null) {
          setCloseDays(resp.data.closedays);
        }
      }
    }
  };

  const GetTimeSlots = async () => {
    const resp = await axios.post(
      window.$DBURL + "appointment/installer/available",
      { Year: year, Month: month, Day: day, Installer_Id: installerId }
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.openslots !== null) {
          setTimeSlots(resp.data.openslots);
        }
      }
    }
  };

  useEffect(() => {
    GetInstallerCloseDays();
  }, [installerId]); // eslint-disable-line react-hooks/exhaustive-deps

  //DOCUMENTATION OF FLEXIBILITY TYPE
  switch (dfFlex) {
    case true:
      type1 = true;

      break;
    case false:
      type2 = true;
      break;

    default:
      type1 = true;
  }

  class MyWeek extends React.Component {
    render() {
      let {
        date,
        localizer,
        min = localizer.startOf(new Date(), "day"),
        max = localizer.endOf(new Date(), "day"),
        scrollToTime = localizer.startOf(new Date(), "day"),
      } = this.props;
      let range = MyWeek.range(date, { localizer });

      return (
        <TimeGrid
          {...this.props}
          range={range}
          eventOffset={15}
          localizer={localizer}
          min={min}
          max={max}
          scrollToTime={scrollToTime}
        />
      );
    }
  }

  MyWeek.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    localizer: PropTypes.any,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    scrollToTime: PropTypes.instanceOf(Date),
  };

  MyWeek.range = (date, { localizer }) => {
    let start = date;
    let end = dates.add(start, 0, "day");

    let current = start;
    let range = [];

    while (localizer.lte(current, end, "day")) {
      range.push(current);
      current = localizer.add(current, 1, "day");
    }

    return range;
  };

  MyWeek.navigate = (date, action, { localizer }) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return localizer.add(date, -1, "day");

      case Navigate.NEXT:
        return localizer.add(date, 1, "day");

      default:
        return date;
    }
  };

  MyWeek.title = (date) => {
    return `Installer Appointment Slots: ${date.toLocaleDateString()}`;
  };

  const [EditYear, setEditYear] = useState(0);
  const [EditMonth, setEditMonth] = useState(0);
  const [EditDay, setEditDay] = useState(0);
  const [startDate, setStartDate] = useState(null);
  var startDateSync = null;

  const HandleStartDate = () => {
    setEditDay(startDateSync.getDate());
    setEditMonth(startDateSync.getMonth() + 1);
    setEditYear(startDateSync.getFullYear());
  };

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  const isWeekday = (date) => {
    const day = getDay(date);
    if (closeDays.length === 1) {
      return day !== closeDays[0].WeekDay;
    } else if (closeDays.length === 2) {
      return day !== closeDays[0].WeekDay && day !== closeDays[1].WeekDay;
    } else if (closeDays.length === 3) {
      return (
        day !== closeDays[0].WeekDay &&
        day !== closeDays[1].WeekDay &&
        day !== closeDays[2].WeekDay
      );
    } else if (closeDays.length === 4) {
      return (
        day !== closeDays[0].WeekDay &&
        day !== closeDays[1].WeekDay &&
        day !== closeDays[2].WeekDay &&
        day !== closeDays[3].WeekDay
      );
    } else if (closeDays.length === 5) {
      return (
        day !== closeDays[0].WeekDay &&
        day !== closeDays[1].WeekDay &&
        day !== closeDays[2].WeekDay &&
        day !== closeDays[3].WeekDay &&
        day !== closeDays[4].WeekDay
      );
    } else if (closeDays.length === 6) {
      return (
        day !== closeDays[0].WeekDay &&
        day !== closeDays[1].WeekDay &&
        day !== closeDays[2].WeekDay &&
        day !== closeDays[3].WeekDay &&
        day !== closeDays[4].WeekDay &&
        day !== closeDays[5].WeekDay
      );
    } else if (closeDays.length === 7) {
      return (
        day !== closeDays[0].WeekDay &&
        day !== closeDays[1].WeekDay &&
        day !== closeDays[2].WeekDay &&
        day !== closeDays[3].WeekDay &&
        day !== closeDays[4].WeekDay &&
        day !== closeDays[5].WeekDay &&
        day !== closeDays[6].WeekDay
      );
    }
  };

  useEffect(() => {
    GetTimeSlots();
  }, [day, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultNotes(dfNotes);
    setDefaultContact(dfName);
    setDefaultAddress(dfAddress);
    setDefaultCity(dfCity);
    setDefaultPhone(dfCellPhone);
  }, [dfNotes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultContact(dfName);
  }, [dfName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultAddress(dfAddress);
  }, [dfAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultCity(dfCity);
  }, [dfCity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDefaultPhone(dfCellPhone);
  }, [dfCellPhone]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <ItemCont>
        <AppointmentCreationDiv>
          <CancelAppointmentText
            onClick={() => {
              setCancelAppointment(true);
            }}
          >
            Cancel Appointment
          </CancelAppointmentText>

          <FlexContCenterAlign>
            <SubHeaderPara>Schedule Date/Time</SubHeaderPara>
            <HeaderDiv>
              <SubHeaderPara textDisplay={timeTextDisplay}>
                {month}/{day}/{year}
              </SubHeaderPara>
              <SubHeaderPara textDisplay={timeTextDisplay}>
                {appointmentTime}
              </SubHeaderPara>
              <BlueUnderlinePointerPara
                onClick={() => {
                  if (editTime === "Edit") {
                    GetTimeSlots();
                    setTimeslotDisplay("flex");
                    setTimeTextDisplay("block");
                    setEditTime("Save");
                  } else if (
                    EditMonth !== month ||
                    EditDay !== day ||
                    EditYear !== year
                  ) {
                    GetTimeSlots();
                    setTimeslotDisplay("none");
                    setTimeTextDisplay("block");
                    setEditTime("Edit");
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                    });
                    onUpdateDate(
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay
                    );
                    setTimeSlotInfoDisplay("none");
                  } else {
                    GetTimeSlots();
                    setTimeslotDisplay("none");
                    setTimeTextDisplay("block");
                    setEditTime("Edit");
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                    });
                    onAppointmentEdit(
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay
                    );
                  }
                }}
              >
                {editTime}
              </BlueUnderlinePointerPara>
            </HeaderDiv>
          </FlexContCenterAlign>

          <FlexContCenterAlign>
            <TimeSlotDiv timeslotDisplay={timeslotDisplay}>
              <SubHeaderPara>Change Time to:</SubHeaderPara>
              <QuestionMarkImg
                src="/questionmark.svg"
                onClick={() => {
                  if (timeSlotInfoDisplay === "none") {
                    setTimeSlotInfoDisplay("block");
                  } else if (timeSlotInfoDisplay === "block") {
                    setTimeSlotInfoDisplay("none");
                  }
                }}
              ></QuestionMarkImg>
              <select
                id="timeslots"
                onChange={(e) => {
                  if (e.target.value === 0 || e.target.value === "0") {
                    setSlotId(currentTimeslotId);
                  } else {
                    setSlotId(e.target.value);
                  }
                }}
              >
                <option value={0}>Select Timeslot</option>
                {timeSlots.map((o, i) => {
                  if (o.Start_Minute === 0 && o.End_Minute === 0) {
                    return (
                      <option value={o.Id}>
                        {o.Start_Hour +
                          ":" +
                          o.Start_Minute +
                          0 +
                          " - " +
                          o.End_Hour +
                          ":" +
                          o.End_Minute +
                          0}
                      </option>
                    ); // eslint-disable-line prefer-template
                  } else if (o.End_Minute === 0) {
                    return (
                      <option value={o.Id}>
                        {o.Start_Hour +
                          ":" +
                          o.Start_Minute +
                          " - " +
                          o.End_Hour +
                          ":" +
                          o.End_Minute +
                          0}
                      </option>
                    );
                  } else if (o.Start_Minute === 0) {
                    return (
                      <option value={o.Id}>
                        {o.Start_Hour +
                          ":" +
                          o.Start_Minute +
                          0 +
                          " - " +
                          o.End_Hour +
                          ":" +
                          o.End_Minute}
                      </option>
                    ); // eslint-disable-line prefer-template
                  } else {
                    return (
                      <option value={o.Id}>
                        {o.Start_Hour +
                          ":" +
                          o.Start_Minute +
                          " - " +
                          o.End_Hour +
                          ":" +
                          o.End_Minute}
                      </option>
                    );
                  }
                })}
              </select>
            </TimeSlotDiv>
          </FlexContCenterAlign>
          <ExplainerPara displayProp={timeSlotInfoDisplay}>
            If "Select Slot" is the only option available, there are no more
            open timeslots on your date of choice.
          </ExplainerPara>
          <FlexContCenterAlign>
            <TimeSlotDiv timeslotDisplay={timeslotDisplay}>
              <SubHeaderPara>Change Date to:</SubHeaderPara>
              <DatePickDiv datedisplay={timeslotDisplay}>
                <DatePicker
                  style={{ marginRight: "10px" }}
                  selected={startDate}
                  filterDate={isWeekday}
                  onChange={(date) => {
                    setStartDate(date);
                    startDateSync = date;
                    HandleStartDate();
                  }}
                  dateFormat="MM/dd/yyyy"
                  minDate={subDays(new Date(), 0)}
                  timeClassName={handleColor}
                  placeholderText="Click to select a date"
                  showMonthDropdown
                ></DatePicker>
              </DatePickDiv>
            </TimeSlotDiv>
          </FlexContCenterAlign>

          <FlexContCenterAlign>
            <SubHeaderPara>
              Flexible?
              <QuestionMarkImg
                src="/questionmark.svg"
                onClick={() => {
                  if (flexibleDisplayProp === "none") {
                    setFlexibleDisplayProp("block");
                  } else if (flexibleDisplayProp === "block") {
                    setFlexibleDisplayProp("none");
                  }
                }}
              ></QuestionMarkImg>
            </SubHeaderPara>
            <CancelAppointmentWindow
              deleteOpen={cancelAppointment}
              closeDel={() => {
                setCancelAppointment(false);
              }}
              onDeleteFunction={() => {
                setCancelAppointment(false);
                onClose();
                CancelAppointment();
              }}
            ></CancelAppointmentWindow>
            <RadioDiv>
              <RadioButtonInnerDiv>
                <input
                  type="radio"
                  id="yes"
                  name="flexible"
                  defaultChecked={type1}
                  onChange={() => {
                    flexible = true;
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                    });
                    onAppointmentEdit(
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay
                    );
                  }}
                />
                <label htmlFor="yes">Yes</label>
                <br />
                <input
                  type="radio"
                  id="no"
                  name="flexible"
                  defaultChecked={type2}
                  onChange={() => {
                    flexible = false;
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay,
                    });
                    onAppointmentEdit(
                      contactName,
                      address,
                      phone,
                      city,
                      flexible,
                      slotId,
                      appDesc,
                      EditYear,
                      EditMonth,
                      EditDay
                    );
                  }}
                />
                <label htmlFor="no">No</label>
                <br />
              </RadioButtonInnerDiv>
            </RadioDiv>
          </FlexContCenterAlign>
          <ExplainerPara displayProp={flexibleDisplayProp}>
            If Flexible is set to Yes, then your appointment will be moved to an
            earlier time automatically when other people cancel their
            appointments.
          </ExplainerPara>
          <FlexContCenterAlign>
            <SubHeaderPara>Notes for Meeting</SubHeaderPara>
            <SubHeaderPara textDisplay={notesDisplayText}>
              {dfNotes}
            </SubHeaderPara>
            <div key={defaultNotes}>
              <LandscapePara
                maxLength="250"
                defaultValue={defaultNotes}
                inputdisplay={notesDisplay}
                onChange={(e) => {
                  setAppDesc(e.target.value);
                }}
              ></LandscapePara>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit1 === "Edit") {
                  setNotesDisplay("block");
                  setNotesDisplayText("none");
                  setEdit1("Save");
                } else {
                  setNotesDisplay("none");
                  setNotesDisplayText("block");
                  setEdit1("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                  });
                  onAppointmentEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay
                  );
                }
              }}
            >
              {edit1}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Contact Name</SubHeaderPara>
            <SubHeaderPara textDisplay={nameDisplayText}>
              {dfName}
            </SubHeaderPara>
            <div key={defaultContact}>
              <TextInputProp
                defaultValue={defaultContact}
                inputdisplay={nameDisplay}
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              ></TextInputProp>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit2 === "Edit") {
                  setNameDisplay("block");
                  setNameDisplayText("none");
                  setEdit2("Save");
                } else {
                  setNameDisplay("none");
                  setNameDisplayText("block");
                  setEdit2("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                  });
                  onAppointmentEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay
                  );
                }
              }}
            >
              {edit2}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Address</SubHeaderPara>
            <SubHeaderPara textDisplay={addressDisplayText}>
              {dfAddress}
            </SubHeaderPara>
            <div key={defaultAddress}>
              <TextInputProp
                defaultValue={defaultAddress}
                inputdisplay={addressDisplay}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              ></TextInputProp>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit3 === "Edit") {
                  setAddressDisplay("block");
                  setAddressDisplayText("none");
                  setEdit3("Save");
                } else {
                  setAddressDisplay("none");
                  setAddressDisplayText("block");
                  setEdit3("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                  });
                  onAppointmentEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay
                  );
                }
              }}
            >
              {edit3}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>City</SubHeaderPara>
            <SubHeaderPara textDisplay={cityDisplayText}>
              {dfCity}
            </SubHeaderPara>
            <div key={defaultCity}>
              <TextInputProp
                defaultValue={defaultCity}
                inputdisplay={cityDisplay}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              ></TextInputProp>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit4 === "Edit") {
                  setCityDisplay("block");
                  setCityDisplayText("none");
                  setEdit4("Save");
                } else {
                  setCityDisplay("none");
                  setCityDisplayText("block");
                  setEdit4("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                  });
                  onAppointmentEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay
                  );
                }
              }}
            >
              {edit4}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <FlexContCenterAlign>
            <SubHeaderPara>Cell Phone</SubHeaderPara>
            <SubHeaderPara textDisplay={phoneDisplayText}>
              {dfCellPhone}
            </SubHeaderPara>
            <div key={defaultPhone}>
              <TextInputProp
                defaultValue={defaultPhone}
                inputdisplay={phoneDisplay}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></TextInputProp>
            </div>
            <BlueUnderlinePointerPara
              onClick={() => {
                if (edit5 === "Edit") {
                  setPhoneDisplay("block");
                  setPhoneDisplayText("none");
                  setEdit5("Save");
                } else {
                  setPhoneDisplay("none");
                  setPhoneDisplayText("block");
                  setEdit5("Edit");
                  onPreview({
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay,
                  });
                  onAppointmentEdit(
                    contactName,
                    address,
                    phone,
                    city,
                    flexible,
                    slotId,
                    appDesc,
                    EditYear,
                    EditMonth,
                    EditDay
                  );
                }
              }}
            >
              {edit5}
            </BlueUnderlinePointerPara>
          </FlexContCenterAlign>
          <ButtonDiv>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setNotesDisplay("none");
                setNameDisplay("none");
                setAddressDisplay("none");
                setCityDisplay("none");
                setPhoneDisplay("none");
                setNotesDisplayText("block");
                setNameDisplayText("block");
                setAddressDisplayText("block");
                setCityDisplayText("block");
                setPhoneDisplayText("block");
                setEdit1("Edit");
                setEdit2("Edit");
                setEdit3("Edit");
                setEdit4("Edit");
                setEdit5("Edit");
              }}
            >
              Close
            </RegularResponsiveButton>
          </ButtonDiv>
        </AppointmentCreationDiv>
      </ItemCont>
    </FixedOverlay200vh>
  );
};

EditAppointment.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onAppointmentEdit: () => {},
  CancelAppointment: () => {},
  onUpdateDate: () => {},
  month: 0,
  year: 0,
  day: 0,
  dfName: "",
  dfAddress: "",
  dfCity: "",
  dfCellPhone: "",
  dfFlex: false,
  dfNotes: "",
  installerId: 0,
  appointmentTime: "",
  currentTimeslotId: 0,
};

export default EditAppointment;
