// React import
import React, { useEffect, useState } from "react";

// Import of useParams hook
import { useParams } from "react-router-dom";

// TextInputProp imported from the "AuthSheet" stylesheet
import { TextInputProp } from "../../StylesheetComps/AuthSheet";

// FlexRowContFullWidth imported from the "Cont" stylesheet
import { FlexRowContFullWidth } from "../../StylesheetComps/Cont";

// BlueUnderlinePointerPara and RegularText imported from the "Titles" stylesheet
import {
  BlueUnderlinePointerPara,
  RegularText,
} from "../../StylesheetComps/Titles";

// UserRole imported from the "UserRoles" stylesheet
import { UserRole } from "../../StylesheetComps/UserRoles";

const ClockList = ({
  indexNum,
  clockin,
  clockout,
  adjclockout,
  adjclockin,
  year,
  month,
  day,
  onUpdateAdjClock,
  onPreview,
  onSetId,
}) => {
  const [editAdjClockIn, setEditAdjClockIn] = useState("Edit");
  const [editAdjClockOut, setEditAdjClockOut] = useState("Edit");

  const [adjInDisplay, setAdjInDisplay] = useState("none");
  const [adjOutDisplay, setAdjOutDisplay] = useState("none");

  const [adjInTextDisplay, setAdjInTextDisplay] = useState("block");
  const [adjOutTextDisplay, setAdjOutTextDisplay] = useState("block");

  const [adjClockInValue, setAdjClockInValue] = useState("");
  const [adjClockOutValue, setAdjClockOutValue] = useState("");

  const [clockOutValueHere, setClockOutValueHere] = useState("");
  const [clockInValueHere, setClockInValueHere] = useState("");
  const [adjClockOutValueHere, setAdjClockOutValueHere] = useState("");
  const [adjClockInValueHere, setAdjClockInValueHere] = useState("");

  const params = useParams();

  useEffect(() => {
    if (clockout == null) {
      setClockOutValueHere("");
      setEditAdjClockOut("");
    } else {
      setClockOutValueHere(clockout);
    }

    if (clockin == null) {
      setClockInValueHere("");
      setEditAdjClockIn("");
    } else {
      setClockInValueHere(clockin);
    }

    if (adjclockin == null) {
      setAdjClockInValueHere("");
    } else {
      setAdjClockInValueHere(adjclockin);
    }

    if (adjclockout == null) {
      setAdjClockOutValueHere("");
    } else {
      setAdjClockOutValueHere(adjclockout);
    }
  }, [clockout, clockin, adjclockout, adjclockin]);

  if (params.roleNum === UserRole.Worker) {
    return (
      <FlexRowContFullWidth>
        <FlexRowContFullWidth>
          <RegularText>{indexNum}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{month + "/" + day + "/" + year}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{clockInValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{clockOutValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{adjClockInValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{adjClockOutValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>
      </FlexRowContFullWidth>
    );
  } else {
    return (
      <FlexRowContFullWidth>
        <FlexRowContFullWidth>
          <RegularText>{indexNum}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{month + "/" + day + "/" + year}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{clockInValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText>{clockOutValueHere.substring(11, 16)}</RegularText>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText textdisplay={adjInTextDisplay}>
            {adjClockInValueHere.substring(11, 16)}
          </RegularText>
          <TextInputProp
            inputdisplay={adjInDisplay}
            defaultValue={adjClockInValueHere.substring(11, 16)}
            onChange={(e) => {
              setAdjClockInValue(e.target.value);
            }}
          ></TextInputProp>
          <BlueUnderlinePointerPara
            onClick={() => {
              if (editAdjClockIn === "Edit") {
                setEditAdjClockIn("Save");
                setAdjInTextDisplay("none");
                setAdjInDisplay("block");
                onSetId();
              } else {
                setEditAdjClockIn("Edit");
                setAdjInTextDisplay("block");
                setAdjInDisplay("none");
                onPreview({ adjClockInValue });
                onUpdateAdjClock(adjClockInValue);
                onSetId();
              }
            }}
          >
            {editAdjClockIn}
          </BlueUnderlinePointerPara>
        </FlexRowContFullWidth>

        <FlexRowContFullWidth>
          <RegularText textdisplay={adjOutTextDisplay}>
            {adjClockOutValueHere.substring(11, 16)}
          </RegularText>
          <TextInputProp
            defaultValue={adjClockOutValueHere.substring(11, 16)}
            inputdisplay={adjOutDisplay}
            onChange={(e) => {
              setAdjClockOutValue(e.target.value);
            }}
          ></TextInputProp>
          <BlueUnderlinePointerPara
            onClick={() => {
              if (editAdjClockOut === "Edit") {
                setEditAdjClockOut("Save");
                setAdjOutTextDisplay("none");
                setAdjOutDisplay("block");
                onSetId();
              } else {
                setEditAdjClockOut("Edit");
                setAdjOutTextDisplay("block");
                setAdjOutDisplay("none");
                onPreview({ adjClockOutValue });
                onUpdateAdjClock(adjClockOutValue);
                onSetId();
              }
            }}
          >
            {editAdjClockOut}
          </BlueUnderlinePointerPara>
        </FlexRowContFullWidth>
      </FlexRowContFullWidth>
    );
  }
};

ClockList.defaultProps = {
  ManagerName: "Default Name",
  onPreview: () => {},
  onUpdateAdjClock: () => {},
  onSetId: () => {},
  StoreLocationName: "123 Yonge Street",
  roleID: 0,
  MetroName: "Toronto",
  Username: "",
  Email: "",
  Phone: "",
  workerId: 0,
  storeId: 0,
  indexNum: 0,
  clockin: 0,
  clockout: 0,
  adjclockin: 0,
  adjclockout: 0,
  year: 0,
  month: 0,
  day: 0,
  scheduleId: 0,
};

export default ClockList;
