import React, { useState } from "react";

import { Header } from "../../../StylesheetComps/AuthSheet";
import { SmallHeader } from "../../../StylesheetComps/Menu";
import {
  BodyPara,
  SelectableMediumLongButton,
} from "../../../StylesheetComps/AuthSheet";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import {
  GreenNavigationButton,
  GreyNavigationButton,
  FlexColumnGreenSelectables,
} from "../../../StylesheetComps/LandscapeForm";
import {
  Section1,
  EstimatorDiv,
  ProgressBar,
  ProgressBarWidth,
} from "../../../StylesheetComps/LandscapeForm";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { FlexRowCont70PercentFlexStart } from "../../../StylesheetComps/Cont";
const ProjectScope = ({
  open,
  onNext,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
  onSelectProjectScope,
}) => {
  //Full Renovation UI Update
  const [renovationcolor, setRenovationColor] = useState("black");
  const [renovationborder, setRenovationBorder] = useState("4px solid white");
  const [renovationselected, setRenovationSelected] = useState("");

  //Add-On UI Update
  const [addoncolor, setAddOnColor] = useState("");
  const [addonborder, setAddOnBorder] = useState("");
  const [addonselected, setAddOnSelected] = useState("");

  //Seasonal Cleanup UI Update
  const [seasonalcolor, setSeasonalColor] = useState("");
  const [seasonalborder, setSeasonalBorder] = useState("");
  const [seasonalselected, setSeasonalSelected] = useState("");

  //Other UI Update
  const [othercolor, setOtherColor] = useState("");
  const [otherborder, setOtherBorder] = useState("");
  const [otherselected, setOtherSelected] = useState("");

  const [otherScopeOfWork, setOtherScopeOfWork] = useState("");
  const [displayOtherDiv, setDisplayOtherDiv] = useState("none");

  //Change the UI depending on the selection / deselection of the scope buttons

  const setRenovationBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setRenovationColor("black");
      setRenovationBorder("4px solid white");
      setRenovationSelected("");
    } else {
      setRenovationColor("#169ADB");
      setRenovationBorder("4px solid #169ADB");
      setRenovationSelected("Full Renovation");
    }
  };

  const setAddOnBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setAddOnColor("black");
      setAddOnBorder("4px solid white");
      setAddOnSelected("");
    } else {
      setAddOnColor("#169ADB");
      setAddOnBorder("4px solid #169ADB");
      setAddOnSelected("Add-on");
    }
  };

  const setSeasonalBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setSeasonalColor("black");
      setSeasonalBorder("4px solid white");
      setSeasonalSelected("");
    } else {
      setSeasonalColor("#169ADB");
      setSeasonalBorder("4px solid #169ADB");
      setSeasonalSelected("Seasonal Cleanup");
    }
  };

  const setOtherBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setOtherColor("black");
      setOtherBorder("4px solid white");
      setOtherSelected("");
      setDisplayOtherDiv("none");
    } else {
      setOtherColor("#169ADB");
      setOtherBorder("4px solid #169ADB");
      setOtherSelected("Other:");
      setDisplayOtherDiv("block");
    }
  };

  const DefineProjectScope = async (
    fullRenovation,
    addOn,
    seasonalCleanup,
    other,
    otherScopeOfWork
  ) => {
    let projectscopestring =
      fullRenovation +
      " " +
      addOn +
      " " +
      seasonalCleanup +
      " " +
      other +
      otherScopeOfWork;
    onSelectProjectScope(projectscopestring);
    onNext();
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="50%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Scope
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="0%"
          fontsize="16px"
        >
          Tell us the scope of your project. If your project includes multiple
          services such as landscaping and maintenance, please indicate the
          scope of the space in the "Other" section below.
        </BodyPara>

        <FlexColumnCont
          width="60%"
          mobilewidth="70vw"
          height="auto"
          marginbottom="0%"
        >
          <FlexColumnCont
            width="100%"
            justifycontent="flex-start"
            height="100%"
          >
            <FlexColumnGreenSelectables>
              <SelectableMediumLongButton
                mobilewidth="90%"
                border={renovationborder}
                color={renovationcolor}
                onClick={() => {
                  setRenovationBoxColor(renovationcolor);
                }}
              >
                Full Renovation
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                mobilewidth="90%"
                border={addonborder}
                color={addoncolor}
                onClick={() => {
                  setAddOnBoxColor(addoncolor);
                }}
              >
                Add-on
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                mobilewidth="90%"
                border={seasonalborder}
                color={seasonalcolor}
                onClick={() => {
                  setSeasonalBoxColor(seasonalcolor);
                }}
              >
                Cleanup
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                mobilewidth="90%"
                border={otherborder}
                color={othercolor}
                onClick={() => {
                  setOtherBoxColor(othercolor);
                }}
              >
                Other
              </SelectableMediumLongButton>
            </FlexColumnGreenSelectables>
          </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          display={displayOtherDiv}
          margintop="2%"
          alignitems="start"
          width="55%"
          mobilewidth="90vw"
          mobilejustifycontent="flex-start"
          mobilealignitems="center"
          marginbottom="0"
          height="auto"
        >
          <FlexRowCont70PercentFlexStart>
            <SmallHeader width="auto">
              If selected other, what is the scope of work?
            </SmallHeader>
          </FlexRowCont70PercentFlexStart>
          <TextInput300x50
            inputwidth="84%"
            placeholder={"Scope of Work"}
            inputheight="1%"
            inputborderradius="5px"
            defaultValue={fullName}
            onChange={(e) => {
              setOtherScopeOfWork(e.target.value);
            }}
          ></TextInput300x50>
        </FlexColumnCont>
        <FlexColumnCont
          height="8%"
          margin="0"
          margintop="2%"
          flexdirection="row"
          justifycontent="space-around"
          width="100%"
        >
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              DefineProjectScope(
                renovationselected,
                addonselected,
                seasonalselected,
                otherselected,
                otherScopeOfWork
              );
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectScope.defaultProps = {
  onChange: () => {},
  onSelectProjectScope: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectScope;
