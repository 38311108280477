/* eslint-disable */

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCategory,
  GetProduct,
} from "../../API_Functions/CatProduct_Functions";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import CategoryCard from "../../comps/CategoryCard";
import Menu from "../../comps/MenuComps/Menu";
import ProductAddOn from "../../comps/ProductAddOns";
import ProductCard from "../../comps/ProductCard";
import ProductInfoCard from "../../comps/ProductInfoCard";
import ProductRelation from "../../comps/productRel";
import ProductToolRelation from "../../comps/ProductRelTools";
import CategoryAdd from "../../comps/Windows/CategoryAdd";
import CategoryEdit from "../../comps/Windows/CategoryEdit";
import DeleteCatWindow from "../../comps/Windows/DeleteCat";
import EditSupplierCard from "../../comps/Windows/EditProductSupplier";
import ProductWindow from "../../comps/Windows/ProductWindow";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  DivWithDisplay,
  DivWithMarginPadding,
  FlexColumnCont80x100Percent,
  FlexDiv,
  FlexRowCont55x15Percent,
  FlexRowContFlexStart,
  FlexRowContHalfHeightFlexStart,
  GridDiv,
  Grid_300Div,
  LeftCont,
  OverflowDiv,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { SmallHeaderLeagueSpartan, TextInput100Percent } from "../../StylesheetComps/LandscapeForm";
import { Icon30px20pxPointer, Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanRegular,
  LeagueSpartanRegularMinWidth,
  StyledHRGreenBreak,
  StyledHRGreyBreak,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { InputField } from "../../StylesheetComps/Inputs";
import { FlexColumnDiv } from "../../StylesheetComps/Div";
import styled from "styled-components";
import MoveToFolder from "../../comps/Windows/MoveToFolder";

const IconBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  gap: 16px;
  margin: 5px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  overflow: hidden;
  background-color: ${ColorPalette.LightGrey};
  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
`;

const Icon = styled.img`
  width: 40px;
  height: 25px;
  cursor: pointer;
  
  &:hover + ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;


const ProductListScreen = () => {
  //========================================== ===================================
  // Product List Screen - View Products and Subcategories here.
  //=============================================================================

  //=============================================================================
  // Manager can click to view more information / edit a product or subcat.
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [storeProducts, setStoreProducts] = useState([]);
  const [unfilteredProducts, setUnfilteredProducts] = useState([]);
  const [subCategories, getSubCategories] = useState([]);

  const [productRelations, setProductRelations] = useState([]);
  const [productAddOns, setProductAddOns] = useState([]);
  const [productToolRelations, setProductToolRelations] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [isEditProductOpen, setIsEditProductOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isProductRelOpen, setIsProductRelOpen] = useState(false);
  const [isProductToolRelOpen, setIsProductToolRelOpen] = useState(false);
  const [isProductAddOnRelOpen, setIsProductAddOnRelOpen] = useState(false);

  const [supplierCallSwitch] = useState(0);
  const [productCallSwitch, setProductCallSwitch] = useState(0);

  const [prodListDisplayState, setProdListDisplayState] = useState("grid");

  const [productObj, setProductObj] = useState(null);

  const [catType, setCatType] = useState(0);
  const [catName, setCatName] = useState("");

  const [productNameRel, setProductName] = useState("");

  const [ProdInfoName] = useState("");
  const [ImageUrl, setImageUrl] = useState("");
  const [ProductID, setProductID] = useState(0);
  const [previousCatId, setPreviousCatId] = useState(0);
  const [previousParentId, setPreviousParentId] = useState(0);

  const [editSuppOpen, setEditSuppOpen] = useState(false);

  const [TaxesVar, setTaxesVar] = useState([]);
  const [maxPos] = useState(0);

  const [isAddSubCatOpen, setisAddSubCatOpen] = useState(false);
  const [delCatOpen, setDelCatOpen] = useState(false);

  const [catId, setCatId] = useState(0);
  const [catePos, setCatePos] = useState(0);
  const [isEditCatOpen, setisEditCatOpen] = useState(false);
  const [categoryObj, setCategoryObj] = useState();
  const [getInfo] = useState(true);

  const [roleNum, setRoleNum] = useState(1000);

  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [showFolderOptions, setShowFolderOptions] = useState(false);
  const [showMoveToFolderWindow, setShowMoveToFolderWindow] = useState(false);

  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemName, setSelectedItemName] = useState("");

  var NewRelatedProductId = 0;

  const GetProductInfo = async (product_id) => {
    var data = await GetProduct(product_id);
    setProductObj(data);
  };

  const AddProductForCategory = async (cateid) => {
    setProductID(0);
    var cate = await GetCategory(cateid);
    var data = await GetProduct(0);

    if (data !== null) {
      data.CateType = cate.CateType;
      data.Category_Id = cate.Id;
      setProductObj(data);
    }
  };

  useEffect(() => {
    if (productObj !== null) {
      if (productObj.Id === 0) {
        setIsEditProductOpen(true);
        setIsProductOpen(false);
      }
    }
  }, [productObj]);

  const RefreshProductList = async () => {
    await GetStoreProductsFunc();
  };

  const RefreshProductOnUpdated = async () => {
    await GetStoreProductsFunc();
  };

  const GetProductTaxInfo = async (Id) => {
    var resp = await axios.get(window.$DBURL + "catalog/prod/" + Id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.product !== null) {
          setTaxesVar(resp.data.product.SalesTax);
        }
      }
    }
  };

  const GetRelatedProducts = async (product_id) => {
    if (product_id !== 0) {
      var data = await GetProduct(product_id);
      if (data !== null) {
        setProductRelations(data.RelatedProducts);
      }
      if (isProductRelOpen === false) {
        setIsProductRelOpen(true);
      }
    }
  };

  const GetProductInfoTool = async (related_id) => {
    var resp = await axios.get(window.$DBURL + "catalog/prod/" + related_id);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.product !== null) {
          setProductToolRelations(resp.data.product.RelatedTools);
        }
      }
    }
    setIsProductToolRelOpen(true);
  };

  const GetProductInfoToolAgain = async () => {
    var resp = await axios.get(window.$DBURL + "catalog/prod/" + ProductID);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.product !== null) {
          setProductToolRelations(resp.data.product.RelatedTools);
        }
      }
    }
  };

  const GetProductInfoSupplier = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/" + params.Id + "/supplier"
    );
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.suppliers !== null) {
          setSupplierList(resp.data.suppliers);
        }
      }
    }
    setIsProductOpen(false);
    setEditSuppOpen(true);
  };

  const GetStoreCategoriesFunc = async () => {
    const resp = await axios.get(window.$DBURL + "catalog/" + params.cateId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.cate !== null) {
          getSubCategories(resp.data.cate.SubCategories);
          setCatType(resp.data.cate.CateType);
          setCatName(resp.data.cate.Name);
          setPreviousParentId(resp.data.cate.Parent_Id);
        }
      }
    }
  };

  const GetSingleCat = async (id) => {
    const resp = await axios.get(window.$DBURL + "catalog/" + id);
    if ((resp.status = 200)) {
      var cate = resp.data.cate;
      setCategoryObj(cate);
      setCatePos(cate.Position);
      setisEditCatOpen(true);
    }
  };

  const GetProductsOfSameCategory = async (categoryid) => {
    var data = await GetCategory(categoryid);
    if (data !== null) {
      if (isProductAddOnRelOpen === false) {
        setIsProductAddOnRelOpen(true);
      }
    }
  };

  const HandleNewCat = async (categoryObj) => {
    var resp = await axios.post(window.$DBURL + "catalog", {
      CateType: categoryObj.CateType,
      Description: categoryObj.Description,
      Name: categoryObj.Name,
      Store_Id: params.Id,
      ImageUrl: categoryObj.ImageUrl,
      Position: maxPos + 1,
      Parent_Id: params.cateId,
      Combine: categoryObj.Combine,
      CombineName: categoryObj.CombineName,
    });
    if (resp.status === 200) {
      GetStoreProductsFunc();
      GetStoreCategoriesFunc();
    }
  };

  const DeleteCategoryFunc = async () => {
    const resp = await axios.delete(window.$DBURL + "catalog/" + catId);
    if (resp.status === 200) {
      GetStoreProductsFunc();
      GetStoreCategoriesFunc();
    }
  };

  const GetStoreProductsFunc = async () => {
    var resp = await axios.get(
      window.$DBURL + "catalog/prod/cate/" + params.cateId
    );
    if (resp.status === 200) {
      var product = resp.data.product;
      setStoreProducts(product);
      setUnfilteredProducts(product);
      if (product != null) {
      }
    } else {
      setStoreProducts([]);
    }
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  const HandleDelete = async () => {
    const resp = await axios.delete(
      window.$DBURL + "catalog/prod/" + ProductID
    );
    if (resp.status === 200) {
      GetStoreProductsFunc();
    }
  };

  const HandleRelationId = async ({ relatedProductId }) => {
    NewRelatedProductId = relatedProductId;
    HandleRelationDel();
  };

  const HandleUpdateRelationship = async ({
    RFactorValue,
    PositionValue,
    relatedId,
  }) => {
    UpdateRelationship(ProductID, relatedId, RFactorValue, PositionValue);
  };

  const UpdateRelationship = async (prod_id, related_id, rfactor, position) => {
    const resp = await axios.put(window.$DBURL + "catalog/relation", {
      Id: prod_id,
      Related_Id: related_id,
      RFactor: rfactor,
      Position: position,
    });
    if (resp.status === 200) {
      GetRelatedProducts(ProductID);
    }
  };

  const UpdateRelatedToolInfo = async (value) => {
    const resp = await axios.put(window.$DBURL + "tool/prodtool", {
      Product_Id: ProductID,
      Tool_Id: value.editTriggerId,
      Trigger: value.triggerValue,
      ListAsDelivery: value.checkboxCheck,
    });
    if (resp.status === 200) {
      GetProductInfoToolAgain();
    }
  };

  const HandleRelationDel = async () => {
    const resp = await axios.delete(window.$DBURL + "catalog/relation", {
      headers: { Id: ProductID, Related_Id: NewRelatedProductId },
      data: { Id: ProductID, Related_Id: NewRelatedProductId },
    });
    if (resp.status === 200) {
      GetRelatedProducts(ProductID);
    }
  };

  const HandleRelationIdTool = async ({ relatedProductId }) => {
    NewRelatedProductId = relatedProductId;
    HandleRelationDelTool();
  };

  const HandleRelationDelTool = async () => {
    const resp = await axios.delete(window.$DBURL + "tool/prodtool", {
      headers: { Product_Id: ProductID, Tool_Id: NewRelatedProductId },
      data: { Product_Id: ProductID, Tool_Id: NewRelatedProductId },
    });
    if (resp.status === 200) {
      GetProductInfoToolAgain();
    }
  };

  const FilterProducts = (productlist, text) => {
    if (text === "") {
      setStoreProducts(unfilteredProducts);
    } else {
      setStoreProducts(
        productlist.filter((o) => {
          return o.Name.toLowerCase().includes(text.toLowerCase());
        })
      );
    }
  };

  useEffect(() => {
    SetHeaderAuthorization();
    GetStoreProductsFunc();
    GetStoreCategoriesFunc();
  }, [params.cateId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    SetHeaderAuthorization();
    GetStoreCategoriesFunc();
    GetStoreProductsFunc();
  }, [isEditCatOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(params.productId !== 0 && params.productId !== '0' && ProductID === 0){
        setIsProductOpen(true);
        setProductID(params.productId);
        GetProductInfo(params.productId);
    }
  }, [params.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (roleNum === UserRole.Admin || roleNum === UserRole.Manager) {
    return (
      <FlexDiv>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <RightCont>
          <br></br>
          <RightViewFullWidth >
            <MoveToFolder movedItemObject={selectedItem} movedItemName={selectedItemName}  open={showMoveToFolderWindow} onClose={()=>{setShowMoveToFolderWindow(false)}}></MoveToFolder>
            
            <DivWithDisplay flexdirection="row">
              <Icon30px30pxPointer
                src="/backarrow.svg"
                margin="10px"
                onClick={() => {
                  if (previousParentId === 0) {
                    history.push("/productcategory/" + params.Id);
                  } else {
                    history.push(
                      "/productlist/" + params.Id + "/" + previousParentId+'/0'
                    );
                    setPreviousCatId(previousParentId);
                  }
                }}
              ></Icon30px30pxPointer>
              <SmallHeaderLeagueSpartan width="100px">
                Products
              </SmallHeaderLeagueSpartan>
                <DivWithDisplay flexdirection="row">
                  <RegularResponsiveButton
                    bgcolor={ColorPalette.PrimaryButtonBlue}
                    onClick={() => {
                      AddProductForCategory(params.cateId);
                    }}
                  >
                    Add Product
                  </RegularResponsiveButton>

                  <RegularResponsiveButton
                    bgcolor={ColorPalette.SecondaryGreen}
                    onClick={() => {
                      setisAddSubCatOpen(true);
                    }}
                  >
                    Add Subcategory
                  </RegularResponsiveButton>
              </DivWithDisplay>
            </DivWithDisplay>
            <br></br>
            <InputField
            width='80%'
                  placeholder="Search for a Product"
                  onChange={(e) => {
                    FilterProducts(unfilteredProducts, e.target.value);
                  }}
                ></InputField>
                
                {
  showFolderOptions ? (
    <IconBar>
    <LeagueSpartanRegular>{selectedItemName}</LeagueSpartanRegular>
    <IconWrapper>
      <Icon onClick={() => setShowFolderOptions(false)} src='/close.svg' alt="Close" />
      <Tooltip>Close</Tooltip>
    </IconWrapper>
    <IconWrapper>
      <Icon src='/folder_icon.svg' alt="Open Folder" />
      <Tooltip>Open Folder</Tooltip>
    </IconWrapper>
    <IconWrapper>
      <Icon src='/folder_move_icon.svg' alt="Move Folder" onClick={()=>{setShowMoveToFolderWindow(true)}} />
      <Tooltip>Move Folder</Tooltip>
    </IconWrapper>
    <IconWrapper>
      <Icon src='/edit_document_icon.svg' alt="Edit Document" />
      <Tooltip>Edit Document</Tooltip>
    </IconWrapper>
  </IconBar>  
  ) : null
}

             {/* Add/Edit pop up windows */}
            <DivWithDisplay>
              <EditSupplierCard
                productid={ProductID}
                open={editSuppOpen}
                SupplierArray={supplierList}
                onClose={() => {
                  setEditSuppOpen(false);
                  GetProductInfo(ProductID);
                  setIsProductOpen(true);
                }}
              ></EditSupplierCard>

              <ProductRelation
                open={isProductRelOpen}
                GetRelationsAgain={() => {
                  GetRelatedProducts(ProductID);
                }}
                onRelationId={HandleRelationId}
                StoreID={params.Id}
                UpdateRFactor={HandleUpdateRelationship}
                UpdatePosition={HandleUpdateRelationship}
                RelatedProducts={productRelations}
                ProductName={productNameRel}
                ProductID={ProductID}
                onClose={() => {
                  setProductObj(null);
                  setIsProductRelOpen(false);
                }}
              ></ProductRelation>

              <ProductToolRelation
                UpdateRelatedTool={UpdateRelatedToolInfo}
                open={isProductToolRelOpen}
                GetRelationsAgain={GetProductInfoToolAgain}
                RelatedTools={productToolRelations}
                onRelationId={HandleRelationIdTool}
                StoreID={params.Id}
                RelatedProducts={productRelations}
                ProductName={productNameRel}
                ProductID={ProductID}
                onClose={() => {
                  setProductObj(null);
                  setIsProductToolRelOpen(false);
                }}
              >
                {" "}
              </ProductToolRelation>

              <ProductAddOn
                productObj={productObj}
                open={isProductAddOnRelOpen}
                RefreshAddOnList={() => {
                  GetProductInfo(ProductID);
                }}
                // onRelationId={HandleRelationId}
                StoreID={params.Id}
                // UpdateRFactor={HandleUpdateRelationship}
                // UpdatePosition={HandleUpdateRelationship}
                RelatedProducts={productObj?.Addons}
                ProductName={productNameRel}
                ProductID={ProductID}
                onClose={() => {
                  setProductObj(null);
                  setIsProductAddOnRelOpen(false);
                }}
              ></ProductAddOn>

              <ProductInfoCard
                Product={productObj}
                ProductAdd={supplierCallSwitch}
                EditProd={() => {
                  setIsEditProductOpen(true);
                  setIsProductOpen(false);
                }}
                editSupp={() => {
                  GetProductInfoSupplier();
                }}
                RefreshProductInfoCard={() => {
                  GetProductInfo(ProductID);
                }}
                open={isProductOpen}
                ProductURL={ImageUrl}
                ProductID={ProductID}
                onClose={() => {
                  setProductObj(null);
                  setIsProductOpen(false);
                  RefreshProductList();
                }}
                ProdInfoHead={ProdInfoName}
                onDelete={() => {
                  setIsProductOpen(false);
                  HandleDelete();
                }}
              ></ProductInfoCard>

              <DeleteCatWindow
                deleteOpen={delCatOpen}
                closeDel={() => setDelCatOpen(false)}
                onDeleteFunction={DeleteCategoryFunc}
              ></DeleteCatWindow>

              <ProductWindow
                categoryId={params.cateId}
                updateProp={productCallSwitch}
                open={isEditProductOpen}
                productID={ProductID}
                onUpdated={() => {
                  RefreshProductOnUpdated();
                }}
                onClose={() => {
                  setProductID(0);
                  setProductObj(null);
                  setProductCallSwitch(0);
                  setIsEditProductOpen(false);
                  setIsProductOpen(false);
                }}
                UpdateTaxData={() => {
                  GetProductTaxInfo(ProductID);
                }}
              ></ProductWindow>

              <CategoryAdd
                open={isAddSubCatOpen}
                CatPosProp={catePos}
                parentId={params.cateId}
                onClose={() => {
                  setisAddSubCatOpen(false);
                }}
                onCategoryAdd={HandleNewCat}
              ></CategoryAdd>
              <CategoryEdit
                open={isEditCatOpen}
                category={categoryObj}
                callCategoryAgain={() => {
                  GetStoreProductsFunc();
                  GetStoreCategoriesFunc();
                }}
                onClose={() => {
                  setisEditCatOpen(false);
                }}
              ></CategoryEdit>
            </DivWithDisplay>
            <FlexColumnDiv height='75vh'  margin='25px' onClick={() => {
                setProdListDisplayState("none");
              }}>
            <DivWithDisplay width='auto' flexdirection='column' alignitems="left" display={subCategories.length > 0 ? 'block' : 'none'}>
              <LeagueSpartanRegular>
                {catName}'s Subcategories
              </LeagueSpartanRegular>
              <StyledHRGreenBreak></StyledHRGreenBreak>
              <DivWithDisplay width='85%' overflowx="hidden">
                  {subCategories.map((o, i) => (
                    <CategoryCard
                      key={i}
                      catId={o.Id}
                      CatHeadText={o.Name}
                      categoryId={o.Id}
                      CatName={o.Name}
                      CatParaText={o.Description}
                      CatUrl={o.ImageUrl}
                      onView={() => {
                          setPreviousCatId(o.Parent_Id);
                          setPreviousParentId(o.Parent_Id);
                      }}
                      selectedCategory={(categoryId)=>{setSelectedCategory(o); setSelectedCategoryName(o.Name); setShowFolderOptions(true);}}
                      DelCat={() => {
                        // console.log(o.Name)
                        setDelCatOpen(true);
                        setCatId(o.Id);
                      }}
                      EditCat={() => {
                        setCatId(o.Id);
                        GetSingleCat(o.Id);
                      }}
                    ></CategoryCard>
                  ))}
              </DivWithDisplay>
              </DivWithDisplay>
              <DivWithDisplay width='auto' flexdirection='column' alignitems="left" display={storeProducts.length > 0 ? 'block' : 'none'}>
                <LeagueSpartanRegularMinWidth>
                  {catName}'s Products
                </LeagueSpartanRegularMinWidth>
              <StyledHRGreenBreak></StyledHRGreenBreak>

              <DivWithDisplay  height='100%' overflowy='scroll' flexdirection="column"  width="80vw" >
                {storeProducts.map((o, i) => (               
                    <ProductCard
                      onSelect={()=>{setSelectedItem(o); setSelectedItemName(o.Name); setShowFolderOptions(true)}}
                      key={i}
                      ProdPrice={o.UnitPrice}
                      getInfo={getInfo}
                      relTool={() => {
                        setProductID(o.Id);
                        setProductName(o.Name);
                        GetProductInfoTool(o.Id);
                      }}
                      relProduct={() => {
                        setProductID(o.Id);
                        setProductName(o.Name);
                        GetRelatedProducts(o.Id);
                      }}
                      relAddOn={() => {
                        setProductID(o.Id);
                        setProductName(o.Name);
                        GetProductsOfSameCategory(o.Category_Id);
                        GetProductInfo(o.Id);
                      }}
                      ProdUrl={o.ImageUrl}
                      ProdId={o.Id}
                      ProdHeadText={o.Name}
                      ProdParaText={o.Description}
                      ProdSuppliers={o.Supplier}
                      onView={() => {
                        setIsProductOpen(true);
                        setProductID(o.Id);
                        setImageUrl(o.ImageUrl);
                        GetProductInfo(o.Id);
                      }}
                    ></ProductCard>
                ))}
              </DivWithDisplay>
            </DivWithDisplay>
            </FlexColumnDiv>
          </RightViewFullWidth>
        </RightCont>
      </FlexDiv>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ProductListScreen.defaultProps = {};

export default ProductListScreen;
