import { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { AddItemRelation, DeleteQuoteItemByEstimator } from "../../../API_Functions/CaseQuote_Functions";
import {
  GetCategory,
  GetProduct,
} from "../../../API_Functions/CatProduct_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnLeftAlign,
  FlexColumnLeftAlignMaxHeight,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont100x8PercentSpaceAround,
  FlexRowContFlexStart,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan, TextInputRegular } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { LeagueSpartanLarge, TitleFontSize100PercentPointer } from "../../../StylesheetComps/Titles";
import QuoteItemWindow from "../QuoteItemWindow";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BodyPara } from "../../../StylesheetComps/AuthSheet";
import { Icon30px30pxPointer } from "../../../StylesheetComps/Quote";
import LoadingIndicator from '../../InteractiveLoadingBar'

const StyledOptionWindow = styled.div`
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 300px;
  z-Index: 1000;
  position: relative;
  left: 12.5vw;
  top: 12.5vh;
  display: ${(props) => (props.display ? props.display : "none")};
  .option {
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      background-color: #e7e7e7;
    }
  }
`;

const QuoteAddOnSelection = ({
  open,
  onClose,
  productObj,
  RefreshQuoteItems,
  primaryitemid,
  shapeData,
  siteMapper,
  CloseForPlotting,
  iconCount,
  isAddOnPlottingSelected
}) => {
  const params = useParams();
  const [productlist, setProductList] = useState([]);
  const [productobj, setProductObj] = useState([]);
  const [addonCategory, setAddonCategory] = useState({});

  const [categoryIndex, setCategoryIndex] = useState(0);

  const [materialWindowToggle, setMaterialWindowToggle] = useState(false);
  const [promptWindowToggle, setPromptWindowToggle] = useState('none');

  const [isLoading, setIsLoading] = useState(false);

  const SetProductsofCategory = async (cateid) => {
    if (cateid !== 0) {
      try {
        const data = await GetCategory(cateid);
        if (data && data.Products) {
          setProductList(data.Products);
          setFilteredAddons(data.Products)
        }
      } catch (error) {
        console.error(error);
      }
    };
  }
    

    const GetInformationForProduct = async (productid) => {
      try {
        const data = await GetProduct(productid);
        if (data !== null) {
          setProductObj(data);
          if(siteMapper === true){
          setPromptWindowToggle('block');
          } else {
          setMaterialWindowToggle(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    

    const CreateNewQuoteAddOnItem = async (item, quoteid, primaryitemid) => {
      setIsLoading(true)
      var description = "Add-On Item";
      try {
        var relationdata = await AddItemRelation(
          primaryitemid,
          item.Id,
          description
        );
        if (relationdata !== null) {
          RefreshQuoteItems(item, item.Id);
        }
      } catch (error) {
        setIsLoading(false)
        window.alert(error);
      }
      setIsLoading(false)
    };
    

    const SetAddonCategoryAndProducts = async (addon) => {
      if (addon !== null && addon !== undefined) {
        setAddonCategory(addon);
        SetProductsofCategory(addon.Category_Id);
      }
    };
    

    const ReturnToPreviousAddon = async (addons, index) => {
      if (index > 0 && addons) {
        setCategoryIndex(index - 1);
        SetAddonCategoryAndProducts(addons[index - 1]);
      }
    };
    

  const ContinueToNextAddon = async (addons, index) => {
    if (index < addons.length - 1) {
      setCategoryIndex(index + 1);
      SetAddonCategoryAndProducts(addons[index + 1]);
    }
  };

  const [runningAdOns, setRunningAddOns] = useState([]);
  const [filteredAddons, setFilteredAddons] = useState([]);

  const handleSearch = (searchText) => {
    const filteredData = filteredAddons.filter((o) => {
      return o.Name.toLowerCase().includes(searchText.toLowerCase());
    });
    setFilteredAddons(filteredData);  // Update the state with the filtered data
  };  

  const AddToRunningAddOns = (item) => {
    if (item && item.Name && typeof item.Id !== 'undefined') {
      setRunningAddOns(prevAddOns => [...prevAddOns, { name: item.Name, id: item.Id }]);
    }
  };  

  useEffect(() => {
    if (productObj.Addons !== undefined) {
      SetAddonCategoryAndProducts(productObj?.Addons[0]);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(iconCount > 0 && isAddOnPlottingSelected == true){
      setMaterialWindowToggle(true)
    }
  }, [iconCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  // if(isLoading === true){
  //   return (
  //     <Backdrop style={{ zIndex: 1000 }} open>
  //     <CircularProgress color="inherit" />
  //     </Backdrop>
  //   ) 
  // } else {
  return (
    <FixedOverlay200vh>
      <FlexRowContFlexStart>
        <LeftItemWindowFixed>
          <LoadingIndicator></LoadingIndicator>
          <FlexColumnLeftAlignMaxHeight>
            <div>
            <SmallHeaderLeagueSpartan>
              {addonCategory?.Category}
            </SmallHeaderLeagueSpartan>
            </div>
          </FlexColumnLeftAlignMaxHeight>
          <TextInputRegular placeholder={`Search for Add-ons in ${addonCategory?.Category}`}
          onChange={(e) => {
            const searchText = e.target.value;
            if (!searchText.trim()) {
              // If search text is empty, reset to original data
              setFilteredAddons(productlist);
            } else {
              // Otherwise, filter data
              handleSearch(searchText);
            }
          }}
          ></TextInputRegular>
          <br></br>
          <FlexColumnLeftAlign height='auto' overflowy='scroll'>
            <FlexRowContFlexStart flexdirection="column" display='block'>
                {filteredAddons.map((o, i) => (
                <FlexColumnLeftAlignMaxHeight display='block' key={i}>
                  <TitleFontSize100PercentPointer
                    onClick={() => {
                      GetInformationForProduct(o.Id);
                    }}
                  >
                    {o.Name}
                  </TitleFontSize100PercentPointer>
                </FlexColumnLeftAlignMaxHeight>
              ))}
              <br></br>


          
            </FlexRowContFlexStart>
          </FlexColumnLeftAlign>
          <br></br>

          <LeagueSpartanLarge>Added Items already:</LeagueSpartanLarge>
{
  runningAdOns.length > 0 ? (
    runningAdOns.map((o, i) => (
      <FlexColumnLeftAlignMaxHeight display='block' key={i}>
        <FlexDiv>
        <TitleFontSize100PercentPointer
        >
          {o.name}
        </TitleFontSize100PercentPointer>
        <Icon30px30pxPointer onClick={async () => {
  setIsLoading(true);
  const data = await DeleteQuoteItemByEstimator(o.id);
  if (data === null) {
    // GetProposalInformation(params.quoteId);
    // Update runningAddOns to remove the item with the matching id
    setRunningAddOns(prevAddOns => prevAddOns.filter(item => item.id !== o.id));
    RefreshQuoteItems();
    setIsLoading(false);
  }
}}
 src='/Garbage_Icon.svg'></Icon30px30pxPointer>
        </FlexDiv>
      </FlexColumnLeftAlignMaxHeight>
    ))
  ) : (
    <p>No Items Added Yet</p> // You can style this message as needed
  )
}
          <br></br>
          <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              mobilewidth='25%'
              minwidth='25%'
              onClick={() => {
                setRunningAddOns([])
                setAddonCategory({});
                setCategoryIndex(0);
                onClose();
              }}
              bgcolor={ColorPalette.PrimaryRed}
            >
              Close
            </RegularResponsiveButton>
            <RegularResponsiveButton
            mobilewidth='25%'
            minwidth='25%'
              pointer={categoryIndex === 0 ? "not-allowed" : "pointer"}
              pointerevent={categoryIndex === 0 ? "none" : ""}
              bgcolor={
                categoryIndex === 0
                  ? ColorPalette.SecondaryGrey
                  : ColorPalette.DarkGrey
              }
              onClick={() => {
                ReturnToPreviousAddon(productObj?.Addons, categoryIndex);
              }}
            >
              Back
            </RegularResponsiveButton>
            <RegularResponsiveButton
            mobilewidth='25%'
            minwidth='25%'
              pointer={
                categoryIndex === productObj?.Addons?.length - 1
                  ? "not-allowed"
                  : "pointer"
              }
              pointerevent={
                categoryIndex === productObj?.Addons?.length - 1 ? "none" : ""
              }
              bgcolor={
                categoryIndex === productObj?.Addons?.length - 1
                  ? ColorPalette.TertiaryGrey
                  : ColorPalette.PrimaryButtonBlue
              }
              onClick={() => {
                ContinueToNextAddon(productObj?.Addons, categoryIndex);
              }}
            >
              Next
            </RegularResponsiveButton>
          </FlexRowCont100PercentSpaceBetween>
        </LeftItemWindowFixed>
      </FlexRowContFlexStart>

      <QuoteItemWindow
        iconCount={iconCount}
        itemId={0}
        quoteid={params.quoteId}
        open={materialWindowToggle}
        onClose={(boolean) => {
          if(boolean === true){
            setIsLoading(true)
          }
          setMaterialWindowToggle(false);
        }}
        siteMapper={shapeData?.data?.area !== undefined ? true : false}
        squareFeetSM={iconCount > 0 ? iconCount : shapeData?.data?.area !== undefined ? shapeData.data.area : 0}
        perimeterSM={shapeData?.data?.perimeter !== undefined ? shapeData.data.perimeter : 0}
        productobj={productobj}
        RefreshItemList={(item) => {
          CreateNewQuoteAddOnItem(item, params.quoteId, primaryitemid);
          AddToRunningAddOns(item)
        }}
      ></QuoteItemWindow>

<StyledOptionWindow display={promptWindowToggle}>
  <br></br>
  <FlexRowCont100x8PercentSpaceAround>
  <BodyPara>
        Would you like to plot these on the Map or enter the quantities manually?
      </BodyPara>
  </FlexRowCont100x8PercentSpaceAround>
     
      <br></br>
      <FlexRowCont100PercentSpaceBetween>
        <RegularResponsiveButton bgcolor={ColorPalette.PrimaryBlue} onClick={()=>{
          setPromptWindowToggle('none');
          CloseForPlotting()
        }}>Map Plot</RegularResponsiveButton>
        <RegularResponsiveButton onClick={()=>{
          setPromptWindowToggle('none')
          setMaterialWindowToggle(true); 
        }}>Manual Entry</RegularResponsiveButton>
      </FlexRowCont100PercentSpaceBetween>
      {/* Add more options here */}
    </StyledOptionWindow>
    </FixedOverlay200vh>
  );
};
// };

QuoteAddOnSelection.defaultProps = {
  onClose: () => {},
  CloseForPlotting: () => {},
  RefreshQuoteItems: () => {},
  shapeData:{
    perimeter:0,
    area:0
  },
  isAddOnPlottingSelected: false
};

export default QuoteAddOnSelection;
