/* eslint-disable */

//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Pdf from "react-to-pdf";
import {
  AddInvoice,
  GetCaseById,
  GetInvoiceObject,
  GetInvoicesByStore,
  GetQuoteByIdForUserDisplay,
} from "../../API_Functions/CaseQuote_Functions";
import {
  GetCurrentTime,
  SetHeaderAuthorization,
  UploadFileToCloud,
} from "../../API_Functions/Utility_Functions";
import InvoiceItem from "../../comps/InvoiceItem";
import InvoiceItemCombo from "../../comps/InvoiceItemCombo";
import InvoiceToolItem from "../../comps/InvoiceToolItem";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import { RegularGreenLeagueText } from "../../MobileComponents/Text";
import { SmallTextInput } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FixedIconToTop,
  RegularResponsiveButton,
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import {
  DivWithDisplay,
  FlexColumn81vwLightGrey,
  FlexColumnLeftAlign,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContAutox100PercentMobileColumn,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import {
  DatalistMedium,
  DatalistOption,
  Image300x200,
} from "../../StylesheetComps/LandscapeForm";
import { Cont, ContentContQuote } from "../../StylesheetComps/Projects";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  LeagueSpartanRegular,
  SmallText,
  StyledHRGreenBreakThick,
  StyledHRPropColour,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";

const InvoiceGenerator = () => {
  const history = useHistory();
  const params = useParams();
  const [Quote, setProposal] = useState({});
  const [Project, setProject] = useState({});
  const [toolsmachinesHeader, setToolsMachinesHeader] = useState("none");
  const [removalHeader, setRemovalHeader] = useState("none");
  const [accessDisplay, setAccessDisplay] = useState("none");
  const [generalMaterialsHeader, setGeneralMaterialsHeader] = useState("none");
  const [deliveryHeader, setDeliveryHeader] = useState("none");

  const [invoiceType, setInvoiceType] = useState("supply");
  const [invoiceTypeName, setInvoiceTypeName] = useState("SUPPLY INVOICE");
  const [createInvoice, setCreateInvoice] = useState(false);

  const [showModifyInvoiceButton, setShowModifyInvoiceButton] = useState(true);
  const [showGenerateInvoiceButton, setShowGenerateInvoiceButton] =
    useState(true);
  const [showSaveInvoiceButton, setShowSaveInvoiceButton] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);

  const [showAttachToProjectButton, setShowAttachtoProjectButton] =
    useState(true);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [enterPaid, setEnterPaid] = useState(false);
  const [toTal, setTotal] = useState();
  const [Paid, setPaid] = useState(0.0);
  const [invoiceDate, setInvoiceDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState("0000");
  const [invoicefileMaterial, setInvoicefileMaterial] = useState(
    "invoice_material.pdf"
  );
  const [invoicefileInstall, setInvoicefileInstall] = useState(
    "invoice_install.pdf"
  );
  const ref = React.createRef();

  const [pdfOptions, setPdfOptions] = useState({
    orientation: "portrait",
    unit: "in",
    format: [8.5, 11],
    pagesplit: true,
  });

  var materialSub = 0;
  var installSub = 0;
  var invoiceItem;

  const GetProjectInformation = async (caseid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      await setProject(data.Case);
      return data.Case;
    } else return null;
  };

  const GetProposalInformation = async (quoteid) => {
    var data = await GetQuoteByIdForUserDisplay(quoteid);
    if (data !== null) {
      if (data.quote !== null) {
        return data.quote;
      }
    } else return null;
  };

  const SetUpInvoiceData = async (project, quoteid) => {
    var quote = await GetProposalInformation(quoteid);
    if (quote !== null) {
      setProposal(quote);
      if (toTal === null || toTal === undefined)
        InitializeTotal({ ...quote.Material.Total });
      else InitializeTotal(toTal);
      var mx = await GenerateInvoiceNumber(project);
      setInvoiceNumber(mx.toString());
      var invoicedate = new Date();
      var duedate = new Date();
      duedate.setDate(duedate.getDate() + 7);
      setInvoiceDate(invoicedate.toLocaleDateString());
      setDueDate(duedate.toLocaleDateString());
      SetQuoteDisplay(quote);
    }
  };

  const InitializeTotal = (total) => {
    if (total !== null && total !== undefined) {
      total.Subtotal = 0;
      total.Total = 0;
      if (total.Tax?.length > 0) {
        for (let i = 0; i < total.Tax.length; i++) {
          total.Tax[i].Amount = 0;
        }
      }
      if (toTal === null || toTal === undefined) setTotal(total);
    }
  };
  const SetQuoteDisplay = async (quote) => {
    if (quote != null) {
      if (quote.Material?.Items?.length > 0) setGeneralMaterialsHeader("flex");

      if (quote.Delivery?.Tools?.length > 0) setDeliveryHeader("flex");

      if (quote.Tool?.Tools?.length > 0) setToolsMachinesHeader("flex");

      if (quote.Removal?.Items?.length > 0) setRemovalHeader("flex");

      if (quote.AccessArea?.Items?.length > 0) setAccessDisplay("flex");
    }
  };

  const modifyInvoice = async () => {
    setCreateInvoice(true);
    setShowModifyInvoiceButton(false);
    setShowGenerateInvoiceButton(false);
    setShowCancelButton(true);
    setShowSaveInvoiceButton(true);
    ItemCounts = 0;
  };

  var ItemCounts = 0;
  const AddItemToInvoiceTotal = (total, tax, subtotal) => {
    if (subtotal > 0) ++ItemCounts;

    if (total !== null && total !== undefined) {
      total.Subtotal += subtotal;
      for (let i = 0; i < tax.length; i++) {
        var amt = (tax[i].Rate * subtotal) / 100;
        for (let j = 0; j < total.Tax?.length; j++) {
          if (total.Tax[j].Id === tax[i].Id) {
            total.Tax[j].Amount += amt;
            total.Total += amt;
          }
        }
      }
      total.Total += subtotal;
    }
  };

  const Save = async () => {
    InitializeTotal(toTal);
    setCreateInvoice(false);
    setShowCancelButton(false);
    setShowSaveInvoiceButton(false);
    setShowModifyInvoiceButton(true); //Modify button should be used only once to avoid errors.
    setShowGenerateInvoiceButton(true);
    setShowDownloadButton(false);
  };

  const Cancel = async () => {
    InitializeTotal(toTal);
    setCreateInvoice(false);
    setShowCancelButton(false);
    setShowSaveInvoiceButton(false);
    //setShowModifyInvoiceButton(true);
    setShowGenerateInvoiceButton(true);
  };

  const SetUpOnInvoiceType = async (type, project) => {
    await SetUpInvoiceData(project, params.quoteId);
    var invoicedate = new Date();
    var info =
      project?.Name +
      "_" +
      project?.City +
      "_" +
      invoicedate.toLocaleDateString();
    var file = "invoice_supply_" + info;

    switch (type) {
      case "supply":
        setInvoiceTypeName("SUPPLY INVOICE");
        setInvoicefileMaterial(file);
        break;
      case "install":
        setInvoiceTypeName("INSTALL INVOICE");
        file = "invoice_install_" + info;
        setInvoicefileInstall(file);
        break;
      case "supplyewo":
        setInvoiceTypeName("SUPPLY EWO INVOICE");
        break;
      case "installewo":
        setInvoiceTypeName("INSTALL EWO INVOICE");
        break;
      default:
        break;
    }
    setShowDownloadButton(false);
  };

  const SetupPdfLength = async (quote) => {
    console.log("in setting up pdf length:", ItemCounts);
    if (quote !== null && quote !== undefined) {
      ItemCounts += quote.Tags.length; //each tag will be listed on a line

      if (invoiceType === "install") {
        // install will include other header rows
        if (quote.AccessArea.Items.length > 0) ++ItemCounts;

        if (quote.Tool.Tools.length > 0) ++ItemCounts;

        if (quote.Delivery.Tools.length > 0) ++ItemCounts;

        if (quote.Removal.Items.length > 0) ++ItemCounts;

        if (quote.Labor.Items.length > 0) ++ItemCounts;
      }
      var len = 1;
      if (ItemCounts > 5) len = Math.ceil((ItemCounts + 10) / 10);

      len = 11.1 * len;
      setPdfOptions({
        orientation: "portrait",
        unit: "in",
        format: [8.5, len],
        pagesplit: true,
      });
    }
  };

  const InitialSetup = async (casid) => {
    var project = await GetProjectInformation(casid);
    await SetUpOnInvoiceType(invoiceType, project);
  };

  /* const GenerateInvoiceNumber = async (project) => {
    if (project === null || project === undefined) return 2000;

    var invoices = await GetInvoicesByStore(project.Store_Id);
    if (invoices?.length > 0) {
      var mx = Math.max(...invoices.map((o) => o.Number));
      return ++mx;
    } else {
      return 2000;
    }
  }; */

  const GenerateInvoiceNumber = async (project) => {
    var d = new Date();
    var year = d.getFullYear();
    var month = ("0" + (d.getMonth() + 1)).slice(-2);
    var day = ("0" + d.getDate()).slice(-2);
    var hour = ("0" + d.getHours()).slice(-2);
    var minute = ("0" + d.getMinutes()).slice(-2);
    //var seconds = ("0" + d.getSeconds()).slice(-2);
    var invoicenumber = month + day + hour + minute + "-" + project.Id;

    if (invoiceType === "supply") invoicenumber = "S" + invoicenumber;
    if (invoiceType === "install") invoicenumber = "I" + invoicenumber;

    return invoicenumber;
  };

  const GenerateInvoiceInfo = async (filename, project, tittle, quoteid) => {
    var invoice = await GetInvoiceObject();
    invoice.Case_Id = project.Id;
    invoice.Total = toTal.Total - Paid;
    invoice.File = filename;
    invoice.isPaid = false;
    invoice.Number = await GenerateInvoiceNumber(project);
    invoice.Address = project.Street + ", " + project.City;
    invoice.Upload_Time = await GetCurrentTime();
    invoice.Tittle = tittle;
    invoice.Store_Id = project.Store_Id;
    invoice.Quote_Id = quoteid;

    return invoice;
  };
  const UploadInvoiceInfo = async (invoice) => {
    await AddInvoice(invoice);
  };

  const CreateInvoiceRecord = async (filename, project, tittle, quoteid) => {
    var invoice = await GenerateInvoiceInfo(filename, project, tittle, quoteid);
    await UploadInvoiceInfo(invoice);
  };

  const AttachInvoiceToProject = async (event) => {
    const file = event.target.files[0];
    var tittle = file.name;
    var filename = await UploadFileToCloud(file);
    await CreateInvoiceRecord(filename, Project, tittle, params.quoteId);
  };
  const hiddenFileInput = React.useRef(null);

  const AttachToProject = (event) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    SetUpOnInvoiceType(invoiceType, Project);
  }, [invoiceType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(async () => {
    SetHeaderAuthorization();
    await InitialSetup(params.caseId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (Quote !== null && Quote !== undefined) {
    if (Quote.Material !== null && Quote.Material !== undefined) {
      InitializeTotal(toTal);
      return (
        <DivWithDisplay>
          <DivWithDisplay>
            <DivWithDisplay alignitems="baseline" margin="0">
              <ContentContQuote id="install">
                <DivWithDisplay height="auto">
                  <FlexRowCont100PercentSpaceBetween mobiledisplay="none">
                    <FlexRowContFullWidth>
                      <Icon30px30pxPointer
                        src="/leftarrow.svg"
                        onClick={() => {
                          history.push(
                            `/viewquoteuser/${params.Id}/${params.storeId}/${params.caseId}/${params.quoteId}/${params.numberofquotes}/${params.lastrevision}`
                          );
                        }}
                      ></Icon30px30pxPointer>
                      <LeagueSpartanRegular>
                        Invoice Generator
                      </LeagueSpartanRegular>
                    </FlexRowContFullWidth>
                    <FlexRowContFullWidth width="auto">
                      <DatalistMedium
                        onChange={(e) => {
                          setInvoiceType(e.target.value);
                        }}
                      >
                        <DatalistOption value="supply">
                          Supply Invoice
                        </DatalistOption>
                        <DatalistOption value={"install"}>
                          Install Invoice
                        </DatalistOption>
                        {/* <DatalistOption value={"supplyewo"}>EWO Supply Invoice</DatalistOption>
                        <DatalistOption value={"installewo"}>EWO Install Invoice</DatalistOption>
 */}{" "}
                      </DatalistMedium>
                      <RegularResponsiveButton
                        display={
                          showSaveInvoiceButton === true ? "block" : "none"
                        }
                        width="125px"
                        onClick={() => {
                          Save();
                        }}
                      >
                        Save Invoice
                      </RegularResponsiveButton>
                      <RegularResponsiveButton
                        display={enterPaid === false ? "block" : "none"}
                        width="125px"
                        onClick={() => {
                          setEnterPaid(true);
                        }}
                      >
                        Enter PAID
                      </RegularResponsiveButton>
                      <RegularResponsiveButton
                        display={enterPaid === true ? "block" : "none"}
                        width="125px"
                        onClick={() => {
                          setEnterPaid(false);
                        }}
                      >
                        Save PAID
                      </RegularResponsiveButton>
                      <RegularResponsiveButton
                        display={
                          showModifyInvoiceButton === true ? "block" : "none"
                        }
                        width="125px"
                        bgcolor={ColorPalette.DarkGrey}
                        onClick={() => {
                          InitializeTotal(toTal);
                          modifyInvoice();
                        }}
                      >
                        Modify Invoice
                      </RegularResponsiveButton>
                      {/* <Pdf
                        targetRef={ref}
                        filename={
                          invoiceType === "supply"
                            ? invoicefileMaterial
                            : invoicefileInstall
                        }
                        options={pdfOptions}
                        scale={1.0}                       
                      >
                        {({ toPdf }) => (
                          <RegularResponsiveButton
                            display={
                              showDownloadButton === true ? "block" : "none"
                            }
                            width="125px"
                            onClick={toPdf}
                          >
                            Download Pdf File
                          </RegularResponsiveButton>
                        )}
                      </Pdf> */}

                      <RegularResponsiveButton
                        display={
                          showAttachToProjectButton === true ? "block" : "none"
                        }
                        width="125px"
                        bgcolor={ColorPalette.SecondaryGreen}
                        onClick={(e) => {
                          AttachToProject(e);
                        }}
                      >
                        Attach to Project
                      </RegularResponsiveButton>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={AttachInvoiceToProject}
                        style={{ display: "none" }}
                      />
                    </FlexRowContFullWidth>
                    <br></br>
                    <StyledHRPropColour
                      border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
                    ></StyledHRPropColour>
                    <br></br>
                  </FlexRowCont100PercentSpaceBetween>

                  <DivWithDisplay height="auto" ref={ref} flexdirection="row">
                    <DivWithDisplay width="25px"> </DivWithDisplay>
                    <DivWithDisplay>
                      <br></br> <br></br>
                      <DivWithDisplay>
                        {/* Start of the Invoice Section. Everything Below this will be included in the invoice */}
                        <FlexRowCont100PercentSpaceBetween>
                          <FlexColumnLeftAlign>
                            <LeagueSpartanHeaderSmall>
                              New Rhodes Construction
                            </LeagueSpartanHeaderSmall>
                            <SmallText>12306 McTavish Road</SmallText>
                            <SmallText>Pitt Meadows BC V3Y 1Z1</SmallText>
                            <SmallText>+1 778-700-3598</SmallText>
                            <SmallText>ar@newrhodesconstruction.com</SmallText>
                            <SmallText>www.newrhodesconstruction.com</SmallText>
                            <SmallText>GST/HST Registration No. :</SmallText>
                            <SmallText>739799336RT0001</SmallText>
                          </FlexColumnLeftAlign>
                          <Image300x200
                            height="auto"
                            src="/YHLogoTinyPNG.png"
                          ></Image300x200>
                        </FlexRowCont100PercentSpaceBetween>
                        <br></br>
                        <RegularGreenLeagueText fontsize="24px">
                          {invoiceTypeName}
                        </RegularGreenLeagueText>
                        <br></br>
                        <br></br>
                        <DivWithDisplay flexdirection="row" mobileflexdirection="row">
                          <DivWithDisplay alignitems="left">
                            <LeagueSpartanHeaderSmall>
                              BILL TO
                            </LeagueSpartanHeaderSmall>
                            <SmallText>{Project?.Name}</SmallText>
                            <SmallText>{Project?.Street}</SmallText>
                            <SmallText>{Project?.City}</SmallText>
                          </DivWithDisplay>                       
                          <DivWithDisplay >
                            <FlexDiv>
                              <FlexColumnLeftAlign>
                                <LeagueSpartanHeaderSmall>
                                  INVOICE #
                                </LeagueSpartanHeaderSmall>

                                <LeagueSpartanHeaderSmall>
                                  DATE
                                </LeagueSpartanHeaderSmall>
                                <br></br>
                                <LeagueSpartanHeaderSmall>
                                  DUE DATE
                                </LeagueSpartanHeaderSmall>
                              </FlexColumnLeftAlign>
                              <DivWithDisplay
                                width="20px"
                                heigt="20px"
                              ></DivWithDisplay>
                              <FlexColumnLeftAlign>
                                <SmallText>{invoiceNumber}</SmallText>
                                <SmallText>{invoiceDate}</SmallText>
                                <br></br>
                                <SmallText>{dueDate}</SmallText>
                              </FlexColumnLeftAlign>
                            </FlexDiv>
                          </DivWithDisplay>
                        </DivWithDisplay>
                        <StyledHRPropColour
                          border={`0.5px solid ${ColorPalette.LightGreen}`}
                        ></StyledHRPropColour>
                        {/* Start of the Invoice Items. Items below are conditionally rendered */}
                        {invoiceType === "supply" ? (
                          <DivWithDisplay>
                            <DivWithDisplay>
                              <InvoiceItem
                                isHeader={true}
                                header2="Item"
                                header3="Description"
                                header4=""
                                header5="Quantity"
                                header6="Unit Rate"
                                header7=""
                                header8="Subtotal"
                                bgColor={ColorPalette.White}
                              ></InvoiceItem>
                              <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                            </DivWithDisplay>
                            {Quote.Tags.map((t, j) => (
                              <DivWithDisplay>
                                <DivWithDisplay>
                                  {Quote.Material.Items.filter(
                                    (item) =>
                                      item.Tag_Id == t.Id &&
                                      item.Parent_Id === 0
                                  ).map(
                                    (o, i) => (
                                      (invoiceItem = o),
                                      (materialSub =
                                        invoiceItem.SubtotalWithRelatedItems),
                                      AddItemToInvoiceTotal(
                                        toTal,
                                        invoiceItem.Tax,
                                        materialSub
                                      ),
                                      (
                                        <DivWithDisplay
                                          display={
                                            invoiceItem.Quant > 0
                                              ? "flex"
                                              : "none"
                                          }
                                        >
                                          <InvoiceItem
                                            showRelatedItems={false}
                                            createInvoice={createInvoice}
                                            noChange={invoiceItem.NoChange}
                                            key={i}
                                            quoteItemObj={invoiceItem}
                                            parentid={invoiceItem.Parent_Id}
                                            OutputValue={(value) => {
                                              invoiceItem = value;
                                            }}
                                            header1="Delete"
                                            header2={invoiceItem.Name}
                                            header3={
                                              invoiceItem.Notes == null
                                                ? ""
                                                : invoiceItem.Notes + ""
                                            }
                                            header4={""}
                                            invoiceTypeName={invoiceType}
                                            header5={
                                              invoiceItem.Quant.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) +
                                              " " +
                                              invoiceItem.DisplayUnit
                                            }
                                            header6={
                                              "$ " +
                                              (
                                                materialSub / invoiceItem.Quant
                                              ).toFixed(4)
                                            }
                                            header7={""}
                                            header8={
                                              "$" +
                                              materialSub
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            }
                                            bgColor={ColorPalette.White}
                                          ></InvoiceItem>
                                        </DivWithDisplay>
                                      )
                                    )
                                  )}
                                </DivWithDisplay>
                              </DivWithDisplay>
                            ))}

                            <DivWithDisplay>
                              {/* display={generalMaterialsHeader} */}
                              {Quote.Material.Items.filter(
                                (item) =>
                                  item.Tag_Id === 0 && item.Parent_Id === 0
                              ).length > 0 ? (
                                <DivWithDisplay>
                                  {Quote.Material.Items.map((o, i) => {
                                    return o.Parent_Id === 0 && o.Tag_Id === 0
                                      ? ((invoiceItem = o),
                                        (materialSub =
                                          invoiceItem.SubtotalWithRelatedItems),
                                        AddItemToInvoiceTotal(
                                          toTal,
                                          invoiceItem.Tax,
                                          materialSub
                                        ),
                                        (
                                          <DivWithDisplay
                                            display={
                                              invoiceItem.Quant > 0
                                                ? "flex"
                                                : "none"
                                            }
                                          >
                                            <InvoiceItem
                                              showRelatedItems={false}
                                              key={i}
                                              quoteItemObj={invoiceItem}
                                              createInvoice={createInvoice}
                                              noChange={invoiceItem.NoChange}
                                              parentid={invoiceItem.Parent_Id}
                                              OutputValue={(value) => {
                                                invoiceItem = value;
                                              }}
                                              header1="Delete"
                                              header2={invoiceItem.Name}
                                              header3={
                                                invoiceItem.Notes == null
                                                  ? ""
                                                  : invoiceItem.Notes + ""
                                              }
                                              invoiceTypeName={invoiceType}
                                              header5={
                                                invoiceItem.Quant.toFixed(
                                                  2
                                                ).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                ) +
                                                " " +
                                                invoiceItem.DisplayUnit
                                              }
                                              header6={
                                                "$ " +
                                                (
                                                  materialSub /
                                                  invoiceItem.Quant
                                                ).toFixed(4)
                                              }
                                              header7={""}
                                              header8={
                                                "$" +
                                                materialSub
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )
                                              }
                                              bgColor={ColorPalette.White}
                                            ></InvoiceItem>
                                          </DivWithDisplay>
                                        ))
                                      : null;
                                  })}
                                </DivWithDisplay>
                              ) : null}
                            </DivWithDisplay>

                            <StyledHRPropColour
                              border={`0.5px dotted ${ColorPalette.PrimaryGrey}`}
                            ></StyledHRPropColour>
                            <br></br>
                            <br></br>
                            <DivWithDisplay flexdirection="row">
                              <DivWithDisplay>
                                <TinyTextItalics>
                                  We appreciate your business and hope you
                                  consider us for any future projects!
                                </TinyTextItalics>
                              </DivWithDisplay>
                              <DivWithDisplay width="50%" flexdirection="row">
                                <DivWithDisplay flexdirection="row" mobileflexdirection="row">
                                  <DivWithDisplay textalign="left">
                                    <SmallText>SUBTOTAL</SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        {o.TaxCode} @ {o.Rate}%
                                      </SmallText>
                                    ))}
                                    <SmallText>TOTAL</SmallText>
                                    <SmallText>PAID</SmallText>
                                    <SmallText>BALANCE DUE</SmallText>
                                  </DivWithDisplay>

                                  <DivWithDisplay textalign="right">
                                    <SmallText>
                                      $
                                      {toTal?.Subtotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        $
                                        {o.Amount.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </SmallText>
                                    ))}

                                    <SmallText>
                                      $
                                      {toTal?.Total.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                    <SmallText
                                      display={
                                        createInvoice || enterPaid
                                          ? "none"
                                          : "block"
                                      }
                                    >
                                      ${Paid}
                                    </SmallText>
                                    <SmallTextInput
                                      inputdisplay={
                                        createInvoice || enterPaid
                                          ? "block"
                                          : "none"
                                      }
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value !== "") {
                                          setPaid(e.target.valueAsNumber);
                                        }
                                      }}
                                      defaultValue={0}
                                    ></SmallTextInput>
                                    <LeagueSpartanHeaderSmall>
                                      $
                                      {(toTal?.Total - Paid)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </LeagueSpartanHeaderSmall>
                                  </DivWithDisplay>
                                </DivWithDisplay>
                              </DivWithDisplay>
                            </DivWithDisplay>
                          </DivWithDisplay>
                        ) : null}

                        {invoiceType === "install" ? (
                          <DivWithDisplay>
                            <InvoiceItem
                              isHeader={true}
                              header2="Item"
                              header3="Description"
                              header4=""
                              header5="Quantity"
                              header6="Install Rate"
                              header7=""
                              header8="Subtotal"
                              bgColor={ColorPalette.White}
                            ></InvoiceItem>
                            <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                            {Quote.Tags.map((t, j) => (
                              <DivWithDisplay>
                                <DivWithDisplay>
                                  {Quote.Material.Items.filter(
                                    (item) =>
                                      item.Tag_Id == t.Id &&
                                      item.Parent_Id === 0
                                  ).map(
                                    (o, i) => (
                                      (invoiceItem = o),
                                      (installSub =
                                        invoiceItem.LaborSubtotalWithRelatedItems),
                                      AddItemToInvoiceTotal(
                                        toTal,
                                        invoiceItem.Tax,
                                        installSub
                                      ),
                                      (
                                        <DivWithDisplay
                                          display={
                                            invoiceItem.Quant > 0
                                              ? "flex"
                                              : "none"
                                          }
                                        >
                                          <InvoiceItem
                                            showRelatedItems={false}
                                            noChange={invoiceItem.NoChange}
                                            key={i}
                                            createInvoice={createInvoice}
                                            invoiceTypeName={invoiceType}
                                            quoteItemObj={invoiceItem}
                                            bgColor={ColorPalette.White}
                                            parentid={invoiceItem.Parent_Id}
                                            OutputValue={(value) => {
                                              invoiceItem = value;
                                            }}
                                            header1="Delete"
                                            header2={invoiceItem.Name}
                                            header3={invoiceItem.Notes}
                                            header4={""}
                                            header5={
                                              invoiceItem.Quant.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) +
                                              " " +
                                              invoiceItem.DisplayUnit
                                            }
                                            header6={
                                              "$ " +
                                              (
                                                installSub / invoiceItem.Quant
                                              ).toFixed(4)
                                            }
                                            header7={""}
                                            header8={
                                              "$" +
                                              installSub
                                                .toFixed(2)
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                            }
                                          ></InvoiceItem>
                                        </DivWithDisplay>
                                      )
                                    )
                                  )}
                                </DivWithDisplay>
                              </DivWithDisplay>
                            ))}

                            <DivWithDisplay>
                              {/* display={generalMaterialsHeader} */}

                              {Quote.Material.Items.map((o, i) => {
                                return o.Parent_Id === 0 && o.Tag_Id === 0
                                  ? ((invoiceItem = o),
                                    (installSub =
                                      o.LaborSubtotalWithRelatedItems),
                                    AddItemToInvoiceTotal(
                                      toTal,
                                      invoiceItem.Tax,
                                      installSub
                                    ),
                                    (
                                      <DivWithDisplay
                                        display={
                                          invoiceItem.Quant > 0
                                            ? "flex"
                                            : "none"
                                        }
                                      >
                                        <InvoiceItem
                                          showRelatedItems={false}
                                          key={i}
                                          quoteItemObj={invoiceItem}
                                          createInvoice={createInvoice}
                                          invoiceTypeName={invoiceType}
                                          OutputValue={(value) => {
                                            invoiceItem = value;
                                          }}
                                          noChange={invoiceItem.NoChange}
                                          parentid={invoiceItem.Parent_Id}
                                          bgColor={ColorPalette.White}
                                          header1="Delete"
                                          header2={invoiceItem.Name}
                                          header3={invoiceItem.Notes}
                                          header4={""}
                                          header5={
                                            invoiceItem.Quant.toFixed(
                                              2
                                            ).replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            ) +
                                            " " +
                                            invoiceItem.DisplayUnit
                                          }
                                          header6={
                                            "$ " +
                                            (
                                              installSub / invoiceItem.Quant
                                            ).toFixed(4)
                                          }
                                          header7={""}
                                          header8={
                                            "$" +
                                            installSub
                                              .toFixed(2)
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )
                                          }
                                        ></InvoiceItem>
                                      </DivWithDisplay>
                                    ))
                                  : null;
                              })}
                            </DivWithDisplay>

                            {Quote.Delivery.Tools.map(
                              (o, i) => (
                                (invoiceItem = o),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  invoiceItem.Tax,
                                  invoiceItem.Price
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      invoiceItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <InvoiceToolItem
                                      key={i}
                                      createInvoice={createInvoice}
                                      invoiceTypeName={invoiceType}
                                      quoteItemObj={invoiceItem}
                                      OutputValue={(value) => {
                                        invoiceItem = value;
                                      }}
                                      header1=" "
                                      header2={invoiceItem.ToolName}
                                      header3={invoiceItem.Notes}
                                      header5={invoiceItem.Quant.toFixed(
                                        2
                                      ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      header6={
                                        "$" +
                                        invoiceItem.BasePrice.toFixed(
                                          2
                                        ).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        "/ Load"
                                      }
                                      header8={
                                        "$" +
                                        invoiceItem.Price.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )
                                      }
                                      header7={""}
                                      bgColor={ColorPalette.White}
                                    ></InvoiceToolItem>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.Removal.Items.map(
                              (o, i) => (
                                (invoiceItem = o),
                                (installSub =
                                  invoiceItem.SubtotalWithRelatedItems +
                                  invoiceItem.LaborSubtotalWithRelatedItems),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  invoiceItem.Tax,
                                  installSub
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      invoiceItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <InvoiceItemCombo
                                      showRelatedItems={false}
                                      key={i}
                                      quoteItemObj={invoiceItem}
                                      createInvoice={createInvoice}
                                      invoiceTypeName={invoiceType}
                                      OutputValue={(value) => {
                                        invoiceItem = value;
                                      }}
                                      noChange={invoiceItem.NoChange}
                                      parentid={invoiceItem.Parent_Id}
                                      bgColor={ColorPalette.White}
                                      header1="Delete"
                                      header2={invoiceItem.Name}
                                      header3={invoiceItem.Notes}
                                      header4={""}
                                      header5={
                                        invoiceItem.Quant.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        " " +
                                        invoiceItem.DisplayUnit
                                      }
                                      header6={
                                        "$ " +
                                        (
                                          installSub / invoiceItem.Quant
                                        ).toFixed(4)
                                      }
                                      header7={""}
                                      header8={
                                        "$" +
                                        installSub
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                    ></InvoiceItemCombo>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.AccessArea.Items.map(
                              (o, i) => (
                                (invoiceItem = o),
                                (installSub =
                                  invoiceItem.SubtotalWithRelatedItems +
                                  invoiceItem.LaborSubtotalWithRelatedItems),
                                AddItemToInvoiceTotal(
                                  toTal,
                                  invoiceItem.Tax,
                                  installSub
                                ),
                                (
                                  <DivWithDisplay
                                    display={
                                      invoiceItem.Quant > 0 ? "flex" : "none"
                                    }
                                  >
                                    <InvoiceItemCombo
                                      showRelatedItems={false}
                                      key={i}
                                      quoteItemObj={invoiceItem}
                                      createInvoice={createInvoice}
                                      invoiceTypeName={"supply"} //Access area  is install invoice, but they are product items, so need to use supply
                                      OutputValue={(value) => {
                                        invoiceItem = value;
                                      }}
                                      noChange={invoiceItem.NoChange}
                                      parentid={invoiceItem.Parent_Id}
                                      bgColor={ColorPalette.White}
                                      header1="Delete"
                                      header2={invoiceItem.Name}
                                      header3={invoiceItem.Notes}
                                      header4={""}
                                      header5={
                                        invoiceItem.Quant.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        ) +
                                        " " +
                                        invoiceItem.DisplayUnit
                                      }
                                      header6={
                                        "$ " +
                                        (
                                          installSub / invoiceItem.Quant
                                        ).toFixed(4)
                                      }
                                      header7={""}
                                      header8={
                                        "$" +
                                        installSub
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                    ></InvoiceItemCombo>
                                  </DivWithDisplay>
                                )
                              )
                            )}

                            {Quote.Tool.Tools.map((o, i) =>
                              o.BasePrice > 0.1 || o.RentalRate > 0.1
                                ? ((invoiceItem = o),
                                  AddItemToInvoiceTotal(
                                    toTal,
                                    invoiceItem.Tax,
                                    invoiceItem.Price
                                  ),
                                  (
                                    <DivWithDisplay
                                      display={
                                        invoiceItem.Quant > 0 ? "flex" : "none"
                                      }
                                    >
                                      <InvoiceToolItem
                                        key={i}
                                        createInvoice={createInvoice}
                                        invoiceTypeName={invoiceType}
                                        quoteItemObj={invoiceItem}
                                        OutputValue={(value) => {
                                          invoiceItem = value;
                                        }}
                                        header1=" "
                                        header2={invoiceItem.ToolName}
                                        subheader2={
                                          invoiceItem.Notes !== null
                                            ? invoiceItem.Notes
                                            : ""
                                        }
                                        header3={
                                          "$ " +
                                          invoiceItem.BasePrice.toFixed(
                                            2
                                          ).replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        }
                                        header5={
                                          invoiceItem.RentalRate > 0.1
                                            ? invoiceItem.RentalLength.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) + " hr"
                                            : invoiceItem.Quant.toFixed(
                                                2
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) + " pc"
                                        }
                                        header6={
                                          "$" +
                                          invoiceItem.RentalRate.toFixed(
                                            2
                                          ).replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        }
                                        header8={
                                          "$" +
                                          invoiceItem.Price.toFixed(2).replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                        }
                                        header7={""}
                                        bgColor={ColorPalette.White}
                                      ></InvoiceToolItem>
                                    </DivWithDisplay>
                                  ))
                                : null
                            )}

                            <br></br>
                            <StyledHRPropColour
                              border={`0.5px dotted ${ColorPalette.PrimaryGrey}`}
                            ></StyledHRPropColour>
                            <br></br>
                            <br></br>
                            <FlexRowContFullWidth margintop="100px">
                              <DivWithDisplay>
                                <TinyTextItalics>
                                  We appreciate your business and hope you
                                  consider us for any future projects!
                                </TinyTextItalics>
                              </DivWithDisplay>
                              <DivWithDisplay width="50%" flexdirection="row">
                                <FlexRowCont100PercentSpaceBetween>
                                  <DivWithDisplay textalign="left">
                                    <SmallText>SUBTOTAL</SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        {o.TaxCode} @ {o.Rate}%
                                      </SmallText>
                                    ))}
                                    <SmallText>TOTAL</SmallText>
                                    <SmallText>PAID</SmallText>
                                    <SmallText>BALANCE DUE</SmallText>
                                  </DivWithDisplay>

                                  <DivWithDisplay textalign="right">
                                    <SmallText>
                                      $
                                      {toTal?.Subtotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                    {toTal?.Tax?.map((o, i) => (
                                      <SmallText>
                                        $
                                        {o.Amount.toFixed(2).replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                      </SmallText>
                                    ))}

                                    <SmallText>
                                      $
                                      {toTal?.Total.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </SmallText>
                                    <SmallText
                                      display={
                                        createInvoice || enterPaid
                                          ? "none"
                                          : "block"
                                      }
                                    >
                                      ${Paid}
                                    </SmallText>
                                    <SmallTextInput
                                      inputdisplay={
                                        createInvoice ? "block" : "none"
                                      }
                                      type="number"
                                      onChange={(e) => {
                                        if (e.target.value !== "") {
                                          setPaid(e.target.valueAsNumber);
                                        }
                                      }}
                                      defaultValue={0}
                                    ></SmallTextInput>
                                    <LeagueSpartanHeaderSmall>
                                      $
                                      {(toTal?.Total - Paid)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </LeagueSpartanHeaderSmall>
                                  </DivWithDisplay>
                                </FlexRowCont100PercentSpaceBetween>
                              </DivWithDisplay>
                            </FlexRowContFullWidth>
                          </DivWithDisplay>
                        ) : null}
                      </DivWithDisplay>
                    </DivWithDisplay>
                  </DivWithDisplay>
                </DivWithDisplay>
              </ContentContQuote>
            </DivWithDisplay>
          </DivWithDisplay>

          {/* <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop> */}
        </DivWithDisplay>
      );
    } else {
      return (
        <Cont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    return (
      <Cont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

InvoiceGenerator.defaultProps = {};

export default InvoiceGenerator;
