import React, { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { FixedContHeight120vh, FlexDiv } from "../../../StylesheetComps/Cont";
import { FlexColumnDiv } from "../../../StylesheetComps/Div";
import { TextInput100Percent } from "../../../StylesheetComps/LandscapeForm";
import { OverlayCont300pxAutoHeight } from "../../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanExtraLargeWidth200px,
  RegularTextBoldRed,
} from "../../../StylesheetComps/Titles";

const EditSupplier = ({
  open,
  onClose,
  onChange,
  onFormComplete,
  supplierID,
  onPreview,
  RoleNameProp,
  NameProp,
  EmailProp,
  PhoneProp,
}) => {
  const [error] = useState(null);
  const [roleFullName, setRoleFullName] = useState("");
  const [roleEmail, setRoleEmail] = useState("");
  const [rolePhone, setRolePhone] = useState("");

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedContHeight120vh>
        <OverlayCont300pxAutoHeight>
          <FlexDiv>
            <FlexColumnDiv>
              <LeagueSpartanExtraLargeWidth200px>
                {RoleNameProp} Name
              </LeagueSpartanExtraLargeWidth200px>
              <TextInput100Percent
                defaultValue={NameProp}
                autoComplete="new-password"
                onChange={(e) => {
                  setRoleFullName(e.target.value);
                }}
              ></TextInput100Percent>
              <LeagueSpartanExtraLargeWidth200px>
                Email
              </LeagueSpartanExtraLargeWidth200px>
              <TextInput100Percent
                defaultValue={EmailProp}
                autoComplete="new-password"
                onChange={(e) => {
                  setRoleEmail(e.target.value);
                }}
              ></TextInput100Percent>
              <LeagueSpartanExtraLargeWidth200px>
                Phone
              </LeagueSpartanExtraLargeWidth200px>
              <TextInput100Percent
                defaultValue={PhoneProp}
                autoComplete="new-password"
                onChange={(e) => {
                  setRolePhone(e.target.value);
                }}
              ></TextInput100Percent>

              <RegularTextBoldRed>{error}</RegularTextBoldRed>
              <RegularResponsiveButton
                onClick={() => {
                  onPreview({ roleFullName, roleEmail, rolePhone });
                  onFormComplete(roleFullName, roleEmail, rolePhone);
                  onClose();
                }}
              >
                Update Supplier
              </RegularResponsiveButton>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={onClose}
              >
                Cancel
              </RegularResponsiveButton>
            </FlexColumnDiv>
          </FlexDiv>
        </OverlayCont300pxAutoHeight>
      </FixedContHeight120vh>{" "}
    </>
  );
};

EditSupplier.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
  NameProp: "",
  EmailProp: "",
  PhoneProp: "",
  supplierID: 0,
};

export default EditSupplier;
