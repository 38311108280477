import { useEffect, useState } from "react";
import { GetStoreByMetro } from "../../../API_Functions/Store_Functions";
import {
  GetCityList,
  GetMetroList,
} from "../../../API_Functions/Utility_Functions";
import {
  BodyPara,
  Header,
  TextInput300x50,
} from "../../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnFullWidth,
  FlexRowContFullWidth,
} from "../../../StylesheetComps/Cont";
import {
  GridContainer2Wide,
  GridContainer3Wide,
} from "../../../StylesheetComps/Div";
import {
  EstimatorDiv,
  FlexContBottomGrey,
  FlexInfoCont,
  GreenNavigationButton,
  GreyNavigationButton,
  Image300x200,
  LandscapePara,
  ProgressBar,
  ProgressBarWidth,
  Section1,
  SmallHeaderLeagueSpartan,
  WideFlexInfoCont,
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../../StylesheetComps/Menu";

const ProjectOverview = ({
  open,
  onNext,
  onBack,
  fullName,
  phone,
  email,
  address,
  city,
  metro,
  area,
  scope,
  type,
  style,
  budget,
  description,
  imgs,
  pdfs,
  onSubmitCase,
  onSaveCase,
  onOpenSaveCase,
  onSetCaseInformation,

  contactinfo,
}) => {
  const [imgMap, setImgMap] = useState([]);
  const [PDFMap, setPDFMap] = useState([]);
  const [videoMap] = useState([]);

  //Project Contact

  const [NameDisplay, setNameDisplay] = useState("none");
  const [PhoneDisplay, setPhoneDisplay] = useState("none");
  const [EmailDisplay, setEmailDisplay] = useState("none");
  const [AddressDisplay, setAddressDisplay] = useState("none");
  const [CityDisplay, setCityDisplay] = useState("none");
  const [MetroDisplay, setMetroDisplay] = useState("none");

  const [NameLabelDisplay, setNameLabelDisplay] = useState("block");
  const [PhoneLabelDisplay, setPhoneLabelDisplay] = useState("block");
  const [EmailLabelDisplay, setEmailLabelDisplay] = useState("block");
  const [AddressLabelDisplay, setAddressLabelDisplay] = useState("block");
  const [CityLabelDisplay, setCityLabelDisplay] = useState("block");
  const [MetroLabelDisplay, setMetroLabelDisplay] = useState("block");

  //Project Details

  const [ProjectAreaDisplay, setProjectAreaDisplay] = useState("none");
  const [ProjectScopeDisplay, setProjectScopeDisplay] = useState("none");
  const [ProjectTypeDisplay, setProjectTypeDisplay] = useState("none");
  const [ProjectStyleDisplay, setProjectStyleDisplay] = useState("none");
  const [ProjectBudgetDisplay, setProjectBudgetDisplay] = useState("none");

  const [ProjectAreaLabelDisplay, setProjectAreaLabelDisplay] =
    useState("block");
  const [ProjectScopeLabelDisplay, setProjectScopeLabelDisplay] =
    useState("block");
  const [ProjectTypeLabelDisplay, setProjectTypeLabelDisplay] =
    useState("block");
  const [ProjectStyleLabelDisplay, setProjectStyleLabelDisplay] =
    useState("block");
  const [ProjectBudgetLabelDisplay, setProjectBudgetLabelDisplay] =
    useState("block");

  const [statusButtonText] = useState("Save Project");

  const [editContactText, setEditContactText] = useState("Edit");
  const [editContactBGColor, setEditContactBGColor] = useState(
    ColorPalette.PrimaryGrey
  );

  const [editDetailsText, setEditDetailsText] = useState("Edit");
  const [editDetailsBGColor, setEditDetailsBGColor] = useState(
    ColorPalette.PrimaryGrey
  );

  const [cityList, setCityList] = useState([]);
  const [metroList, setMetroList] = useState([]);

  const [ErrorText, setErrorText] = useState("");
  const [ErrorTextDetails] = useState("");

  const [ProjectArea, setProjectArea] = useState(area);
  const [ProjectScope, setProjectScope] = useState(scope);
  const [ProjectType, setProjectType] = useState(type);
  const [ProjectStyle, setProjectStyle] = useState(style);
  const [ProjectBudget, setProjectBudget] = useState(budget);

  const [developerMetroId] = useState(3);

  const [ProjectDescription, setProjectDescription] = useState(description);

  const GetLocationInfo = async () => {
    let metrodata = await GetMetroList();
    if(contactinfo.Metro_Id !== undefined){
      let citydata = await GetCityList(contactinfo.Metro_Id);
      setCityList(citydata);
    }

    setMetroList(metrodata);
  };

  useEffect(() => {
    // setImgMap(imgs)
    if (pdfs !== undefined || pdfs !== null) {
      setPDFMap(
        pdfs.filter((o) => o.File?.includes("pdf") || o.File?.includes(".PDF"))
      );
    }
    if (imgs !== undefined || imgs !== null) {
      setImgMap(
        imgs.filter(
          (o) =>
            o.File?.slice(-4) !== ".MOV" &&
            o.File?.slice(-4) !== ".pdf" &&
            o.File?.slice(-4) !== ".PDF" &&
            o.File?.slice(-4) !== ".mp4" &&
            o.File?.slice(-4) !== ".MP4"
        )
      );
    }
  }, [imgs]); // eslint-disable-line react-hooks/exhaustive-deps

  const EditProjectContactInfo = async () => {
    if (editContactText === "Edit") {
      setNameDisplay("block");
      setPhoneDisplay("block");
      setEmailDisplay("block");
      setAddressDisplay("block");
      setCityDisplay("block");
      setMetroDisplay("block");

      setNameLabelDisplay("none");
      setPhoneLabelDisplay("none");
      setEmailLabelDisplay("none");
      setAddressLabelDisplay("none");
      setCityLabelDisplay("none");
      setMetroLabelDisplay("none");

      setEditContactText("Save");
      setEditContactBGColor(ColorPalette.PrimaryButtonBlue);
    } else if (
      contactinfo.Name !== "" &&
      contactinfo.Address !== "" &&
      contactinfo.City_Id !== 0 &&
      contactinfo.Phone !== "" &&
      contactinfo.Email !== "" &&
      contactinfo.Metro_Id !== 0 &&
      //User should not be able to select 'Developer Metro' / Metro Id = 3
      contactinfo.Metro_Id !== developerMetroId
    ) {
      setNameDisplay("none");
      setPhoneDisplay("none");
      setEmailDisplay("none");
      setAddressDisplay("none");
      setCityDisplay("none");
      setMetroDisplay("none");

      setNameLabelDisplay("block");
      setPhoneLabelDisplay("block");
      setEmailLabelDisplay("block");
      setAddressLabelDisplay("block");
      setCityLabelDisplay("block");
      setMetroLabelDisplay("block");

      setEditContactText("Edit");
      setEditContactBGColor(ColorPalette.PrimaryGrey);
      setErrorText("");
    } else {
      setErrorText("Please fill in all of the boxes");
    }
  };

  const EditProjectDetails = async () => {
    if (editDetailsText === "Edit") {
      setProjectAreaDisplay("block");
      setProjectScopeDisplay("block");
      setProjectTypeDisplay("block");
      setProjectStyleDisplay("block");
      setProjectBudgetDisplay("block");

      setProjectAreaLabelDisplay("none");
      setProjectScopeLabelDisplay("none");
      setProjectTypeLabelDisplay("none");
      setProjectStyleLabelDisplay("none");
      setProjectBudgetLabelDisplay("none");

      setEditDetailsText("Save");
      setEditDetailsBGColor(ColorPalette.PrimaryButtonBlue);
    } else {
      setProjectAreaDisplay("none");
      setProjectScopeDisplay("none");
      setProjectTypeDisplay("none");
      setProjectStyleDisplay("none");
      setProjectBudgetDisplay("none");

      setProjectAreaLabelDisplay("block");
      setProjectScopeLabelDisplay("block");
      setProjectTypeLabelDisplay("block");
      setProjectStyleLabelDisplay("block");
      setProjectBudgetLabelDisplay("block");

      setEditDetailsText("Edit");
      setEditDetailsBGColor(ColorPalette.PrimaryGrey);
    }
  };

  //Assigns the ID based on the Name Selected
  const SelectCity = async (e) => {
    contactinfo.City_Id = e.target.value;
    cityList.find(function (o, i) {
      if (o.Id == e.target.value) {
        contactinfo.City = o.Name;
      }
      return null;
    });
  };

  const SelectMetro = async (e) => {
    contactinfo.Metro_Id = e.target.value;
    console.log(
      metroList.find(function (o, i) {
        if (o.Id == e.target.value) {
          contactinfo.Metro = o.Name;
        }
        return null;
      })
    );
    var stores = await GetStoreByMetro(contactinfo.Metro_Id);
    if (stores !== null) {
      contactinfo.Store_Id = stores[0].Id;
    }
  };

  const UpdateCityList = async (metroid) => {
    if (metroid !== 0 && metroid !== 3) {
      contactinfo.City = "";
      contactinfo.City_Id = 0;
      let citydata = await GetCityList(metroid);
      setCityList(citydata);
    }
  };

  useEffect(() => {
    if (contactinfo !== undefined) {
      GetLocationInfo();
    }
  }, [contactinfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProjectArea(area);
  }, [area]);

  useEffect(() => {
    setProjectScope(scope);
  }, [scope]);

  useEffect(() => {
    setProjectType(type);
  }, [type]);

  useEffect(() => {
    setProjectStyle(style);
  }, [style]);

  useEffect(() => {
    setProjectBudget(budget);
  }, [budget]);

  useEffect(() => {
    setProjectDescription(description);
  }, [description]);

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1 height="auto">
        <ProgressBar>
          <ProgressBarWidth width="100%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Overview
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="80%"
          marginbottom="2%"
          fontsize="16px"
        >
          Review all of your information and confirm the details. Once you press
          save project, you will have the option to save as a draft or submit,
          and our team will be alerted and get started right away. You will
          receive a confirmation email, as well as a project proposal within 1-2
          days*
        </BodyPara>

        <FlexColumnFullWidth>
          <FlexColumnCont
            mobiledisplay="none"
            height="8%"
            margin="0"
            margintop="2%"
            marginbottom="2%"
            flexdirection="row"
            justifycontent="space-around"
            width="100%"
          >
            <GreyNavigationButton
              onClick={() => {
                onBack();
              }}
            >
              Back
            </GreyNavigationButton>

            <GreenNavigationButton
              onClick={() => {
                onSetCaseInformation({
                  contactinfo,
                  ProjectArea,
                  ProjectScope,
                  ProjectType,
                  ProjectStyle,
                  ProjectBudget,
                  ProjectDescription,
                });
                onOpenSaveCase();
              }}
            >
              {statusButtonText}
            </GreenNavigationButton>
          </FlexColumnCont>
          <FlexColumnFullWidth>
            <FlexContBottomGrey mobilewidth="90vw" marginbottom="1.5%">
              <SmallHeaderLeagueSpartan margintop="1.5%">
                Contact Info
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
                onClick={() => {
                  EditProjectContactInfo();
                }}
                bgcolor={editContactBGColor}
              >
                {editContactText}
              </RegularResponsiveButton>
            </FlexContBottomGrey>
            <BodyPara bodycolor="red" marginbottom="2%" fontsize="16px">
              {ErrorText}
            </BodyPara>

            <FlexRowContFullWidth>
              <FlexInfoCont mobilewidth="100%">
                <WideFlexInfoCont>
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Full Name:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={NameDisplay}
                    inputheight="15%"
                    defaultValue={contactinfo.Name}
                    onChange={(e) => {
                      contactinfo.Name = e.target.value;
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={NameLabelDisplay}>
                    {contactinfo.Name}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont>
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Phone Number:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={PhoneDisplay}
                    inputheight="15%"
                    defaultValue={contactinfo.Phone}
                    onChange={(e) => {
                      contactinfo.Phone = e.target.value;
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={PhoneLabelDisplay}>
                    {contactinfo.Phone}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont>
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Email Address:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={EmailDisplay}
                    inputheight="15%"
                    defaultValue={contactinfo.Email}
                    onChange={(e) => {
                      contactinfo.Email = e.target.value;
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={EmailLabelDisplay}>
                    {contactinfo.Email}
                  </SmallHeader>
                </WideFlexInfoCont>
              </FlexInfoCont>

              <FlexInfoCont mobilewidth="100%">
                <WideFlexInfoCont>
                  <WideFlexInfoCont width="25%">
                    <SmallHeader>Address:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={AddressDisplay}
                    inputheight="15%"
                    defaultValue={contactinfo.Street}
                    onChange={(e) => {
                      contactinfo.Street = e.target.value;
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={AddressLabelDisplay}>
                    {contactinfo.Street}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont>
                  <WideFlexInfoCont width="25%">
                    <SmallHeader>City:</SmallHeader>
                  </WideFlexInfoCont>

                  <SmallHeader>{contactinfo.City}</SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont>
                  <WideFlexInfoCont width="25%">
                    <SmallHeader>Region:</SmallHeader>
                  </WideFlexInfoCont>

                  <SmallHeader>{contactinfo.Metro}</SmallHeader>
                </WideFlexInfoCont>
              </FlexInfoCont>
            </FlexRowContFullWidth>
          </FlexColumnFullWidth>

          <FlexColumnFullWidth>
            <FlexContBottomGrey
              mobilewidth="90vw"
              margintop="3%"
              marginbottom="1.5%"
            >
              <BodyPara
                mobiledisplay="none"
                bodycolor="red"
                marginbottom="2%"
                fontsize="16px"
              >
                {ErrorTextDetails}
              </BodyPara>

              <SmallHeaderLeagueSpartan margintop="1.5%">
                Project Details
              </SmallHeaderLeagueSpartan>
              <RegularResponsiveButton
                onClick={() => {
                  EditProjectDetails();
                }}
                bgcolor={editDetailsBGColor}
              >
                {editDetailsText}
              </RegularResponsiveButton>
            </FlexContBottomGrey>

            <FlexRowContFullWidth>
              <FlexInfoCont mobilewidth="100%">
                <WideFlexInfoCont>
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Project Area:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={ProjectAreaDisplay}
                    inputheight="15%"
                    value={ProjectArea}
                    onChange={(e) => {
                      setProjectArea(e.target.value);
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={ProjectAreaLabelDisplay}>
                    {ProjectArea}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont>
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Project Scope:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={ProjectScopeDisplay}
                    inputheight="15%"
                    value={ProjectScope}
                    onChange={(e) => {
                      setProjectScope(e.target.value);
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={ProjectScopeLabelDisplay}>
                    {ProjectScope}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont wordwrap="break-word">
                  <WideFlexInfoCont width="30%">
                    <SmallHeader>Project Type:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={ProjectTypeDisplay}
                    inputheight="15%"
                    value={ProjectType}
                    onChange={(e) => {
                      setProjectType(e.target.value);
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={ProjectTypeLabelDisplay}>
                    {ProjectType}
                  </SmallHeader>
                </WideFlexInfoCont>
              </FlexInfoCont>

              <FlexInfoCont mobilewidth="100%">
                <WideFlexInfoCont>
                  <WideFlexInfoCont width="18%">
                    <SmallHeader>Style:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={ProjectStyleDisplay}
                    inputheight="15%"
                    value={ProjectStyle}
                    onChange={(e) => {
                      setProjectStyle(e.target.value);
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={ProjectStyleLabelDisplay}>
                    {ProjectStyle}
                  </SmallHeader>
                </WideFlexInfoCont>

                <WideFlexInfoCont mobileflexdirection="column">
                  <WideFlexInfoCont width="18%">
                    <SmallHeader>Budget:</SmallHeader>
                  </WideFlexInfoCont>
                  <TextInput300x50
                    inputdisplay={ProjectBudgetDisplay}
                    inputheight="15%"
                    value={ProjectBudget}
                    onChange={(e) => {
                      setProjectBudget(e.target.value);
                    }}
                  ></TextInput300x50>
                  <SmallHeader display={ProjectBudgetLabelDisplay}>
                    {ProjectBudget}
                  </SmallHeader>
                </WideFlexInfoCont>
              </FlexInfoCont>
            </FlexRowContFullWidth>
          </FlexColumnFullWidth>

          <FlexContBottomGrey
            mobilewidth="90vw"
            margintop="1%"
            marginbottom="1.5%"
          />

          <FlexColumnCont height="auto" margintop="5%">
            <SmallHeader>Project Description:</SmallHeader>
            <LandscapePara
              defaultValue={ProjectDescription}
              onChange={(e) => {
                setProjectDescription(e.target.value);
              }}
            ></LandscapePara>
          </FlexColumnCont>

          <GridContainer2Wide>
            <GridContainer3Wide>
              {imgMap.map((o, i) => (
                <GridContainer2Wide key={imgMap}>
                  <Image300x200
                    src={window.$IMGURL + o.File}
                    alt={"File:" + o}
                  ></Image300x200>
                </GridContainer2Wide>
              ))}
              {videoMap.map((o, i) => (
                <video width="320" height="240" controls>
                  <source src={window.$IMGURL + o} />
                  =Your browser does not support the video tag.
                </video>
              ))}
              {PDFMap.map((o, i) => (
                <div>
                  <a
                    rel="noreferrer"
                    href={window.$IMGURL + o.File}
                    target="_blank"
                  >
                    <Image300x200
                      src={"/pdficon.png"}
                      alt={"File:" + o.File}
                    ></Image300x200>
                  </a>
                </div>
              ))}
            </GridContainer3Wide>
          </GridContainer2Wide>
        </FlexColumnFullWidth>

        <FlexColumnCont
          display="none"
          mobiledisplay="flex"
          height="8%"
          margin="0"
          margintop="2%"
          marginbottom="2%"
          mobileflexdirection="row"
          justifycontent="space-around"
          width="100%"
        >
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>

          <GreenNavigationButton
            onClick={() => {
              onSetCaseInformation({
                contactinfo,
                ProjectArea,
                ProjectScope,
                ProjectType,
                ProjectStyle,
                ProjectBudget,
                ProjectDescription,
              });
              onOpenSaveCase();
            }}
          >
            Save
          </GreenNavigationButton>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectOverview.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  onDrafted: () => {},
  onSaveDraft: () => {},
  onOpenSaveCase: () => {},
  onSubmitCase: () => {},
  onSetCaseInformation: () => {},
  fullName: "Default Name",
  phone: "(604)-XXX-XXXX",
  email: "example@email.com",
  address: "123 Example St.",
  city: "Default City",
  metro: "Default Metro",
  area: "Default Area",
  scope: "Default Scope",
  type: "Default Type",
  style: "Default Style",
  budget: "Default Budget",
  description: "Default Description",
  imgs: ["name"],
  contactinfo: ["default"],
};

export default ProjectOverview;
