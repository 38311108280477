export const ColorPalette = {
  PrimaryButtonBlue: "#169ADB", //Primary Button Green
  MiddleGreen: "#2667AE", //Green between Primary Button Green & Secondary Button Green
  SecondaryGreen: "#2667AE", // Secondary Button Green, Meant to be used alongside Primary.
  AlternateGreen: "#E1FAD6", // Alternate Green, Use if Primary + Secondary are overused
  LightGreen: "#dceff9",
  LightGrey: "#F9F9F9",
  GreyGreen: "#edf7fc",
  PrimaryGrey: "#D3D3D3",
  SecondaryGrey: "#DBDBDB",
  TertiaryGrey: "#F4F4F4",
  PrimaryRed: "#FF4C4C",
  White: "#FFFFFF",
  DarkGrey: "#2E2E2E",
  PrimaryOrange: "#FF9A00",
  PrimaryYellow: "#FFEB2F",
  BackgroundGrey: "#F8F8F8",
  BoxShadowGrey: "#B7B7B7",
  PrimaryTurquoise: "#78d1cc",
  SecondaryBackgroundGrey: "#e3e5e8",
  PrimaryBlue:'#194B79',
  PrimaryGreen: '#51C159',
  LightBlack: 	'#353839',
  yh50: "#effee7",
  yh100: "#dbfccb",
  yh200: "#b9f99d",
  yh300: "#8df165",
  yh400: "#65e536",
  yh500: "#169ADB",
  yh600: "#31a20e",
  yh700: "#277c0f",
  yh800: "#236212",
  yh900: "#205314",
  yh950: "#0c2e05",
};
