import axios from "axios";
import React, { useEffect, useState } from "react";
import { UpdateToolKit } from "../../../API_Functions/Tool_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont500x850px,
  FlexRowCont100PercentSpaceBetween,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { FlexDivCenter } from "../../../StylesheetComps/Div";
import { Image200x200pxContain } from "../../../StylesheetComps/Imgs/Images";
import {
  TextAreaLarge,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { Icon30px20pxPointer } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanRegularMinWidth,
  RegularTextBoldRed,
  StyledHRPropColour,
} from "../../../StylesheetComps/Titles";

const ToolKitEdit = ({ open, onClose, RefreshToolkitList, toolkitobj }) => {
  const [ToolKitDefaultUrl, setToolKitDefaultUrl] = useState(
    toolkitobj?.ImageUrl
  );
  const [photoDisplay, setPhotoDisplay] = useState("block");
  const [errorText] = useState("");
  var file = "";

  function handleUpload(event) {
    file = event.target.files[0];
    UploadFile(file);
  }

  const UploadFile = async (file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setToolKitDefaultUrl(pic);
      toolkitobj.ImageUrl = pic;
      setPhotoDisplay("block");
    }
  };

  const DeleteImg = async (img) => {
    var resp = await axios.delete(window.$DBURL + "utilities/image", {
      data: { Url: img },
    });
    if (resp.status === 200) {
      toolkitobj.ImageUrl = "";
      setToolKitDefaultUrl("");
    }
  };

  const UpdateToolKitFunction = async (toolkitobj) => {
    var data = await UpdateToolKit(toolkitobj);
    if (data !== null) {
      RefreshToolkitList();
    }
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffect(() => {
    setToolKitDefaultUrl(toolkitobj?.ImageUrl);
  }, [toolkitobj?.ImageUrl]);

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <Cont500x850px>
        <LeftItemWindowFixed width="100px">
          <FlexRowCont100PercentSpaceBetween>
            <LeagueSpartanRegularMinWidth>
              ToolKit Name
            </LeagueSpartanRegularMinWidth>
            <TextInputReusable
              key={toolkitobj.Name}
              placeholder="ToolKit Name"
              defaultValue={toolkitobj.Name}
              onChange={(e) => {
                toolkitobj.Name = e.target.value;
              }}
            ></TextInputReusable>
          </FlexRowCont100PercentSpaceBetween>
          <hr></hr>
          <FlexRowCont100PercentSpaceBetween>
            <LeagueSpartanRegularMinWidth>Code</LeagueSpartanRegularMinWidth>
            <TextInputReusable
              key={toolkitobj.Code}
              placeholder="ToolKit Code"
              defaultValue={toolkitobj.Code}
              onChange={(e) => {
                toolkitobj.Code = e.target.value;
              }}
            ></TextInputReusable>
          </FlexRowCont100PercentSpaceBetween>
          <hr></hr>
          <FlexRowCont100PercentSpaceBetween>
            <LeagueSpartanRegularMinWidth>
              Position
            </LeagueSpartanRegularMinWidth>
            <TextInputReusable
              key={toolkitobj.Position}
              placeholder="Position"
              defaultValue={toolkitobj.Position}
              onChange={(e) => {
                toolkitobj.Position = e.target.value;
              }}
            ></TextInputReusable>
          </FlexRowCont100PercentSpaceBetween>

          <hr></hr>
          <FlexRowCont100PercentSpaceBetween>
            <LeagueSpartanRegularMinWidth>
              ToolKit Description
            </LeagueSpartanRegularMinWidth>
            <TextAreaLarge
              maxlength="100"
              onChange={(e) => {
                toolkitobj.Description = e.target.value;
              }}
              defaultValue={toolkitobj.Description}
            ></TextAreaLarge>
          </FlexRowCont100PercentSpaceBetween>
          <hr></hr>
          <FlexRowCont100PercentSpaceBetween>
            <LeagueSpartanRegularMinWidth>
              ToolKit Picture
            </LeagueSpartanRegularMinWidth>
            <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
            <FlexRowCont100PercentSpaceBetween>
              <Image200x200pxContain
                display={photoDisplay}
                src={window.$IMGURL + ToolKitDefaultUrl}
              ></Image200x200pxContain>
              <Icon30px20pxPointer
                display={photoDisplay}
                src="/Garbage_Icon.svg"
                onClick={() => {
                  DeleteImg(ToolKitDefaultUrl);
                  setToolKitDefaultUrl("");
                  setPhotoDisplay("none");
                }}
              ></Icon30px20pxPointer>
            </FlexRowCont100PercentSpaceBetween>
          </FlexRowCont100PercentSpaceBetween>
          <TextInputReusable
            type="file"
            id="ToolKitpic"
            name="filename"
            onChange={handleUpload}
          ></TextInputReusable>
          <StyledHRPropColour
            border={`0.5px solid white`}
            width="70%"
          ></StyledHRPropColour>
          <FlexDivCenter>
            <RegularResponsiveButton
              onClick={() => {
                UpdateToolKitFunction(toolkitobj);
                onClose();
                setPhotoDisplay("block");
              }}
            >
              Save
            </RegularResponsiveButton>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                onClose();
                setPhotoDisplay("block");
              }}
            >
              Cancel
            </RegularResponsiveButton>
          </FlexDivCenter>
          <StyledHRPropColour
            border={`0.5px solid white`}
            width="70%"
          ></StyledHRPropColour>
        </LeftItemWindowFixed>
      </Cont500x850px>
    </FixedOverlay200vh>
  );
};

ToolKitEdit.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onToolKitEdit: () => {},
  onImgDelete: () => {},
  ToolKitName: "ToolKit Name",
  ToolKitCode: "Default Code",
  ToolKitDesc: "",
  RadioSelect: 0,
  ToolKitUrl: "",
  ToolKitPosProp: 0,
};

export default ToolKitEdit;
