import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumn81vwLightGrey,
  FlexColumnCont100xAutoPercent,
  FlexColumnFullWidth,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Icon100x100 } from "../../StylesheetComps/Imgs/Icons";
import {
  Image300x200,
  SmallHeaderLeagueSpartan,
} from "../../StylesheetComps/LandscapeForm";
import { FlexColumnContBoxShadow } from "../../StylesheetComps/Menu";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import { SmallText } from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";

const PaymentPendingScreen = () => {
  //=============================================================================
  // Payment Success Screen - User redirected here on Successful Stripe Payment
  //=============================================================================

  //=============================================================================
  // User will redirect to QuoteAppointment Page to Book Appointment with Inst.
  //=============================================================================

  const [roleNum] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const history = useHistory();

  useEffect(() => {
    SetHeaderAuthorization();
    setUserInfo(JSON.parse(sessionStorage.getItem("userInfo")));
  }, []);

  if (roleNum !== UserRole.Loading) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu></ActionMenu>

          <ContentCont77vw>
            <FlexColumnFullWidth display={"block"} height="auto" margin="0">
              <FlexColumnCont100xAutoPercent>
                <Image300x200 src={"/Customer_Service_Icon.svg"}></Image300x200>
                <SmallHeaderLeagueSpartan fontsize="24px">
                  Your Project is Pending Payment.
                </SmallHeaderLeagueSpartan>

                <br></br>
                <br></br>

                <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number1.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Send Payment
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      We've received your project's approval. Now it's time to
                      send payment.
                    </SmallText>
                  </FlexColumnContBoxShadow>

                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number2.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Payment Confirmation
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      Once we receive payment, we'll notify you and confirm your
                      project.
                    </SmallText>
                  </FlexColumnContBoxShadow>

                  <FlexColumnContBoxShadow>
                    <Icon100x100 src="/number3.svg"></Icon100x100>
                    <SmallHeaderLeagueSpartan>
                      Project Scheduling
                    </SmallHeaderLeagueSpartan>
                    <SmallText>
                      Your project will be scheduled at the soonest possible
                      date and you'll be notified.
                    </SmallText>
                  </FlexColumnContBoxShadow>
                </FlexRowCont100PercentSpaceBetween>
                <br></br>
                <br></br>
                <FlexRowCont mobileflexdirection="column">
                  <RegularResponsiveButton
                    mobilewidth="68vw"
                    onClick={() => {
                      history.push(
                        "/CasesPersonal/" +
                          userInfo.Metro_Id +
                          "/" +
                          userInfo.Store_Id
                      );
                    }}
                  >
                    View Projects
                  </RegularResponsiveButton>
                  {/* <RegularResponsiveButton bgcolor={ColorPalette.DarkGrey} onClick={()=>{history.push("/payment/"+params.amount+"/"+params.caseId+"/"+params.quoteId+"/")
}}>View Payment Methods</RegularResponsiveButton> */}
                </FlexRowCont>
              </FlexColumnCont100xAutoPercent>
            </FlexColumnFullWidth>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

PaymentPendingScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default PaymentPendingScreen;
