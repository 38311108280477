import axios from 'axios';

export const QuoteStatus = {
    Created: 0,
    UserNotified: 1,
    RevisionRequested: 2,
    Approved: 3,
    Declined: 4,
    Buy: 10,
    Buy_Install: 11,
    Expired: 100,
    Canceled:-1
  }

export const CaseStatus = {
    Drafted: 0,
    Submitted: 1,
    Accepted: 2,
    Processed: 3,
    Closed: 4,
    CancelRequested: 5,
    PayPending: 6, 
    Construction: 7,
    Canceled: -1,
  }

export const BidStatus = {
    NotBidding: 0,
    Bidding: 1,
    Decided: 2,
}
 // get categories of a store with storeid.
export const GetUserCases = async(user_id)=>{
    var resp = await axios.get(window.$DBURL+"case/user/"+ user_id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetCaseById = async(id)=>{
    var resp = await axios.get(window.$DBURL+"case/"+ id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetCasesByGuide = async(estimator_id)=>{
    var resp = await axios.get(window.$DBURL+"case/estimator/"+ estimator_id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetCasesByStore = async(store_id)=>{
    var resp = await axios.get(window.$DBURL+"case/store/"+ store_id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const AddCase = async(newcase)=>{
    var resp = await axios.post(window.$DBURL+"case/add", {Case: newcase})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const AddCaseOBO = async(newcase, inputusername)=>{
    var resp = await axios.post(window.$DBURL+"case/addOBO", {Case: newcase, username:inputusername})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const UpdateACase = async(newcase)=>{
    var resp = await axios.put(window.$DBURL+"case/update", {Case: newcase})
 
    if(resp.status===200)
    {
        var data = await GetCaseById(newcase.Id);
        return data;
    }
    else
    {
        return null;
    }
}

export const AssignCase = async(case_id, user_id)=>{
    var resp = await axios.put(window.$DBURL+"case/" + case_id + "/assign/"+ user_id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Submit = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/submit");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Accept = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/accept");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Processed = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/process");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Close = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/close");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Cancel = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/cancel");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_CancelRequested = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/cancelrequest");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetCaseTo_Construction = async(cas_id)=>{
    var resp = await axios.put(window.$DBURL+"case/"+ cas_id + "/construction");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetQuoteById = async(id)=>{
    var resp = await axios.get(window.$DBURL+"case/quote/"+ id);
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetQuoteByIdForUserDisplay = async(id)=>{
    var resp = await axios.get(window.$DBURL+"case/quote/"+ id + "/user");
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const AddQuote = async(quote)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/add", {
       Quote: quote})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const AddQuoteRevision = async(quote)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/add/revision", {
       Quote: quote})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const UpdateQuote = async(quote)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/update", { Quote: quote})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Notify = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/notify")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Expire = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/expire")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Cancel = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/cancel")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Created = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/create")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Buy = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/buy")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Buy_Install = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/install")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}


export const SetQuoteTo_RequestRevision = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/revision")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Revisioned = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/approved")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_Declined = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/declined")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}


export const SetQuoteTo_Bid = async(quote)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote.Id + "/bid", 
    {Quote: quote})
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_CancelBid = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/cancelbid")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const SetQuoteTo_BidCompleted = async(quote_id)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/" + quote_id + "/completebid")
 
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}


export const GetStoreBiddingQuotes = async(storeid)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/bidding/store/" + storeid)
 
    if(resp.status===200)
    {
        return resp.data.quotes;
    }
    else
    {
        return null;
    }
}

export const GetQuoteItem = async(item_id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/item/" + item_id)
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const AddQuoteItem = async(item)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/item/add", { Item: item})
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const UpdateQuoteItem = async(item)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/item/update", {Item: item})
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const DeleteQuoteItemByEstimator = async(item_id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/item/" + item_id)
    if(resp.status===200)
    {       
        return null;
    }
    else
    {
        var data = await GetQuoteItem(item_id)
        return data;
    }
}

export const DeleteQuoteItemByHomeowner = async(quote_id, item_id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/" + quote_id + "/item/" + item_id)
    if(resp.status===200)
    {       
        return null;
    }
    else
    {
        var data = await GetQuoteItem(item_id)
        return data;
    }
}

export const AddQuoteTool = async(item)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/tool", {
        Quote_Id:item.Quote_Id, Tool_Id:item.Tool_Id, RentalLength: item.RentalLength, 
         Quant:item.Quant
      })
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const UpdateQuoteTool = async(item)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/tool", {
        Id:item.Id, RentalLength: item.RentalLength, 
         Quant:item.Quant
      })
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetQuoteTool = async(item_id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/tool/"+ item_id)
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const DeleteQuoteTool = async(item_id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/tool/"+ item_id)  
    
    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuoteTool(item_id);
        return data;
    }
}


export const GetQuote_AccessArea = async(item_id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/access/"+ item_id)
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const DeleteQuote_AccessArea = async(item_id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/access/"+ item_id)  
    
    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuote_AccessArea(item_id);
        return data;
    }
}

export const AddQuote_AccessArea = async(item)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/access", {Case_Id:item.Case_Id,
        Quote_Id:item.Quote_Id, Width:item.Width, Length: item.Length, Elevation:item.Elevation, 
         Obstruct:item.Obstruct
      })
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const UpdateQuote_AccessArea = async(item)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/access", {Id:item.Id,
        Width:item.Width, Length: item.Length, 
         Obstruct:item.Obstruct, Elevation:item.Elevation, 
      })
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const GetQuoteObject = async()=>{
    const resp = await axios.get(window.$DBURL+"case/quote/object")
    
    if(resp.status===200)
    {
        return resp.data.Quote;
    }
    else
    {
        return null;
    }
}

export const GetCaseObject = async()=>{
    const resp = await axios.get(window.$DBURL+"case/object")
    
    if(resp.status===200)
    {
        return resp.data.Case;
    }
    else
    {
        return null;
    }
}

export const GetInvoice = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/invoice/" + id)
    
    if(resp.status===200)
    {
        return resp.data.Invoice;
    }
    else
    {
        return null;
    }
}

export const AddInvoice = async(invoice)=>{
    const resp = await axios.post(window.$DBURL+"case/invoice/",{Invoice:invoice})
    
    if(resp.status===200)
    {
        return resp.data.Invoice;
    }
    else
    {
        return null;
    }
}

export const UpdateInvoice = async(invoice)=>{
    const resp = await axios.put(window.$DBURL+"case/invoice/",{Invoice:invoice})
    
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}

export const DeleteInvoiceById = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/invoice/" + id)
    
    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetInvoice(id);
        return data;
    }
}

export const GetInvoiceObject = async()=>{
    const data = await GetInvoice(0);
    
    return data;
}

export const GetAllInvoices = async()=>{
    const resp = await axios.get(window.$DBURL+"case/invoice")
    
    if(resp.status===200)
    {
        return resp.data.Invoice;
    }
    else
    {
        return null;
    }
}

export const GetInvoicesByStore = async(storeid)=>{
    const resp = await axios.get(window.$DBURL+"case/invoice/store/" + storeid)
    
    if(resp.status===200)
    {
        return resp.data.Invoice;
    }
    else
    {
        return null;
    }
}

export const DeleteCaseById = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/"+ id);
    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetCaseById(id);
        return data;
    }
}

export const DeleteQuoteById = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/"+ id);
    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuoteById(id);
        return data;
    }
}

export const GetInvoicesByUser = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/invoice/user/" + id)
    
    if(resp.status===200)
    {
        return resp.data.Invoices;
    }
    else
    {
        return null;
    }
}

export const ReverseQuant_QuoteItem = async(item_id, quantity)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/item/quant_reverse",
                            {Id:item_id, Quant: quantity});
    
    if(resp.status===200)
    {
        return resp.data.quantity;
    }
    else
    {
        return quantity;
    }
}

export const AddQuoteComment = async(comment)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/comment",
                            {Comment: comment});
    
    if(resp.status===200)
    {
        return resp.data.comment;
    }
    else
    {
        return null;
    }
}

export const UpdateQuoteComment = async(comment)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/comment",
                            {Comment: comment});
    if(resp.status===200)
    {
        return resp;
    }
    else
    {
        return null;
    }
}

export const GetQuoteComment = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/comment/" + id);
    
    if(resp.status===200)
    {
        return resp.data.comment;
    }
    else
    {
        return null;
    }
}

export const DeleteQuoteComment = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/comment/" + id);

    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuoteComment(id);
        return data;
    }
}


export const AddQuotePhoto = async(photo)=>{    
    const resp = await axios.post(window.$DBURL+"case/quote/photo",
        {Caption:photo.Caption, File:photo.File, Quote_Id: photo.Quote_Id  });
     
    if(resp.status===200)
    {
        return resp.data.newphoto;
    }
    else
    {
        return null;
    }
}

export const UpdateQuotePhoto = async(photo)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/photo",
    {Caption:photo.Caption, File:photo.File, Id: photo.Id  });
    if(resp.status===200)
    {
        return resp;
    }
    else
    {
        return null;
    }
}

export const GetQuotePhoto = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/photo/" + id);
    
    if(resp.status===200)
    {
        return resp.data.photo;
    }
    else
    {
        return null;
    }
}

export const DeleteQuotePhoto = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/photo/" + id);

    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuotePhoto(id);
        return data;
    }
}

export const AddCasePhoto = async(photo)=>{
    const resp = await axios.post(window.$DBURL+"case/photo",
    {Caption:photo.Caption, File:photo.File, Case_Id: photo.Case_Id  });
    
    if(resp.status===200)
    {
        return resp.data.newphoto;
    }
    else
    {
        return null;
    }
}

export const UpdateCasePhoto = async(photo)=>{
    const resp = await axios.put(window.$DBURL+"case/photo",
    {Caption:photo.Caption, File:photo.File, Id: photo.Id  });
    if(resp.status===200)
    {
        return resp;
    }
    else
    {
        return null;
    }
}

export const GetCasePhoto = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/photo/" + id);
    
    if(resp.status===200)
    {
        return resp.data.photo;
    }
    else
    {
        return null;
    }
}

export const DeleteCasePhoto = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/photo/" + id);

    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetCasePhoto(id);
        return data;
    }
}

export const AddQuoteTag = async(tag)=>{    
    const resp = await axios.post(window.$DBURL+"case/quote/tag",
        {TagName:tag.TagName,  Quote_Id: tag.Quote_Id  });
     
    if(resp.status===200)
    {
        return resp.data.newtag;
    }
    else
    {
        return null;
    }
}

export const UpdateQuoteTag = async(tag)=>{
    const resp = await axios.put(window.$DBURL+"case/quote/tag",
    {TagName:tag.TagName, Id: tag.Id  });
    if(resp.status===200)
    {
        return resp;
    }
    else
    {
        return null;
    }
}

export const GetQuoteTag = async(id)=>{
    const resp = await axios.get(window.$DBURL+"case/quote/tag/" + id);
    
    if(resp.status===200)
    {
        return resp.data.tag;
    }
    else
    {
        return null;
    }
}

export const DeleteQuoteTag = async(id)=>{
    const resp = await axios.delete(window.$DBURL+"case/quote/tag/" + id);

    if(resp.status===200)
    {
        return null;
    }
    else
    {
        var data = await GetQuoteTag(id);
        return data;
    }
}

export const AddItemRelation = async(primaryitemid, secondaryitemid, description)=>{
    const resp = await axios.post(window.$DBURL+"case/quote/item/relation", 
    { Item_Id: primaryitemid, RelatedItem_Id:secondaryitemid, Description: description })
    if(resp.status===200)
    {
        return resp.data;
    }
    else
    {
        return null;
    }
}