/* eslint-disable */

//  React Imports ---------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  GetQuoteByIdForUserDisplay,
  QuoteStatus,
  ReverseQuant_QuoteItem,
  SetQuoteTo_Buy,
  SetQuoteTo_Buy_Install,
} from "../../API_Functions/CaseQuote_Functions";
import {
  GetCurrentTime,
  MakeStripePay,
  SetHeaderAuthorization,
} from "../../API_Functions/Utility_Functions";
//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import TopMentProposalView from "../../comps/MenuComps/TopMenuProposalView";
import QuoteItem from "../../comps/QuoteItem";
import ImageViewWindow from "../../comps/Windows/ImageEnlargementWindow";
import RevisionInstructionsWindow from "../../comps/Windows/RevisionInstructions";
import DuplicateProposalWindow from "../../comps/Windows/SaveRevisionGuide";
import MobileQuoteItem from "../../MobileComponents/MobileQuoteItem";
import { Stored_Values } from "../../Stored_Values";
import { CaseStatus } from "../../StylesheetComps/CaseStatus";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FixedIconToTop,
  RegularResponsiveButton,
} from "../../StylesheetComps/Components";
// Imported Styled Components ---------------------------
import { GetTaxesForMetro } from "../../API_Functions/Store_Functions";
import EstimateFooter from "../../comps/EstimateFooter";
import {
  AutoSizeOverflowY,
  FlexColumn81vwLightGrey,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexColumnFullWidthMargins,
  FlexContFullWidthHeightJustifyCenter,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont60PercentSpaceEvenly,
  FlexRowContAutox100PercentMobileColumn,
  FlexRowContFlexStart,
  FlexRowContFlexStartMobileColumn,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import {
  FlexContainerItem,
  FlexContainerPhotos,
  GridContainer3Wide,
  SummaryContainer,
  SummaryContainerItems,
} from "../../StylesheetComps/Div";
import { Icon200x200 } from "../../StylesheetComps/Imgs/Icons";
import {
  OverrideEmbedDiv,
  OverrideEmbedImage,
} from "../../StylesheetComps/Imgs/Images";
import {
  Image375px,
  TextAreaAutoResizeNoBorder,
} from "../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../StylesheetComps/Menu";
import {
  Cont,
  ContentCont44Percent,
  ContentCont77vw,
  ContentContQuote,
} from "../../StylesheetComps/Projects";
import {
  LargeText,
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  MediumHeader,
  StyledHRGreenBreak,
  StyledHRGreenBreakThick,
  StyledHRPropColour,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { CategoryTypes } from "../../StylesheetComps/CategoryTypes";
import Dropdown from "../../comps/DropdownMenu";

const ViewProposalUserView = () => {
  // React-router variables
  const history = useHistory();
  const params = useParams();

  // Data variables
  const [Quote, setProposal] = useState({});
  const [Case, setCase] = useState({});
  const [curTime, setCurTime] = useState("");
  var MaterialSum = 0;
  var InstallSum = 0;
  var TotalSum = 0;
  var materialSub = 0;
  var installSub = 0;

  // Totals
  const [supplyTotal, setSupplyTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [installTotal, setInstallTotal] = useState({
    Subtotal: 0,
    Tax: [],
    Total: 0,
  });
  const [projectSubtotal, setProjectSubtotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [projectGrandTotal, setProjectGrandTotal] = useState(0);
  const [deliveryTotal, setDeliveryTotal] = useState(0);
  const [accessTotal, setAccessTotal] = useState(0);

  // Display control for headers
  const [toolsmachinesHeader, setToolsMachinesHeader] = useState("none");
  const [removalHeader, setRemovalHeader] = useState("none");
  const [accessDisplay, setAccessDisplay] = useState("none");
  const [generalMaterialsHeader, setGeneralMaterialsHeader] = useState("none");
  const [deliveryHeader, setDeliveryHeader] = useState("none");

  // Display control for various elements
  const [installEstimateDisplay, setInstallEstimateDisplay] = useState("flex");
  const [laborDetailView, setLaborDetailView] = useState("none");
  const [showGenerateInovice, setShowGenerateInvoice] = useState("none");
  const [laborDetailToggleView, setLaborDetailToggleView] = useState("none");
  const [laborDetailArrow, setLaborDetailArrow] = useState("/downarrow.svg");
  const [discardButtonDisplay, setDiscardButtonDisplay] = useState("none");
  const [makeRevisionsButtonDisplay, setMakeRevisionsButtonDisplay] =
    useState("block");
  const [saveRevisionsButtonDisplay, setSaveRevisionsButtonDisplay] =
    useState("none");
  const [cloneProposalButtonDisplay, setCloneProposalButtonDisplay] =
    useState("none");
  const [showDepositButton, setShowDepositButton] = useState("block");
  const [labourBreakdownDisplay, setShowLabourBreakdown] = useState("none");
  const [showRelated, setShowRelated] = useState(false);
  const [laborDetailToggle, setLaborDetailToggle] = useState(false);

  // UI controls for image preview
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageGalleryDisplay, setImageGalleryDisplay] = useState(false);
  const [cloneProposalWindowDisplay, setCloneProposalWindowDisplay] =
    useState(false);

  // Revision instruction window
  const [revisionInstructionDisplay, setRevisionInstructionDisplay] =
    useState(false);
  const [revisionAcceptDisplay, setRevisionAcceptDisplay] = useState("none");
  const [revisionDeclineDisplay, setRevisionDeclineDisplay] = useState("none");

  // Deletion state
  const [deleteState, setDeleteState] = useState(false);

  // Header input state
  const [headerInputShown, setHeaderInputShown] = useState(0);

  // Opacity control
  const [supplyTotalOpacity, setSupplyTotalOpacity] = useState("100%");
  const [installTotalOpacity, setInstallTotalOpacity] = useState("100%");

  // Naming
  const [projectName, setProjectName] = useState("");
  const [estimateName, setEstimateName] = useState("");
  const [homeownerName, setHomeownerName] = useState("");
  const [projectCaseNumber, setProjectCaseNumber] = useState(0);

  // File naming
  const [invoicefileMaterial, setInvoicefileMaterial] = useState(
    "invoice_material.pdf"
  );
  const [invoicefileInstall, setInvoicefileInstall] = useState(
    "invoice_install.pdf"
  );

  // Totals for untagged items
  const [untaggedMaterialSubtotal, setUntaggedMaterialSubtotal] = useState(0);
  const [untaggedInstallSubtotal, setUntaggedInstallSubtotal] = useState(0);
  const [untaggedTotalSubtotal, setUntaggedTotalSubtotal] = useState(0);

  // Error handling
  const [errorAgreementText, setErrorAgreementText] = useState("");

  // Supply control
  const [supplyOnlySelected, setSupplyOnlySelected] = useState(false);

  // Taxation
  const [storeTaxes, setStoreTaxes] = useState(false);

  // Scrolling control
  const scrollToOverview = document.getElementById("overview");
  const scrollToSupply = document.getElementById("supply");
  const scrollToInstall = document.getElementById("install");
  const scrollToDesigns = document.getElementById("designs");
  const scrollToComments = document.getElementById("comments");
  const scrollToConfirm = document.getElementById("confirmation");

  // Case status
  const [caseStatus, setCaseStatus] = useState(0);

  // Button reference
  const buttonRef = React.useRef(null);
  const [displayStatus, setDisplayStatus] = useState({});

  let removalsHeader7Subtotal = 0;
  let [removalsHeader8Subtotal, setRemovalsHeader8Subtotal] = useState(0);
  let toolsHeader7Subtotal = 0;
  let toolsHeader8Subtotal = 0;
  const [projectInstallSubtotal, setProjectInstallSubtotal] = useState(0);

  const formattedDateTime = async () => {
    const now = new Date();
    const day = now.getDate();
    const month = now.toLocaleString("default", { month: "short" });
    const year = now.getFullYear();
    let hour = now.getHours();
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12;
    hour = hour ? hour : 12;
    const minute = now.getMinutes().toString().padStart(2, "0");
    return `${day} ${month} ${year}, ${hour}:${minute} ${ampm}`;
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }

  const downloadPDF = async () => {
    const timestamp = await formattedDateTime();
    const input = document.getElementById("estimate");

    const includeElement = document.getElementById("include-on-pdf");
    const originalDisplayStyle = includeElement.style.display;
    includeElement.style.display = "flex";

    html2canvas(input, {
      useCss: true,
      scale: 1, // Reduce the size of the canvas
      ignoreElements: (element) => {
        return element.id === "exclude-from-pdf";
      },
    }).then((canvas) => {
      includeElement.style.display = originalDisplayStyle;

      const imgData = canvas.toDataURL("image/jpeg", 1); // Use JPEG with 50% quality
      const pdf = new jsPDF("p", "mm", "a4"); // set the PDF to A4 size
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pageWidth;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      let pdfPages = Math.ceil(pdfHeight / pageHeight);

      for (let i = 0; i < pdfPages; i++) {
        if (i !== 0) {
          pdf.addPage();
        }
        pdf.addImage(imgData, "PNG", 0, -(i * pageHeight), pdfWidth, pdfHeight);
      }

      pdf.save(`${timestamp} ${Case?.Street}.pdf`);
    });
  };

  const DisplayImageInGallery = async (url) => {
    if (!url) return;

    setImagePreviewUrl(url);
    setImageGalleryDisplay(true);
    document.body.style.overflow = "hidden";
  };

  const DisplayLaborBreakdown = async (toggle) => {
    if (toggle) {
      setLaborDetailArrow("/downarrow.svg");
      setLaborDetailToggleView("none");
      setLaborDetailToggle(false);
    } else {
      setLaborDetailToggle(true);
      setLaborDetailArrow("/uparrow.svg");
      setLaborDetailToggleView("flex");
    }
  };

  const toggleDisplay = (id) => {
    setDisplayStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const GetProjectInformation = async (caseId) => {
    const data = await GetCaseById(caseId);
    const { Case } = data || {};

    if (!Case) return;

    const { Name, Tittle, CaseNumber, Street, City, CaseStatus } = Case;

    setCase(Case);

    if (Name) setHomeownerName(Name);
    if (Tittle) setProjectName(Tittle);
    if (CaseNumber) setProjectCaseNumber(CaseNumber);

    if (Street && City) {
      const ext = `${Street}-${City}`;
      setInvoicefileInstall(`invoice_install_${ext}.pdf`);
      setInvoicefileMaterial(`invoice_material_${ext}.pdf`);
    }

    if (
      [
        CaseStatus.Closed,
        CaseStatus.Canceled,
        CaseStatus.CancelRequested,
        CaseStatus.Drafted,
      ].includes(CaseStatus)
    ) {
      setMakeRevisionsButtonDisplay("none");
    }

    setCaseStatus(CaseStatus);
  };

  const toggleRemovalDisplay = (id) => {
    setRemovalDisplayStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const DisplayDepositButton = async () => {
    if (caseStatus === CaseStatus.Construction) {
      setShowDepositButton("none");
    } else setShowDepositButton("block");
  };

  const [estimateGST, setEstimateGST] = useState(0);
  // const [estimatePST, setEstimatePST] = useState(0)

  const GetProposalInformation = async (quoteid) => {
    const headers = {
      apikey: window.$POLYGONAPIKEY,
      authorization: window.$POLYGONAUTHKEY,
    };

    let quote; // Declare quote here for broader scope access

    try {
      const checkResponse = await axios.get(
        `${window.$LOCKINURL}?estimateId=eq.${quoteid}`,
        { headers }
      );

      if (Array.isArray(checkResponse.data) && checkResponse.data.length > 0) {
        console.log("Estimate already there!");
        // If the API returns the quote directly or as part of an object, adjust accordingly
        // Assuming the quote is directly within the data array
        quote = checkResponse.data[0].quote; // Adjust this based on the actual structure
      } else {
        const data = await GetQuoteByIdForUserDisplay(quoteid);
        if (!data || !data.quote) return;
        quote = data.quote; // Set quote from this block for use outside
      }
    } catch (error) {
      console.error("Error fetching proposal information:", error);
      return; // Exit if there's an error
    }

    if (!quote) return; // If quote hasn't been set, exit the function

    let removalsHeader7Subtotal = 0;
    let removalsHeader8Subtotal = 0;

    quote?.Removal?.Items.forEach((o) => {
      removalsHeader7Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
      removalsHeader8Subtotal += Number((o.Price + o.LaborSubtotal).toFixed(2));
    });

    let projectInstallSubtotal = 0;

    quote?.Tags?.forEach((t) => {
      const primaryItems = quote?.Material?.Items.filter(
        (item) => item.Tag_Id === t.Id && item.Parent_Id === 0
      );

      primaryItems.forEach((o) => {
        const installSub =
          showRelated === true
            ? o.LaborSubtotal
            : o.LaborSubtotalWithRelatedItems;
        projectInstallSubtotal += installSub;
      });
    });

    quote?.Material?.Items.forEach((o) => {
      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
        const installSub =
          showRelated === true
            ? o.LaborSubtotal
            : o.LaborSubtotalWithRelatedItems;
        projectInstallSubtotal += installSub;
      }
    });

    setProjectInstallSubtotal(projectInstallSubtotal);
    setProposal(quote);
    setDeliveryTotal(
      quote?.Delivery?.Tools?.reduce(
        (accumulator, tool) => accumulator + tool.Price,
        0
      ) || 0
    );
    setAccessTotal(
      quote?.AccessArea?.Items?.reduce(
        (accumulator, items) => accumulator + items.Price,
        0
      ) || 0
    );
    UpdateUIBasedOnStatus(quote);

    const materialSubtotal = quote?.Material?.Total?.Subtotal || 0;
    const deliverySubtotal = quote?.Delivery?.Total?.Subtotal || 0;
    const toolSubtotal = quote?.Tool?.Total?.Subtotal || 0;
    const accessAreaSubtotal = quote?.AccessArea?.Total?.Subtotal || 0;
    const installSubtotal =
      Number(projectInstallSubtotal) > Stored_Values.MIN_LABOR
        ? Number(projectInstallSubtotal)
        : Stored_Values?.MIN_LABOR;

    supplyTotal.Subtotal = (materialSubtotal + deliverySubtotal).toFixed(2);
    installTotal.Subtotal = (
      installSubtotal +
      toolSubtotal +
      removalsHeader7Subtotal +
      accessAreaSubtotal
    ).toFixed(2);

    const subtotal =
      Number(supplyTotal.Subtotal) + Number(installTotal.Subtotal);
    setProjectSubtotal(
      subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );

    if (quote.Tittle) {
      setEstimateName(quote.Tittle);
    }

    const storeTaxArray = await GetTaxesForMetro(params.Id);
    setStoreTaxes(storeTaxArray);

    let gstTax = 0;
    // let pstTax = 0;

    const taxitems = [
      { type: "Material", subtotal: materialSubtotal },
      { type: "Delivery", subtotal: deliverySubtotal },
      { type: "Labor", subtotal: installSubtotal },
      { type: "Removal", subtotal: removalsHeader7Subtotal },
      { type: "AccessArea", subtotal: accessAreaSubtotal },
      { type: "Tool", subtotal: toolSubtotal },
    ];

    const gst = storeTaxArray.find((tax) => tax.TaxCode === "Gst");
    // const pst = storeTaxArray.find(tax => tax.TaxCode === 'PST');

    if (gst) {
      taxitems.forEach((item) => {
        const { type, subtotal } = item;

        if (type === "Material") {
          gstTax += (gst.Rate / 100) * subtotal;
          // pstTax += (pst.Rate / 100) * subtotal;
        } else {
          gstTax += (gst.Rate / 100) * subtotal;
        }
      });
    }

    gstTax = gstTax.toFixed(2);
    // pstTax = pstTax.toFixed(2);

    // setEstimatePST(pstTax);
    setEstimateGST(gstTax);

    const grandTotal = Number(gstTax) + Number(subtotal.toFixed(2));
    setProjectGrandTotal(grandTotal);
    setDeposit(grandTotal * 0.1);
  };

  const UpdateUIBasedOnStatus = async (quoteobj) => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));

    if (userInfo.Role === UserRole.Manager) {
      if (quoteobj.QuoteStatus === QuoteStatus.RevisionRequested) {
        setRevisionDeclineDisplay("block");
        setRevisionAcceptDisplay("block");
      }
      setCloneProposalButtonDisplay("block");
    } else if (userInfo.Role === UserRole.Estimator) {
      setCloneProposalButtonDisplay("block");
    }
  };

  const ChangeUIToMakeRevisions = async () => {
    setHeaderInputShown(3);
    setMakeRevisionsButtonDisplay("none");
    setDiscardButtonDisplay("block");
    setSaveRevisionsButtonDisplay("block");
  };

  const UpdateQuoteItemQuantity = async (itemid, itemquantity) => {
    var reverseQuantity = await ReverseQuant_QuoteItem(itemid, itemquantity);
    Quote.Material.Items.find(function (o, i) {
      if (o.Id === itemid) {
        o.Quant = itemquantity;
        o.Price = reverseQuantity * o.UnitPrice;
        setSupplyTotalOpacity("40%");
        setInstallTotalOpacity("40%");
      }
      return null;
    });
    //Change Delete State to force a rerender in React
    setDeleteState(!deleteState);
  };

  const ValidateAgreement = async (supplyonly, caseid, quoteid) => {
    // if (supplyonly === true) {
    // var databuy = await SetQuoteTo_Buy(quoteid);
    // if (databuy !== null) {
    // PushToPayment(deposit.toFixed(2), caseid, quoteid);
    // } else setErrorAgreementText("Please try again!");
    // } else {
    // var databuyinstall = await SetQuoteTo_Buy_Install(quoteid);
    // if (databuyinstall !== null) {
    var depositSelect =
      deposit < Stored_Values.MIN_DEPOSIT ? Stored_Values.MIN_DEPOSIT : deposit;
    PushToPayment(depositSelect.toFixed(2), caseid, quoteid);
    // } else setErrorAgreementText("Please try again!");
    // }
  };

  const PushToPayment = async (amount, caseid, quoteid) => {
    var data = await GetCaseById(caseid);
    if (data !== null) {
      if (data.Case !== null) {
        var token = axios.defaults.headers.common["Authorization"];
        if (token) {
          var tokenRef = token.substring(6);
        } else {
          tokenRef = "am9zaHRlc3QxMDU6QWExMjM0NTY3OCQ=";
        }
        var name =
          data.Case.Name +
          ", " +
          data.Case.Street +
          "," +
          data.Case.City +
          " on Project # " +
          data.Case.CaseNumber;
        var transactionid = data.Case.CaseNumber;
        var paymenturl = await MakeStripePay(
          amount,
          "CAD",
          name,
          transactionid,
          quoteid,
          data.Case.Store_Id,
          tokenRef,
          0
        );
        if (paymenturl !== null) {
          window.open(paymenturl, "_blank");
        }
      }
    }
  };

  const SetCurrentTime = async () => {
    var curtime = await GetCurrentTime();
    setCurTime(curtime);
  };

  const ref = React.createRef();
  const ref2 = React.createRef();
  const options = {
    orientation: "portrait",
    unit: "in",
    format: [12, 40],
  };

  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));

  const SetQuoteDisplay = async (quote) => {
    if (!quote) return;

    const { Role } = JSON.parse(sessionStorage.getItem("userInfo"));

    if (
      Role === UserRole.Installer ||
      Role === UserRole.Manager ||
      Role === UserRole.Estimator
    ) {
      setLaborDetailView("block");
    }

    if (
      Role === UserRole.Manager ||
      Role === UserRole.Estimator ||
      Role === UserRole.Accountant
    ) {
      setShowGenerateInvoice("block");
      setShowLabourBreakdown("flex");
    }

    if (
      quote.Material?.Items.some(
        (item) => item.Parent_Id === 0 && item.Tag_Id === 0
      )
    )
      setGeneralMaterialsHeader("flex");

    if (quote.Delivery?.Tools?.length > 0) setDeliveryHeader("flex");

    if (quote.Tool?.Tools?.length > 0) setToolsMachinesHeader("flex");

    if (quote.Removal?.Items?.length > 0) setRemovalHeader("flex");

    if (quote.AccessArea?.Items?.length > 0) setAccessDisplay("flex");
  };

  const RouteToPrevious = async (role) => {
    if (role == UserRole.User || role == UserRole.VIP) {
      history.push(
        "/viewcaseuser/" +
          params.Id +
          "/" +
          params.storeId +
          "/" +
          params.caseId
      );
    } else if (params.lastrevision == 0) {
      history.push(
        "/viewcase/" + params.Id + "/" + params.storeId + "/" + params.caseId
      );
    } else {
      history.push(
        "/viewcaseuser/" +
          params.Id +
          "/" +
          params.storeId +
          "/" +
          params.caseId
      );
    }
  };

  //Project Comment Files
  const [previewImage, setPreviewImage] = useState("");

  const inputFile = useRef(null);

  const [totalRemovalsByItem, setTotalRemovalsByItem] = useState({});

  function computeRemovalsTotal(removalsArray, labourArray) {
    const totalRemovalsByItem = {};
    const assignedLabours = new Set();

    removalsArray.forEach((removal, removalIndex) => {
      let totalRemovals = 0;

      labourArray.forEach((labour) => {
        if (assignedLabours.has(labour.Id)) return; // Skip already assigned labours

        const removalMatch = removalsArray.reduce((closest, r) => {
          if (
            labour.Notes.includes("Removals") &&
            labour.Notes.split("-").some((part) => r.Name.includes(part.trim()))
          ) {
            const currentDiff = Math.abs(
              r.Price + r.LaborSubtotal - labour.Price
            );
            const closestDiff = closest
              ? Math.abs(closest.Price + closest.LaborSubtotal - labour.Price)
              : Infinity;

            return currentDiff < closestDiff ? r : closest;
          }
          return closest;
        }, null);

        if (
          removalMatch &&
          (removalMatch.Id === removal.Id ||
            removalMatch.Parent_Id === removal.Id)
        ) {
          totalRemovals += labour.Quant;
          assignedLabours.add(labour.Id); // Mark the labour as assigned
        }
      });

      totalRemovalsByItem[`${removal.Name}_${removalIndex}`] = totalRemovals;
    });

    setTotalRemovalsByItem(totalRemovalsByItem);
  }

  useEffect(() => {
    SetHeaderAuthorization();
    GetProposalInformation(params.quoteId);
    GetProjectInformation(params.caseId);
    DisplayDepositButton();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let header7Subtotal = 0;
    let header8Subtotal = 0;

    Quote?.Removal?.Items.forEach((o) => {
      header7Subtotal += o.Price + o.LaborSubtotal;
      header8Subtotal += o.Price + o.LaborSubtotal;
    });

    setRemovalsHeader8Subtotal(header8Subtotal);
  }, [Quote?.Removal?.Items]);

  useEffect(() => {
    if (params.numberofquotes === 0) {
      setMakeRevisionsButtonDisplay("none");
      setHideProjectConfirmationDisplay("none");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newUntaggedMaterialSubtotal = 0;
    let newUntaggedInstallSubtotal = 0;
    let newUntaggedTotalSubtotal = 0;

    Quote?.Material?.Items.forEach((o) => {
      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
        const materialSub = o.SubtotalWithRelatedItems;
        const installSub = o.LaborSubtotalWithRelatedItems;

        newUntaggedMaterialSubtotal += materialSub;
        newUntaggedInstallSubtotal += installSub;
        newUntaggedTotalSubtotal += materialSub + installSub;
      }
    });

    setUntaggedMaterialSubtotal(newUntaggedMaterialSubtotal);
    setUntaggedInstallSubtotal(newUntaggedInstallSubtotal);
    setUntaggedTotalSubtotal(newUntaggedTotalSubtotal);
  }, [Quote?.Material?.Items]);

  useEffect(() => {
    SetCurrentTime();
    SetQuoteDisplay(Quote);
  }, [Quote]); // eslint-disable-line react-hooks/exhaustive-deps

  var observe;
  if (window.attachEvent) {
    observe = function (element, event, handler) {
      element?.attachEvent("on" + event, handler);
    };
  } else {
    observe = function (element, event, handler) {
      element?.addEventListener(event, handler, false);
    };
  }
  function init() {
    var text = document.getElementById("text");
    if (text !== null) {
      function resize() {
        text.style.height = "auto";
        text.style.height = text.scrollHeight + "px";
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      observe(text, "change", resize);
      observe(text, "cut", delayedResize);
      observe(text, "paste", delayedResize);
      observe(text, "drop", delayedResize);
      observe(text, "keydown", delayedResize);

      resize();
    }
  }

  useEffect(() => {
    init();
  }, [Quote.Notes]); // eslint-disable-line react-hooks/exhaustive-deps

  const [deliveryDisplayStatus, setDeliveryDisplayStatus] = useState(false);
  const [removalDisplayStatus, setRemovalDisplayStatus] = useState(false);

  const RemovalItemsNoTag =
    Quote?.Removal?.Items?.filter(
      (removalItem) => removalItem.Tag_Id === 0
    ).map((item) => {
      const originalRemovalIndex = Quote.Removal.Items.findIndex(
        (removal) => removal.Id === item.Id
      );
      return {
        ...item,
      };
    }) || []; // Default to an empty array if undefined

  const totalRemovalCost = RemovalItemsNoTag.reduce(
    (sum, item) => sum + item.Price + item.LaborSubtotal,
    0
  );
  const totalRemovalQuantity = RemovalItemsNoTag.reduce(
    (sum, item) => sum + item.Quant,
    0
  );

  // let totalAggregatedDeliveryCost = 0;

  // Quote?.Material?.Items.forEach(o => {
  //   if (o.Parent_Id === 0 && o.Tag_Id === 0) {
  //     // Check deliveries for the untagged item itself
  //     Quote.Delivery.Tools.forEach(tool => {
  //       if (o.Id === tool.Item_Id) {
  //         totalAggregatedDeliveryCost += tool.Price;
  //       }
  //     });

  //     // Check deliveries for the related items of the untagged item

  //   }
  //   if (o.RelatedItems) {
  //     o.RelatedItems.forEach(relatedItem => {
  //       Quote.Delivery.Tools.forEach(tool => {
  //         if (relatedItem.Id === tool.Item_Id) {
  //           totalAggregatedDeliveryCost += tool.Price;
  //         }
  //       });
  //     });
  //   }
  // });

  let totalAggregatedDeliveryCost = 0;

  Quote?.Material?.Items.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      // Check deliveries for the untagged item itself
      Quote.Delivery.Tools.forEach((tool) => {
        if (o.Id === tool.Item_Id) {
          totalAggregatedDeliveryCost += tool.Price;
        }
      });

      // Check deliveries for the related items of the untagged item
      if (o.RelatedItems && o.RelatedItems.length > 0) {
        addRelatedItemsDeliveryCost(o.RelatedItems, Quote.Delivery.Tools);
      }
    }

    // For items that aren't untagged but have related items
    if (o.RelatedItems && o.Parent_Id !== 0) {
      addRelatedItemsDeliveryCost(o.RelatedItems, Quote.Delivery.Tools);
    }
  });

  function addRelatedItemsDeliveryCost(relatedItems, tools) {
    relatedItems.forEach((relatedItem) => {
      tools.forEach((tool) => {
        if (relatedItem.Id === tool.Item_Id) {
          totalAggregatedDeliveryCost += tool.Price;
        }
      });

      // Check for sub-related items
      if (relatedItem.RelatedItems && relatedItem.RelatedItems.length > 0) {
        addRelatedItemsDeliveryCost(relatedItem.RelatedItems, tools);
      }
    });
  }

  console.log("Total Aggregated Delivery Cost:", totalAggregatedDeliveryCost);

  let DeliveryItemsNoTag = [];

  Quote?.Material?.Items.forEach((o) => {
    if (o.Parent_Id === 0 && o.Tag_Id === 0) {
      Quote.Delivery.Tools.forEach((tool) => {
        if (o.Id === tool.Item_Id) {
          DeliveryItemsNoTag.push(tool);
        }
      });
    }
    if (o.RelatedItems && o.RelatedItems.length > 0) {
      o.RelatedItems.forEach((relatedItem) => {
        Quote.Delivery.Tools.forEach((tool) => {
          if (relatedItem.Id === tool.Item_Id) {
            DeliveryItemsNoTag.push(tool);
          }
        });
      });
    }
  });

  // function convertHoursToTimeString(businessHours) {
  //   const HOURS_IN_DAY = 8; // Business hours in a day
  //   const DAYS_IN_WEEK = 5; // Business days in a week
  //   const HOURS_IN_WEEK = HOURS_IN_DAY * DAYS_IN_WEEK; // Business hours in a week

  //   if (businessHours < HOURS_IN_DAY) {
  //     return "1 day";
  //   } else if (businessHours < HOURS_IN_DAY * 4) {
  //     let days = Math.floor(businessHours / HOURS_IN_DAY);
  //     return `${days}-${days + 1} days`;
  //   } else if (businessHours <= HOURS_IN_WEEK) {
  //     return "1-2 weeks";
  //   } else {
  //     let weeks = Math.floor(businessHours / HOURS_IN_WEEK);
  //     let remainingHours = businessHours % HOURS_IN_WEEK;

  //     let lowerWeekBound = weeks;
  //     let upperWeekBound = weeks + 1;

  //     if (remainingHours > 0 && remainingHours <= HOURS_IN_DAY * 4) {
  //       upperWeekBound = weeks + 1; // Changed this line to ensure no more than a 1-week range
  //     }

  //     if (weeks < 5) {
  //       return `${lowerWeekBound}-${upperWeekBound} weeks`;
  //     } else {
  //       let lowerMonthBound = Math.floor(weeks / 4); // Approximating 4 weeks per month
  //       let upperMonthBound = lowerMonthBound + 1;
  //       return `${lowerMonthBound}-${upperMonthBound} months`;
  //     }
  //   }
  // }

  function convertHoursToTimeString(businessHours) {
    const HOURS_IN_DAY = 8; // Business hours in a day

    if (businessHours < HOURS_IN_DAY) {
      return "Less than 1 Business Day";
    } else {
      // Calculate the lower and upper bounds for business days
      let lowerDayBound = Math.floor(businessHours / HOURS_IN_DAY);
      let upperDayBound = Math.ceil(businessHours / HOURS_IN_DAY);

      // If the upper and lower bounds are the same, it means it's exactly that many days
      if (lowerDayBound === upperDayBound) {
        return `${lowerDayBound} Business Day(s)`;
      } else {
        // Provide a range of days
        return `${lowerDayBound}-${upperDayBound} Business Days`;
      }
    }
  }

  if (Quote !== null && Quote !== undefined) {
    if (Quote.Material !== null && Quote.Material !== undefined) {
      if (window.$ISMOBILE === true) {
        return (
          <Cont>
            <LeftCont>
              <Menu> </Menu>
            </LeftCont>
            <FlexColumn81vwLightGrey>
              <TopMentProposalView
                mobilePageProp={estimateName}
                pageProp={projectName}
                estimateName={estimateName}
                homeownerName={homeownerName}
                projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
                scrollinfo={() => {
                  scrollToOverview.scrollIntoView();
                }}
                scrollsupply={() => {
                  scrollToSupply.scrollIntoView();
                }}
                scrollinstall={() => {
                  scrollToInstall.scrollIntoView();
                }}
                scrolldesigns={() => {
                  scrollToDesigns.scrollIntoView();
                }}
                scrollcomments={() => {
                  scrollToComments.scrollIntoView();
                }}
                scrollconfirmation={() => {
                  scrollToConfirm.scrollIntoView();
                }}
              ></TopMentProposalView>

              <FlexRowContFlexStartMobileColumn
                height="auto"
                display={
                  Quote.QuoteStatus === QuoteStatus.RevisionRequested
                    ? "none"
                    : "flex"
                }
              ></FlexRowContFlexStartMobileColumn>

              {/* Proposal Overview --------------------------------------------------------------- */}
              <RevisionInstructionsWindow
                open={revisionInstructionDisplay}
                CloseWindow={() => {
                  setRevisionInstructionDisplay(false);
                }}
                StartRevising={() => {
                  ChangeUIToMakeRevisions();
                  setRevisionInstructionDisplay(false);
                }}
              ></RevisionInstructionsWindow>

              <DuplicateProposalWindow
                quoteObj={Quote}
                onClose={() => {
                  setCloneProposalWindowDisplay(false);
                }}
                open={cloneProposalWindowDisplay}
              ></DuplicateProposalWindow>
              <ImageViewWindow
                open={imageGalleryDisplay}
                imgurl={imagePreviewUrl}
                onClose={() => {
                  setImageGalleryDisplay(false);
                  document.body.style.overflow = "unset";
                }}
              ></ImageViewWindow>

              <ContentCont77vw height="auto" width="75vw">
                <FlexColumnFullWidthMargins>
                  <br></br>
                  <FlexRowContFlexStart height="auto">
                    <LeagueSpartanLarge>Estimate Details</LeagueSpartanLarge>

                    {userinfo.Role !== UserRole.Manager &&
                    Role !== UserRole.Estimator ? null : (
                      <Dropdown currentPage={"homeownerView"} />
                    )}
                  </FlexRowContFlexStart>
                  <br></br>
                  <TextAreaAutoResizeNoBorder
                    id="text"
                    value={Quote.Notes}
                  ></TextAreaAutoResizeNoBorder>
                </FlexColumnFullWidthMargins>
              </ContentCont77vw>

              <ContentCont44Percent height="auto" justifycontent="space-around">
                <FlexColumnFullWidthMargins height="100%">
                  <br></br>
                  <FlexRowContFlexStart height="auto">
                    <LeagueSpartanLarge>Summary</LeagueSpartanLarge>
                  </FlexRowContFlexStart>
                  <br></br>
                  <SummaryContainer height="100%" justifycontent="space-around">
                    {/* <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    > */}
                    {/* <LeagueSpartanHeaderSmall>Material Supply</LeagueSpartanHeaderSmall> */}
                    {/* </SummaryContainerItems> */}
                    <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                      <MediumHeader>Materials:</MediumHeader>
                      <MediumHeader>
                        {" "}
                        $
                        {Quote?.Material?.Total?.Subtotal?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems
                      display={
                        Quote?.Delivery?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    >
                      <MediumHeader>Delivery:</MediumHeader>
                      <MediumHeader>
                        {" "}
                        $
                        {Quote?.Delivery?.Total?.Subtotal?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>

                    {/* <SummaryContainerItems
                      display={
                        Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                      }
                    > */}
                    {/* <LeagueSpartanHeaderSmall>Installation</LeagueSpartanHeaderSmall> */}
                    {/* </SummaryContainerItems> */}

                    <SummaryContainerItems
                      display={
                        Number(projectInstallSubtotal).toFixed(2) >
                        Stored_Values.MIN_LABOR
                          ? "flex"
                          : "none"
                      }
                    >
                      <MediumHeader>Labour:</MediumHeader>
                      <MediumHeader>
                        $
                        {Number(
                          projectInstallSubtotal + removalsHeader8Subtotal
                        )
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems
                      display={
                        Number(projectInstallSubtotal).toFixed(2) >
                        Stored_Values.MIN_LABOR
                          ? "none"
                          : "flex"
                      }
                    >
                      <MediumHeader>Minimum Labour Fee:</MediumHeader>
                      <MediumHeader>
                        ${Stored_Values?.MIN_LABOR?.toFixed(2)}
                      </MediumHeader>
                    </SummaryContainerItems>
                    <SummaryContainerItems>
                      <MediumHeader>
                        Machines, Disposals, Site Access
                      </MediumHeader>

                      <MediumHeader>
                        ${" "}
                        {(
                          parseFloat(
                            Quote?.Tool?.Total?.Subtotal?.toFixed(2).replace(
                              /,/g,
                              ""
                            )
                          ) +
                          parseFloat(
                            Quote?.AccessArea?.Total?.Subtotal?.toFixed(
                              2
                            ).replace(/,/g, "")
                          )
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>
                    {/* <SummaryContainerItems
                      display={
                        'flex'
                      }
                    >
                      <MediumHeader>Removals:</MediumHeader>
                      <MediumHeader>
                        ${" "}
                        {((removalsHeader8Subtotal))
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems> */}

                    <StyledHRPropColour
                      border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                    ></StyledHRPropColour>
                    <SummaryContainerItems>
                      <LeagueSpartanHeaderSmall>
                        Project Subtotal:
                      </LeagueSpartanHeaderSmall>
                      <MediumHeader>
                        $
                        {
                          projectSubtotal
                          // .toFixed(2)
                          // .toString()
                          // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      </MediumHeader>
                    </SummaryContainerItems>

                    <SummaryContainerItems>
                      <MediumHeader>
                        {storeTaxes[0]?.TaxCode} ({storeTaxes[0]?.Rate}%)
                      </MediumHeader>
                      <MediumHeader>
                        $
                        {estimateGST
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems>

                    {/* <SummaryContainerItems>
                  <MediumHeader>
                      {storeTaxes[1]?.TaxCode} ({storeTaxes[1]?.Rate}%)
                    </MediumHeader>
                    <MediumHeader>
                      ${estimatePST.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </MediumHeader>
                    </SummaryContainerItems> */}

                    <StyledHRGreenBreak></StyledHRGreenBreak>
                    <SummaryContainerItems>
                      <LeagueSpartanRegular>
                        Project Total:
                      </LeagueSpartanRegular>
                      <LeagueSpartanRegular>
                        {" "}
                        $
                        {projectGrandTotal
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </LeagueSpartanRegular>
                    </SummaryContainerItems>
                    <FlexColumnContCenterAlign>
                      <RegularResponsiveButton
                        fontsize="14px"
                        lineheight="30px"
                        width="95%"
                        height="30px"
                        display={showDepositButton}
                        onClick={() => {
                          ValidateAgreement(
                            supplyOnlySelected,
                            params.caseId,
                            params.quoteId
                          );
                        }}
                      >
                        Pay Deposit $
                        {deposit < Stored_Values.MIN_DEPOSIT
                          ? Stored_Values.MIN_DEPOSIT.toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : deposit
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </RegularResponsiveButton>
                      {/* <RegularResponsiveButton
                        display={
                          userinfo.Role === UserRole.Manager ? "block" : "none"
                        }
                        onClick={() => {
                          history.push(
                            `/quoteappointment/${params.Id}/${params.storeId}/${params.quoteId}`
                          );
                        }}
                      >
                        Make Appointment
                      </RegularResponsiveButton> */}
                      <TinyTextItalics color={ColorPalette.DarkGrey}>
                        Project deposit is non-refundable. By paying the
                        deposit, you have agreed that you have read and
                        understood our{" "}
                        <span>
                          {" "}
                          <a
                            rel="noreferrer"
                            href="https://newrhodesconstruction.com/rl-terms-of-service/"
                            target="_blank"
                          >
                            {" "}
                            Terms of Service{" "}
                          </a>{" "}
                        </span>{" "}
                      </TinyTextItalics>
                    </FlexColumnContCenterAlign>
                  </SummaryContainer>
                </FlexColumnFullWidthMargins>
              </ContentCont44Percent>

              <ContentCont77vw
                display={Quote.Photos.length > 0 ? "block" : "none"}
              >
                <FlexColumnFullWidth>
                  <FlexRowContFlexStart>
                    <LeagueSpartanLarge>Construction Plans</LeagueSpartanLarge>
                  </FlexRowContFlexStart>

                  <br></br>
                  <GridContainer3Wide>
                    {Quote.Photos.map((o, i) => {
                      return o.File?.includes(".pdf") ||
                        o.File?.includes(".PDF") ? (
                        <FlexContainerPhotos key={i}>
                          <a
                            id={i}
                            rel="noreferrer"
                            href={o.File !== "" ? window.$IMGURL + o.File : ""}
                            target={"_blank"}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <embed
                              target={"_blank"}
                              src={"/pdficon.png"}
                              width={"150px"}
                              style={{ backgroundColor: "white" }}
                              height={"200px"}
                            ></embed>
                            <FlexContFullWidthHeightJustifyCenter>
                              <LargeText>{o.Caption}</LargeText>
                            </FlexContFullWidthHeightJustifyCenter>
                          </a>
                        </FlexContainerPhotos>
                      ) : (
                        <FlexContainerPhotos key={i}>
                          <Image375px
                            onClick={() => {
                              DisplayImageInGallery(o.File);
                            }}
                            src={o.File !== "" ? window.$IMGURL + o.File : ""}
                          ></Image375px>
                          <FlexContFullWidthHeightJustifyCenter>
                            <LargeText>{o.Caption}</LargeText>
                          </FlexContFullWidthHeightJustifyCenter>
                        </FlexContainerPhotos>
                      );
                    })}
                  </GridContainer3Wide>
                </FlexColumnFullWidth>
              </ContentCont77vw>

              <FlexRowContAutox100PercentMobileColumn
                alignitems="baseline"
                margin="0"
              >
                <ContentContQuote id="install">
                  <FlexColumnFullWidth>
                    <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                      <FlexColumnFullWidth mobilewidth="90vw">
                        <FlexRowContFlexStart>
                          <LeagueSpartanLarge>
                            Project Estimate
                          </LeagueSpartanLarge>
                        </FlexRowContFlexStart>
                      </FlexColumnFullWidth>
                    </FlexRowCont100PercentSpaceBetween>
                  </FlexColumnFullWidth>

                  <FlexColumnFullWidth
                    display={installEstimateDisplay}
                    height="auto"
                    ref={ref2}
                  >
                    <AutoSizeOverflowY>
                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {Quote.Tags.map(
                        (t, j) => (
                          (MaterialSum = 0),
                          (InstallSum = 0),
                          (TotalSum = 0),
                          (
                            <div>
                              <div>
                                <br></br>

                                <FlexRowCont100PercentSpaceBetween>
                                  <LeagueSpartanRegular>
                                    {t.TagName}
                                  </LeagueSpartanRegular>
                                </FlexRowCont100PercentSpaceBetween>

                                <StyledHRGreenBreakThick></StyledHRGreenBreakThick>

                                {Quote.Material.Items.filter(
                                  (item) =>
                                    item.Tag_Id == t.Id && item.Parent_Id === 0
                                ).map((o, i) => (
                                  <MobileQuoteItem
                                    noChange={o.NoChange}
                                    key={i}
                                    inputheaderposition={headerInputShown}
                                    OutputValue={(value) => {
                                      UpdateQuoteItemQuantity(o.Id, value);
                                    }}
                                    quoteItemObj={o}
                                    parentid={o.Parent_Id}
                                    header1="Delete"
                                    header2={o.Name}
                                    subheader2notes={
                                      o.Notes == null ? "" : o.Notes + ""
                                    }
                                    subheader2={
                                      o.Description === null
                                        ? ""
                                        : o.Description
                                    }
                                    header3={
                                      o.Quant.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      ) +
                                      " " +
                                      o.DisplayUnit
                                    }
                                    header4={
                                      "$" +
                                      o.UnitPrice.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header5={
                                      "$" +
                                      o.Price.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header6={
                                      "$" +
                                      (o.LaborSubtotal / o.Quant).toFixed(4)
                                    }
                                    header7={
                                      "$" +
                                      o.LaborSubtotal.toFixed(2).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    header8={
                                      "$" +
                                      (
                                        parseFloat(o.Price.toFixed(2)) +
                                        parseFloat(o.LaborSubtotal.toFixed(2))
                                      )
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                    header3title="Quantity"
                                    header4title="Unit Rate"
                                    header5title="Material Subtotal"
                                    header6title="Install Rate"
                                    header7title="Install Subtotal"
                                  ></MobileQuoteItem>
                                ))}
                              </div>
                            </div>
                          )
                        )
                      )}

                      <div>
                        <br></br>

                        <FlexRowCont100PercentSpaceBetween>
                          <LeagueSpartanRegular
                            display={generalMaterialsHeader}
                          >
                            General Materials
                          </LeagueSpartanRegular>
                        </FlexRowCont100PercentSpaceBetween>

                        <StyledHRGreenBreakThick
                          display={generalMaterialsHeader}
                        ></StyledHRGreenBreakThick>

                        {Quote.Material.Items.map((o, i) => {
                          return o.Parent_Id === 0 && o.Tag_Id === 0 ? (
                            <MobileQuoteItem
                              key={i}
                              quoteItemObj={o}
                              inputheaderposition={headerInputShown}
                              OutputValue={(value) => {
                                UpdateQuoteItemQuantity(o.Id, value);
                              }}
                              noChange={o.NoChange}
                              parentid={o.Parent_Id}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={
                                o.Description === null ? "" : o.Description
                              }
                              subheader2notes={
                                o.Notes == null ? "" : o.Notes + ""
                              }
                              header3={o.Quant.toFixed(2) + " " + o.DisplayUnit}
                              header4={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header5={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header6={
                                "$" + (o.LaborSubtotal / o.Quant).toFixed(4)
                              }
                              header7={
                                "$" +
                                o.LaborSubtotal.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                (
                                  parseFloat(o.Price.toFixed(2)) +
                                  parseFloat(o.LaborSubtotal.toFixed(2))
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              header3title="Quantity"
                              header4title="Unit Rate"
                              header5title="Material Subtotal"
                              header6title="Install Rate"
                              header7title="Install Subtotal"
                            ></MobileQuoteItem>
                          ) : null;
                        })}
                      </div>

                      <LeagueSpartanRegular display={deliveryHeader}>
                        Delivery
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        display={deliveryHeader}
                      ></StyledHRGreenBreakThick>

                      {/* {Quote.Delivery.Tools.map((o, i) => ( */}
                      <MobileQuoteItem
                        // key={i}
                        // header1=" "
                        header2={"Delivery Cost"}
                        header8={
                          "$" +
                          deliveryTotal
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></MobileQuoteItem>
                      {/* ))} */}

                      <LeagueSpartanRegular display={removalHeader}>
                        Removals
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={removalHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Removal.Items.map((o, i) => (
                        <MobileQuoteItem
                          key={i}
                          header1="Delete"
                          header2={o.Name}
                          subheader2notes={o.Notes == null ? "" : o.Notes + ""}
                          header3={
                            o.Quant.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            ) +
                            " " +
                            o.DisplayUnit
                          }
                          header4={
                            "$" +
                            ((o.Price + o.LaborSubtotal) / o.Quant)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header5={
                            "$" +
                            (o.Price + o.LaborSubtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header8={
                            "$" +
                            (o.Price + o.LaborSubtotal)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          header3title="Quantity"
                          header4title="Removal Rate"
                          header5title="Removal Subtotal"
                        ></MobileQuoteItem>
                      ))}

                      {/* <LeagueSpartanRegular display={accessDisplay}>
                        Access Area
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        display={accessDisplay}
                      ></StyledHRGreenBreakThick> */}

                      {/* {Quote.AccessArea.Items.map((o, i) => ( */}

                      {/* ))} */}

                      <LeagueSpartanRegular display={toolsmachinesHeader}>
                        Machines, Disposal, Access
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={toolsmachinesHeader}
                      ></StyledHRGreenBreakThick>

                      {Quote.Tool.Tools.map((o, i) => {
                        return o?.ToolName.includes("Wheelbarrow") ||
                          o?.ToolName.includes("Suggested") ? null : (
                          <MobileQuoteItem
                            key={i}
                            header1="Delete"
                            header2={o.ToolName}
                            subheader2={o.Notes !== null ? o.Notes : ""}
                            itemLink={o.VideoUrl}
                            header3={
                              // o.RentalLength > 0
                              //   ? o.RentalLength.toFixed(2).replace(
                              //       /\B(?=(\d{3})+(?!\d))/g,
                              //       ","
                              //     ) + " hr"
                              //   :
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header4={
                              o.RentalRate > 0
                                ? "$" +
                                  (o.RentalRate * o.RentalLength)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                            }
                            header5={
                              o.RentalRate > 0 && o.BasePrice > 0
                                ? "$" +
                                  o.BasePrice.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                : ""
                            }
                            header8={
                              "$" +
                              o.Price.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )
                            }
                            header3title="Quantity"
                            header4title="Rental Rate"
                            header5title={
                              o.RentalRate > 0 && o.BasePrice > 0
                                ? "Move Cost Per Machine"
                                : ""
                            }
                          ></MobileQuoteItem>
                        );
                      })}
                      {accessTotal > 0 ? (
                        <MobileQuoteItem
                          // key={i}
                          // header1="Delete"
                          header2={"Access Area Cost"}
                          header8={
                            "$" +
                            accessTotal
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          // subheader2={o.Notes}
                          // header3={
                          //   o.Quant.toFixed(2).replace(
                          //     /\B(?=(\d{3})+(?!\d))/g,
                          //     ","
                          //   ) +
                          //   " " +
                          //   o.DisplayUnit
                          // }
                          // header4={
                          //   "$" +
                          //   o.UnitPrice.toFixed(2).replace(
                          //     /\B(?=(\d{3})+(?!\d))/g,
                          //     ","
                          //   ) +
                          //   "" +
                          //   o.DisplayUnit
                          // }
                          // header5={
                          //   "$" +
                          //   o.Price.toFixed(2).replace(
                          //     /\B(?=(\d{3})+(?!\d))/g,
                          //     ","
                          //   )
                          // }

                          // header3title="Quantity"
                          // header4title="Access Rate"
                          // header5title="Access Subtotal"
                        ></MobileQuoteItem>
                      ) : null}

                      <LeagueSpartanRegular display={laborDetailToggleView}>
                        Labour Breakdown (Staff Only)
                      </LeagueSpartanRegular>

                      <StyledHRGreenBreakThick
                        mobiledisplay={laborDetailToggleView}
                      ></StyledHRGreenBreakThick>

                      <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>

                      {laborDetailToggleView === "flex"
                        ? Quote.Labor.Items.map((o, i) => (
                            <MobileQuoteItem
                              key={i}
                              header1="Delete"
                              header2={o.Name}
                              subheader2={o.Notes}
                              header6={
                                o.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                ) + " hr"
                              }
                              header7={
                                "$" +
                                o.UnitPrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                              header8={
                                "$" +
                                o.Price.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                            ></MobileQuoteItem>
                          ))
                        : null}

                      <br></br>
                    </AutoSizeOverflowY>
                  </FlexColumnFullWidth>
                </ContentContQuote>
              </FlexRowContAutox100PercentMobileColumn>
            </FlexColumn81vwLightGrey>

            <FixedIconToTop
              src="/BackToTopArrowAlt.svg"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            ></FixedIconToTop>
          </Cont>
        );
      }
      /////////////////////////// MOBILE ->>>>>>>>>>>>>

      /////////////////////////// DESKTOP ->>>>>>>>>>>>>

      return (
        <Cont>
          <LeftCont id="exclude-from-pdf">
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey
            minheight="auto"
            height="fit-content"
            id="estimate"
          >
            {/* <div id='exclude-from-pdf' style={{height: "100%", position: "relative"}}> */}
            <TopMentProposalView
              id="exclude-from-pdf"
              quoteObj={Quote}
              mobilePageProp={estimateName}
              pageProp={projectName}
              estimateName={estimateName}
              homeownerName={homeownerName}
              projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
              onBack={() => {
                RouteToPrevious(userinfo.Role);
              }}
              scrollinfo={() => {
                scrollToOverview.scrollIntoView();
              }}
              scrollsupply={() => {
                scrollToSupply.scrollIntoView();
              }}
              scrollinstall={() => {
                scrollToInstall.scrollIntoView();
              }}
              scrolldesigns={() => {
                scrollToDesigns.scrollIntoView();
              }}
              scrollcomments={() => {
                scrollToComments.scrollIntoView();
              }}
              scrollconfirmation={() => {
                scrollToConfirm.scrollIntoView();
              }}
            ></TopMentProposalView>
            {/* </div> */}
            {/* Approval --------------------------------------------------------------- */}

            <FlexRowContFlexStartMobileColumn
              height="auto"
              display={
                Quote.QuoteStatus === QuoteStatus.RevisionRequested
                  ? "none"
                  : "flex"
              }
            >
              <FlexRowCont alignitems="stretch" height="auto" width="100%">
                <ContentCont77vw
                  height="auto"
                  width="75vw"
                  id="exclude-from-pdf"
                >
                  <FlexColumnFullWidthMargins>
                    <br></br>
                    <FlexRowContFlexStart height="auto">
                      <LeagueSpartanLarge>Estimate Details</LeagueSpartanLarge>
                    </FlexRowContFlexStart>

                    <br></br>
                    <TextAreaAutoResizeNoBorder
                      id="text"
                      value={Quote.Notes}
                    ></TextAreaAutoResizeNoBorder>
                  </FlexColumnFullWidthMargins>
                </ContentCont77vw>
                <ContentCont77vw
                  display="none"
                  id="include-on-pdf"
                  width="75vw"
                  height="auto"
                  justifycontent="space-between"
                >
                  <FlexColumnFullWidthMargins
                    height="auto"
                    justifycontent="flex-start"
                  >
                    <Icon200x200 src="/NRCLogoRegularBlack.png"></Icon200x200>
                    <div>
                      <SmallHeader>New Rhodes Construction</SmallHeader>
                      <SmallHeader>
                        12306 McTavish Road Pitt Meadows BC V3Y1Z1
                      </SmallHeader>
                      <SmallHeader>+1 (778-657-5944)</SmallHeader>
                      <SmallHeader>ar@newrhodesconstruction.com</SmallHeader>
                      <SmallHeader>www.newrhodesconstruction.com</SmallHeader>
                      <SmallHeader>
                        GST/HST Registration No. 781153283RT0001
                      </SmallHeader>
                    </div>
                  </FlexColumnFullWidthMargins>

                  <FlexColumnFullWidthMargins
                    height="auto"
                    justifycontent="flex-start"
                  >
                    <div>
                      <SmallHeader>
                        <b> BILL TO: </b> {Case?.Name}
                      </SmallHeader>
                      <SmallHeader>{Case?.Email}</SmallHeader>
                      <SmallHeader>
                        {Case?.Street} {", "}
                        {Case?.City}
                      </SmallHeader>
                      <SmallHeader>{Case?.Phone}</SmallHeader>
                      <SmallHeader>
                        Date: {formatDate(Quote?.EditTime)}
                      </SmallHeader>
                      <br></br>
                      <TinyTextItalics color={ColorPalette.DarkGrey}>
                        Project deposit is non-refundable. By paying the
                        deposit, you have agreed that you have read and
                        understood our{" "}
                        <span>
                          {" "}
                          <a
                            rel="noreferrer"
                            href="https://newrhodesconstruction.com/rl-terms-of-service/"
                            target="_blank"
                          >
                            {" "}
                            Terms of Service{" "}
                          </a>{" "}
                        </span>{" "}
                      </TinyTextItalics>
                    </div>
                  </FlexColumnFullWidthMargins>
                </ContentCont77vw>
                <ContentCont77vw
                  width="75vw"
                  height="auto"
                  justifycontent="space-around"
                >
                  <FlexColumnFullWidthMargins height="100%">
                    <br></br>
                    <FlexRowContFlexStart height="auto">
                      <LeagueSpartanLarge>Summary</LeagueSpartanLarge>
                      <RegularResponsiveButton
                        id="exclude-from-pdf"
                        display={labourBreakdownDisplay}
                        onClick={downloadPDF}
                      >
                        Download PDF
                      </RegularResponsiveButton>
                    </FlexRowContFlexStart>
                    <SummaryContainer
                      height="100%"
                      justifycontent="space-around"
                    >
                      <SummaryContainerItems
                        display={
                          Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        {/* <LeagueSpartanHeaderSmall>Material Supply</LeagueSpartanHeaderSmall> */}
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Quote?.Material?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <MediumHeader>Materials:</MediumHeader>
                        <MediumHeader>
                          {" "}
                          $
                          {Quote?.Material?.Total?.Subtotal?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Quote?.Delivery?.Total?.Subtotal > 0 ? "flex" : "none"
                        }
                      >
                        <MediumHeader>Delivery:</MediumHeader>
                        <MediumHeader>
                          {" "}
                          $
                          {Quote?.Delivery?.Total?.Subtotal?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>

                      {/* <br></br> */}

                      <SummaryContainerItems
                        display={
                          Number(projectInstallSubtotal).toFixed(2) >
                          Stored_Values.MIN_LABOR
                            ? "flex"
                            : "none"
                        }
                      >
                        <MediumHeader>Labour:</MediumHeader>
                        <MediumHeader key={removalsHeader8Subtotal}>
                          $
                          {Number(
                            projectInstallSubtotal + removalsHeader8Subtotal
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems
                        display={
                          Number(projectInstallSubtotal).toFixed(2) >
                          Stored_Values.MIN_LABOR
                            ? "none"
                            : "flex"
                        }
                      >
                        <MediumHeader>Minimum Labour Fee:</MediumHeader>
                        <MediumHeader>
                          ${Stored_Values?.MIN_LABOR?.toFixed(2)}
                        </MediumHeader>
                      </SummaryContainerItems>
                      <SummaryContainerItems>
                        <MediumHeader>
                          Machines, Disposals, Site Access
                        </MediumHeader>

                        <MediumHeader>
                          ${" "}
                          {(
                            parseFloat(
                              Quote?.Tool?.Total?.Subtotal?.toFixed(2).replace(
                                /,/g,
                                ""
                              )
                            ) +
                            parseFloat(
                              Quote?.AccessArea?.Total?.Subtotal?.toFixed(
                                2
                              ).replace(/,/g, "")
                            )
                          )
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      {/* <SummaryContainerItems
                    >
                      <MediumHeader>Removals:</MediumHeader>
                      <MediumHeader>
                        ${" "}
                        {((removalsHeader8Subtotal).toFixed(2))
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </MediumHeader>
                    </SummaryContainerItems> */}

                      <StyledHRPropColour
                        border={`0.5px solid ${ColorPalette.PrimaryButtonBlue}`}
                      ></StyledHRPropColour>
                      <SummaryContainerItems>
                        <LeagueSpartanHeaderSmall>
                          Project Subtotal:
                        </LeagueSpartanHeaderSmall>
                        <MediumHeader>${projectSubtotal}</MediumHeader>
                      </SummaryContainerItems>

                      <SummaryContainerItems>
                        <MediumHeader>
                          {storeTaxes[0]?.TaxCode} ({storeTaxes[0]?.Rate}%)
                        </MediumHeader>
                        <MediumHeader>
                          $
                          {estimateGST
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </MediumHeader>
                      </SummaryContainerItems>
                      {/* <SummaryContainerItems>
                  <MediumHeader>
                      {storeTaxes[1]?.TaxCode} ({storeTaxes[1]?.Rate}%)
                    </MediumHeader>
                    <MediumHeader>
                      ${estimatePST.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </MediumHeader>
                    </SummaryContainerItems> */}
                      <StyledHRGreenBreak></StyledHRGreenBreak>
                      <SummaryContainerItems>
                        <LeagueSpartanRegular>
                          Project Total:
                        </LeagueSpartanRegular>
                        <LeagueSpartanRegular>
                          {" "}
                          $
                          {projectGrandTotal
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </LeagueSpartanRegular>
                      </SummaryContainerItems>
                      <SummaryContainerItems>
                        <LeagueSpartanRegular>
                          Projected Project Length:
                        </LeagueSpartanRegular>
                        <LeagueSpartanRegular>
                          {convertHoursToTimeString(
                            Quote.Labor.Items.reduce(
                              (total, o) => total + o?.Quant,
                              0
                            )
                          )}
                        </LeagueSpartanRegular>
                      </SummaryContainerItems>
                      <FlexColumnContCenterAlign id="exclude-from-pdf">
                        <RegularResponsiveButton
                          fontsize="14px"
                          lineheight="30px"
                          width="95%"
                          height="30px"
                          display={showDepositButton}
                          ref={buttonRef}
                          onClick={() => {
                            ValidateAgreement(
                              supplyOnlySelected,
                              params.caseId,
                              params.quoteId
                            );
                          }}
                        >
                          Pay Deposit $
                          {deposit < Stored_Values.MIN_DEPOSIT
                            ? Stored_Values.MIN_DEPOSIT.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : deposit
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        </RegularResponsiveButton>

                        <TinyTextItalics color={ColorPalette.DarkGrey}>
                          Project deposit is non-refundable. By paying the
                          deposit, you have agreed that you have read and
                          understood our{" "}
                          <span>
                            {" "}
                            <a
                              rel="noreferrer"
                              href="https://newrhodesconstruction.com/rl-terms-of-service/"
                              target="_blank"
                            >
                              {" "}
                              Terms of Service{" "}
                            </a>{" "}
                          </span>{" "}
                        </TinyTextItalics>
                      </FlexColumnContCenterAlign>
                    </SummaryContainer>
                  </FlexColumnFullWidthMargins>
                </ContentCont77vw>
              </FlexRowCont>
            </FlexRowContFlexStartMobileColumn>

            {/* Proposal Overview --------------------------------------------------------------- */}
            <RevisionInstructionsWindow
              open={revisionInstructionDisplay}
              CloseWindow={() => {
                setRevisionInstructionDisplay(false);
              }}
              StartRevising={() => {
                ChangeUIToMakeRevisions();
                setRevisionInstructionDisplay(false);
              }}
            ></RevisionInstructionsWindow>

            <ImageViewWindow
              open={imageGalleryDisplay}
              imgurl={imagePreviewUrl}
              onClose={() => {
                setImageGalleryDisplay(false);
                document.body.style.overflow = "unset";
              }}
            ></ImageViewWindow>
            <DuplicateProposalWindow
              quoteObj={Quote}
              onClose={() => {
                setCloneProposalWindowDisplay(false);
              }}
              open={cloneProposalWindowDisplay}
            ></DuplicateProposalWindow>

            <ContentCont77vw
              display={Quote.Photos.length > 0 ? "block" : "none"}
              id="exclude-from-pdf"
            >
              <FlexColumnFullWidth>
                <FlexRowContFlexStart>
                  <LeagueSpartanLarge>Construction Plans</LeagueSpartanLarge>
                </FlexRowContFlexStart>

                <br></br>
                <FlexRowCont60PercentSpaceEvenly
                  alignitems="baseline"
                  width="100%"
                  flexwrap="wrap"
                >
                  {Quote.Photos.map((o, i) => {
                    return o.File?.includes(".pdf") ||
                      o.File?.includes(".PDF") ? (
                      <FlexContainerItem key={i}>
                        <a
                          id={i}
                          rel="noreferrer"
                          href={o.File !== "" ? window.$IMGURL + o.File : ""}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          <embed
                            target={"_blank"}
                            href={o.File !== "" ? window.$IMGURL + o.File : ""}
                            src={
                              o.File !== ""
                                ? window.$IMGURL +
                                  o.File +
                                  "#toolbar=0&navpanes=0&scrollbar=0"
                                : ""
                            }
                            width={"350px"}
                            style={{ backgroundColor: "white" }}
                            height={"200px"}
                          ></embed>
                          <OverrideEmbedDiv>
                            <OverrideEmbedImage></OverrideEmbedImage>
                          </OverrideEmbedDiv>
                          <FlexContFullWidthHeightJustifyCenter>
                            <LargeText>{o.Caption}</LargeText>
                          </FlexContFullWidthHeightJustifyCenter>
                        </a>
                      </FlexContainerItem>
                    ) : (
                      <FlexContainerItem key={i}>
                        <Image375px
                          onClick={() => {
                            DisplayImageInGallery(o.File);
                          }}
                          src={o.File !== "" ? window.$IMGURL + o.File : ""}
                        ></Image375px>
                        <FlexContFullWidthHeightJustifyCenter>
                          <LargeText>{o.Caption}</LargeText>
                        </FlexContFullWidthHeightJustifyCenter>
                      </FlexContainerItem>
                    );
                  })}
                </FlexRowCont60PercentSpaceEvenly>
              </FlexColumnFullWidth>
            </ContentCont77vw>

            <FlexRowContAutox100PercentMobileColumn
              alignitems="baseline"
              margin="0"
            >
              <ContentContQuote id="install">
                <FlexColumnFullWidth>
                  <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                    <FlexColumnFullWidth>
                      <FlexRowContFlexStart>
                        <LeagueSpartanLarge>
                          Project Estimate
                        </LeagueSpartanLarge>
                      </FlexRowContFlexStart>
                    </FlexColumnFullWidth>
                  </FlexRowCont100PercentSpaceBetween>
                </FlexColumnFullWidth>

                <FlexColumnFullWidth
                  display={installEstimateDisplay}
                  height="auto"
                  ref={ref2}
                >
                  <AutoSizeOverflowY>
                    {Quote.Tags.map((t, j) => {
                      let MaterialSum = 0;
                      let InstallSum = 0;
                      let TotalSum = 0;

                      const RemovalItemsWithTag = Quote.Removal.Items.filter(
                        (removalItem) => removalItem.Tag_Id === t.Id
                      ).map((item) => {
                        const originalRemovalIndex =
                          Quote.Removal.Items.findIndex(
                            (removal) => removal.Id === item.Id
                          );
                        return {
                          ...item,
                        };
                      });

                      const totalRemovalCost = RemovalItemsWithTag.reduce(
                        (sum, item) => sum + item.Price + item.LaborSubtotal,
                        0
                      );
                      const totalRemovalTime = RemovalItemsWithTag.reduce(
                        (sum, item) => {
                          const originalRemovalIndex =
                            Quote.Removal.Items.findIndex(
                              (removal) => removal.Id === item.Id
                            );
                          return (
                            sum +
                            (totalRemovalsByItem[
                              `${item.Name}_${originalRemovalIndex}`
                            ] || 0)
                          );
                        },
                        0
                      );

                      const primaryItems = Quote.Material.Items.filter(
                        (item) => item.Tag_Id == t.Id && item.Parent_Id === 0
                      );

                      const matchedDeliveries = [];

                      function checkDeliveries(item) {
                        // Check for deliveries related to the item itself
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (item.Id === tool.Item_Id) {
                            matchedDeliveries.push({
                              ...tool,
                              Tag_Id: item.Tag_Id,
                            });
                          }
                        });

                        // If the item has related items, check for deliveries related to those as well
                        if (item.RelatedItems && item.RelatedItems.length > 0) {
                          item.RelatedItems.forEach((relatedItem) => {
                            checkDeliveries(relatedItem); // Recursive call for nested related items
                          });
                        }
                      }

                      primaryItems.forEach((primaryItem) => {
                        checkDeliveries(primaryItem);
                      });

                      let totalAggregatedDeliveryCostWithTagId = 0;

                      function aggregateDeliveryCost(item) {
                        // Check deliveries for the item itself
                        Quote.Delivery.Tools.forEach((tool) => {
                          if (item.Id === tool.Item_Id) {
                            totalAggregatedDeliveryCostWithTagId += tool.Price;
                          }
                        });

                        // If the item has related items, check deliveries for those as well
                        if (item.RelatedItems && item.RelatedItems.length > 0) {
                          item.RelatedItems.forEach((relatedItem) => {
                            aggregateDeliveryCost(relatedItem); // Recursive call for nested related items
                          });
                        }
                      }

                      primaryItems.forEach((primaryItem) => {
                        aggregateDeliveryCost(primaryItem);
                      });

                      primaryItems.forEach((o) => {
                        const materialSub = o.SubtotalWithRelatedItems;
                        const installSub = o.LaborSubtotalWithRelatedItems;

                        // Quote.Removal.Items.filter(removalItem => removalItem.Tag_Id === t.Id).forEach((o) => {
                        //   const removalTotal = o.Price + o.LaborSubtotal;
                        //   MaterialSum += removalTotal;  // Assuming removals are treated as material costs
                        //   TotalSum += removalTotal;
                        // });

                        MaterialSum += materialSub;
                        InstallSum += installSub;
                        TotalSum += materialSub + installSub;
                      });

                      Quote.Removal.Items.filter(
                        (removalItem) => removalItem.Tag_Id === t.Id
                      ).forEach((o) => {
                        const removalTotal = o.Price + o.LaborSubtotal;
                        // MaterialSum += removalTotal;  // Assuming removals are treated as material costs
                        TotalSum += removalTotal;
                      });

                      MaterialSum += totalAggregatedDeliveryCostWithTagId;
                      TotalSum += totalAggregatedDeliveryCostWithTagId;

                      return (
                        <div key={j}>
                          <div>
                            <br></br>
                            {primaryItems.length > 0 ||
                            RemovalItemsWithTag.length > 0 ? (
                              <QuoteItem
                                isHeader={true}
                                header2={t.TagName}
                                // header3="Quantity"
                                // header4="Unit Rate"
                                // header5="Material Subtotal"
                                // header6="Install Rate"
                                // header7="Install Subtotal"
                                // header8="Line Subtotal"
                                bgColor={ColorPalette.White}
                              ></QuoteItem>
                            ) : null}

                            {primaryItems.length > 0 ||
                            RemovalItemsWithTag.length > 0 ? (
                              <StyledHRGreenBreakThick></StyledHRGreenBreakThick>
                            ) : null}

                            {primaryItems.map((o, i) => {
                              const materialSub =
                                displayStatus[o.Id] === true
                                  ? o.Price
                                  : o.SubtotalWithRelatedItems;
                              const installSub =
                                displayStatus[o.Id] === true
                                  ? o.LaborSubtotal
                                  : o.LaborSubtotalWithRelatedItems;

                              return (
                                <QuoteItem
                                  CategoryType={CategoryTypes.Material}
                                  noChange={o.NoChange}
                                  key={i}
                                  showRelatedItems={displayStatus[o.Id]}
                                  onToggleDisplay={() => toggleDisplay(o.Id)}
                                  inputheaderposition={headerInputShown}
                                  OutputValue={(value) => {
                                    UpdateQuoteItemQuantity(o.Id, value);
                                  }}
                                  quoteItemObj={o}
                                  parentid={o.Parent_Id}
                                  header1="Delete"
                                  header2={o.Name}
                                  subheader2notes={
                                    o.Notes == null ? "" : o.Notes + ""
                                  }
                                  subheader2={
                                    o.Description === null ? "" : o.Description
                                  }
                                  header3={
                                    o.Quant.toFixed(2).replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    ) +
                                    " " +
                                    o.DisplayUnit
                                  }
                                  header4={
                                    "$" + (materialSub / o.Quant).toFixed(4)
                                  }
                                  header5={
                                    "$" +
                                    materialSub
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header6={
                                    "$" + (installSub / o.Quant).toFixed(4)
                                  }
                                  header7={
                                    "$" +
                                    installSub
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                  header8={
                                    "$" +
                                    (
                                      parseFloat(materialSub.toFixed(2)) +
                                      parseFloat(installSub.toFixed(2))
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                ></QuoteItem>
                              );
                            })}

                            {RemovalItemsWithTag.length > 0 ? (
                              <div key={`removal-tag`}>
                                <QuoteItem
                                  CategoryType={CategoryTypes.Removal}
                                  header1="Delete"
                                  header2="Removal"
                                  header3={`${
                                    parseFloat(
                                      RemovalItemsWithTag.reduce(
                                        (accumulator, item) =>
                                          accumulator + item.Quant,
                                        0
                                      )
                                    ).toFixed(2) || "0.00"
                                  } ${RemovalItemsWithTag[0].DisplayUnit}`}
                                  RemovalItems={RemovalItemsWithTag}
                                  showRelatedItems={removalDisplayStatus[t.Id]}
                                  RefreshComponentState={() => {
                                    GetProposalInformation(params.quoteId);
                                  }}
                                  deleteItemFromEstimate={(itemId) => {
                                    if (itemId) {
                                      setDeleteItemId(itemId);
                                    } else {
                                      console.log("no item passed");
                                    }
                                    setDeleteItemConfirmDisplay(true);
                                  }}
                                  onToggleDisplay={() =>
                                    toggleRemovalDisplay(t.Id)
                                  }
                                  // header4={
                                  //   removalDisplayStatus === false
                                  //   ? (
                                  //     (() => {
                                  //       const totalQuantity = parseFloat(RemovalItemsNoTag.reduce((accumulator, item) => accumulator + item.Quant, 0));
                                  //       const divisionResult = totalRemovalCost / totalQuantity;
                                  //       return `$${divisionResult.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                                  //     })()
                                  //   )
                                  //   : ''
                                  // }
                                  // header9={removalDisplayStatus[t.Id] === true ? '' :  decimalHoursToTimeString(totalRemovalTime)}
                                  header8={
                                    removalDisplayStatus[t.Id] === true
                                      ? ""
                                      : "$" +
                                        totalRemovalCost
                                          .toFixed(2)
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  }
                                ></QuoteItem>
                              </div>
                            ) : null}

                            {totalAggregatedDeliveryCostWithTagId > 0 ? (
                              <QuoteItem
                                key={`tagged-aggregated-delivery`}
                                quoteItemObj={{
                                  Price: totalAggregatedDeliveryCostWithTagId,
                                }}
                                showRelatedItems={displayStatus[t.Id]}
                                RefreshComponentState={() => {
                                  GetProposalInformation(params.quoteId);
                                }}
                                deleteItemFromEstimate={async (item_id) => {
                                  setIsLoading(true);
                                  await DeleteQuoteTool(item_id);
                                  GetProposalInformation(params.quoteId);
                                  setIsLoading(false);
                                }}
                                CategoryType={"Delivery"}
                                onToggleDisplay={() => toggleDisplay(t.Id)}
                                DeliveryItems={matchedDeliveries}
                                header1="Delete"
                                header2="Deliveries"
                                header3=""
                                header4=""
                                header8={
                                  displayStatus[t.Id] === true
                                    ? ""
                                    : "$" +
                                      totalAggregatedDeliveryCostWithTagId
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                }
                              ></QuoteItem>
                            ) : null}

                            {primaryItems.length > 0 ||
                            RemovalItemsWithTag.length > 0 ? (
                              <QuoteItem
                                isHeader={true}
                                header2="Subtotal"
                                // header5={"$" + MaterialSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                // header7={"$" + InstallSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                header8={
                                  "$" +
                                  TotalSum.toFixed(2).replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                bgColor={ColorPalette.White}
                              ></QuoteItem>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}

                    {generalMaterialsHeader === "flex" ? <br></br> : null}

                    {generalMaterialsHeader === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="General Materials"
                        // header3="Quantity"
                        // header4="Unit Rate"
                        // header5="Material Subtotal"
                        // header6="Install Rate"
                        // header7="Install Subtotal"
                        // header8="Line Subtotal"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}

                    <StyledHRGreenBreakThick
                      display={generalMaterialsHeader}
                    ></StyledHRGreenBreakThick>

                    {Quote?.Material?.Items.map((o, i) => {
                      if (o.Parent_Id === 0 && o.Tag_Id === 0) {
                        const materialSub =
                          displayStatus[o.Id] === true
                            ? o.Price
                            : o.SubtotalWithRelatedItems;
                        const installSub =
                          displayStatus[o.Id] === true
                            ? o.LaborSubtotal
                            : o.LaborSubtotalWithRelatedItems;

                        return (
                          <QuoteItem
                            CategoryType={CategoryTypes.Material}
                            showRelatedItems={displayStatus[o.Id]}
                            onToggleDisplay={() => toggleDisplay(o.Id)}
                            key={i}
                            quoteItemObj={o}
                            inputheaderposition={headerInputShown}
                            OutputValue={(value) => {
                              UpdateQuoteItemQuantity(o.Id, value);
                            }}
                            noChange={o.NoChange}
                            parentid={o.Parent_Id}
                            header1="Delete"
                            header2={o.Name}
                            subheader2={
                              o.Description === null ? "" : o.Description
                            }
                            subheader2notes={
                              o.Notes == null ? "" : o.Notes + ""
                            }
                            header3={
                              o.Quant.toFixed(2).replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              ) +
                              " " +
                              o.DisplayUnit
                            }
                            header4={"$" + (materialSub / o.Quant).toFixed(4)}
                            header5={
                              "$" +
                              materialSub
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            header6={"$" + (installSub / o.Quant).toFixed(4)}
                            header7={
                              "$" +
                              installSub
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                            header8={
                              "$" +
                              (
                                parseFloat(materialSub.toFixed(2)) +
                                parseFloat(installSub.toFixed(2))
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }
                          ></QuoteItem>
                        );
                      }
                      return null;
                    }).concat(
                      RemovalItemsNoTag.length > 0 ? (
                        <div key={`removal-no-tag`}>
                          <QuoteItem
                            CategoryType={CategoryTypes.Removal}
                            deleteItemFromEstimate={(itemId) => {
                              if (itemId) {
                                setDeleteItemId(itemId);
                              } else {
                                setDeleteItemId(o.Id);
                              }
                              setDeleteItemConfirmDisplay(true);
                            }}
                            RefreshComponentState={() => {
                              GetProposalInformation(params.quoteId);
                            }}
                            header1="Delete"
                            header2="Removal"
                            header3={`${
                              parseFloat(
                                RemovalItemsNoTag.reduce(
                                  (accumulator, item) =>
                                    accumulator + item.Quant,
                                  0
                                )
                              ).toFixed(2) || "0.00"
                            } ${
                              RemovalItemsNoTag.length > 0
                                ? RemovalItemsNoTag[0].DisplayUnit
                                : ""
                            }`}
                            RemovalItems={RemovalItemsNoTag}
                            showRelatedItems={removalDisplayStatus}
                            onToggleDisplay={() => {
                              setRemovalDisplayStatus(!removalDisplayStatus);
                            }}
                            header4={
                              removalDisplayStatus === false
                                ? (() => {
                                    const totalQuantity = parseFloat(
                                      RemovalItemsNoTag.reduce(
                                        (accumulator, item) =>
                                          accumulator + item.Quant,
                                        0
                                      )
                                    );
                                    const divisionResult =
                                      totalRemovalCost / totalQuantity;
                                    return `$${divisionResult
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                                  })()
                                : ""
                            }
                            header8={
                              removalDisplayStatus === false
                                ? "$" +
                                  totalRemovalCost
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                : ""
                            }
                          ></QuoteItem>
                        </div>
                      ) : null,
                      totalAggregatedDeliveryCost > 0 ? (
                        <QuoteItem
                          key={`total-aggregated-delivery`}
                          quoteItemObj={{ Price: totalAggregatedDeliveryCost }}
                          DeliveryItems={DeliveryItemsNoTag}
                          CategoryType={"Delivery"}
                          RefreshComponentState={() => {
                            GetProposalInformation(params.quoteId);
                          }}
                          showRelatedItems={deliveryDisplayStatus}
                          onToggleDisplay={() => {
                            setDeliveryDisplayStatus(!deliveryDisplayStatus);
                          }}
                          header1="Delete"
                          header2="Deliveries"
                          header3=""
                          header4=""
                          header8={
                            deliveryDisplayStatus === false
                              ? "$" +
                                totalAggregatedDeliveryCost
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : ""
                          }
                        ></QuoteItem>
                      ) : null,
                      generalMaterialsHeader === "flex" ? (
                        <QuoteItem
                          isHeader={true}
                          key={"untagged_subtotals"}
                          header2={"Subtotal"}
                          bgColor={"White"}
                          // header5={`$${untaggedMaterialSubtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          // header7={`$${untaggedInstallSubtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          header8={`$${(
                            untaggedTotalSubtotal +
                            totalRemovalCost +
                            totalAggregatedDeliveryCost
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                        />
                      ) : null
                    )}

                    {toolsmachinesHeader === "flex" ? (
                      <QuoteItem
                        isHeader={true}
                        header2="Machines, Disposal, Access"
                        bgColor={ColorPalette.White}
                      ></QuoteItem>
                    ) : null}
                    <StyledHRGreenBreakThick
                      display={toolsmachinesHeader}
                    ></StyledHRGreenBreakThick>
                    {Quote.Tool.Tools.sort((a, b) =>
                      a.ToolName.localeCompare(b.ToolName)
                    ).map((o, i) => {
                      if (!o?.ToolName.includes("Wheelbarrow")) {
                        if (o.RentalRate > 0 && o.BasePrice > 0) {
                          toolsHeader7Subtotal += o.BasePrice;
                        }
                        toolsHeader8Subtotal += o.Price;
                      }

                      return o?.ToolName.includes("Wheelbarrow") ||
                        o?.ToolName.includes("Suggested") ? null : (
                        // <QuoteItem
                        //   CategoryType={'Tool'}
                        //   key={i}
                        //   header1="Delete"
                        //   header2={o.ToolName}
                        //   subheader2={o.Notes !== null ? o.Notes : ""}
                        //   // header6={
                        //   //   o.RentalRate > 0
                        //   //     ? "$" +
                        //   //       (o.RentalRate * o.RentalLength).toFixed(2).replace(
                        //   //         /\B(?=(\d{3})+(?!\d))/g,
                        //   //         ","
                        //   //       )
                        //   //     : "$" +
                        //   //       o.BasePrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        //   // }
                        //   itemLink={o.VideoUrl}
                        //   header7={
                        //     o.RentalRate > 0 && o.BasePrice > 0
                        //       ? "$" +
                        //         o.BasePrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        //       : ""
                        //   }
                        //   header5={
                        //     `${o.RentalLength} hours`

                        //   }
                        //   header3={
                        //     o.Quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        //   }
                        //   header8={
                        //     "$" +
                        //     o.Price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        //   }
                        // ></QuoteItem>
                        <QuoteItem
                          CategoryType={"Tool"}
                          key={i}
                          header1="Delete"
                          header2={o.ToolName}
                          subheader2={o.Notes !== null ? o.Notes : ""}
                          header4={
                            o.RentalRate > 0
                              ? `$${o.RentalRate.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}`
                              : null
                          }
                          header6={
                            o.BasePrice > 0
                              ? `$${o.BasePrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}`
                              : null
                          }
                          header7={
                            o.RentalRate > 0 && o.BasePrice > 0
                              ? `$${o.BasePrice.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}`
                              : ""
                          }
                          header3={
                            o.RentalLength > 0
                              ? `${o.RentalLength} hours`
                              : typeof o?.Quant === "number" && !isNaN(o.Quant)
                              ? o.Quant.toFixed(2).replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              : ""
                          }
                          header9={o.RentalLength > 0 ? o.RentalLength : null}
                          // header3={o?.Quant.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          // header8={o.RentalRate > 0 && o.BasePrice > 0 ? `$${o.BasePrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : ""}
                          header8={`$${o.Price.toFixed(2).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}`}
                          itemLink={o.VideoUrl}
                        ></QuoteItem>
                      );
                    })}
                    {accessTotal > 0 ? (
                      <div>
                        <QuoteItem
                          CategoryType={CategoryTypes.Access}
                          header2={"Access Area Cost"}
                          header8={
                            "$" +
                            accessTotal
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                        ></QuoteItem>
                      </div>
                    ) : null}

                    {(toolsHeader7Subtotal > 0 || toolsHeader8Subtotal > 0) && (
                      <QuoteItem
                        isHeader={true}
                        bgColor={ColorPalette.White}
                        header2={`Subtotal`}
                        // header7={"$" + toolsHeader7Subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        header8={
                          "$" +
                          (toolsHeader8Subtotal + accessTotal)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      ></QuoteItem>
                    )}
                    {toolsmachinesHeader === "flex" ? (
                      <div>
                        <br></br>
                        <br></br>
                      </div>
                    ) : null}
                    <FlexRowContFullWidth
                      id="exclude-from-pdf"
                      onClick={() => {
                        DisplayLaborBreakdown(laborDetailToggle);
                      }}
                    ></FlexRowContFullWidth>
                    <StyledHRGreenBreakThick
                      display={laborDetailToggleView}
                    ></StyledHRGreenBreakThick>
                    <StyledHRPropColour border="0.5px solid white"></StyledHRPropColour>
                  </AutoSizeOverflowY>
                </FlexColumnFullWidth>
              </ContentContQuote>
            </FlexRowContAutox100PercentMobileColumn>

            {/* Comments  --------------------------------------------------------------- */}
          </FlexColumn81vwLightGrey>

          <FixedIconToTop
            src="/BackToTopArrowAlt.svg"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          ></FixedIconToTop>
          <EstimateFooter
            depositAmount={
              deposit < Stored_Values.MIN_DEPOSIT
                ? Stored_Values.MIN_DEPOSIT
                : deposit
            }
            buttonRef={buttonRef}
            depositCallback={() => {
              ValidateAgreement(
                supplyOnlySelected,
                params.caseId,
                params.quoteId
              );
            }}
          ></EstimateFooter>
        </Cont>
      );
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

ViewProposalUserView.defaultProps = {
  ProfileName: "Default Name",
};

export default ViewProposalUserView;
