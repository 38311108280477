import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import RelatedProduct from "../../RelatedProduct.svg";
import RelatedTool from "../../RelatedTool.svg";
import {
  Cont180pxAutoHeight,
  DivWithDisplay,
  DivWithMarginPadding,
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont100x8PercentSpaceAround,
  FlexRowCont60PercentSpaceEvenly,
  FlexRowContFullWidth,
  GridDiv,
} from "../../StylesheetComps/Cont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  RegularText,
  TitleFontSize100PercentPointer,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexDivSpaceBetween } from "../../StylesheetComps/Div";
import { Icon20x20, Icon30x30 } from "../../StylesheetComps/Imgs/Icons";

const ProductCard = ({
  ProdHeadText,
  ProdPrice,
  ProdUrl,
  onView,
  relProduct,
  relTool,
  relAddOn,
  onSelect,
}) => {
  useEffect(() => {
    GetSessionInfo();
  }, []);

  const [roleNum, setRoleNum] = useState(1000);
  const GetSessionInfo = async () => {
    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

    return (
      <FlexRowContFullWidth onClick={()=>{onSelect()}} hovermargin='5px' hoverbgcolor={ColorPalette.LightGrey}>
      <FlexDivSpaceBetween width='58%'>
      <FlexDiv justifycontent='space-between' width='40vw'>
        <FlexDiv width='100%'>
        <Icon20x20 src='/Inventory.svg' />
        <RegularText onClick={() => {
              onView();
            }}>
           {ProdHeadText}
        </RegularText>
        </FlexDiv>
        <FlexDiv width='20%'>
        <RegularText >
           {" $" + ProdPrice.toFixed(2)}
        </RegularText>
        </FlexDiv>
        </FlexDiv>
        <FlexDiv>
        <Icon30px30pxPointer
              src={RelatedProduct}
              onClick={relProduct}
            ></Icon30px30pxPointer>
         <Icon30px30pxPointer
              src={RelatedTool}
              onClick={relTool}
            ></Icon30px30pxPointer>
              <Icon30px30pxPointer
              src={"/AddOn_Icon.svg"}
              onClick={relAddOn}
            ></Icon30px30pxPointer>
        </FlexDiv>
        </FlexDivSpaceBetween>
      </FlexRowContFullWidth>
      // <DivWithMarginPadding width="310px" height="110px" >
        
      //     <DivWithDisplay flexdirection="row">
      //       <DivWithDisplay>
      //       <TitleFontSize100PercentPointer onClick={onView}>
      //         {ProdHeadText}
      //       </TitleFontSize100PercentPointer>
      //       </DivWithDisplay>
      //       <DivWithDisplay height="5px" width="10px"></DivWithDisplay>
      //       <DivWithDisplay>
      //       <RegularText>{" $" + ProdPrice.toFixed(2)}</RegularText>
      //       </DivWithDisplay>
      //     </DivWithDisplay>

      //     <DivWithDisplay flexdirection="row" width="80%">
      //       <DivWithDisplay>
      //       <Icon30px30pxPointer
      //         src={RelatedProduct}
      //         onClick={relProduct}
      //       ></Icon30px30pxPointer>
      //       </DivWithDisplay>
      //       <DivWithDisplay>
      //       <Icon30px30pxPointer
      //         src={RelatedTool}
      //         onClick={relTool}
      //       ></Icon30px30pxPointer>
      //       </DivWithDisplay>
      //       <DivWithDisplay>
      //       <Icon30px30pxPointer
      //         src={"/AddOn_Icon.svg"}
      //         onClick={relAddOn}
      //       ></Icon30px30pxPointer>
      //       </DivWithDisplay>
            
            
            
      //     </DivWithDisplay>
         
      // </DivWithMarginPadding>
    );
  
};

ProductCard.defaultProps = {
  ProdHeadText: "Default Product Name",
  ProdParaText: "This item is great and makes your yard look much nicer",
  ProdButtonText: "Edit Suppliers",
  ProdId: 0,
  ProdUrl: "",
  ProdSuppliers: "Supplier Name",
  ProductSupplierArray: ["Default Name"],
  ProdPrice: 0,
};

export default ProductCard;
