import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  GetQuoteById
} from "../../API_Functions/CaseQuote_Functions";
import { GetCurrentTime, SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import ActionMenu from "../../comps/ActionMenu";
import InstallerQuoteAppCard from "../../comps/InstallerQuoteAppCard";
import Menu from "../../comps/MenuComps/Menu";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  DivWithDisplay,
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn, FullWidthMobileCont100vw100vh
} from "../../StylesheetComps/Cont";
import { LeagueSpartanExtraLargeBold, SmallHeaderOpenSansBold } from "../../StylesheetComps/Titles";
// Import of react-datepicker CSS
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmBiddingDateWindow from "../../comps/Windows/ConfirmBiddingDateWindow";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexColumnDiv } from "../../StylesheetComps/Div";

const QuoteAppointmentScreen = () => {
  //=============================================================================
  // Quote Appointment Screen - User redirected from PaySuccess on successful
  // Stripe payment.
  //=============================================================================

  //=============================================================================
  // This page displays next available dates for Installers to take their Quotes
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [installerDates, setInstallerDates] = useState([]);

  const [userMetroId, setUserMetroId] = useState(0);

  const [caseStoreId, setCaseStoreId] = useState(0);

  const [startDate, setStartDate] = useState(new Date());

  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [Quote, setQuote] = useState();
  const GetSessionInfo = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    setUserMetroId(userInfo.Metro_Id);
  };

  const GetCaseQuote = async (quoteid) => {
    var data = await GetQuoteById(quoteid);
    if (data !== null) {
      setQuote(data.quote);
      var casedata = await GetCaseById(data.quote.Case_Id);
      if (casedata !== null) {
        setCaseStoreId(casedata.Case.Store_Id);
        GetInstallerEarliestTimes(casedata.Case.Store_Id);
      }
    }
  };

  const GetInstallerEarliestTimes = async (storeid) => {
    const curtime = await GetCurrentTime();
    let resp;
    if (storeid === 0) {
      resp = await axios.post(
        `${window.$DBURL}appointment/quote/store/${caseStoreId}/installerdates`,
        { Quote_Id: params.quoteId, CurTime: curtime }
      );
    } else {
      resp = await axios.post(
        `${window.$DBURL}appointment/quote/store/${storeid}/installerdates`,
        { Quote_Id: params.quoteId, CurTime: curtime }
      );
    }
    if (resp.status === 200 && resp.data && resp.data.installerdates) {
      setInstallerDates(resp.data.installerdates);
    }
  };

  useEffect(() => {
    GetSessionInfo();
    SetHeaderAuthorization();
    GetCaseQuote(params.quoteId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <LeagueSpartanExtraLargeBold>
            Earliest Date Available For Installers
          </LeagueSpartanExtraLargeBold>
          <DivWithDisplay>
            <DivWithDisplay width="50%">
              Please select the earliest date for a installer to complete your
              project without interruption, or choose an earlier date to start
              if you are ok with interruptions. Alternatively, you can put your
              project up to receive bids from non-affiliated installers that may
              start your project earlier with a little higher install charge.
            </DivWithDisplay>
            <FlexColumnDiv>
              <ConfirmBiddingDateWindow
                quote={Quote}
                open={confirmWindowOpen}
                width="400px"
                top="30%"
                left={`35%`}
                leftbuttoncolor={ColorPalette.PrimaryRed}
                leftbuttontext={`Cancel`}
                LeftFunction={() => {
                  setConfirmWindowOpen(false);
                }}
                rightbuttoncolor={ColorPalette.PrimaryButtonBlue}
                rightbuttontext={`Confirm Date`}
                startdate={`${moment(startDate).format(
                  "LL"
                )}`}
              ></ConfirmBiddingDateWindow>
              <RegularResponsiveButton
                onClick={() => {
                  setConfirmWindowOpen(true);
                }}
              >
                Put Estimate up for Bid
              </RegularResponsiveButton>
            </FlexColumnDiv>
          </DivWithDisplay>
          <br></br>
          <SmallHeaderOpenSansBold>Earliest Available Start Dates:</SmallHeaderOpenSansBold>
          <br></br>
          {installerDates.map((o, i) => (
            <InstallerQuoteAppCard
              metroId={userMetroId}
              installerUserId={o.User_Id}
              storeId={caseStoreId}
              InstallerName={o.Name}
              year={o.Date.substring(0, 4)}
              month={o.Date.substring(5, 7)}
              day={o.Date.substring(8, 10)}
            ></InstallerQuoteAppCard>
          ))}
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

QuoteAppointmentScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default QuoteAppointmentScreen;
