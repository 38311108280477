import React, { useState } from 'react';
import styled from 'styled-components';

const DialogWrapper = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Cover the full width of the screen */
  height: 100%; /* Cover the full height of the screen */
  z-index: 1000;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  @media (max-width: 1006px) {
    width: 95vw;
  }
`;

const DialogContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const DialogHeader = styled.h2`
  font-family: 'LeagueSpartan-Bold', sans-serif;
`;

const DialogBody = styled.p`
  font-family: 'Avenir', sans-serif;
`;

const StyledButton = styled.button`
  margin: 5px;
  padding: 10px;
  cursor: pointer;
  /* You can customize the styling further as needed */
`;


const ConfirmationDialog = ({ show, onCancel, onConfirm }) => (
    <DialogWrapper show={show}>
      <DialogContent>
        <DialogHeader>Confirm Action</DialogHeader>
        <DialogBody>Are you sure you want to save the estimate?</DialogBody>
        <StyledButton onClick={onCancel}>Cancel</StyledButton>
        <StyledButton onClick={onConfirm}>Save</StyledButton>
      </DialogContent>
    </DialogWrapper>
  );

  ConfirmationDialog.defaultProps = {

  }

export default ConfirmationDialog;
