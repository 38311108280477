import React, { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont100Percent50Percent,
  Cont60PercentMargin20px,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { LeagueSpartanExtraLargeBold } from "../../../StylesheetComps/Titles";

const AddManagerWindow = ({ open, onClose, onFormComplete, onPreview }) => {
  const [managerName, setManagerName] = useState("");
  const [managerUsername, setManagerUsername] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [managerPass, setManagerPass] = useState("");
  const [metroId] = useState(0);
  const [storeId] = useState(0);

  if (!open) return null;
  return (
    <Cont60PercentMargin20px>
      <LeagueSpartanExtraLargeBold>Create Manager</LeagueSpartanExtraLargeBold>
      <LeftItemWindowFixed>
        <Cont100Percent50Percent>
          <LeagueSpartanExtraLargeBold>
            Name of Manager
          </LeagueSpartanExtraLargeBold>
          <TextInput72Percent
            placeholder="Name"
            onChange={(e) => {
              setManagerName(e.target.value);
            }}
            autoComplete="new-password"
          ></TextInput72Percent>
          <LeagueSpartanExtraLargeBold>Username</LeagueSpartanExtraLargeBold>
          <TextInput72Percent
            placeholder="Username"
            onChange={(e) => {
              setManagerUsername(e.target.value);
            }}
            autoComplete="new-password"
          ></TextInput72Percent>
          <LeagueSpartanExtraLargeBold>Email</LeagueSpartanExtraLargeBold>
          <TextInput72Percent
            placeholder="Email"
            onChange={(e) => {
              setManagerEmail(e.target.value);
            }}
            autoComplete="new-password"
          ></TextInput72Percent>
        </Cont100Percent50Percent>
        <Cont100Percent50Percent>
          <LeagueSpartanExtraLargeBold>Phone</LeagueSpartanExtraLargeBold>

          <TextInput72Percent
            placeholder="Phone"
            onChange={(e) => {
              setManagerPhone(e.target.value);
            }}
            autoComplete="new-password"
          ></TextInput72Percent>
          <LeagueSpartanExtraLargeBold>Password</LeagueSpartanExtraLargeBold>

          <TextInput72Percent
            placeholder="Password"
            type="password"
            onChange={(e) => {
              setManagerPass(e.target.value);
            }}
            autoComplete="new-password"
          ></TextInput72Percent>
          <RegularResponsiveButton
            onClick={() => {
              onPreview({
                managerName,
                managerUsername,
                managerEmail,
                managerPhone,
                managerPass,
                metroId,
                storeId,
              });
              onFormComplete(
                managerName,
                managerUsername,
                managerEmail,
                managerPhone,
                managerPass,
                metroId,
                storeId
              );
            }}
          >
            Add
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </Cont100Percent50Percent>
      </LeftItemWindowFixed>
    </Cont60PercentMargin20px>
  );
};

AddManagerWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
};

export default AddManagerWindow;
