/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddCase,
  GetCaseObject,
  SetCaseTo_Submit,
  UpdateACase,
} from "../../API_Functions/CaseQuote_Functions";
import SaveProjectWindow from "../../comps/Estimate Form/ConfirmSave";
import Drafted from "../../comps/Estimate Form/Drafted";
import LandscapeDesc from "../../comps/Estimate Form/Landscape Desc";
import PersonalInfo from "../../comps/Estimate Form/Personal Info";
import PhotoUploadComp from "../../comps/Estimate Form/Photo Upload";
import ProjectArea from "../../comps/Estimate Form/Project Area";
import ProjectBudget from "../../comps/Estimate Form/Project Budget";
import ProjectOverview from "../../comps/Estimate Form/Project Overview";
import ProjectPath from "../../comps/Estimate Form/Project Path";
import ProjectScope from "../../comps/Estimate Form/Project Scope";
import ProjectStyle from "../../comps/Estimate Form/Project Style";
import ProjectDescType from "../../comps/Estimate Form/ProjectDescType";
import Submitted from "../../comps/Estimate Form/Submitted";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import ScrollToTop from "../../comps/ScrollToTop";

import { GetStoreByMetro } from "../../API_Functions/Store_Functions";
import { GetCurrentTime } from "../../API_Functions/Utility_Functions";
import {
  EstimatePageMainFlexCont,
  EstimatePageRightCont,
  EstimatePageRightView,
} from "../../StylesheetComps/LandscapeForm";
const EstimateScreen = () => {
  //=============================================================================
  // Estimate Screen / Hack my Yard Form - All Users can access
  //=============================================================================

  //=============================================================================
  // Users fill out their information and create their case from this page
  //=============================================================================

  const params = useParams();

  var [caseObj, setCaseObj] = useState({});

  const [userInfo, setUserInfo] = useState({});

  //Form Navigation

  const [personalInfoOpen, setPersonalInfoOpen] = useState(true);
  const [projectPathOpen, setProjectPathOpen] = useState(false);
  const [projectAreaOpen, setProjectAreaOpen] = useState(false);
  const [projectScopeOpen, setProjectScopeOpen] = useState(false);
  const [projectDescTypeOpen, setProjectDescTypeOpen] = useState(false);
  const [landscapeDescOpen, setLandscapeDescOpen] = useState(false);
  const [projectStyleOpen, setProjectStyleOpen] = useState(false);
  const [projectBudgetOpen, setProjectBudgetOpen] = useState(false);
  const [photoUploadOpen, setPhotoUploadOpen] = useState(false);
  const [reviewOpen, setReviewOpen] = useState(false);
  const [submittedOpen, setSubmittedOpen] = useState(false);
  const [draftedOpen, setDraftedOpen] = useState(false);
  const [saveCaseOpen, setSaveCaseOpen] = useState(false);

  //Project Functionality Page

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("[Project Name]");

  const [surveyPath, setSurveyPath] = useState("");

  const [projectArea, setProjectArea] = useState("");

  const [projectScope, setProjectScope] = useState("");

  const [projectType, setProjectType] = useState("");

  const [projectStyle, setProjectStyle] = useState("N/A");

  const [projectBudget, setProjectBudget] = useState("");

  const [allImgsArray, setAllImgsArray] = useState([]);
  const [pdfArray, setPDFArray] = useState([]);

  const [metroIdOfUser, setMetroIdOfUser] = useState(0);
  const [storeIdOfUser, setStoreIdOfUser] = useState(0);

  const [roleNum, setRoleNum] = useState(1000);

  //Define the user's default information

  const GetPersonalInfo = async () => {
    let userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
    setMetroIdOfUser(userinfo.Metro_Id);
    setStoreIdOfUser(userinfo.Store_Id);
    setRoleNum(userinfo.Role);
  };

  //When the user submits their personal information

  const SetCaseInformation = async ({ ContactInfo, Title }) => {
    caseObj.Tittle = Title;
    caseObj.Phone = ContactInfo.current.Phone;
    caseObj.Name = ContactInfo.current.Name;
    caseObj.Email = ContactInfo.current.Email;
    caseObj.Street = ContactInfo.current.Street;
    caseObj.Metro_Id = ContactInfo.current.Metro_Id;
    caseObj.Metro = ContactInfo.current.Metro;
    var stores = await GetStoreByMetro(caseObj.Metro_Id);
    if (stores !== null) {
      caseObj.Store_Id = stores[0].Id;
    }
    caseObj.City = ContactInfo.current.City;
    caseObj.City_Id = ContactInfo.current.City_Id;
    caseObj.CreateTime = await GetCurrentTime();
    setUserInfo(ContactInfo.current);
    setTitle(Title);
  };

  const UpdateLocalProjectInfo = async ({
    contactinfo,
    ProjectArea,
    ProjectScope,
    ProjectType,
    ProjectStyle,
    ProjectBudget,
    ProjectDescription,
  }) => {
    caseObj.Phone = contactinfo.Phone;
    caseObj.Name = contactinfo.Name;
    caseObj.Email = contactinfo.Email;
    caseObj.Street = contactinfo.Street;
    caseObj.Metro_Id = contactinfo.Metro_Id;
    caseObj.Metro = contactinfo.Metro;
    caseObj.City = contactinfo.City;
    caseObj.City_Id = contactinfo.City_Id;
    caseObj.Tittle = title;
    caseObj.Description =
      "Project Area:" +
      ProjectArea +
      ", Project Scope:" +
      ProjectScope +
      ", Project Type:" +
      ProjectType +
      ", Project Style:" +
      ProjectStyle +
      ",Project Budget:" +
      ProjectBudget +
      ", Description:" +
      ProjectDescription;
    setProjectArea(ProjectArea);
    setProjectScope(ProjectScope);
    setProjectType(ProjectType);
    setProjectStyle(ProjectStyle);
    setProjectBudget(ProjectBudget);
    setDescription(ProjectDescription);
  };

  //Save the Description as a global variable

  const SetDescription = async ({ description }) => {
    setDescription(description);
  };

  const PushImgsToArray = async ({
    streetView,
    accessTo,
    panorama,
    areaOfWork,
    imgArray,
    PDFMap,
  }) => {
    var requiredImages = [];
    if (streetView !== "") {
      requiredImages.push({ File: streetView });
    }
    if (accessTo !== "") {
      requiredImages.push({ File: accessTo });
    }
    if (panorama !== "") {
      requiredImages.push({ File: panorama });
    }
    if (areaOfWork !== "") {
      requiredImages.push({ File: areaOfWork });
    }
    setAllImgsArray(imgArray.concat(requiredImages));
    setPDFArray(PDFMap);
  };

  const CreateCase = async (caseobj, submitted) => {
    if (caseobj.Id !== 0 && caseobj.Id !== null && caseobj.Id !== undefined) {
      UpdateCase(caseobj, submitted);
    } else {
      SaveCase(caseobj);
    }
  };

  //When the user presses the 'Save Case' button, create a new case

  const SaveCase = async (caseobj) => {
    var newcase = await AddCase(caseobj);
    if (newcase !== null) {
      setCaseObj(newcase.Case);
    }
  };

  const UpdateCase = async (caseobj, submitted) => {
    var updatecase = await UpdateACase(caseobj);
    if (updatecase !== null) {
      setCaseObj(updatecase.Case);
      if (submitted == false) {
        ChangeDisplayToDrafted();
      } else {
        SubmitCase(caseObj);
      }
    }
  };

  const SubmitCase = async (caseObj) => {
    if (caseObj !== null) {
      let data = await SetCaseTo_Submit(caseObj.Id);
      if (data !== null) {
        ChangeDisplayToSubmitted();
      }
    }
  };

  const ChangeDisplayToDrafted = async () => {
    setReviewOpen(false);
    setSaveCaseOpen(false);
    setDraftedOpen(true);
    window.scrollTo(0, 0);
  };

  const ChangeDisplayToSubmitted = async () => {
    setReviewOpen(false);
    setSaveCaseOpen(false);
    setSubmittedOpen(true);
    window.scrollTo(0, 0);
  };

  //Save the Survey Path that was selected ('detailed' or 'simple') to a global variable to be used later.

  const SelectSurveyPath = async (surveypath) => {
    setSurveyPath(surveypath);
    window.scrollTo(0, 0);
  };

  //Save the Project Area that was selected (Front Yard, Back Yard, Side Yard, Entertainment Area, Driveway, Other, OtherText) to a global variable to be used later.

  const SelectProjectArea = async (projectareatext) => {
    setProjectArea(projectareatext);
    window.scrollTo(0, 0);
  };

  //Save the Project Scope that was selected (Full Renovation, Add-on, Seasonal Cleanup, Other, OtherText) to a global variable to be used later.

  const SelectProjectScope = async (projectscopetext) => {
    setProjectScope(projectscopetext);
    window.scrollTo(0, 0);
  };

  //Save the Project Type that was selected (Planting, Fencing, Walkway, Retaining Walls, Lawn, Patio/Decking, Driveway, Yard Structure, Water Feature, Other, OtherText) to a global variable to be used later.

  const SelectProjectType = async (projectdesctype) => {
    setProjectType(projectdesctype);
    window.scrollTo(0, 0);
  };

  //Save the Project Style that was selected (Modern, Traditional/English, Japanese, Contemporary) to a global variable to be used later.

  const SelectProjectStyle = async (projectstylestring) => {
    setProjectStyle(projectstylestring);
    window.scrollTo(0, 0);
  };

  //Save the Min and Max budget to a global variable to be used later.

  const SelectProjectBudget = async (projectbudgetstring) => {
    setProjectBudget(projectbudgetstring);
    window.scrollTo(0, 0);

    caseObj.Description =
      "Project Area:" +
      projectArea +
      ", Project Scope:" +
      projectScope +
      ", Project Type:" +
      projectType +
      ", Project Style:" +
      projectStyle +
      ",Project Budget:" +
      projectbudgetstring +
      ", Description:" +
      description;
    CreateCase(caseObj, false);
  };

  const GetCaseObjectStructure = async () => {
    var data = await GetCaseObject();
    if (data !== null) {
      caseObj = data;
    }
  };

  //Redirect to different sections depending on if the user chose 'detailed' or 'simple'

  const DetermineSurveyPath = async (surveyPath, direction) => {
    if (direction === "forward") {
      if (surveyPath === "detailed") {
        setLandscapeDescOpen(false);
        setProjectStyleOpen(true);
      } else {
        setLandscapeDescOpen(false);
        setProjectBudgetOpen(true);
      }
      window.scrollTo(0, 0);
    } else if (direction === "backward") {
      if (surveyPath === "detailed") {
        setProjectStyleOpen(true);
        setProjectBudgetOpen(false);
      } else {
        setLandscapeDescOpen(true);
        setProjectBudgetOpen(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetPersonalInfo();
    GetCaseObjectStructure();
  }, []);

  {
    return (
      <EstimatePageMainFlexCont>
        <ScrollToTop />
        <Menu
          onReset={() => {
            setSubmittedOpen(false);
            setReviewOpen(false);
            setPhotoUploadOpen(false);
            setLandscapeDescOpen(false);
            setPersonalInfoOpen(false);
            setDraftedOpen(false);
          }}
        >
          {" "}
        </Menu>

        <EstimatePageRightCont>
          <ActionMenu
            mobilePageProp="New Project"
            pageProp={"New Project - " + title}
            hackmyyardbottom={"4px solid #169ADB"}
          ></ActionMenu>
          <EstimatePageRightView>
            <PersonalInfo
              open={personalInfoOpen}
              onNext={() => {
                setPersonalInfoOpen(false);
                setProjectPathOpen(true);
                window.scrollTo(0, 0);
              }}
              onPreview={SetCaseInformation}
            ></PersonalInfo>

            <ProjectPath
              open={projectPathOpen}
              onSelectSurveyPath={SelectSurveyPath}
              onBack={() => {
                setProjectPathOpen(false);
                setPersonalInfoOpen(true);
              }}
              onNext={() => {
                setProjectPathOpen(false);
                setProjectAreaOpen(true);
              }}
            ></ProjectPath>

            <ProjectArea
              open={projectAreaOpen}
              onSelectProjectArea={SelectProjectArea}
              onBack={() => {
                setProjectPathOpen(true);
                setProjectAreaOpen(false);
              }}
              onNext={() => {
                setProjectAreaOpen(false);
                setProjectScopeOpen(true);
              }}
            ></ProjectArea>

            <ProjectScope
              open={projectScopeOpen}
              onSelectProjectScope={SelectProjectScope}
              onBack={() => {
                setProjectAreaOpen(true);
                setProjectScopeOpen(false);
              }}
              onNext={() => {
                setProjectDescTypeOpen(true);
                setProjectScopeOpen(false);
              }}
            ></ProjectScope>

            <ProjectDescType
              open={projectDescTypeOpen}
              onSelectProjectType={SelectProjectType}
              onBack={() => {
                setProjectScopeOpen(true);
                setProjectDescTypeOpen(false);
              }}
              onNext={() => {
                setProjectDescTypeOpen(false);
                setLandscapeDescOpen(true);
              }}
            ></ProjectDescType>

            <LandscapeDesc
              open={landscapeDescOpen}
              onNext={() => {
                DetermineSurveyPath(surveyPath, "forward");
              }}
              onBack={() => {
                setLandscapeDescOpen(false);
                setProjectDescTypeOpen(true);
                window.scrollTo(0, 0);
              }}
              onPreview={SetDescription}
            ></LandscapeDesc>

            <ProjectStyle
              open={projectStyleOpen}
              onSelectProjectStyle={SelectProjectStyle}
              onBack={() => {
                setLandscapeDescOpen(true);
                setProjectStyleOpen(false);
              }}
              onNext={() => {
                setProjectStyleOpen(false);
                setProjectBudgetOpen(true);
              }}
            ></ProjectStyle>

            <ProjectBudget
              open={projectBudgetOpen}
              onSelectProjectBudget={SelectProjectBudget}
              onBack={() => {
                DetermineSurveyPath(surveyPath, "backward");
              }}
              onNext={() => {
                setPhotoUploadOpen(true);
                setProjectBudgetOpen(false);
              }}
            ></ProjectBudget>

            <PhotoUploadComp
              open={photoUploadOpen}
              caseObj={caseObj}
              onNext={() => {
                GetPersonalInfo();
                setPhotoUploadOpen(false);
                setReviewOpen(true);
                window.scrollTo(0, 0);
              }}
              onBack={() => {
                setPhotoUploadOpen(false);
                setProjectBudgetOpen(true);
                window.scrollTo(0, 0);
              }}
              onPreview={PushImgsToArray}
            ></PhotoUploadComp>

            <ProjectOverview
              open={reviewOpen}
              onBack={() => {
                setReviewOpen(false);
                setPhotoUploadOpen(true);
              }}
              onNext={() => {
                setReviewOpen(false);
                setSubmittedOpen(true);
              }}
              onOpenSaveCase={() => {
                setSaveCaseOpen(true);
              }}
              contactinfo={userInfo}
              area={projectArea}
              scope={projectScope}
              type={projectType}
              style={projectStyle}
              budget={projectBudget}
              description={description}
              imgs={allImgsArray}
              pdfs={pdfArray}
              onSetCaseInformation={UpdateLocalProjectInfo}
            ></ProjectOverview>

            <SaveProjectWindow
              saveOpen={saveCaseOpen}
              onSaveCase={() => {
                CreateCase(caseObj, false);
              }}
              onClose={() => {
                setSaveCaseOpen(false);
              }}
              SaveCase_ChangeStatusToSubmit={() => {
                CreateCase(caseObj, true);
              }}
            ></SaveProjectWindow>

            <Submitted
              open={submittedOpen}
              metroId={metroIdOfUser}
              storeId={storeIdOfUser}
            ></Submitted>

            <Drafted
              open={draftedOpen}
              metroId={metroIdOfUser}
              storeId={storeIdOfUser}
            ></Drafted>
          </EstimatePageRightView>
        </EstimatePageRightCont>
      </EstimatePageMainFlexCont>
    );
  }
};

EstimateScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default EstimateScreen;
