import React from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContRedBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { useHistory } from "react-router-dom";
import { SetCaseTo_Cancel } from "../../../API_Functions/CaseQuote_Functions";
import { useParams } from "react-router-dom";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const GuideCancelProjectWindow = ({ deleteOpen, CloseWindow, projectId }) => {
  const history = useHistory();
  const params = useParams();

  const CancelProject = async (caseid, metro_id, store_id) => {
    if (caseid !== 0) {
      var data = await SetCaseTo_Cancel(caseid);
      if (data !== null) {
        alert("Case Cancelled");
        history.push("/cases/" + metro_id + "/" + store_id);
      }
    }
  };

  if (!deleteOpen) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContRedBorderTopRounded>
        <SmallHeaderLeagueSpartan>
          You're about to cancel this project
        </SmallHeaderLeagueSpartan>
        <SmallTextPadding>
          Are you sure you want to cancel this project?
        </SmallTextPadding>
        <FlexRowCont>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={CloseWindow}
          >
            Return
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              CloseWindow();
              CancelProject(projectId, params.Id, params.storeId);
            }}
          >
            Cancel
          </RegularResponsiveButton>
        </FlexRowCont>
      </FlexColumnContRedBorderTopRounded>
    </FixedOverlay200vh>
  );
};

GuideCancelProjectWindow.defaultProps = {
  CloseWindow: () => {},
};

export default GuideCancelProjectWindow;
