import {
  FlexCont300x150px,
  FlexDiv,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import { Icon30x30, Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import { LeagueSpartanHeaderSmall, LeagueSpartanLarge } from "../../StylesheetComps/Titles";

const StoreCard = ({ StoreCardHeader, StoreCardImgUrl }) => {
  return (
    <FlexDiv>
      <FlexRowContFullWidth justifycontent="space-evenly">
        <Icon30x40pxPointer src={StoreCardImgUrl}></Icon30x40pxPointer>
        <LeagueSpartanHeaderSmall margin='10px'>{StoreCardHeader}</LeagueSpartanHeaderSmall>
      </FlexRowContFullWidth>
    </FlexDiv>
  );
};

StoreCard.defaultProps = {
  StoreCardHeader: "Default Header",
  StoreCardImgUrl: "Inventory.svg",
};

export default StoreCard;
