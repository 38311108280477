import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/ActionMenu";
import {
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont,
  FlexColumnCont100xAutoPercent,
  FlexColumnCont70x100Percent,
} from "../../StylesheetComps/Cont";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UserRole } from "../../StylesheetComps/UserRoles";
import {
  FlexColumnFullWidth,
  LeftCont,
  FlexColumn81vwLightGrey,
} from "../../StylesheetComps/Cont";
import { Cont } from "../../StylesheetComps/Projects";
import { ContentCont77vw } from "../../StylesheetComps/Projects";
import { Icon200x200 } from "../../StylesheetComps/Imgs/Icons";
import { MediumHeader } from "../../StylesheetComps/Titles";
import { FlexColumnContBoxShadow19Percent } from "../../StylesheetComps/Menu";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import { GreenButtonWidth12VWHeight40 } from "../../StylesheetComps/AuthSheet";
import axios from "axios";

const DashboardWelcomeScreen = () => {
  const [roleNum] = useState(0);
  const history = useHistory();
  const [metroId, setMetroId] = useState(0);
  const [storeId, setStoreId] = useState(0);

  const CheckStoreEstimates = async (metroId, storeId) => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + metroId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          if (storeId === 0) {
            if (resp.data.stores.length !== 1) {
              history.push("/storelistestimate/" + metroId);
            } else {
              history.push(
                "/estimate/" + metroId + "/" + resp.data.stores[0].Id
              );
            }
          } else {
            history.push("/estimate/" + metroId + "/" + storeId);
          }
        }
      }
    }
  };

  useEffect(() => {
    SetHeaderAuthorization();
    var userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    setMetroId(userInfo.Metro_Id);
    setStoreId(userInfo.Store_Id);
  }, []);

  if (roleNum !== UserRole.Loading) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu></ActionMenu>

          <ContentCont77vw>
            <FlexColumnFullWidth display={"block"} height="auto" margin="0">
              <FlexColumnCont100xAutoPercent>
                <FlexColumnCont70x100Percent>
                  <SmallHeaderLeagueSpartan fontsize="24px">
                    Ready to start your project?
                  </SmallHeaderLeagueSpartan>
                  <br></br>
                  <MediumHeader>
                    Hack your Yard and receive a free cost estimate and construction plan. Then, we'll
                    schedule the work and start building!
                  </MediumHeader>
                </FlexColumnCont70x100Percent>

                <FlexRowCont100PercentSpaceBetween mobileflexdirection="column">
                  <FlexColumnContBoxShadow19Percent>
                    <Icon200x200 src="/Home_Icon.svg"></Icon200x200>
                    <br></br>
                    <SmallHeaderLeagueSpartan>
                      Tell us about the project.
                    </SmallHeaderLeagueSpartan>
                  </FlexColumnContBoxShadow19Percent>

                  <FlexColumnContBoxShadow19Percent>
                    <Icon200x200 src="/Take_Photo_Icon.svg"></Icon200x200>
                    <br></br>
                    <SmallHeaderLeagueSpartan>
                      Take photos of the outdoor space.
                    </SmallHeaderLeagueSpartan>
                  </FlexColumnContBoxShadow19Percent>

                  {/* <FlexColumnContBoxShadow19Percent>
                    <Icon200x200 src="/Project_Package_Icon.svg"></Icon200x200>
                    <br></br>
                    <SmallHeaderLeagueSpartan>
                      Receive a full design {" & "} estimate.
                    </SmallHeaderLeagueSpartan>
                  </FlexColumnContBoxShadow19Percent> */}

                  <FlexColumnContBoxShadow19Percent>
                    <Icon200x200 src="/Schedule_Icon.svg"></Icon200x200>
                    <br></br>
                    <SmallHeaderLeagueSpartan>
                      Schedule the Construction.
                    </SmallHeaderLeagueSpartan>
                  </FlexColumnContBoxShadow19Percent>
                </FlexRowCont100PercentSpaceBetween>
                <br></br>
                <br></br>
                <FlexRowCont mobileflexdirection="column">
                  <GreenButtonWidth12VWHeight40
                    mobilewidth="68vw"
                    onClick={() => {
                      CheckStoreEstimates(metroId, storeId);
                    }}
                  >
                    New Project
                  </GreenButtonWidth12VWHeight40>
                </FlexRowCont>
              </FlexColumnCont100xAutoPercent>
            </FlexColumnFullWidth>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

DashboardWelcomeScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default DashboardWelcomeScreen;
