import { Link } from "react-router-dom";
import { FlexCont80PercentAutoHeightPadding } from "../../StylesheetComps/Cont";
import {
  Div30Percent,
  FlexColumnDivSpaceBetween,
} from "../../StylesheetComps/Div";
import { Icon40x40Percent } from "../../StylesheetComps/Imgs/Icons";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import { RegularText } from "../../StylesheetComps/Titles";

const MetroCatCard = ({ MetroName, MetroID, DelMetro, EditMetro }) => {
  return (
    <FlexCont80PercentAutoHeightPadding>
      <Div30Percent>
        <Icon40x40Percent src={"/metroicon.svg"}></Icon40x40Percent>
      </Div30Percent>
      <Link
        to={"/MetroID/" + MetroID}
        style={{ textDecoration: "none", color: "black" }}
      >
        <RegularText>{MetroName}</RegularText>
      </Link>
      <FlexColumnDivSpaceBetween>
        <Icon30px20pxPointer
          src={"/EditIcon.svg"}
          onClick={EditMetro}
        ></Icon30px20pxPointer>
        <Icon30px20pxPointer
          src={"/Garbage_Icon.svg"}
          onClick={DelMetro}
        ></Icon30px20pxPointer>
      </FlexColumnDivSpaceBetween>
    </FlexCont80PercentAutoHeightPadding>
  );
};

MetroCatCard.defaultProps = {
  Metro: "Default Name",
  StoreLocationName: "123 Yonge Street",
  MetroName: "Default Metro Name",
  MetroID: 0,
  MetroUrl:
    "https://torontoguardian.com/wp-content/uploads/2019/01/Toronto-skyline-tourism.jpg",
};

export default MetroCatCard;
