import axios from "axios";

export const GetTool = async (tool_id) => {
  var resp = await axios.get(window.$DBURL + "tool/" + tool_id);
  if (resp.status === 200) {
    return resp.data.tool;
  } else {
    return null;
  }
};

export const GetToolObject = async () => {
  var tool = await GetTool(0);
  return tool;
};

export const AddTool = async (newtool) => {
  var resp = await axios.post(window.$DBURL + "tool/add", { Tool: newtool });
  if (resp.status === 200) {
    return resp.data.tool;
  } else {
    return null;
  }
};

export const UpdateTool = async (tool) => {
  var resp = await axios.put(window.$DBURL + "tool/update", { Tool: tool });
  if (resp.status === 200) {
    return resp;
  } else {
    return null;
  }
};

export const GetToolKit = async (kit_id) => {
  var resp = await axios.get(window.$DBURL + "tool/kit/" + kit_id);
  if (resp.status === 200) {
    return resp.data.toolkit;
  } else {
    return null;
  }
};

export const GetToolKitObject = async () => {
  var toolkit = await GetToolKit(0);
  return toolkit;
};

export const AddToolKit = async (newkit) => {
  var resp = await axios.post(window.$DBURL + "tool/kit/add", {
    ToolKit: newkit,
  });
  if (resp.status === 200) {
    return resp.data.toolkit;
  } else {
    return null;
  }
};

export const UpdateToolKit = async (kit) => {
  var resp = await axios.put(window.$DBURL + "tool/kit/update", {
    ToolKit: kit,
  });
  if (resp.status === 200) {
    return resp;
  } else {
    return null;
  }
};

// get toolkits of a store with storeid.
export const GetStoreToolKits = async (storeid) => {
  var resp = await axios.get(window.$DBURL + "tool/kit/store/" + storeid);
  if (resp.status === 200) {
    return resp.data;
  } else {
    //display error message

    //and return null.
    return null;
  }
};

//delete Toolkit with id
export const DeleteToolKit = async (id) => {
  var resp = await axios.delete(window.$DBURL + "tool/kit/" + id);
  if (resp.status === 200) {
    return null;
  }
};

//delete  a tool with id
export const DeleteTool = async (id) => {
  var resp = await axios.delete(window.$DBURL + "tool/" + id);
  if (resp.status === 200) {
    return null;
  }
};
