import React, { useState, useEffect } from "react";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  FlexColumn81vwLightGrey,
  FlexColumnContCenterAlign,
  FlexColumnPropWidth,
  FlexRowCont,
} from "../../StylesheetComps/Cont";
import { LeftCont } from "../../StylesheetComps/Cont";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";

import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { GetInvoicesByUser } from "../../API_Functions/CaseQuote_Functions";
import {
  MediumHeader100PercentWidth,
  StyledHRGreyBreak,
} from "../../StylesheetComps/Titles";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";
import { MediumHeader100PercentWidthWhiteText } from "../../StylesheetComps/Titles";
import { Icon45x45 } from "../../StylesheetComps/Imgs/Icons";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { MobileWideGreenButton } from "../../MobileComponents/Buttons";

const BillingScreen = () => {
  const [roleNum, setRoleNum] = useState(1000);
  const [invoiceArray, setInvoiceArray] = useState([]);

  const [noInvoicesText, setNoInvoicesText] = useState("");

  // Define a function to set the user invoices
  const SetUserInvoices = async (userid) => {
    try {
      // Get the invoices by user ID
      const data = await GetInvoicesByUser(userid);

      // If the invoices are found, set the invoice array and the no invoices text
      if (data !== null) {
        setInvoiceArray(data);
        if (data.length <= 0) {
          setNoInvoicesText("No Invoices Available");
        }
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));

  useEffect(() => {
    SetHeaderAuthorization();
    SetUserInvoices(userinfo.Id);
    setRoleNum(userinfo.Role);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (window.$ISMOBILE === true) {
    if (roleNum !== 1000) {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey>
            <ActionMenu
              mobilePageProp="Billing"
              pageProp={"Billing"}
              billingbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
            ></ActionMenu>
            <ContentCont77vw>
              <FlexColumnCont
                justifycontent="flex-start"
                width="73vw"
                height="95%"
                margin="0"
              >
                <FlexColumnCont
                  alignitems="left"
                  width="70vw"
                  margin="0"
                  height="40%"
                >
                  <FlexColumnCont
                    bgcolor={ColorPalette.DarkGrey}
                    padding=".75%"
                    justifycontent="flex-start"
                    margin="0"
                    margintop="1%"
                    height="30px"
                    width="99%"
                    flexdirection="row"
                  >
                    <FlexColumnPropWidth width="14%" alignitems="left">
                      <MediumHeader100PercentWidthWhiteText>
                        Date
                      </MediumHeader100PercentWidthWhiteText>
                    </FlexColumnPropWidth>

                    <FlexColumnPropWidth width="65%" alignitems="left">
                      <MediumHeader100PercentWidthWhiteText>
                        Project Name
                      </MediumHeader100PercentWidthWhiteText>
                    </FlexColumnPropWidth>

                    <FlexColumnPropWidth
                      width="16%"
                      height="100%"
                      alignitems="left"
                    >
                      <MediumHeader100PercentWidthWhiteText>
                        View Invoice
                      </MediumHeader100PercentWidthWhiteText>
                    </FlexColumnPropWidth>
                  </FlexColumnCont>
                </FlexColumnCont>

                <FlexColumnCont
                  margin="0"
                  alignitems="left"
                  width="70vw"
                  height="auto"
                >
                  {invoiceArray.map((o, i) => (
                    <FlexColumnCont
                      margin="0"
                      alignitems="left"
                      width="70vw"
                      height="auto"
                    >
                      <FlexColumnCont
                        mobilewidth="90vw"
                        justifycontent="flex-start"
                        mobilejustifycontent="space-between"
                        margin="0"
                        margintop="0%"
                        borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                        height="auto"
                        width="99%"
                        flexdirection="row"
                      >
                        <FlexColumnPropWidth width="15%" mobiledisplay="flex">
                          <MediumHeader100PercentWidth>
                            {o.Upload_Time.substring(0, 10)}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth width="62%" alignitems="left">
                          <MediumHeader100PercentWidth>
                            {o.Tittle}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="15%"
                          height="100%"
                          mobiledisplay="flex"
                        >
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target="_blank"
                          >
                            <Icon45x45 src="/Eye_Icon.svg"></Icon45x45>
                          </a>
                        </FlexColumnPropWidth>
                      </FlexColumnCont>
                    </FlexColumnCont>
                  ))}

                  <br></br>

                  <FlexColumnCont mobilewidth="100%">
                    <SmallHeaderLeagueSpartan>
                      {noInvoicesText}
                    </SmallHeaderLeagueSpartan>
                  </FlexColumnCont>

                  <br></br>
                  <StyledHRGreyBreak></StyledHRGreyBreak>
                  <br></br>
                </FlexColumnCont>
              </FlexColumnCont>

              <FlexColumnContCenterAlign>
                <SmallHeaderLeagueSpartan>
                  Need Help With Your Billing Information?
                </SmallHeaderLeagueSpartan>
                <br></br>
                <a
                  rel="noreferrer"
                  href="https://www.newrhodesconstruction.com/contact-us/"
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                >
                  <MobileWideGreenButton>Contact Us</MobileWideGreenButton>
                </a>
              </FlexColumnContCenterAlign>
            </ContentCont77vw>
          </FlexColumn81vwLightGrey>
        </Cont>
      );
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else if (roleNum !== 1000) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu
            mobilePageProp="Billing"
            pageProp={"Billing"}
            billingbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
          ></ActionMenu>
          <ContentCont77vw>
            <FlexColumnCont
              justifycontent="flex-start"
              width="73vw"
              height="95%"
              margin="0"
            >
              <FlexColumnCont
                alignitems="left"
                width="70vw"
                margin="0"
                height="40%"
              >
                <FlexColumnCont
                  bgcolor={ColorPalette.DarkGrey}
                  padding=".75%"
                  justifycontent="flex-start"
                  margin="0"
                  margintop="1%"
                  height="30px"
                  width="99%"
                  flexdirection="row"
                >
                  <FlexColumnPropWidth width="100px" alignitems="left">
                    <MediumHeader100PercentWidthWhiteText>
                      Date
                    </MediumHeader100PercentWidthWhiteText>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="250px" alignitems="left">
                    <MediumHeader100PercentWidthWhiteText>
                      Project Name
                    </MediumHeader100PercentWidthWhiteText>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="200px" alignitems="left">
                    <MediumHeader100PercentWidthWhiteText>
                      Invoice Number
                    </MediumHeader100PercentWidthWhiteText>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="240px" alignitems="left">
                    <MediumHeader100PercentWidthWhiteText>
                      Address
                    </MediumHeader100PercentWidthWhiteText>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth
                    width="150px"
                    height="100%"
                    alignitems="left"
                  >
                    <MediumHeader100PercentWidthWhiteText>
                      View Invoice
                    </MediumHeader100PercentWidthWhiteText>
                  </FlexColumnPropWidth>
                </FlexColumnCont>
              </FlexColumnCont>

              <FlexColumnCont
                margin="0"
                alignitems="left"
                width="70vw"
                height="auto"
              >
                {invoiceArray.map((o, i) => (
                  <FlexColumnCont
                    margin="0"
                    alignitems="left"
                    width="70vw"
                    height="auto"
                  >
                    <FlexColumnCont
                      mobilewidth="90vw"
                      justifycontent="flex-start"
                      mobilejustifycontent="space-between"
                      margin="0"
                      margintop="0%"
                      borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                      height="auto"
                      width="99%"
                      flexdirection="row"
                    >
                      <FlexColumnPropWidth width="110px" mobiledisplay="flex">
                        <MediumHeader100PercentWidth>
                          {o.Upload_Time.substring(0, 10)}
                        </MediumHeader100PercentWidth>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="250px" alignitems="left">
                        <MediumHeader100PercentWidth>
                          {o.Tittle}
                        </MediumHeader100PercentWidth>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="200px" alignitems="left">
                        <MediumHeader100PercentWidth>
                          #{o.Number}
                        </MediumHeader100PercentWidth>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="200px" alignitems="left">
                        <MediumHeader100PercentWidth>
                          {o.Address}
                        </MediumHeader100PercentWidth>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="200px" mobiledisplay="flex">
                        <a
                          rel="noreferrer"
                          href={window.$IMGURL + o.File}
                          target="_blank"
                        >
                          <Icon45x45 src="/Eye_Icon.svg"></Icon45x45>
                        </a>
                      </FlexColumnPropWidth>
                    </FlexColumnCont>
                  </FlexColumnCont>
                ))}

                <br></br>

                <FlexColumnCont mobilewidth="100%">
                  <SmallHeaderLeagueSpartan>
                    {noInvoicesText}
                  </SmallHeaderLeagueSpartan>
                </FlexColumnCont>

                <br></br>
                <StyledHRGreyBreak></StyledHRGreyBreak>
                <br></br>
              </FlexColumnCont>

              <FlexRowCont
                justifycontent="flex-start"
                alignitems="center"
                width="95%"
              >
                <FlexColumnCont
                  justifycontent="center"
                  alignitems="left"
                  width="45%"
                  height="auto"
                  margin="0"
                >
                  <SmallHeaderLeagueSpartan>
                    Billing Information
                  </SmallHeaderLeagueSpartan>
                  <MediumHeader100PercentWidth>
                    Need to change your billing information?
                  </MediumHeader100PercentWidth>
                </FlexColumnCont>
                <a
                  rel="noreferrer"
                  href="https://www.newrhodesconstruction.com/contact-us/"
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                >
                  <RegularResponsiveButton>Contact Us</RegularResponsiveButton>
                </a>
              </FlexRowCont>
            </FlexColumnCont>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

BillingScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default BillingScreen;
