import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AddProduct,
  GetCategory,
  GetProduct,
  UpdateProduct,
} from "../../../API_Functions/CatProduct_Functions";
import { GetTaxesForStore } from "../../../API_Functions/Store_Functions";
import { UploadFileToCloud } from "../../../API_Functions/Utility_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont85vwAutoHeight,
  FlexColumnFullWidth,
  FlexDiv,
  FlexRowContFullWidth,
  FlexRowContHalfHeightFlexStart,
} from "../../../StylesheetComps/Cont";
import { FlexColumnDiv, FlexDivMargins } from "../../../StylesheetComps/Div";
import { Icon15x15, Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { Image40x40px } from "../../../StylesheetComps/Imgs/Images";
import { TextAreaLarge } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../../StylesheetComps/Quote";
import {
  RegularText,
  RegularTextBoldRed,
  SmallTitleBold,
  StyledHRPropColour,
  TinyTextItalics,
  TitleFontSize100PercentPointer,
} from "../../../StylesheetComps/Titles";
import Input from "../../Input";

const ProductWindow = ({
  open,
  onClose,
  updateProp,
  productID,
  categoryId,
  onUpdated,
  taxbordercolor,
}) => {
  const [productObj, setProdcutObj] = useState(null);

  const params = useParams();

  const [editTaxDisplay, setEditTaxDisplay] = useState("none");
  const [photoDisplay, setPhotoDisplay] = useState("block");
  const [errorText] = useState("");
  const [taxMap, setTaxMap] = useState([]);

  const [productUrlDisplay, setProductUrlDisplay] = useState("");
  const [positionDisplayProp, setPositionDisplayProp] = useState("none");
  const [installSpeedDisplayProp, setInstallSpeedDisplayProp] =
    useState("none");
  const [
    installSpeedDisplayPropContainer,
    setInstallSpeedDisplayPropContainer,
  ] = useState("flex");
  const [RoundupInstructionDisplay, setRoundupInstructionDisplay] =
    useState("none");
  const [RestockInstructionDisplay, setRestockInstructionDisplay] =
    useState("none");

  const [reStockDisplayProp, setReStockDisplayProp] = useState("none");
  const [convertUnitDisplayProp, setConvertUnitDisplayProp] = useState("none");
  const [convertUnitInstructionDisplay, setConvertUnitInstructionDisplay] =
    useState("none");
  const [denominatorInstructionDisplay, setDenominatorInstructionDisplay] =
    useState("none");
  const [displayUnitInstructionDisplay, setDisplayUnitInstructionDisplay] =
    useState("none");

  var file = "";
  var roundYes = productObj?.RoundUp;
  var roundNo = !productObj?.RoundUp;
  var convertYes = productObj?.Convert;
  var convertNo = !productObj?.Convert;
  var reStockYes = productObj?.ReStock;
  var reStockNo = !productObj?.ReStock;
  var roundToHalfYes = productObj?.RoundToHalf;
  var roundToHalfNo = !productObj?.RoundToHalf;

  function handleUpload(event) {
    file = event.target.files[0];
    UploadFile(productObj, file);
  }

  const UploadFile = async (product, file) => {
    var pic = await UploadFileToCloud(file);
    if (pic !== null) {
      setProductUrlDisplay(pic);
      product.ImageUrl = pic;
      setPhotoDisplay("block");
      setProdcutObj(product);
    }
  };

  const GetProductInfo = async (product_id) => {
    var product = await GetProduct(product_id);
    setProdcutObj(product);

    if (product !== null) {
      if (product.ImageUrl !== null) {
        setProductUrlDisplay(product.ImageUrl);
      }
      if (product.ReStock === true) {
        setReStockDisplayProp("flex");
      }
      if (product.Convert === true) {
        setConvertUnitDisplayProp("flex");
      }
    }
  };

  const SetTaxOptions = async (storeid) => {
    const taxlist = await GetTaxesForStore(storeid);
    setTaxMap(taxlist);
  };

  const DeleteTax = async (product, taxid) => {
    if (product.Id === 0) {
      product.SalesTax?.forEach((element, index) => {
        if (element.Tax_Id === taxid) product.SalesTax.splice(index, 1);
      });
    } else {
      product.SalesTax?.forEach(async (element, index) => {
        if (element.Tax_Id === taxid) {
          await axios.delete(window.$DBURL + "tax/prodtax/" + element.Id);
          product.SalesTax.splice(index, 1);
        }
      });
    }
    setProdcutObj(product);
  };

  const AddProductTax = async (product, taxid) => {
    if (product.Id === 0) {
      product.SalesTax.push({ Tax_Id: taxid });
    } else {
      var resp = await axios.post(window.$DBURL + "tax/prodtax", {
        Product_Id: product.Id,
        Tax_Id: taxid,
      });
      if (resp.status === 200) {
        product.SalesTax.push(resp.data.tax);
      }
    }
    setProdcutObj(product);
  };

  // useEffect(() => {
  //   GetProductInfo(productID);
  // }, [productData]); // eslint-disable-line react-hooks/exhaustive-deps

  const SaveProduct = async (product, catid) => {
    if (product.Id === 0) {
      var cate = await GetCategory(catid);
      if (cate !== null) {
        product.CateType = cate.CateType;
        product.Category_Id = cate.Id;
      }
      await AddProduct(product);
    } else await UpdateProduct(product);

    onUpdated();
    onClose();
    setPhotoDisplay("block");
    setEditTaxDisplay("none");
    //GetProductInfo(product.Id);
    //SetTaxOptions(params.Id);
  };

  useEffect(() => {
    switch (productObj?.CateType) {
      case 2:
        setInstallSpeedDisplayPropContainer("none");
        break;
      case 3:
        setInstallSpeedDisplayPropContainer("none");
        break;
      default:
    }
  }, [productObj?.CateType]);

  useEffect(() => {
    SetTaxOptions(params.Id);
  }, [productObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetProductInfo(productID);
    SetTaxOptions(params.Id);
  }, [updateProp, productID]); // eslint-disable-line react-hooks/exhaustive-deps

  const Calculateunitprice_Originalprice_change = async (e) => {
    var product = productObj;
    product.OriginalPrice = e.target.value;

    var calculation =
      productObj.OriginalPrice * (1 + productObj.Markup / 100.0);
    product.UnitPrice = calculation;

    setProdcutObj(product);
  };

  const CalculateUnitPrice_Markup_change = async (e) => {
    var product = productObj;
    product.Markup = e.target.value;
    var calculation =
      productObj.OriginalPrice * (1 + productObj.Markup / 100.0);
    product.UnitPrice = calculation;

    setProdcutObj(product);
  };

  const Initialize_Instruction_Display = async () => {
    setPositionDisplayProp("none");
    setDisplayUnitInstructionDisplay("none");
    setInstallSpeedDisplayProp("none");
    setRoundupInstructionDisplay("none");
    setRestockInstructionDisplay("none");
    setConvertUnitInstructionDisplay("none");
    setDenominatorInstructionDisplay("none");
  };

  if (!open) {
    return null;
  } else if (productObj === null){
    return <div></div>
  } else {
    return (
      <FixedOverlay200vh>
        {" "}
        <Cont85vwAutoHeight>
          <FlexColumnFullWidth flexdirection="row" height="95vh">
            <FlexColumnFullWidth overflowy="auto">
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Product Name</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Product Name"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Name}
                    onChange={(e) => {
                      var product = productObj;
                      product.Name = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Description</SmallTitleBold>
                </FlexDiv>

                <HundredPercentFlexDiv>
                  <TextAreaLarge
                    placeholder="Description of Product"
                    defaultValue={productObj.Description}
                    inputwidth="100%"
                    inputheight="10px"
                    onChange={(e) => {
                      var product = productObj;
                      product.Description = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></TextAreaLarge>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Manager's Notes</SmallTitleBold>
                </FlexDiv>

                <HundredPercentFlexDiv>
                  <TextAreaLarge
                    placeholder="Manager's Notes"
                    defaultValue={productObj.Notes}
                    inputwidth="100%"
                    inputheight="10px"
                    onChange={(e) => {
                      var product = productObj;
                      product.Notes = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></TextAreaLarge>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Original Price ($)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    type="number"
                    placeholder="Original Price ($)"
                    defaultValue={productObj.OriginalPrice}
                    inputwidth="100%"
                    inputheight="10px"
                    onChange={(e) => {
                      Calculateunitprice_Originalprice_change(e);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Markup (%)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    type="number"
                    placeholder="Markup (%)"
                    defaultValue={productObj.Markup}
                    inputwidth="100%"
                    inputheight="10px"
                    onChange={(e) => {
                      CalculateUnitPrice_Markup_change(e);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              {/* <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Unit Price ($)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <EditProductPara>
                    {"$" + productObj.UnitPrice.toFixed(2)}
                  </EditProductPara>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart> */}
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Unit for Price (Feet, Etc.)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Unit"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Unit}
                    onChange={(e) => {
                      var product = productObj;
                      product.Unit = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexDiv>
                <RegularResponsiveButton
                  onClick={() => {
                    SaveProduct(productObj, categoryId);
                  }}
                >
                  Save
                </RegularResponsiveButton>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setPhotoDisplay("block");
                    setEditTaxDisplay("none");
                    onClose();
                  }}
                >
                  Cancel
                </RegularResponsiveButton>
              </FlexDiv>
            </FlexColumnFullWidth>
            <FlexColumnFullWidth overflowy="auto">
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Position</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (positionDisplayProp === "none") {
                        Initialize_Instruction_Display();
                      } else {
                        setPositionDisplayProp("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Position"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Position}
                    onChange={(e) => {
                      var product = productObj;
                      product.Position = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={positionDisplayProp}>
                (Position is the place where the product will be displayed on
                screen. Position 2 will be displayed after position 1, for
                example)
              </TinyTextItalics>
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Install Instructions</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Disclaimer"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Disclaimer}
                    onChange={(e) => {
                      var product = productObj;
                      product.Disclaimer = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Product Link</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Product Link"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.ProductLink}
                    onChange={(e) => {
                      var product = productObj;
                      product.ProductLink = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Product SKU</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Product Link"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Code}
                    onChange={(e) => {
                      var product = productObj;
                      product.Code = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Allowance(%)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Allowance"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.Allowance}
                    onChange={(e) => {
                      var product = productObj;
                      product.Allowance = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Setup Time(Hours)</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="0"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.SetupTime}
                    onChange={(e) => {
                      var product = productObj;
                      product.SetupTime = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart
                displayProp={installSpeedDisplayPropContainer}
              >
                <FlexDiv>
                  <SmallTitleBold>
                    Install Speed (Hours per {productObj.Unit})
                  </SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (installSpeedDisplayProp === "none") {
                        Initialize_Instruction_Display();
                        setInstallSpeedDisplayProp("block");
                      } else {
                        setInstallSpeedDisplayProp("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Install Speed"
                    inputheight="10px"
                    inputwidth="100%"
                    defaultValue={productObj.InstallSpeed}
                    onChange={(e) => {
                      var product = productObj;
                      product.InstallSpeed = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={installSpeedDisplayProp}>
                (InstallSpeed is the time needed by installation of one unit of
                this item, hours/unit. Ex. Installation of 1 sqft of turf is 12
                minutes, so the InstallSpeed of truf is 12/60 = 0.2 hour/sqft.)
              </TinyTextItalics>
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Minimum</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    defaultValue={productObj.Minimum}
                    placeholder="Minimum"
                    inputheight="10px"
                    inputwidth="100%"
                    onChange={(e) => {
                      var product = productObj;
                      product.Minimum = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold># of Product in Stock</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="# of Product in Stock"
                    defaultValue={productObj.Stock}
                    inputwidth="100%"
                    inputheight="10px"
                    onChange={(e) => {
                      var product = productObj;
                      product.Stock = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Round Up</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (RoundupInstructionDisplay === "none") {
                        Initialize_Instruction_Display();
                        setRoundupInstructionDisplay("block");
                      } else {
                        setRoundupInstructionDisplay("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <FlexDiv>
                    <FlexDivMargins>
                      <input
                        defaultChecked={roundYes}
                        type="radio"
                        id="yesRU"
                        name="roundUp"
                        onChange={() => {
                          var product = productObj;
                          product.RoundUp = true;
                          setProdcutObj(product);
                        }}
                      />
                      <label htmlFor="yesRU">Yes</label>
                      <br />
                      <input
                        defaultChecked={roundNo}
                        type="radio"
                        id="noRU"
                        name="roundUp"
                        onChange={() => {
                          var product = productObj;
                          product.RoundUp = false;
                          setProdcutObj(product);
                        }}
                      />
                      <label htmlFor="noRU">No</label>
                      <br />
                    </FlexDivMargins>
                  </FlexDiv>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={RoundupInstructionDisplay}>
                (Round up will round the fraction to integer. Ex, 1.2 hours will
                round up to 2 hours, 4.4 hours will round up to 5.)
              </TinyTextItalics>
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Restock</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (RestockInstructionDisplay === "none") {
                        Initialize_Instruction_Display();
                      } else {
                        setRestockInstructionDisplay("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <FlexDiv>
                    <FlexDivMargins>
                      <input
                        defaultChecked={reStockYes}
                        type="radio"
                        id="yesRS"
                        name="reStock"
                        onChange={() => {
                          var product = productObj;
                          product.ReStock = true;
                          setProdcutObj(product);
                          setReStockDisplayProp("flex");
                        }}
                      />
                      <label htmlFor="yesRS">Yes</label>
                      <br />
                      <input
                        defaultChecked={reStockNo}
                        type="radio"
                        id="noRS"
                        name="reStock"
                        onChange={() => {
                          var product = productObj;
                          product.ReStock = false;
                          setProdcutObj(product);
                          setReStockDisplayProp("none");
                        }}
                      />
                      <label htmlFor="noRS">No</label>
                      <br />
                    </FlexDivMargins>
                  </FlexDiv>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={RestockInstructionDisplay}>
                (Restock allows adding restock cost for unused products that
                need to be restocked.)
              </TinyTextItalics>

              <FlexRowContHalfHeightFlexStart displayProp={reStockDisplayProp}>
                <FlexDiv>
                  <SmallTitleBold>Pack Size</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Pack Size"
                    defaultValue={productObj.PackSize}
                    inputheight="10px"
                    inputwidth="100%"
                    onChange={(e) => {
                      var product = productObj;
                      product.PackSize = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart displayProp={reStockDisplayProp}>
                <FlexDiv>
                  <SmallTitleBold>Charge Rate</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    placeholder="Charge Rate"
                    defaultValue={productObj.Charge_Rate}
                    inputheight="10px"
                    inputwidth="100%"
                    onChange={(e) => {
                      var product = productObj;
                      product.Charge_Rate = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
            </FlexColumnFullWidth>

            <FlexColumnFullWidth overflowy="auto">
              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Round To Half</SmallTitleBold>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <FlexDiv>
                    <FlexDivMargins>
                      <input
                        defaultChecked={roundToHalfYes}
                        type="radio"
                        id="yes"
                        name="flexible"
                        onChange={() => {
                          var product = productObj;
                          product.RoundToHalf = true;
                          setProdcutObj(product);
                        }}
                      />
                      <label htmlFor="yes">Yes</label>
                      <br />
                      <input
                        defaultChecked={roundToHalfNo}
                        type="radio"
                        id="no"
                        name="flexible"
                        onChange={() => {
                          var product = productObj;
                          product.RoundToHalf = false;
                          setProdcutObj(product);
                        }}
                      />
                      <label htmlFor="no">No</label>
                      <br />
                    </FlexDivMargins>
                  </FlexDiv>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>

              <FlexRowContHalfHeightFlexStart>
                <FlexDiv>
                  <SmallTitleBold>Convert Unit</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (convertUnitInstructionDisplay === "none") {
                        Initialize_Instruction_Display();
                        setConvertUnitInstructionDisplay("block");
                      } else {
                        setConvertUnitInstructionDisplay("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <FlexDiv>
                    <FlexDivMargins>
                      <input
                        type="radio"
                        id="yesConvert"
                        defaultChecked={convertYes}
                        name="convert"
                        onChange={() => {
                          var product = productObj;
                          product.Convert = true;
                          setProdcutObj(product);
                          setConvertUnitDisplayProp("flex");
                        }}
                      />
                      <label htmlFor="yesConvert">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="noConvert"
                        defaultChecked={convertNo}
                        name="convert"
                        onChange={() => {
                          var product = productObj;
                          product.Convert = false;
                          setProdcutObj(product);
                          setConvertUnitDisplayProp("none");
                          setDenominatorInstructionDisplay("none");
                          setDisplayUnitInstructionDisplay("none");
                        }}
                      />
                      <label htmlFor="noConvert">No</label>
                      <br />
                    </FlexDivMargins>
                  </FlexDiv>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={convertUnitInstructionDisplay}>
                Convert unit will convert this unit and quantity to the display
                unit on quote, ex. 27 cubic ft will be converted to 1 cubic
                yard.
              </TinyTextItalics>

              <FlexRowContHalfHeightFlexStart
                displayProp={convertUnitDisplayProp}
              >
                <FlexDiv>
                  <SmallTitleBold>Denominator</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (denominatorInstructionDisplay === "none") {
                        setDenominatorInstructionDisplay("block");
                        setDisplayUnitInstructionDisplay("none");
                        setPositionDisplayProp("none");
                        setInstallSpeedDisplayProp("none");
                        setRoundupInstructionDisplay("none");
                        setRestockInstructionDisplay("none");
                        setConvertUnitInstructionDisplay("none");
                      } else if (denominatorInstructionDisplay === "block") {
                        setDenominatorInstructionDisplay("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    defaultValue={productObj.Denominator}
                    inputheight="10px"
                    inputwidth="100%"
                    onChange={(e) => {
                      var product = productObj;
                      product.Denominator = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={denominatorInstructionDisplay}>
                Denominator is the number to divide in conversion. Ex.
                denominator is 27 when converting cubic feet to cubic yard; 9
                when converting sq ft to sq yard.
              </TinyTextItalics>

              <FlexRowContHalfHeightFlexStart
                displayProp={convertUnitDisplayProp}
              >
                <FlexDiv>
                  <SmallTitleBold>Display Unit</SmallTitleBold>
                  <Icon15x15
                    src="/questionmark.svg"
                    onClick={() => {
                      if (displayUnitInstructionDisplay === "none") {
                        Initialize_Instruction_Display();
                        setDisplayUnitInstructionDisplay("block");
                      } else if (displayUnitInstructionDisplay === "block") {
                        setDisplayUnitInstructionDisplay("none");
                      }
                    }}
                  ></Icon15x15>
                </FlexDiv>
                <HundredPercentFlexDiv>
                  <Input
                    defaultValue={productObj.DisplayUnit}
                    inputheight="10px"
                    inputwidth="100%"
                    onChange={(e) => {
                      var product = productObj;
                      product.DisplayUnit = e.target.value;
                      setProdcutObj(product);
                    }}
                  ></Input>
                </HundredPercentFlexDiv>
              </FlexRowContHalfHeightFlexStart>
              <TinyTextItalics display={displayUnitInstructionDisplay}>
                Displayed unit on quote after unit conversion.
              </TinyTextItalics>

              <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
              <FlexRowContHalfHeightFlexStart>
                <Image40x40px
                  photoprops1={photoDisplay}
                  src={window.$IMGURL + productUrlDisplay}
                  alt="No Image"
                ></Image40x40px>
                <Icon20x20
                  deletephotoprops={photoDisplay}
                  src="/Garbage_Icon.svg"
                  onClick={() => {
                    // DeleteImg(productObj.ImageUrl);
                    setPhotoDisplay("none");
                  }}
                ></Icon20x20>
              </FlexRowContHalfHeightFlexStart>
              <Input type="file" onChange={handleUpload}></Input>

              <FlexColumnDiv>
                <FlexRowContFullWidth>
                  <SmallTitleBold>Current Taxes</SmallTitleBold>
                  <RegularResponsiveButton
                  
                    onClick={() => {
                      setEditTaxDisplay("flex");
                    }}
                  >Add Tax</RegularResponsiveButton>
                </FlexRowContFullWidth>
                <StyledHRPropColour
                  border={`0.5px solid ${ColorPalette.PrimaryGrey}`}
                ></StyledHRPropColour>
                {productObj.SalesTax.map((x, i) => (
                  <FlexRowContFullWidth>
                    <RegularText key={i} taxbordercolor={taxbordercolor}>
                      {x.TaxCode} {x.Rate}%
                    </RegularText>
                    <Icon30px20pxPointer
                      src="/Garbage_Icon.svg"
                      onClick={() => {
                        DeleteTax(productObj, x.Tax_Id);
                        SetTaxOptions(params.Id);
                      }}
                    ></Icon30px20pxPointer>
                  </FlexRowContFullWidth>
                ))}
              </FlexColumnDiv>
              <FlexColumnDiv display={editTaxDisplay}>
                <SmallTitleBold>Select Tax to Add to Product</SmallTitleBold>
                <StyledHRPropColour
                  border={`0.5px dotted ${ColorPalette.PrimaryGrey}`}
                ></StyledHRPropColour>
                {taxMap.map((o, i) => (
                  <FlexRowContFullWidth
                    onClick={() => {
                      AddProductTax(productObj, o.Id);
                      SetTaxOptions(params.Id);
                      setEditTaxDisplay("none");
                    }}
                  >
                    <TitleFontSize100PercentPointer key={i}>
                      • {o.TaxCode} {o.Rate}%
                    </TitleFontSize100PercentPointer>
                  </FlexRowContFullWidth>
                ))}
              </FlexColumnDiv>
            </FlexColumnFullWidth>
          </FlexColumnFullWidth>
        </Cont85vwAutoHeight>
      </FixedOverlay200vh>
    );
  }
};

ProductWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onUpdated: () => {},
  RemoveImg: () => {},
  UpdateTaxData: () => {},

  taxbordercolor: "black",
  productID: 0,
  categoryId: 0,
  updateProp: 0,
  packSizeProp: 0,
  chargeRateProp: 0,
  reStockProp: false,
};

export default ProductWindow;
