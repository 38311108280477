/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import ZoneCityWindow from "../../comps/Windows/ZoneCity";
import ZoneCityCard from "../../comps/Windows/ZoneCityCard";
import ZoneSupplierWindow from "../../comps/Windows/ZoneSupplier";
import ZoneSupplierCard from "../../comps/ZoneSupplierCard";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

import {
  FlexCont100vw100vh,
  FlexDiv,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightView_FlexFullWidth_Column } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDiv,
  Icon30px20pxPointer,
} from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const ViewSplitCont = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

const ZoneScreen = () => {
  //=============================================================================
  // Zone Screen - View Single Zone and its Suppliers and Cities
  //=============================================================================

  //=============================================================================
  // Accessable only by Admin
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [zone, getZone] = useState([]);
  const [zoneCities, getZoneCities] = useState([]);
  const [zoneSuppliers, getZoneSuppliers] = useState([]);
  const [zoneName, getZoneName] = useState("");
  var cityIdDeletion = 0;
  var setZoneDelId = 0;
  var positionSuppProp = 0;

  const GetZone = async () => {
    const resp = await axios.get(window.$DBURL + "store/zone/" + params.zoneId);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.zone !== null) {
          getZone(resp.data.zone);
          getZoneName(resp.data.zone.Name);
        }
      }
    }
  };

  const GetCities = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/zone/" + params.zoneId + "/cities"
    );
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.cities !== null) {
          getZoneCities(resp.data.cities);
        }
      }
    }
  };

  const GetSuppliers = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/zone/" + params.zoneId + "/supplier"
    );
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.suppliers !== null) {
          getZoneSuppliers(resp.data.suppliers);
        }
      }
    }
  };

  const AddCityToZone = async () => {
    const resp = await axios.post(
      window.$DBURL + "store/zone/" + params.zoneId + "/city/" + setCityId
    );
    if (resp.status == 200) {
      GetCities();
    }
  };

  const AddSupplierToZone = async () => {
    const resp = await axios.post(
      window.$DBURL +
        "store/zone/" +
        params.zoneId +
        "/supplier/" +
        setSupplierId +
        "/position/" +
        positionSuppProp
    );
    if (resp.status == 200) {
      GetSuppliers();
    }
  };

  var setCityId = 0;
  const HandleFormCityId = async (cityId) => {
    setCityId = cityId;
  };

  var setSupplierId = 0;
  const HandleFormSupplierId = async ({ supplierId, positionSupp }) => {
    setSupplierId = supplierId;
    positionSuppProp = positionSupp;
  };

  const HandleSupplierRemove = async () => {
    const resp = await axios.delete(
      window.$DBURL +
        "store/zone/" +
        params.zoneId +
        "/supplier/" +
        setZoneDelId
    );
    if (resp.status == 200) {
      GetSuppliers();
    }
  };

  const HandleCityRemove = async () => {
    const resp = await axios.delete(
      window.$DBURL + "store/zone/" + params.zoneId + "/city/" + cityIdDeletion
    );
    if (resp.status == 200) {
      GetCities();
    }
  };

  const [addCityOpen, onAddCityOpen] = useState(false);
  const [addSupplierOpen, onAddSupplierOpen] = useState(false);

  useEffect(() => {
    GetZone();
    GetCities();
    GetSuppliers();
  }, []);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightView_FlexFullWidth_Column>
          <HundredPercentFlexDiv>
            <Icon30px20pxPointer
              src="/backarrow.svg"
              onClick={() => {
                history.push("/zonelist/" + params.Id);
              }}
            ></Icon30px20pxPointer>
            <LeagueSpartanExtraLargeBold>
              {zoneName}
            </LeagueSpartanExtraLargeBold>
          </HundredPercentFlexDiv>
          <FlexDiv>
            <ViewSplitCont>
              <FlexDiv70x7Percent>
                <LeagueSpartanExtraLargeBold>
                  Suppliers
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton
                  onClick={() => {
                    onAddSupplierOpen(true);
                  }}
                >
                  Add Supplier
                </RegularResponsiveButton>
              </FlexDiv70x7Percent>
              <ZoneSupplierWindow
                zoneOpen={addSupplierOpen}
                onClose={() => {
                  onAddSupplierOpen(false);
                }}
                onFormComplete={AddSupplierToZone}
                onPreview={HandleFormSupplierId}
              ></ZoneSupplierWindow>

              {zoneSuppliers.map((o, i) => (
                <ZoneSupplierCard
                  key={i}
                  onPreview={GetSuppliers}
                  SupplierNameText={o.User.Name}
                  SupplierPosition={o.Position}
                  SupplierId={o.User.Id}
                  RemoveFromZone={() => {
                    setZoneDelId = o.User.Id;
                    HandleSupplierRemove();
                  }}
                ></ZoneSupplierCard>
              ))}
            </ViewSplitCont>
            <ViewSplitCont>
              <FlexDiv70x7Percent>
                <LeagueSpartanExtraLargeBold>
                  Cities
                </LeagueSpartanExtraLargeBold>
                <RegularResponsiveButton
                  onClick={() => {
                    onAddCityOpen(true);
                  }}
                >
                  Add City
                </RegularResponsiveButton>
              </FlexDiv70x7Percent>
              <ZoneCityWindow
                zoneOpen={addCityOpen}
                onClose={() => {
                  onAddCityOpen(false);
                }}
                onFormComplete={AddCityToZone}
                onPreview={HandleFormCityId}
              ></ZoneCityWindow>
              {zoneCities.map((o, i) => (
                <ZoneCityCard
                  key={i}
                  CityNameText={o.Name}
                  delCityFromZone={() => {
                    cityIdDeletion = o.Id;
                    HandleCityRemove();
                  }}
                ></ZoneCityCard>
              ))}
            </ViewSplitCont>
          </FlexDiv>
        </RightView_FlexFullWidth_Column>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

ZoneScreen.defaultProps = {
  ProfileName: "Default Name",
  ZoneName: "EXAMPLE Zone #1",
};

export default ZoneScreen;
