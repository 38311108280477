import React from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnContRedBorderTopRounded,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { SmallTextPadding } from "../../../StylesheetComps/Titles";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SetCaseTo_CancelRequested } from "../../../API_Functions/CaseQuote_Functions";
import { ColorPalette } from "../../../StylesheetComps/Colors";

const RequestProjectCancelWindow = ({
  deleteOpen,
  CloseWindow,
  onRequestCancelFunction,
  projectId,
}) => {
  const history = useHistory();
  const params = useParams();

  const ChangeCaseStatus_RequestCancellation = async (project_id) => {
    var data = await SetCaseTo_CancelRequested(project_id);
    if (data !== null) {
      history.push("/CasesPersonal/" + params.Id + "/" + params.storeId);
    }
  };

  if (!deleteOpen) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContRedBorderTopRounded>
        <SmallHeaderLeagueSpartan>
          You're about to request to cancel your project
        </SmallHeaderLeagueSpartan>
        <SmallTextPadding>
          Are you sure you want to request to cancel your project? Once approved
          by your guide, your project will be cancelled.
        </SmallTextPadding>
        <FlexRowCont>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryGrey}
            onClick={CloseWindow}
          >
            Return
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              CloseWindow();
              ChangeCaseStatus_RequestCancellation(projectId);
            }}
          >
            Request Cancellation
          </RegularResponsiveButton>
        </FlexRowCont>
      </FlexColumnContRedBorderTopRounded>
    </FixedOverlay200vh>
  );
};

RequestProjectCancelWindow.defaultProps = {
  onRequestCancelFunction: () => {},
  CloseWindow: () => {},
};

export default RequestProjectCancelWindow;
