import React from "react";
import { Link, useParams } from "react-router-dom";
import { FlexCont90PercentAutoHeight } from "../../StylesheetComps/Cont";
import { LeagueSpartanRegular } from "../../StylesheetComps/Titles";

const RoleCatCard = ({ RoleName }) => {
  const params = useParams();

  return (
    <FlexCont90PercentAutoHeight>
      <Link
        to={"/rolelist/" + params.Id + "/" + RoleName}
        style={{ textDecoration: "none", color: "black" }}
      >
        <LeagueSpartanRegular>{RoleName}</LeagueSpartanRegular>
      </Link>
    </FlexCont90PercentAutoHeight>
  );
};

RoleCatCard.defaultProps = {
  RoleName: "Default Role",
};

export default RoleCatCard;
