import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import {
  Div100vw20vh,
  MyAccountCardDiv,
  TopDiv40vh,
  WhiteTopRoundedDiv,
} from "../../MobileComponents/Divs";
import { LogoRelativeLeft, TopImage40vh } from "../../MobileComponents/Images";
import {
  LeagueHeader14px,
  MenuHeader,
  WhiteAvenirHeader,
  WhiteLeagueHeader,
} from "../../MobileComponents/Text";
import {
  FlexColumnCont75x80Percent,
  FlexColumnLeftAlign,
  FlexContzIndex1,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { Icon20x20 } from "../../StylesheetComps/Imgs/Icons";
import { UserRole } from "../../StylesheetComps/UserRoles";

const MyAccountScreen = () => {
  const [userinfo, setUserinfo] = useState({});
  const [roleName, setRoleName] = useState("");
  const history = useHistory();

  useEffect(() => {
    setUserinfo(JSON.parse(sessionStorage.getItem("userInfo")));
    switch (JSON.parse(sessionStorage.getItem("userInfo")).Role) {
      case UserRole.User:
        setRoleName("Homeowner");
        break;
      case UserRole.VIP:
        setRoleName("VIP Homeowner");
        break;
      case UserRole.Worker:
        setRoleName("Worker");
        break;
      case UserRole.OwnerOperator:
        setRoleName("Owner / Operator");
        break;
      case UserRole.Estimator:
        setRoleName("Guide");
        break;
      case UserRole.Installer:
        setRoleName("Installer");
        break;
      case UserRole.Manager:
        setRoleName("Manager");
        break;
      case UserRole.Admin:
        setRoleName("Admin");
        break;
      case UserRole.Accountant:
        setRoleName("Accountant");
        break;
      case UserRole.Supplier:
        setRoleName("Supplier");
        break;

      default:
        break;
    }
  }, []);

  return (
    <FlexContzIndex1>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu mobilePageProp="My Account"></ActionMenu>

        <Div100vw20vh>
          <TopImage40vh src="/Log In Image.png"></TopImage40vh>
        </Div100vw20vh>

        <TopDiv40vh>
          <LogoRelativeLeft
            src={
              userinfo.Image === null
                ? "/PersonIcon.svg"
                : window.$IMGURL + userinfo.Image
            }
          ></LogoRelativeLeft>
          <FlexColumnLeftAlign>
            <WhiteLeagueHeader>
              {userinfo.Name !== null ? userinfo.Name : ""}
            </WhiteLeagueHeader>
            <WhiteAvenirHeader>{roleName}</WhiteAvenirHeader>
          </FlexColumnLeftAlign>
        </TopDiv40vh>

        <WhiteTopRoundedDiv width='87.75vw'>
          <MenuHeader>My Account</MenuHeader>
          <FlexColumnCont75x80Percent>
            <MyAccountCardDiv>
              <LeagueHeader14px
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </LeagueHeader14px>
              <Icon20x20 src="rightarrow.svg"></Icon20x20>
            </MyAccountCardDiv>

            <a
              rel="noreferrer"
              style={{ textDecoration: "none", color: "black" }}
              href="https://newrhodesconstruction.com/privacy-policy/"
              target="_blank"
            >
              <MyAccountCardDiv>
                <LeagueHeader14px>Privacy Policy</LeagueHeader14px>
                <Icon20x20 src="rightarrow.svg"></Icon20x20>
              </MyAccountCardDiv>
            </a>

            <a
              rel="noreferrer"
              style={{ textDecoration: "none", color: "black" }}
              href="https://newrhodesconstruction.com/rl-terms-of-service/"
              target="_blank"
            >
              <MyAccountCardDiv>
                <LeagueHeader14px>Terms of Service</LeagueHeader14px>
                <Icon20x20 src="rightarrow.svg"></Icon20x20>
              </MyAccountCardDiv>
            </a>
          </FlexColumnCont75x80Percent>
        </WhiteTopRoundedDiv>
      </FlexColumnCont80vwAutoHeight>
    </FlexContzIndex1>
  );
};

MyAccountScreen.defaultProps = {};

export default MyAccountScreen;
