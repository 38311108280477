/* eslint-disable */

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ActionMenu from "../../comps/ActionMenu";
import ChangePass from "../../comps/ChangePass";
import Menu from "../../comps/MenuComps/Menu";
import UpdateProfile from "../../comps/UpdateProfile";
import { UserRole } from "../../StylesheetComps/UserRoles";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import {
  MobileGreenButton,
  MobileSmallGreenButton,
} from "../../MobileComponents/Buttons";
import {
  Div100vw20vh,
  FlexColumnDiv90vw,
  TopDiv40vh,
  WhiteTopRoundedDiv,
} from "../../MobileComponents/Divs";
import { LogoRelativeLeft, TopImage40vh } from "../../MobileComponents/Images";
import {
  MenuHeader,
  WhiteAvenirHeader,
  WhiteLeagueHeader,
} from "../../MobileComponents/Text";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnLeftAlign,
  FlexContMobileColumn,
  FlexContzIndex1,
  FlexDiv,
  FlexRowContFullWidth,
  FullWidthMobileCont100vw100vh,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { RegularTextBoldRed } from "../../StylesheetComps/Titles";

const ProfileDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1006px) {
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: column;
  }
`;

const ProfileInfo = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 2%;
  margin: 2%;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  @media (max-width: 1006px) {
    display: ;
    width: 100%;
    margin: 0%;
    height: 60%;
    box-shadow: 0px 0px 0px #d3d3d3;
  }
`;

const UpdatePass = styled.a`
  background-color: #169ADB;
  padding: 1%;
  color: white;
  width: 8vw;
  border-radius: 10px;
  font-family: Futura;
  font-size: 100%;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  margin: 1%;
  @media (max-width: 1006px) {
    width: 50%;
    margin-top: 20px;
  }
`;

const Clickables = styled.div`
  display: flex;
  // height:80%;
  width: 100%;
  @media (max-width: 1006px) {
    flex-direction: column;
  }
`;

const UpdateClickables = styled.div`
  @media (max-width: 1006px) {
    flex-direction: column;
    position: relative;
    height: 0%;
    display: ${(props) => (props.changeView ? props.changeView : "none")};
  }
`;

const HeaderDiv = styled.div`
  display: none;
  @media (max-width: 1006px) {
    width: 40%;
    display: flex;
    justify-content: space-around;
  }
`;

const UpdateDiv = styled.div`
display:flex;
flex-direction:column;
@media (max-width: 1006px) {
+}
`;

const ProfileScreen = ({ onPreview, onProfileUpdated }) => {
  //=============================================================================
  // Profile Screen - User can view and edit their own Profile Information here
  //=============================================================================

  //=============================================================================
  // All users have access to this page via the ActionMenu or Left Menu
  //=============================================================================

  const history = useHistory();

  const [passSwitch, setPassSwitch] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [metroSwitch, setMetroSwitch] = useState(false);
  const [citySwitch, setCitySwitch] = useState(false);

  const [cpCurrentUsername, setcpCurrentUsername] = useState("");
  const [cpOldPass, setcpOldPass] = useState("");
  const [cpNewPass, setcpNewPass] = useState("");
  const [cpConfirmNewPass, setcpConfirmNewPass] = useState("");
  const [confirmMessage] = useState("");
  const [updated] = useState(0);

  const [passView, setPassView] = useState("none");
  const [metroView, setMetroView] = useState("none");

  const [emailValue, setEmailValue] = useState("");
  const [email, setEmail] = useState("");
  const [NameValue, setNameValue] = useState("");
  const [AddressValue, setAddressValue] = useState("");
  const [CityValue, setCityValue] = useState("");
  const [UsernameValue, setUsernameValue] = useState("");
  const [PhoneValue, setPhoneValue] = useState("");
  const [MetroValue, setMetroValue] = useState("");
  const [RoleValue, setRoleValue] = useState("");
  const [userId, setUserId] = useState(0);
  const [roleNum, setRoleNum] = useState(1000);
  const [roleName, setRoleName] = useState("");
  const [ImgUrl, setImgUrl] = useState("");

  const [userinfo, setUserinfo] = useState({});

  const [ErrorText, setErrorText] = useState("");

  const HandlePasswordChange = async ({
    currentUsername,
    oldPass,
    newPass,
    confirmNewPass,
    onPreview,
    onChange,
  }) => {
    setcpCurrentUsername(currentUsername);
    setcpOldPass(oldPass);
    setcpNewPass(newPass);
    setcpConfirmNewPass(confirmNewPass);
  };

  const HandleProfileUpdated = async ({
    emailValue2,
    NameValue2,
    AddressValue2,
    PhoneValue2,
    onPreview,
    onChange,
  }) => {
    setEmailValue(emailValue2);
    setNameValue(NameValue2);
    setAddressValue(AddressValue2);
    setPhoneValue(PhoneValue2);
  };

  const ChangePasswordFunc = async (cpOldPass, cpNewPass) => {
    try {
      setErrorText("Old Password Incorrect");

      // Log out from the current session
      const resplogout = await axios.post(
        `${window.$DBURL}users/${userId}/session/logout`
      );

      // Change the password
      const resp = await axios.put(`${window.$DBURL}users/password`, {
        UserName: UsernameValue,
        OldPassword: cpOldPass,
        NewPassword: cpNewPass,
      });

      if (resp.status === 200) {
        // Clear the error text
        setErrorText("");

        // Delete the current authorization token
        delete axios.defaults.headers.common["Authorization"];

        // Log in with the new password
        const resplogin = await axios.post(
          `${window.$DBURL}users/session/login`,
          {
            Username: UsernameValue,
            Password: cpNewPass,
          }
        );

        if (resplogin.data !== undefined || resplogin.data !== null) {
          // Save the new authorization token
          const token = resplogin.data.Session.AuthToken;
          axios.defaults.baseURL = window.$DBURL;
          sessionStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = `Basic ${token}`;
        }

        // Update the UI
        setPassSwitch(false);
        setPassView("none");
        window.scrollTo(0, 0);
      }
    } catch (error) {
      // Handle any errors that may have occurred
      console.error(error);
    }
  };

  const UpdateAccount = async (email, name, address, phone, image) => {
    const resp = await axios.put(`${window.$DBURL}users`, {
      Name: name,
      Phone: phone,
      Id: userId,
      Street: address,
      Email: email,
      Username: UsernameValue,
      Image: image,
    });
    GetProfileInfo();
  };

  const GetProfileInfo = async () => {
    const resp = await axios.get(window.$DBURL + "users/profile", {});
    const { user } = resp.data;

    sessionStorage.setItem("userInfo", JSON.stringify(user));
    setUserinfo(JSON.parse(sessionStorage.getItem("userInfo")));

    setEmail(user.Email);
    setUserId(user.Id);
    setEmailValue(user.Email);
    setUsernameValue(user.Username);
    setNameValue(user.Name);
    setAddressValue(user.Street);
    setCityValue(user.City);
    setPhoneValue(user.Phone);
    setMetroValue(user.Metro);
    setImgUrl(user.Image);

    switch (JSON.parse(sessionStorage.getItem("userInfo")).Role) {
      case UserRole.User:
        setRoleName("Homeowner");
        break;
      case UserRole.VIP:
        setRoleName("VIP Homeowner");
        break;
      case UserRole.Worker:
        setRoleName("Worker");
        break;
      case UserRole.OwnerOperator:
        setRoleName("Owner / Operator");
        break;
      case UserRole.Estimator:
        setRoleName("Guide");
        break;
      case UserRole.Installer:
        setRoleName("Installer");
        break;
      case UserRole.Manager:
        setRoleName("Manager");
        break;
      case UserRole.Admin:
        setRoleName("Admin");
        break;
      case UserRole.Accountant:
        setRoleName("Accountant");
        break;
      case UserRole.Supplier:
        setRoleName("Supplier");
        break;
      default:
        break;
    }

    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  useEffect(() => {
    SetHeaderAuthorization();
    GetProfileInfo();
  }, []);

  if (window.$ISMOBILE === true) {
    if (roleNum !== UserRole.Loading) {
      return (
        <FlexContzIndex1>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>

          <FlexColumnCont80vwAutoHeight>
            <ActionMenu mobilePageProp="Profile"></ActionMenu>

            <Div100vw20vh>
              <TopImage40vh src="/DarkProfileImage2.png"></TopImage40vh>
            </Div100vw20vh>

            <TopDiv40vh>
              <LogoRelativeLeft
                src={
                  userinfo.Image === null
                    ? "/PersonIcon.svg"
                    : window.$IMGURL + userinfo.Image
                }
              ></LogoRelativeLeft>
              <FlexColumnLeftAlign>
                <WhiteLeagueHeader>
                  {userinfo.Name !== null ? userinfo.Name : ""}
                </WhiteLeagueHeader>
                <WhiteAvenirHeader>{roleName}</WhiteAvenirHeader>
                <br></br>
                <MobileGreenButton
                  onClick={() => {
                    setEditProfile(true);
                  }}
                >
                  Edit Profile
                </MobileGreenButton>
              </FlexColumnLeftAlign>
            </TopDiv40vh>

            <WhiteTopRoundedDiv>
              <FlexDiv>
                <Icon30px30pxPointer
                  onClick={() => {
                    history.push("/myaccount");
                  }}
                  src="/leftarrow.svg"
                ></Icon30px30pxPointer>
                <MenuHeader>Profile</MenuHeader>
                <MobileSmallGreenButton
                  onClick={() => {
                    setPassSwitch(true);
                    setPassView("block");
                    window.scrollTo(0, 0);
                  }}
                >
                  Change Password{" "}
                </MobileSmallGreenButton>
              </FlexDiv>

              <FlexColumnDiv90vw>
                <ProfileDiv>
                  <UpdateClickables changeView={passView}></UpdateClickables>
                  <UpdateClickables changeView={metroView}></UpdateClickables>

                  <ProfileInfo>
                    <UpdateDiv changeView={passView}>
                      <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
                      <ChangePass
                        passSwitch={passSwitch}
                        onClose={() => {
                          setPassSwitch(false);
                          setPassView("none");
                          window.scrollTo(0, 0);
                        }}
                        onPasswordChanged={ChangePasswordFunc}
                        onPreview={HandlePasswordChange}
                      ></ChangePass>
                      <br></br>
                    </UpdateDiv>
                    <hr></hr>
                    <Clickables>
                      <UpdateProfile
                        editprofile={editProfile}
                        CallProfileAgain={() => {
                          GetProfileInfo();
                        }}
                        role={roleNum}
                        imgurl={ImgUrl}
                        metro={MetroValue}
                        city={CityValue}
                        email={emailValue}
                        name={NameValue}
                        phone={PhoneValue}
                        address={AddressValue}
                        updated={updated}
                        onProfileUpdated={UpdateAccount}
                        ChangeCity={() => {
                          setMetroSwitch(false);
                          setCitySwitch(true);
                          setMetroView("block");
                          window.scrollTo(0, 0);
                        }}
                        ChangeMetro={() => {
                          setCitySwitch(false);
                          setMetroSwitch(true);
                          setMetroView("block");
                          window.scrollTo(0, 0);
                        }}
                        onPreview={HandleProfileUpdated}
                      ></UpdateProfile>
                    </Clickables>
                  </ProfileInfo>
                </ProfileDiv>
              </FlexColumnDiv90vw>
            </WhiteTopRoundedDiv>
          </FlexColumnCont80vwAutoHeight>
        </FlexContzIndex1>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  } else {
    //Desktop
    if (roleNum !== UserRole.Loading) {
      return (
        <FlexContMobileColumn>
          <FullWidthMobileCont100vw100vh>
            <Menu> </Menu>
          </FullWidthMobileCont100vw100vh>

          <FlexColumnCont80vwAutoHeight>
            <ActionMenu mobilePageProp="Profile"></ActionMenu>
            <FlexRowContFullWidth>
              <HeaderDiv></HeaderDiv>
              <p>{confirmMessage}</p>
              <ProfileDiv>
                <UpdateClickables changeView={passView}></UpdateClickables>
                <UpdateClickables changeView={metroView}></UpdateClickables>
                <ProfileInfo>
                  <UpdateDiv>
                    <UpdatePass
                      onClick={() => {
                        setPassSwitch(true);
                        setPassView("block");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Change Password{" "}
                    </UpdatePass>
                    <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
                    <ChangePass
                      passSwitch={passSwitch}
                      onClose={() => {
                        setPassSwitch(false);
                        setPassView("none");
                        window.scrollTo(0, 0);
                      }}
                      onPasswordChanged={ChangePasswordFunc}
                      onPreview={HandlePasswordChange}
                    ></ChangePass>
                    <br></br>
                  </UpdateDiv>
                  <hr></hr>
                  <Clickables>
                    <UpdateProfile
                      CallProfileAgain={() => {
                        GetProfileInfo();
                      }}
                      role={roleNum}
                      imgurl={ImgUrl}
                      metro={MetroValue}
                      city={CityValue}
                      email={emailValue}
                      name={NameValue}
                      phone={PhoneValue}
                      address={AddressValue}
                      updated={updated}
                      onProfileUpdated={UpdateAccount}
                      ChangeCity={() => {
                        setMetroSwitch(false);
                        setCitySwitch(true);
                        setMetroView("block");
                        window.scrollTo(0, 0);
                      }}
                      ChangeMetro={() => {
                        setCitySwitch(false);
                        setMetroSwitch(true);
                        setMetroView("block");
                        window.scrollTo(0, 0);
                      }}
                      onPreview={HandleProfileUpdated}
                    ></UpdateProfile>
                  </Clickables>
                </ProfileInfo>
              </ProfileDiv>
            </FlexRowContFullWidth>
          </FlexColumnCont80vwAutoHeight>
        </FlexContMobileColumn>
      );
    } else {
      return (
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
  }
};

ProfileScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default ProfileScreen;
