/* eslint-disable */

import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GetStoreCategories } from "../../API_Functions/CatProduct_Functions";
import ActionMenu from "../../comps/ActionMenu";
import ManagerCard from "../../comps/ManagerCard";
import Menu from "../../comps/MenuComps/Menu";
import AddManagerWindow from "../../comps/Windows/AddManager";
import EditStoreWindow from "../../comps/Windows/EditStore";
import ManagerIcon from "../../manager.svg";
import PhoneIcon from "../../phone.svg";

import { FlexCont100vw100vh, LeftCont } from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import { FlexDiv70x7Percent } from "../../StylesheetComps/Div";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const StoreText = styled.p`
  font-size: 18px;
  font-family: Avenir;
`;

const StoreAddressDiv = styled.div`
  width: 30%;
  height: 5%;
  margin-left: 2%;
`;

const StoreInfoDiv = styled.div`
  width: 50%;
  height: 5%;
  margin: 22px;

  display: flex;
`;

const IconInfoDiv = styled.div`
  width: 30%;
  height: 30%;
  margin: 0px;

  display: flex;
  align-items: center;
`;

const InfoIcon = styled.img`
  margin: 5%;
`;

const ErrorTextPara = styled.p`
  font-family: Futura;
  color: red;
  font-weight: 500;
`;

const StoreAdminScreen = () => {
  //=============================================================================
  // Store Admin Screen - Admin can view basic information about Store
  //=============================================================================

  //=============================================================================
  // Accessable only by Admin
  //=============================================================================

  const [storeInfo, setStoreInfo] = useState([]);
  const [storeName, setStoreName] = useState([]);
  const [storeAddress, setStoreAddress] = useState([]);
  const [storePhone, setStorePhone] = useState([]);
  const [managerName, setManagerName] = useState([]);
  const [storeCategory, setStoreCategory] = useState([]);
  const [errorText, setErrorText] = useState("");

  const [managerArray, setManagerArray] = useState([]);

  const [isNewManagerOpen, setIsNewManagerOpen] = useState(false);

  var setManagerNameStore = "";
  var setManagerUsername = "";
  var setManagerEmail = "";
  var setManagerPhone = "";
  var setManagerPass = "";

  const [metroId, setMetroId] = useState(0);
  const [IdForStore, setStoreId] = useState(0);

  var newStoreId = 0;
  var newMetroId = 0;
  var setUserId = 0;

  const params = useParams();

  const GetManagers = async () => {
    const resp = await axios.get(
      window.$DBURL + "store/" + params.storeId + "/managers"
    );
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.managers !== null) {
          setManagerArray(resp.data.managers);
        }
      }
    }
  };

  const GetStoreCategory = async () => {
    var data = await GetStoreCategories(params.Id);
    setStoreCategory(data);
  };

  const GetMetro = async () => {
    const resp = await axios.get(window.$DBURL + "store/" + params.storeId);
    if (resp.status == 200) {
      if (resp.data !== null) {
        if (resp.data.store !== null) {
          setStoreInfo(resp.data.store);
          setStoreName(resp.data.store.Name);
          setStoreAddress(resp.data.store.Address);
          setStorePhone(resp.data.store.Phone);
        }
      }
    }
  };

  const GetProfileInfo = async () => {
    setManagerName(JSON.parse(sessionStorage.getItem("userInfo")).Name);
  };

  useEffect(() => {
    GetMetro();
    GetProfileInfo();
    GetStoreCategory();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const HandleFormComplete = async ({
    storeName,
    storeAddress,
    storePhone,
  }) => {
    setStoreName(storeName);
    setStoreAddress(storeAddress);
    setStorePhone(storePhone);
  };

  const HandleFormCompleteStore = async ({
    managerName,
    managerUsername,
    managerEmail,
    managerPhone,
    managerPass,
    metroId,
    storeId,
  }) => {
    setManagerNameStore = managerName;
    setManagerUsername = managerUsername;
    setManagerEmail = managerEmail;
    setManagerPhone = managerPhone;
    setManagerPass = managerPass;
    newStoreId = storeId;
    newMetroId = metroId;
  };

  const HandleEditStore = async (
    newStoreName,
    newStoreAddress,
    newStorePhone
  ) => {
    var resp = await axios.put(window.$DBURL + "store", {
      Id: params.storeId,
      Name: newStoreName,
      Address: newStoreAddress,
      Phone: newStorePhone,
    });
    if (resp.status == 200) {
      GetMetro();
    }
  };

  const HandleManagerDel = async (key) => {
    var resp = await axios.delete(window.$DBURL + "admin/manager/" + setUserId);
    if (resp.status == 200) {
      GetManagers();
    }
  };

  const HandleNewManager = async () => {
    const resp = await axios
      .post(window.$DBURL + "admin/manager/" + params.storeId, {
        Name: setManagerNameStore,
        Username: setManagerUsername,
        Password: setManagerPass,
        Phone: setManagerPhone,
        Email: setManagerEmail,
        Metro_Id: newMetroId,
        Store_Id: newStoreId,
      })
      .catch(handleErrors);
    if (resp.status == 200) {
      setIsNewManagerOpen(false);

      GetManagers();
      setErrorText("");
    }
  };

  function handleErrors(err) {
    if ((err.Message = "Invalid username! Username already exists.")) {
      setErrorText("Invalid username! Username already exists.");
    } else if (err.request) {
      setErrorText("Manager Creation Failed, Username may already be in use.");
    } else if (err.response) {
      setErrorText("Manager Creation Failed, Username may already be in use.");
    }
  }

  useEffect(() => {
    GetManagers();
  }, []);

  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <LeagueSpartanExtraLargeBold>
              {storeName}
            </LeagueSpartanExtraLargeBold>
            <RegularResponsiveButton
              onClick={() => {
                setIsEditOpen(true);
              }}
            >
              Edit Store
            </RegularResponsiveButton>
          </FlexDiv70x7Percent>
          <EditStoreWindow
            Name={storeName}
            Address={storeAddress}
            Phone={storePhone}
            open={isEditOpen}
            onClose={() => {
              setIsEditOpen(false);
            }}
            onFormComplete={HandleEditStore}
            onPreview={HandleFormComplete}
          ></EditStoreWindow>

          <StoreAddressDiv>
            <StoreText>{storeAddress}</StoreText>
          </StoreAddressDiv>
          <StoreInfoDiv>
            <IconInfoDiv>
              <InfoIcon src={PhoneIcon}></InfoIcon>
              <StoreText>{storePhone}</StoreText>
            </IconInfoDiv>
            <IconInfoDiv>
              <InfoIcon src={ManagerIcon}></InfoIcon>
              <StoreText>{managerName}</StoreText>
            </IconInfoDiv>
          </StoreInfoDiv>
          <hr></hr>
          <FlexDiv70x7Percent>
            <LeagueSpartanExtraLargeBold>Managers</LeagueSpartanExtraLargeBold>
            <RegularResponsiveButton
              onClick={() => {
                setIsNewManagerOpen(true);
              }}
            >
              Create Manager
            </RegularResponsiveButton>
            <ErrorTextPara>{errorText}</ErrorTextPara>
          </FlexDiv70x7Percent>
          <AddManagerWindow
            open={isNewManagerOpen}
            onClose={() => setIsNewManagerOpen(false)}
            onPreview={HandleFormCompleteStore}
            onFormComplete={HandleNewManager}
          ></AddManagerWindow>
          {managerArray.map((o, i) => (
            <ManagerCard
              ManagerName={o.Name}
              StoreLocationName={o.User_Id}
              MetroName={o.StoreName}
              onDel={() => {
                setUserId = o.User_Id;
                HandleManagerDel();
              }}
            ></ManagerCard>
          ))}
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexCont100vw100vh>
  );
};

StoreAdminScreen.defaultProps = {
  ProfileName: "Default Name",
  StoreImgUrl:  '/NRCLogoRegularBlack.png' ,
  StoreName: "Store Name",
  StoreAddressText:
    "SAMPLE ADDRESS TEST ADDRESS Unit 200-6200 McKay Avenue Mississauga, Ontario V5H4L7",
  PhoneNumber: "(778)-888-8888",
  ManagerName: "Manager Name",
};

export default StoreAdminScreen;
