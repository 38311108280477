import styled from "styled-components";

const nullProp = null;

export const StickyOverlayDiv160vwAutoHeight = styled.div`
  width: 160vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
`;

export const FixedOverlayRegular = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: ${(props) => (props.height ? props.height : "")};
  background-color: "rgba(0,0,0,.7)";
  background-color: rgba(0, 0, 0, 0.7);
  zindex: 1000;
`;

export const FixedOverlay1000vh = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: 1000vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

export const FixedOverlay200vh = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: 200vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
`;

export const OverlayCont300pxAutoHeight = styled.div`
  background-color: white;
  width: 300px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  align-items: center;
`;

export const OverlayCont450pxAutoHeight = styled.div`
  background-color: white;
  width: 450px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  align-items: center;
`;

export const AbsoluteOverlayDivFullWidth = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  left: 16%;
  display: flex;
  @media (max-width: 1006px) {
    left: 0%;
  }
`;

export const OverlayDiv200vh400vw = styled.div`
  width: 200vh;
  height: 400vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100;
  left: 16%;
  display: flex;
`;

export const FlexOverlayDivFullWidth = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  @media (max-width: 1006px) {
    margin-left: auto;
    margin-right: auto;
    width: 97vw;
  }
`;

export default nullProp;
