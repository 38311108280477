import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnFullWidth,
  FlexDiv,
  FlexRowCont70PercentSpaceBetween,
} from "../../StylesheetComps/Cont";
import { OverlayDiv200vh400vw } from "../../StylesheetComps/OverlayCont";
import {
  LeagueSpartanLarge,
  MediumHeaderMarginLeft,
  RegularText,
  RegularTextBoldRed,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
import DeleteConfirmWindow from "../Windows/DeleteProduct";

const ToolInfoCard = ({ open, Tool, onClose, onDelete, onEdit }) => {
  const [deleteTool, setDeleteTool] = useState(false);

  if (!open) return null;
  if (Tool !== null && Tool !== undefined) {
    return (
      <OverlayDiv200vh400vw>
        {" "}
        <FlexColumnFullWidth bgcolor="white" width="500px" height="80vh">
          <FlexColumnFullWidth>
            <FlexDiv>
              <LeagueSpartanLarge>{Tool.Name}</LeagueSpartanLarge>
            </FlexDiv>
            <FlexRowCont70PercentSpaceBetween>
              <RegularText>
                {"$" + Tool.RentalRate + " per " + Tool.RUnit}
              </RegularText>
              <DeleteConfirmWindow
                deleteOpen={deleteTool}
                onDeleteFunction={onDelete}
                closeDel={() => {
                  setDeleteTool(false);
                }}
              ></DeleteConfirmWindow>
              <RegularResponsiveButton onClick={onEdit}>
                Edit Tool
              </RegularResponsiveButton>
            </FlexRowCont70PercentSpaceBetween>
            <a href={Tool.VideoUrl} target={"_blank"} rel="noreferrer">
              <RegularText>{Tool.VideoUrl}</RegularText>
            </a>
            <FlexDiv>
              <MediumHeaderMarginLeft>
                Stock: {Tool.Stock}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Capacity: {Tool.Capacity} {Tool.CapUnit}
              </MediumHeaderMarginLeft>
            </FlexDiv>
            <StyledHRPropColour
              border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
            ></StyledHRPropColour>
            <FlexDiv>
              <MediumHeaderMarginLeft>
                Height: {Tool.Height}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Width: {Tool.Width}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Length: {Tool.Length}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Weight: {Tool.Weight}
              </MediumHeaderMarginLeft>
            </FlexDiv>
            <StyledHRPropColour
              border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
            ></StyledHRPropColour>
            <FlexDiv>
              <MediumHeaderMarginLeft>
                Min. Rental: {Tool.MinRental}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Move Cost Per Machine: ${Tool.MoveCost}
              </MediumHeaderMarginLeft>
            </FlexDiv>
            <StyledHRPropColour
              border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
            ></StyledHRPropColour>
            <FlexDiv>
              <MediumHeaderMarginLeft>
                Load Time: {Tool.LoadTime}
              </MediumHeaderMarginLeft>
              <MediumHeaderMarginLeft>
                Unload Time: {Tool.UnloadTime}
              </MediumHeaderMarginLeft>
            </FlexDiv>
            <StyledHRPropColour
              border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
            ></StyledHRPropColour>
            <MediumHeaderMarginLeft>
              Speed: {Tool.Speed} {Tool.SpeedUnit}
            </MediumHeaderMarginLeft>
            <StyledHRPropColour
              border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
            ></StyledHRPropColour>
            <FlexDiv>
              {Tool?.Taxes?.map((o, i) => (
                <MediumHeaderMarginLeft key={i}>
                  {o.TaxCode}: {o.Rate}%
                </MediumHeaderMarginLeft>
              ))}
            </FlexDiv>
            <RegularTextBoldRed
              onClick={() => {
                setDeleteTool(true);
              }}
            >
              Delete
            </RegularTextBoldRed>
          </FlexColumnFullWidth>
        </FlexColumnFullWidth>
        <RegularResponsiveButton
          onClick={() => {
            onClose();
          }}
          bgcolor={ColorPalette.PrimaryRed}
        >
          Exit
        </RegularResponsiveButton>
      </OverlayDiv200vh400vw>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ToolInfoCard.defaultProps = {
  ToolID: 0,
};

export default ToolInfoCard;
