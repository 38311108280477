import React, { useState } from "react"; // imports the React library and the hook for state
import "react-big-calendar/lib/css/react-big-calendar.css"; // imports a stylesheet for the react-big-calendar component
import "react-datepicker/dist/react-datepicker.css"; // imports a stylesheet for the react-datepicker component
import styled from "styled-components"; // imports a library for creating styled components
import Input from "../../comps/Input"; // imports the Input component from the specified location
import { ColorPalette } from "../../StylesheetComps/Colors"; // imports the ColorPalette object from the specified location
import { RegularResponsiveButton } from "../../StylesheetComps/Components"; // imports the RegularResponsiveButton component from the specified location
import {
  FlexColumnContGreenBorderTopRounded,
  FlexDiv,
} from "../../StylesheetComps/Cont"; // imports the FlexColumnContGreenBorderTopRounded and FlexDiv components from the specified location
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont"; // imports the FixedOverlay200vh component from the specified location
import { RegularTextBoldRed } from "../../StylesheetComps/Titles"; // imports the RegularTextBoldRed component from the specified location

const ItemCont = styled.div`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 30%;
  width: 500px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 30px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  align-items: center;
  justify-content: center;
  @media (max-width: 1006px) {
    left: 0%;
    width: 98.5vw;
    height: auto;
    overflow-y: scroll;
    margin: 0px;
  }
`;
const Header = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: black;
  font-family: Futura;
  margin-left: 12px;
  padding-top: 10px;
`;

const AppointmentCreationDiv = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
`;

const SubHeaderPara = styled.p`
  font-family: Futura;
  font-size: 16px;
  width: 150px;
`;

const ButtonDiv = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
`;

const LandscapePara = styled.textarea`
  width: 61%;
  height: 10vh;
  background-color: #ededed;
  border: 0;
  outline: none;
  resize: none;
  overflow: auto;
  border-radius: 4px;
  font-family: Futura;
  font-size: 14px;
  padding: 10px;
  margin-left: 1%;
`;

const InputDivsTime = styled.div`
  display: ${(props) => (props.inputdisplay ? props.inputdisplay : "none")};
  justify-content: flex-start;
`;

const InputClosedDays = styled.input`
  margin: 10px;
  height: 30px;
  @media (max-width: 1006px) {
    width: 50%;
  }
`;

const InputDataList = styled.datalist``;

const InputOption = styled.option``;

const CloseDayInputText = styled.p`
  font-family: Futura;
  font-size: 18px;
  font-weight: bold;
  width: 50px;
`;

const CreateSchedule = ({
  open,
  onClick,
  onClose,
  onPreview,
  onScheduleCreate,
  month,
  day,
  year,
  name,
  defaultaddress,
  defaultcity,
  cellphone,
}) => {
  const [contactName, setContactName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setEmail] = useState("");
  const [timeSlotDisplayProp] = useState("flex");
  const [openHourSelected, setOpenHourSelected] = useState(0);
  const [openMinuteSelected, setOpenMinuteSelected] = useState(0);
  const [closeHourSelected, setCloseHourSelected] = useState(0);
  const [closeMinuteSelected, setCloseMinuteSelected] = useState(0);
  const [errorText, setErrorText] = useState("");
  const [appDesc, setAppDesc] = useState("");

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      {" "}
      <FlexColumnContGreenBorderTopRounded>
        <ItemCont>
          <Header>
            Schedule for {month}/{day}/{year}
          </Header>

          <AppointmentCreationDiv>
            <InputDivsTime inputdisplay={timeSlotDisplayProp}>
              <CloseDayInputText>From:</CloseDayInputText>
              <InputClosedDays
                type="text"
                maxLength="2"
                list="openhours"
                onChange={(e) => {
                  setOpenHourSelected(e.target.value);
                }}
              />
              <InputDataList id="openhours">
                <InputOption value="1"></InputOption>
                <InputOption value="2"></InputOption>
                <InputOption value="3"></InputOption>
                <InputOption value="4"></InputOption>
                <InputOption value="5"></InputOption>
                <InputOption value="6"></InputOption>
                <InputOption value="7"></InputOption>
                <InputOption value="8"></InputOption>
                <InputOption value="9"></InputOption>
                <InputOption value="10"></InputOption>
                <InputOption value="11"></InputOption>
                <InputOption value="12"></InputOption>
                <InputOption value="13"></InputOption>
                <InputOption value="14"></InputOption>
                <InputOption value="15"></InputOption>
                <InputOption value="16"></InputOption>
                <InputOption value="17"></InputOption>
                <InputOption value="18"></InputOption>
                <InputOption value="19"></InputOption>
                <InputOption value="20"></InputOption>
                <InputOption value="21"></InputOption>
                <InputOption value="22"></InputOption>
                <InputOption value="23"></InputOption>
                <InputOption value="24"></InputOption>
              </InputDataList>
              <InputClosedDays
                type="text"
                maxLength="2"
                list="openminutes"
                onChange={(e) => {
                  setOpenMinuteSelected(e.target.value);
                }}
              />
              <InputDataList id="openminutes"></InputDataList>
            </InputDivsTime>

            <InputDivsTime inputdisplay={timeSlotDisplayProp}>
              <CloseDayInputText>To:</CloseDayInputText>
              <InputClosedDays
                type="text"
                maxLength="2"
                list="closehours"
                onChange={(e) => {
                  setCloseHourSelected(e.target.value);
                }}
              />
              <InputDataList id="closehours">
                <InputOption value="1"></InputOption>
                <InputOption value="2"></InputOption>
                <InputOption value="3"></InputOption>
                <InputOption value="4"></InputOption>
                <InputOption value="5"></InputOption>
                <InputOption value="6"></InputOption>
                <InputOption value="7"></InputOption>
                <InputOption value="8"></InputOption>
                <InputOption value="9"></InputOption>
                <InputOption value="10"></InputOption>
                <InputOption value="11"></InputOption>
                <InputOption value="12"></InputOption>
                <InputOption value="13"></InputOption>
                <InputOption value="14"></InputOption>
                <InputOption value="15"></InputOption>
                <InputOption value="16"></InputOption>
                <InputOption value="17"></InputOption>
                <InputOption value="18"></InputOption>
                <InputOption value="19"></InputOption>
                <InputOption value="20"></InputOption>
                <InputOption value="21"></InputOption>
                <InputOption value="22"></InputOption>
                <InputOption value="23"></InputOption>
                <InputOption value="24"></InputOption>
              </InputDataList>

              <InputClosedDays
                type="text"
                maxLength="2"
                list="closeminutes"
                onChange={(e) => {
                  setCloseMinuteSelected(e.target.value);
                }}
              />
              <InputDataList id="closeminutes"></InputDataList>
            </InputDivsTime>

            <FlexDiv>
              <SubHeaderPara>Schedule Description</SubHeaderPara>
              <LandscapePara
                maxLength="250"
                placeholder="Anything you want to tell us?"
                onChange={(e) => {
                  setAppDesc(e.target.value);
                }}
              ></LandscapePara>
            </FlexDiv>
            <FlexDiv>
              <SubHeaderPara>Contact Name</SubHeaderPara>
              <Input
                defaultValue={name}
                placeholder="Contact Name"
                inputwidth="60%"
                inputheight="10px"
                onChange={(e) => {
                  setContactName(e.target.value);
                }}
              ></Input>
            </FlexDiv>
            <FlexDiv>
              <SubHeaderPara>Address</SubHeaderPara>
              <Input
                defaultValue={defaultaddress}
                placeholder="Address"
                inputwidth="60%"
                inputheight="10px"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              ></Input>
            </FlexDiv>
            <FlexDiv>
              <SubHeaderPara>City</SubHeaderPara>
              <Input
                defaultValue={defaultcity}
                placeholder="City"
                inputwidth="60%"
                inputheight="10px"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              ></Input>
            </FlexDiv>
            <FlexDiv>
              <SubHeaderPara>Cell Phone</SubHeaderPara>
              <Input
                defaultValue={cellphone}
                placeholder="Cell Phone"
                inputwidth="60%"
                inputheight="10px"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              ></Input>
            </FlexDiv>
            <RegularTextBoldRed>{errorText}</RegularTextBoldRed>

            <ButtonDiv>
              <RegularResponsiveButton
                onClick={() => {
                  if (openHourSelected === 0 || closeHourSelected === 0) {
                    setErrorText("Time not set properly!");
                  } else {
                    onPreview({
                      contactName,
                      address,
                      phone,
                      city,
                      appDesc,
                      openHourSelected,
                      openMinuteSelected,
                      closeHourSelected,
                      closeMinuteSelected,
                    });
                    onScheduleCreate(
                      contactName,
                      address,
                      phone,
                      city,
                      appDesc,
                      openHourSelected,
                      openMinuteSelected,
                      closeHourSelected,
                      closeMinuteSelected
                    );
                    onClose();
                    setOpenHourSelected(0);
                    setOpenMinuteSelected(0);
                    setCloseHourSelected(0);
                    setCloseMinuteSelected(0);
                    setErrorText("");
                  }
                }}
              >
                {" "}
                Submit
              </RegularResponsiveButton>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  onClose();
                  setErrorText("");
                  setOpenHourSelected(0);
                  setOpenMinuteSelected(0);
                  setCloseHourSelected(0);
                  setCloseMinuteSelected(0);
                }}
              >
                Cancel
              </RegularResponsiveButton>
            </ButtonDiv>
          </AppointmentCreationDiv>
        </ItemCont>
      </FlexColumnContGreenBorderTopRounded>
    </FixedOverlay200vh>
  );
};

CreateSchedule.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onScheduleCreate: () => {},
  month: 0,
  year: 0,
  day: 0,
  name: "",
  defaultaddress: "",
  defaultcity: "",
  cellphone: "",
};

export default CreateSchedule;
