import React, { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont400x400px,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { LeagueSpartanExtraLargeWidth200px } from "../../../StylesheetComps/Titles";

const EditCityWindow = ({ open, onClose, Name, onFormComplete, onPreview }) => {
  const [storeNewCity, setStoreNewCity] = useState("");
  if (!open) return null;
  return (
    <>
      {" "}
      <Cont400x400px>
        <LeftItemWindowFixed>
          <LeagueSpartanExtraLargeWidth200px>
            City Name
          </LeagueSpartanExtraLargeWidth200px>
          <TextInput72Percent
            defaultValue={Name}
            onChange={(e) => {
              setStoreNewCity(e.target.value);
            }}
          ></TextInput72Percent>
          <RegularResponsiveButton
            onClick={() => {
              onPreview({ storeNewCity });
              onFormComplete(storeNewCity);
              onClose();
            }}
          >
            Update
          </RegularResponsiveButton>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={onClose}
          >
            Cancel
          </RegularResponsiveButton>
        </LeftItemWindowFixed>
      </Cont400x400px>{" "}
    </>
  );
};

EditCityWindow.defaultProps = {
  onPreview: () => {},
  onFormComplete: () => {},
  Name: "",
};

export default EditCityWindow;
