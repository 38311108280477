import axios from "axios";
import React, { useEffect, useState } from "react";
//DATEPICKER IMPORTS
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import ClockList from "../../comps/ClockList";
import Menu from "../../comps/MenuComps/Menu";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFullWidth,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import {
  LeagueSpartanExtraLargeBold,
  RegularText,
} from "../../StylesheetComps/Titles";

const ClockScheduleSummary = () => {
  //===============================================================================
  // Clock Schedule Summary Screen. Staff can view Schedules for all their workers
  //===============================================================================

  //===============================================================================
  // Managers / Accountants should be only ones with access
  //===============================================================================

  const params = useParams();

  const [roleNum] = useState(1000);
  const [workerName] = useState("");
  const [clockMap, setClockMap] = useState([]);

  var d = new Date();
  var fullyear = d.getFullYear();
  var month = ("0" + (d.getMonth() + 1)).slice(-2);
  var day = ("0" + d.getDate()).slice(-2);

  const [scheduleId, setScheduleId] = useState(0);
  const [clockId, setClockId] = useState(0);
  const [clockin, setClockIn] = useState(0);
  const [clockout, setClockOut] = useState(0);

  var newClockInSync = "";
  var newClockOutSync = "";

  var newClockInSyncUpdated = "default";
  var newClockOutSyncUpdated = "default";

  const [startDate, setStartDate] = useState(
    new Date(fullyear + "/" + month + "/" + day)
  );
  const [endDate, setEndDate] = useState(
    new Date(fullyear + "/" + month + "/" + day)
  );
  var selectedStartYear = startDate.getFullYear();
  var selectedStartMonth = startDate.getMonth() + 1;
  var selectedStartDate = startDate.getDate();

  var selectedEndYear = endDate.getFullYear();
  var selectedEndMonth = endDate.getMonth() + 1;
  var selectedEndDate = endDate.getDate();

  const GetWorkerClockRecords = async () => {
    const resp = await axios.post(window.$DBURL + "schedule/clock/store", {
      StartDate:
        selectedStartYear +
        "-" +
        selectedStartMonth +
        "-" +
        selectedStartDate +
        "T" +
        0 +
        0 +
        ":" +
        0 +
        0,
      EndDate:
        selectedEndYear +
        "-" +
        selectedEndMonth +
        "-" +
        selectedEndDate +
        "T" +
        23 +
        ":" +
        59,
      Id: params.Id,
    });
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.clock !== null) {
          setClockMap(resp.data.clocks);
        }
      }
    }
  };

  const HandleFormClock = async ({ adjClockInValue, adjClockOutValue }) => {
    newClockInSync = adjClockInValue;
    newClockOutSync = adjClockOutValue;
    if (adjClockInValue !== undefined && adjClockOutValue !== undefined) {
      newClockInSyncUpdated =
        clockin.substring(0, 10) + adjClockInValue + clockin.substring(16, 20);
      newClockOutSyncUpdated =
        clockout.substring(0, 10) +
        adjClockOutValue +
        clockout.substring(16, 20);
    } else if (adjClockOutValue !== undefined) {
      newClockInSyncUpdated = clockin;
      newClockOutSyncUpdated =
        clockout.substring(0, 11) +
        adjClockOutValue +
        clockout.substring(16, 20);
    } else if (adjClockInValue !== undefined) {
      newClockInSyncUpdated =
        clockin.substring(0, 11) + adjClockInValue + clockin.substring(16, 20);
      newClockOutSyncUpdated = clockout;
    }
  };

  const HandleAdjustment = async () => {
    if (newClockOutSync === undefined) {
      const resp = await axios.put(window.$DBURL + "schedule/clock", {
        Id: clockId,
        Schedule_Id: scheduleId,
        Crew_Id: params.userId,
        Crewname: params.workerName,
        Clockin: clockin,
        Clockout: clockout,
        AdjClockin: newClockInSyncUpdated || clockin,
      });
      if (resp.status === 200) {
        newClockInSyncUpdated = "";
      }
    } else if (newClockInSync === undefined) {
      const resp = await axios.put(window.$DBURL + "schedule/clock", {
        Id: clockId,
        Schedule_Id: scheduleId,
        Crew_Id: params.userId,
        Crewname: params.workerName,
        Clockin: clockin,
        Clockout: clockout,
        AdjClockout: newClockOutSyncUpdated || clockout,
      });
      if (resp.status === 200) {
        newClockOutSyncUpdated = "";
      }
    }
    GetWorkerClockRecords();
  };

  useEffect(() => {
    GetWorkerClockRecords();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetWorkerClockRecords();
  }, [startDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    GetWorkerClockRecords();
  }, [endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexContMobileColumn>
      <FullWidthMobileCont100vw100vh>
        <Menu> </Menu>
      </FullWidthMobileCont100vw100vh>

      <FlexColumnCont80vwAutoHeight>
        <ActionMenu />
        <FlexColumnFullWidth>
          <FlexRowContFullWidth>
            <LeagueSpartanExtraLargeBold>
              {workerName}
            </LeagueSpartanExtraLargeBold>
            <FlexRowContFullWidth>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </FlexRowContFullWidth>
            <RegularResponsiveButton>
              Export to Spreadsheet
            </RegularResponsiveButton>
          </FlexRowContFullWidth>

          <FlexRowCont100PercentSpaceBetween>
            <RegularText>Index</RegularText>
            <RegularText>Date</RegularText>
            <RegularText>Clock-In</RegularText>
            <RegularText>Clock-Out</RegularText>
            <RegularText>Adj. Clock-In</RegularText>
            <RegularText>Adj. Clock-Out</RegularText>
          </FlexRowCont100PercentSpaceBetween>

          {clockMap.map((o, i) => (
            <div key={i}>
              <LeagueSpartanExtraLargeBold>
                {o.Name}
              </LeagueSpartanExtraLargeBold>
              {o.Clocks.map((record, keyNum) => (
                <ClockList
                  key={i}
                  indexNum={i + 1}
                  year={record.Clockin.substring(0, 4)}
                  month={record.Clockin.substring(5, 7)}
                  day={record.Clockin.substring(8, 10)}
                  clockin={record.Clockin}
                  clockout={record.Clockout}
                  adjclockin={record.AdjClockin}
                  adjclockout={record.AdjClockout}
                  roleID={roleNum}
                  onPreview={HandleFormClock}
                  onUpdateAdjClock={HandleAdjustment}
                  onSetId={() => {
                    setScheduleId(o.Schedule_Id);
                    setClockId(record.Id);
                    setClockIn(record.Clockin);
                    setClockOut(record.Clockout);
                  }}
                ></ClockList>
              ))}
              <LeagueSpartanExtraLargeBold>
                Total Hours:{" "}
              </LeagueSpartanExtraLargeBold>
              <RegularText>{o.Hours.toFixed(2)} hours worked</RegularText>
              <hr></hr>
            </div>
          ))}
        </FlexColumnFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexContMobileColumn>
  );
};

ClockScheduleSummary.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default ClockScheduleSummary;
