import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import { StickyCont400x400px } from "../../../StylesheetComps/Cont";
import { Div300pxMargin30px } from "../../../StylesheetComps/Div";
import { FixedOverlay1000vh } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth } from "../../../StylesheetComps/Quote";
import Input from "../../Input";

const CityList = styled.div`
  width: 80%;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  color: black;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px;
  margin-left: 20px;
  line-height: 40px;
  cursor: pointer;
`;

const SupplierCont = styled.div``;

const EditSupplierCard = ({
  open,
  onClose,
  RefreshSupplierList,
  SupplierArray,
  productid,
}) => {
  const [supplierId, setSupplierId] = useState(0);
  const [itemName, setItemName] = useState("");
  const [posInput, setPosInput] = useState("none");
  const [positionSupp, setPositionSupp] = useState(0);

  const AddSupplierToProduct = async (product_id, supplier_id, position) => {
    const resp = await axios.post(
      window.$DBURL +
        "catalog/product/" +
        product_id +
        "/supplier/" +
        supplier_id +
        "/position/" +
        position
    );
    if (resp.status === 200) {
      RefreshSupplierList();
      onClose();
    }
  };

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlay1000vh>
        <StickyCont400x400px>
          <Div300pxMargin30px>
            {SupplierArray.map((o, i) => (
              <SupplierCont
                onClick={() => {
                  setSupplierId(o.Id);
                  setItemName(o.Name);
                  setPosInput("block");
                }}
              >
                <CityList>{o.Name}</CityList>
                <hr></hr>
              </SupplierCont>
            ))}
            <Input
              inputwidth="71%"
              inputheight={"50%"}
              placeholder={"Enter Position"}
              inputdisplay={posInput}
              onChange={(e) => {
                setPositionSupp(e.target.value);
              }}
            ></Input>
            <Cont100PercentWidth>
              <RegularResponsiveButton
                onClick={() => {
                  AddSupplierToProduct(productid, supplierId, positionSupp);
                }}
              >
                Add {itemName}
              </RegularResponsiveButton>
            </Cont100PercentWidth>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={onClose}
            >
              Cancel
            </RegularResponsiveButton>
          </Div300pxMargin30px>
        </StickyCont400x400px>{" "}
      </FixedOverlay1000vh>{" "}
    </>
  );
};

EditSupplierCard.defaultProps = {
  onChange: () => {},
  RefreshSupplierList: () => {},
  defaultName: "",
  SupplierArray: ["Default Name"],
};

export default EditSupplierCard;
