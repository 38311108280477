// React import
import React, { useState } from "react";

// Input component import
import Input from "../Input";

// FlexDiv, FlexRowCont100x8PercentSpaceAround, and RelativeContBGColorWhite imported from the "Cont" stylesheet
import {
  FlexDiv,
  FlexRowCont100x8PercentSpaceAround,
  RelativeContBGColorWhite,
} from "../../StylesheetComps/Cont";

// FlexDiv40PercentMobileWidth100Percent imported from the "Div" stylesheet
import { FlexDiv40PercentMobileWidth100Percent } from "../../StylesheetComps/Div";

// RegularText, SmallTitleBoldWithWidth, and RegularTextBoldRed imported from the "Titles" stylesheet
import {
  RegularText,
  SmallTitleBoldWithWidth,
  RegularTextBoldRed,
} from "../../StylesheetComps/Titles";

// RegularResponsiveButton imported from the "Components" stylesheet
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

// MobileInput imported from the "Inputs" mobile component
import { MobileInput } from "../../MobileComponents/Inputs";

// ColorPalette imported from the "Colors" stylesheet
import { ColorPalette } from "../../StylesheetComps/Colors";

const ChangePass = ({ passSwitch, onClose, onPasswordChanged, onPreview }) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [errorText, setErrorText] = useState("");

  if (!passSwitch) return null;

  if (window.$ISMOBILE === true) {
    return (
      <RelativeContBGColorWhite>
        <RegularText>Change Password</RegularText>
        <hr></hr>
        <FlexDiv>
          <SmallTitleBoldWithWidth>Old Password</SmallTitleBoldWithWidth>
          <MobileInput
            type="password"
            inputwidth="40%"
            inputheight="5px"
            placeholder="Old Password"
            onChange={(e) => {
              setOldPass(e.target.value);
            }}
          ></MobileInput>
        </FlexDiv>
        <br></br>
        <FlexDiv>
          <SmallTitleBoldWithWidth>New Password</SmallTitleBoldWithWidth>
          <MobileInput
            type="password"
            inputwidth="40%"
            inputheight="5px"
            placeholder="New Password"
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
          ></MobileInput>
        </FlexDiv>
        <br></br>
        <FlexDiv>
          <SmallTitleBoldWithWidth>
            Confirm New Password
          </SmallTitleBoldWithWidth>
          <MobileInput
            type="password"
            inputwidth="40%"
            inputheight="1px"
            placeholder="Confirm New Password"
            onChange={(e) => {
              setConfirmNewPass(e.target.value);
            }}
          ></MobileInput>
        </FlexDiv>
        <br></br>
        <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
        <FlexRowCont100x8PercentSpaceAround>
          <RegularResponsiveButton
            bgcolor={ColorPalette.PrimaryRed}
            onClick={() => {
              onClose();
              setErrorText("");
            }}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            onClick={() => {
              if (confirmNewPass !== newPass || oldPass === "") {
                setErrorText("Passwords do not match");
              } else {
                onPreview({ oldPass, newPass });
                onPasswordChanged(oldPass, newPass);
                setErrorText("");
              }
            }}
          >
            Save Changes
          </RegularResponsiveButton>
        </FlexRowCont100x8PercentSpaceAround>
      </RelativeContBGColorWhite>
    );
  } else {
    return (
      <RelativeContBGColorWhite>
        <RegularText>Change Password</RegularText>
        <hr></hr>
        <FlexDiv>
          <SmallTitleBoldWithWidth>Old Password</SmallTitleBoldWithWidth>
          <Input
            type="password"
            inputwidth="40%"
            inputheight="5px"
            placeholder="Old Password"
            onChange={(e) => {
              setOldPass(e.target.value);
            }}
          ></Input>
        </FlexDiv>
        <FlexDiv>
          <SmallTitleBoldWithWidth>New Password</SmallTitleBoldWithWidth>
          <Input
            type="password"
            inputwidth="40%"
            inputheight="5px"
            placeholder="New Password"
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
          ></Input>
        </FlexDiv>
        <FlexDiv>
          <SmallTitleBoldWithWidth>
            Confirm New Password
          </SmallTitleBoldWithWidth>
          <Input
            type="password"
            inputwidth="40%"
            inputheight="1px"
            placeholder="Confirm New Password"
            onChange={(e) => {
              setConfirmNewPass(e.target.value);
            }}
          ></Input>
        </FlexDiv>
        <RegularTextBoldRed>{errorText}</RegularTextBoldRed>
        <FlexDiv40PercentMobileWidth100Percent>
          <RegularResponsiveButton
            onClick={() => {
              onClose();
              setErrorText("");
            }}
            bgcolor={ColorPalette.PrimaryRed}
          >
            Cancel
          </RegularResponsiveButton>
          <RegularResponsiveButton
            onClick={() => {
              if (confirmNewPass !== newPass || oldPass === "") {
                setErrorText("Passwords do not match");
              } else {
                onPreview({ oldPass, newPass });
                onPasswordChanged(oldPass, newPass);
                setErrorText("");
              }
            }}
          >
            Save Changes
          </RegularResponsiveButton>
        </FlexDiv40PercentMobileWidth100Percent>
      </RelativeContBGColorWhite>
    );
  }
};

ChangePass.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onPasswordChanged: () => {},
};

export default ChangePass;
