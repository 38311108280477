/* eslint-disable */

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import {
  FlexColumnCont80vwAutoHeight,
  FlexColumnFullWidth,
  FlexContMobileColumn,
  FlexRowContFlexStart,
  FullWidthMobileCont100vw100vh,
} from "../../StylesheetComps/Cont";
import { Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import { RegularText } from "../../StylesheetComps/Titles";

const StoreCalendarScreen = () => {
  //=============================================================================
  // Store Calendar Screen - View Availability for Appointments of Store
  //=============================================================================

  //=============================================================================
  // Users can click on date to view installers available on that date
  //=============================================================================

  const history = useHistory();

  const [storeId, setStoreId] = useState(0);
  const [roleNum, setRoleNum] = useState(1000);
  const [storeAppointments, setStoreAppointments] = useState([]);

  const D = new Date();
  const currentYear = D.getFullYear();
  const currentMonth = D.getMonth() + 1;
  const params = useParams();

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const GetSessionInfo = async () => {
    try {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (!userInfo) throw new Error("No userInfo found in sessionStorage");

      setStoreId(userInfo.Store_Id);
      if (storeId === 0) {
        GetAppointmentsSync();
      } else {
        GetAppointmentsAsync();
      }
    } catch (error) {
      // handle error
    }
  };

  const [storeName, setStoreName] = useState("");

  const GetStoreInfo = async () => {
    try {
      const resp = await axios.get(`${window.$DBURL}store/${params.storeId}`);

      if (resp.status === 200 && resp.data && resp.data.store) {
        setStoreName(resp.data.store.Name);
      }
    } catch (error) {
      // handle error
    }
  };

  var fulldate = "";
  var storeMonth = 0;
  var storeYear = 0;
  var storeDay = 0;

  const GetAppointmentsSync = async () => {
    try {
      const resp = await axios.post(
        `${window.$DBURL}appointment/store/calendar`,
        {
          Year: currentYear,
          Month: currentMonth,
          Store_Id: params.storeId,
        }
      );

      if (resp.status === 200 && resp.data && resp.data.status) {
        setStoreAppointments(resp.data.status);
      }

      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (!userInfo) throw new Error("No userInfo found in sessionStorage");
      setRoleNum(userInfo.Role);
    } catch (error) {
      // handle error
    }
  };

  const GetAppointmentsAsync = async () => {
    try {
      const resp = await axios.post(
        `${window.$DBURL}appointment/store/calendar`,
        {
          Year: currentYear,
          Month: currentMonth,
          Store_Id: params.storeId,
        }
      );

      if (resp.status === 200 && resp.data && resp.data.status) {
        setStoreAppointments(resp.data.status);
      }

      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (!userInfo) throw new Error("No userInfo found in sessionStorage");
      setRoleNum(userInfo.Role);
    } catch (error) {
      // handle error
    }
  };

  const closeDayPropGetter = (date) => {
    var obj = storeAppointments.find((o, i) => {
      if (
        (o.Date === date.getDate() &&
          o.Month === date.getMonth() + 1 &&
          o.Year === date.getFullYear() &&
          o.Status === 1) ||
        (o.Date === date.getDate() &&
          o.Month === date.getMonth() + 1 &&
          o.Year === date.getFullYear() &&
          o.Status === 2)
      )
        return storeAppointments[i];
    });
    if (
      obj !== undefined &&
      obj.Date === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear() &&
      obj.Status === 1
    )
      return { style: { backgroundColor: "#169ADB" } };
    else if (
      obj !== undefined &&
      obj.Date === date.getDate() &&
      obj.Month === date.getMonth() + 1 &&
      obj.Year === date.getFullYear() &&
      obj.Status === 2
    )
      return { style: { backgroundColor: "red" } };
  };

  useEffect(() => {
    SetHeaderAuthorization();
    GetSessionInfo();
    GetStoreInfo();
  }, []);

  const calendarStyle = {
    height: "65vh",
    width: "100%",
    "@media (maxWidth: 1006px)": {
      height: "65vw",
    },
  };
  if (roleNum !== 1000) {
    return (
      <FlexContMobileColumn>
        <FullWidthMobileCont100vw100vh>
          <Menu> </Menu>
        </FullWidthMobileCont100vw100vh>
        <FlexColumnCont80vwAutoHeight>
          <ActionMenu
            mobilePageProp="Store Calendar"
            pageProp={""}
            calendarbottom={"4px solid #169ADB"}
          ></ActionMenu>
          <FlexColumnFullWidth>
            <FlexRowContFlexStart height="auto">
              <Icon30x40pxPointer
                src="/backarrow.svg"
                onClick={() => {
                  history.push("/storelistcalendars/" + params.Id);
                }}
              ></Icon30x40pxPointer>
              <RegularText>{storeName + "'s Store Calendar"}</RegularText>
            </FlexRowContFlexStart>
            <Calendar
              events={[]}
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              style={calendarStyle}
              dayPropGetter={closeDayPropGetter}
              views={{ month: true }}
              longPressThreshold={10}
              selectable={true}
              dayLayoutAlgorithm="no-overlap"
              onSelectSlot={(slotInfo) => {
                fulldate = slotInfo.slots[0];
                storeMonth = fulldate.getMonth() + 1;
                storeYear = fulldate.getFullYear();
                storeDay = fulldate.getDate();
                if (
                  storeAppointments.find(function (o, i) {
                    return (
                      o.Date === fulldate.getDate() &&
                      o.Month === fulldate.getMonth() + 1 &&
                      o.Year === fulldate.getFullYear() &&
                      o.Status === 2
                    );
                  })
                ) {
                } else if (D > fulldate) {
                } else {
                  history.push(
                    "/installersavailable/" +
                      params.Id +
                      "/" +
                      params.storeId +
                      "/" +
                      storeMonth +
                      "/" +
                      storeDay +
                      "/" +
                      storeYear +
                      "/" +
                      0 +
                      "/0"
                  );
                }
              }}
            />
          </FlexColumnFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexContMobileColumn>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

StoreCalendarScreen.defaultProps = {
  ProfileName: "Default Name",
  onPreview: () => {},
  onProfileUpdated: () => {},
};

export default StoreCalendarScreen;
