import { useEffect, useState } from "react";
import {
  DeleteQuoteTool,
  UpdateQuoteTool,
} from "../../API_Functions/CaseQuote_Functions";
import { TextInputProp } from "../../StylesheetComps/AuthSheet";
import {
  Cont100Percent4PercentDisplayProp,
  FlexContFullWidthJustifyCenter,
  FlexRowContFullWidth,
} from "../../StylesheetComps/Cont";
import {
  FlexColumnDivCentered20Percent,
  FlexDivCentered45Percent,
  FullWidthDivFlexStart,
  QuadDeleteTitle,
  QuoteQuadMedium,
} from "../../StylesheetComps/Div";
import {
  BlueUnderlinePointerPara,
  Futura25PercentWidth,
  SmallText,
  SmallTitleBold,
} from "../../StylesheetComps/Titles";

const QuoteTableDeliveryItems = ({
  onItemChange,
  itemId,
  toolobj,
  noChange,
}) => {
  const [youtubeLinkProp, setYoutubeLinkProp] = useState("");
  const [txtLineState] = useState("none");
  const [showSave, setShowSave] = useState("none");
  const [showUnit, setShowUnit] = useState("block");
  const [displayView, setDisplayView] = useState("block");
  const [itemQuantity, setItemQuantity] = useState(0);
  const [rate] = useState(toolobj.Price / toolobj.Quant);

  const DeleteItem = async (itemid) => {
    var data = await DeleteQuoteTool(itemid);
    if (data === null) {
      onItemChange();
    }
  };

  useEffect(() => {
    if (toolobj?.VideoUrl !== null) {
      var p =
        /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (toolobj?.VideoUrl?.match(p)) {
        setYoutubeLinkProp(toolobj?.VideoUrl);
        setDisplayView("block");
      } else {
        setDisplayView("none");
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ExecuteUpdate = async () => {
    if (itemQuantity !== 0 && !noChange) {
      toolobj.Quant = itemQuantity;
      UpdateItem(toolobj);
    }
    setShowSave("none");
    if (!noChange) setShowUnit("block");
  };

  const onFocus = () => {
    if (!noChange) setShowSave("block");

    setShowUnit("none");
  };

  const UpdateItem = async (quoteitem) => {
    var data = await UpdateQuoteTool(quoteitem);
    if (data !== null) {
      onItemChange();
    }
  };

  return (
    <div>
      <Cont100Percent4PercentDisplayProp>
        <FullWidthDivFlexStart>
          <FlexDivCentered45Percent>
            <FlexRowContFullWidth>
              <SmallTitleBold>
                {toolobj.ToolName + " - " + toolobj.Notes}
                {toolobj.Item_Id}
              </SmallTitleBold>
              <BlueUnderlinePointerPara
                display={displayView}
                onClick={() => {
                  if (toolobj.VideoUrl !== null) {
                    window.open(youtubeLinkProp, "_blank");
                  }
                }}
              >
                View
              </BlueUnderlinePointerPara>
            </FlexRowContFullWidth>
          </FlexDivCentered45Percent>

          <FlexColumnDivCentered20Percent>
            <FlexContFullWidthJustifyCenter>
              <TextInputProp
              key={toolobj.Quant}
                inputwidth="50%"
                onFocus={onFocus}
                type="number"
                defaultValue={toolobj.Quant}
                onChange={(e) => {
                  if (!noChange) setItemQuantity(e.target.value);
                }}
              ></TextInputProp>
              <Futura25PercentWidth
                displayProp={showUnit}
                txtLine={txtLineState}
                key={toolobj.Unit}
              >
                {toolobj.Unit}
              </Futura25PercentWidth>
              <BlueUnderlinePointerPara
                displayProp={showSave}
                onClick={ExecuteUpdate}
              >
                Save
              </BlueUnderlinePointerPara>
            </FlexContFullWidthJustifyCenter>
          </FlexColumnDivCentered20Percent>

          <QuoteQuadMedium>
            <SmallText key={rate} txtLine={txtLineState}>{rate.toFixed(2)}</SmallText>
          </QuoteQuadMedium>

          <QuoteQuadMedium>
            <SmallText key={toolobj.Price}>${toolobj.Price.toFixed(2)}</SmallText>
          </QuoteQuadMedium>

          <QuoteQuadMedium>
            <QuadDeleteTitle
              onClick={() => {
                DeleteItem(itemId);
              }}
            >
              Delete
            </QuadDeleteTitle>
          </QuoteQuadMedium>
        </FullWidthDivFlexStart>
      </Cont100Percent4PercentDisplayProp>
    </div>
  );
};

QuoteTableDeliveryItems.defaultProps = {
  itemId: 0,
  onItemChange: () => {},
};

export default QuoteTableDeliveryItems;
