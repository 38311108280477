import React, { useState, useEffect } from "react";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexColumnCont90x95Percent,
  FlexColumnCont95x30Percent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import {
  LandscapePara,
  MediumStyledImage,
  SmallHeaderLeagueSpartan,
} from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { AddQuotePhoto } from "../../../API_Functions/CaseQuote_Functions";
import { Icon20x20 } from "../../../StylesheetComps/Imgs/Icons";
import { LinkText } from "../../../StylesheetComps/AuthSheet";
const QuotePhotoUploadPreviewWindow = ({
  open,
  CloseWindow,
  CancelUpload,
  previewsrc,
  quoteObj,
  UploadSuccessful,
}) => {
  const SpinnerIcon = "/Spinner.svg";

  const [previewImage, setPreviewImage] = useState("");
  const [imageCaption, setImageCaption] = useState("");

  //Upload Boolean prevents Images from getting uploaded twice.

  const [uploadBoolean, setUploadBoolean] = useState(false);

  const AddPhotoToQuote = async (quoteid, imgurl, caption) => {
    var photo = {
      Caption: caption,
      File: imgurl,
      Quote_Id: quoteid,
    };
    var newquotepic = await AddQuotePhoto(photo);
    if (newquotepic !== null) {
      setImageCaption("");
      setUploadBoolean(false);
      UploadSuccessful();
      CloseWindow();
    }
  };

  const ChangeUIToPreventDoubles = async (uploadboolean) => {
    if (uploadboolean === false) {
      setUploadBoolean(true);
      AddPhotoToQuote(quoteObj.Id, previewsrc, imageCaption);
    } else {
    }
  };

  useEffect(() => {
    if (previewsrc !== undefined && previewsrc !== null) {
      if (previewsrc.includes("pdf") || previewsrc.includes("PDF")) {
        setPreviewImage("/pdficon.png");
      } else {
        setPreviewImage(window.$IMGURL + previewsrc);
      }
    }
  }, [previewsrc]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight>
        <FlexRowCont
          height="100%"
          justifycontent="flex-start"
          alignitems="baseline"
        >
          <FlexColumnCont90x95Percent>
            <SmallHeaderLeagueSpartan>Preview Photo</SmallHeaderLeagueSpartan>
            <FlexRowCont width="40%" height="40%" marginbottom="5%">
              <MediumStyledImage
                objfit="contain"
                mobileheight="20vh"
                mobilewidth="40vw"
                height="100%"
                src={previewImage}
              ></MediumStyledImage>
            </FlexRowCont>
            <FlexColumnCont95x30Percent>
              <SmallHeaderLeagueSpartan>
                Photo Description
              </SmallHeaderLeagueSpartan>
              <LandscapePara
                mobilewidth="68vw"
                mobileheight="25vh"
                maxLength="250"
                onChange={(e) => {
                  setImageCaption(e.target.value);
                }}
              ></LandscapePara>
            </FlexColumnCont95x30Percent>
            <FlexRowCont justifycontent="flex-end">
              <LinkText
                onClick={() => {
                  CancelUpload();
                }}
              >
                Cancel
              </LinkText>
              <RegularResponsiveButton
                onClick={() => {
                  setPreviewImage(SpinnerIcon);
                  ChangeUIToPreventDoubles(uploadBoolean);
                }}
              >
                Upload
              </RegularResponsiveButton>
            </FlexRowCont>
          </FlexColumnCont90x95Percent>
          <Icon20x20
            onClick={() => {
              CancelUpload();
            }}
            src="/Close.svg"
          ></Icon20x20>
        </FlexRowCont>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  );
};

QuotePhotoUploadPreviewWindow.defaultProps = {
  CloseWindow: () => {},
  CancelUpload: () => {},
  UploadSuccessful: () => {},
};

export default QuotePhotoUploadPreviewWindow;
