import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  FlexColumnContHalfHeightSpaceBetween,
  FlexColumnFullWidth,
  FlexCont70PercentAuto,
  FlexDiv,
  FlexRowCont100PercentFlexEnd,
  FlexRowCont60PercentFlexStart,
  FlexRowContFlexStart,
  FlexRowContFullWidth,
  FlexRowContHalfHeight,
  FlexRowContHalfHeightFlexStart,
} from "../../StylesheetComps/Cont";
import DeleteConfirmWindow from "../Windows/DeleteProduct";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import { AddProduct } from "../../API_Functions/CatProduct_Functions";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import { Icon30x40pxPointer } from "../../StylesheetComps/Imgs/Icons";
import { Image300x200 } from "../../StylesheetComps/LandscapeForm";
import { SmallHeader } from "../../StylesheetComps/Menu";
import { AbsoluteOverlayDivFullWidth } from "../../StylesheetComps/OverlayCont";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanTiny,
  MediumHeader,
  RegularTextWithMargins,
} from "../../StylesheetComps/Titles";
import EditSupplierCard from "../Windows/EditProductSupplier";
import { FlexColumnDiv, FlexDivSpaceAround } from "../../StylesheetComps/Div";

const ProductInfoCard = ({
  open,
  onView,
  onClose,
  onDelete,
  EditProd,
  Product,
  RefreshProductInfoCard,
}) => {
  const params = useParams();
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [editSupplierDisplay, setEditSupplierDisplay] = useState(false);
  const [supplierList, setSupplierList] = useState([]);

  const CreateDuplicateProduct = async (product) => {
    var data = await AddProduct(product);
    if (data !== null) {
      Product = data;
    }
  };

  const SetSupplierOptions = async (storeid) => {
    const supplierlist = await axios.get(
      window.$DBURL + "/store/" + storeid + "/supplier"
    );
    if (supplierlist.status === 200) {
      setSupplierList(supplierlist.data.suppliers);
    }
  };

  const RemoveSupplierFromProduct = async (productid, supplierid) => {
    const resp = await axios.delete(
      window.$DBURL + "catalog/product/" + productid + "/supplier/" + supplierid
    );
    if (resp.status === 200) {
      RefreshProductInfoCard();
      SetSupplierOptions(params.Id);
    }
  };

  useEffect(() => {
    SetSupplierOptions(params.Id);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!open) return null;
  if (Product !== undefined && Product !== null) {
    return (
      <AbsoluteOverlayDivFullWidth>
        <FlexCont70PercentAuto width='55%' onClick={onView}>
          <EditSupplierCard
            open={editSupplierDisplay}
            SupplierArray={supplierList}
            onClose={() => {
              setEditSupplierDisplay(false);
              RefreshProductInfoCard();
            }}
            productid={Product.Id}
          ></EditSupplierCard>
          <FlexRowContHalfHeight>
            <FlexRowCont60PercentFlexStart>
              <FlexColumnFullWidth>
                <LeagueSpartanLarge whitespace="wrap">
                  {Product.Name}
                </LeagueSpartanLarge>
                <Image300x200
                  display={Product.ImageUrl === "" ? "none" : "block"}
                  src={window.$IMGURL + Product.ImageUrl}
                ></Image300x200>
              </FlexColumnFullWidth>
            </FlexRowCont60PercentFlexStart>
            <FlexDivSpaceAround width='35%'>
              <FlexColumnDiv alignitems='center'>
            <LeagueSpartanTiny>Edit Product</LeagueSpartanTiny>
            <Icon30x40pxPointer onClick={EditProd} src='/EditIcon.svg'>
            </Icon30x40pxPointer>
            </FlexColumnDiv>
            <FlexColumnDiv alignitems='center'>
              <LeagueSpartanTiny>Duplicate Product</LeagueSpartanTiny>
            <Icon30x40pxPointer onClick={async () => {
                await CreateDuplicateProduct(Product);
                onClose();
              }} src='/Duplicate_Icon.svg'>
            </Icon30x40pxPointer>
            </FlexColumnDiv>
            <FlexColumnDiv alignitems='center'>
            <LeagueSpartanTiny>Suppliers</LeagueSpartanTiny>
            <Icon30x40pxPointer
              onClick={() => {
                setEditSupplierDisplay(true);
              }}
              src="/Supplier_Icon.svg"
            ></Icon30x40pxPointer>
            </FlexColumnDiv>
           
            </FlexDivSpaceAround>

            <Icon30x40pxPointer
              onClick={() => {
                onClose();
              }}
              src="/Close.svg"
            ></Icon30x40pxPointer>
        
          </FlexRowContHalfHeight>

          <FlexRowContHalfHeightFlexStart></FlexRowContHalfHeightFlexStart>

          {/* <FlexColumnContHalfHeightSpaceBetween> */}
            {/* <FlexRowContFlexStart>
              <LeagueSpartanHeaderSmall>Suppliers:</LeagueSpartanHeaderSmall>
              {Product.Supplier.map((o, i) => (
                <FlexDiv key={i}>
                  <MediumHeader marginright="0px">{o.User.Name}</MediumHeader>
                  <Icon30px20pxPointer
                    onClick={() => {
                      RemoveSupplierFromProduct(Product.Id, o.User.Id);
                    }}
                    src="/Garbage_Icon.svg"
                  ></Icon30px20pxPointer>
                  <MediumHeader marginright="0px">,</MediumHeader>
                </FlexDiv>
              ))}
            </FlexRowContFlexStart> */}

            {/* <FlexRowContFlexStart>
              <LeagueSpartanHeaderSmall>Product Link:</LeagueSpartanHeaderSmall>
              <a
                href={"https://" + Product.ProductLink}
                target={"_blank"}
                rel="noreferrer"
              >
                <SmallHeader>{Product.ProductLink}</SmallHeader>
              </a>
            </FlexRowContFlexStart> */}

          {/* </FlexColumnContHalfHeightSpaceBetween> */}

          <FlexRowContHalfHeightFlexStart>
            <LeagueSpartanHeaderSmall>Description:</LeagueSpartanHeaderSmall>
            <RegularTextWithMargins>
              {Product.Description}
            </RegularTextWithMargins>
          </FlexRowContHalfHeightFlexStart>

          <FlexRowContHalfHeightFlexStart>
            <LeagueSpartanHeaderSmall>Taxes:</LeagueSpartanHeaderSmall>
            {Product.SalesTax.map((o, i) => (
              <RegularTextWithMargins key={i}>
                {o.TaxCode}: {o.Rate}%
              </RegularTextWithMargins>
            ))}
            
            {/* <FlexRowContFlexStart>
              <LeagueSpartanHeaderSmall>SKU:</LeagueSpartanHeaderSmall>
              <SmallHeader>
                {Product.Code === null ? "N/A" : Product.Code}
              </SmallHeader>
            </FlexRowContFlexStart> */}
          </FlexRowContHalfHeightFlexStart>

          <FlexRowContHalfHeightFlexStart>
            <LeagueSpartanHeaderSmall>Pricing:</LeagueSpartanHeaderSmall>
            <RegularTextWithMargins>
              Original Price: ${Product.OriginalPrice.toFixed(4)}
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Markup: {Product.Markup.toFixed(2)}%
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              UnitPrice: ${Product.UnitPrice.toFixed(2)}
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Unit: {Product.Unit}
            </RegularTextWithMargins>
          </FlexRowContHalfHeightFlexStart>

          <FlexRowContHalfHeightFlexStart>
          <LeagueSpartanHeaderSmall>Installation:</LeagueSpartanHeaderSmall>
            <RegularTextWithMargins>
              Install Speed: {Product.InstallSpeed.toFixed(4)}
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Allowance: {Product.Allowance}%
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Setup Time: {Product.SetupTime} Hours
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Minimum: {Product.Minimum}
            </RegularTextWithMargins>
          </FlexRowContHalfHeightFlexStart>

          <FlexRowContHalfHeightFlexStart>
          <LeagueSpartanHeaderSmall>Conversion:</LeagueSpartanHeaderSmall>
            <RegularTextWithMargins>
              Convert: {Product.Convert === false ? "No" : "Yes"}
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Denominator: {Product.Denominator}
            </RegularTextWithMargins>
            <RegularTextWithMargins>
              Display Unit:{" "}
              {Product.DisplayUnit === "" ? "N/A" : Product.DisplayUnit}
            </RegularTextWithMargins>
          </FlexRowContHalfHeightFlexStart>

          <FlexRowContFullWidth>
            <FlexRowCont100PercentFlexEnd>
              <RegularResponsiveButton
                bgcolor={ColorPalette.PrimaryRed}
                onClick={() => {
                  setDeleteProduct(true);
                }}
              >
                Delete Product
              </RegularResponsiveButton>
            </FlexRowCont100PercentFlexEnd>

            <DeleteConfirmWindow
              deleteOpen={deleteProduct}
              onDeleteFunction={onDelete}
              closeDel={() => {
                setDeleteProduct(false);
              }}
            ></DeleteConfirmWindow>
          </FlexRowContFullWidth>
        </FlexCont70PercentAuto>
      </AbsoluteOverlayDivFullWidth>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

ProductInfoCard.defaultProps = {
  ProductID: 0,
  ProductAdd: 0,
  RefreshProductInfoCard: () => {},
};

export default ProductInfoCard;
