import React from "react";
import { Icon35px100Perc } from "../../../StylesheetComps/Imgs/Icons";
import { FlexDiv, ContAutoWidth5vh } from "../../../StylesheetComps/Cont";
import { RegularTextBold } from "../../../StylesheetComps/Titles";

const ZoneCityCard = ({ CityNameText, delCityFromZone }) => {
  return (
    <ContAutoWidth5vh>
      <RegularTextBold>{CityNameText}</RegularTextBold>
      <FlexDiv>
        <Icon35px100Perc
          src="/Garbage_Icon.svg"
          onClick={delCityFromZone}
        ></Icon35px100Perc>
      </FlexDiv>
    </ContAutoWidth5vh>
  );
};

ZoneCityCard.defaultProps = {
  CityNameText: "EX. Mississauga",
  MetroName: "Toronto",
  CityID: 0,
};

export default ZoneCityCard;
