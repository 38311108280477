import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { QuoteStatus } from "../../API_Functions/CaseQuote_Functions";
import { MobileSmallGreenButton } from "../../MobileComponents/Buttons";
import { LeagueHeader12px } from "../../MobileComponents/Text";
import { LargeResponsiveButton } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexColumnCont100xAutoPercent } from "../../StylesheetComps/Cont";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { CaseCardCont } from "../../StylesheetComps/Projects";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { MediumHeader100PercentWidth } from "../../StylesheetComps/Titles";
const ProposalCardUser = ({ proposalObj, numberOfQuotes, proposallist }) => {
  const history = useHistory();
  const [marginLeftRevision, setMarginLeftRevision] = useState("");
  const revisionMarginLeft = "0px";
  const [revisionText, setRevisionText] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [statusText, setStatusText] = useState("");
  const [highestRevisionNumber, setHighestRevisionNumber] = useState(0);

  useEffect(() => {
    var quotetag = "";
    switch (proposalObj.QuoteStatus) {
      case QuoteStatus.Created:
        setStatusText("New");
        setStatusColor(ColorPalette.PrimaryButtonBlue);
        break;
      case QuoteStatus.UserNotified:
        setStatusText("New");
        setStatusColor(ColorPalette.PrimaryButtonBlue);
        break;
      case QuoteStatus.RevisionRequested:
        setStatusText("Revision Pending");
        setStatusColor(ColorPalette.PrimaryGrey);
        break;
      case QuoteStatus.Approved:
        setStatusText("Approved");
        setStatusColor(ColorPalette.PrimaryButtonBlue);
        break;
      case QuoteStatus.Declined:
        setStatusText("Declined");
        setStatusColor(ColorPalette.PrimaryRed);
        break;
      case QuoteStatus.Buy:
        setStatusText("Supply Material");
        setStatusColor(ColorPalette.PrimaryButtonBlue);
        break;
      case QuoteStatus.Buy_Install:
        setStatusText("Accepted");
        setStatusColor(ColorPalette.PrimaryButtonBlue);
        break;
      case QuoteStatus.Expired:
        setStatusText("Expired");
        setStatusColor(ColorPalette.PrimaryRed);
        break;
      case QuoteStatus.Canceled:
        setStatusText("Cancelled");
        setStatusColor(ColorPalette.PrimaryRed);
        break;
      default:
    }
    if (proposalObj.Tittle !== null) {
      quotetag = " - " + proposalObj.Tittle;
    } else {
      quotetag = " ";
    }
    if (proposalObj.Revision === 0) {
      switch (proposalObj.Proposal) {
        case 0:
          setRevisionText("Proposal" + quotetag);
          break;
        default:
          setRevisionText("Option " + proposalObj.Proposal + quotetag);
      }
    } else {
      switch (proposalObj.Proposal) {
        case 0:
          setMarginLeftRevision(revisionMarginLeft);
          setRevisionText(
            "Proposal - Revision " + proposalObj.Revision + quotetag
          );
          break;
        default:
          setMarginLeftRevision(revisionMarginLeft);
          setRevisionText(
            "Option " +
              proposalObj.Proposal +
              " - Revision " +
              proposalObj.Revision +
              quotetag
          );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const DetermineRevisionNumber = async (quotelist) => {
    var proposalcases = quotelist.filter((o) => {
      return o.Proposal === proposalObj.Proposal;
    });

    setHighestRevisionNumber(
      Math.max.apply(
        Math,
        proposalcases.map((o) => o.Revision)
      ) + 1
    );
  };

  useEffect(() => {
    if (proposallist !== undefined && proposallist !== null) {
      DetermineRevisionNumber(proposallist);
    }
  }, [proposallist]); // eslint-disable-line react-hooks/exhaustive-deps

  const params = useParams();

  if (window.$ISMOBILE === true) {
    return (
      <CaseCardCont
        onClick={() => {
          history.push(
            "/viewquoteuser/" +
              params.Id +
              "/" +
              params.storeId +
              "/" +
              params.caseId +
              "/" +
              proposalObj.Id +
              "/" +
              numberOfQuotes +
              "/" +
              highestRevisionNumber
          );
        }}
      >
        <FlexColumnCont100xAutoPercent>
          <LeagueHeader12px>{revisionText}</LeagueHeader12px>
        </FlexColumnCont100xAutoPercent>

        <FlexColumnCont100xAutoPercent>
          <SmallHeader>{proposalObj.AddTime.substring(0, 10)}</SmallHeader>
          <MobileSmallGreenButton
            mobilemargintop="0"
            width="150px"
            mobilewidth="30vw"
            height="2.5vw"
            bgcolor={statusColor}
            onClick={() => {
              history.push(
                "/viewquoteuser/" +
                  params.Id +
                  "/" +
                  params.storeId +
                  "/" +
                  params.caseId +
                  "/" +
                  proposalObj.Id +
                  "/" +
                  numberOfQuotes +
                  "/" +
                  highestRevisionNumber
              );
            }}
          >
            {" "}
            {statusText}{" "}
          </MobileSmallGreenButton>
        </FlexColumnCont100xAutoPercent>
      </CaseCardCont>
    );
  } else {
    return (
      <FlexColumnCont
        marginleft={marginLeftRevision}
        mobilewidth="90vw"
        mobilejustifycontent="space-between"
        justifycontent="flex-start"
        margin="0"
        paddingbottom='10px'
        margintop="0%"
        borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
        height="100%"
        width="100%"
        flexdirection="row"
      >
        <Icon30px30pxPointer
          display={
            proposalObj?.QuoteStatus === QuoteStatus.Buy ||
            proposalObj?.QuoteStatus === QuoteStatus.Buy_Install
              ? "block"
              : "none"
          }
          src="/Green_Checkmark_Icon.svg"
        ></Icon30px30pxPointer>
        <MediumHeader100PercentWidth
          maxwidth="74%"
          cursor="pointer"
          onClick={() => {
            history.push(
              "/viewquoteuser/" +
                params.Id +
                "/" +
                params.storeId +
                "/" +
                params.caseId +
                "/" +
                proposalObj.Id +
                "/" +
                numberOfQuotes +
                "/" +
                highestRevisionNumber
            );
          }}
        >
          {revisionText}
        </MediumHeader100PercentWidth>

        <MediumHeader100PercentWidth maxwidth="13%">
          {proposalObj.AddTime.substring(0, 10)}
        </MediumHeader100PercentWidth>

        <LargeResponsiveButton
          width="20%"
          bgcolor={statusColor}
          onClick={() => {
            history.push(
              "/viewquoteuser/" +
                params.Id +
                "/" +
                params.storeId +
                "/" +
                params.caseId +
                "/" +
                proposalObj.Id +
                "/" +
                numberOfQuotes +
                "/" +
                highestRevisionNumber
            );
          }}
        >
          {statusText}
        </LargeResponsiveButton>
      </FlexColumnCont>
    );
  }
};

ProposalCardUser.defaultProps = {};

export default ProposalCardUser;
