//TOOLKIT IMPORT
import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./comps/ScrollToTop";

//PAGES IMPORT
import ProfileScreen from "./pages/Profile";
import EstimateScreen from "./pages/Estimate";
import ManagerListScreen from "./pages/ManagerList";
import RoleListScreen from "./pages/RoleList";
import MetroCatScreen from "./pages/MetroCat";
import RoleCatScreen from "./pages/RoleCat";
import StoreScreen from "./pages/Store";
import MetroIDScreen from "./pages/SingleMetro";
import ProductCategoryScreen from "./pages/ProductCat";
import ZoneScreen from "./pages/SingleZone";
import CasesScreen from "./pages/Cases";
import ProductListScreen from "./pages/ProductList";
import ToolListScreen from "./pages/ToolList";
import QuoteScreen from "./pages/Quote";
import ZoneListScreen from "./pages/ZoneList";
import ViewListOfStoresScreen from "./pages/StoreManager";
import StoreAdminListScreen from "./pages/StoreListAdmin";
import StoreAdminScreen from "./pages/StoreAdmin";
import ActivateScreen from "./pages/Activate";
import ViewQuotePage from "./pages/ViewQuotePage";
import UpdateCasePage from "./pages/UpdateCasePage";
import MyCalendarScreen from "./pages/MyCalendar";
import StoreCalendarScreen from "./pages/StoreCalendar";
import StoreScheduleScreen from "./pages/StoreSchedule";
import StoreInstallersScreen from "./pages/StoreInstallers";
import StoreWorkersScreen from "./pages/StoreWorkers";
import InstallerSetupScreen from "./pages/InstallerAvailability";
import WorkerScheduleScreen from "./pages/MySchedule";
import ClockInOutScreen from "./pages/ClockInOut";
import ClockSchedule from "./pages/ClockSchedule";
import InstallerCalendarScreen from "./pages/InstallerCalendar";
import AcceptOrderScreen from "./pages/AcceptOrder";
import RejectOrderScreen from "./pages/RejectOrder";
import StoreListInstallerScreen from "./pages/StoreListInstallers";
import StoreListCalendarScreen from "./pages/StoreCalendarList";
import StoreListInstallersAvailableScreen from "./pages/InstallersAvailableOn";
import InstallerPersonalCalendar from "./pages/InstallerPersonalCalendar";
import ClockScheduleSummary from "./pages/ClockScheduleSummary";
import StoreScheduleDayScreen from "./pages/StoreScheduleDay";
import StoreListEstimateScreen from "./pages/StoreEstimateList";
import PaymentSuccessScreen from "./pages/PaySuccess";
import PaymentSuccessTransitScreen from "./pages/PaySuccessTransit";
import PaymentFailScreen from "./pages/PayFail";
import QuoteAppointmentScreen from "./pages/QuoteAppointment";
import UserCasesScreen from "./pages/UserCases";
import ToolKitScreen from "./pages/Toolkits";
import LoginPage from "./pages/AuthPages/Login";
import SignUpPage from "./pages/AuthPages/SignUp";
import InstallerRegisterPage from "./pages/AuthPages/InstallerRegister";
import DashboardScreen from "./pages/Dashboard";
import MyProjectsScreen from "./UserProjectPages/MyProjects";
import ViewProjectUserView from "./UserProjectPages/ViewProjectUser";
import ViewProposalUserView from "./UserProjectPages/ViewProposalUser";
import BidQuotePage from "./Bidding/BidQuotePage";
import BillingScreen from "./pages/Billing";
import ViewProjectGuideView from "./ProjectGuideView/ViewStoreProject";
import PaymentOptionsScreen from "./UserProjectPages/PaymentOptions";
import PaymentPendingScreen from "./pages/PayPending";
import MyAccountScreen from "./pages/MyAccount";
import DashboardWelcomeScreen from "./pages/DashboardWelcome";
import InvoiceGenerator from "./InvoicePages/InvoiceGenerator";
import ProjectBidListScreen from "./UserProjectPages/ProjectBidList";
import ViewProposalConstructionView from "./UserProjectPages/ConstructionView";
import SiteMapper from "./comps/SiteMapper";
import ViewProposalEstimatorView from "./UserProjectPages/ViewProposalEstimate";
import ViewProposalAccountantView from "./UserProjectPages/ViewProjectAccountant";
import ViewEstimateNA from "./UserProjectPages/ViewOnlyEstimate";
import SitemapDesktop from "./pages/SitemapDesktop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Switch>
          {/* Default Route / Login Page */}
          <Route exact path="/" component={LoginPage} />

          {/* Billing Page, User can view all of their invoices across every project */}
          <Route path="/billing" component={BillingScreen} />

          {/* Signup Page */}
          <Route path="/signup" component={SignUpPage} />

          {/* Installer Register Page */}
          <Route path="/installer_register" component={InstallerRegisterPage} />

          {/* Dashboard */}
          <Route path="/home" component={DashboardScreen} />

          {/* Profile Page */}
          <Route path="/profile" component={ProfileScreen} />

          {/* New Project Form */}
          <Route path="/estimate/:Id/:storeId" component={EstimateScreen} />

          {/* List of Managers */}
          <Route path="/managerlist" component={ManagerListScreen} />

          {/* List of Roles */}
          <Route path="/rolecat/:Id" component={RoleCatScreen} />

          {/* List of Staff under a specific Role */}
          <Route path="/rolelist/:Id/:role" component={RoleListScreen} />

          {/* List of All Regions */}
          <Route path="/metrolist" component={MetroCatScreen} />

          {/* Store Page */}
          <Route path="/store/:Id" component={StoreScreen} />

          {/* Edit Metro Information Page */}
          <Route path="/MetroID/:Id" component={MetroIDScreen} />

          {/* View Product Categories of a Store */}
          <Route
            path="/productcategory/:Id"
            component={ProductCategoryScreen}
          />

          {/* View Products of a specific Category */}
          <Route
            path="/ProductList/:Id/:cateId/:productId"
            component={ProductListScreen}
          />

          {/* View Toolkits of a Sotre */}
          <Route path="/toolkit/:Id" component={ToolKitScreen} />

          {/* View Tools of a specific Toolkit */}
          <Route
            path="/ToolList/:Id/:kitId/:kitName"
            component={ToolListScreen}
          />

          {/* Edit Zone Information */}
          <Route path="/Zone/:Id/:zoneId" component={ZoneScreen} />

          {/* Staff View of Store Projects */}
          <Route path="/Cases/:Id/:storeId" component={CasesScreen} />

          {/* User View of their own Projects */}
          <Route
            path="/CasesPersonal/:Id/:storeId"
            component={MyProjectsScreen}
          />

          {/* Guide creating an Estimate for the first time */}
          <Route path="/quote/:Id/:storeId/:caseId" component={QuoteScreen} />

          {/* List of all Zones of a Region */}
          <Route path="/ZoneList/:Id/" component={ZoneListScreen} />

          {/* Select a Store from a store list */}
          <Route path="/listofstores/:Id" component={ViewListOfStoresScreen} />

          {/* Staff View of a Project */}
          <Route
            path="/viewcase/:Id/:storeId/:caseId"
            component={ViewProjectGuideView}
          />

          {/* User View of their Project */}
          <Route
            path="/viewcaseuser/:Id/:storeId/:caseId"
            component={ViewProjectUserView}
          />

          {/* User can update their project page */}
          <Route
            path="/updatecase/:Id/:storeId/:caseId"
            component={UpdateCasePage}
          />

          {/* List of Stores (Admin View) */}
          <Route path="/storeadminlist/:Id" component={StoreAdminListScreen} />

          {/* Edit Store (Admin View) */}
          <Route path="/storeadmin/:Id/:storeId" component={StoreAdminScreen} />

          {/* User activates their account */}
          <Route path="/activate/:activateCode" component={ActivateScreen} />

          {/* Guide View of Editing an Estimate */}
          <Route
            path="/viewquote/:Id/:storeId/:caseId/:quoteId/:viewId"
            component={ViewQuotePage}
          />

          {/* Guide View of Editing an Estimate */}
          <Route
            path="/viewestimatestaff/:Id/:storeId/:caseId/:quoteId/:viewId"
            component={ViewProposalEstimatorView}
          />

<Route
            path="/viewestimateaccountant/:Id/:storeId/:caseId/:quoteId/:viewId"
            component={ViewProposalAccountantView}
          />


          {/* ViewProposalUser / User View of a Quote */}
          <Route
            path="/viewquoteuser/:Id/:storeId/:caseId/:quoteId/:numberofquotes/:lastrevision"
            component={ViewProposalUserView}
          />

          {/* ViewProposalUser NO AUTH / User View of a Quote */}
          <Route
            path="/viewestimate/:Id/:storeId/:caseId/:quoteId/:numberofquotes/:lastrevision/:viewonly"
            component={ViewEstimateNA}
          />

           {/* ViewProposalUser / User View of a Quote */}
           <Route
            path="/viewestimateconstruction/:Id/:storeId/:caseId/:quoteId/:numberofquotes/:lastrevision"
            component={ViewProposalConstructionView}
          />

            {/* Bid Estimate / Installers Bid on Projects */}
                    <Route
            path="/bidquote/:Id/:storeId/:caseId/:quoteId/:numberofquotes/:lastrevision"
            component={BidQuotePage}
          />

          {/* Users own Calendar Page */}
          <Route path="/calendar" component={MyCalendarScreen} />

          {/* Store Calendar Page */}
          <Route
            path="/storecalendar/:Id/:storeId"
            component={StoreCalendarScreen}
          />

          {/* User views available times that an installer has available */}
          <Route
            path="/installercalendar/:Id/:storeId/:eventId/:instUserId/:instName/:routeId/:year/:month/:day/:quoteId"
            component={InstallerCalendarScreen}
          />

          {/* Installers own Calendar Page */}
          <Route
            path="/installerpersonalcalendar"
            component={InstallerPersonalCalendar}
          />

          {/* Store Schedule page */}
          <Route path="/storeschedule" component={StoreScheduleScreen} />

          {/* List of Installers of a Store  */}
          <Route
            path="/storeinstallers/:Id/:storeId"
            component={StoreInstallersScreen}
          />

          {/* List of Workers of a Store */}
          <Route path="/storeworkers" component={StoreWorkersScreen} />

          {/* Installers Business Hours Setup */}
          <Route path="/installersetup" component={InstallerSetupScreen} />

          {/* Worker views their own schedule */}
          <Route
            path="/workerschedule/:storeId/:workerId/:workerName"
            component={WorkerScheduleScreen}
          />

          {/* Worker Clock In/Out Page */}
          <Route path="/clockinout" component={ClockInOutScreen} />

          {/* View the Schedule of a Worker */}
          <Route
            path="/clockschedule/:storeId/:userId/:roleNum/:workerName"
            component={ClockSchedule}
          />

          {/* Payment Options for User */}
          <Route
            path="/payment/:amount/:caseId/:quoteId/:invoiceid"
            component={PaymentOptionsScreen}
          />

          {/* Supplier accepts order Page */}
          <Route
            path="/order/accept/quote/:quoteid/item/:itemid"
            component={AcceptOrderScreen}
          />

          {/* Supplier rejects order Page */}
          <Route
            path="/order/reject/quote/:quoteid/item/:itemid"
            component={RejectOrderScreen}
          />

          {/* List Stores before viewing Installers */}
          <Route
            path="/storelistinstallers/:Id"
            component={StoreListInstallerScreen}
          />

          {/* List Stores before viewing Store Calendar */}
          <Route
            path="/storelistcalendars/:Id"
            component={StoreListCalendarScreen}
          />

          {/* View all installers available at the time selected after paying deposit */}
          <Route
            path="/installersavailable/:Id/:storeId/:storeMonth/:storeDay/:storeYear/:instId/:quoteId"
            component={StoreListInstallersAvailableScreen}
          />

          {/* View all installers available on the day from the Store Schedule */}
          <Route
            path="/storescheduleday/:Id/:storeId/:storeMonth/:storeDay/:storeYear"
            component={StoreScheduleDayScreen}
          />

          {/* View the schedules of all workers for a store */}
          <Route
            path="/clockschedulesummary/:Id"
            component={ClockScheduleSummary}
          />

          {/* View all stores available before Landscape form */}
          <Route
            path="/storelistestimate/:Id"
            component={StoreListEstimateScreen}
          />

          {/* Redirect User here after payment was successful */}
          <Route
            path="/paymentsuccess/:quoteId/:storeId/:token/:transactionid/:amount/:currency/:invoiceid"
            component={PaymentSuccessScreen}
          />

          {/* Redirect User here after payment was successful */}
          <Route
            path="/paymentsuccesstransit/:quoteId/:storeId/:token/:transactionid/:amount/:currency/:invoiceid"
            component={PaymentSuccessTransitScreen}
          />

          {/* Redirect User here after payment fails */}
          <Route
            path="/paymentfailed/:quoteId/:token"
            component={PaymentFailScreen}
          />

          {/* Redirect User here after payment method was selected */}
          <Route
            path="/paymentpending/:amount/:caseId/:quoteId"
            component={PaymentPendingScreen}
          />

          {/* User books appointment page */}
          <Route
            path="/quoteappointment/:metroId/:storeId/:quoteId"
            component={QuoteAppointmentScreen}
          />

          {/* Staff View Cases of a Specific User */}
          <Route
            path="/usercases/:Id/:storeId/:userId/:userName"
            component={UserCasesScreen}
          />

          {/* Mobile Only My Account Page */}
          <Route path="/myaccount" component={MyAccountScreen} />

          {/* First Time Login will redirect users here instead of Dashboard */}
          <Route path="/welcometoyardhack" component={DashboardWelcomeScreen} />

          {/* Invoice Generator Page */}
          <Route
            path="/invoicegenerator/:Id/:storeId/:caseId/:quoteId/:numberofquotes/:lastrevision"
            component={InvoiceGenerator}
          />

          {/* List of all projects up for bid */}
          <Route
            path={`/projectbidlist/:metroId/:storeId`}
            component={ProjectBidListScreen}
          />

          <Route
            path={'/sitemapper/:Id/:storeId/:caseId/:quoteId'}
            component={SiteMapper}
            />

          <Route
            path={'/sitemapperdesktop/:Id/:storeId/:caseId/:quoteId'}
            component={SitemapDesktop}
            />

            
        </Switch>
      </div>
    </Router>
  );
}

export default App;
