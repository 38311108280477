import styled from "styled-components";
import { ColorPalette } from "../../StylesheetComps/Colors";

const nullProp = null;

export const RegularResponsiveButton = styled.div`
  min-width: ${(props) => (props.minwidth ? props.minwidth : "100px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "30px")};
  text-align: center;
  font-family: LeagueSpartan-Bold;
  color: ${(props) => (props.color ? props.color : "white")};
  background-color: ${(props) =>
    props.bgcolor ? props.bgcolor : ColorPalette.PrimaryButtonBlue};
  vertical-align: middle;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "12px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 2px")};
  margin-top: ${(props) => (props.margintop ? props.margintop : "")};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "")};
  margin-right: ${(props) => (props.marginright ? props.marginright : "")};
  margin-left: ${(props) => (props.marginleft ? props.marginleft : "")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "2.5")};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  pointer-events: ${(props) => (props.pointerevent ? props.pointerevent : "")};
  border-radius: 10px;
  transition: 0.3s;
  padding: 4px 10px;
  display: ${(props) => (props.display ? props.display : "block")};
  &:hover {
    opacity: ${(props) => (props.hoveropacity ? props.hoveropacity : "70%")};
  }
  @media (max-width: 1006px) {
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "")};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
  }
`;

export const FixedIconToTop = styled.img`
  position: fixed;
  right: 0.5%;
  bottom: 0.25%;
  width: 50px;
  height: 50px;
  padding: 5px;
  transition: width 0.5s, height 0.5s, opacity 0.5s;
  cursor: pointer;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  &:hover {
    opacity: 70%;
  }
  @media (max-width: 1006px) {
    display: none;
  }
`;

export const Select16px = styled.select`
  font-family: Avenir;
  font-size: min(0.75vw, 14px);
  border: 1px solid #d3d3d3;
  height: 40%;
  width: 12vw;
  margin-left: 5px;
  margin-right: 5px;
`;

export const SelectionInputRegular = styled.select`
  width: 200px;
  font-family: Avenir;
  font-size: 18px;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
`;

export const Option16px = styled.option`
  font-family: Avenir;
  font-size: min(0.75vw, 14px);
`;

export default nullProp;
