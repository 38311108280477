import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { GetQuoteById } from "../../API_Functions/CaseQuote_Functions";
import Menu from "../../comps/MenuComps/Menu";
import ActionMenuQuoteView from "../../comps/MenuComps/TopMenuQuoteView";
import AccessAreaQuote from "../../comps/Quote Form/AccessArea";
import MaterialSelection from "../../comps/Quote Form/MaterialSelection";
import ScrollToTop from "../../comps/ScrollToTop";
import ViewQuote from "../../pages/ViewQuote";
import {
  FlexColumnCont80vwAutoHeight,
  FlexRowContFullWidth,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";

const ViewQuotePage = ({ numberOfQuotes }) => {
  const [returned, setReturned] = useState(false);
  const [returnedAccess, setReturnedAccess] = useState(false);
  const [returnCateType, setReturnCateType] = useState(0);

  const [accessAreaOpen, setAccessAreaOpen] = useState(true);
  const [materialSelectionOpen, setMaterialSelectionOpen] = useState(false);
  const [quoteOpen, setQuoteOpen] = useState(true);

  const [quote, setQuote] = useState();

  const [quoteTitle, setQuoteTitle] = useState("");
  const [returnPage, setReturnPage] = useState("");
  const [highestRevisionNumber, setHighestRevisionNumber] = useState(0);
  const [saveQuoteState, setSaveQuoteState] = useState(false);

  const params = useParams();
  const history = useHistory();
  // Define a function to get the quote information for the header
  const GetQuoteInformationForHeader = async (quoteid) => {
    try {
      // Get the quote by its ID
      const data = await GetQuoteById(quoteid);
      setQuote(data);
      // If the quote is found, set the quote title
      if (data) {
        setQuoteTitle(data.quote.Tittle);
      }
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const DisplayAccessPageChange = async (returnpage) => {
    setAccessAreaOpen(true);
    switch (returnpage) {
      case "quote":
        setQuoteOpen(true);
        break;
      case "itemselection":
        setMaterialSelectionOpen(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    GetQuoteInformationForHeader(params.quoteId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexRowContFullWidth alignitems="baseline">
      <ScrollToTop />
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>

      <FlexColumnCont80vwAutoHeight minheight="auto" height="fit-content">
        <ActionMenuQuoteView
          pageProp={`Edit Estimate #${params.quoteId} (${
            quoteTitle === undefined || quoteTitle === null
              ? "Untitled Estimate"
              : quoteTitle
          })`}
          accessopen={accessAreaOpen}
          materialopen={materialSelectionOpen}
          quoteopen={quoteOpen}
          firstlink={() => {
            setReturnPage("quote");
            setAccessAreaOpen(true);
            setMaterialSelectionOpen(false);
            setQuoteOpen(true);
            window.scrollTo(0, 0);
          }}
          secondlink={() => {
            setReturnPage("access");
            setReturnedAccess(true);
            setMaterialSelectionOpen(false);
            setAccessAreaOpen(false);
            setQuoteOpen(false);
            window.scroll(0, 0);
          }}
          thirdlink={() => {
            setReturnPage("itemselection");
            setReturnCateType(0);
            setReturned(true);
            setAccessAreaOpen(true);
            setQuoteOpen(false);
            setMaterialSelectionOpen(true);
            window.scrollTo(0, 0);
          }}
          fourthlink={() => {
            setReturnPage("quote");
            setAccessAreaOpen(true);
            setMaterialSelectionOpen(false);
            setQuoteOpen(true);
            window.scrollTo(0, document.body.scrollHeight);
          }}
          // ReturnToProject={() => {
          //   history.push(
          //     "/viewcase/" +
          //       params.Id +
          //       "/" +
          //       params.storeId +
          //       "/" +
          //       params.caseId
          //   );
          // }}
          SwitchToUserView={() => {
            setSaveQuoteState(true);
          }}
        ></ActionMenuQuoteView>

        <RightViewFullWidth height="auto">
          <AccessAreaQuote
            desiredpage={returnPage}
            quoteid={params.quoteId}
            ReturnToPage={(returnpage) => {
              DisplayAccessPageChange(returnpage);
            }}
            returned={returnedAccess}
            open={accessAreaOpen}
            onNext={() => {
              setAccessAreaOpen(true);
              setMaterialSelectionOpen(true);
              window.scrollTo(0, 0);
            }}
          ></AccessAreaQuote>

          <MaterialSelection
            quote={quote}
            quoteId={params.quoteId}
            returned={returned}
            onBackToQuote={() => {
              setMaterialSelectionOpen(false);
              setQuoteOpen(true);
              window.scrollTo(0, 0);
            }}
            open={materialSelectionOpen}
            returnCateType={returnCateType}
            onBack={() => {
              setAccessAreaOpen(false);
              setMaterialSelectionOpen(false);
              window.scrollTo(0, 0);
            }}
          ></MaterialSelection>

          <ViewQuote
            saveQuoteState={saveQuoteState}
            onToolAdded={() => {}}
            open={quoteOpen}
            onItemChange={() => {}}
            ReturnToMaterial={() => {
              setReturnCateType(0);
              setReturned(true);
              setMaterialSelectionOpen(true);
              setQuoteOpen(false);
              window.scrollTo(0, 0);
            }}
            ReturnToLabor={() => {
              setReturnCateType(2);
              setReturned(true);
              setMaterialSelectionOpen(true);
              setQuoteOpen(false);
              window.scrollTo(0, 0);
            }}
            ReturnToRemoval={() => {
              setReturnCateType(4);
              setReturned(true);
              setMaterialSelectionOpen(true);
              setQuoteOpen(false);
              window.scrollTo(0, 0);
            }}
            ReturnToAccess={() => {
              setReturnedAccess(true);
              setAccessAreaOpen(false);
              setQuoteOpen(false);
              window.scroll(0, 0);
            }}
          ></ViewQuote>
        </RightViewFullWidth>
      </FlexColumnCont80vwAutoHeight>
    </FlexRowContFullWidth>
  );
};
ViewQuotePage.defaultProps = {
  numberOfQuotes: 0,
};

export default ViewQuotePage;
