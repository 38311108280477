import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import StaffCaseCardMobile from "../../MobileComponents/StaffProjectCard";
import { GreenButtonWidth12VWHeight40, TextInput300x50 } from "../../StylesheetComps/AuthSheet";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexColumn81vwLightGrey,
  FlexColumnCont80vwAutoHeight,
  FlexCont76PercentMobile,
  FlexContMobileTopVH,
  FlexContzIndex1,
  FlexDiv,
  FlexRowCont70PercentFlexStart,
  FlexRowContFlexStartAutoHeight,
  LeftCont
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import {
  Div150pxMobile250px,
  Div150pxMobileDisplayNone,
  Div80px,
  StyledDiv,
  StyledFlexDiv
} from "../../StylesheetComps/Div";
import { HundredPercentFlexDiv } from "../../StylesheetComps/Quote";
import {
  LargeHeaderBold,
  LeagueFontSize100Percent,
  StyledHRGreenBreakDashed,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import ActionMenu from "../../comps/ActionMenu";
import CaseCard from "../../comps/CaseCard";
import Menu from "../../comps/MenuComps/Menu";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import styled from 'styled-components'
import QuickProjectForm from "../../comps/Windows/AddProjectOBO";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const InputContainer = styled.div`
`;

const InputField = styled.input`
  padding: 12px 16px;
  border: 1px solid #d1d1d6; /* Neutral light border */
  border-radius: 10px;
  width: 85%;
  margin: 10px;
  font-size: 16px;
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  background-color: #f9f9f9;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &:focus {
    border-color: #007aff; /* Signature blue focus */
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.3); /* Subtle glow */
    outline: none;
    background-color: #ffffff; /* Emphasize focus with brighter background */
  }

  &:hover {
    border-color: #c7c7cc; /* Slightly darker on hover */
  }

  &::placeholder {
    color: #c7c7cc; /* Neutral placeholder */
    font-weight: 300;
  }
`;


const Dropdown = styled.select`
  appearance: none;
  background-color: #ffffff; /* Clean, minimal background */
  border: 1px solid #d1d1d6; /* Light border for definition */
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 16px;
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  color: #333;
  box-shadow: none; /* Minimized shadow for simplicity */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  margin-right: 13px;
  &:hover {
    border-color: #c7c7cc; /* Slightly darker border on hover */
  }

  &:focus {
    outline: none;
    border-color: #007aff; /* Apple's signature blue for focus */
    box-shadow: 0 0 8px rgba(0, 122, 255, 0.3); /* Subtle glow */
    background-color: #f9f9f9; /* Slightly differentiated focus background */
  }

  &::placeholder {
    color: #c7c7cc; /* Light neutral placeholder color */
  }

  /* Custom arrow styling for the dropdown */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 6' fill='%23666'%3E%3Cpath d='M5 6L0 0h10L5 6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 10px 6px;
`;


const DropdownOption = styled.option`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const DropdownLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
  color: #333;
`;


const CasesScreen = () => {
  //=============================================================================
  // Staff Cases Page - Staff Redirected here from "Store Cases" in Menu
  //=============================================================================

  //=============================================================================
  // Displays all cases of a store that staff can interact with
  //=============================================================================

  const params = useParams();
  const history = useHistory();
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState(0);
  const [storeCases, setStoreCases] = useState([]);
  const [roleNum, setRoleNum] = useState(1000);
  const [deleteCase, setDeleteCase] = useState(false);

  const bottomBorderConst = "2px solid " + ColorPalette.PrimaryButtonBlue;



  const [assignedBorder, setAssignedBorder] = useState("");
  const [allBorder, setAllBorder] = useState(bottomBorderConst);
  const [newBorder, setNewBorder] = useState("");
  const [acceptedBorder, setAcceptedBorder] = useState("");
  const [processedBorder, setProcessedBorder] = useState("");
  const [paypendingBorder, setPayPendingBorder] = useState("");
  const [inprogressBorder, setInProgressBorder] = useState("");
  const [cancelRequestedBorder, setCancelRequestedBorder] = useState("");
  const [cancelledBorder, setCancelledBorder] = useState("");
  const [closedBorder, setClosedBorder] = useState("");
  const [displayQuickProject, setDisplayQuickProject] = useState("none");
  const [selectedBorder, setSelectedBorder] = useState('');

  const handleSortChange = (e) => {
    const selectedOption = caseOptions.find(option => option.value === e.target.value);
    if (selectedOption) {
      if (selectedOption.value === "mine" || selectedOption.value === "all") {
        selectedOption.sortFunction();
      } else {
        SortCaseAccordingToStatus(storeCases, selectedOption.caseStatus);
      }
      selectedOption.setBorder(bottomBorderConst);
    }
  };


  const GetCases = async (storeId, userId) => {
    if (storeId > 0) {
      try {
        // Get the cases for the given store using Axios
        const response = await axios.get(
          `${window.$DBURL}case/store/${storeId}`
        );

        // If the request was successful and the data is not null
        if (response.status === 200 && response.data) {
          // Set the store cases and filter the cases by user
          setStoreCases(response.data.Cases);
          var arr = response.data.Cases;
          setCases(
            response.data.Cases
          );
          // Set the role number using the user info stored in session storage
          const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
          setRoleNum(userInfo.Role);
        }
      } catch (error) {
        // Handle any errors that may have occurred
        console.error(error);
      }
    }
  };

  const HandleCaseDel = async (caseId) => {
    try {
      // Delete the case using the provided caseId
      const response = await axios.delete(`${window.$DBURL}case/${caseId}`);

      // If the case was successfully deleted, get the updated cases and sort them
      if (response.status === 200) {
        const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        GetCases(params.storeId, userInfo.Id);
        SortMyCases(userInfo.Id);
      }
    } catch (error) {
      // Handle any errors that may have occurred
      console.error(error);
    }
  };

  const FilterCase = (text) => {
    setCases(
      storeCases.filter((o) => {
        return (
          (o.Name && o.Name.toLowerCase().includes(text.toLowerCase())) ||
          (o.CaseNumber && o.CaseNumber.includes(text)) ||
          (o.Phone && o.Phone.includes(text)) ||
          (o.Street && o.Street.toLowerCase().includes(text.toLowerCase()))
        );
      })
    );
  };


  const SortAll = () => {
    ResetBorderUI();
    setCases(
      storeCases.filter((o) => {
        return (
          o.CaseStatus === CaseStatus.Drafted ||
          CaseStatus.Submitted ||
          CaseStatus.Accepted ||
          CaseStatus.Processed ||
          CaseStatus.Closed ||
          CaseStatus.CancelRequested
        );
      })
    );
  };

  const SortCaseAccordingToStatus = async (cases, status) => {
    ResetBorderUI();
    if (cases !== null) {
      setCases(
        cases.filter((o) => {
          return o.CaseStatus === status;
        })
      );
    }
  };

  function SortMyCases(userId) {
    ResetBorderUI();
    setCases(
      storeCases.filter((o) => {
        return o.Estimator_Id === userId;
      })
    );
  }

  const ResetBorderUI = async () => {
    setAssignedBorder("");
    setAllBorder("");
    setNewBorder("");
    setAcceptedBorder("");
    setProcessedBorder("");
    setPayPendingBorder("");
    setInProgressBorder("");
    setCancelRequestedBorder("");
    setCancelledBorder("");
    setClosedBorder("");
  };

  useEffect(() => {
    SetHeaderAuthorization();
    GetCases(params.storeId, JSON.parse(sessionStorage.getItem("userInfo")).Id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const caseOptions = [
    { label: "Assigned To Me", value: "mine", sortFunction: () => SortMyCases(JSON.parse(sessionStorage.getItem("userInfo")).Id), setBorder: setAssignedBorder },
    { label: "All", value: "all", sortFunction: SortAll, setBorder: setAllBorder },
    { label: "Submitted", value: "submitted", caseStatus: CaseStatus.Submitted, setBorder: setNewBorder },
    { label: "Assigned", value: "assigned", caseStatus: CaseStatus.Accepted, setBorder: setAcceptedBorder },
    { label: "Estimated", value: "estimated", caseStatus: CaseStatus.Processed, setBorder: setProcessedBorder },
    { label: "Accepted Project", value: "payPending", caseStatus: CaseStatus.PayPending, setBorder: setPayPendingBorder },
    { label: "In-Progress", value: "inProgress", caseStatus: CaseStatus.Construction, setBorder: setInProgressBorder },
    { label: "Cancel Requested", value: "cancelRequested", caseStatus: CaseStatus.CancelRequested, setBorder: setCancelRequestedBorder },
    { label: "Cancelled", value: "cancelled", caseStatus: CaseStatus.Canceled, setBorder: setCancelledBorder },
    { label: "Closed", value: "closed", caseStatus: CaseStatus.Closed, setBorder: setClosedBorder },
  ];

  if(window.$ISMOBILE === true) {
    return (
      <FlexContzIndex1>
        {/* <LeftCont>
          <Menu> </Menu>
        </LeftCont> */}

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu mobilePageProp="Estimate Dashboard"></ActionMenu>

          <div style={{display:displayQuickProject}}>
          <QuickProjectForm onClose={()=>{setDisplayQuickProject('none')}}></QuickProjectForm>
          </div>

          <InputContainer>
          <InputField
                placeholder="Search for Project (Address, Name, Phone)"
                font_size="12px"
                onChange={(e) => {
                  FilterCase(e.target.value);
                }}
              >
          </InputField>
          <FlexDiv>
          <RegularResponsiveButton
          marginright='10px'
          marginleft='10px'
          bgcolor={ColorPalette.PrimaryButtonBlue}
          onClick={() => {
            setDisplayQuickProject('flex')
          }}
        >
        Create Project
        </RegularResponsiveButton>
      <Dropdown id="case-select" onChange={handleSortChange}>
        <DropdownOption>Filter Project By Status</DropdownOption>
        {caseOptions.map(option => (
          <DropdownOption key={option.value} value={option.value}>
            {option.label}
          </DropdownOption>
        ))}
      </Dropdown>
          </FlexDiv>
        
          </InputContainer>
  
          {cases.map((o, i) => (
                <StaffCaseCardMobile
                  onClick={()=>{history.push(`/viewcase/${params.Id}/${params.storeId}/${o.Id}`)}}
                  key={i}
                  homeowner={o.Name}
                  address={o.Street}
                  status={o.CaseStatus}
                  phone={o.Phone}
                ></StaffCaseCardMobile>
            ))}
          <br></br>
          <br></br>
          <br></br>
        </FlexColumnCont80vwAutoHeight>
      </FlexContzIndex1>
    );
  } else{
  if (
    roleNum === UserRole.Admin ||
    roleNum === UserRole.Manager ||
    roleNum === UserRole.Estimator ||
    roleNum === UserRole.Accountant
  ) {
    return (
      <FlexContMobileTopVH>
        <LeftCont>
          <Menu></Menu>
        </LeftCont>

        <FlexColumn81vwLightGrey>
          {/* <ActionMenu /> */}
          <RightViewFullWidth>
            <FlexRowContFlexStartAutoHeight height='10vh'>
              <TextInput300x50
                placeholder="Search by Name / Phone / Case Number"
                font_size="12px"
                inputheight="10%"
                inputwidth="43.5%"
                inputmargintop="10px"
                inputmarginleft="10px"
                margin="10px"
                onChange={(e) => {
                  FilterCase(e.target.value);
                }}
              ></TextInput300x50>
           <Wrapper>
      <DropdownLabel htmlFor="case-select">Case Status:</DropdownLabel>
      <Dropdown id="case-select" onChange={handleSortChange}>
        {caseOptions.map(option => (
          <DropdownOption key={option.value} value={option.value}>
            {option.label}
          </DropdownOption>
        ))}
      </Dropdown>
    </Wrapper>
            </FlexRowContFlexStartAutoHeight>

            {/* <FlexCont76PercentMobile>
    <StyledFlexDiv basis="250px">
        <LeagueFontSize100Percent>Case Number</LeagueFontSize100Percent>
    </StyledFlexDiv>
    <StyledFlexDiv basis="210px">
        <LeagueFontSize100Percent>Homeowner</LeagueFontSize100Percent>
    </StyledFlexDiv>
    <StyledFlexDiv basis="290px" mobileDisplay="none">
        <LeagueFontSize100Percent>Submitted Time</LeagueFontSize100Percent>
    </StyledFlexDiv>
    <StyledFlexDiv basis="220px">
        <LeagueFontSize100Percent>Estimator</LeagueFontSize100Percent>
    </StyledFlexDiv>
    <StyledFlexDiv basis="220px">
        <LeagueFontSize100Percent>Status</LeagueFontSize100Percent>
    </StyledFlexDiv>
</FlexCont76PercentMobile> */}



            <DeleteConfirmWindow
              deleteOpen={deleteCase}
              onDeleteFunction={() => {
                HandleCaseDel(caseId);
              }}
              closeDel={() => {
                setDeleteCase(false);
              }}
            ></DeleteConfirmWindow>
            {cases.map((o, i) => (
              <div key={i}>
                <CaseCard
                isOdd={i % 2 !== 0}
                  index={cases.length - i}
                  key={i}
                  Address = {o.Street}
                  Status={o.CaseStatus}
                  EstimatorName={o.Estimator}
                  CaseId={o.Id}
                  ClientName={o.Name}
                  CaseNumber={"#" + o.CaseNumber}
                  DateText={o.CreateTime}

                  onDel={() => {
                    setCaseId(o.Id);
                    setDeleteCase(true);
                  }}
                ></CaseCard>
              </div>
            ))}
          </RightViewFullWidth>
        </FlexColumn81vwLightGrey>
      </FlexContMobileTopVH>
    );
  } else if (roleNum === UserRole.Estimator) {
    return (
      <FlexContMobileTopVH>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu />
          <RightViewFullWidth>
            <HundredPercentFlexDiv>
              <FlexRowCont70PercentFlexStart>
                <LargeHeaderBold
                  borderbottom={assignedBorder}
                  head_text="My Assignments"
                  font_size="16px"
                  onClick={() => {
                    SortMyCases(
                      JSON.parse(sessionStorage.getItem("userInfo")).Id
                    );
                    setAssignedBorder(bottomBorderConst);
                  }}
                >
                  Assigned
                </LargeHeaderBold>
                <LargeHeaderBold
                  borderbottom={newBorder}
                  head_text="New"
                  font_size="16px"
                  onClick={() => {
                    SortCaseAccordingToStatus(storeCases, CaseStatus.Submitted);
                    setNewBorder(bottomBorderConst);
                  }}
                >
                  New
                </LargeHeaderBold>
                <LargeHeaderBold
                  borderbottom={cancelRequestedBorder}
                  head_text="Cancel Requested"
                  font_size="16px"
                  onClick={() => {
                    SortCaseAccordingToStatus(
                      storeCases,
                      CaseStatus.CancelRequested
                    );
                    setCancelRequestedBorder(bottomBorderConst);
                  }}
                >
                  Cancel Requested
                </LargeHeaderBold>
                <LargeHeaderBold
                  borderbottom={cancelledBorder}
                  head_text="Cancelled"
                  font_size="16px"
                  onClick={() => {
                    SortCaseAccordingToStatus(storeCases, CaseStatus.Canceled);
                    setCancelledBorder(bottomBorderConst);
                  }}
                >
                  Cancelled
                </LargeHeaderBold>
                <LargeHeaderBold
                  borderbottom={closedBorder}
                  head_text="Closed"
                  font_size="16px"
                  onClick={() => {
                    SortCaseAccordingToStatus(storeCases, CaseStatus.Closed);
                    setClosedBorder(bottomBorderConst);
                  }}
                >
                  Closed
                </LargeHeaderBold>
              </FlexRowCont70PercentFlexStart>
            </HundredPercentFlexDiv>

            <FlexCont76PercentMobile>
    <StyledDiv width="80px">
        <LeagueFontSize100Percent>Index</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="150px" mobileWidth="250px">
        <LeagueFontSize100Percent>Case Number</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="150px" mobileWidth="250px">
        <LeagueFontSize100Percent>Homeowner</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="200px" mobileDisplay="none">
        <LeagueFontSize100Percent>Submitted Time</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="150px" mobileWidth="250px">
        <LeagueFontSize100Percent>Estimator</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="150px" mobileWidth="250px">
        <LeagueFontSize100Percent>Status</LeagueFontSize100Percent>
    </StyledDiv>
    <StyledDiv width="150px" mobileWidth="250px">
        <LeagueFontSize100Percent>Delete</LeagueFontSize100Percent>
    </StyledDiv>
</FlexCont76PercentMobile>


            {cases.map((o, i) => (
              <CaseCard
                Status={o.CaseStatus}
                CaseId={o.Id}
                EstimatorName={o.Estimator}
                ClientName={o.Name}
                key={i}
                CaseNumber={"#" + o.CaseNumber}
                DateText={o.CreateTime}
                onDel={() => {
                  HandleCaseDel(o.Id);
                }}
              ></CaseCard>
            ))}
          </RightViewFullWidth>
        </FlexColumn81vwLightGrey>
      </FlexContMobileTopVH>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};
}

CasesScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default CasesScreen;
