/* eslint-disable */

// React Imports -------------------------------------
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCaseById,
  QuoteStatus,
  SetCaseTo_Submit,
} from "../../API_Functions/CaseQuote_Functions";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
// Imported Components -------------------------------
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import ProposalCardUser from "../../comps/ProposalCardUser";
import DeleteProjectWindow from "../../comps/Windows/DeleteProject";
import RequestProjectCancelWindow from "../../comps/Windows/RequestProjectCancel";
import {
  Div100vw20vh,
  WhiteTopRoundedDivAutoHeight,
} from "../../MobileComponents/Divs";
import { TopImage40vh } from "../../MobileComponents/Images";
import { MobileBodyTextFullWidth } from "../../MobileComponents/Text";
import { LargeResponsiveButton } from "../../StylesheetComps/AuthSheet";
import { CaseStatus } from "../../StylesheetComps/CaseStatus";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
// Imported Styled Components ------------------------
import {
  FlexColumn81vwLightGrey,
  FlexColumnContCenterAlign,
  FlexColumnFullWidth,
  FlexColumnPropWidth,
  FlexCont90PercentAutoHeight,
  FlexContFullWidthJustifyCenter,
  FlexRowCont300x200,
  FlexRowContFlexStartMobileColumn,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { GridContainer2Wide } from "../../StylesheetComps/Div";
import {
  Icon20x20,
  Icon30x30,
  Icon45x45,
} from "../../StylesheetComps/Imgs/Icons";
import { Image80x100px } from "../../StylesheetComps/Imgs/Images";
import {
  FlexContBottomGrey,
  FlexDivFlexStartCenter,
  FlexInfoCont,
  Image300x200GreenBorder,
  LandscapePara,
  PhotoContExtra,
  SmallHeaderLeagueSpartan,
  WideFlexInfoCont,
} from "../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../StylesheetComps/Menu";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import {
  LeagueSpartanLarge,
  MediumHeader100PercentWidth,
  MediumHeader100PercentWidthWhiteText,
  RegularText,
  RegularTextSmallWithWidth,
  SmallGreenLeagueText,
  SmallText,
  SmallTextPadding,
  SmallTitleBoldWithWidth,
} from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";

const ViewProjectUserView = () => {
  const history = useHistory();
  const params = useParams();

  const [projectInfoDisplay, setProjectInfoDisplay] = useState("none");

  const [draftCaseVisible, setDraftCaseVisible] = useState("none");
  const [missingPhotosVisible] = useState("none");
  const [introVisible, setIntroVisible] = useState("none");
  const [proposalVisible, setProposalVisible] = useState("none");
  const [invoiceVisible, setInvoiceVisible] = useState("none");

  const [projectName, setProjectName] = useState("");
  const [projectAreaString, setProjectAreaString] = useState("");
  const [projectScopeString, setProjectScopeString] = useState("");
  const [ProjectTypeString, setProjectTypeString] = useState("");
  const [ProjectStyleString, setProjectStyleString] = useState("");
  const [ProjectBudgetString, setProjectBudgetString] = useState("");
  const [projectDescriptionString, setProjectDescriptionString] = useState("");

  const [projectProposalsDisplay, setProjectProposalsDisplay] =
    useState("none");
  const [projectInvoicesDisplay, setProjectInvoicesDisplay] = useState("none");

  const [projectInfoArrow, setProjectInfoArrow] = useState("/downarrow.svg");
  const [projectProposalArrow, setProjectProposalArrow] =
    useState("/downarrow.svg");
  const [projectInvoiceArrow, setProjectInvoiceArrow] =
    useState("/downarrow.svg");

  const [deleteProjectDisplay, setDeleteProjectDisplay] = useState("none");
  const [deleteProjectWindowDisplay, setDeleteProjectWindowDisplay] =
    useState(false);
  const [deleteProjectBorder, setDeleteProjectDisplayBorder] = useState(
    "2px solid " + ColorPalette.PrimaryRed
  );

  const [requestCancelDisplay, setRequestCancelDisplay] = useState("none");
  const [requestCancelWindowDisplay, setRequestCancelWindowDisplay] =
    useState(false);
  const [requestCancelProjectBorder] = useState(
    "2px solid " + ColorPalette.PrimaryRed
  );

  const [backProposalArrowDisplay, setBackProposalArrowDisplay] =
    useState("none");
  const [backProjectArrowDisplay, setBackProjectArrowDisplay] =
    useState("none");

  const [projectObj, setProjectObj] = useState({});
  const [projectInvoices, setProjectInvoices] = useState([]);
  const [projectProposals, setProjectProposals] = useState([]);

  const [projectPhotos, setProjectPhotos] = useState([]);

  const [submitButtonDisplay, setSubmitButtonDisplay] = useState("block");

  const [PDFMap, setPDFMap] = useState([]);
  const [roleNum, setRoleNum] = useState(1000);

  const [expiredProjectProposalArrow, setExpiredProjectProposalArrow] =
    useState("/downarrow.svg");
  const [expiredProjectProposalsDisplay, setExpiredProjectProposalsDisplay] =
    useState("none");
  const [expiredProposalVisible, setExpiredProposalVisible] = useState("block");

  const DisplayProjectInfo = async (arrow) => {
    if (arrow === "/uparrow.svg") {
      setProjectInfoDisplay("none");
      setProjectInfoArrow("/downarrow.svg");
    } else {
      setProjectInfoDisplay("flex");
      setProjectInfoArrow("/uparrow.svg");
    }
  };

  const DisplayProjectProposals = async (arrow, type) => {
    // For non-expired proposals
    if (type === "nonExpired") {
      if (arrow === "/uparrow.svg") {
        setProjectProposalsDisplay("none");
        setProjectProposalArrow("/downarrow.svg");
      } else {
        setProjectProposalsDisplay("flex");
        setProjectProposalArrow("/uparrow.svg");
      }
    }
    // For expired proposals
    else if (type === "expired") {
      if (arrow === "/uparrow.svg") {
        setExpiredProjectProposalsDisplay("none");
        setExpiredProjectProposalArrow("/downarrow.svg");
      } else {
        setExpiredProjectProposalsDisplay("flex");
        setExpiredProjectProposalArrow("/uparrow.svg");
      }
    }
  };

  const DisplayProjectInvoices = async (arrow) => {
    if (arrow === "/uparrow.svg") {
      setProjectInvoicesDisplay("none");
      setProjectInvoiceArrow("/downarrow.svg");
    } else {
      setProjectInvoicesDisplay("flex");
      setProjectInvoiceArrow("/uparrow.svg");
    }
  };

  const SetupProjectForDisplay = async (caseid) => {
    var data = await GetCaseById(caseid);

    if (data !== null) {
      var project = data.Case;
      if (project !== null) {
        setProjectObj(project);
        SetupProjectDescriptionStrings(project.Description);
        SetProposalVisibility(project);
        SetInvoiceVisibility(project);
        SetProjectControlButtons(project.CaseStatus, project.Photos);
        if (project.Invoices !== null) {
          setProjectInvoices(project.Invoices);
        }
        if (data.Case.Tittle !== null && data.Case.Tittle !== undefined) {
          setProjectName(project.Tittle);
        }
        if (project.Photos !== null) {
          setPDFMap(
            project.Photos.filter(
              (o) =>
                o.File?.slice(-4) === ".pdf" || o.File?.slice(-4) === ".PDF"
            )
          );
          setProjectPhotos(
            project.Photos.filter(
              (o) =>
                !o.File?.includes(".pdf") || !(o.File?.slice(-4) !== ".PDF")
            )
          );
        }
      }
    }
  };

  const SetupProjectDescriptionStrings = async (projectdesc) => {
    setProjectDescriptionString(
      projectdesc.substring(projectdesc.indexOf("Description:") + 12)
    );
    setProjectAreaString(
      projectdesc.substring(
        projectdesc.indexOf("Project Area") + 13,
        projectdesc.indexOf(",") - 1
      )
    );
    setProjectScopeString(
      projectdesc.substring(
        projectdesc.indexOf("Project Scope") + 14,
        projectdesc.indexOf(",", projectdesc.indexOf(",") + 1)
      )
    );
    setProjectTypeString(
      projectdesc.substring(
        projectdesc.indexOf("Project Type") + 13,
        projectdesc.indexOf(", Project Style") - 1
      )
    );
    setProjectStyleString(
      projectdesc.substring(
        projectdesc.indexOf("Project Style") + 14,
        projectdesc.indexOf(",Project Budget") - 1
      )
    );
    setProjectBudgetString(
      projectdesc.substring(
        projectdesc.indexOf("Project Budget:") + 15,
        projectdesc.indexOf(", Description")
      )
    );
  };

  const SetProjectControlButtons = async (case_status, photos) => {
    switch (case_status) {
      case CaseStatus.Drafted:
        setDraftCaseVisible("block");

        setDeleteProjectDisplay("flex");
        setRequestCancelDisplay("none");
        break;
      case CaseStatus.Submitted:
        setDraftCaseVisible("none");
        setRequestCancelDisplay("flex");
        setDeleteProjectDisplay("none");
        break;
      case CaseStatus.Accepted:
        setDraftCaseVisible("none");
        setRequestCancelDisplay("flex");
        setDeleteProjectDisplay("none");
        break;
      case CaseStatus.Processed:
        setDraftCaseVisible("none");
        setRequestCancelDisplay("flex");
        setDeleteProjectDisplay("none");
        break;
      case CaseStatus.Closed:
        setDraftCaseVisible("none");
        setRequestCancelDisplay("none");
        setDeleteProjectDisplay("none");
        break;
      case CaseStatus.CancelRequested:
        setDraftCaseVisible("none");
        setDeleteProjectDisplay("none");
        setRequestCancelDisplay("none");
        setDeleteProjectDisplayBorder("2px solid " + ColorPalette.PrimaryRed);
        break;
      case CaseStatus.Canceled:
        setDraftCaseVisible("none");
        setDeleteProjectDisplay("none");
        setRequestCancelDisplay("none");
        setDeleteProjectDisplayBorder("2px solid " + ColorPalette.PrimaryRed);
        break;

      default:
        break;
    }
  };
  const SetProposalVisibility = async (projectobj) => {
    if (projectobj.Quotes !== null) {
      AddProposalsToArray(projectobj.Quotes);
    }
    if (
      projectobj.Quotes.filter((o) => {
        return o.QuoteStatus !== QuoteStatus.Created;
      }).length > 0
    ) {
      setIntroVisible("block");
      setProposalVisible("block");
      setBackProposalArrowDisplay("block");
      setProjectProposalArrow("/uparrow.svg");
      setProjectProposalsDisplay("flex");
    } else {
      setProjectInfoDisplay("flex");
      setBackProjectArrowDisplay("block");
      setProjectInfoArrow("/downarrow.svg");
      setProposalVisible("none");
      setProjectProposalsDisplay("block");
    }
  };

  const AddProposalsToArray = async (quotes) => {
    var filteredquotes = quotes.filter((o) => {
      return o.QuoteStatus !== QuoteStatus.Created;
    });
    setProjectProposals(
      filteredquotes.sort((a, b) =>
        a.Proposal > b.Proposal
          ? 1
          : a.Proposal === b.Proposal
          ? a.Revision > b.Revision
            ? 1
            : -1
          : -1
      )
    );
  };

  const SetInvoiceVisibility = async (projectobj) => {
    if (projectobj.Invoices !== null) {
      if (projectobj.Invoices.length > 0) {
        setInvoiceVisible("block");
      } else {
        setInvoiceVisible("none");
      }
    }
  };

  const ChangeProjectStatusToSubmit = async (caseid) => {
    setSubmitButtonDisplay("none");
    setDraftCaseVisible("none");
    var data = await SetCaseTo_Submit(caseid);
    if (data !== null) {
      if (data.Case !== null) SetProjectControlButtons(data.Case.CaseStatus);
    }
  };

  const PushToPayment = async (amount, caseid, quoteid, invoiceid) => {
    history.push(
      "/payment/" + amount + "/" + caseid + "/" + quoteid + "/" + invoiceid
    );
  };

  useEffect(() => {
    SetHeaderAuthorization();
    var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
    setRoleNum(userinfo.Role);
    SetupProjectForDisplay(params.caseId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (window.$ISMOBILE === true) {
    if (roleNum !== UserRole.Loading) {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey>
            <ActionMenu
              mobilePageProp={projectName}
              pageProp={"Projects - " + projectName}
              projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
            ></ActionMenu>

            <ContentCont77vw display={draftCaseVisible}>
              <FlexCont90PercentAutoHeight>
                <SmallHeaderLeagueSpartan>
                  Ready to Submit?
                </SmallHeaderLeagueSpartan>
                <FlexColumnContCenterAlign>
                  <SmallText>
                    Review all of your information and confirm the details. Once
                    you press Submit Project, our team will be alerted and get
                    started right away. You will receive a confirmation email,
                    as well as a project proposal within 1-2 days*
                  </SmallText>
                </FlexColumnContCenterAlign>
                <br></br>
                <RegularResponsiveButton
                  display={submitButtonDisplay}
                  onClick={() => {
                    ChangeProjectStatusToSubmit(projectObj.Id);
                  }}
                >
                  Submit Project
                </RegularResponsiveButton>
              </FlexCont90PercentAutoHeight>
            </ContentCont77vw>

            <ContentCont77vw display={missingPhotosVisible}>
              <FlexCont90PercentAutoHeight>
                <SmallHeaderLeagueSpartan>
                  You are still missing some photos.
                </SmallHeaderLeagueSpartan>
                <FlexColumnContCenterAlign>
                  <SmallText>
                    We seem to be missing some required photos for your project.
                    You can add the Street View, Access Area, Panorama, and Area
                    of Work photos by pressing the "Edit" button below.
                  </SmallText>
                </FlexColumnContCenterAlign>
                <br></br>
                <RegularResponsiveButton
                  onClick={() => {
                    history.push(
                      "/updatecase/" +
                        params.Id +
                        "/" +
                        params.storeId +
                        "/" +
                        params.caseId
                    );
                  }}
                >
                  Edit Project
                </RegularResponsiveButton>
              </FlexCont90PercentAutoHeight>
            </ContentCont77vw>

            <Div100vw20vh>
              <TopImage40vh src="/homescreenimg.png"></TopImage40vh>
            </Div100vw20vh>

            {/* Project Information --------------------------------------------------------------- */}

            <WhiteTopRoundedDivAutoHeight>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProjectArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="1.25%">
                  Project Information
                </SmallHeaderLeagueSpartan>
                <RegularResponsiveButton
                  onClick={() => {
                    history.push(
                      "/updatecase/" +
                        params.Id +
                        "/" +
                        params.storeId +
                        "/" +
                        params.caseId
                    );
                  }}
                >
                  Edit
                </RegularResponsiveButton>
                <Icon30x30
                  src={projectInfoArrow}
                  onClick={() => {
                    DisplayProjectInfo(projectInfoArrow);
                  }}
                ></Icon30x30>
              </FlexColumnCont>

              <FlexContBottomGrey width="95%" height="1%"></FlexContBottomGrey>

              <FlexColumnFullWidth display={projectInfoDisplay}>
                <FlexColumnCont
                  flexdirection="row"
                  justifycontent="flex-start"
                  height="auto"
                  width="80%"
                  margin="0"
                  mobileflexdirection="column"
                  mobilewidth="90vw"
                  tabletflexdirection="column"
                  tabletalignitems="baseline"
                >
                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Case Number
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>
                      <RegularTextSmallWithWidth>
                        #{projectObj.CaseNumber}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Full Name:
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>
                      <RegularTextSmallWithWidth>
                        {projectObj.Name}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Phone Number:
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>
                      <RegularTextSmallWithWidth>
                        {projectObj.Phone}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Email Address:
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>
                      <RegularTextSmallWithWidth>
                        {projectObj.Email}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>
                  </FlexInfoCont>

                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Address:
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>
                      <RegularTextSmallWithWidth>
                        {projectObj.Street}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>City:</SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>

                      <RegularTextSmallWithWidth>
                        {projectObj.City}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallTitleBoldWithWidth>
                          Region:
                        </SmallTitleBoldWithWidth>
                      </WideFlexInfoCont>

                      <RegularTextSmallWithWidth>
                        {projectObj.Metro}
                      </RegularTextSmallWithWidth>
                    </WideFlexInfoCont>
                  </FlexInfoCont>
                </FlexColumnCont>

                <FlexContBottomGrey
                  width="95%"
                  height="1%"
                ></FlexContBottomGrey>

                <hr></hr>

                <FlexRowContFlexStartMobileColumn>
                  <WideFlexInfoCont>
                    <SmallTitleBoldWithWidth>
                      Project Area:
                    </SmallTitleBoldWithWidth>
                    <RegularTextSmallWithWidth>
                      {projectAreaString}
                    </RegularTextSmallWithWidth>
                  </WideFlexInfoCont>

                  <br></br>

                  <WideFlexInfoCont>
                    <SmallTitleBoldWithWidth>
                      Project Scope:
                    </SmallTitleBoldWithWidth>
                    <RegularTextSmallWithWidth>
                      {projectScopeString}
                    </RegularTextSmallWithWidth>
                  </WideFlexInfoCont>

                  <br></br>

                  <WideFlexInfoCont>
                    <SmallTitleBoldWithWidth>
                      Project Type:
                    </SmallTitleBoldWithWidth>
                    <RegularTextSmallWithWidth>
                      {ProjectTypeString}
                    </RegularTextSmallWithWidth>
                  </WideFlexInfoCont>

                  <br></br>

                  <WideFlexInfoCont>
                    <SmallTitleBoldWithWidth>
                      Project Style:
                    </SmallTitleBoldWithWidth>
                    <RegularTextSmallWithWidth>
                      {ProjectStyleString === "N/"
                        ? "Not Selected"
                        : ProjectStyleString}
                    </RegularTextSmallWithWidth>
                  </WideFlexInfoCont>

                  <br></br>

                  <WideFlexInfoCont>
                    <SmallTitleBoldWithWidth>
                      Project Budget:
                    </SmallTitleBoldWithWidth>
                    <RegularTextSmallWithWidth>
                      {ProjectBudgetString}
                    </RegularTextSmallWithWidth>
                  </WideFlexInfoCont>
                </FlexRowContFlexStartMobileColumn>

                <FlexContBottomGrey
                  width="95%"
                  height="1%"
                ></FlexContBottomGrey>

                <FlexColumnCont
                  alignitems="left"
                  height="auto"
                  margintop="2%"
                  margin="0"
                  width="100%"
                >
                  <SmallHeader paddingbottom="1.5%">
                    Project Description:
                  </SmallHeader>
                  <LandscapePara
                    readonly
                    disabled
                    defaultValue={projectDescriptionString}
                  ></LandscapePara>
                </FlexColumnCont>

                <br></br>

                <SmallHeaderLeagueSpartan>
                  Uploaded Photos
                </SmallHeaderLeagueSpartan>

                <FlexContFullWidthJustifyCenter>
                  <GridContainer2Wide>
                    {projectPhotos.map((o, i) => (
                      <FlexDivFlexStartCenter key={i}>
                        <FlexRowCont300x200>
                          <Image300x200GreenBorder
                            src={window.$IMGURL + o.File}
                            alt={"File:" + o.File}
                          ></Image300x200GreenBorder>
                        </FlexRowCont300x200>
                        <SmallTextPadding>{o.Caption}</SmallTextPadding>
                      </FlexDivFlexStartCenter>
                    ))}

                    {PDFMap.map((o, i) => (
                      <FlexDivFlexStartCenter key={i}>
                        <FlexRowCont300x200>
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target="_blank"
                          >
                            <PhotoContExtra key={i}>
                              <FlexColumnCont height="auto">
                                <Image80x100px
                                  src={"/pdficon.png"}
                                  alt={"File:" + o.File}
                                ></Image80x100px>
                                <LargeResponsiveButton>
                                  View PDF
                                </LargeResponsiveButton>
                              </FlexColumnCont>
                            </PhotoContExtra>
                          </a>
                        </FlexRowCont300x200>
                        <SmallTextPadding>{o.Caption}</SmallTextPadding>
                      </FlexDivFlexStartCenter>
                    ))}
                  </GridContainer2Wide>
                </FlexContFullWidthJustifyCenter>
                <RegularResponsiveButton
                  width="200px"
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setDeleteProjectWindowDisplay(true);
                  }}
                  display={deleteProjectDisplay}
                >
                  <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                  Delete Project
                </RegularResponsiveButton>

                <RegularResponsiveButton
                  width="250px"
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setRequestCancelWindowDisplay(true);
                  }}
                  display={requestCancelDisplay}
                >
                  <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                  Request Cancellation
                </RegularResponsiveButton>

                <DeleteProjectWindow
                  projectId={projectObj.Id}
                  deleteOpen={deleteProjectWindowDisplay}
                  CloseWindow={() => {
                    setDeleteProjectWindowDisplay(false);
                  }}
                ></DeleteProjectWindow>
                <RequestProjectCancelWindow
                  projectId={projectObj.Id}
                  deleteOpen={requestCancelWindowDisplay}
                  CloseWindow={() => {
                    setRequestCancelWindowDisplay(false);
                  }}
                ></RequestProjectCancelWindow>
              </FlexColumnFullWidth>
            </WhiteTopRoundedDivAutoHeight>

            {/* Project Proposals --------------------------------------------------------------- */}

            <WhiteTopRoundedDivAutoHeight display={proposalVisible}>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProposalArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Estimates
                </SmallHeaderLeagueSpartan>
                <Icon30x30
                  onClick={() => {
                    DisplayProjectProposals(projectProposalArrow);
                  }}
                  src={projectProposalArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth margin="0" display={projectProposalsDisplay}>
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="73vw"
                  height="auto"
                  margin="0"
                >
                  <FlexColumnCont alignitems="left" width="70vw" margin="0">
                    <FlexColumnCont
                      justifycontent="flex-start"
                      margin="0"
                      margintop="1%"
                      borderbottom={
                        "4px solid " + ColorPalette.PrimaryButtonBlue
                      }
                      height="50%"
                      width="99%"
                      flexdirection="row"
                    >
                      <FlexColumnPropWidth width="auto">
                        <SmallHeaderLeagueSpartan>
                          Package Name
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="49%"></FlexColumnPropWidth>

                      <FlexColumnPropWidth width="20%">
                        <SmallHeaderLeagueSpartan>
                          Date
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="auto">
                        <SmallHeaderLeagueSpartan>
                          Status
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>
                    </FlexColumnCont>
                  </FlexColumnCont>

                  <FlexColumnCont
                    margin="0"
                    alignitems="left"
                    width="70vw"
                    height="auto"
                  >
                    <br></br>
                    {projectProposals
                      .filter((o) => o.QuoteStatus !== QuoteStatus.Expired)
                      .map((o, i) => (
                        <div key={i}>
                          <ProposalCardUser
                            key={i}
                            proposallist={projectProposals}
                            quoteid={o.Id}
                            proposalObj={o}
                            numberOfQuotes={projectProposals.length}
                          ></ProposalCardUser>
                        </div>
                      ))}

                    <br></br>
                  </FlexColumnCont>
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </WhiteTopRoundedDivAutoHeight>

            <WhiteTopRoundedDivAutoHeight display={expiredProposalVisible}>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProposalArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Archived Estimates
                </SmallHeaderLeagueSpartan>
                <Icon30x30
                  onClick={() => {
                    DisplayProjectProposals(
                      expiredProjectProposalArrow,
                      "expired"
                    );
                  }}
                  src={expiredProjectProposalArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth
                margin="0"
                display={expiredProjectProposalsDisplay}
              >
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="73vw"
                  height="auto"
                  margin="0"
                >
                  <FlexColumnCont alignitems="left" width="70vw" margin="0">
                    <FlexColumnCont
                      justifycontent="flex-start"
                      margin="0"
                      margintop="1%"
                      borderbottom={
                        "4px solid " + ColorPalette.PrimaryButtonBlue
                      }
                      height="50%"
                      width="99%"
                      flexdirection="row"
                    >
                      <FlexColumnPropWidth width="auto">
                        <SmallHeaderLeagueSpartan>
                          Package Name
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="49%"></FlexColumnPropWidth>

                      <FlexColumnPropWidth width="20%">
                        <SmallHeaderLeagueSpartan>
                          Date
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="auto">
                        <SmallHeaderLeagueSpartan>
                          Status
                        </SmallHeaderLeagueSpartan>
                      </FlexColumnPropWidth>
                    </FlexColumnCont>
                  </FlexColumnCont>

                  <FlexColumnCont
                    margin="0"
                    alignitems="left"
                    width="70vw"
                    height="auto"
                  >
                    <br></br>
                    {projectProposals
                      .filter((o) => o.QuoteStatus === QuoteStatus.Expired)
                      .map((o, i) => (
                        <div key={i}>
                          <ProposalCardUser
                            key={i}
                            proposallist={projectProposals}
                            quoteid={o.Id}
                            proposalObj={o}
                            numberOfQuotes={projectProposals.length}
                          ></ProposalCardUser>
                        </div>
                      ))}

                    <br></br>
                  </FlexColumnCont>
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </WhiteTopRoundedDivAutoHeight>

            {/* Project Invoices --------------------------------------------------------------- */}

            {/* <WhiteTopRoundedDivAutoHeight
              mobilepaddingbottom="15vh"
              display={invoiceVisible}
            >
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Invoices
                </SmallHeaderLeagueSpartan>

                <Icon30x30
                  onClick={() => {
                    DisplayProjectInvoices(projectInvoiceArrow);
                  }}
                  src={projectInvoiceArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth display={projectInvoicesDisplay}>
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="73vw"
                  height="95%"
                  margin="0"
                >
                  <FlexColumnCont
                    alignitems="left"
                    width="70vw"
                    margin="0"
                    height="auto"
                  >
                    <FlexColumnCont
                      bgcolor={ColorPalette.DarkGrey}
                      padding=".75%"
                      justifycontent="flex-start"
                      margin="0"
                      margintop="1%"
                      height="30px"
                      width="99%"
                      flexdirection="row"
                    >
                      <FlexColumnPropWidth width="45%">
                        <MediumHeader100PercentWidthWhiteText>
                          Date
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="55%"></FlexColumnPropWidth>

                      <FlexColumnPropWidth width="16%" height="100%">
                        <MediumHeader100PercentWidthWhiteText>
                          View Invoice
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>
                    </FlexColumnCont>
                  </FlexColumnCont>

                  {projectInvoices.map((o, i) => (
                    <FlexColumnCont
                      key={i}
                      margin="0"
                      alignitems="left"
                      width="70vw"
                      height="auto"
                    >
                      <FlexColumnCont
                        mobilewidth="90vw"
                        justifycontent="flex-start"
                        mobilejustifycontent="space-between"
                        margin="0"
                        margintop="0%"
                        borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                        height="auto"
                        width="99%"
                        flexdirection="row"
                      >
                        <FlexColumnPropWidth width="30%" mobiledisplay="flex">
                          <MediumHeader100PercentWidth>
                            {o.Upload_Time.substring(0, 10)}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth width="55%"></FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="15%"
                          height="100%"
                          mobiledisplay="flex"
                        >
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target="_blank"
                          >
                            <Icon45x45 src="/Eye_Icon.svg"></Icon45x45>
                          </a>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="100px"
                          margin="0"
                          mobiledisplay={o.IsPaid === true ? "none" : "flex"}
                        >
                          <RegularResponsiveButton
                            onClick={() => {
                              PushToPayment(
                                o.Total,
                                o.Case_Id,
                                o.Quote_Id,
                                o.Id
                              );
                            }}
                          >
                            Pay Invoice
                          </RegularResponsiveButton>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="100px"
                          margin="0"
                          mobiledisplay={o.IsPaid === true ? "flex" : "none"}
                        >
                          <SmallGreenLeagueText fontsize="18px">
                            PAID
                          </SmallGreenLeagueText>
                        </FlexColumnPropWidth>
                      </FlexColumnCont>
                    </FlexColumnCont>
                  ))}
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </WhiteTopRoundedDivAutoHeight> */}
          </FlexColumn81vwLightGrey>
        </Cont>
      );
      /* Loading Screen --------------------------------------------------------------- */
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else {
    //Desktop View

    if (roleNum !== UserRole.Loading) {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey>
            <ActionMenu
              mobilePageProp={projectName}
              pageProp={"Projects - " + projectName}
              projectsbottom={"4px solid  " + ColorPalette.PrimaryButtonBlue}
            ></ActionMenu>

            {/* Project Drafted --------------------------------------------------------------- */}

            <ContentCont77vw display={draftCaseVisible}>
              <FlexCont90PercentAutoHeight>
                <SmallHeaderLeagueSpartan>
                  Ready to Submit?
                </SmallHeaderLeagueSpartan>
                <FlexColumnContCenterAlign>
                  <SmallText>
                    Review all of your information and confirm the details. Once
                    you press Submit Project, our team will be alerted and get
                    started right away. You will receive a confirmation email,
                    as well as a project proposal within 1-2 days*
                  </SmallText>
                </FlexColumnContCenterAlign>
                <br></br>
                <RegularResponsiveButton
                  display={submitButtonDisplay}
                  onClick={() => {
                    ChangeProjectStatusToSubmit(projectObj.Id);
                  }}
                >
                  Submit Project
                </RegularResponsiveButton>
              </FlexCont90PercentAutoHeight>
            </ContentCont77vw>

            {/* Project Information --------------------------------------------------------------- */}

            <ContentCont77vw>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProjectArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="1.25%">
                  Project Information
                </SmallHeaderLeagueSpartan>
                <RegularResponsiveButton
                  onClick={() => {
                    history.push(
                      "/updatecase/" +
                        params.Id +
                        "/" +
                        params.storeId +
                        "/" +
                        params.caseId
                    );
                  }}
                >
                  Edit
                </RegularResponsiveButton>
                <Icon30x30
                  src={projectInfoArrow}
                  onClick={() => {
                    DisplayProjectInfo(projectInfoArrow);
                  }}
                ></Icon30x30>
              </FlexColumnCont>

              <FlexColumnFullWidth display={projectInfoDisplay}>
                <FlexColumnCont
                  flexdirection="row"
                  justifycontent="flex-start"
                  height="auto"
                  width="80%"
                  margin="0"
                  mobileflexdirection="column"
                  mobilewidth="90vw"
                  tabletflexdirection="column"
                  tabletalignitems="baseline"
                >
                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Full Name:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectObj.Name}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Phone Number:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectObj.Phone}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Email Address:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectObj.Email}</SmallHeader>
                    </WideFlexInfoCont>
                  </FlexInfoCont>

                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Address:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectObj.Street}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>City:</SmallHeader>
                      </WideFlexInfoCont>

                      <SmallHeader>{projectObj.City}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Region:</SmallHeader>
                      </WideFlexInfoCont>

                      <SmallHeader>{projectObj.Metro}</SmallHeader>
                    </WideFlexInfoCont>
                  </FlexInfoCont>
                </FlexColumnCont>

                <FlexContBottomGrey
                  width="95%"
                  height="1%"
                ></FlexContBottomGrey>

                <br></br>

                <FlexColumnCont
                  flexdirection="row"
                  justifycontent="flex-start"
                  height="auto"
                  width="80%"
                  margin="0"
                  mobileflexdirection="column"
                  mobilewidth="90vw"
                  tabletflexdirection="column"
                  tabletalignitems="baseline"
                >
                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Project Area:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectAreaString}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Project Scope:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{projectScopeString}</SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Project Type:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>{ProjectTypeString}</SmallHeader>
                    </WideFlexInfoCont>
                  </FlexInfoCont>

                  <FlexInfoCont mobilewidth="100%">
                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Project Style:</SmallHeader>
                      </WideFlexInfoCont>
                      <SmallHeader>
                        {ProjectStyleString === "N/"
                          ? "Not Selected"
                          : ProjectStyleString}
                      </SmallHeader>
                    </WideFlexInfoCont>

                    <WideFlexInfoCont>
                      <WideFlexInfoCont width="175px">
                        <SmallHeader>Project Budget:</SmallHeader>
                      </WideFlexInfoCont>

                      <SmallHeader>{ProjectBudgetString}</SmallHeader>
                    </WideFlexInfoCont>
                  </FlexInfoCont>
                </FlexColumnCont>

                <FlexContBottomGrey
                  width="95%"
                  height="1%"
                ></FlexContBottomGrey>

                <FlexColumnCont
                  alignitems="left"
                  height="auto"
                  margintop="2%"
                  margin="0"
                  width="100%"
                >
                  <SmallHeader paddingbottom="1.5%">
                    Project Description:
                  </SmallHeader>
                  <LandscapePara
                    readonly
                    disabled
                    defaultValue={projectDescriptionString}
                  ></LandscapePara>
                </FlexColumnCont>

                <br></br>

                <SmallHeaderLeagueSpartan>
                  Uploaded Photos
                </SmallHeaderLeagueSpartan>

                <FlexContFullWidthJustifyCenter>
                  <GridContainer2Wide>
                    {projectPhotos.map((o, i) => (
                      <FlexDivFlexStartCenter key={i}>
                        <FlexRowCont300x200>
                          <Image300x200GreenBorder
                            src={window.$IMGURL + o.File}
                            alt={"File:" + o.File}
                          ></Image300x200GreenBorder>
                        </FlexRowCont300x200>
                        <SmallTextPadding>{o.Caption}</SmallTextPadding>
                      </FlexDivFlexStartCenter>
                    ))}

                    {PDFMap.map((o, i) => (
                      <FlexDivFlexStartCenter key={i}>
                        <FlexRowCont300x200>
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target="_blank"
                          >
                            <PhotoContExtra key={i}>
                              <FlexColumnCont height="auto">
                                <Image80x100px
                                  src={"/pdficon.png"}
                                  alt={"File:" + o.File}
                                ></Image80x100px>
                                <LargeResponsiveButton>
                                  View PDF
                                </LargeResponsiveButton>
                              </FlexColumnCont>
                            </PhotoContExtra>
                          </a>
                        </FlexRowCont300x200>
                        <SmallTextPadding>{o.Caption}</SmallTextPadding>
                      </FlexDivFlexStartCenter>
                    ))}
                  </GridContainer2Wide>
                </FlexContFullWidthJustifyCenter>
                <RegularResponsiveButton
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setDeleteProjectWindowDisplay(true);
                  }}
                  display={deleteProjectDisplay}
                  border={deleteProjectBorder}
                  width="200px"
                >
                  <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                  Delete Project
                </RegularResponsiveButton>

                <RegularResponsiveButton
                  width="250px"
                  bgcolor={ColorPalette.PrimaryRed}
                  onClick={() => {
                    setRequestCancelWindowDisplay(true);
                  }}
                  display={requestCancelDisplay}
                  border={requestCancelProjectBorder}
                >
                  <Icon20x20 src="/Garbage_Icon.svg"></Icon20x20>
                  Request Cancellation
                </RegularResponsiveButton>

                <DeleteProjectWindow
                  projectId={projectObj.Id}
                  deleteOpen={deleteProjectWindowDisplay}
                  CloseWindow={() => {
                    setDeleteProjectWindowDisplay(false);
                  }}
                ></DeleteProjectWindow>
                <RequestProjectCancelWindow
                  projectId={projectObj.Id}
                  deleteOpen={requestCancelWindowDisplay}
                  CloseWindow={() => {
                    setRequestCancelWindowDisplay(false);
                  }}
                ></RequestProjectCancelWindow>
              </FlexColumnFullWidth>
            </ContentCont77vw>

            {/* Project Proposals --------------------------------------------------------------- */}

            <ContentCont77vw display={proposalVisible}>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProposalArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Estimates
                </SmallHeaderLeagueSpartan>
                <Icon30x30
                  onClick={() => {
                    DisplayProjectProposals(projectProposalArrow);
                  }}
                  src={projectProposalArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth margin="0" display={projectProposalsDisplay}>
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="100%"
                  height="auto"
                  margin="0"
                >
                  <FlexColumnCont alignitems="left" width="75vw" margin="0">
                    <FlexColumnCont
                      justifycontent="flex-start"
                      margin="0"
                      margintop="1%"
                      borderbottom={
                        "4px solid " + ColorPalette.PrimaryButtonBlue
                      }
                      height="50%"
                      width="99%"
                      flexdirection="row"
                    >
                      <SmallHeaderLeagueSpartan minwidth="73%" maxwidth="73%">
                        Package Name
                      </SmallHeaderLeagueSpartan>

                      <SmallHeaderLeagueSpartan minwidth="17.5%" maxwidth="20%">
                        Date
                      </SmallHeaderLeagueSpartan>

                      <SmallHeaderLeagueSpartan maxwidth="auto">
                        Status
                      </SmallHeaderLeagueSpartan>
                    </FlexColumnCont>
                  </FlexColumnCont>
                  <br></br>
                  <FlexColumnCont
                    margin="0"
                    alignitems="left"
                    width="75vw"
                    height="auto"
                  >
                    {projectProposals
                      .filter((o) => o.QuoteStatus !== QuoteStatus.Expired)
                      .map((o, i) => (
                        <div key={i}>
                          <ProposalCardUser
                            key={i}
                            proposallist={projectProposals}
                            quoteid={o.Id}
                            proposalObj={o}
                            numberOfQuotes={projectProposals.length}
                          ></ProposalCardUser>
                        </div>
                      ))}

                    <br></br>
                  </FlexColumnCont>
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </ContentCont77vw>

            <ContentCont77vw display={expiredProposalVisible}>
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <Icon30x30
                  display={backProposalArrowDisplay}
                  src="/leftarrow.svg"
                  onClick={() => {
                    history.push(
                      "/CasesPersonal/" + params.Id + "/" + params.storeId
                    );
                  }}
                ></Icon30x30>
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Archived Estimates
                </SmallHeaderLeagueSpartan>
                <Icon30x30
                  onClick={() => {
                    DisplayProjectProposals(
                      expiredProjectProposalArrow,
                      "expired"
                    );
                  }}
                  src={expiredProjectProposalArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth
                margin="0"
                display={expiredProjectProposalsDisplay}
              >
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="100%"
                  height="auto"
                  margin="0"
                >
                  <FlexColumnCont alignitems="left" width="75vw" margin="0">
                    <FlexColumnCont
                      justifycontent="flex-start"
                      margin="0"
                      margintop="1%"
                      borderbottom={
                        "4px solid " + ColorPalette.PrimaryButtonBlue
                      }
                      height="50%"
                      width="99%"
                      flexdirection="row"
                    >
                      <SmallHeaderLeagueSpartan minwidth="73%" maxwidth="73%">
                        Package Name
                      </SmallHeaderLeagueSpartan>

                      <SmallHeaderLeagueSpartan minwidth="17.5%" maxwidth="20%">
                        Date
                      </SmallHeaderLeagueSpartan>

                      <SmallHeaderLeagueSpartan maxwidth="auto">
                        Status
                      </SmallHeaderLeagueSpartan>
                    </FlexColumnCont>
                  </FlexColumnCont>
                  <br></br>
                  <FlexColumnCont
                    margin="0"
                    alignitems="left"
                    width="75vw"
                    height="auto"
                  >
                    {projectProposals
                      .filter((o) => o.QuoteStatus === QuoteStatus.Expired)
                      .map((o, i) => (
                        <div key={i}>
                          <ProposalCardUser
                            key={i}
                            proposallist={projectProposals}
                            quoteid={o.Id}
                            proposalObj={o}
                            numberOfQuotes={projectProposals.length}
                          ></ProposalCardUser>
                        </div>
                      ))}

                    <br></br>
                  </FlexColumnCont>
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </ContentCont77vw>

            {/* Project Invoices --------------------------------------------------------------- */}

            <ContentCont77vw
              mobilepaddingbottom="15vh"
              display={invoiceVisible}
            >
              <FlexColumnCont
                height="8vh"
                flexdirection="row"
                justifycontent="flex-start"
                margin="0"
              >
                <SmallHeaderLeagueSpartan width="100%" marginleft="3%">
                  Invoices
                </SmallHeaderLeagueSpartan>

                <Icon30x30
                  onClick={() => {
                    DisplayProjectInvoices(projectInvoiceArrow);
                  }}
                  src={projectInvoiceArrow}
                ></Icon30x30>
              </FlexColumnCont>
              <FlexColumnFullWidth display={projectInvoicesDisplay}>
                <FlexColumnCont
                  justifycontent="flex-start"
                  width="73vw"
                  height="95%"
                  margin="0"
                >
                  <FlexColumnCont
                    alignitems="left"
                    width="70vw"
                    margin="0"
                    height="auto"
                  >
                    <FlexColumnCont
                      bgcolor={ColorPalette.DarkGrey}
                      padding=".75%"
                      paddingleft="1%"
                      paddingright="2%"
                      justifycontent="space-between"
                      margin="0"
                      margintop="1%"
                      height="30px"
                      width="97%"
                      flexdirection="row"
                    >
                      <FlexColumnPropWidth width="200px" margin="0">
                        <MediumHeader100PercentWidthWhiteText>
                          Date
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="100px" margin="0">
                        <MediumHeader100PercentWidthWhiteText>
                          Invoice #
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="200px" margin="0">
                        <MediumHeader100PercentWidthWhiteText>
                          Amount ($)
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="400px" margin="0">
                        <MediumHeader100PercentWidthWhiteText>
                          Address
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth
                        width="125px"
                        height="100%"
                        margin="0"
                        alignitems="center"
                      >
                        <MediumHeader100PercentWidthWhiteText>
                          View Invoice
                        </MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>

                      <FlexColumnPropWidth width="100px" margin="0">
                        <MediumHeader100PercentWidthWhiteText></MediumHeader100PercentWidthWhiteText>
                      </FlexColumnPropWidth>
                    </FlexColumnCont>
                  </FlexColumnCont>

                  {projectInvoices.map((o, i) => (
                    <FlexColumnCont
                      key={i}
                      margin="0"
                      alignitems="left"
                      width="70vw"
                      height="auto"
                    >
                      <FlexColumnCont
                        mobilewidth="90vw"
                        justifycontent="space-between"
                        mobilejustifycontent="space-between"
                        margin="0"
                        margintop="0%"
                        borderbottom={"1px solid " + ColorPalette.PrimaryGrey}
                        height="auto"
                        paddingleft="1%"
                        width="97%"
                        flexdirection="row"
                      >
                        <FlexColumnPropWidth
                          width="200px"
                          mobiledisplay="flex"
                          margin="0"
                        >
                          <MediumHeader100PercentWidth>
                            {o.Upload_Time.substring(0, 10)}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth width="100px" margin="0">
                          <MediumHeader100PercentWidth>
                            #{o.Number}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth width="200px" margin="0">
                          <MediumHeader100PercentWidth>
                            $
                            {o.Total?.toFixed(2).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth width="400px" margin="0">
                          <MediumHeader100PercentWidth>
                            {o.Address}
                          </MediumHeader100PercentWidth>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="125px"
                          height="100%"
                          margin="0"
                          mobiledisplay="flex"
                        >
                          <a
                            rel="noreferrer"
                            href={window.$IMGURL + o.File}
                            target="_blank"
                          >
                            <Icon45x45 src="/Eye_Icon.svg"></Icon45x45>
                          </a>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="100px"
                          margin="0"
                          display={o.IsPaid === true ? "none" : "flex"}
                        >
                          <RegularResponsiveButton
                            onClick={() => {
                              PushToPayment(
                                o.Total,
                                o.Case_Id,
                                o.Quote_Id,
                                o.Id
                              );
                            }}
                          >
                            Pay Invoice
                          </RegularResponsiveButton>
                        </FlexColumnPropWidth>

                        <FlexColumnPropWidth
                          width="100px"
                          margin="0"
                          display={o.IsPaid === true ? "flex" : "none"}
                        >
                          <SmallGreenLeagueText fontsize="18px">
                            PAID
                          </SmallGreenLeagueText>
                        </FlexColumnPropWidth>
                      </FlexColumnCont>
                    </FlexColumnCont>
                  ))}
                </FlexColumnCont>
              </FlexColumnFullWidth>
            </ContentCont77vw>
          </FlexColumn81vwLightGrey>
        </Cont>
      );
      /* Loading Screen --------------------------------------------------------------- */
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  }
};

ViewProjectUserView.defaultProps = {
  ProfileName: "Default Name",
};

export default ViewProjectUserView;
