import { useState } from "react";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  Cont400x400pxFixed,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import { TextInput72Percent } from "../../../StylesheetComps/LandscapeForm";
import { FixedOverlayRegular } from "../../../StylesheetComps/OverlayCont";
import { Cont100PercentWidth } from "../../../StylesheetComps/Quote";
import {
  LeagueSpartanExtraLargeBold,
  LeagueSpartanExtraLargeWidth200px,
} from "../../../StylesheetComps/Titles";

const NewZoneWindow = ({
  open,
  onClose,
  onChange,
  onFormComplete,
  onPreview,
}) => {
  const [storeNewZone, setStoreNewZone] = useState("");

  if (!open) return null;
  return (
    <>
      {" "}
      <FixedOverlayRegular>
        <Cont400x400pxFixed>
          <LeagueSpartanExtraLargeBold>New Zone</LeagueSpartanExtraLargeBold>
          <LeftItemWindowFixed>
            <LeagueSpartanExtraLargeWidth200px>
              Zone Name
            </LeagueSpartanExtraLargeWidth200px>
            <TextInput72Percent
              onChange={(e) => {
                setStoreNewZone(e.target.value);
              }}
            ></TextInput72Percent>
            <Cont100PercentWidth
              onClick={() => {
                onPreview({ storeNewZone });
              }}
            >
              <RegularResponsiveButton
                onClick={() => {
                  onFormComplete(storeNewZone);
                  onClose();
                }}
              >
                Add
              </RegularResponsiveButton>
            </Cont100PercentWidth>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={onClose}
            >
              Cancel
            </RegularResponsiveButton>
          </LeftItemWindowFixed>
        </Cont400x400pxFixed>{" "}
      </FixedOverlayRegular>{" "}
    </>
  );
};

NewZoneWindow.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onFormComplete: () => {},
};

export default NewZoneWindow;
