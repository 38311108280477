import React, { useState } from "react";

import {
  Header,
  SelectableMediumLongButton,
} from "../../../StylesheetComps/AuthSheet";
import { SmallHeader } from "../../../StylesheetComps/Menu";
import { BodyPara } from "../../../StylesheetComps/AuthSheet";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import {
  Section1,
  EstimatorDiv,
  GreyNavigationButton,
  GreenNavigationButton,
  ProgressBar,
  ProgressBarWidth,
  FlexColumnGreenSelectables,
} from "../../../StylesheetComps/LandscapeForm";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { FlexRowCont70PercentFlexStart } from "../../../StylesheetComps/Cont";
const ProjectArea = ({
  open,
  onNext,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
  onSelectProjectArea,
}) => {
  //Front Yard UI Update
  const [frontcolor, setFrontColor] = useState("black");
  const [frontborder, setFrontBorder] = useState("4px solid white");
  const [frontselected, setFrontSelected] = useState("");

  //Back Yard UI Update
  const [backcolor, setBackColor] = useState("");
  const [backborder, setBackBorder] = useState("");
  const [backselected, setBackSelected] = useState("");

  //Side Yard UI Update
  const [sidecolor, setSideColor] = useState("");
  const [sideborder, setSideBorder] = useState("");
  const [sideselected, setSideSelected] = useState("");

  //Entertainment UI Update
  const [entertainmentcolor, setEntertainmentColor] = useState("");
  const [entertainmentborder, setEntertainmentBorder] = useState("");
  const [entertainmentselected, setEntertainmentSelected] = useState("");

  //Driveway UI Update
  const [drivewaycolor, setDrivewayColor] = useState("");
  const [drivewayborder, setDrivewayBorder] = useState("");
  const [drivewayselected, setDrivewaySelected] = useState("");

  //Other UI Update
  const [othercolor, setOtherColor] = useState("");
  const [otherBorder, setOtherBorder] = useState("");
  const [otherSelected, setOtherSelected] = useState("");
  const [displayOtherDiv, setDisplayOtherDiv] = useState("none");

  const [otherAreaText, setOtherAreaText] = useState("");

  //Change Select / Deselected UI for the button text and borders,
  //as well as if it is selected

  const setFrontBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setFrontColor("black");
      setFrontBorder("4px solid white");
      setFrontSelected("");
    } else {
      setFrontColor("#169ADB");
      setFrontBorder("4px solid #169ADB");
      setFrontSelected("Front Yard");
    }
  };

  const setBackBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setBackColor("black");
      setBackBorder("4px solid white");
      setBackSelected("");
    } else {
      setBackColor("#169ADB");
      setBackBorder("4px solid #169ADB");
      setBackSelected("Back Yard");
    }
  };

  const setSideBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setSideColor("black");
      setSideBorder("4px solid white");
      setSideSelected("");
    } else {
      setSideColor("#169ADB");
      setSideBorder("4px solid #169ADB");
      setSideSelected("Side Yard");
    }
  };

  const setEntertainmentBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setEntertainmentColor("black");
      setEntertainmentBorder("4px solid white");
      setEntertainmentSelected("");
    } else {
      setEntertainmentColor("#169ADB");
      setEntertainmentBorder("4px solid #169ADB");
      setEntertainmentSelected("Entertainment Area");
    }
  };

  const setDriveWayBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setDrivewayColor("black");
      setDrivewayBorder("4px solid white");
      setDrivewaySelected("");
    } else {
      setDrivewayColor("#169ADB");
      setDrivewayBorder("4px solid #169ADB");
      setDrivewaySelected("Driveway");
    }
  };

  const setOtherBoxColor = async (originalcolor) => {
    if (originalcolor === ColorPalette.PrimaryButtonBlue) {
      setOtherColor("black");
      setOtherBorder("4px solid white");
      setOtherSelected("");
      setDisplayOtherDiv("none");
    } else {
      setOtherColor("#169ADB");
      setOtherBorder("4px solid #169ADB");
      setOtherSelected("Other:");
      setDisplayOtherDiv("block");
    }
  };

  //Take Global Variables for possible areas of work and combine them into a single string. Push this string as a parameter to a higher function
  //and move onto the next subpage (Project Scope)

  const DefineProjectArea = async (
    frontselected,
    backselected,
    sideselected,
    entertainmentselected,
    drivewayselected,
    otherSelected,
    otherAreaText
  ) => {
    let projectareastring =
      frontselected +
      " " +
      backselected +
      " " +
      sideselected +
      " " +
      entertainmentselected +
      " " +
      drivewayselected +
      " " +
      otherSelected +
      otherAreaText;
    onSelectProjectArea(projectareastring);
    onNext();
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="37.5%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Area
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="0%"
          fontsize="16px"
        >
          Tell us where your project is located. If your project takes up a
          larger space, please indicate the scale of the space in the "Other"
          section below.{" "}
        </BodyPara>

        <FlexColumnCont
          width="60%"
          mobilewidth="70vw"
          height="auto"
          marginbottom="0%"
        >
          <FlexColumnCont width="100%" justifycontent="flex-start" height="80%">
            <FlexColumnGreenSelectables>
              <SelectableMediumLongButton
                border={frontborder}
                color={frontcolor}
                onClick={() => {
                  setFrontBoxColor(frontcolor);
                }}
              >
                Front Yard
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                border={backborder}
                color={backcolor}
                onClick={() => {
                  setBackBoxColor(backcolor);
                }}
              >
                Back Yard
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                border={sideborder}
                color={sidecolor}
                onClick={() => {
                  setSideBoxColor(sidecolor);
                }}
              >
                Side Yard
              </SelectableMediumLongButton>
            </FlexColumnGreenSelectables>

            <FlexColumnGreenSelectables>
              <SelectableMediumLongButton
                border={entertainmentborder}
                color={entertainmentcolor}
                onClick={() => {
                  setEntertainmentBoxColor(entertainmentcolor);
                }}
              >
                Entertainment
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                border={drivewayborder}
                color={drivewaycolor}
                onClick={() => {
                  setDriveWayBoxColor(drivewaycolor);
                }}
              >
                Driveway
              </SelectableMediumLongButton>
              <SelectableMediumLongButton
                border={otherBorder}
                color={othercolor}
                onClick={() => {
                  setOtherBoxColor(othercolor);
                }}
              >
                Other
              </SelectableMediumLongButton>
            </FlexColumnGreenSelectables>
          </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          display={displayOtherDiv}
          width="55%"
          margintop="2%"
          marginbottom="0"
          height="auto"
        >
          <FlexRowCont70PercentFlexStart>
            <SmallHeader>
              If selected other, where is the area of work?
            </SmallHeader>
          </FlexRowCont70PercentFlexStart>
          <TextInput300x50
            inputwidth="84%"
            placeholder={"Area of Work"}
            inputheight="1%"
            inputborderradius="5px"
            defaultValue={fullName}
            onChange={(e) => {
              setOtherAreaText(e.target.value);
            }}
          ></TextInput300x50>
        </FlexColumnCont>
        <FlexColumnCont
          height="8%"
          margin="0"
          margintop="2%"
          flexdirection="row"
          justifycontent="space-around"
          width="100%"
        >
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              DefineProjectArea(
                frontselected,
                backselected,
                sideselected,
                entertainmentselected,
                drivewayselected,
                otherSelected,
                otherAreaText
              );
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectArea.defaultProps = {
  onChange: () => {},
  onSelectProjectArea: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectArea;
