import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import Menu from "../../comps/MenuComps/Menu";
import DeleteZoneWindow from "../../comps/Windows/DeleteZone";
import EditZoneWindow from "../../comps/Windows/EditZone";
import NewZoneWindow from "../../comps/Windows/NewZone";
import ZoneCatCard from "../../comps/ZoneCatCard";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexCont100vw100vh,
  LeftCont,
  RightCont,
} from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import {
  FlexDiv70x7Percent,
  GridDiv80xAutoPercent,
} from "../../StylesheetComps/Div";
import {
  HundredPercentFlexDiv,
  Icon30px30pxPointer,
} from "../../StylesheetComps/Quote";
import { LeagueSpartanExtraLargeBold } from "../../StylesheetComps/Titles";

const ZoneListScreen = () => {
  //=============================================================================
  // Zone List Screen - View all Zone inside of a Metro (Edit, Delete)
  //=============================================================================

  const params = useParams();
  const history = useHistory();

  const [zoneListMap, getZoneListMap] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDelOpen, setIsDelOpen] = useState(false);

  const [editMetroOpen, setEditMetroOpen] = useState(false);
  const [storeMetroId, setStoreMetroId] = useState(0);
  const [storeStoreId, setStoreStoreId] = useState(0);

  const HandleEditMetro = async (newMetroName) => {
    var resp = await axios.put(window.$DBURL + "store/zone", {
      Name: newMetroName,
      Id: storeMetroId,
    });
    if (resp.status === 200) {
      GetZoneList();
    }
  };

  const GetZoneList = async () => {
    try {
      const resp = await axios.get(
        `${window.$DBURL}store/zonelist/${params.Id}`
      );

      if (resp.status === 200 && resp.data !== null) {
        getZoneListMap(resp.data.zones);
      }
    } catch (error) {
      // Handle any errors that may occur
    }
  };

  const GetProfileInfo = async () => {
    setStoreStoreId(JSON.parse(sessionStorage.getItem("userInfo")).Store_Id);
  };

  const HandleNewZone = async (newMetro) => {
    try {
      const resp = await axios.post(`${window.$DBURL}store/zone`, {
        Name: newMetro,
        Store_Id: storeStoreId,
      });

      if (resp.status === 200) {
        GetZoneList();
      }
    } catch (error) {
      // Handle any errors that may occur
    }
  };

  const HandleDeleteZone = async () => {
    try {
      const resp = await axios.delete(
        `${window.$DBURL}store/zone/${storeMetroId}`
      );
      if (resp.status === 200) {
        GetZoneList();
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    GetZoneList();
    GetProfileInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FlexCont100vw100vh>
      <LeftCont>
        <Menu> </Menu>
      </LeftCont>
      <NewZoneWindow
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onFormComplete={HandleNewZone}
      ></NewZoneWindow>
      <RightCont>
        <ActionMenu />
        <RightViewFullWidth>
          <FlexDiv70x7Percent>
            <HundredPercentFlexDiv>
              <Icon30px30pxPointer
                src="/backarrow.svg"
                onClick={() => {
                  history.push("/store/" + params.Id);
                }}
              ></Icon30px30pxPointer>
              <LeagueSpartanExtraLargeBold>Zones</LeagueSpartanExtraLargeBold>
              <RegularResponsiveButton onClick={() => setIsOpen(true)}>
                Add Zone
              </RegularResponsiveButton>
            </HundredPercentFlexDiv>
          </FlexDiv70x7Percent>
          <DeleteZoneWindow
            deleteOpen={isDelOpen}
            closeDel={() => {
              setIsDelOpen(false);
            }}
            onDeleteFunction={HandleDeleteZone}
          ></DeleteZoneWindow>
          <GridDiv80xAutoPercent>
            {zoneListMap.map((o, i) => (
              <ZoneCatCard
                key={i}
                MetroName={o.Name}
                MetroID={o.Store_Id}
                ZoneID={o.Id}
                DelMetro={() => {
                  setIsDelOpen(true);
                  setStoreMetroId(o.Id);
                }}
                EditMetro={() => {
                  setEditMetroOpen(true);
                  setStoreMetroId(o.Id);
                }}
              ></ZoneCatCard>
            ))}
          </GridDiv80xAutoPercent>
          <EditZoneWindow
            open={editMetroOpen}
            onClose={() => setEditMetroOpen(false)}
            onMetroComplete={HandleEditMetro}
          ></EditZoneWindow>
        </RightViewFullWidth>
      </RightCont>
    </FlexCont100vw100vh>
  );
};

ZoneListScreen.defaultProps = {
  ProfileName: "Default Name",
  RoleName: "Role Name Ex. Estimator",
};

export default ZoneListScreen;
