import React from "react";
import { Link } from "react-router-dom";
import {
  Icon30x40pxPointer,
  Icon40x40Percent,
} from "../../StylesheetComps/Imgs/Icons";
import { FlexCont80PercentAutoHeightPadding } from "../../StylesheetComps/Cont";
import {
  Div30Percent,
  FlexColumnDivSpaceBetween,
} from "../../StylesheetComps/Div";
import { RegularTextBold } from "../../StylesheetComps/Titles";

const ZoneCatCard = ({ MetroName, MetroID, DelMetro, EditMetro, ZoneID }) => {
  return (
    <FlexCont80PercentAutoHeightPadding>
      <Div30Percent>
        <Icon40x40Percent src={"/metroicon.svg"}></Icon40x40Percent>
      </Div30Percent>
      <Link
        to={"/Zone/" + MetroID + "/" + ZoneID}
        style={{ textDecoration: "none", color: "black" }}
      >
        <RegularTextBold>{MetroName}</RegularTextBold>
      </Link>
      <FlexColumnDivSpaceBetween>
        <Icon30x40pxPointer
          src={"/EditIcon.svg"}
          onClick={EditMetro}
        ></Icon30x40pxPointer>
        <Icon30x40pxPointer
          src={"/Garbage_Icon.svg"}
          onClick={DelMetro}
        ></Icon30x40pxPointer>
      </FlexColumnDivSpaceBetween>
    </FlexCont80PercentAutoHeightPadding>
  );
};

ZoneCatCard.defaultProps = {
  MetroName: "Default Zone Name",
  MetroID: 0,
  ZoneID: 0,
};

export default ZoneCatCard;
