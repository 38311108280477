import styled from "styled-components";

const nullProp = null;

export const InputField = styled.input`
margin-bottom: 20px;
padding: 12px 16px;
font-size: 14px;
border: 1px solid #ccc;
margin-left: 5px;
border-radius: 8px;
outline: none;
color: #333;
background-color: #f9f9f9;
transition: border-color 0.3s ease;
width: ${(props) => (props.width ? props.width : "100%")};
min-width:75px;
box-sizing: border-box;

&:focus {
  border-color: #007aff; // You can adjust this to match your theme color
}

::placeholder {
  color: #999;
}
`;

export const RegularFuturaOption = styled.option`
  font-family: Futura;
  font-size: 18px;
  cursor: pointer;
  text-align: left;
  margin: 10px;
  width: 200px;
  @media (max-width: 1006px) {
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const BlankOption = styled.option``;

export const TextInputSmall = styled.input`
  width: ${(props) => (props.inputwidth ? props.inputwidth : "350px")};
  height: ${(props) => (props.inputheight ? props.inputheight : "5px")};
  border-radius: ${(props) =>
    props.inputborderradius ? props.inputborderradius : "4px"};
  background-color: ${(props) =>
    props.inputbgcolor ? props.inputbgcolor : "#F4F4F4"};
  border-style: ${(props) =>
    props.inputborderstyle ? props.inputborderstyle : "solid"};
  border-color: ${(props) =>
    props.inputbordercolor ? props.inputbordercolor : "#AAAAAA"};
  border-width: ${(props) =>
    props.inputborderwidth ? props.inputborderwidth : 2};
  display: ${(props) => (props.display ? props.display : "block")};
  margin: 0px;
  margin: 10px;
  padding: 12px;
  ::-webkit-file-upload-button {
    display: none;
  }
  outline: none;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "100%")};
  font-family: Avenir;
  @media (max-width: 1006px) {
    margin-top: 2vh;
    margin-left: ${(props) =>
      props.inputmarginleftmobile ? props.inputmarginleftmobile : "0%"};
    display: ${(props) => (props.mobiledisplay ? props.mobiledisplay : "")};
    width: ${(props) => (props.mobilewidth ? props.mobilewidth : "70vw")};
  }
`;

export default nullProp;
