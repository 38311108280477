import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Cont100PercentMobileOpt,
  FlexColumnLeftAlign,
  FlexDiv,
  FlexRowCont,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFlexStart,
} from "../../StylesheetComps/Cont";
import {
  FlexCenterDiv100PercentCenter,
  QuoteQuadHead,
  QuoteQuadHeaderBorderTopLeft,
  QuoteQuadHeaderBorderTopRight,
  QuoteQuadHeadMedium,
} from "../../StylesheetComps/Div";
import {
  LeagueSpartanHeaderSmall,
  LeagueSpartanLarge,
  LeagueSpartanRegular,
  SmallHeaderOpenSansBold,
  StyledHRGreenBreak,
  TinyTextItalics,
} from "../../StylesheetComps/Titles";
import QuoteTableItems from "../QuoteTableItems";
import QuoteTableTops from "../QuoteTableTops";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import { GetQuoteById } from "../../API_Functions/CaseQuote_Functions";

const QuoteTable = ({
  cateType,
  Tags,
  quoteItems,
  quoteTotalsArray,
  subtotal,
  total,
  onDel,
  onItemChange,
  EditTagWindow,
  DeleteTag
}) => {
  const [primaryItems, setPrimaryItems] = useState([]);
  var CateType = {
    Material: 0,
    Labor: 2,
  };

  useEffect(() => {
    if (quoteItems != null) {
      if (quoteItems.length > 0) {
        setPrimaryItems(
          quoteItems.filter(
            (o) =>
              (o.Parent_Id === 0 && o.CateType !== CateType.Labor) ||
              o.CateType === CateType.Labor
          )
        );
      } else {
        setPrimaryItems([]);
      }
    }
  }, [quoteItems]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleItemChange = useCallback(() => {
    onItemChange();
  }, [onItemChange]);

  const filterRelatedItems = useCallback(
    (o) =>
      quoteItems.filter(
        (item) =>
          (item.Parent_Id === o.Id && item.CateType === CateType.Material) ||
          (item.Parent_Id === o.Material_Id &&
            item.Parent_Id !== item.Material_Id &&
            item.CateType === CateType.Labor)
      ),
    [quoteItems, CateType]
  );

  const itemRemoval = (itemId) => {
    setPrimaryItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };
  
  const primaryItemsNoTag = useMemo(
    () => primaryItems.filter((item) => item.Tag_Id === 0),
    [primaryItems]
  );

  const primaryItemsWithTags = useMemo(
    () => Tags.map((t) => ({ tag: t, items: primaryItems.filter((item) => item.Tag_Id === t.Id) })),
    [Tags, primaryItems]
  );

  if (quoteItems !== ["name"]) {
    return (
      <Cont100PercentMobileOpt>
        {primaryItemsWithTags.map(({tag, items}, j) => (
          <div key={j}>
                    <br></br>
              <FlexRowContFlexStart>
              <LeagueSpartanHeaderSmall minwidth='100px'>{tag.TagName}</LeagueSpartanHeaderSmall>
              <FlexRowCont100PercentSpaceBetween>
              <Icon30px30pxPointer
                      onClick={async () => {
                        EditTagWindow(tag);
                      }}
                      src="/pencil.svg"
                    ></Icon30px30pxPointer>
                              <Icon30px30pxPointer
                    onClick={() => {
                      DeleteTag(tag)
                    }}
                    src="/Garbage_Icon.svg"
                  ></Icon30px30pxPointer>
                    </FlexRowCont100PercentSpaceBetween>
              </FlexRowContFlexStart>
              <StyledHRGreenBreak></StyledHRGreenBreak>
            <br></br>

            <FlexDiv>
          <QuoteQuadHeaderBorderTopLeft>
            <SmallHeaderOpenSansBold>Name</SmallHeaderOpenSansBold>
          </QuoteQuadHeaderBorderTopLeft>

          <QuoteQuadHead>
            <SmallHeaderOpenSansBold>Quantity</SmallHeaderOpenSansBold>
          </QuoteQuadHead>

          <QuoteQuadHeadMedium>
            <SmallHeaderOpenSansBold>Unit Rate</SmallHeaderOpenSansBold>
          </QuoteQuadHeadMedium>

          <QuoteQuadHeadMedium>
            <SmallHeaderOpenSansBold>Price</SmallHeaderOpenSansBold>
          </QuoteQuadHeadMedium>

          <QuoteQuadHeaderBorderTopRight>
            <SmallHeaderOpenSansBold>Remove Item</SmallHeaderOpenSansBold>
          </QuoteQuadHeaderBorderTopRight>
        </FlexDiv>

        {
  items.length === 0
    ? <FlexCenterDiv100PercentCenter>
      <TinyTextItalics>No Items</TinyTextItalics>
    </FlexCenterDiv100PercentCenter> 
    : items.map((o, i) => (
        <QuoteTableItems
          key={i}
          quoteItemObj={o}
          onItemChange={() => { handleItemChange(); itemRemoval(o.itemid); }}
          noChange={o.NoChange}
          RelatedItems={filterRelatedItems(o)}
          onDel={onDel}
        />
      ))
}
          </div>
        ))}

<br></br>
<FlexDiv>
          <QuoteQuadHeaderBorderTopLeft>
            <SmallHeaderOpenSansBold>Name</SmallHeaderOpenSansBold>
          </QuoteQuadHeaderBorderTopLeft>

          <QuoteQuadHead>
            <SmallHeaderOpenSansBold>Quantity</SmallHeaderOpenSansBold>
          </QuoteQuadHead>

          <QuoteQuadHeadMedium>
            <SmallHeaderOpenSansBold>Unit Rate</SmallHeaderOpenSansBold>
          </QuoteQuadHeadMedium>

          <QuoteQuadHeadMedium>
            <SmallHeaderOpenSansBold>Price</SmallHeaderOpenSansBold>
          </QuoteQuadHeadMedium>

          <QuoteQuadHeaderBorderTopRight>
            <SmallHeaderOpenSansBold>Remove Item</SmallHeaderOpenSansBold>
          </QuoteQuadHeaderBorderTopRight>
        </FlexDiv>        

{primaryItemsNoTag.map((o, i) => (
          <QuoteTableItems
            key={i}
            quoteItemObj={o}
            onItemChange={(itemid)=>{handleItemChange(); itemRemoval(itemid) }}
            noChange={o.NoChange}
            RelatedItems={filterRelatedItems(o)}
            onDel={onDel}
          ></QuoteTableItems>
        ))}

        <QuoteTableTops
          cateType={cateType}
          subtotal={subtotal}
          quoteTax={quoteTotalsArray}
          total={total}
        ></QuoteTableTops>
      </Cont100PercentMobileOpt>
    );
  } else {
    return (
      <Backdrop
        style={{ zIndex: 2 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

QuoteTable.defaultProps = {
  Tags: [],
  quoteItems: ["name"],
  quoteTotalsArray: ["name"],
  subtotal: "",
  total: "",
  cateType: 0,
  onItemChange: () => {},
  EditTagWindow: () => {},
  DeleteTag: () => {},
};

export default QuoteTable;
