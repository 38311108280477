import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import Input from "../../comps/Input";
import { ContAutoWidth5vh } from "../../StylesheetComps/Cont";
import { FlexDiv50Percent } from "../../StylesheetComps/Div";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import { RegularTextBold } from "../../StylesheetComps/Titles";

const ZoneSupplierCard = ({
  SupplierNameText,
  SupplierId,
  RemoveFromZone,
  SupplierPosition,
}) => {
  const params = useParams();

  var supplierPos = 0;

  const UpdatePosOfSupplier = async () => {
    const resp = await axios.put(
      window.$DBURL +
        "store/zone/" +
        params.zoneId +
        "/supplier/" +
        SupplierId +
        "/position/" +
        supplierPos
    );
    if (resp.status === 200) {
    }
  };

  return (
    <ContAutoWidth5vh>
      <FlexDiv50Percent>
        <RegularTextBold>{SupplierNameText}</RegularTextBold>
      </FlexDiv50Percent>

      <FlexDiv50Percent>
        <RegularTextBold>Position:</RegularTextBold>
        <Input
          type="number"
          defaultValue={SupplierPosition}
          value={SupplierPosition}
          inputheight={"20%"}
          maxlength={1}
          inputwidth={"10%"}
          placeholder=""
          onChange={(e) => {
            supplierPos = e.target.value;
            if (supplierPos !== "") {
              UpdatePosOfSupplier();
              e.target.blur();
            }
          }}
        ></Input>
      </FlexDiv50Percent>
      <Icon30px20pxPointer
        src="/Garbage_Icon.svg"
        onClick={RemoveFromZone}
      ></Icon30px20pxPointer>
    </ContAutoWidth5vh>
  );
};

ZoneSupplierCard.defaultProps = {
  SupplierNameText: "EX. Home Depot",
  SupplierId: 0,
  SupplierPosition: 0,
  onPreview: () => {},
};

export default ZoneSupplierCard;
