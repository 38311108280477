import { useState } from "react";
import { useParams } from "react-router-dom";
import { DeleteProductAddon } from "../../API_Functions/CatProduct_Functions";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexRowCont100PercentSpaceBetween,
  FlexRowCont60PercentFlexStart,
  FlexRowContFlexStart,
  LeftItemWindowFixed,
} from "../../StylesheetComps/Cont";
import { SmallHeaderLeagueSpartan } from "../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";
import { Icon30px30pxPointer } from "../../StylesheetComps/Quote";
import {
  LeagueSpartanTiny,
  RegularText,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";
import CategoryContentList from "../CategoryContentList";
import ConfirmWindow from "../Windows/ConfirmWIndow";

const ProductAddOn = ({ open, onClose, productObj, RefreshAddOnList }) => {
  const params = useParams();

  const [categoryContentVisible, setIsCategoryContentVisible] = useState(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);

  const [deleteId, setDeleteId] = useState(0);

  const RemoveAddon = async (productid, cateid) => {
    const data = await DeleteProductAddon(productid, cateid);
    if (data !== null) {
      RefreshAddOnList();
      setIsConfirmDeleteVisible(false);
    }
  };

  const ResetView = async () => {};

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexRowContFlexStart>
        <LeftItemWindowFixed>
          <SmallHeaderLeagueSpartan width="500px">
            {productObj?.Name}
          </SmallHeaderLeagueSpartan>
          <StyledHRPropColour
            border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
          ></StyledHRPropColour>
          <br></br>
          <FlexRowCont100PercentSpaceBetween>
            <FlexRowCont60PercentFlexStart>
              <LeagueSpartanTiny>Addon Categories</LeagueSpartanTiny>
            </FlexRowCont60PercentFlexStart>
            <LeagueSpartanTiny>Position</LeagueSpartanTiny>
            <LeagueSpartanTiny>Delete</LeagueSpartanTiny>
          </FlexRowCont100PercentSpaceBetween>
          {productObj?.Addons.map((o, i) => (
            <FlexRowCont100PercentSpaceBetween overflowy="auto">
              <FlexRowCont60PercentFlexStart>
                <RegularText>{o.Category}</RegularText>
              </FlexRowCont60PercentFlexStart>
              <RegularText>{o.Position}</RegularText>
              <Icon30px30pxPointer
                onClick={() => {
                  setDeleteId(o.Category_Id);
                  setIsConfirmDeleteVisible(true);
                }}
                src="/Garbage_Icon.svg"
              ></Icon30px30pxPointer>
            </FlexRowCont100PercentSpaceBetween>
          ))}
          <ConfirmWindow
            open={isConfirmDeleteVisible}
            left="5%"
            leftbuttontext="Return"
            rightbuttontext="Remove"
            leftbuttoncolor={ColorPalette.PrimaryGrey}
            rightbuttoncolor={ColorPalette.PrimaryRed}
            confirmtext="Are you sure you want to remove this Addon?"
            LeftFunction={() => {
              setIsConfirmDeleteVisible(false);
            }}
            RightFunction={() => {
              RemoveAddon(productObj?.Id, deleteId);
            }}
          ></ConfirmWindow>
          <br></br>
          <StyledHRPropColour
            border={`0.5px dashed ${ColorPalette.PrimaryGrey}`}
          ></StyledHRPropColour>
          <br></br>
          <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                ResetView();
                setIsCategoryContentVisible(false);
                onClose();
              }}
            >
              Close
            </RegularResponsiveButton>{" "}
            <RegularResponsiveButton
              onClick={() => {
                setIsCategoryContentVisible(true);
              }}
            >
              Create AddOn
            </RegularResponsiveButton>
          </FlexRowCont100PercentSpaceBetween>
        </LeftItemWindowFixed>

        <CategoryContentList
          categoryid={params.cateId}
          open={categoryContentVisible}
          productObj={productObj}
          RefreshCategoryList={() => {
            RefreshAddOnList();
          }}
        ></CategoryContentList>
      </FlexRowContFlexStart>
    </FixedOverlay200vh>
  );
};

ProductAddOn.defaultProps = {
  open: false,
  onClose: () => {},
  productObj: {},
  RefreshAddOnList: () => {},
};

export default ProductAddOn;
