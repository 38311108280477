/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetCityList,
  GetMetroList,
} from "../../../API_Functions/Utility_Functions";
import { BodyPara, Header } from "../../../StylesheetComps/AuthSheet";
import {
  FlexColumnCont75x80Percent,
  FlexColumnFullWidth,
  FlexColumnLeftAlign,
  FlexRowCont,
} from "../../../StylesheetComps/Cont";
import {
  DatalistMedium,
  DatalistOption,
  EstimatorDiv,
  FormContPersonalInfo,
  FormDivs,
  GreenNavigationButton,
  GreyNavigationButton,
  ProgressBar,
  ProgressBarWidth,
  SecDivPersonalInfoMarginLeft,
  Section1,
  TextInputRegular,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont, SmallHeader } from "../../../StylesheetComps/Menu";
import {
  LeagueSpartanTiny,
  MediumHeader100PercentWidth,
  RegularTextBoldRed,
} from "../../../StylesheetComps/Titles";

const PersonalInfo = ({ open, onNext, onBack, onPreview, onPersonalInfo }) => {
  const history = useHistory();
  const params = useParams();
  const projectInfoMargin = "30px";
  const mobileHeaderWidth = "90px";

  const [Title, setTitle] = useState("");
  const [cityList, setCityList] = useState([]);
  const [metroList, setMetroList] = useState([]);
  const [ErrorText, setErrorText] = useState("");

  //Set the ContactInfo variable to use userobj as a reference

  let userobj = JSON.parse(sessionStorage.getItem("userInfo"));
  let ContactInfo = React.useRef(userobj);

  //Get the location info for the user and define the Metro/City Map Datalists

  const GetLocationInfo = async () => {
    let metrodata = await GetMetroList();
    let citydata = await GetCityList(userobj.Metro_Id);
    setCityList(citydata);
    setMetroList(metrodata);
  };

  //Update the CityList to match the cities in a metro (activated when user
  //selects a new metro)

  const UpdateCityList = async (metroid) => {
    if (metroid !== 0 && metroid !== 3) {
      ContactInfo.current.City = "";
      ContactInfo.current.City_Id = 0;
      let citydata = await GetCityList(metroid);
      setCityList(citydata);
    }
  };

  //Run GetLocationInfo(); immediately after load

  useEffect(() => {
    GetLocationInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Once user wants to proceed, ensure all values are filled in,
  //display an error if otherwise.

  const DefinePersonalInfo = async () => {
    ContactInfo.current.Name = firstName + " " + lastName;
    if (
      firstName !== null &&
      firstName !== "" &&
      firstName !== undefined &&
      firstName !== " " &&
      lastName !== null &&
      lastName !== "" &&
      lastName !== undefined &&
      lastName !== " " &&
      ContactInfo.current.Name !== null &&
      ContactInfo.current.Name !== "" &&
      ContactInfo.current.Name !== undefined &&
      ContactInfo.current.Name !== " " &&
      ContactInfo.current.Street !== null &&
      ContactInfo.current.Street !== "" &&
      ContactInfo.current.Street !== undefined &&
      ContactInfo.current.Street !== " " &&
      ContactInfo.current.CityId !== 0 &&
      ContactInfo.current.Phone !== null &&
      ContactInfo.current.Phone !== "" &&
      ContactInfo.current.Phone !== undefined &&
      ContactInfo.current.Phone !== " " &&
      ContactInfo.current.Email !== null &&
      ContactInfo.current.Email !== "" &&
      ContactInfo.current.Email !== undefined &&
      ContactInfo.current.Email !== " " &&
      Title !== null &&
      Title !== "" &&
      Title !== undefined &&
      Title !== " " &&
      ContactInfo.current.Metro_Id !== 0 &&
      ContactInfo.current.Metro_Id !== 3
    ) {
      onPreview({ ContactInfo, Title });
      onPersonalInfo(ContactInfo, Title);
      onNext();
    } else {
      setErrorText("Please fill in all of the boxes");
      window.scrollTo(0, 0);
    }
  };

  //Assigns the ID based on the Name Selected
  const SelectCity = async (e) => {
    ContactInfo.current.City_Id = e.target.value;
    cityList.find(function (o, i) {
      if (o.Id === e.target.value) {
        ContactInfo.current.City = o.Name;
      }
      return null;
    });
  };

  const SelectMetro = async (e) => {
    ContactInfo.current.Metro_Id = e.target.value;
    console.log(
      metroList.find(function (o, i) {
        if (o.Id === e.target.value) {
          ContactInfo.current.Metro = o.Name;
        }
        return null;
      })
    );
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  if (window.$ISMOBILE === true) {
    if (!open) return null;
    return (
      <EstimatorDiv>
        <Section1>
          <ProgressBar>
            <ProgressBarWidth width="12.5%"></ProgressBarWidth>
          </ProgressBar>

          <Header fontsize="22px" marginbottom="1%">
            Project Information
          </Header>
          <BodyPara bodycolor="#808080" marginbottom="0%" fontsize="16px">
            Enter all the information below to get started on your project.
          </BodyPara>
          <BodyPara bodycolor="red" marginbottom="2%" fontsize="16px">
            {ErrorText}
          </BodyPara>
          <FlexColumnFullWidth>
            <FlexColumnCont75x80Percent>
              <SmallHeader
                mobilewidth={mobileHeaderWidth}
                width="100%"
                margin="0px"
              >
                Project Name
              </SmallHeader>
              <TextInputRegular
                mobilemarginright={projectInfoMargin}
                inputwidth="87%"
                placeholder={"Project Name (Ex. Robyn's Backyard)"}
                inputheight="1%"
                inputborderradius="5px"
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></TextInputRegular>
            </FlexColumnCont75x80Percent>
          </FlexColumnFullWidth>

          <FormDivs>
            <FormContPersonalInfo>
              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>
                  First Name
                </MediumHeader100PercentWidth>
                <TextInputRegular
                  placeholder={"First Name"}
                  defaultValue={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                ></TextInputRegular>

                <MediumHeader100PercentWidth>
                  Last Name
                </MediumHeader100PercentWidth>
                <TextInputRegular
                  placeholder={"Last Name"}
                  defaultValue={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                ></TextInputRegular>
              </SecDivPersonalInfoMarginLeft>

              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>
                  Phone Number
                </MediumHeader100PercentWidth>
                <TextInputRegular
                  placeholder={"Cell Phone"}
                  defaultValue={ContactInfo.current.Phone}
                  onChange={(e) => {
                    ContactInfo.current.Phone = e.target.value;
                  }}
                ></TextInputRegular>
              </SecDivPersonalInfoMarginLeft>

              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>
                  Email Address
                </MediumHeader100PercentWidth>
                <TextInputRegular
                  placeholder={"Email Address"}
                  defaultValue={ContactInfo.current.Email}
                  onChange={(e) => {
                    ContactInfo.current.Email = e.target.value;
                  }}
                ></TextInputRegular>
              </SecDivPersonalInfoMarginLeft>
            </FormContPersonalInfo>
            <FormContPersonalInfo>
              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>
                  Address
                </MediumHeader100PercentWidth>
                <TextInputRegular
                  placeholder={"Address"}
                  defaultValue={ContactInfo.current.Street}
                  onChange={(e) => {
                    ContactInfo.current.Street = e.target.value;
                  }}
                ></TextInputRegular>
              </SecDivPersonalInfoMarginLeft>

              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>City</MediumHeader100PercentWidth>

                <DatalistMedium
                  defaultValue={ContactInfo.current.City}
                  inputwidth="100%"
                  inputheight="40%"
                  id="cities"
                  onChange={(e) => {
                    SelectCity(e);
                  }}
                >
                  <DatalistOption value={0}>Select City</DatalistOption>
                  {cityList.map((o, i) => (
                    <DatalistOption
                      id="city"
                      defaultValue={o.Id === ContactInfo.current.City_Id}
                      selected={o.Id === ContactInfo.current.City_Id}
                      key={o.Id}
                      value={o.Id}
                    >
                      {o.Name}
                    </DatalistOption>
                  ))}
                </DatalistMedium>
              </SecDivPersonalInfoMarginLeft>

              <SecDivPersonalInfoMarginLeft>
                <MediumHeader100PercentWidth>
                  Region
                </MediumHeader100PercentWidth>
                <DatalistMedium
                  defaultValue={ContactInfo.current.Metro}
                  inputwidth="100%"
                  inputheight="40%"
                  id="metros"
                  onChange={(e) => {
                    SelectMetro(e);
                    // UpdateCityList(e.target.value);
                  }}
                >
                  <DatalistOption value={0}>Select Region</DatalistOption>
                  {metroList.map((o, i) => (
                    <DatalistOption
                      key={o.Id}
                      value={o.Id}
                      defaultValue={o.Id === ContactInfo.current.Metro_Id}
                      selected={o.Id === ContactInfo.current.Metro_Id}
                    >
                      {o.Name}
                    </DatalistOption>
                  ))}
                </DatalistMedium>
              </SecDivPersonalInfoMarginLeft>
            </FormContPersonalInfo>
          </FormDivs>
          <FlexColumnCont
            height="8%"
            mobilewidth="auto"
            margin="0"
            margintop="auto"
            flexdirection="row"
            justifycontent="space-around"
            width="100%"
          >
            <GreyNavigationButton
              onClick={() => {
                history.push("/storelistestimate/" + params.Id);
              }}
            >
              Back
            </GreyNavigationButton>
            <GreenNavigationButton
              onClick={() => {
                DefinePersonalInfo();
              }}
            >
              Next
            </GreenNavigationButton>
          </FlexColumnCont>
        </Section1>
      </EstimatorDiv>
    );
  } else {
    if (!open) return null;
    return (
      <EstimatorDiv>
        <Section1>
          <ProgressBar>
            <ProgressBarWidth width="12.5%"></ProgressBarWidth>
          </ProgressBar>

          <Header fontsize="22px" marginbottom="1%">
            Project Information
          </Header>
          <BodyPara bodycolor="#808080" marginbottom="0%" fontsize="16px">
            Enter all the information below to get started on your project.
          </BodyPara>
          <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>

          <FlexRowCont>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="projectname">
                Project Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="projectname"
                mobilemarginright={projectInfoMargin}
                placeholder={"Project Name (Ex. Robyn's Backyard)"}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="address">Address</LeagueSpartanTiny>
              <TextInputReusable
                id="address"
                placeholder={"Address"}
                defaultValue={ContactInfo.current.Street}
                onChange={(e) => {
                  ContactInfo.current.Street = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowCont>

          <FlexRowCont>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="firstname">
                First Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="firstname"
                placeholder={"First Name"}
                defaultValue={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="lastname">
                Last Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="lastname"
                placeholder={"Last Name"}
                defaultValue={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowCont>

          <FlexRowCont>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="cellphone">
                Phone Number
              </LeagueSpartanTiny>
              <TextInputReusable
                type="tel"
                id="cellphone"
                placeholder={"Cell Phone"}
                defaultValue={ContactInfo.current.Phone}
                onChange={(e) => {
                  ContactInfo.current.Phone = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="emailaddress">
                Email Address
              </LeagueSpartanTiny>
              <TextInputReusable
                type="email"
                id="emailaddress"
                placeholder={"Email Address"}
                defaultValue={ContactInfo.current.Email}
                onChange={(e) => {
                  ContactInfo.current.Email = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowCont>

          <FlexRowCont>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="cities">City</LeagueSpartanTiny>

              <DatalistMedium
                defaultValue={ContactInfo.current.City}
                id="cities"
                onChange={(e) => {
                  SelectCity(e);
                }}
              >
                <DatalistOption value={0}>Select City</DatalistOption>
                {cityList.map((o, i) => (
                  <DatalistOption
                    id="city"
                    defaultValue={o.Id === ContactInfo.current.City_Id}
                    selected={o.Id === ContactInfo.current.City_Id}
                    key={o.Id}
                    value={o.Id}
                  >
                    {o.Name}
                  </DatalistOption>
                ))}
              </DatalistMedium>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="metros">Region</LeagueSpartanTiny>
              <DatalistMedium
                defaultValue={ContactInfo.current.Metro}
                id="metros"
                onChange={(e) => {
                  SelectMetro(e);
                  UpdateCityList(e.target.value);
                }}
              >
                <DatalistOption value={0}>Select Region</DatalistOption>
                {metroList.map((o, i) => (
                  <DatalistOption
                    key={o.Id}
                    value={o.Id}
                    defaultValue={o.Id === ContactInfo.current.Metro_Id}
                    selected={o.Id === ContactInfo.current.Metro_Id}
                  >
                    {o.Name}
                  </DatalistOption>
                ))}
              </DatalistMedium>
            </FlexColumnLeftAlign>
          </FlexRowCont>

          <br></br>

          <FlexColumnCont
            height="8%"
            mobilewidth="auto"
            margin="0"
            margintop="auto"
            flexdirection="row"
            justifycontent="space-around"
            width="100%"
          >
            <GreyNavigationButton
              onClick={() => {
                history.push("/storelistestimate/" + params.Id);
              }}
            >
              Back
            </GreyNavigationButton>
            <GreenNavigationButton
              onClick={() => {
                DefinePersonalInfo();
              }}
            >
              Next
            </GreenNavigationButton>
          </FlexColumnCont>
        </Section1>
      </EstimatorDiv>
    );
  }
};

PersonalInfo.defaultProps = {
  onChange: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default PersonalInfo;
