//  React Imports ---------------------------------------
import React, { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

//  Imported Components ---------------------------------
import Menu from "../../comps/MenuComps/Menu";
import ActionMenu from "../../comps/MenuComps/TopMenu";
import CaseCardUser from "../../comps/CaseCardUser";

// Imported Styled Components ---------------------------
import {
  LeftCont,
  FlexColumn81vwLightGrey,
  FlexColumnFullWidthMargins,
  FlexColumnPropWidth,
} from "../../StylesheetComps/Cont";
import { SetHeaderAuthorization } from "../../API_Functions/Utility_Functions";
import { Cont, ContentCont77vw } from "../../StylesheetComps/Projects";
import { FlexColumnCont } from "../../StylesheetComps/Menu";
import { Header } from "../../StylesheetComps/AuthSheet";
import { GetUserCases } from "../../API_Functions/CaseQuote_Functions";
import { LeagueFontSize100Percent } from "../../StylesheetComps/Titles";
import { ColorPalette } from "../../StylesheetComps/Colors";
import { UserRole } from "../../StylesheetComps/UserRoles";
import { Div100vw10vh } from "../../MobileComponents/Divs";
import { TopImage15vh } from "../../MobileComponents/Images";
import { MobileWideGreenButtonRelative } from "../../MobileComponents/Buttons";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const MyProjectsScreen = () => {
  var userinfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const params = useParams();

  const [userCase, setUserCases] = useState([]);
  const [roleNum, setRoleNum] = useState(1000);
  const [metroId, setMetroId] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const history = useHistory();

  const RetrieveUserCases = async (userid) => {
    let data = await GetUserCases(userid);
    if (data !== null) {
      setUserCases(data.Cases);
      setRoleNum(userinfo.Role);
    }
  };

  const CheckStoreEstimates = async (metroId, storeId) => {
    const resp = await axios.get(window.$DBURL + "store/metro/" + metroId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        if (resp.data.stores !== null) {
          if (storeId === 0) {
            if (resp.data.stores.length !== 1) {
              history.push("/storelistestimate/" + metroId);
            } else {
              history.push(
                "/estimate/" + metroId + "/" + resp.data.stores[0].Id
              );
            }
          } else {
            history.push("/estimate/" + metroId + "/" + storeId);
          }
        }
      }
    }
  };

  useEffect(() => {
    SetHeaderAuthorization();
    setRoleNum(userinfo.Role);
    RetrieveUserCases(userinfo.Id);
    setMetroId(userinfo.Metro_Id);
    setStoreId(userinfo.Store_Id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (window.$ISMOBILE === true) {
    if (roleNum !== UserRole.Loading) {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <FlexColumn81vwLightGrey>
            <ActionMenu
              mobilePageProp="Projects"
              pageProp={"Projects"}
              projectsbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
            ></ActionMenu>

            <Div100vw10vh>
              <TopImage15vh src="/homescreenimg.png"></TopImage15vh>
            </Div100vw10vh>

            <MobileWideGreenButtonRelative
              onClick={() => {
                CheckStoreEstimates(metroId, storeId);
              }}
            >
              New Project +
            </MobileWideGreenButtonRelative>

            <ContentCont77vw>
              <FlexColumnFullWidthMargins>
                {/*  <Header margintop="0%" marginbottom="0%" fontsize="20px">
                  Current Projects
                </Header> */}

                <FlexColumnCont
                  justifycontent="flex-start"
                  margin="0"
                  margintop="2%"
                  borderbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
                  height="45px"
                  width="99%"
                  flexdirection="row"
                >
                  <FlexColumnPropWidth
                    width="auto"
                    marginright="11%"
                    marginleft="3.5%"
                  >
                    <LeagueFontSize100Percent>
                      Project Name
                    </LeagueFontSize100Percent>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="auto" marginright="16.5%">
                    <LeagueFontSize100Percent>Address</LeagueFontSize100Percent>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="auto" marginright="28%">
                    <LeagueFontSize100Percent>Date</LeagueFontSize100Percent>
                  </FlexColumnPropWidth>

                  <FlexColumnPropWidth width="auto" height="100%">
                    <LeagueFontSize100Percent>Status</LeagueFontSize100Percent>
                  </FlexColumnPropWidth>
                </FlexColumnCont>

                {userCase.map((o, i) => (
                  <CaseCardUser
                    CaseImgs={o.ImageUrl}
                    Status={o.CaseStatus}
                    CaseId={o.Id}
                    EstimatorName={o.Estimator}
                    ProjectName={o.Tittle}
                    Address={o.Street}
                    StartDate={o.CreateTime}
                    ClientName={o.Name}
                    key={i}
                    CaseNumber={"#" + o.CaseNumber}
                    DateText={o.CreateTime}
                  ></CaseCardUser>
                ))}
              </FlexColumnFullWidthMargins>
            </ContentCont77vw>
          </FlexColumn81vwLightGrey>
        </Cont>
      );
    } else {
      return (
        <Cont>
          <LeftCont>
            <Menu> </Menu>
          </LeftCont>
          <Backdrop style={{ zIndex: 2 }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Cont>
      );
    }
  } else if (roleNum !== UserRole.Loading) {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <FlexColumn81vwLightGrey>
          <ActionMenu
            mobilePageProp="Projects"
            pageProp={"Projects"}
            projectsbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
          ></ActionMenu>
          <ContentCont77vw>
            <FlexColumnFullWidthMargins>
              {/* <Header margintop="0%" marginbottom="0%" fontsize="20px">
                Current Projects
              </Header> */}

              <FlexColumnCont
                justifycontent="flex-start"
                margin="0"
                margintop="1%"
                borderbottom={"4px solid " + ColorPalette.PrimaryButtonBlue}
                height="6%"
                width="99%"
                flexdirection="row"
              >
                <FlexColumnPropWidth
                  width="auto"
                  minwidth="115px"
                  marginright="8.5vw"
                >
                  <LeagueFontSize100Percent>
                    Project Name
                  </LeagueFontSize100Percent>
                </FlexColumnPropWidth>

                <FlexColumnPropWidth width="auto" marginright="14vw">
                  <LeagueFontSize100Percent>Address</LeagueFontSize100Percent>
                </FlexColumnPropWidth>

                <FlexColumnPropWidth width="auto" marginright="19vw">
                  <LeagueFontSize100Percent>Date</LeagueFontSize100Percent>
                </FlexColumnPropWidth>

                <FlexColumnPropWidth width="auto" height="100%">
                  <LeagueFontSize100Percent></LeagueFontSize100Percent>
                </FlexColumnPropWidth>
              </FlexColumnCont>

              {userCase.map((o, i) => (
                <CaseCardUser
                  CaseImgs={o.ImageUrl}
                  Status={o.CaseStatus}
                  CaseId={o.Id}
                  EstimatorName={o.Estimator}
                  ProjectName={o.Tittle}
                  Address={o.Street}
                  StartDate={o.CreateTime}
                  ClientName={o.Name}
                  key={i}
                  CaseNumber={"#" + o.CaseNumber}
                  DateText={o.CreateTime}
                ></CaseCardUser>
              ))}
            </FlexColumnFullWidthMargins>
          </ContentCont77vw>
        </FlexColumn81vwLightGrey>
      </Cont>
    );
  } else {
    return (
      <Cont>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>
        <Backdrop style={{ zIndex: 2 }} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Cont>
    );
  }
};

MyProjectsScreen.defaultProps = {};

export default MyProjectsScreen;
