import React, { useState } from "react";

import { Header } from "../../../StylesheetComps/AuthSheet";
import { SmallHeader } from "../../../StylesheetComps/Menu";
import {
  BodyPara,
  SelectableMediumLongButton,
} from "../../../StylesheetComps/AuthSheet";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { TextInput300x50 } from "../../../StylesheetComps/AuthSheet";
import {
  FlexColumnGreenSelectables,
  GreenNavigationButton,
  GreyNavigationButton,
} from "../../../StylesheetComps/LandscapeForm";
import {
  Section1,
  EstimatorDiv,
  ProgressBar,
  ProgressBarWidth,
} from "../../../StylesheetComps/LandscapeForm";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { FlexRowCont70PercentFlexStart } from "../../../StylesheetComps/Cont";
const ProjectDescType = ({
  open,
  onNext,
  onBack,
  onPreview,
  onPersonalInfo,
  fullName,
  addressofUser,
  emailofUser,
  phoneofUser,
  onSelectProjectType,
}) => {
  //Planting UI Update
  const [plantingcolor, setPlantingColor] = useState("black");
  const [plantingborder, setPlantingBorder] = useState("4px solid white");
  const [planting, setPlanting] = useState("");

  //Fencing UI Update
  const [fencingcolor, setFencingColor] = useState("black");
  const [fencingborder, setFencingBorder] = useState("4px solid white");
  const [fencing, setFencing] = useState("");

  //Walkway UI Update
  const [walkwaycolor, setWalkwayColor] = useState("black");
  const [walkwayborder, setWalkwayBorder] = useState("4px solid white");
  const [walkway, setWalkway] = useState("");

  //Retaining UI Update
  const [retainingcolor, setRetainingColor] = useState("black");
  const [retainingborder, setRetainingBorder] = useState("4px solid white");
  const [retaining, setRetaining] = useState("");

  //Lawn UI Update
  const [lawncolor, setLawnColor] = useState("black");
  const [lawnborder, setLawnBorder] = useState("4px solid white");
  const [lawn, setLawn] = useState("");

  //Patio UI Update
  const [patiocolor, setPatioColor] = useState("black");
  const [patioborder, setPatioBorder] = useState("4px solid white");
  const [patio, setPatio] = useState("");

  //Driveway UI Update
  const [drivewaycolor, setDrivewayColor] = useState("black");
  const [drivewayborder, setDrivewayBorder] = useState("4px solid white");
  const [driveway, setDriveway] = useState("");

  //Yard Structure UI Update
  const [yardstructurecolor, setYardStructureColor] = useState("black");
  const [yardstructureborder, setYardStructureBorder] =
    useState("4px solid white");
  const [yardstructure, setYardStructure] = useState("");

  //Water Feature UI Update
  const [waterfeaturecolor, setWaterFeatureColor] = useState("black");
  const [waterfeatureborder, setWaterFeatureBorder] =
    useState("4px solid white");
  const [waterfeature, setWaterFeature] = useState("");

  //Other UI Update
  const [othercolor, setOtherColor] = useState("black");
  const [otherborder, setOtherBorder] = useState("4px solid white");
  const [other, setOther] = useState("");
  const [displayOtherDiv, setDisplayOtherDiv] = useState("none");

  const [otherTypeOfWork, setOtherTypeOfWork] = useState("");

  //Switch to the set the UI Styles + Values for the Project Type Buttons

  const TypeOfWorkChecked = async (type, color) => {
    switch (type) {
      case "planting":
        if (color === "black") {
          setPlanting("Planting");
          setPlantingColor(ColorPalette.PrimaryButtonBlue);
          setPlantingBorder("4px solid #169ADB");
        } else {
          setPlanting("");
          setPlantingColor("black");
          setPlantingBorder("4px solid white");
        }
        break;
      case "fencing":
        if (color === "black") {
          setFencing("Fencing");
          setFencingColor(ColorPalette.PrimaryButtonBlue);
          setFencingBorder("4px solid #169ADB");
        } else {
          setFencing("");
          setFencingColor("black");
          setFencingBorder("4px solid white");
        }
        break;
      case "walkway":
        if (color === "black") {
          setWalkway("Walkway");
          setWalkwayColor(ColorPalette.PrimaryButtonBlue);
          setWalkwayBorder("4px solid #169ADB");
        } else {
          setWalkway("");
          setWalkwayColor("black");
          setWalkwayBorder("4px solid white");
        }
        break;
      case "retaining":
        if (color === "black") {
          setRetaining("Retaining Walls");
          setRetainingColor(ColorPalette.PrimaryButtonBlue);
          setRetainingBorder("4px solid #169ADB");
        } else {
          setRetaining("");
          setRetainingColor("black");
          setRetainingBorder("4px solid white");
        }
        break;
      case "lawn":
        if (color === "black") {
          setLawn("Lawn");
          setLawnColor(ColorPalette.PrimaryButtonBlue);
          setLawnBorder("4px solid #169ADB");
        } else {
          setLawn("");
          setLawnColor("black");
          setLawnBorder("4px solid white");
        }
        break;
      case "patio":
        if (color === "black") {
          setPatio("Patio/Decking");
          setPatioColor(ColorPalette.PrimaryButtonBlue);
          setPatioBorder("4px solid #169ADB");
        } else {
          setPatio("");
          setPatioColor("black");
          setPatioBorder("4px solid white");
        }
        break;
      case "driveway":
        if (color === "black") {
          setDriveway("Driveway");
          setDrivewayColor(ColorPalette.PrimaryButtonBlue);
          setDrivewayBorder("4px solid #169ADB");
        } else {
          setDriveway("");
          setDrivewayColor("black");
          setDrivewayBorder("4px solid white");
        }
        break;
      case "yardstructure":
        if (color === "black") {
          setYardStructure("Yard Structure");
          setYardStructureColor(ColorPalette.PrimaryButtonBlue);
          setYardStructureBorder("4px solid #169ADB");
        } else {
          setYardStructure("");
          setYardStructureColor("black");
          setYardStructureBorder("4px solid white");
        }
        break;
      case "waterfeature":
        if (color === "black") {
          setWaterFeature("Water Feature");
          setWaterFeatureColor(ColorPalette.PrimaryButtonBlue);
          setWaterFeatureBorder("4px solid #169ADB");
        } else {
          setWaterFeature("");
          setWaterFeatureColor("black");
          setWaterFeatureBorder("4px solid white");
        }
        break;
      case "other":
        if (color === "black") {
          setDisplayOtherDiv("block");
          setOther("Other:");
          setOtherColor(ColorPalette.PrimaryButtonBlue);
          setOtherBorder("4px solid #169ADB");
        } else {
          setDisplayOtherDiv("none");
          setOther("");
          setOtherColor("black");
          setOtherBorder("4px solid white");
        }
        break;
      default:
    }
  };

  //When next is clicked, define scope project type, push to higher funcition, and move forward to project description

  const DefineProjectType = async (
    planting,
    fencing,
    walkway,
    retaining,
    lawn,
    patio,
    driveway,
    yardstructure,
    waterfeature,
    other,
    otherTypeOfWork
  ) => {
    let projectdesctype =
      planting +
      " " +
      fencing +
      " " +
      walkway +
      " " +
      retaining +
      " " +
      lawn +
      " " +
      patio +
      " " +
      driveway +
      " " +
      yardstructure +
      " " +
      waterfeature +
      " " +
      other +
      otherTypeOfWork;
    onSelectProjectType(projectdesctype);
    onNext();
  };

  if (!open) return null;
  return (
    <EstimatorDiv>
      <Section1>
        <ProgressBar>
          <ProgressBarWidth width="62.5%"></ProgressBarWidth>
        </ProgressBar>

        <Header fontsize="28px" marginbottom="1%">
          Project Type
        </Header>
        <BodyPara
          bodycolor="#808080"
          width="55%"
          marginbottom="2%"
          fontsize="16px"
        >
          What type of work would you like done? You can always remove or add
          work from your project once you receive your free project estimate
          (Check all that apply).
        </BodyPara>

        <FlexColumnCont
          width="73%"
          height="auto"
          marginbottom="3%"
          alignitems="left"
        >
          <FlexColumnCont width="100%" height="100%" marginbottom="0%">
            <FlexColumnCont
              width="100%"
              mobilewidth="80vw"
              justifycontent="flex-start"
              height="80%"
            >
              <FlexColumnGreenSelectables>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={plantingborder}
                  color={plantingcolor}
                  onClick={() => {
                    TypeOfWorkChecked("planting", plantingcolor);
                  }}
                >
                  Planting
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={fencingborder}
                  color={fencingcolor}
                  onClick={() => {
                    TypeOfWorkChecked("fencing", fencingcolor);
                  }}
                >
                  Fencing
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={walkwayborder}
                  color={walkwaycolor}
                  onClick={() => {
                    TypeOfWorkChecked("walkway", walkwaycolor);
                  }}
                >
                  Walkway
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={retainingborder}
                  color={retainingcolor}
                  onClick={() => {
                    TypeOfWorkChecked("retaining", retainingcolor);
                  }}
                >
                  Retaining Wall
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={lawnborder}
                  color={lawncolor}
                  onClick={() => {
                    TypeOfWorkChecked("lawn", lawncolor);
                  }}
                >
                  Lawn
                </SelectableMediumLongButton>
              </FlexColumnGreenSelectables>

              <FlexColumnGreenSelectables>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={patioborder}
                  color={patiocolor}
                  onClick={() => {
                    TypeOfWorkChecked("patio", patiocolor);
                  }}
                >
                  Patio/Decking
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={drivewayborder}
                  color={drivewaycolor}
                  onClick={() => {
                    TypeOfWorkChecked("driveway", drivewaycolor);
                  }}
                >
                  Driveway
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={yardstructureborder}
                  color={yardstructurecolor}
                  onClick={() => {
                    TypeOfWorkChecked("yardstructure", yardstructurecolor);
                  }}
                >
                  Yard Structure
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={waterfeatureborder}
                  color={waterfeaturecolor}
                  onClick={() => {
                    TypeOfWorkChecked("waterfeature", waterfeaturecolor);
                  }}
                >
                  Water Feature
                </SelectableMediumLongButton>
                <SelectableMediumLongButton
                  mobilewidth="90%"
                  border={otherborder}
                  color={othercolor}
                  onClick={() => {
                    TypeOfWorkChecked("other", othercolor);
                  }}
                >
                  Other
                </SelectableMediumLongButton>
              </FlexColumnGreenSelectables>
            </FlexColumnCont>
          </FlexColumnCont>
        </FlexColumnCont>

        <FlexColumnCont
          display={displayOtherDiv}
          margintop="0"
          alignitems="left"
          width="55%"
          marginbottom="0"
          height="auto"
          mobilewidth="90vw"
          mobilealignitems="center"
        >
          <FlexRowCont70PercentFlexStart>
            <SmallHeader>
              If selected other, what is the type of work?
            </SmallHeader>
          </FlexRowCont70PercentFlexStart>
          <TextInput300x50
            inputwidth="84%"
            placeholder={"Type of Work"}
            inputheight="1%"
            inputborderradius="5px"
            defaultValue={fullName}
            onChange={(e) => {
              setOtherTypeOfWork(e.target.value);
            }}
          ></TextInput300x50>
        </FlexColumnCont>
        <FlexColumnCont
          height="8%"
          margin="0"
          margintop="2%"
          flexdirection="row"
          justifycontent="space-around"
          width="100%"
        >
          <GreyNavigationButton
            onClick={() => {
              onBack();
            }}
          >
            Back
          </GreyNavigationButton>
          <GreenNavigationButton
            onClick={() => {
              DefineProjectType(
                planting,
                fencing,
                walkway,
                retaining,
                lawn,
                patio,
                driveway,
                yardstructure,
                waterfeature,
                other,
                otherTypeOfWork
              );
            }}
          >
            Next
          </GreenNavigationButton>
        </FlexColumnCont>
      </Section1>
    </EstimatorDiv>
  );
};

// }

ProjectDescType.defaultProps = {
  onChange: () => {},
  onSelectProjectType: () => {},
  onPreview: () => {},
  onPersonalInfo: () => {},
  fullName: "",
  addressofUser: "",
  emailofUser: "",
  phoneofUser: "",
};

export default ProjectDescType;
