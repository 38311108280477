import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionMenu from "../../comps/ActionMenu";
import CaseCard from "../../comps/CaseCard";
import Menu from "../../comps/MenuComps/Menu";
import DeleteConfirmWindow from "../../comps/Windows/DeleteProduct";
import { UserRole } from "../../StylesheetComps/UserRoles";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorPalette } from "../../StylesheetComps/Colors";
import {
  FlexCont76PercentMobile,
  FlexContMobileTopVH,
  LeftCont,
} from "../../StylesheetComps/Cont";
import { FlexColumnCont80vwAutoHeight } from "../../StylesheetComps/Cont";
import { RightViewFullWidth } from "../../StylesheetComps/Cont/RightView";
import {
  Div150pxMobile250px,
  Div150pxMobileDisplayNone,
  Div80px,
} from "../../StylesheetComps/Div";
import {
  LeagueFontSize100Percent,
  LeagueSpartanLarge,
  StyledHRPropColour,
} from "../../StylesheetComps/Titles";

const UserCasesScreen = () => {
  //=============================================================================
  // Users Cases Screen - Manager/Estimator can view all cases of a user
  //=============================================================================

  const params = useParams();

  const [cases, setCases] = useState([]);

  const [roleNum, setRoleNum] = useState(1000);
  const [caseId, setCaseId] = useState(0);

  const [deleteCase, setDeleteCase] = useState(false);

  const GetUserCases = async () => {
    const resp = await axios.get(window.$DBURL + "case/user/" + params.userId);
    if (resp.status === 200) {
      if (resp.data !== null) {
        setCases(resp.data.Cases);
      }
    }

    setRoleNum(JSON.parse(sessionStorage.getItem("userInfo")).Role);
  };

  const HandleCaseDel = async (caseId) => {
    const resp = await axios.delete(window.$DBURL + "case/" + caseId);
    if (resp !== null) {
    }
    GetUserCases();
  };

  useEffect(() => {
    GetUserCases();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    roleNum === UserRole.Admin ||
    roleNum === UserRole.Manager ||
    UserRole.Estimator
  ) {
    return (
      <FlexContMobileTopVH>
        <LeftCont>
          <Menu> </Menu>
        </LeftCont>

        <FlexColumnCont80vwAutoHeight>
          <ActionMenu />
          <RightViewFullWidth>
            <LeagueSpartanLarge>
              {params.userName + "'s Cases"}
            </LeagueSpartanLarge>
            <StyledHRPropColour
              border={`0.5px solid ${ColorPalette.PrimaryGrey} `}
            ></StyledHRPropColour>
            <FlexCont76PercentMobile hoverbg="white">
              <Div80px>
                <LeagueFontSize100Percent>Index</LeagueFontSize100Percent>
              </Div80px>
              <Div150pxMobile250px>
                <LeagueFontSize100Percent>Case Number</LeagueFontSize100Percent>
              </Div150pxMobile250px>
              <Div150pxMobile250px>
                <LeagueFontSize100Percent>Homeowner</LeagueFontSize100Percent>
              </Div150pxMobile250px>
              <Div150pxMobileDisplayNone>
                <LeagueFontSize100Percent>
                  Submitted Time
                </LeagueFontSize100Percent>
              </Div150pxMobileDisplayNone>
              <Div150pxMobile250px>
                <LeagueFontSize100Percent>Estimator</LeagueFontSize100Percent>
              </Div150pxMobile250px>
              <Div150pxMobile250px>
                <LeagueFontSize100Percent>Status</LeagueFontSize100Percent>
              </Div150pxMobile250px>
              <Div150pxMobile250px>
                <LeagueFontSize100Percent>Delete</LeagueFontSize100Percent>
              </Div150pxMobile250px>
            </FlexCont76PercentMobile>
            {cases.map((o, i) => (
              <div>
                <DeleteConfirmWindow
                  deleteOpen={deleteCase}
                  onDeleteFunction={() => {
                    HandleCaseDel(caseId);
                  }}
                  closeDel={() => {
                    setDeleteCase(false);
                  }}
                ></DeleteConfirmWindow>
                <CaseCard
                  index={cases.length - i - 1}
                  key={i}
                  Status={o.CaseStatus}
                  EstimatorName={o.Estimator}
                  CaseId={o.Id}
                  ClientName={o.Name}
                  CaseNumber={"#" + o.CaseNumber}
                  DateText={o.CreateTime}
                  onDel={() => {
                    setCaseId(o.Id);
                    setDeleteCase(true);
                  }}
                ></CaseCard>
              </div>
            ))}
          </RightViewFullWidth>
        </FlexColumnCont80vwAutoHeight>
      </FlexContMobileTopVH>
    );
  } else {
    return (
      <Backdrop style={{ zIndex: 2 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

UserCasesScreen.defaultProps = {
  ProfileName: "Default Name",
};

export default UserCasesScreen;
